import * as React from "react";
import { Grid } from "@material-ui/core";
import { Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Button from '@material-ui/core/Button';

type Props = {
  acceptBtnText: string;
  cancelBtnText: string;
  titleText: string;
  children: JSX.Element[] | JSX.Element;
  onClose: (accepted: boolean) => void;
  openModel: boolean;
  onFilterExport: () => void;
  isDisableExport: boolean;
};

class CustomKendoDialog extends React.Component<Props> {

constructor(props: Props) {
    super(props);
  }

 handleClose = () => {   
    this.props.onClose(false);
  };

  render() {
    return (
      <div>
        {this.props.openModel && (
          <Dialog
            onClose={this.handleClose.bind(this, false)}
            aria-labelledby="customized-dialog-title"
            className="modal bulk-import-modal">
            <DialogTitle className="modal-title">
              {this.props.titleText}
              <IconButton
                className="modal-close"
                aria-label="close"
                onClick={this.handleClose.bind(this, false)}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  {this.props.children}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActionsBar>
              <Button className="btn-secondary" onClick={this.handleClose}>{this.props.cancelBtnText}
              </Button>
              <Button variant="contained" color="primary" onClick={this.props.onFilterExport} disabled={!this.props.isDisableExport}>
                {this.props.acceptBtnText}
              </Button>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    );
  }
}

export default CustomKendoDialog;
