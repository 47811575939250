import { Action, Reducer } from "redux";
import { INotificationMessageModel } from "../models/MessageModel";

export interface INotificationMessageState {
  notificationData: INotificationMessageModel[];
  unReadNotificationCount: number;
  openAlertPopup?: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface ISetNotificationDataAction {
  type: "SET_NOTIFICATION_DATA";
  notificationData?: INotificationMessageModel[];
  unReadNotificationCount?: number;
  openAlertPopup?: boolean;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = ISetNotificationDataAction;

export const actionCreators = {
  setNotificationData: (notificationData: INotificationMessageModel[], unReadNotificationCount: number, openAlertPopup?: boolean) => ({ type: "SET_NOTIFICATION_DATA", notificationData, unReadNotificationCount, openAlertPopup } as ISetNotificationDataAction),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: INotificationMessageState = {
  notificationData: null,
  unReadNotificationCount: 0,
  openAlertPopup: null
};

export const reducer: Reducer<INotificationMessageState> = (
  state: INotificationMessageState | undefined,
  incomingAction: Action
): INotificationMessageState => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "SET_NOTIFICATION_DATA":
      return { ...state, notificationData: (action as ISetNotificationDataAction).notificationData, unReadNotificationCount: (action as ISetNotificationDataAction).unReadNotificationCount, openAlertPopup: (action as ISetNotificationDataAction).openAlertPopup };
    default:
      return state;
  }
};
