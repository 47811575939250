export default class Project {
  public projectId: number = 0;
  public projectNumber: string = "";
  public projectName: string = "";
  public projectStatus: string = "";
  public projectStartDate?: Date | null | undefined;
  public lastModifiedDate?: string | null | undefined;
  public clientMatter?: string = "";
  public clientName: string = "";
  public clientServicesDirectorName?: string = "";
  public matterType?: string = "";
  public primaryPMName?: string = ""; 
  public deliveryLocation?: string = "";
  public esiProjectCode?: string = ""; 
  public sapContractId?: string = "";
  public snowflakeId?: string = "";  
}