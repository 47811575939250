import { FilterDescriptor } from "@progress/kendo-data-query";
import { GridCellProps, GridColumnProps, GridFilterCellProps } from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Employer, EmployerDomain, EmployerSelectable } from "../../../models/EmployerModel";
import AdminService from "../../../services/AdminService";
import { Button } from "@progress/kendo-react-buttons";
import { AdvancedGrid, IGridParams } from "../../common/grid/AdvancedGrid";
import { connect } from "react-redux";
import * as SessionStore from "../../../store/Session";
import { IApplicationState } from "../../../store";
import "./Employers.scss";
import SingleSelectDropDown from "../../common/SingleSelectDropDown";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import GridSelector from "../../common/grid/GridSelector";
import EmployerDomainsPopup from "./EmployerDomainsPopup";
import { DropDownFilter } from "../../common/grid/filters/DropDownFilter";
import { Tooltip } from "@progress/kendo-react-tooltip";

type IProps = {
  history: any;
};

type selectedEmployer = {
  id: number;
  name: string;
  domainCount: number;
  showViewButton: false;
}

type Props = IProps & SessionStore.ISessionState;

const EmployersList = (props: Props) => {
  const [employers, setEmployers] = useState<Employer[]>([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isOpenDetailsView, setIsOpenDetailsView] = useState(false);
  const [isOpenTooltip, setIsOpenTooltip] = useState(true);
  const [selectedEmployerDetails, setSelectedEmployerDetails] = useState({} as selectedEmployer);

  const dataState: IGridParams = {
    skip: 0,
    take: 100,
    sort: [{ field: "name", dir: "asc" }]
  };

  const fetchEmployers = async (dataState: IGridParams) => {
    setIsLoading(true);

    var filters = dataState.filters
      ? dataState.filters.map(compositeFilter => {


        const epiqClientFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "epiqClient"
        ) as FilterDescriptor;

        if (epiqClientFilter) {
          const epiqClientFilterValue = epiqClientFilter.value
            ? epiqClientFilter.value.id == 0 || epiqClientFilter.value.id == 1
              ? epiqClientFilter.value.id == 0
                ? false
                : true
              : null
            : false;

          return {
            ...compositeFilter,
            filters: [
              {
                ...epiqClientFilter,
                field: "epiqClient",
                operator: "eq",
                value: epiqClientFilterValue
              }
            ]
          };
        }

        const numericFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "id" || (filter as FilterDescriptor).field === "totalDomainCount"
        ) as FilterDescriptor;

        if (numericFilter) {
          const isFilterValueString = numericFilter.value && typeof (numericFilter.value) === "string"

          if (isFilterValueString) {
            return {
              ...compositeFilter,
              filters: [
                {
                  ...numericFilter,
                  value: -1
                }
              ]
            };
          }
          else if (isFilterValueString === null) {
            return {
              ...compositeFilter,
              filters: [
                {
                  ...numericFilter,
                  operator: "gte",
                  value: 0
                }
              ]
            };
          }

        }

        const epiqDeptFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "epiqDept"
        ) as FilterDescriptor;

        if (epiqDeptFilter) {
          const epiqDeptFilterValue = epiqDeptFilter.value;

          return {
            ...compositeFilter,
            filters: [
              {
                ...epiqDeptFilter,
                field: "epiqDept",
                operator: "eq",
                value: epiqDeptFilterValue.name
              }
            ]
          };
        }

        const entityTypeFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "entityType"
        ) as FilterDescriptor;

        if (entityTypeFilter) {
          const entityTypeFilterValue = entityTypeFilter.value;

          return {
            ...compositeFilter,
            filters: [
              {
                ...entityTypeFilter,
                field: "entityType",
                operator: "eq",
                value: entityTypeFilterValue.name
              }
            ]
          };
        }

        return compositeFilter;
      }) : [];

    const result = await AdminService.getEmployerList({ ...dataState, filters: filters });

    if (result.ok) {
      setEmployers(result.data.results);
      setCount(result.data.count);
      setHasError(false);
    } else {
      setHasError(true);
      console.log("Something went wrong while fetching employers.");
    }

    setIsLoading(false);

  }

  const employerNameCellLink = (props: GridCellProps) => {
    const name = props.dataItem.name;
    return (
      <td>
        <div className="emp-name">
          <NavLink to={`/administration/employerDetails/${props.dataItem.id}`} >
            <em title={name}>{name}</em>
          </NavLink>
        </div>
      </td>
    );
  }

  const employerNameCell = (props: GridCellProps) => {
    const name = props.dataItem.name;
    return (
      <td>
        {name}
      </td>
    );
  }

  const employerDomainCell = (props: GridCellProps) => {
    const domain = props.dataItem.employerDomain ? props.dataItem.employerDomain : "";
    return (
      <td>
        {domain}
      </td>
    );
  }

  const employerDomainCountNoLink = (props: GridCellProps) => {
    const domainCount = props.dataItem.totalDomainCount ? props.dataItem.totalDomainCount : 0;
    return (
      <td>
        {domainCount}
      </td>
    );
  }

  const employerDomainCountCellLink = (selectedEmployerDetails: selectedEmployer) => {
    return (
      <div className="domain-count">
        {selectedEmployerDetails.domainCount > 0 ? (<a
          className="k-link"
          onClick={e => {
            setIsOpenTooltip(false);
            setSelectedEmployerDetails(selectedEmployerDetails);
            setIsOpenDetailsView(true);
          }}
        >
          <em>
            {selectedEmployerDetails.domainCount}
          </em>
        </a>) : (<em>{selectedEmployerDetails.domainCount}</em>)}

      </div>
    );
  };

  const epiqClient = (props: GridCellProps) => {
    return <td>{props.dataItem.epiqClient ? "Epiq Client" : "Non-Epiq Client"}</td>;
  };

  const employerDomainCountCell = (props: GridCellProps) => {
    if (props.dataItem._inEdit) {
      const request: IGridParams = { skip: 0, take: 50 };

      return (
        <td>
          <label> Select Employer Domain </label>
          <div className="resource-selector">
            <SingleSelectDropDown
              getItems={params => AdminService.getRoles(params)}
              textToRequestConverter={input => {
                return { ...request, searchText: input };
              }}
              onChange={event => {
                if (event) {
                  const id = event.data ? event.data.id : null;
                  const updated = { ...props.dataItem, role: event.data, roleId: id };
                  props.onChange({
                    dataItem: updated,
                    dataIndex: props.dataIndex,
                    field: "dataItem",
                    syntheticEvent: event.subEvent ? (event.subEvent as ComboBoxChangeEvent).syntheticEvent : null
                  });
                }
              }}
              preselectedValue={
                props.dataItem.role
                  ? {
                    id: props.dataItem.role.id,
                    text: props.dataItem.role.roleName,
                    data: props.dataItem.role
                  }
                  : props.dataItem.role
              }
              typeToListConverter={(domains: EmployerDomain[]) =>
                domains.map(r => {
                  return {
                    id: r.id,
                    text: r.domain,
                    data: r
                  };
                })
              }
            />
          </div>
        </td>
      );
    }
    const selectedEmployer = {
      domainCount: props.dataItem.totalDomainCount,
      id: props.dataItem.id,
      name: props.dataItem.name,

    } as selectedEmployer;

    return <td>{employerDomainCountCellLink(selectedEmployer)}</td>;

  };

  const epiqDept = (props: GridCellProps) => {
    const dept = props.dataItem.epiqDept;
    return (
      <td>
        {dept}
      </td>
    );
  };

  const entityType = (props: GridCellProps) => {
    const enitity = props.dataItem.entityType;
    return (
      <td>
        {enitity}
      </td>
    );
  };

  const columns = new Array<GridColumnProps>(
    { field: "name", title: "EMPLOYER NAME", cell: props.sessionData.permissions.has("EpiqAdminGetEmployer") ? employerNameCellLink : employerNameCell, filterable: true, filter: "text" },
    { field: "employerDomain", title: "EMPLOYER DOMAIN", cell: employerDomainCell, filterable: true, filter: "text" },
    {
      field: "epiqClient",
      title: "EPIQ CLIENT",
      cell: epiqClient,
      filterable: true,
      filter: "text",
      filterCell: (props: GridFilterCellProps) => (
        <DropDownFilter
          {...props}
          data={[{ name: "Epiq Client", id: 1 }, { name: "Non-Epiq Client", id: 0 }]}
          textField="name"
          defaultSelectedOption={null}
        />
      )
    },
    {
      field: "epiqDept",
      title: "EPIQ DEPT",
      cell: epiqDept,
      filterable: true,
      filter: "text",
      filterCell: (props: GridFilterCellProps) => (
        <DropDownFilter
          {...props}
          data={[{ name: "EDS", id: 0 }, { name: "EMS", id: 1 }, { name: "GBTS", id: 3 }, { name: "Other", id: 2 }]}
          textField="name"
          defaultSelectedOption={null}
        />
      ),
    },
    {
      field: "entityType",
      title: "ENTITY TYPE",
      cell: entityType,
      filterable: true,
      filter: "text",
      filterCell: (props: GridFilterCellProps) => (
        <DropDownFilter
          {...props}
          data={[{ name: "Corporation", id: 0 }, { name: "Law Firm", id: 1 }, { name: "Government", id: 2 }, { name: "Other", id: 3 }]}
          textField="name"
          defaultSelectedOption={null}
        />
      )
    },
    { field: "totalDomainCount", title: "TOTAL DOMAINS", cell: props.sessionData.permissions.has("EpiqAdminGetEmployer") ? employerDomainCountCell : employerDomainCountNoLink, filterable: true, filter: "numeric" },
    { field: "id", title: "EMPLOYER ID", filterable: true, filter: "numeric" }
  );

  const handleCreateEmployerClick = () => {
    props.history.push("/administration/employerDetails/0");
  }

  const gridToolBarRender = (): JSX.Element => {
    return props.sessionData.permissions.has("EpiqAdminCreateEmployer") && (
      <Button className="add-role-btn" icon={"plus"} primary={true} onClick={() => handleCreateEmployerClick()}>
        Employer
      </Button>
    );
  }

  const handleAddUsersPopupClose = () => {
    setIsOpenDetailsView(false);
  };

  const getViewButtonLink = () => {
    let url = `/administration/employerDetails/${selectedEmployerDetails.id}`;
    return url;
  };

  const filterElements = (element: any) => {
    if (element.tagName === "EM") {
      return true;
    }
    return false;
  };

  return (
    <div className="employers-admin-wrapper">
      <Tooltip openDelay={2} position="right" filter={filterElements}>
        <AdvancedGrid
          showErrorState={hasError}
          showLoadingIndicator={isLoading}
          sortable={{ mode: "multiple" }}
          data={employers}
          dataFetch={fetchEmployers}
          dataState={dataState}
          columns={columns}
          paging={false}
          totalRecords={{ value: count, label: "Employers" }}
          noRecordsRender={<p>No employers found.</p>}
          noLoadOnMount={true}
          filteredDataOnly={<p>Please filter to search for employers.</p>}
          multiFieldFilterDelimiter="|"
          filterOperators={{
            text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
            date: [{ text: "grid.filterEqOperator", operator: "eq" }],
            numeric: [{ text: "grid.filterEqOperator", operator: "eq" }]
          }}
          gridToolbarContent={gridToolBarRender()}
          moreRecordsAvailableMessage="Too many employers to display. Adjust filters to refine results"
        />
      </Tooltip>

      <GridSelector
        isOpen={isOpenDetailsView}
        viewBtnText={"VIEW DETAILS"}
        viewBtnUrl={getViewButtonLink()}
        prefixTitleText={
          "Employer"
        }
        titleText={selectedEmployerDetails.name}
        addAvailable={false}
        onClose={handleAddUsersPopupClose}
        addClass="details-view-modal modal-as-sidebar"
      >

        <EmployerDomainsPopup employerId={selectedEmployerDetails.id} />
      </GridSelector>

    </div>
  );
};

export default connect((state: IApplicationState) => ({ ...state.sessionState }), {
})(EmployersList as any);