import * as React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import * as SessionStore from "../../store/Session";
import SessionTimeout from "./SessionTimeout";
import SessionLost from "./SessionLost";
import { ISessionManager } from "../../SessionManager";
import SessionSync from "./SessionSync";

interface IProps {
  sessionManager: ISessionManager;
}

type Props = IProps & SessionStore.ISessionState;

const Session = (props: Props) => {
  return (
    <React.Fragment>
      <SessionTimeout sessionManager={props.sessionManager} />
      <SessionLost sessionManager={props.sessionManager} />
      <SessionSync sessionManager={props.sessionManager} />
    </React.Fragment>
  );
};

export default connect((state: IApplicationState) => state.sessionState, null)(Session);
