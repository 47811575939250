import React, { useEffect, useState } from "react";
import JobService, { ISubmitJobRequestData } from "../../../../services/JobService";
import NotificationService from "../../../../services/NotificationService";
import { ServiceBase } from "../../../../services/ServiceBase";
import { ITicketDetails } from "../../../../services/SupportService";
import { IFileInfo } from "../../../files/FileUploadWizard/FileUploadStep";
import { UploadFileStatus, UploadOnProgressEvent } from "@progress/kendo-react-upload";
import { Slide } from "@progress/kendo-react-animation";
import AddAttachments from "./AddAttachments";
import "./AddAttachmentsSlide.scss";
import { JobTypeEnum } from "../../../../models/Enums";

interface ISubmitData {
  requestTypeCode: string;
  projectId: number;
  id: string;
  sysId: string;
}

interface ISubmitSupportTicketUpdateJobRequestData extends ISubmitJobRequestData {
  submitData: ISubmitData;
  watchListDataSource: boolean;
}

interface IProps {
    jobType?: number;
    ticketDetails: ITicketDetails;
    watchListDataSource: boolean;
    openSlide: boolean;
    onClose: () => void;
}

enum SupportJobStates {
    Support_FileUploadFailed = 63,
    Support_TicketUpdateFilesReady = 66,
    Support_SubmittingTicketUpdateFiles = 67,
    Support_SubmittingTicketUpdateFilesFailed = 68,
    Support_TicketUpdateFilesSubmittedSuccessfully = 69
}

type Props = IProps;

const AddAttachmentsSlide = (props: Props) => {

  const [jobId, setJobId] = useState<string>(null);
  const [uploadState, setUploadState] = useState<"init" | "uploading" | "uploadComplete" | "uploadProcessing">("init");

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    const onClick = (e: MouseEvent) => {

      if (props.openSlide && uploadState !== "uploading" && uploadState !== "uploadProcessing" && uploadState !== "uploadComplete" &&
          e.target && !(e.target as HTMLElement).closest('#attach-files-container')) {
        props.onClose();
      }
    };

    if (props.openSlide) {
      document.body.addEventListener("click", onClick);
    }
    else {
      document.body.removeEventListener("click", onClick);
    }

    return () => {
      document.body.removeEventListener("click", onClick);
    };
  }, [props.openSlide, props.ticketDetails, uploadState]);

  const initialize = async function(forceNewJob: boolean = false) {
    if (!jobId || forceNewJob) {
      const result = await JobService.initializeJob({ jobType: props.jobType });

      if (result.ok) {
        setJobId(result.data.jobId);
      }
    }
  }

  const resetJob = async function() {
    const result = await JobService.resetJob(jobId);

    if (!result.ok) {
      initialize(true);
    }
  }

  const fetchJobStatus = async function() {
  const result = await JobService.getJobStatus(jobId);

  if (result.ok) {
    const status = result.data.statusId;

    switch (status) {
    case SupportJobStates.Support_TicketUpdateFilesReady:
    case SupportJobStates.Support_SubmittingTicketUpdateFiles:
      await ServiceBase.setTimeoutPromise(1000);
      await fetchJobStatus();

      break;
    case SupportJobStates.Support_TicketUpdateFilesSubmittedSuccessfully:
      NotificationService.showSuccessToast("Updated request with attached files successfully.");
      setUploadState("init");
      props.onClose();
      //reset the job so more appends will succeed.
      await resetJob();

      break;
    case SupportJobStates.Support_SubmittingTicketUpdateFilesFailed:
      NotificationService.showErrorToast("Updating request with attached files failed.");
      setUploadState("init");
      //reset the job so more appends will succeed.
      await resetJob();
      break;

    case SupportJobStates.Support_FileUploadFailed:
      NotificationService.showErrorToast("Updating request with attached files failed.");
      setUploadState("init");
      //reset the job so more appends will succeed.
      await resetJob();  
      break;
      }
    } else {
        NotificationService.showErrorToast("Failed to get job status.");
    }
  };

  const handleOnUploadComplete = async function() {

    const projectId = props.ticketDetails.projectId == 0 ? null : props.ticketDetails.projectId;

    const requestData: ISubmitSupportTicketUpdateJobRequestData = {
      jobId: jobId,
      jobType: props.jobType,
      submitData: {
        requestTypeCode: props.ticketDetails.supportTicketType.replace(" ", "").trim().toLowerCase(),
        projectId: projectId,
        id: props.ticketDetails.incidentId,
        sysId: props.ticketDetails.sysId
      },
      watchListDataSource: props.watchListDataSource
    };

    setUploadState("uploadProcessing");

    const submitJobData = await JobService.submitJob(requestData);

    if (!submitJobData.ok) {
      NotificationService.showErrorToast("Failed to submit job to update request with attached files.");
    } else {
      await fetchJobStatus();
    }
  }

  const handleOnDataStateChanged = function(files: Array<IFileInfo>) {

    if (files.find(f => f.file.status === UploadFileStatus.UploadFailed) === undefined) {
      setUploadState("uploadComplete");
    }
  }

  const handleOnBeforeUpload = function() {
    setUploadState("uploading");
  }

  const attachFilesContent = props.openSlide ? (
    <div id="attach-files-container" className="attach-files-container">
      <AddAttachments
        jobId={jobId}
        jobType={props.jobType}
        ticketNumber={props.ticketDetails.incidentId}
        onUploadComplete={handleOnUploadComplete}
        onBeforeUpload={handleOnBeforeUpload}
        onDataStateChanged={handleOnDataStateChanged}
      />
    </div>
  ) : null;

  return <div className="attach-files-slide">
    <Slide direction="up" transitionEnterDuration={300} transitionExitDuration={300} className="slide-container">
      {attachFilesContent}
    </Slide>
  </div>
}

AddAttachmentsSlide.defaultProps = {
  jobType: JobTypeEnum.UpdateTicket // update ticket
}

export default AddAttachmentsSlide;