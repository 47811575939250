import React from "react";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import { DropDownList, DropDownListChangeEvent, ListItemProps } from "@progress/kendo-react-dropdowns";
import "./DropDownFilter.scss";

interface IProps<T> extends GridFilterCellProps {
  data: Array<T>;
  textField?: string;
  defaultSelectedOption?: T;
  valueField?:string;
}

type Props<T> = IProps<T>;

export class DropDownFilter<T> extends React.PureComponent<Props<T>> {
  constructor(props: Props<T>) {
    super(props);
  }

  itemRender(li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps): React.ReactNode {
    const itemChildren = <span className="list-item-wrapper">{li.props.children}</span>;

    return React.cloneElement(li, li.props, itemChildren);
  }

  render() {
    return (
      <div className="k-filtercell drop-down-filter-wrapper">
        <DropDownList
          data={this.props.data}
          onChange={this.onChange.bind(this)}
          itemRender={this.itemRender.bind(this)}
          dataItemKey={this.props.textField}
          textField={this.props.textField}
          value={this.props.value || this.props.defaultSelectedOption}
        />
        <button
          className="k-button k-button-icon k-clear-button-visible"
          title="Clear"
          disabled={!this.props.value}
          onClick={this.onClearButtonClick.bind(this)}
        >
          <span className="k-icon k-i-filter-clear" />
        </button>
      </div>
    );
  }

  onChange(event: DropDownListChangeEvent) {
    const value = this.props.valueField ? event.target.value[this.props.valueField] : event.target.value;
    this.props.onChange({
      value,
      operator: event.target.value !== null ? "eq" : "",
      syntheticEvent: event.syntheticEvent
    });
  }

  onClearButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    this.props.onChange({
      value: this.props.defaultSelectedOption || null,
      operator: "",
      syntheticEvent: event
    });
  }
}
