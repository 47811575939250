import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { AdvancedGrid } from "../../common/grid/AdvancedGrid";
import { GridCellProps, GridColumnProps, GridFilterCellProps, GridFilterChangeEvent } from "@progress/kendo-react-grid";
import { Button, Paper, Grid, CircularProgress } from "@material-ui/core";
import { ISsoUserRequest, ISsoUserGroup, ISsoUser } from "../../../models/UserModels";
import UserService from "../../../services/UserService";
import NotificationService from "../../../services/NotificationService";
import * as SessionStore from "../../../store/Session";
import { NavLink } from "react-router-dom";

import "./SsoGroupDetails.scss";
import { IApplicationState } from "../../../store";

interface IProps {
  match?: any;
  className?: string;
  gridToolbarContent?: JSX.Element;
}

interface IRefreshGridParams extends ISsoUserRequest {
  refreshToggle: boolean;
}

type Props = IProps & SessionStore.ISessionState;

const GridConfigurations = {
  PageSize: 100,
  OrderBy: "id",
  Ascending: "asc",
  Descending: "desc"
};

const startingGridParams: IRefreshGridParams = {
  skip: 0,
  take: GridConfigurations.PageSize,
  sort: [{ field: GridConfigurations.OrderBy, dir: "desc" }],
  refreshToggle: false
};


const SsoGroupDetails = (props: Props) => {
 
  const gridRef = useRef<AdvancedGrid<ISsoUser, IRefreshGridParams>>(null);
  const [hasError, setHasError] = useState(false);
  const [isGridLoading, setIsGridLoading] = useState(true);
  const [dataState, setDataState] = useState(startingGridParams);
  const [users, setUsers] = useState([] as Array<ISsoUser>);
  const [group, setGroup] = useState(null as ISsoUserGroup);
  const [objectId, setObjectId] = useState<number | null | undefined>(null)
  const filterTimeOut = useRef(null);

  const dateCell  = (props: GridCellProps) => {
    const d = new Date(props.dataItem[props.field])
    return <td>{d.toLocaleString()}</td>
  }

  const loginCell = (props: GridCellProps) => {
    if (!props.dataItem.eaUserId) {
      return <td><em title={props.dataItem.login}>{props.dataItem.login}</em></td>
    }

    return <td>
      <div className="sso-user-details">
        <div className="sso-user-name">
          <NavLink to={`/administration/userDetails/${props.dataItem.eaUserId}`}>
            <em title={props.dataItem.login}>{props.dataItem.login} </em>
          </NavLink>
        </div>
      </div>
    </td>;
  }

  useEffect(() => {
    fetchDataAsync(dataState);
  }, []);

  const columns = new Array<GridColumnProps>(
    { field: "login", title: "LOGIN", cell: props.sessionData.permissions.has("EpiqAdminGetUser") ? loginCell : null, sortable:false, headerClassName: "no-sort" },
    { field: "email", title: "EMAIL", sortable:false, headerClassName: "no-sort" },
    { field: "firstName", title: "FIRST NAME", sortable:false, headerClassName: "no-sort" },
    { field: "lastName", title: "LAST NAME", sortable:false, headerClassName: "no-sort" },
    { field: "status", title: "STATUS", sortable:false, headerClassName: "no-sort" },
    { field: "activated", title: "ACTIVATED", cell: dateCell, sortable:false, headerClassName: "no-sort" },
    { field: "id", title: "IDP ID", sortable:false, headerClassName: "no-sort" },
  );

  const fetchDataAsync = async (dataState: IRefreshGridParams, caller?: string) => {
    setIsGridLoading(true);

    const [userResults, groupResult] = await Promise.all([
      UserService.getSsoUsers(props.match.params.groupId), 
      UserService.getSsoGroup(props.match.params.groupId)
    ]);

    if (userResults.ok && groupResult.ok) {
      setUsers(userResults.data);
      setGroup(groupResult.data.remoteData);
      setObjectId(groupResult.data.eaObjectId);
      setIsGridLoading(false);
      setHasError(false);
    } else {
      console.log("Could not load grid.");
      setIsGridLoading(false);
      setHasError(true);
    }

    setDataState(dataState);
  }

  const gridFilterChange = (event: GridFilterChangeEvent) => {
    const newState = { ...dataState, filter: event.filter };
    setDataState(newState);

    if (filterTimeOut.current) {
      clearTimeout(filterTimeOut.current);
    }

    filterTimeOut.current = setTimeout(() => {
      gridRef.current.resetGridState(newState, false, true);
    }, 500);
  };

  const addAsResource = async () => {
    const result = await UserService.createEAUserGroupFromSso(props.match.params.groupId);

    if (result.ok) {
      NotificationService.showSuccessToast(`Successfully created resource for group.`);
      setObjectId(result.data);
    } else {
      NotificationService.showErrorToast("Failed to add this okta user group as resource");
    }
  };

  if (!group) {
    return (
      <Paper>
        <div className="embed-container spinner-wrapper">
          <CircularProgress />
        </div>
      </Paper>
    )
  }

  let addAsResourceButton = <></>;

  if (props.sessionData.permissions.has("EpiqAdminCreateOktaGroup") && objectId == null) {
    addAsResourceButton = <span className="dialog-btn">
      <Button variant="contained" className="btn-contined" color="primary" onClick={() => addAsResource()}>
        ADD AS RESOURCE
      </Button>
    </span>
  }

  return (
    <>
      <Paper className={`sso-group-detail-wrapper ${props.className}`}>
        <div className="sso-group-btns">
          {addAsResourceButton}
        </div>
        <div className="sso-group-detail-fields">
          <Grid container>
              <Grid item md={4} sm={6} xs={12}>
              <div className="sso-detail-info-row">
                  <strong> Name</strong> {group.profile.name}
                </div>
                <div className="sso-detail-info-row">
                  <strong> Id</strong> {group.id}
                </div>
                <div className="sso-detail-info-row">
                  <strong> Description</strong> {group.profile.description}
                </div>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <div className="sso-detail-info-row">
                  <strong> Created</strong> {(new Date(group.created)).toLocaleString()}
                </div>
                <div className="sso-detail-info-row">
                  <strong> Last Updated</strong> {(new Date(group.lastUpdated)).toLocaleString()}
                </div>
                <div className="sso-detail-info-row">
                  <strong> Is EA Resource</strong> {objectId ? "Yes" : "No"}
                </div>
              </Grid>
            </Grid>
        </div>
        <AdvancedGrid
          ref={(standardGridInstance: AdvancedGrid<ISsoUser, IRefreshGridParams>) => {
            gridRef.current = standardGridInstance;
          }}
          showErrorState={hasError}
          showLoadingIndicator={isGridLoading}
          data={users}
          dataFetch={fetchDataAsync}
          dataState={dataState}
          columns={columns}
          paging={false}
          noRecordsRender={<p>No Groups Found</p>}
          noLoadOnMount={false}
          filteredDataOnly={false}
          multiFieldFilterDelimiter="|"
          gridToolbarContent={props.gridToolbarContent}
          hidePaper={false}
          filter={dataState.filter}
          onFilterChange={gridFilterChange}
        />
      </Paper>
    </>
  );
}

export default connect(
  (state: IApplicationState) => ({
    ...state.sessionState
  }),
  null
)(SsoGroupDetails);