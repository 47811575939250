import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { AdvancedGrid, IGridParamsAdvancedRefreshable, IGridStateMerged } from "../../common/grid/AdvancedGrid";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import { GridCellProps, GridColumnProps, GridFilterCellProps, GridFilterChangeEvent } from "@progress/kendo-react-grid";
import { Button, Paper } from "@material-ui/core";
import { ISsoUserRequest, ISsoUserGroup } from "../../../models/UserModels";
import UserService from "../../../services/UserService";
import CommandCell from "../../common/grid/columns/CommandCell";
import * as SessionStore from "../../../store/Session";
import { NavLink } from "react-router-dom";
import { IApplicationState } from "../../../store";
import "./SsoGroupList.scss";
import CommonHelper from "../../common/utilities/CommonHelper";
import ResourceService from "../../../services/ResourceService";
import { IResourceParams, ResourceRequestFieldsToShow } from "../../../models/ResourceModels";
import { ServiceResponseJson } from "../../../services/ServiceBase";

export const kOktaGroupNoOp = "kOktaGroupNoOp";

interface IProps {
  match?: any;
  className: string;
  gridToolbarContent?: JSX.Element;
  selectionChange?: (selected: ISsoUserGroup[]) => void;
  restrictToUserIdpId?: string;
  addClickHandler?: () => void;
  additionalGroups?: ISsoUserGroup[];
  enableRemoveSsoGroup?: boolean;
  onRemoveUpdateSsoGroups?: (selected: ISsoUserGroup) => void;
  isOpen?:boolean;
  excludedGroupIds?: Array<string>;
  onLoadData?: (groups: ISsoUserGroup[]) => void;
}

interface IRefreshGridParams extends ISsoUserRequest {
  refreshToggle: boolean;
}

interface ISsoUserGroupSelectable extends ISsoUserGroup {
  selected?: boolean;
}

type Props = IProps & SessionStore.ISessionState;

const GridConfigurations = {
  PageSize: 100,
  OrderBy: "id",
  Ascending: "asc",
  Descending: "desc"
};

const startingGridParams: IRefreshGridParams = {
  skip: 0,
  take: GridConfigurations.PageSize,
  sort: [{ field: GridConfigurations.OrderBy, dir: "desc" }],
  refreshToggle: false
};

const getDefaultGroups = () => {
  return {items:[], merged:[], count:0, selections:{}} as IGridStateMerged<ISsoUserGroupSelectable>;
}

const SsoGroupList = (props: Props) => {
 
  const gridRef = useRef<AdvancedGrid<ISsoUserGroupSelectable, IRefreshGridParams>>(null);
  const [hasError, setHasError] = useState(false);
  const [isGridLoading, setIsGridLoading] = useState(true);
  const [dataState, setDataState] = useState(startingGridParams);
  const [groups, setGroups] = useState(getDefaultGroups());
  const filterTimeOut = useRef(null);

  const dateCell  = (props: GridCellProps) => {
    const d = new Date(props.dataItem[props.field])
    return <td>{d.toLocaleString()}</td>
  }

  const imageCell = (props: GridCellProps) => {
    if (props.dataItem["_links"] && props.dataItem["_links"].logo && props.dataItem["_links"].logo.length > 0) {
      return <td><img src={props.dataItem["_links"].logo[0].href} /></td>
    }

    return (<td> </td>);
  }

  const nameCell = (cellProps: GridCellProps) => {
    return <td>
      <div className="sso-group-details">
        <div className="sso-group-name">
          {props.sessionData.permissions.has("EpiqAdminGetSSOGroup") ?
            <NavLink to={`/administration/sso-group/${cellProps.dataItem.id}`}>
              <em title={cellProps.dataItem.profile.name}>{cellProps.dataItem.profile.name} </em>
            </NavLink>
            :
            <>{cellProps.dataItem.profile.name}</>
          }
        </div>
      </div>
    </td>;
  }

  useEffect(() => {
    if (props.isOpen !== false) {
      updateMergedState(groups.items, groups.selections);
    }
  }, [props.additionalGroups]);

  const updateMergedState = (ssoGroups: ISsoUserGroupSelectable[], preSelected: Record<number | string, ISsoUserGroupSelectable>) => {

    if (!ssoGroups) {
      ssoGroups = [];
    }

    for (let i = 0; i < ssoGroups.length; i++) {
      if (preSelected[ssoGroups[i].id]) {
        ssoGroups[i].selected = true;
      }
    }

    if (props.additionalGroups && props.additionalGroups.length) {
      const merged = CommonHelper.dedupeObjects<ISsoUserGroup>(props.additionalGroups, groups.items, 'id');
      setGroups({items: ssoGroups, merged, count: merged.length, selections: preSelected});
    } else {
      setGroups({items: ssoGroups, merged: ssoGroups, count: ssoGroups.length, selections: preSelected});
    }    
  }

  const selectRow = (dataItem: ISsoUserGroupSelectable) => {

    const changes = { ... groups };

    for (let i = 0; i < changes.items.length; i++) {
      if (dataItem.id === changes.items[i].id) {
        changes.items[i].selected = !changes.items[i].selected;
        if (!changes.items[i].selected) {
          delete changes.selections[changes.items[i].id];
        } else {
          changes.selections[changes.items[i].id] = changes.items[i];
        }
      }
    }

    setGroups(changes);

    props.selectionChange(Object.keys(changes.selections).map(e => changes.selections[e]));
  };

  const removeSsoGroup = (dataItem: ISsoUserGroupSelectable) => {
    let gs = [ ... groups.items ];

    for (let i = 0; i < gs.length; i++) {
      if (gs[i].id === dataItem.id) {
        gs.splice(i, 1);
        break;
      }
    }

    updateMergedState(gs, groups.selections);

    props.onRemoveUpdateSsoGroups(dataItem);
  };

  const columns = new Array<GridColumnProps>(
    { field: "profile.name", title: "GROUP NAME", filterable: true, filter: "text", cell: nameCell, sortable:false, headerClassName: "no-sort" },
    { field: "id", title: "ID", sortable:false, headerClassName: "no-sort", width:200 },
    { field: "profile.description", title: "DESCRIPTION", sortable: false, headerClassName: "no-sort", width:400  }
  );

  if (props.selectionChange) {
    columns.unshift({
      title: "",     
      cell: CommandCell({
        onSelection: selectRow,
        selectedField: "selected"
      }),
      sortable: false,
      headerClassName: "no-sort",
      width: 50
    });
  }

  if (props.enableRemoveSsoGroup && (props.sessionData.permissions.has("EpiqAdminUpdateUser")
    || /* AssignOktaGroup isn't an ideal check, but for now we wouldn't even see the record if we didn't have AssignOktaGroup while using this page as a non-EpiqAdmin */ 
      props.sessionData.permissions.has("AssignOktaGroup"))) {
    columns.push({
      title: "",
      cell: CommandCell({ onRemove: removeSsoGroup }),
      sortable: false,
      headerClassName: "no-sort",
      width: 50
    });
  }

  const fetchDataAsync = async (dataState: IRefreshGridParams, caller?: string) => {

    if (props.isOpen === false) {
      return;
    }

    setIsGridLoading(true);

    let groupName = null as string;
    if (dataState.filter && dataState.filter.filters && dataState.filter.filters.length) // For now we'll just assume we have one filter and that it should be the profile name filter. This stuff would get scrapped if/when the API takes in multi filter param
    {
      const filter = (dataState.filter.filters[0] as FilterDescriptor);
      groupName = filter.field === "profile.name" ? filter.value : null;
    }

    const clientAdmin = !props.sessionData.permissions.has("EpiqAdminGetUser");

    console.log("clientadmin", clientAdmin);

    const results = kOktaGroupNoOp === props.restrictToUserIdpId ?  { ok: true, data: []  }
      : await (clientAdmin && !props.restrictToUserIdpId ? resourceFetch(groupName) : userEndpointSSOFetch(groupName));

    if (results.ok) {
      setIsGridLoading(false);
      updateMergedState(results.data, groups.selections);
      if (props.onLoadData && !groupName){ /*User is not filtering, we just want to show the data on initial load.*/
        props.onLoadData(results.data);
      }
      setHasError(false);
    } else {
      console.log("Could not load grid.");
      setIsGridLoading(false);
      setHasError(true);
    }

    setDataState(dataState);
  }

  const userEndpointSSOFetch = async (groupName: string) => {
    return await props.restrictToUserIdpId ?
      (await UserService.getSsoGroupsByUser(dataState.take, groupName, props.restrictToUserIdpId, props.context.admin && !props.sessionData.permissions.has("EpiqAdminGetUser") ? props.context.admin.contextResourceGroupId : null)) :
      (await UserService.getSsoGroups(dataState.take, groupName));
  }

  const resourceFetch = async (groupName: string) => {

    const filters = [{
      field: "ResourceGroupId",
      operator: "eq",
      value: props.context.admin.contextResourceGroupId
    }] as Array<FilterDescriptor>;

    if (groupName) {
      filters.push({
        field: "Group Name",
        operator: "contains",
        value: groupName
      });
    }

    if (props.excludedGroupIds && props.excludedGroupIds.length) {
        props.excludedGroupIds.map((oktaId)=> {
          filters.push({
          field: "Okta Id",
          operator: "notin",
          value: oktaId
          });
        })        
      }

    const query: IGridParamsAdvancedRefreshable = {
      skip: 0,
      take: dataState.take,
      sort: [{ field: "Group Name", dir: "desc" }],
      refreshToggle: false,
      queryGroupsInfo: true,
      batchRequests: [
        {
          expandPermissions: true,
          onlyFields: ["PermissionCode", "Group Name", "Description", "Okta Id"],
          isDistinct: true,
          resourceFieldsToShow: ResourceRequestFieldsToShow.PermissionCode,
          batchFilters:
            [
              {
                logic: "and",
                filters: filters
              }
            ]
        }
      ]
    };

    var results = await ResourceService.getResourcesDetailsBatch(query, "oktagroup");

    if (!results.ok) {
      return new Promise<ServiceResponseJson>((resolve, reject) => {
        resolve(results);
      });
    }

    return new Promise<ServiceResponseJson>((resolve, reject) => {
      resolve({...results, data: (results.data.results as Array<any>).map(r => {
        const permissions = new Array<string>();

        if (r.fields["AssignOktaGroup"]) permissions.push(r.fields["AssignOktaGroup"]);
        if (r.fields["GetOktaGroup"]) permissions.push(r.fields["GetOktaGroup"]);

        return { profile: { name: r.fields["Group Name"], description: r.fields["Description"] }, id: r.fields["Okta Id"], permissions: permissions };
      })});
    });
  }

  const gridFilterChange = (event: GridFilterChangeEvent) => {
    const newState = { ...dataState, filter: event.filter };
    setDataState(newState);

    if (filterTimeOut.current) {
      clearTimeout(filterTimeOut.current);
    }

    filterTimeOut.current = setTimeout(() => {
      gridRef.current.resetGridState(newState, false, true);
    }, 500);
  };

  if (!props.context.admin && !props.sessionData.permissions.has("EpiqAdminGetUser")) {
    return (<><Paper className={`sso-group-list-wrapper ${props.className}`}>
      <div>You do not have permissions to view this</div>
    </Paper></>);
  }

  return (
    <>
      <Paper className={`sso-group-list-wrapper ${props.className}`}>
        <AdvancedGrid
          ref={(standardGridInstance: AdvancedGrid<ISsoUserGroupSelectable, IRefreshGridParams>) => {
            gridRef.current = standardGridInstance;
          }}
          showErrorState={hasError}
          showLoadingIndicator={isGridLoading}
          data={groups.merged}
          dataFetch={fetchDataAsync}
          dataState={dataState}
          columns={columns}
          paging={false}
          noRecordsRender={<p>No Okta Groups Found.</p>}
          noLoadOnMount={false}
          filteredDataOnly={false}
          multiFieldFilterDelimiter="|"
          gridToolbarContent={props.gridToolbarContent}
          hidePaper={false}
          filter={dataState.filter}
          onFilterChange={gridFilterChange}
          totalRecords={{ value: groups.merged.length, label: "Okta Groups" }}
        />
      </Paper>
    </>
  );
}

export default connect(
  (state: IApplicationState) => ({
    ...state.sessionState
  }),
  null
)(SsoGroupList);