import * as React from "react";
import { useEffect, useState } from "react";
import SpeedTestService from "../../services/SpeedTestService";
import { ISpeedTestResult } from "../../models/SpeedTestResultModel";
import InlineFeedback from "../administration/common/InlineFeedback";
import "./SpeedTest.scss";

interface ISpeedTestProps {
  height: number;
  width: number;
  speedTestUrl: string;
  speedTestFinishedCallback?: (result: ISpeedTestResult) => void;
  readonly?: boolean;
  hideSpeedTestCompleteMessage?: boolean;
  jobId?: string;
}

type Props = ISpeedTestProps;

const SpeedTest = (props: Props) => {
  const [showSpeedTestCompletedMessage, setShowSpeedTestCompletedMessage] = useState(false);
  const resultsHandler = async (event: any) => {
    //this will match on URL's that differ only with one containing a trailing /
    if (event.origin.startsWith(props.speedTestUrl) || props.speedTestUrl.startsWith(event.origin)) {
      const parsedResults = { ...JSON.parse(event.data).results, jobId: props.jobId } as ISpeedTestResult;

      if (!props.readonly) {
        await SpeedTestService.saveSpeedTestResult(parsedResults);
      }

      if (props.speedTestFinishedCallback) {
        props.speedTestFinishedCallback(parsedResults);
      }

      setShowSpeedTestCompletedMessage(true);
      
      return;
    }

    console.log("event origin and speed test URL mismatch, untrusted, no data will be handled.");
  }

  useEffect(() => {
    window.addEventListener("message", resultsHandler);

    return () => {
      window.removeEventListener("message", resultsHandler);
    };
  }, []);


  return (
    <>
      <div>
        <b>Click Start to run the speed test.</b>
      </div>
      <iframe height={props.height} width={props.width} src={props.speedTestUrl} />
      {!props.hideSpeedTestCompleteMessage && showSpeedTestCompletedMessage && <InlineFeedback result={{message: 'Speed test complete.  Review your results.', level: 'Success'}} />}
    </>
  );
};

export default SpeedTest;
