import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";

export interface IMainDrawerState {
  expanded: boolean;
}

interface ISetMainDrawerExpandedAction {
  type: "SET_MAIN_DRAWER_EXPANDED";
}

interface IGetMainDrawerExpandedAction {
    type: "GET_MAIN_DRAWER_EXPANDED";
    expanded: boolean;
}

type KnownAction = ISetMainDrawerExpandedAction | IGetMainDrawerExpandedAction;

export const actionCreators = {
  setMainDrawerExpanded: (): AppThunkAction<ISetMainDrawerExpandedAction> => (dispatch) => {
    dispatch({
      type: "SET_MAIN_DRAWER_EXPANDED"
    });
  },
  getMainDrawerExpanded: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const currentState = getState();

    if(currentState) {
    dispatch({
        type: "GET_MAIN_DRAWER_EXPANDED",
        expanded: currentState.mainDrawerState.expanded
      });
    }
  }
};

const unloadedState: IMainDrawerState = {
  expanded: true
};

export const reducer: Reducer<IMainDrawerState> = (state: IMainDrawerState | undefined, incomingAction: Action): IMainDrawerState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "SET_MAIN_DRAWER_EXPANDED":
        return {
            expanded: !state.expanded
        };
    case "GET_MAIN_DRAWER_EXPANDED":
        return {
            expanded: state.expanded
        };
    default:
        return state;
  }
};
