import { Employer } from "./EmployerModel";
import { IResource } from "./ResourceModels";


class NotificationResolvedModel {
  reEnableAppTile?: boolean;
  issueResolved?: boolean;
  issueResolvedTitle: string;
  issueResolvedMessage: string;
}

class MessageModel extends NotificationResolvedModel {
  notificationId: number = 0;
  agreementId: number = 0;
  name: string = "";
  messageTypeId: number = 0;
  startDate?: Date;
  endDate?: Date;
  active?: boolean;
  status: string = "";
  comment: string = "";
  triggerType: number = 0;
  title: string = "";
  message: string = "";
  noEndDate: boolean = false;
  forceLogoutOnDisagree: boolean = false;
  employers: string = "";
  domains: string = "";
  employerList: Employer[] = [];
  employerIds: number[] = [];
  startTime?: Date;
  endTime?: Date;
  notificationResourcesDetails: IResource[] = [];
  addResources: number[] = [];
  timezone: string = "";
  timezoneOffset: number = 0;
  repeat: boolean = false;
}

class CommonMessageModel extends MessageModel {
  resourceDelimeter?: string;
}


export interface IAgreementAcceptanceModel {
  agreementId: number;
  agreementEmployerId: number;
  title: string;
  message: string;
  name: string;
  forceLogoutOnDisagree: boolean;
}

export interface INotificationMessageModel {
  notificationId: number;
  title: string;
  message: string;
  name: string;
  active: boolean;
  repeat: boolean;
  startDate: Date;
  endDate: Date;
  createdDate: Date;
  lastmodifiedDate: Date;
  messageTypeId: number;
  issueResolved: boolean;
  issueResolvedTitle: string;
  issueResolvedMessage: string;
  postedResolvedMessage: boolean;
  notificationResourcesDetails: INotificationResource[];
}

export interface INotificationResource {
  resourceId: number;
  resourceName: string;
  objectId: number;
}
export interface IAuditProps {
  action: string;
  agreement: IAgreementAcceptanceModel;
  employerId: number;
}

enum MessageStatusEnum {
  Active = "Active",
  Ready = "Ready",
  Ended = "Ended",
  NotActive = "Not Active"
}

export interface IMessageTypeModel {
  id: number;
  name: string;
}

enum MessageTypeIdsEnum {
  DEFAULT = 0,
  EULA_AGREEMENT = 1,
  UPCOMING_MAINTENANCE = 2,
  URGENT_MAINTENANCE = 3
}

enum TriggerDisplayMessageEnum {
  DEFAULT = 0,
  EMPLOYER = 1,
  APP_INSTANCE = 2
}

enum NotificationPopupButtonLabelEnum {
  Close = "Close",
  ViewDetails = "View Details",
}


export { MessageModel, MessageStatusEnum, MessageTypeIdsEnum, CommonMessageModel, TriggerDisplayMessageEnum, NotificationPopupButtonLabelEnum };