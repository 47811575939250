import React from "react";
import { connect } from "react-redux";
import CommonHelper from "../../../common/utilities/CommonHelper";
import { UserGroup } from "../../../../models/UserModels";
import { IApplicationState } from "../../../../store";
import AdminService from "../../../../services/AdminService";
import { formatDate } from "@telerik/kendo-intl";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { GridColumnProps, GridFilterCellProps } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { NavLink } from "react-router-dom";
import { AdvancedGrid, IGridParams } from "../../../common/grid/AdvancedGrid";
import "./UserGroups.scss";
import { FilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import * as SessionStore from "../../../../store/Session";
import { DropDownFilter } from "../../../common/grid/filters/DropDownFilter";
import CommandCell from "../../../common/grid/columns/CommandCell";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const GridConfigurations = {
  PageSize: 500,
  OrderBy: "groupName",
  Ascending: "asc",
  Descending: "desc"
};

interface IProps {
  history: any;
}

interface IUserGroupFetchResult {
  totalUserGroups: number;
  userGroups: Array<UserGroup>;
}

type Props = IProps & SessionStore.ISessionState;

type State = {
  userGroups: UserGroup[];
  totalUserGroups: UserGroup[];
  retryCount: number;
  isUserGroupsExist: boolean;
  totalUserGroupsCount: number;
  isLoading: boolean;
  hasError: boolean;
  dataState: IGridParams;
  fetchingUserGroups: boolean;
  fetchResults: IUserGroupFetchResult;
  deleteConfirmPopup:false
};

class UserGroups extends React.PureComponent<Props, State> {
  columns: Array<GridColumnProps>;

  constructor(props: Props) {
    super(props);

    this.state = {
      userGroups: [],
      totalUserGroups: [],
      totalUserGroupsCount: 0,
      isUserGroupsExist: false,
      retryCount: 0,
      isLoading: true,
      hasError: false,
      dataState: {
        skip: 0,
        take: GridConfigurations.PageSize,
        sort: [{ field: GridConfigurations.OrderBy, dir: "asc" }]
      },
      fetchingUserGroups: false,
      fetchResults: {
        totalUserGroups: 0,
        userGroups: new Array<UserGroup>()
      },
      deleteConfirmPopup:false
    };

    this.columns = new Array<GridColumnProps>(
      { field: "groupName", title: "GROUP NAME", cell: this.props.sessionData.permissions.has("EpiqAdminGetUserGroup") ? this.userGroupCustomCell : this.userGroupCustomCellNoLink, filterable: true, filter: "text" },
      {
        field: "epiqOnly",
        title: "EPIQ Admin Only",
        cell: this.epiqAdminOnly,
        filterCell: (props: GridFilterCellProps) => (
          <DropDownFilter
            {...props}
            data={[{ name: "Epiq Admin", id: 1 }, { name: "Non-Epiq Admin", id: 0 }]}
            textField="name"
            defaultSelectedOption={null}
          />
        ),
        filterable: true,
        filter: "text"
      },
      { field: "createdDate", title: "DATE CREATED", cell: this.createdDateCustomCell, filterable: true, filter: "date" },
      { field: "comments", title: "DESCRIPTION", cell: this.descriptionCustomCell, filterable: true, filter: "text" },
    );
  }

  filterElements(element: any) {
    if (element.tagName === "EM") {
      return true;
    }
    return false;
  };

  async getUserGroups(dataState: IGridParams) {
    let isCreatedDateFilterExists = false;
    let createdDateValue: Date;
    const filters = dataState.filters
      ? dataState.filters.map(compositeFilter => {

        const epiqAdminOnlyFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "epiqOnly"
        ) as FilterDescriptor;

        if (epiqAdminOnlyFilter) {
          const epiqAdminOnlyFilterValue = epiqAdminOnlyFilter.value
            ? epiqAdminOnlyFilter.value.id == 0 || epiqAdminOnlyFilter.value.id == 1
              ? epiqAdminOnlyFilter.value.id == 0
                ? false
                : true
              : null
            : false;

          return {
            ...compositeFilter,
            filters: [
              {
                ...epiqAdminOnlyFilter,
                field: "epiqOnly",
                operator: "eq",
                value: epiqAdminOnlyFilterValue
              }
            ]
          };
        }

        const createdDateFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "createdDate"
        ) as FilterDescriptor;

        if (createdDateFilter) {
          const dateFilterValue = createdDateFilter.value;

          if (!isNaN(new Date(dateFilterValue).getFullYear()) && new Date(dateFilterValue).getFullYear() > 1970) {
            createdDateFilter.operator = 'eq';
          }

          createdDateValue = typeof dateFilterValue === "string" ? new Date("01/01/1901") : ((createdDateFilter.operator === 'isnull' || createdDateFilter.operator === 'isnotnull') ? null : new Date(dateFilterValue));

          if (createdDateFilter.operator === 'isnull' || createdDateFilter.operator === 'isnotnull') {
            return {
              ...compositeFilter,
              filters: [
                {
                  ...createdDateFilter,
                  field: "createdDate",
                  operator: typeof dateFilterValue === "string" && createdDateFilter.operator === 'isnotnull' ? "eq" : createdDateFilter.operator,
                  value: createdDateValue
                }
              ]
            };
          }

          isCreatedDateFilterExists = true;

          return {
            ...compositeFilter,
            filters: [
              {
                ...createdDateFilter,
                field: "createdDate",
                operator: "gte",
                value: new Date(createdDateValue.toUTCString())
              }
            ]
          };
        }

        return compositeFilter;
      })
      : [];

    if (isCreatedDateFilterExists) {
      filters.push({
        logic: "and",
        filters: [
          {
            field: "createdDate",
            operator: "lt",
            value: new Date(new Date(createdDateValue.setUTCDate(createdDateValue.getUTCDate() + 1)).toUTCString())
          }
        ]
      });
    }

    let reverseSortDescriptors: SortDescriptor[] = this.updateEpiqOnlySortDirection(dataState);

    this.setState({ ...this.state, fetchingUserGroups: true }, async () => {
      const result = await AdminService.getUserGroupList({ ...dataState, filters: filters, sort: reverseSortDescriptors });

      let updateSortDescriptors: SortDescriptor[] = this.updateEpiqOnlySortDirection(dataState);
      this.setState({ ...this.state, dataState: { ...dataState, sort: updateSortDescriptors } });

      this.setState({
        ...this.state,
        fetchingUserGroups: false,
        hasError: !result.ok,
        fetchResults: {
          ...this.state.fetchResults,
          userGroups: result.ok ? result.data.results : new Array<UserGroup>(),
          totalUserGroups: result.ok && result.data.results ? (result.data.results as Array<UserGroup>).length : 0
        }
      });
    });
  }

  handleCreateUserGroupClick() {
    this.props.history.push("/administration/userGroupDetails/0");
  };

  userGroupCustomCell(props: any) {
    return (
      <td>
        <div className="group-details">
          <div className="group-name">
            <NavLink to={`/administration/userGroupDetails/${props.dataItem.groupId}`}>
              <em title={props.dataItem.groupName}> {props.dataItem.groupName} </em>
            </NavLink>
          </div>
        </div>
      </td>
    );
  };

  userGroupCustomCellNoLink(props: any) {
    return (
      <td>
        <div className="group-details">
          <div className="group-name">
            <em title={props.dataItem.groupName}> {props.dataItem.groupName} </em>
          </div>
        </div>
      </td>
    );
  };

  createdDateCustomCell(props: any) {
    let date = "-";
    if (props.dataItem.createdDate) {
      let createdDate = props.dataItem.createdDate;
      let localDateTime = CommonHelper.convertUTCDateToLocalDate(createdDate);
      date = formatDate(localDateTime, "g");
    }
    return <td>{date}</td>;
  };

  descriptionCustomCell(props: any) {
    return <td className="description"> {props.dataItem.comments ? props.dataItem.comments : "-"} </td>;
  };

  gridToolBarRender(): JSX.Element {
    return (this.props.sessionData.permissions.has("EpiqAdminCreateUserGroup") && (
      <Button className="add-user-group-btn" icon={"plus"} primary={true} onClick={this.handleCreateUserGroupClick.bind(this)} >
        User Group
      </Button>)
    );
  }

  epiqAdminOnly(props: any) {
    return <td>{props.dataItem.epiqOnly ? "Epiq Admin" : "Non-Epiq Admin"}</td>;
  };

  updateEpiqOnlySortDirection = (dataState: IGridParams) => {
    const sort: SortDescriptor[] = dataState.sort ? [...dataState.sort] : [];
    return sort.map((item, index) => {

      if (item.field === "epiqOnly") {
        item.dir = item.dir === "asc" ? "desc" : "asc"
      }

      return item;
    });
  }

  render() {
    const { hasError, dataState, fetchingUserGroups, fetchResults } = this.state;
   
    return (

      <div className="user-group-list">
        <Tooltip openDelay={2} position="right" filter={this.filterElements}>
          <AdvancedGrid
            showErrorState={hasError}
            showLoadingIndicator={fetchingUserGroups}
            sortable={{ mode: "multiple" }}
            data={fetchResults.userGroups}
            dataFetch={this.getUserGroups.bind(this)}
            dataState={dataState}
            columns={this.columns}
            paging={false}
            totalRecords={{ value: fetchResults.totalUserGroups, label: "User Groups" }}
            noRecordsRender={<p>No user groups found.</p>}
            noLoadOnMount={true}
            filteredDataOnly={<p>Please filter to search for user groups.</p>}
            multiFieldFilterDelimiter="|"
            filterOperators={{
              text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
              date: [{ text: "grid.filterIsNotNullOperator", operator: "isnotnull" }, { text: "grid.filterIsNullOperator", operator: "isnull" }]
            }}
            gridToolbarContent={this.gridToolBarRender()}
            moreRecordsAvailableMessage="Too many user groups to display. Adjust filters to refine results"
          />
        </Tooltip>
      </div>
    );
  }
}

export default connect((state: IApplicationState) => ({ ...state.sessionState }), {})(UserGroups as any);
