class SecureUploadLocation {
  public id: number = 0;
  public name: string = "";
  public secureFormURL: string = "";
  public uploadDestination: string = "";
  public ftpSiteUrl: string = "";
  public speedTestUrl: string = "";

  static dedupeEnvs = (newEnvs: Environment[], existingEnvs: Environment[]): Environment[] => {
    const combined = (existingEnvs || []).concat(newEnvs || []);
    return combined.filter((env, idx, self) => self.findIndex(env2 => env2.id === env.id) === idx);
  };
}

export interface ISecureUploadUXState {
  jobId: number;
  formInitialized: boolean;
  formLocationId: number;
  unsuccessfulLoad: boolean;
  submittedSuccessfully: boolean;
  projectId: number;
  appDbId: string;
}

class UpsertLocationModel extends SecureUploadLocation {
  public addEnvironments: number[] = []; 
  public removeEnvironments: number[] = [];
}

class AppDBLocation {
  public EnviromentId: number = 0;
  public SecureUploadId: number = 0;
}

class Environment {
  public id: number = 0;
  public environmentLocation: string = "";
  public fiveTranSynced: Date;
  public selected: boolean = false;
}

class SecureUploadReqParams {
  public projectId: number;
  public appDbId: string;
}

export { SecureUploadLocation, UpsertLocationModel, AppDBLocation, Environment, SecureUploadReqParams };
