import React, { useState, useEffect, useRef } from "react";
import PermissionPopup from "./PermissionPopup";
import DropDownListItem from "../../../models/DropDownListItem";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { GridColumnProps } from "@progress/kendo-react-grid";
import { Permission, Role } from "../../../models/Role";
import "./PermissionsList.scss";
import { AdvancedGrid, IGridParams } from "../../common/grid/AdvancedGrid";
import AdminService from "../../../services/AdminService";
import { Button } from "@progress/kendo-react-buttons";
import CommandCell from "../../common/grid/columns/CommandCell";

interface Props {
  roleDetails: Role;
  savedPermissions: Permission[];
  savedDBPermissions?: Permission[];
  updateSelectedPermisions?: (permissions: Permission[], isFilterEnabled?: boolean, isPermissionRemoved?: boolean) => void;
  epiqOnly: boolean;
  permissions: Set<string>;
  isCreate?: boolean;
}

const gridProps: IGridParams = {
  skip: 0,
  take: 1000,
  sort: [{ field: "permissionName", dir: "asc" }]
};

const PermissionsList = (props: Props) => {
  const [rolePermissions, setRolePermissions] = useState(props.savedPermissions);
  const [filteredPermissions, setfilterPermissions] = useState([] as Permission[]);
  const [isPermissionSelectorOpen, setisPermissionSelectorOpen] = useState(false);
  const [dataState] = useState(gridProps as IGridParams);
  const [potentiallySelectedPermissions, setPotentiallySelectedPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isFilterExists, setIsFilterExists] = useState(false);

  const getRoleBasedPermissions = async (dataState: IGridParams) => {
    console.log(dataState);
    if (props.roleDetails.id === undefined) {
      setRolePermissions([]);

      if (props.updateSelectedPermisions) {
        props.updateSelectedPermisions([], false);
      }
    }
    else {
      setIsLoading(true);
      const response = await AdminService.getPermissionsListByRoleId(props.roleDetails.id, dataState);

      if (response.ok) {
        const permissions = response.data && response.data.results ? response.data.results : [];
        setRolePermissions(permissions);

        if (props.updateSelectedPermisions) {
          if (dataState.filters && (dataState.filters.length === 0 || dataState.sort.length === 0)) {
            props.updateSelectedPermisions(permissions, true);
          } else {
            props.updateSelectedPermisions(props.savedDBPermissions, false);
          }
        }        

        setHasError(false);
      }
      else {
        setHasError(true);
      }
      setIsLoading(false);
    }

    setIsFilterExists(dataState.filters && dataState.filters.length > 0);
  };

  useEffect(() => { }, [rolePermissions, filteredPermissions]);

  const addSelectedListItems = (selectedItems: DropDownListItem[]) => {
    const newPermissions: Permission[] = selectedItems.map((p) => {
      return {
        id: p.id,
        permissionName: p.name,
        description: p.description,
        ...p.restProps
      };
    });

    setPotentiallySelectedPermissions(newPermissions);
  };

  const closePermissionPopup = (accepted: boolean) => {
    if (accepted) {
      const filterCombinedPermissions = [...rolePermissions, ...potentiallySelectedPermissions];
      const combinedPermissions = [...props.savedPermissions, ...potentiallySelectedPermissions];
      setRolePermissions(filterCombinedPermissions);
      if (props.updateSelectedPermisions) {
        props.updateSelectedPermisions(combinedPermissions, false);
      }
    }

    setisPermissionSelectorOpen(false);
    setPotentiallySelectedPermissions([]);
  };

  const handleAddPermissions = () => {
    setisPermissionSelectorOpen(true);
  };

  const filterElements: any = (element: any) => {
    if (element.tagName === "EM") {
      return true;
    }
    return false;
  };

  const getSavedDropDownListItems = () => {
    return rolePermissions ? rolePermissions.map((p) => p.id) : ([] as number[]);
  };

  const descriptionCustomCell = (props: any) => {
    return <td className="description"> {props.dataItem.description ? props.dataItem.description : "-"} </td>;
  };

  const permissionPopup = (
    <PermissionPopup
      isPermissionSelectorOpen={isPermissionSelectorOpen}
      savedListItems={getSavedDropDownListItems()}
      addSelectedListItems={addSelectedListItems}
      closePermissionPopup={closePermissionPopup}
      roleDetails={props.roleDetails}
      addAvailable={potentiallySelectedPermissions.length > 0}
      epiqOnly={props.epiqOnly}
    />
  );

  const removePermission = (dataItem: any) => {
    let tempRolePermissions = rolePermissions.filter((permission) => permission.id !== dataItem.id);
    let permissions = props.savedPermissions.filter((permission) => permission.id !== dataItem.id)
    setRolePermissions(tempRolePermissions);
    props.updateSelectedPermisions(permissions, false, true);
  }

  const gridToolBarRender = (): JSX.Element => {
    return ((props.isCreate && props.permissions.has("EpiqAdminCreateRole")) || (!props.isCreate && props.permissions.has("EpiqAdminUpdateRole"))) && (
      <>
        <Button className="add-user-btn" icon={"plus"} primary={true} onClick={handleAddPermissions}>
          Permissions
        </Button>
      </>
    );
  }

  const columns = new Array<GridColumnProps>(
    {
      field: "permissionName",
      title: "PERMISSION NAME",
      filterable: true
    },
    {
      field: "description",
      title: "DESCRIPTION",
      cell: descriptionCustomCell,
      filterable: true
    },
    {
      title: "",
      cell: CommandCell({ onRemove: removePermission }),
      headerClassName: 'no-sort'
    }
  );

  return (
    //this class is being set to base-grid-wrapper for now until we convert all of this to standard grid
    <>
      <div>
        {permissionPopup}
        <div className="permission-list">
          <Tooltip openDelay={2} position="right" filter={filterElements}>
            <AdvancedGrid
              showErrorState={hasError}
              showLoadingIndicator={isLoading}
              sortable={{ mode: "multiple" }}
              data={rolePermissions}
              dataFetch={getRoleBasedPermissions}
              dataState={dataState}
              columns={columns}
              paging={false}
              totalRecords={{ value: rolePermissions.length, label: "Permissions" }}
              noRecordsRender={<p>{ !isFilterExists ? "No permissions added." : "No permissions found."}</p>}
              noLoadOnMount={true}
              filteredDataOnly={false}
              multiFieldFilterDelimiter="|"
              filterOperators={{
                text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
                date: [{ text: "grid.filterEqOperator", operator: "eq" }]
              }}
              gridToolbarContent={gridToolBarRender()}
            />
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default PermissionsList;
