import * as React from "react";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { GridCellProps } from "@progress/kendo-react-grid";
import { Grid } from "@material-ui/core";
import { AdvancedGrid } from "../common/grid/AdvancedGrid";
import { IResource } from "../../models/ResourceModels";
import GridHeader from "../common/grid/GridHeader";
import ResourceService from "../../services/ResourceService";
import { IJobDetailResourcesParams } from "../../models/JobModels";
import { useRef } from "react";

type IProps = {
  resourceGroupId: number;
};

const JobDetailsResources = (props: IProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [gridItems, setGridItems] = React.useState(null as Array<IResource>);
  const pendingRequest = useRef(null);
  const requestStarted = useRef(false);
  const dataState = {
    skip: 0,
    take: 500,
    sort: [{ field: 'name', order: 'asc' }]
  };

  const search = async (value: string) => {
    await getGridResults({ ...dataState, searchValue: value });
  }

  const getGridResults = async (dataState: IJobDetailResourcesParams) => {

    if (requestStarted.current) {
      clearTimeout(pendingRequest.current);
      pendingRequest.current = setTimeout(() => {
        getGridResults(dataState);
      }, 300);
      return;
    }

    requestStarted.current = true;
    setIsLoading(true);

    const searchValue = dataState && dataState.searchValue ? dataState.searchValue : null;

    var filter = searchValue != null ? [{
      logic: "or",
      filters: [{
        field: "name",
        operator: "contains",
        value: searchValue
      }]
    } as CompositeFilterDescriptor] as CompositeFilterDescriptor[] : [];

    const result = await ResourceService.getResourceGroupResources({ resourceGroupId: props.resourceGroupId, ...dataState, filters: filter });
   
    setHasError(!result.ok);
    setGridItems(result.data.results);

    setIsLoading(false);
    requestStarted.current = false;
  }

  const resourceName = (props: GridCellProps) => {
    return (
      <td>
        <span title={props.dataItem.name}>{props.dataItem.name}</span>
      </td>
    );
  }

  const columns = [{
    field: "name", title: "name", filterable: false, cell: resourceName,
    sortable: false,
    headerClassName: "no-sort"
  }];

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <GridHeader
            searchButton={false}
            searchDisabled={false}
            searchPlaceholder={"Search"}
            onChangeSearch={search}
            minSearchCharacters={3}
            headerTypeName={"Resources"}
            displayCount={gridItems ? gridItems.length : 0}
          />
          <div>
            <AdvancedGrid
              showErrorState={hasError}
              showLoadingIndicator={isLoading}
              data={gridItems}
              dataFetch={getGridResults}
              dataState={dataState}
              columns={columns}
              paging={false}
              noRecordsRender={<p>No information to display.</p>}
              noLoadOnMount={false}
              filteredDataOnly={false}
              multiFieldFilterDelimiter="|"
              total={gridItems ? gridItems.length : 0}
              moreRecordsAvailableMessage="Displaying first 500 items. Click View Details to refine results."
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default JobDetailsResources;