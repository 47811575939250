import { ServiceBase, ResponseMessages, ServiceResponseJson } from "./ServiceBase";


const getNotificationMessages: ResponseMessages = {
  FailedMessage: "Failed to get notification information.",
  SuccessMessage: "Successfully retrieved notification information."
};

export default class NotificationMessageService extends ServiceBase {

  static async getResouceNotificationMessages(abortSignal?: AbortSignal) {
    const url = `${NotificationMessageService.getBaseUri()}/getUserResourceNotifications`;

    const result: ServiceResponseJson = await fetch(url, ServiceBase.fetchOptions(abortSignal))
      .then(async (response) => await ServiceBase.handleResponseJson(response, getNotificationMessages))
      .catch(async (response) => await ServiceBase.handleResponseJson(response, getNotificationMessages));

    return result;
  }

  static getBaseUri() {
    return `${(<any>window).ApplicationVariables.apiUri}notificationmessages`;
  }
}