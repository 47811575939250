import { ServiceBase, ResponseMessages, ServiceResponseJson, ServiceResponse, ServiceResponseStr } from "./ServiceBase";
import { IGridParams as AdvancedGridParams } from "../components/common/grid/AdvancedGrid";
import CommonHelper from "../components/common/utilities/CommonHelper";
import { ITheme, IUpsertTheme } from "../models/Theme";

const GetThemesMessages: ResponseMessages = {
    FailedMessage: "Failed to get themes.",
    SuccessMessage: "Successfully retrieved themes."
};

const UpsertThemeMessages: ResponseMessages = {
    FailedMessage: "Upsert theme failed.",
    SuccessMessage: "Upsert theme succeeded."
  };

export default class ThemeService extends ServiceBase {
    static async getThemes(gridParams: AdvancedGridParams) {
        const filterJSON = JSON.stringify(gridParams.filters) || "";
        const sortJSON = JSON.stringify(gridParams.sort) || "";
        const url = `${ThemeService.getBaseThemeUri()}?${this.getFilterSortQueryString(
          filterJSON,
          sortJSON
        )}&${this.toQueryString(gridParams, ["filters", "sort", "filter"])}`;
    
        const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
          .then(async response => await this.handleResponseJson(response, GetThemesMessages))
          .catch(async response => await this.handleResponseJson(response, GetThemesMessages));

        if (result.ok) {
            result.data.results = result.data.results.map((result: ITheme): ITheme => {
                return {
                    ...result,
                    createdDate: new Date(result.createdDate),
                    lastModifiedDate: new Date(result.lastModifiedDate)
                };
                }
            );
        }
    
        return result;
    }

    static async getThemeDetails(themeId: number) {
        const url = `${ThemeService.getBaseThemeUri()}/getThemeDetails?themeId=${themeId}`;
        const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
          .then(async response => await this.handleResponseJson(response, GetThemesMessages))
          .catch(async response => await this.handleResponseJson(response, GetThemesMessages));

        return result;
    }

    static async updateTheme(theme: IUpsertTheme) {
        const result: ServiceResponseJson = await fetch(ThemeService.getBaseThemeUri(), {
          body: JSON.stringify(theme),
          ...this.fetchPutOptions()
        })
          .then(response => this.handleResponseJson(response, UpsertThemeMessages))
          .catch(response => this.handleResponseJson(response, UpsertThemeMessages));
    
        return result;
      }

    static async createTheme(theme: IUpsertTheme) {
        const result: ServiceResponseJson = await fetch(ThemeService.getBaseThemeUri(), {
          body: this.toPostBody(theme),
          ...this.fetchPostOptions()
        })
          .then(response => this.handleResponseJson(response, UpsertThemeMessages))
          .catch(response => this.handleResponseJson(response, UpsertThemeMessages));
    
        return result;
      }

    static getBaseThemeUri() {
        return `${(<any>window).ApplicationVariables.apiUri}theme`;
    }
}