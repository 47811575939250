import { IContextInfo } from "../../models/AdminContext";
import { Preferences } from "../../models/Preferences";
import { StorageApiBase, StorageType } from "./StorageApiBase";
import { IStyleOverrides } from "../../models/SiteBrandingModel";

class SessionStorageApi extends StorageApiBase {
  private readonly consoleSessionActionsCountKey = "consoleSessionActionsCount";
  private readonly consoleSessionTimeoutWarningKey = "consoleSessionTimeoutWarningActive";
  private readonly consoleSessionLostWarningKey = "consoleSessionLostWarningActive";
  private readonly consoleSessionActionsCountThreshold = 1000;
  private readonly consoleSessionPreferencesKey = "consoleSessionPreferences";
  private readonly consoleSessionSyncCountKey = "consoleSessionSyncCount";
  private readonly consoleSessionContextSyncCountKey = "consoleSessionContextSyncCountKey";
  private readonly consoleSessionImpersonatedUserKey = "consoleSessionImpersonatedUser";
  private readonly consoleSessionContextKey = "consoleSessionContext";
  private readonly consoleSessionBrandingKey = "consoleSessionBranding";
  private readonly consoleSessionClosedNotificationsKey = "consoleSessionClosedNotificationsKey";

  constructor(storageType: StorageType) {
    super(storageType);
  }

  getSessionTimeoutWarning(): boolean {
    return this.get(this.consoleSessionTimeoutWarningKey) == 1;
  }


  getSessionLostWarning(): boolean {
    return this.get(this.consoleSessionLostWarningKey) == 1;
  }

  getActionsCount(): number {
    return this.get(this.consoleSessionActionsCountKey);
  }

  hasPreferences(): boolean {
    return !!this.get(this.consoleSessionPreferencesKey);
  }

  getPreferences(): Preferences {
    return this.get(this.consoleSessionPreferencesKey) ? JSON.parse(this.get(this.consoleSessionPreferencesKey)) : null;
  }

  getContext(): IContextInfo {
    return this.get(this.consoleSessionContextKey) ? JSON.parse(this.get(this.consoleSessionContextKey)) : null;
  }

  getBranding(): IStyleOverrides {
    return this.get(this.consoleSessionBrandingKey) ? JSON.parse(this.get(this.consoleSessionBrandingKey)) : null;
  }

  getClosedNotifications(): Array<Number> {
    return this.get(this.consoleSessionClosedNotificationsKey) ? JSON.parse(this.get(this.consoleSessionClosedNotificationsKey)) : [];
  }

  getSyncCount(): string {
    return this.get(this.consoleSessionSyncCountKey) || null;
  }

  getContextSyncCount(): string {
    return this.get(this.consoleSessionContextSyncCountKey) || null;
  }

  getImpersonatedUser(): string {
    return this.get(this.consoleSessionImpersonatedUserKey);
  }

  initializeSessionTrackingStorage() {
    if (!this.exists(this.consoleSessionActionsCountKey)) {
      this.store([[this.consoleSessionActionsCountKey, 1]]);
    } else {
      this.incrementOrResetActionsCount();
    }

    if (!this.exists(this.consoleSessionTimeoutWarningKey)) {
      this.store([[this.consoleSessionTimeoutWarningKey, 0]]);
    }

    if (!this.exists(this.consoleSessionLostWarningKey)) {
      this.store([[this.consoleSessionLostWarningKey, 0]]);
    }
  }

  incrementOrResetActionsCount(): void {
    if (this.get(this.consoleSessionActionsCountKey) > this.consoleSessionActionsCountThreshold) {
      this.store([[this.consoleSessionActionsCountKey, 1]]);
    } else {
      this.incrementNumberValue(this.consoleSessionActionsCountKey);
    }
  }

  setSessionTimeoutWarning(active: boolean): void {
    this.store([[this.consoleSessionTimeoutWarningKey, active ? 1 : 0]]);
  }

  setSessionLostWarning(active: boolean): void {
    this.store([[this.consoleSessionLostWarningKey, active ? 1 : 0]]);
  }

  setPreferences(preference: Preferences): void {
    this.store([[this.consoleSessionPreferencesKey, JSON.stringify(preference)]]);
  }

  setContext(context: IContextInfo): void {
    this.store([[this.consoleSessionContextKey, JSON.stringify(context)]]);
  }

  setBranding(overrides: IStyleOverrides): void {
    this.store([[this.consoleSessionBrandingKey, JSON.stringify(overrides)]]);
  }

  setClosedNotifications(notificationIds: Array<Number>): void {
    if (notificationIds.length === 0) {
      this.clearByKey([this.consoleSessionClosedNotificationsKey]);
    } else {
      this.store([[this.consoleSessionClosedNotificationsKey, JSON.stringify(notificationIds)]]);
    }
  }

  setImpersonatedUser(impersonatedUser: string): void {
    if (impersonatedUser == null) {
      this.clearByKey([this.consoleSessionImpersonatedUserKey]);
    } else {
      this.store([[this.consoleSessionImpersonatedUserKey, impersonatedUser]]);
    }
  }

  incrementSyncCount(): void {
    if (this.get(this.consoleSessionSyncCountKey) == null) {
      this.store([[this.consoleSessionSyncCountKey, "0"]]);
    }
    else {
      this.incrementNumberValue(this.consoleSessionSyncCountKey);
    }
  }

  incrementContextSyncCount(): void {
    if (this.get(this.consoleSessionContextSyncCountKey) == null) {
      this.store([[this.consoleSessionContextSyncCountKey, "0"]]);
    }
    else {
      this.incrementNumberValue(this.consoleSessionContextSyncCountKey);
    }
  }

  clearPowerBiCode(code: string): void {
    this.store([["clearcmd-" + code, true]]);
  }
  retrieveClearCode(code: string) {
    if (this.exists("clearcmd-" + code)) {
      this.clearByKey(["clearcmd-" + code]);
      return true;
    } else {
      return false;
    }
  }
}

const sessionStorageApi = new SessionStorageApi(StorageType.Local);

export default sessionStorageApi;
