import React, { useState, useEffect, useRef } from "react";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { GridCellProps, GridColumnProps, GridFilterCellProps } from "@progress/kendo-react-grid";
import { AdvancedGrid, IGridParams } from "../../../common/grid/AdvancedGrid";
import AdminService from "../../../../services/AdminService";
import { Button } from "@progress/kendo-react-buttons";
import CommandCell from "../../../common/grid/columns/CommandCell";
import { EmployerDomain } from "../../../../models/EmployerModel";
import { DropDownFilter } from "../../../common/grid/filters/DropDownFilter";
import { Checkbox } from "@progress/kendo-react-inputs";
import CommonHelper from "../../../common/utilities/CommonHelper";
import { formatDate } from "@telerik/kendo-intl";
import "./Domains.scss";
import { CompositeFilterDescriptor, FilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import { connect } from "react-redux";
import * as SessionStore from "../../../../store/Session";
import { IApplicationState } from "../../../../store";
import { NavLink } from "react-router-dom";
import { filter } from "@progress/kendo-data-query/dist/npm/transducers";


type IProps = {
  history: any;
};

type Props = IProps & SessionStore.ISessionState;

const gridProps: IGridParams = {
  skip: 0,
  take: 1000,
  sort: [{ field: "domain", dir: "asc" }]
};

type State = {
  domainsList: EmployerDomain[];
  dataState: IGridParams;
  isFilterExists: boolean;
  isLoading: boolean;
  hasError: boolean;
};

class DomainsList extends React.Component<Props, State> {
  state: State;
  columns: Array<GridColumnProps>;
  constructor(props: Props) {
    super(props);

    this.state = {
      domainsList: [],
      dataState: gridProps,
      isFilterExists: false,
      isLoading: false,
      hasError: false
    };

    this.columns = new Array<GridColumnProps>(
      { field: "domain", title: "DOMAIN NAME", cell: this.domainNameCell, filterable: true, filter: "text" },
      {
        field: "federated", title: "FEDERATED", cell: this.isFederated, filterable: true,
        filterCell: (props: GridFilterCellProps) => (
          <DropDownFilter
            {...props}
            data={[{ name: "Federated", id: 1 }, { name: "Non-Federated", id: 0 }]}
            textField="name"
            defaultSelectedOption={null}
          />
        )
      },
      {
        field: "treatAsFederated", title: "TREAT AS FEDERATED", cell: this.treatAsFederated, filterable: true,
        filterCell: (props: GridFilterCellProps) => (
          <DropDownFilter
            {...props}
            data={[{ name: "Treat As Federated", id: 1 }, { name: "Treat As Non-Federated", id: 0 }]}
            textField="name"
            defaultSelectedOption={null}
          />
        )
      },
      { field: "employerName", title: "EMPLOYER NAME", cell: props.sessionData.permissions.has("EpiqAdminGetEmployer") ? this.employerNameCellLink : this.employerNameCell, filterable: true, filter: "text" },
      { field: "id", title: "DOMAIN ID", filterable: true, filter: "numeric" },
      { field: "createdDate", title: "DATE CREATED", format: "{0:g}", filterable: true, filter: "date", cell: this.createdDateCustomCell });
  }

  updateFederatedSortDirection = (dataState: IGridParams) => {
    const sort: SortDescriptor[] = dataState.sort ? [...dataState.sort] : [];
    return sort.map((item, index) => {

      if (item.field === "federated") {
        item.dir = item.dir === "asc" ? "desc" : "asc"
      }

      return item;
    });
  }

  getModifiedCorrectDateFilter = (
    gridFilters: Array<CompositeFilterDescriptor>,
    dateColumnName: string = "createdDate"
  ) => {
    // This function is used for date column filter in all advance grid pages.
    let isCreatedDateFilterExists = false;
    let createdDateValue: Date;
    const filters = gridFilters
      ? gridFilters.map(compositeFilter => {
        const createdDateFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === dateColumnName
        ) as FilterDescriptor;

        if (createdDateFilter) {
          const dateFilterValue = createdDateFilter.value;

          if (dateFilterValue === "") {
            let index = compositeFilter.filters.findIndex((p) => p === createdDateFilter);
            if (index >= 0) {
              compositeFilter.filters.splice(index, 1);
              return { ...compositeFilter };
            }
          }


          if (!isNaN(new Date(dateFilterValue).getFullYear()) && new Date(dateFilterValue).getFullYear() > 1970) {
            createdDateFilter.operator = 'eq';
          }

          createdDateValue = typeof dateFilterValue === "string" ? new Date("01/01/1901") : ((createdDateFilter.operator === 'isnull' || createdDateFilter.operator === 'isnotnull') ? null : new Date(dateFilterValue));

          if (createdDateFilter.operator === 'isnull' || createdDateFilter.operator === 'isnotnull') {
            return {
              ...compositeFilter,
              filters: [
                {
                  ...createdDateFilter,
                  field: dateColumnName,
                  operator: typeof dateFilterValue === "string" && createdDateFilter.operator === 'isnotnull' ? "eq" : createdDateFilter.operator,
                  value: createdDateValue
                }
              ]
            };
          }

          isCreatedDateFilterExists = true;

          return {
            ...compositeFilter,
            filters: [
              {
                ...createdDateFilter,
                field: dateColumnName,
                operator: "gte",
                value: new Date(createdDateValue.toUTCString())
              }
            ]
          };
        }

        const numericFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "id"
        ) as FilterDescriptor;

        if (numericFilter) {
          const isFilterValueString = numericFilter.value && typeof (numericFilter.value) === "string"

          if (isFilterValueString) {
            return {
              ...compositeFilter,
              filters: [
                {
                  ...numericFilter,
                  value: -1
                }
              ]
            };
          }
          else if (isFilterValueString === null) {
            return {
              ...compositeFilter,
              filters: [
                {
                  ...numericFilter,
                  operator: "gte",
                  value: 0
                }
              ]
            };
          }

        }

        return compositeFilter;
      })
      : [];

    if (isCreatedDateFilterExists) {
      filters.push({
        logic: "and",
        filters: [
          {
            field: dateColumnName,
            operator: "lt",
            value: new Date(new Date(createdDateValue.setUTCDate(createdDateValue.getUTCDate() + 1)).toUTCString())
          }
        ]
      });
    }

    return filters;
  };

  getFederatedDomainFilter = (
    gridFilters: Array<CompositeFilterDescriptor>,
    field: string = "federated"
  ) => {
    const filters = gridFilters
      ? gridFilters.map(compositeFilter => {
        const federatedFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === field
        ) as FilterDescriptor;

        if (federatedFilter) {
          const federatedFilterValue = federatedFilter.value
            ? (federatedFilter.value.id == 0 || federatedFilter.value.id == 1
              ? (federatedFilter.value.id == 0
                ? false
                : true)
              : null)
            : false;


          return {
            ...compositeFilter,
            filters: [
              {
                ...federatedFilter,
                field: field,
                operator: "eq",
                value: federatedFilterValue
              }
            ]
          };
        }

        return compositeFilter;
      }) : [];

    return filters;
  };

  getDomainsList = async (dataState: IGridParams) => {

      let filters = this.getModifiedCorrectDateFilter(dataState.filters);
      filters = this.getFederatedDomainFilter(filters, "federated");
    filters = this.getFederatedDomainFilter(filters, "treatAsFederated");

      if (filters && filters.length > 0) {
        let compositeFilters = new Array<CompositeFilterDescriptor>();

        filters.forEach(compositeFilter => {
          if (compositeFilter.filters.length > 0) {
            let federatedFilter: FilterDescriptor = compositeFilter.filters.find(
              filter => (filter as FilterDescriptor).value !== ""
            ) as FilterDescriptor;

            if (federatedFilter) {
              compositeFilters.push(compositeFilter);
            }
          }
        });

        filters = compositeFilters;

      }

      this.setState({ isFilterExists: filters && filters.length > 0, isLoading: true });

      const result = await AdminService.getDomainsList({ ...dataState, filters: filters });

      if (result) {
        const domains = result.data && result.data.results ? result.data.results as EmployerDomain[] : [];
        this.setState({ ...this.state, domainsList: domains, hasError: false });
      }
      else {
        this.setState({ ...this.state, hasError: true });
      }

      this.setState({ ...this.state, isLoading: false });
  }

  domainNameCell = (cellProps: GridCellProps) => {
    const domain = cellProps.dataItem.domain;
    return (
      <td className="domain-cell">
        <em title={domain}>
          {domain}
        </em>
      </td>
    );
  }

  isFederated = (cellProps: GridCellProps) => {
    return (
      <td>
        <input
          type="checkbox"
          name="federated"
          checked={cellProps.dataItem.federated === undefined ? false : cellProps.dataItem.federated}
          disabled={true}
        />
      </td>
    );
  };

  treatAsFederated = (cellProps: GridCellProps) => {
    return (
      <td>
        <input
          type="checkbox"
          name="treatasfederated"
          checked={cellProps.dataItem.treatAsFederated === undefined ? false : cellProps.dataItem.treatAsFederated}
          disabled={true}
        />
      </td>
    );
  };

  createdDateCustomCell = (props: GridCellProps) => {
    let date = "";
    let createdDate = props.dataItem.createdDate;

    if (createdDate) {
      let localDateTime = CommonHelper.convertUTCDateToLocalDate(createdDate);
      date = formatDate(localDateTime, "g");
    }

    return <td>{date}</td>;
  }

  employerNameCellLink = (props: GridCellProps) => {
    const empName = props.dataItem.employerName;
    const empId = props.dataItem.employerId;
    return (
      <td className="emp-name">
        <NavLink to={`/administration/employerDetails/${empId}`}>
          <em title={empName}>{empName}</em>
        </NavLink>
      </td>
    );
  }

  employerNameCell = (props: GridCellProps) => {
    const empName = props.dataItem.employerName;
    return (
      <td className="emp-name">
        <em title={empName}> {empName} </em>
      </td>
    );
  }

  filterElements: any = (element: any) => {
    if (element.tagName === "EM") {
      return true;
    }
    return false;
  };

  render() {
    const { hasError, isLoading, domainsList, dataState, isFilterExists } = this.state;

    return (
      //this class is being set to base-grid-wrapper for now until we convert all of this to standard grid
      <div className="domain-list-wrapper">
        <Tooltip openDelay={2} position="right" filter={this.filterElements}>
          <AdvancedGrid
            showErrorState={hasError}
            showLoadingIndicator={isLoading}
            data={domainsList}
            dataFetch={this.getDomainsList}
            dataState={dataState}
            columns={this.columns}
            paging={false}
            totalRecords={{ value: domainsList.length, label: "Domains" }}
            noRecordsRender={<p>{isFilterExists ? "No domains found." : "No domains added."}</p>}
            noLoadOnMount={true}
            filteredDataOnly={<p>Please filter to search for domains.</p>}
            multiFieldFilterDelimiter="|"
            filterOperators={{
              text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
              date: [{ text: "grid.filterIsNotNullOperator", operator: "isnotnull" }, { text: "grid.filterIsNullOperator", operator: "isnull" }],
              numeric: [{ text: "grid.filterEqOperator", operator: "eq" }]
            }}
          />
        </Tooltip>
      </div>
    );
  }

}

export default connect((state: IApplicationState) => ({ ...state.sessionState }), {
})(DomainsList as any);
