import React, { useState, useEffect, useRef, ReactElement } from "react";
import { Application } from "../../../models/ApplicationModels";
import { IGridParams } from "../../common/grid/BaseGrid";
import AdminService from "../../../services/AdminService";
import { GridColumnProps } from "@progress/kendo-react-grid";
import CommandCell from "../../common/grid/columns/CommandCell";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { AdvancedGrid } from "../../common/grid/AdvancedGrid";

type Props = {
  selectedApplications?: Application[] | undefined;
  handleSelectedApplicationsChange: (selectedApplications: Application[]) => void;
};

const gridParams: IGridParams = {
  skip: 0,
  take: 500,
  sort: [{ field: "name", dir: "asc" }]
};

const ApplicationList = (props: Props) => {
  const [applications, setApplications] = useState<Application[]>([]);
  const [count, setCount] = useState<number>(0);
  const [selectedApplications, setSelectedApplications] = useState<Application[]>([...props.selectedApplications]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [dataState, setDataState] = useState(gridParams);

  useEffect(() => {
    
  },[]);

  const fetchApps = async (dataState: IGridParams) => {
    setIsLoading(true);
    
    const result = await AdminService.getAppResourcesList({ ...dataState });
    if (result.ok) {
      const apps = result && result.data && result.data.map((app: Application) => {
        
        const selectedApp = selectedApplications && selectedApplications.find(a => a.id == app.id);
        if (selectedApp) {
          app.selected = true;
        }
        return app;
      });
      setApplications(apps);
      setCount(apps.count);
      setDataState(dataState);

    } else {
      setHasError(true);
      console.log("Something went wrong while fetching applications.");
    }

    setIsLoading(false);

  }

  const selectRow = (dataItem: Application) => {
    console.log(dataItem);
    const selectedApps = [...selectedApplications];
    let isSelected = false;

    if (selectedApps.some(a => a.id == dataItem.id)) {
      selectedApps.splice(selectedApps.findIndex(a => a.id == dataItem.id), 1);
    }
    else {
      isSelected = true;
      selectedApps.push(dataItem);
    }

    setSelectedApplications([...selectedApps]);
    setApplications(applications.map(app => app.id === dataItem.id ? { ...app, selected: isSelected } : app));

    props.handleSelectedApplicationsChange(selectedApps);
  };

  const columns = new Array<GridColumnProps>(
    {
      title: "", cell: CommandCell({ onSelection: selectRow, selectedField: "selected" }),
      sortable: false,
      headerClassName: "no-sort"
    },
    { field: "name", title: "APP NAME", filterable: true },
    { field: "instanceName", title: "INSTANCE NAME", filterable: true, filter: "text" },
    { field: "url", title: "WEBSITE URL", filterable: true, filter: "text" },
  );

  return (
    <div className="applications-grid-wrapper">
      <Tooltip openDelay={2} position="top">
        <AdvancedGrid
          showErrorState={hasError}
          showLoadingIndicator={isLoading}
          data={applications}
          dataFetch={fetchApps}
          dataState={dataState}
          columns={columns}
          paging={false}
          noRecordsRender={<p>No applications found.</p>}
          noLoadOnMount={false}
          filteredDataOnly={false}
          selectedField="selected"
          filterOperators={{
            text: [{ text: "grid.filterContainsOperator", operator: "contains" }]
          }}
        />
      </Tooltip>
    </div>
  );

}

export default ApplicationList;
