export interface ITheme {
    id: number;
    name: string;
    data: Array<IThemeRuleSection>;
    description: string;
    createdDate: Date;
    createdBy: number;
    lastModifiedDate: Date;
    lastModifiedBy: number;
}

export interface IThemeRule {
    style: string;
    value: string;
}

export interface IThemeRuleSection {
    name: string;
    rules: Array<IThemeRule>;
}

// TODO put this back after we update typescript
// export interface IUpsertTheme extends Omit<ITheme, 'data'> {
//     data: Array<IUpsertThemeRuleSection>;
// }

export interface IUpsertTheme {
    id: number;
    name: string;
    data: Array<IUpsertThemeRuleSection>;
    description: string;
    createdDate: Date;
    createdBy: number;
    lastModifiedDate: Date;
    lastModifiedBy: number;
}

export interface IUpsertThemeRuleSection {
    name: string;
    rules: Array<IThemeRule>;
    selectors: Array<string>;
}

export interface IThemeReferenceInfo {
    selectors: Array<string>;
}

export interface IThemeReference {
    [key: string]: IThemeReferenceInfo
}

const themeReference = {
    "Main Menu": {
        selectors: [".main-drawer-wrapper .main-drawer-container .k-drawer"]
    },
    "Main Menu Selected": {
        selectors: [".main-drawer-wrapper .main-drawer-container .k-drawer .k-state-selected"]
    }
} as IThemeReference;

export {themeReference};
