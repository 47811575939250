import { CircularProgress, Paper } from "@material-ui/core";
import React from "react";
import { Employer, EmployerDomain, UpsertEmployerModel } from "../../../models/EmployerModel";
import EmployerDomains from "./EmployerDomains";
import EmployerInfo from "./EmployerInfo";
import { connect } from "react-redux";
import * as HeaderStore from "../../../store/Header";
import * as WarningMessageStore from "../../../store/WarningMessage";
import * as SessionStore from "../../../store/Session";
import AdminService from "../../../services/AdminService";
import { IGridParams } from "../../common/grid/AdvancedGrid";
import NotificationService from "../../../services/NotificationService";
import { IApplicationState } from "../../../store";
import CommonHelper from "../../common/utilities/CommonHelper";
import { differenceWith } from "lodash";


interface IRefreshGridParams extends IGridParams {
  refreshToggle: boolean;
}

interface IProps {
  setHeaderButtons: any;
  match?: any;
  history: any;
}

enum Mode {
  Edit,
  Create,
  View
}

const EmployerConfigurations = {
  MinimumCharacters: 3
};

type Props = IProps & HeaderStore.IHeaderState &
  typeof HeaderStore.actionCreators &
  WarningMessageStore.IWarningMessageState &
  typeof WarningMessageStore.actionCreators &
  SessionStore.ISessionState;

const gridProps: IGridParams = {
  skip: 0,
  take: 1000,
  sort: [{ field: "domain", dir: "asc" }]
};

type State = {
  mode: Mode;
  updateModel: UpsertEmployerModel;
  initializing: boolean;
  isFormValid: boolean;
  selectedDomains: EmployerDomain[];
  savedDomains: EmployerDomain[];
  deletedDomains: EmployerDomain[];
  finalDomainsList: EmployerDomain[];
  hasEpiqOnlyError: boolean;
  isEmpAlreadyExist: boolean;
  isUpdateSaved: boolean;
  employerDetails: Employer;
  savedEmployerDetails: Employer;
  isLoading: boolean;
  hasError: boolean;
  dataState: IGridParams;
};

class EmployerDetails extends React.Component<Props, State> {
  state: State;
  constructor(props: Props) {
    super(props);

    const mode = Number(this.props.match.params.employerId) > 0 ? Mode.Edit : Mode.Create;

    this.state = {
      mode,
      updateModel: new UpsertEmployerModel(),
      initializing: true,
      isFormValid: mode !== Mode.Create,
      selectedDomains: [],
      savedDomains: [],
      deletedDomains: [],
      finalDomainsList:[],
      hasEpiqOnlyError: false,
      isEmpAlreadyExist: false,
      isUpdateSaved: false,
      employerDetails: new Employer(),
      savedEmployerDetails: new Employer(),
      isLoading: false,
      hasError: false,
      dataState: gridProps
    };
  }

  async componentDidMount() {
    await this.props.setHeaderButtons(this.getBtnsList(true), "", "/administration/employers", "Back to employer list");
    await this.ensureDataFetched();
  }

  private async ensureDataFetched() {
    if (this.state.mode === Mode.Edit) {
      const employerId: number = Number(this.props.match.params.employerId);

      const employerDetailsResult = await AdminService.getEmployerDetails(employerId);

      this.setState({...this.state, isLoading: true});
      if (!employerDetailsResult.ok) {
        await this.props.setHeaderButtons(null);
        await this.props.saveUnsavedChanges(null, false);
        this.setState({ ...this.state, hasError: true });
        this.props.history.push("/administration/employers");
      } else {
        const employerDetails = employerDetailsResult.data ? employerDetailsResult.data : new Employer();
        this.setState({
          updateModel: CommonHelper.convertPropsToType(employerDetails, UpsertEmployerModel),
          employerDetails: employerDetails,
          savedEmployerDetails: CommonHelper.cloneObject(employerDetails),
          initializing: false,
          isLoading: false,
          hasError: false
        });
      }
    }
    else {
      this.setState({ initializing: false });
    }

    this.props.saveUnsavedChanges(JSON.stringify(this.state.employerDetails), false);
  }

  getEmployerDomains = async (dataState: IGridParams) => {
    const result = await AdminService.getEmployerDomainListById(this.props.match.params.employerId, { ...dataState });

    if (result) {
      const domains = result.data && result.data.results ? result.data.results : [];
      this.setState({ ...this.state, savedDomains: domains, selectedDomains: domains});
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
    if (prevState !== this.state) {
      const empDetailsIsDirty = this.employerDetailsIsDirty();
      const domainsListIsDirty = this.domainsListIsDirty();
      this.props.updateUnsavedChanges(empDetailsIsDirty || domainsListIsDirty);
    }
  }

  getBtnsList: any = (isSaveDisabled: boolean, isSaving: boolean) => {
    const buttons: any = [
      {
        buttonLabel: "Cancel",
        type: "button",
        handleClick: this.handleCancelClick
      },

      {
        buttonLabel: "Save",
        type: "button",
        disabled: isSaveDisabled,
        handleClick: this.handleSaveClick,
        color: "primary",
        isSaving: isSaving
      }
    ];
    return buttons;
  };

  handleCancelClick = () => {
    this.props.history.push("/administration/employers");
  };

  handleSaveClick = async () => {
    const requestModel = this.state.updateModel;
    const employer = { ...requestModel, employerDomains: this.state.selectedDomains };
    await this.props.setHeaderButtons(this.getBtnsList(true, true), "", "/administration/employers", "Back to employer list");
    if (requestModel.id == 0) {
      const result = await AdminService.createEmployer(employer);

      if (result.ok) {
        const errorMessage = result.data && result.data.inValid === true && result.data.message ? result.data.message : "";

        if (errorMessage && errorMessage.toLocaleLowerCase() === "employer already exists.") {
          this.setState({ isEmpAlreadyExist: true });
          await this.props.setHeaderButtons(this.getBtnsList(true, false), "", "/administration/employers", "Back to employer list");
        }
        else {
          this.getEmployerDomains(this.state.dataState);
          const empObj = CommonHelper.cloneObject(this.state.savedEmployerDetails) as Employer;
          empObj.name = employer.name;
          this.setState({ savedEmployerDetails: empObj,});
          NotificationService.showSuccessToast(`${employer.name} Created.`);
          this.props.saveUnsavedChanges(null, false);
          this.props.history.push("/administration/employers");
        }
      }
      else {
        NotificationService.showErrorToast("Something went wrong. Employer was not created.");
      }
    }
    else {
      const result = await AdminService.updateEmployer(employer);
      if (result.ok) {
        const errorMessage = result.data && result.data.inValid === true && result.data.message ? result.data.message : "";

        if (errorMessage && errorMessage.toLocaleLowerCase() === "employer already exists.") {
          this.setState({ isEmpAlreadyExist: true });
        }
        else {
          this.getEmployerDomains(this.state.dataState);
          this.setState({ isUpdateSaved: true, savedEmployerDetails: employer, employerDetails: employer });
          await this.props.saveUnsavedChanges(JSON.stringify(this.state.employerDetails), false);
          NotificationService.showSuccessToast("Update saved.");
        }

        await this.props.setHeaderButtons(this.getBtnsList(true, false), "", "/administration/employers", "Back to employer list");
      }
      else {
        NotificationService.showErrorToast("Something went wrong.Update not saved.");
        await this.props.setHeaderButtons(this.getBtnsList(true, false), "", "/administration/employers", "Back to employer list");
      }
    }
  };

  updateEmpDetails = (emp: Employer) => {
    const updateModel = CommonHelper.convertPropsToType(emp, UpsertEmployerModel);
    this.setState({ updateModel: updateModel, employerDetails: emp });
  };

  componentWillUnmount() {
    this.props.setHeaderButtons(null);
    this.props.saveUnsavedChanges(null, false);
  }

  updateSelectedDomains = (selectedSavedDomains: EmployerDomain[], isFilterExists: boolean) => {
    this.setState({ ...this.state, selectedDomains: selectedSavedDomains }, this.toggleSave);
  };

  updateSavedDomains = (updatedDomains: EmployerDomain[], isFilterExists: boolean, dataState: IGridParams) => {
    this.setState({ ...this.state, savedDomains: updatedDomains, selectedDomains: updatedDomains, dataState: dataState }, this.toggleSave);
  }

  handleEmployerEdited(emp: Employer, isValid: boolean) {    
    const newEmpName = emp.name;
    const editedEmpDetails: Employer = {
      ...this.state.employerDetails,
      name: newEmpName,
      epiqClient:emp.epiqClient,
      epiqDept: emp.epiqDept,
      entityType: emp.entityType,
      description: emp.description
    };

    const isEmpAlreadyExist = this.state.updateModel.name.toLowerCase() === newEmpName.toLowerCase() && this.state.isEmpAlreadyExist ? this.state.isEmpAlreadyExist : false;

    this.setState({
      ...this.state,
      updateModel: CommonHelper.convertPropsToType(editedEmpDetails, UpsertEmployerModel),
      isFormValid: isValid,
      employerDetails: editedEmpDetails,
      isEmpAlreadyExist
    }, this.toggleSave);
  }

  handleEmployerEditCancelled = (emp: Employer) => {
    this.setState({
      ...this.state, updateModel: CommonHelper.convertPropsToType(emp, UpsertEmployerModel),
      employerDetails: CommonHelper.cloneObject(emp)
    }, this.toggleSave);
  }

  employerDetailsIsDirty() {
    return this.props.initialState !== JSON.stringify(this.state.employerDetails as Employer);
  }

  domainsListIsDirty() {
    const { selectedDomains, savedDomains } = this.state;

    const selDomains = selectedDomains ? selectedDomains : [];
    const savedDoms = savedDomains ? savedDomains : [];

    if (selDomains.length !== savedDomains.length) return true;

    const areEqual = differenceWith(selDomains, savedDoms, (value1, value2) => {
      return (value1.domain === value2.domain && value1.treatAsFederated === value2.treatAsFederated);
    });

    return areEqual.length > 0;
  }

  private toggleSave() {
    const dirtyDomainssList = this.domainsListIsDirty();
    const canSave =
      (this.employerDetailsIsDirty() && this.isFormValidationsPassed())
      || (!this.employerDetailsIsDirty() && this.isFormValidationsPassed() && dirtyDomainssList);

    this.props.setHeaderButtons(this.getBtnsList(!canSave), "", "/administration/employers", "Back to employers list");
  }

  private validateDomains = (domains: EmployerDomain[]) => {
    return domains.length > 0;
  }

  isFormValidationsPassed = () => {
    const {
      updateModel,
      isFormValid,
      isEmpAlreadyExist
    } = this.state;
    const isEmpNameValidationPassed =
      updateModel.name && isEmpAlreadyExist === false
        ? updateModel.name.length >= EmployerConfigurations.MinimumCharacters
        : false;

    const isDomainValidationPassed = this.validateDomains(this.state.selectedDomains);

    return (
      (isEmpNameValidationPassed && isFormValid && isDomainValidationPassed)
    );
  };

  resetViewMode = (isEmpInfoInEditMode: boolean) => {
    this.setState({ isUpdateSaved: isEmpInfoInEditMode });
  };

  render() {

    const { mode, initializing, employerDetails, savedEmployerDetails, isEmpAlreadyExist, isUpdateSaved, isLoading, hasError, savedDomains } = this.state;
    let employerInfo;
    if ((mode !== Mode.Create && isLoading) || initializing) {
      employerInfo = (
        <Paper className="employer-grid-wrapper">
          <div className="employer-loader">
            <CircularProgress />
          </div>
        </Paper>
      );
    }
    else if (hasError) {
      employerInfo = (
        <Paper className="emp-details-wrapper">
          <div className="no-emp">
            <p> Oops! Something went wrong. </p>
            <p>Please check your network connection and refresh the page.</p>
          </div>
        </Paper>
      );
    }
    else {
      employerInfo = (
        <>
          <EmployerInfo
            employer={this.state.mode === Mode.Edit ? this.state.employerDetails : new Employer()}
            savedEmployerDetails={savedEmployerDetails}
            isEmployerNameExist={isEmpAlreadyExist}
            isUpdateSaved={isUpdateSaved}
            createEmployer={mode === Mode.Create}
            permissions={this.props.sessionData.permissions}
            onEmployerEdited={this.handleEmployerEdited.bind(this)}
            resetViewMode={this.resetViewMode}
          />

          <EmployerDomains employerDetails={employerDetails}
            permissions={this.props.sessionData.permissions}
            updateSelectedDomains={this.updateSelectedDomains}
            updateSavedDomains={this.updateSavedDomains}
            isCreate={mode === Mode.Create}
            savedDomains={savedDomains}
            employerDomains={this.state.selectedDomains}
            navigateToEmpList={this.handleCancelClick}
            isSuperAdmin={this.props.sessionData.superAdmin}
          />
        </>)
    }

    return (
      <Paper className="emp-detais-wrapper">
        {employerInfo}
      </Paper>
    );
  }
}

export default connect((state: IApplicationState) => ({ ...state.headerState, ...state.warningMessageState, ...state.sessionState }), {
  ...HeaderStore.actionCreators,
  ...WarningMessageStore.actionCreators
})(EmployerDetails as any);