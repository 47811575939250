import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { IGridParams } from "../components/common/grid/StandardGrid";
import { VoteStatus } from "./JobModels";
import { User } from "./UserModels";

export interface IApproval {
  id: number;
  message: string;
  title: string;
  status: number;
  payload: string;
  createdDate: Date;
}

export interface IApprovalForUser {
  id: number;
  title: string;
  message: string;
  status: number;
  createdDate: Date;
  userApprovalResponseId: number;
  approverIsListed: boolean;
  createdByUser: User;
  approvalType: approvalTypes;
  approversList: IApproverDetails[];
  comment: string;
  rejectionType: Array<number>;
  mappedRejectionType: Array<string>;
  incidentNumber: string;
  approvedByUser: User;
}

interface IApproverDetails {
  approverUserId: number;
  approverUserEmail: string;
  approverResponseDate: Date;
  approverResponseId: number;
}

export interface IApprovalForUserDetails extends IApprovalForUser {
  payloadUnpacked?: any;
}

export interface ISetApprovalRequest {
  approvalId: number;
  responseId: number;
  comment: string;
  rejectionType: Array<number>;
}

export interface IApprovalParams extends IGridParams {
  filters?: Array<CompositeFilterDescriptor>;
  statusIds?: Array<number>;
  approvalsToTrack?: Array<number>;
  canVote: boolean;
  responseId: number;
}

export interface IApprovableStatus extends IApprovalForUser {
  voteStatus?: VoteStatus;
}

export enum approvalStatus {
  pending = 1,
  approved = 2,
  rejected = 3,
  expired = 4
}

export enum approvalResponseTypes {
  approved = 1,
  rejected = 2
}

export enum approvalTypes {
  newResource = 1,
  newUsers = 2,
}