import { cloneDeep } from "lodash";
import { IFieldData, IFieldValueLookup, IWizardDependencyCondition, IWizardDependencyConditionDescriptor, IWizardRequest, IWizardSection, WizardFieldTypes } from "../../../models/Wizard";
import WizardHelper from "./WizardHelper";

interface IErrorMessage {
  [conditionKey: string]: string;
}

interface IConditionErrorMessages {
  [conditionFieldId: string]: IErrorMessage;
}

export interface IActiveErrorMessages {
  [fieldId: string]: IConditionErrorMessages;
}

class WizardStateManager {
  public values: IFieldValueLookup;
  public validationMsgs: IActiveErrorMessages;
  public page: number = 0;
  public resetDepSet: Record<string, Set<string>>;

  constructor(values?: IFieldValueLookup) {
    this.values = values || {};
    this.validationMsgs = {};
    this.resetDepSet = {};
  }

  public updateFields(updates: IFieldValueLookup) {
    this.values = Object.assign(this.values, updates);
  }

  public resetValidationFields(errorMessages: IActiveErrorMessages) {
    this.validationMsgs = Object.assign(this.validationMsgs, errorMessages);
  }

  public updateResetDepSetAndValidations(sections: Array<IWizardSection>) {
    let dependencyFields = {} as Record<string, Set<string>>;

    sections.forEach(section => {
      section.fields.forEach(field => {

        if (this.values[field.fieldId]) {
          //--- reset field
          if (field.resetForField) {
            const spiltResetFieldIds = field.resetForField.split(',');
            let newDeps = { ...dependencyFields };
            spiltResetFieldIds.forEach((resetFieldId: string, i: number) => {
              let deps = dependencyFields[resetFieldId];
              if (!deps) {
                deps = new Set();
              }
              deps.add(field.fieldId);
              newDeps[resetFieldId] = deps
            });
            dependencyFields = newDeps;
          }

          //--- validation field
          if (field.validation) {
            const insertedConditionValue = this.values[field.fieldId].value;
            this.dependencyStatus(field.validation, this.values, field.fieldId, insertedConditionValue);
          }
        }

      });
    });

    this.resetDepSet = Object.assign(this.resetDepSet, dependencyFields);
  }

  public dependencyStatus(
    dependency: IWizardDependencyConditionDescriptor | IWizardDependencyCondition,
    valDictionary?: IFieldValueLookup,
    fieldIdToLogValidationMsgFor?: string,
    insertedConditionValue?: WizardFieldTypes
  ): boolean {
    if (!dependency) return true;

    valDictionary = valDictionary || this.values;

    const descr = dependency as IWizardDependencyConditionDescriptor;

    if (descr.logic) {
      let allowed = true;

      const needsAll = descr.logic === "and";

      for (let i = 0; i < descr.subConditions.length; i++) {
        allowed = this.dependencyStatus(
          descr.subConditions[i],
          valDictionary,
          fieldIdToLogValidationMsgFor,
          insertedConditionValue
        );
        if (allowed && !needsAll) {
          return true; // 'or' succeeds
        }

        if (!allowed && needsAll) {
          return false;
        }
      }
      return allowed;
    }

    const condition = dependency as IWizardDependencyCondition;

    if (
      condition.inactiveSetsResult &&
      (!valDictionary[condition.fieldId] || !valDictionary[condition.fieldId].active)
    ) {
      return true;
    } else if (
      condition.inactiveSetsResult === false &&
      (!valDictionary[condition.fieldId] || !valDictionary[condition.fieldId].active)
    ) {
      return false;
    }

    const fieldValue = valDictionary[condition.fieldId] || { value: null as WizardFieldTypes, active: true };

    if (!fieldValue.active && (condition.compare !== "neq")) {
      // we will short curcuit 'neq' because *inactive* would mean that the value is *not equal to* ('neq') anything in the condition
      return false;
    }

    let value = null as WizardFieldTypes;

    if (condition.lookupName) {
      value = fieldValue.lookupValues ? fieldValue.lookupValues[condition.lookupName] : null;
    } else {
      value = fieldValue.value;
    }

    if (value === undefined) {
      value = null;
    }

    const isValid = WizardHelper.isConditionValid(value, condition, insertedConditionValue);
    if (fieldIdToLogValidationMsgFor && (
      (valDictionary[condition.fieldId] && valDictionary[condition.fieldId].isDirty) || // if this is a field to compare against don't validate against until we enter data for it
      (!condition.fieldId && condition.value != null) // validation when against own value
    )) {
      this.updateValidationChanges(fieldIdToLogValidationMsgFor, condition, isValid);
    }

    return isValid;
  };

  extractFieldData(sections: Array<IWizardSection>, objectCreator: (lookup: IFieldValueLookup) => IWizardRequest) {
    const submittedFieldData = {} as IFieldValueLookup;
    const auditData = [] as Array<IWizardSection>;

    sections.forEach(section => {
      if (!this.dependencyStatus(section.dependency)) {
        return;
      }
      const auditInfo = cloneDeep(section);

      auditData.push(auditInfo);

      auditInfo.fields.forEach(field => {
        if (this.dependencyStatus(section.dependency)) {
          field.value = this.values[field.fieldId] ? this.values[field.fieldId].value : null;
          submittedFieldData[field.fieldId] = this.values[field.fieldId];
        }
      });
    });
    const wrequest = objectCreator(submittedFieldData);
    const requestData = { wrequest: wrequest, auditDataInfo: auditData };
    return { ...requestData };
  }

  getWithActiveStatusUpdates(fields: Array<IFieldData>, valDictionary: IFieldValueLookup) {
    if (!fields) return valDictionary;

    fields.forEach(f => {
      const isDepSatisifed = this.dependencyStatus(f.dependency, valDictionary);

      if (isDepSatisifed && !WizardHelper.isFieldActive(f, valDictionary)) {
        if (valDictionary[f.fieldId]) valDictionary[f.fieldId].active = true;
        else valDictionary[f.fieldId] = { value: f.value == undefined ? null : f.value, active: true };
      } else if (!isDepSatisifed && WizardHelper.isFieldActive(f, valDictionary) && valDictionary[f.fieldId]) {
        valDictionary[f.fieldId].active = false;
      }
    });

    return valDictionary;
  };


  conditionKey(condition: IWizardDependencyCondition) {
    return `${condition.fieldId}-${condition.compare}-${condition.value != null ? condition.value.toString() : "NULL"}`;
  };

  updateValidationChanges(fieldId: string, condition: IWizardDependencyCondition, isValid: boolean) {
    if (!condition.failureMessage) return;

    const msgsForField = this.validationMsgs[fieldId] || {};
    const conditionFieldId = condition.fieldId || fieldId;
    const key = this.conditionKey(condition);

    if (!isValid && msgsForField[conditionFieldId]) {
      msgsForField[conditionFieldId][key] = condition.failureMessage;
    } else if (!isValid) {
      msgsForField[conditionFieldId] = { [key]: condition.failureMessage };
    } else if (isValid && msgsForField[conditionFieldId] && msgsForField[conditionFieldId][key]) {
      delete msgsForField[conditionFieldId][key];
    }

    if (msgsForField[conditionFieldId] && Object.keys(msgsForField[conditionFieldId]).length === 0) {
      delete msgsForField[conditionFieldId];
    }

    if (Object.keys(msgsForField).length === 0) {
      this.validationMsgs[fieldId] && delete this.validationMsgs[fieldId];
    } else {
      this.validationMsgs[fieldId] = msgsForField;
    }
  };

  public hasRequiredFields(page: IWizardSection, valDictionary: IFieldValueLookup) {
    for (let i = 0; i < page.fields.length; i++) {
      if (!page.fields[i].requiredWhenActive) {
        continue;
      }

      const currentFieldStats = valDictionary[page.fields[i].fieldId];
      const value = currentFieldStats ? currentFieldStats.value : null;
      const isActive = currentFieldStats ? currentFieldStats.active : this.dependencyStatus(page.fields[i].dependency, valDictionary);

      if (isActive && (Array.isArray(value) ? (value as Array<any>).length === 0 : (value == null || value.toString().trim() == "")))
        return true;
    }

    return false;
  }

  public hasInValidFields(page: IWizardSection, validationMsgs: IActiveErrorMessages) {
    if (!validationMsgs) {
      return false;
    }

    for (let i = 0; i < page.fields.length; i++) {
      const currentFieldStats = validationMsgs[page.fields[i].fieldId];
      if (currentFieldStats) {
        return true;
      }

    }

    return false;
  }
}

export default WizardStateManager;