import React from "react";
import { StandardWizard, IWizardStepProps } from "../../common/wizard/StandardWizard";
import FileUploadStep, { IFileInfo } from "./FileUploadStep";
import SingleSelectDropdown, { ISingleSelectDropSelectedEvent, IStandardFilterParams } from "../../common/SingleSelectDropDown";
import { ComboBoxChangeEvent, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import ResourceService, { IResourceGroupPermission } from "../../../services/ResourceService";
import { IResourceGroups } from "../../../models/ResourceModels";
import "./FileValidation.scss";

type Props = {
    onFinished: () => void;
    allGroups: boolean;
};

type State = {
    validationComplete: boolean;
    filesReadyForValidation: boolean;
    resourceGroupId: number;
    activeStep: number;
    isFileInvalid: boolean;
};

class FileUploadWizard extends React.Component<Props, State> {
    uploadWizardSteps: Array<IWizardStepProps>;

    constructor(props: Props) {
        super(props);

        this.state = {
            validationComplete: false,
            filesReadyForValidation: false,
            resourceGroupId: null,
            activeStep: 0,
            isFileInvalid: false
        };

        this.uploadWizardSteps = new Array<IWizardStepProps>(
            {
                stepConfig: {
                    label: "Select Resource",
                    isValid: undefined,
                    validator: () => (this.state.resourceGroupId !== null)
                },
                stepContent: (
                    <div>
                        <h2>Select Resource Group</h2>
                        <p>Select the resource group to assign the files to.  All files you upload in the next step will be assigned to this resource group.</p>
                        <SingleSelectDropdown
                            getItems={this.getResourceItems.bind(this)}
                            textToRequestConverter={(searchText: string) => ({ searchText, take: 100, skip: 0 })}
                            typeToListConverter={this.props.allGroups ? this.resourceGroupResultsConverterAdmin() : this.resourceGroupResultsConverter()}
                            onChange={(selected: ISingleSelectDropSelectedEvent<IResourceGroups & IResourceGroupPermission>, e: ComboBoxChangeEvent | DropDownListChangeEvent) => {
                                this.setState({ ...this.state, resourceGroupId: selected && (selected.data.resourceGroupId || selected.data.id) || null })
                            }}
                            disableFiltering={!this.props.allGroups}
                        />
                    </div>
                )
            },
            {
                stepConfig: {
                    label: "Select & Upload Files",
                    isValid: undefined,
                    validator: () => (this.state.validationComplete),
                },
                stepContent: () => { return (<FileUploadStep resourceGroupId={this.state.resourceGroupId} onFilesReadyForValidation={this.onFilesReadyForValidation.bind(this)} onValidationComplete={this.onValidationComplete.bind(this)} />); }
            }
        )
    }

    handleOnStepChanged(activeStep: number) {
        //check if we are going backwards, if so we need to reset state stuff. 
        if (this.state.activeStep > activeStep) {
            this.setState({ ...this.state, activeStep, validationComplete: false, resourceGroupId: null, filesReadyForValidation: false, isFileInvalid: false });
        } else {
            this.setState({ ...this.state, activeStep });
        }
    }

    resourceGroupResultsConverterAdmin() {
        return (resourceItem: Array<IResourceGroups & IResourceGroupPermission>) => resourceItem.map(item => {
            return {
                id: item.id,
                text: item.groupName,
                data: item
            }
        });
    }

    resourceGroupResultsConverter() {
        return (resourceItems: Array<IResourceGroups & IResourceGroupPermission>) => resourceItems.map(resourceItem => {
            return {
                id: resourceItem.resourceGroupId,
                text: resourceItem.resourceGroupName,
                data: resourceItem
            }
        });
    }

    async getResourceItems(filter: IStandardFilterParams) {
        if (this.props.allGroups) {
            return await ResourceService.getResourceGroups(filter);
        } else {
            return await ResourceService.getGroupPermissions("UploadFile");
        }
    }

    onFilesReadyForValidation() {
        this.setState({ ...this.state, filesReadyForValidation: true });
    }

    onValidationComplete(invalidFiles: Array<IFileInfo>) {
      (invalidFiles.length === 0) ? this.setState({ ...this.state, validationComplete: true }) : this.setState({ ...this.state, isFileInvalid: true });
    }

    render() {
      const lastStepButtonLabel = !this.state.filesReadyForValidation || (this.state.filesReadyForValidation && this.state.validationComplete) || this.state.isFileInvalid ? "Finish" : "Validating";
        
        return (
            <StandardWizard
                steps={this.uploadWizardSteps}
                lastStepButtonLabel={lastStepButtonLabel}
                onSubmitClick={this.props.onFinished}
                onStepChanged={this.handleOnStepChanged.bind(this)}
                disablePreviousStep={false}
            />
        );
    }
}

export default FileUploadWizard;