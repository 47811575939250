enum StorageType {
  Local,
  Session
}

const storageProvider: any = {
  local: localStorage,
  session: sessionStorage
};

class StorageApiBase {
  private readonly storage: any = null;

  constructor(storageType: StorageType) {
    this.storage = storageProvider[StorageType[storageType].toLowerCase()];
  }

  protected clearByKey(keys: Array<string>) {
    keys.forEach(value => {
      this.storage.removeItem(value);
    });
  }

  protected get(key: string): any {
    return this.storage.getItem(key);
  }

  protected exists(key: string): boolean {
    return this.storage[key];
  }

  protected store(items: Array<[string, any]>) {
    items.forEach(value => {
      this.storage.setItem(value[0], value[1]);
    });
  }

  protected incrementNumberValue(key: string) {
    this.storage[key]++;
  }
}

export { StorageType, StorageApiBase };
