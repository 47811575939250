import "babel-polyfill";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import { SessionManager } from "./SessionManager";
import JobManager from "./JobManager";
import ReportManager from "./ReportManager";
import App from "./App";
import { ApplicationStateManager } from "./AppStatusManager";

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

//Exporting the store to make ti available to non React.Component things.  This should never be directly imported into a React.Component
//instead always use redux with connect to get access to state
export const store = configureStore();

// declare as singleton
new ApplicationStateManager(store);

ReactDOM.render(
  <Provider store={store}>
    <App sessionManager={new SessionManager(store)} jobManager={new JobManager(store)} reportManager={new ReportManager(store)} />
  </Provider>,
  document.getElementById("root")
);
