import React, { useState, useEffect } from "react";
import {
  MultiSelect,
  MultiSelectChangeEvent,
  MultiSelectFilterChangeEvent,
  ListItemProps
} from "@progress/kendo-react-dropdowns";
import { User } from "../../../../models/UserModels";
import AdminService from "../../../../services/AdminService";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import { TagData } from "@progress/kendo-react-dropdowns/dist/npm/MultiSelect/TagList";
import NotificationService from "../../../../services/NotificationService";
import Tooltip from "@material-ui/core/Tooltip";
import CommonHelper from "../../../common/utilities/CommonHelper";
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import bgMessages from '../../../../Localization/en.json';
loadMessages(bgMessages, 'en-US');

type Props = {
  users?: User[] | undefined;
  appId?: number;
  typeName?: string;
  onAddUpdateUsers?: (selected: User[]) => void;
  selectedAppUsers: User[];
  appName?: string;
};

const AddUsersToApp = (props: Props) => {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [isLodaing, setIsLoading] = useState(false);
  const [openDropDown, SetOpenDropDown] = useState(false);

  useEffect(() => { }, [users]);

  const handleChange = async (event: MultiSelectChangeEvent) => {
    const values = event.target.value;
    const lastItem = values[values.length - 1];
    const filteredUsers = users.filter(item => item !== lastItem);
    setUsers(filteredUsers);
    setSelectedUsers(values);
    props.onAddUpdateUsers(values.length > 0 ? values : []);
    SetOpenDropDown(values.length > 0);
  };

  const itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = usersBackground(itemProps.dataItem);
    return React.cloneElement(li, li.props, itemChildren);
  };

  const tagRender = (tagData: TagData, li: React.ReactElement<HTMLLIElement>) =>
    React.cloneElement(li, li.props, [
      <Tooltip
        key={"addusertooltip" + tagData.data[0].id}
        title={tagData.data[0].username}
        placement="top-start"
        arrow
        className="email-tool-tip"
      >
        <span key={"addusername" + tagData.data[0].id} id={tagData.data[0].id}>
          {" "}
          {tagData.data[0].fullName}{" "}
        </span>
      </Tooltip>,

      li.props.children[1]
    ]);

  let UserAppName = props.appName ? props.appName : "<ApplicationName>";

  const filterChange = (event: MultiSelectFilterChangeEvent) => {
    let searchValue = event.filter.value;
    if (!CommonHelper.isNullOrWhiteSpace(searchValue) && searchValue.length >= 3) {
      setUsers([]);
      getUsers(setUsers, searchValue.trim());
    }
    if (searchValue.length === 0) {
      SetOpenDropDown(false);
    }
  };

  const removeSelectedUsers = (searchResults: any, selectedUsers: User[]) => {
    for (let i = searchResults.length - 1; i >= 0; i--) {
      for (let j = 0; j < selectedUsers.length; j++) {
        if (searchResults[i] && searchResults[i].id === selectedUsers[j].id) {
          searchResults.splice(i, 1);
        }
      }
    }
    return searchResults;
  };

  const getUsers = async (setUsers: React.Dispatch<React.SetStateAction<User[]>>, values: any) => {
    const params = {
      pageNumber: 0,
      searchText: values,
      pageSize: 100
    };
    setIsLoading(true);
    try {
      const result = await AdminService.getUser(params);

      if (result.ok) {
        let searchResults = result.data.results.map((dataItem: any) =>
          Object.assign({ fullName: dataItem.lastName + ", " + dataItem.firstName }, dataItem)
        );

        let selectedUserList = selectedUsers && selectedUsers.length > 0 ? selectedUsers : new Array<User>(); // Selected users in the popup
        selectedUserList = props.selectedAppUsers && props.selectedAppUsers.length > 0 ? selectedUserList.concat(props.selectedAppUsers) : selectedUserList; // Concatenate already selected users from the grid
        searchResults = removeSelectedUsers(searchResults, selectedUserList); // remove already selected users from search results.
        setUsers(searchResults);
      }

      setIsLoading(false);
      SetOpenDropDown(true);
    } catch (e) {
      setUsers([]);
      NotificationService.showErrorToast("Something went wrong while getting users.");
    } finally {
      setIsLoading(false);
    }
  };

  const formattedlastlogindate = (userlastlogindate?: Date) => {
    return userlastlogindate
      ? CommonHelper.convertUTCDateToLocalDateString(userlastlogindate, "MMDDYYY", "/")
      : "-";
  };

  const focusedItemIndex = (data: any, inputText: string, textField?: string) => {
    let text = inputText.toLowerCase();
    const index = data.findIndex((item: any) =>
      String(textField ? item[textField] : item)
        .toLowerCase()
        .includes(text.trim())
    );

    return index > -1
      ? index
      : data.findIndex((item: any) =>
        String(textField ? item["username"] : item)
          .toLowerCase()
          .includes(text.trim())
      );
  };

  const usersBackground = (dataItem: any) => {
    const fullName = dataItem.fullName;
    const username = dataItem.username;
    const lastLoginDate = formattedlastlogindate(dataItem.lastLoginDate);

    return (
      <div key={dataItem.id} className="dd-item-details">
        <Grid container>
          <Grid item md={9} sm={9} className="user-name-wrapper">
            <div className="dd-item-name">
              <em title={fullName}>{fullName}</em>
            </div>
            <p className="email">
              {" "}
              <em title={username}>{username}</em>{" "}
            </p>
          </Grid>
          <Grid item md={3} sm={3} className="last-login-wrapper">
            <div className="last-login-container">
              <p className="last-login-label">Last Login</p>
              <p className="last-login">
                {" "}
                <em title={lastLoginDate}>{lastLoginDate}</em>{" "}
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <div>
      <Grid container className="user-create">
        <Grid item xs={12}>
          <h6>
            Search and add users to <span>{UserAppName}</span>{" "}
          </h6>
          <div className="multi-select-wrapper">
            <span className="search-wrapper">
              <SearchIcon />
            </span>
            <LocalizationProvider language="en-US">
              <IntlProvider locale="en" >
                <MultiSelect
                  data={users}
                  onChange={handleChange}
                  filterable={true}
                  itemRender={(li, itemprops) => itemRender(li, itemprops)}
                  onFilterChange={e => filterChange(e)}
                  loading={isLodaing}
                  textField="fullName"
                  dataItemKey="id"
                  placeholder="Search"
                  tagRender={(tagData, li) => tagRender(tagData, li)}
                  opened={users.length > 0 && openDropDown}
                  onBlur={() => {
                    SetOpenDropDown(false);
                  }}
                  onFocus={() => {
                    SetOpenDropDown(true);
                  }}
                  focusedItemIndex={focusedItemIndex}
                  popupSettings={{
                    height: '45vh'
                  }}
                />
              </IntlProvider>
            </LocalizationProvider>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddUsersToApp;
