import React, { Component } from "react";
import { Input } from "@progress/kendo-react-inputs";
import ErrorIcon from "@material-ui/icons/Error";

export class Constants {
  static NO_SHOW_VALIDATION_MESSAGE: string = "hide";
  constructor() {}
}

export const SimpleInput = (
  fieldRenderProps: any /* kendo FieldRenderProps + late-bound props */
) => {
  return (
    <div
      className={`form-input ${
        fieldRenderProps.visited && fieldRenderProps.validationMessage
          ? "has-error"
          : ""
      }`}
    >
      <label>{fieldRenderProps.label}</label>
      <Input
        placeholder={fieldRenderProps.placeholder}
        value={fieldRenderProps.value}
        onChange={fieldRenderProps.onChange}
        maxLength={fieldRenderProps.maxLength}
      />
      {fieldRenderProps.visited &&
        fieldRenderProps.validationMessage &&
        fieldRenderProps.validationMessage !==
          Constants.NO_SHOW_VALIDATION_MESSAGE && (
          <span className={"error-info"}>
            <ErrorIcon />
          </span>
        )}
      {fieldRenderProps.visited &&
        fieldRenderProps.validationMessage &&
        fieldRenderProps.validationMessage !==
          Constants.NO_SHOW_VALIDATION_MESSAGE && (
          <div className={"k-required"}>
            {fieldRenderProps.validationMessage}
          </div>
        )}
    </div>
  );
};
