import * as JobStore from "./store/Jobs";
import { JobInfo } from "./models/JobModels";
import { IGetJobStatusResponseData } from "./services/JobService";
import NotificationService from "./services/NotificationService";

let jobManagerInstance: JobManager = null;

interface TrackingJob {
  jobId: string;
  jobTimeout?: number;
  currNumChecks: number;
  waitsSpans: Array<number>;
  messageConfig: JobMessagingConfig;
}

interface SpecialStatusCases {
  previousStatus?: number;
  statusTypes?: Record<number, StatusInfo>; // Job status type id in db is the key
}

interface StatusInfo {
  message?: string;
  degree?: "success" | "info" | "warning" | "error";
  keepChecking?: boolean;
  onStatus?: (data: string) => void;
  statusCallbackHasNoMessage?: boolean;
}

export interface JobMessagingConfig {
  genericSuccessMessage?: string;
  genericErrorMessage?: string;
  specialStatusCases?: SpecialStatusCases;
  genericInfoMessage?: string;
  cancelMessage?: string;
  approvalPendingMessage?: string;
  onApprovalNeeded?: () => void;
}

interface CompositeJobInfo {
  info: JobInfo;
  config: JobMessagingConfig;
}

export default class JobManager {
  readonly store: any;
  trackingJobs: Record<string, TrackingJob>;

  constructor(store?: any) {
    if (jobManagerInstance) {
      return jobManagerInstance;
    } else if (store == null) {
      throw new Error("The store for job manager must be instantiated at least once");
    }

    jobManagerInstance = this;
    this.store = store;
    this.trackingJobs = {};
  }

  addJobId(jobId: string, messageConfig?: JobMessagingConfig, waitsSpans?: Array<number>) {
    if (this.trackingJobs[jobId]) {
      console.warn("Skipping this job since we are already tracking job:" + jobId);
      return;
    }

    this.trackingJobs[jobId] = {
      jobId,
      messageConfig,
      currNumChecks: 0,
      waitsSpans: waitsSpans || waitsSpans && waitsSpans.length > 0 ? waitsSpans : [1000, 2000]
    };

    if (messageConfig && messageConfig.genericInfoMessage) {
      NotificationService.showInfoToast(messageConfig.genericInfoMessage, true, true);
    }

    this.updateTimeout(this.trackingJobs[jobId]);
  }

  stopTrackingJob(jobId: string) {

    const job = this.trackingJobs[jobId];

    if (!job) {
      console.warn("Cannot stop tracking job since we are not tracking job:" + jobId);
      return;
    }

    clearTimeout(job.jobTimeout);
    delete this.trackingJobs[jobId];
  }

  clearAllJobs() {
    for (const jobId in this.trackingJobs) {
      this.stopTrackingJob(jobId);
    }

    this.store.dispatch(JobStore.actionCreators.ClearAll());
  }

  updateTimeout(job: TrackingJob) {
    // last object in array is the final timespan
    const jobInterval = job.waitsSpans.length <= job.currNumChecks + 1 ? job.waitsSpans[job.waitsSpans.length - 1] : job.waitsSpans[job.currNumChecks];

    job.currNumChecks++;
    job.jobTimeout = window.setTimeout(() => {
      if (!this.trackingJobs[job.jobId]) {
        return; // Job no longer in the tracking list so stop polling
      }

      const action = JobStore.actionCreators.GetJobStatus;
      this.store.dispatch(action(job.jobId));
      this.updateTimeout(job);
    }, jobInterval);

    this.trackingJobs[job.jobId] = job;
  }

  trackedStatuses(jobInfos: JobInfo[]) {
    const jobs = [] as CompositeJobInfo[];

    if (!jobInfos) {
      return [];
    }

    jobInfos.forEach(j => {
      const trackedJob = this.trackingJobs[j.jobId];
      if (trackedJob) {
        jobs.push({ info: j, config: trackedJob.messageConfig });
      }
    });

    return jobs;
  }

  checkJobStatus(jobId: string): IGetJobStatusResponseData {
    return this.store.getState().jobState.jobInfos.find((jobInfo: JobInfo) => {
      return jobInfo.jobId === jobId;
    });
  }
}
