import { Action, Reducer } from "redux";

export interface IAuthenticationState {
  authenticated: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface SetAuthenticationAction {
  type: "SET_AUTHENTICATION";
  authenticated: boolean;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = SetAuthenticationAction;

export const actionCreators = {
  setAuthentication: (authenticated: boolean) => ({ type: "SET_AUTHENTICATION", authenticated } as SetAuthenticationAction)
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: IAuthenticationState = { authenticated: false };

export const reducer: Reducer<IAuthenticationState> = (state: IAuthenticationState | undefined, incomingAction: Action): IAuthenticationState => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "SET_AUTHENTICATION":
      return { authenticated: action.authenticated };
    default:
      return state;
  }
};
