import * as ReportStore from "./store/Report";

let reportManagerInstance: ReportManager = null;

export default class ReportManager {
  readonly store: any;

  constructor(store?: any) {
    if (reportManagerInstance) {
      return reportManagerInstance;
    } else if (store == null) {
      throw new Error("The store for the report manager must be instantiated at least once");
    }

    reportManagerInstance = this;
    this.store = store;
  }

  clearReports() {
    this.store.dispatch(ReportStore.actionCreators.ClearAll());
  }
}