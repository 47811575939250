import * as React from "react";
import CommonHelper from "../../../common/utilities/CommonHelper";
import { Grid } from "@material-ui/core";
import { IResourceGroupAutoModel, ResourceGroupDtls } from "../../../../models/ResourceModels";
import { formatDate } from "@telerik/kendo-intl";
import { TextInput } from "../../../common/TextInput";
import CollapsibleSection from "../../common/CollapsibleSection";
import CreateIcon from "@material-ui/icons/Create";
import CancelIcon from "@material-ui/icons/Cancel";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { ShowEpiqOnlyControls } from "../../../../models/Enums";
import ErrorIcon from "@material-ui/icons/Error";
import ResourceGroupAutomation from "./ResourceGroupAutomation";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import "./Resource.scss";
import DialogBox from "../../../common/DialogBox";
import NotificationService from "../../../../services/NotificationService";
import ResourceService from "../../../../services/ResourceService";

interface IElementToValidate {
  name: string;
  isValid: boolean;
}

interface IProps {
  resourceGroupDetails: ResourceGroupDtls;
  createResourceGroup?: boolean;
  isSaving: boolean;
  isResourceGroupAlreadyExist: boolean;
  isSavedEditedDetailsIntoDB: boolean;
  updateResourceDetails?: (editedResourceDetails: ResourceGroupDtls, isValid: boolean) => void;
  automationSectionUpdated?: (automationModel: IResourceGroupAutoModel) => void;
  automationSectionChanged?: (isReady: boolean) => void;
  automationRequireChanged?: (needsapproval: boolean) => void;
  automationModel?: IResourceGroupAutoModel;
  resetViewMode: (isResourceInfoInEditMode: boolean) => void;
  showEpiqOnlyAdmin: ShowEpiqOnlyControls;
  disableEpiqOnlyAdmin: boolean;
  permissions: Set<string>;
  hasEpiqOnlyError: boolean;
  history: any;
  saveUnsavedChanges: any;
}

type Props = IProps;

type State = {
  isResourceInfoInEditMode: boolean;
  editedResourceDetails: ResourceGroupDtls;
  elementsToValidate: Array<IElementToValidate>;
  isEpiqOnly: boolean;
  showAutomation: boolean;
  requireApproval: boolean;
  draftAutomationModel?: IResourceGroupAutoModel;
  canDelete: boolean;
  openDeleteConfirmPopup: boolean;
};

const validationMsg =
  "Cannot remove Epiq Admin Only flag when group contains Epiq Admin resources. Remove these resources and try again.";

class Resource extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);    
    this.state = {
      isResourceInfoInEditMode: props.createResourceGroup,
      draftAutomationModel: props.automationModel,
      editedResourceDetails: props.createResourceGroup
        ? new ResourceGroupDtls()
        : CommonHelper.cloneObject(props.resourceGroupDetails),
      elementsToValidate: new Array<IElementToValidate>({ name: "groupName", isValid: !props.createResourceGroup }),
      isEpiqOnly: false,
      showAutomation: props.automationModel ? !!props.automationModel.filter : false,
      requireApproval: props.automationModel ? !!props.automationModel.requireApproval : false,
      canDelete: true,
      openDeleteConfirmPopup: false
    };
  }

  onGroupInfoChange = (value: string, name: string, isValid: boolean) => {
    let editedResourceGroup: any = this.state.editedResourceDetails;
    editedResourceGroup[name] = value;

    this.setElementValidationState({ name, isValid }, () => {
      this.setState({ editedResourceDetails: editedResourceGroup });

      if (this.props.updateResourceDetails) {
        this.props.updateResourceDetails(editedResourceGroup, isValid);
      }
    });
  };

  onCancelEditResourceInfo = () => {
    this.setState(
      {
        editedResourceDetails: CommonHelper.cloneObject(this.props.resourceGroupDetails),
        isResourceInfoInEditMode: false
      },
      () => {
        if (this.props.updateResourceDetails) {
          this.props.updateResourceDetails(this.props.resourceGroupDetails, true);
        }
      }
    );
  };

  private setElementValidationState(newValidationState: IElementToValidate, callBack: () => void) {
    const indexToUpdate = this.state.elementsToValidate.findIndex(element => element.name === newValidationState.name);
    const elementsToValidate = [...this.state.elementsToValidate];

    if (indexToUpdate === -1) {
      callBack();
    } else {
      elementsToValidate.splice(indexToUpdate, 1);
      elementsToValidate.splice(indexToUpdate, 0, newValidationState);

      this.setState({ ...this.state, elementsToValidate }, callBack);
    }
  }

  onCheckBoxChange = (isChecked: boolean) => {
    let editedResourceGroup: any = this.state.editedResourceDetails;
    editedResourceGroup["epiqOnly"] = isChecked;

    this.setState({ editedResourceDetails: editedResourceGroup, isEpiqOnly: isChecked }, () => {
      if (this.props.updateResourceDetails) {
        this.props.updateResourceDetails(editedResourceGroup, true);
      }
    });
  };

  onFilterApplied = (change: IResourceGroupAutoModel) => {
    this.props.automationSectionUpdated(change);
    this.setState({ draftAutomationModel: null });
  };

  onUseAutomationChecked = (event: CheckboxChangeEvent) => {
    this.setState({ showAutomation: event.value }, () => {
      if (!event.value) {
        this.props.automationSectionUpdated({
          filter: null,
          resourceTypeCode: null,
          resourceTypeId: null,
          usedFields: null,
          isDirty: false,
          requireApproval: false,
          displayName: null
        });
      }
    });
  };

  onRequireApprovalChecked = (event: CheckboxChangeEvent) => {
    this.setState({ requireApproval: event.value }, () => {
      this.props.automationRequireChanged(event.value);
    });
  };

  onAutomationEditChange = (change: IResourceGroupAutoModel) => {
    if (!change) {
      this.setState({ draftAutomationModel: null },
        () => this.props.automationSectionChanged(true));
      return;
    }

    const { requireApproval } = this.state;

    this.setState({ draftAutomationModel: { ...change, requireApproval } },
      () => this.props.automationSectionChanged(false));
  }

  handleClickDelete = async () => {
    const response = await ResourceService.canDeleteResourceGroup(this.props.resourceGroupDetails.id);
    if (response.ok) {
      this.setState({
        canDelete: response.data,
        openDeleteConfirmPopup: true,
      });
    }
  };

  async toggleDialogEvent(event: React.MouseEvent<HTMLElement>) {
    if (event.currentTarget.textContent.toLocaleLowerCase() === "confirm") {
      this.setState({ openDeleteConfirmPopup: false, canDelete: false });
      const result = await ResourceService.deleteResourceGroup(this.props.resourceGroupDetails);
      if (result.ok) {
        NotificationService.showSuccessToast(`Deleted resource group: ${this.props.resourceGroupDetails.groupName}.`);
        this.props.saveUnsavedChanges(null, false);
        this.props.history.push("/administration/resourceGroups");
      } else {
        NotificationService.showErrorToast(`${this.props.resourceGroupDetails.groupName} resource group deletion failed`);
      }
    }
    else if (event.currentTarget.textContent.toLocaleLowerCase() === "cancel" || event.currentTarget.textContent.toLocaleLowerCase() === "ok") {
      this.setState({ openDeleteConfirmPopup: false, canDelete: false });
    }
  }

  private getOperatorDesc(operator : string) {
    switch (operator) {
      case "contains":
        return "Contains"
      case "eq":
        return "Is equal to"
      case "in":
        return "In"
      default:
        return operator
    }
  }

  render() {
    const { editedResourceDetails, isResourceInfoInEditMode, showAutomation, requireApproval, draftAutomationModel } = this.state;
    const { isSaving, isSavedEditedDetailsIntoDB, showEpiqOnlyAdmin, automationModel } = this.props;
    const currentAutomationModel =  draftAutomationModel || automationModel;
    
    const createdDate = formatDate(CommonHelper.convertUTCDateToLocalDate(editedResourceDetails.createdDate), "g");
    const notesLength = editedResourceDetails.description ? editedResourceDetails.description.length : 0;
    const isResourceDetailsInEditMode = isSavedEditedDetailsIntoDB
      ? !isSavedEditedDetailsIntoDB
      : isResourceInfoInEditMode;
    const resourceGroupName =
      this.props.resourceGroupDetails.id > 0 ? this.props.resourceGroupDetails.groupName : "Create Resource Group";

    const confirmDeleteDialog = (
      <div className="warning">
        <DialogBox
          title={this.state.canDelete ? "Confirm deletion" : "Clear resource group"}
          content={this.state.canDelete ? `This action deletes resource group: '${this.props.resourceGroupDetails.groupName}'. Confirm that you want to delete this resource group.` : "Cannot delete an active resource group, Remove all resources and assignments prior to deletion."}
          okButtonLabelText={this.state.canDelete ? "Cancel" : ""}
          cancelButtonLabelText={this.state.canDelete ? "Confirm" : "OK"}
          visible={this.state.openDeleteConfirmPopup}
          toggleDialogEvent={this.toggleDialogEvent.bind(this)}
        /></div>);

    return (
      <>
        <div className="resource-details-wrapper papergrid-space">
          <Grid container alignItems="center">
            <Grid item sm={9} xs={12}>
              <h3 className="section-head">{resourceGroupName} </h3>
            </Grid>
            <Grid item sm={3} xs={12} className="button-wrapper">
              {!this.props.createResourceGroup && (
                <>
                  <span className="dialog-btn">
                    {(showEpiqOnlyAdmin === ShowEpiqOnlyControls.ENABLE) && (
                      <button className="k-button" onClick={this.handleClickDelete.bind(this)}>
                        Delete
                      </button>
                    )}
                  </span>
                  <span className="edit-resource">
                    {isResourceDetailsInEditMode && (
                      <CancelIcon className="edit-icon" onClick={this.onCancelEditResourceInfo} />
                    )}
                    {!isResourceDetailsInEditMode && this.props.permissions.has("EpiqAdminUpdateResourceGroup") && (
                      <CreateIcon
                        className="edit-icon"
                        onClick={() => {
                          this.setState(
                            {
                              isResourceInfoInEditMode: true,
                              editedResourceDetails: CommonHelper.cloneObject(this.props.resourceGroupDetails)
                            },
                            () => {
                              this.props.resetViewMode(false);
                            }
                          );
                        }}
                      />
                    )}
                  </span>
                </>

              )}
            </Grid>
          </Grid>

          {isResourceDetailsInEditMode && (
            <div className="resource-group-edit">
              <CollapsibleSection title="GENERAL INFO" >
                <>
                  <div className="field-hint">* Required Fields</div>
                  <Grid container>
                    <Grid item sm={4} md={4}>
                      <div className="resource-selector">
                        <TextInput
                          type="text"
                          placeholder="Resource Group Name"
                          defaultValue={editedResourceDetails.groupName}
                          name="groupName"
                          legacyMode={true}
                          label="* Resource Group Name"
                          validations={[
                            { name: "required", errorMessage: "This field is required." },
                            {
                              name: "CustomValidation",
                              predicate: `${this.props.isResourceGroupAlreadyExist}`,
                              errorMessage: "Resource Group already exists."
                            }
                          ]}
                          onChange={this.onGroupInfoChange}
                          maxLength={250}
                          disabled={isSaving}
                          displayCustomValidationMessage={this.props.isResourceGroupAlreadyExist}
                        />
                        {(showEpiqOnlyAdmin == ShowEpiqOnlyControls.SHOW || showEpiqOnlyAdmin == ShowEpiqOnlyControls.ENABLE) && (
                          <>
                            <Checkbox
                              defaultChecked={editedResourceDetails.epiqOnly ? true : false}
                              onChange={event => this.onCheckBoxChange(event.value)}
                              name="epiq_only"
                              label="Epiq Admin Only"
                              disabled={this.props.disableEpiqOnlyAdmin}
                            />
                            <br />
                            <br />
                          </>
                        )}
                        <Checkbox
                          defaultChecked={showAutomation}
                          onChange={this.onUseAutomationChecked}
                          name="enable_automation"
                          label="Enable automation"
                        /><br />
                        <Checkbox
                          defaultChecked={requireApproval}
                          onChange={this.onRequireApprovalChecked}
                          name="require_approval"
                          label="Require approval"
                          disabled={!showAutomation}
                        />
                        {this.props.hasEpiqOnlyError && (
                          <div className="error-message">
                            <div className="error-info">
                              <span>
                                <ErrorIcon />
                              </span>
                              <span className="error-msg">{validationMsg}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item sm={8} md={8}>
                      <TextInput
                        label="Description"
                        type="textarea"
                        legacyMode={true}
                        name="description"
                        defaultValue={editedResourceDetails.description}
                        onChange={this.onGroupInfoChange}
                        notesLength={notesLength}
                        maxLength={255}
                        disabled={this.props.isSaving}
                        placeholder="Enter a short description"
                      />
                    </Grid>
                  </Grid>
                </>
              </CollapsibleSection>
              {showAutomation && (
                <CollapsibleSection title="AUTOMATION RULE" initHidden={!showAutomation} >
                  <ResourceGroupAutomation onFilterApplied={this.onFilterApplied} onEditChange={this.onAutomationEditChange} automationModel={draftAutomationModel || automationModel} />
                </CollapsibleSection>
              )}
            </div>
          )}

          {!isResourceDetailsInEditMode && (
            <div className="resource-info">
              <Grid container>
                <Grid item md={4} sm={6} xs={12}>
                  <div>
                    <strong> Created Date</strong> {createdDate}
                  </div>
                  <div>
                    <strong> Automation Enabled</strong> {automationModel && automationModel.filter ? "Yes" : "No"}
                  </div>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <div>
                    <strong> Epiq Only</strong> {editedResourceDetails.epiqOnly ? "True" : "False"}
                  </div>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <div>
                    <div>
                      <strong> Description </strong>
                    </div>
                    <div className="resource-group-desc">{this.props.resourceGroupDetails.description}</div>
                  </div>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                 
                  {currentAutomationModel && currentAutomationModel.filter && currentAutomationModel.displayName && (currentAutomationModel.displayName.toLowerCase() === "ediscovery project" || currentAutomationModel.displayName.toLowerCase() === "sales contract number") &&
                    <>
                      <div><strong> New resources of {currentAutomationModel.displayName}</strong></div>
                      {currentAutomationModel.filter.filters.map((fieldInfo: any, index: number) =>
                      (
                        (currentAutomationModel.usedFields && currentAutomationModel.usedFields.indexOf(fieldInfo.field) >= 0) && <div><label className="auto-condt-view">{index == 0 ? "Where" : "And"} {fieldInfo.field} {"<" + this.getOperatorDesc(fieldInfo.operator) + ">"}  {Array.isArray(fieldInfo.value) ? fieldInfo.value.join(", ") : fieldInfo.value}</label></div>)
                      )
                      }
                    </>
                  }
                  </Grid>
              </Grid>
            </div>
          )}
        </div>
        {confirmDeleteDialog}
      </>
    );
  }
}

export default Resource;
