import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";

export default class FilterHelper {
    static getModifiedCorrectDateFilter = (
        gridFilters: Array<CompositeFilterDescriptor>,
        dateColumnName: string = ""
    ) => {
        // This function is used for date column filter in all advance grid pages.
        let isDateFilterExists = false;
        let isDateFilterCleared = false;
        let dateColumnValue: Date;
        const filters = gridFilters
        ? gridFilters.map(compositeFilter => {
            const dateColumnFilter: FilterDescriptor = compositeFilter.filters.find(
            filter => (filter as FilterDescriptor).field === dateColumnName
            ) as FilterDescriptor;

            if (dateColumnFilter) {
            const dateFilterValue = dateColumnFilter.value;
            
            isDateFilterCleared = typeof dateFilterValue === "string" && (dateFilterValue as String).length === 0;

            if (!isNaN(new Date(dateFilterValue).getFullYear()) && new Date(dateFilterValue).getFullYear() > 1970) {
                dateColumnFilter.operator = 'eq';
            }

            dateColumnValue = typeof dateFilterValue === "string" ? new Date("01/01/1901") : ((dateColumnFilter.operator === 'isnull' || dateColumnFilter.operator === 'isnotnull') ? null : new Date(dateFilterValue));

            if (dateColumnFilter.operator === 'isnull' || dateColumnFilter.operator === 'isnotnull') {
                return {
                ...compositeFilter,
                filters: [
                    {
                    ...dateColumnFilter,
                    field: dateColumnName,
                    operator: typeof dateFilterValue === "string" && dateColumnFilter.operator === 'isnotnull' ? "eq" : dateColumnFilter.operator,
                    value: dateColumnValue
                    }
                ]
                };
            }

            isDateFilterExists = true;

            return {
                ...compositeFilter,
                filters: [
                {
                    ...dateColumnFilter,
                    field: dateColumnName,
                    operator: "gte",
                    value: new Date(dateColumnValue.toUTCString())
                }
                ]
            };
            }
            return compositeFilter;
        })
        : [];

        if (!isDateFilterCleared && isDateFilterExists) {
            filters.push({
                logic: "and",
                filters: [
                {
                    field: dateColumnName,
                    operator: "lt",
                    value: new Date(new Date(dateColumnValue.setUTCDate(dateColumnValue.getUTCDate() + 1)).toUTCString())
                }
                ]
            });
        }

        return filters;
    };

    static forceFilter = (compositeFilters: Array<CompositeFilterDescriptor>, filter: FilterDescriptor) => {
        if (!compositeFilters) compositeFilters = [];

        for (let i = 0; i < compositeFilters.length; i++) {
            let filters = compositeFilters[i].filters as Array<FilterDescriptor>;

            if (!filters) {
                filters = [];
                continue;
            }

            for (let j = 0; j < filters.length; j++) {
                if (filters[j].field === filter.field) {
                    filters[j] = filter;
                    return compositeFilters;
                }
            }
        }

        // value is not set so we add it
        if (compositeFilters.length === 0) {
            compositeFilters.push({logic:"and", filters: []});
        }

        if (!compositeFilters[0].filters) {
            compositeFilters[0].filters = [];
        }

        compositeFilters[0].filters.push(filter);

        return compositeFilters;
    }

    static removeFiltersByFieldName = (compositeFilters: Array<CompositeFilterDescriptor>, field: string) => {
        if (!compositeFilters) return compositeFilters;

        for (let i = compositeFilters.length - 1; i >= 0; i--) {
            //let filters = compositeFilters[i].filters as Array<FilterDescriptor>;

            if (!compositeFilters[i].filters) {
                continue;
            }

            for (let j = compositeFilters[i].filters.length - 1; j >= 0; j--) {
                if ((compositeFilters[i].filters[j] as FilterDescriptor).field === field) {
                    compositeFilters[i].filters.splice(j, 1);
                }
            }

            if (compositeFilters[i].filters.length === 0) {
                compositeFilters.splice(i,1);
            }
        }

        return compositeFilters;
    }
}