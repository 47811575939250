import * as React from "react";
import Grid from "@material-ui/core/Grid";
import { TextInput } from "../../common/TextInput";
import { Checkbox } from "@progress/kendo-react-inputs";
import { formatDate } from "@telerik/kendo-intl";
import "./roles.scss";
import { Role as RoleModel, UpsertRoleModel } from "../../../models/Role";
import { ShowEpiqOnlyControls } from "../../../models/Enums";
import CreateIcon from "@material-ui/icons/Create";
import CancelIcon from "@material-ui/icons/Cancel";
import ErrorIcon from "@material-ui/icons/Error";
import CommonHelper from "../../common/utilities/CommonHelper";

enum PlaceHolders {
  roleName = "(e.g. Review Manager)"
}

enum ErrorMessages {
  RoleExists = "Role already exists.",
  Required = "This field is required.",
  EpiqAdminOnlyError = "Cannot remove Epiq Admin Only flag when role contains Epiq Only permissions. Remove these permissions and try again."
}

interface IElementToValidate {
  name: string;
  isValid: boolean;
}

interface IProps {
  role: RoleModel;
  savedRoleDetails: RoleModel;
  createRole?: boolean;
  onRoleEdited: (role: UpsertRoleModel, isValid?: boolean) => void;
  onRoleEditCancelled?: (role: UpsertRoleModel) => void;
  permissions: Set<string>;
  showEpiqOnlyAdmin: ShowEpiqOnlyControls;
  hasEpiqOnlyError: boolean;
  isRoleNameExist: boolean;
  isUpdateSaved: boolean;
  resetViewMode: (isRoleInfoInEditMode: boolean) => void;
  onRoleDelete?: () => void;
}

type Props = IProps;

type State = {
  roleInfoInEditMode: boolean;
  visible: boolean;
  roleInEdit: UpsertRoleModel;
  superAdmin: boolean;
  elementsToValidate: Array<IElementToValidate>;
};

class Role extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const role = CommonHelper.convertPropsToType(props.role, UpsertRoleModel);

    this.state = {
      roleInfoInEditMode: props.createRole,
      visible: false,
      roleInEdit: props.createRole ? new UpsertRoleModel() : CommonHelper.cloneObject(role),
      superAdmin: false,
      elementsToValidate: new Array<IElementToValidate>({ name: "roleName", isValid: !props.createRole }),
    };
  }

  handleChange = (value: string) => {
    var tempRole = this.state.roleInEdit;
    tempRole.description = value ? value : "";
    
      this.setState({ roleInEdit: tempRole });

      if (this.props.onRoleEdited) {
        this.props.onRoleEdited(tempRole, true);
      }
  };

  onRoleDelete = () => {

    if (this.props.onRoleDelete) {
      this.props.onRoleDelete();
    }
  };

  onChange = (value: string, name: string, isValid: boolean) => {
    var tempRole = this.state.roleInEdit;
    tempRole.roleName = value ? value : "";

    this.setElementValidationState({ name, isValid }, () => {
      this.setState({ roleInEdit: tempRole });
      if (this.props.onRoleEdited) {
        this.props.onRoleEdited(tempRole, isValid);
      }
    });
  };

  onCheckBoxChange = (isChecked: boolean) => {
    var tempRole = this.state.roleInEdit;
    tempRole.epiqOnly = isChecked ? isChecked : false;

    this.setState({ roleInEdit: tempRole });
    if (this.props.onRoleEdited) {
      this.props.onRoleEdited(tempRole, true);
    }
  };

  onCancelEditRoleInfo = () => {
    this.setState({
      roleInfoInEditMode: false,
      roleInEdit: CommonHelper.cloneObject(this.props.savedRoleDetails)
    },
      () => {
        if (this.props.onRoleEdited) {
          this.props.onRoleEdited(this.props.savedRoleDetails, true);
        }
      });   
  };

  private setElementValidationState(newValidationState: IElementToValidate, callBack: () => void) {
    const indexToUpdate = this.state.elementsToValidate.findIndex(element => element.name === newValidationState.name);
    const elementsToValidate = [...this.state.elementsToValidate];

    if (indexToUpdate === -1) {
      callBack();
    } else {
      elementsToValidate.splice(indexToUpdate, 1);
      elementsToValidate.splice(indexToUpdate, 0, newValidationState);

      this.setState({ ...this.state, elementsToValidate }, callBack);
    }
  }

  render() {
    const { role, showEpiqOnlyAdmin, isRoleNameExist, isUpdateSaved } = this.props;
    const { roleInEdit, roleInfoInEditMode } = this.state;
    const commentsLength = roleInEdit.description ? roleInEdit.description.length : 0;
    const roleName =
      this.props.role.id > 0 ? this.props.role.roleName : "Create Role";
    const createdDate = roleInEdit.createdDate ? formatDate(CommonHelper.convertUTCDateToLocalDate(roleInEdit.createdDate), "g") : "";
    const isRoleDetailsInEditMode = isUpdateSaved ? !isUpdateSaved : roleInfoInEditMode;

    const createRole = (
      <fieldset className="create-role-form">
        <div className="field-hint">* Required Fields</div>
        <div>
          <Grid container>
            <Grid item xs={6}>
              <div className="text-input-has-error">
                <TextInput
                  type="text"
                  name="roleName"
                  label="* Role Name"
                  legacyMode={true}
                  placeholder={PlaceHolders.roleName}
                  defaultValue={roleInEdit.roleName}
                  maxLength={50}
                  validations={[
                    { name: "required", errorMessage: ErrorMessages.Required },
                    {
                      name: "CustomValidation",
                      errorMessage: `${ErrorMessages.RoleExists}`,
                      predicate: `${isRoleNameExist}`
                    }
                  ]}
                  onChange={this.onChange}
                  displayCustomValidationMessage={isRoleNameExist}
                />
              </div>
              <div className="text-input-has-error">
                {(showEpiqOnlyAdmin == ShowEpiqOnlyControls.ENABLE ||
                  showEpiqOnlyAdmin == ShowEpiqOnlyControls.SHOW) && <Checkbox
                  defaultChecked={roleInEdit.epiqOnly ? true : false}
                    onChange={(event) => this.onCheckBoxChange(event.value)}
                    name="epiqOnly"
                    label="Epiq Admin Only"
                  disabled={!this.props.createRole || showEpiqOnlyAdmin === ShowEpiqOnlyControls.SHOW }
                  />
                }
              </div>
              {this.props.hasEpiqOnlyError && (<div className="error-message">
                <div className="error-info">
                  <span>
                    <ErrorIcon />
                  </span>
                  <span className="error-msg">{ErrorMessages.EpiqAdminOnlyError}</span>
                </div>
              </div>)}
            </Grid>
            <Grid item xs={6}>
              <div>
                <TextInput
                  type="textarea"
                  label="Description"
                  name="description"
                  legacyMode={true}
                  defaultValue={roleInEdit.description}
                  onChange={this.handleChange}
                  notesLength={commentsLength}
                  maxLength={255}
                  placeholder="Enter a short description."
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </fieldset>
    );

    return (
      <div className="grid-wrapper role-grid-wrapper papergrid-space">
        <Grid container alignItems="center">
          <Grid item sm={6} xs={12}>
            <h3 className="section-head">{roleName} </h3>
          </Grid>
          <Grid item sm={6} xs={12} className="button-wrapper">
            {!this.props.createRole && (
              <>
                 <span className="dialog-btn">
                  {showEpiqOnlyAdmin === ShowEpiqOnlyControls.ENABLE &&
                    <button className="k-button" onClick={this.onRoleDelete.bind(this)}>
                       Delete
                    </button>
                  }
                  </span>
                  <span className="edit-user">                
                    {isRoleDetailsInEditMode && (
                      <CancelIcon className="edit-icon" onClick={this.onCancelEditRoleInfo} />
                    )}
                    {!isRoleDetailsInEditMode &&
                      this.props.permissions.has("EpiqAdminUpdateRole") && (<CreateIcon
                        className="edit-icon"
                      onClick={() => {
                          this.setState({
                            roleInEdit: this.props.role,
                            roleInfoInEditMode: true,
                          },
                            () => { this.props.resetViewMode(false); });
                        }}
                      />
                      )}
                    </span>
              </>
            )}
          </Grid>
        </Grid>
        {isRoleDetailsInEditMode && createRole}
        {!isRoleDetailsInEditMode && (
          <div className="role-info">
            <Grid container>
              <Grid item sm={4} xs={12}>
                <div className="role-info-row">
                  <strong>Created</strong> {createdDate}
                </div>
              </Grid>
              <Grid item sm={4} xs={12}>
                <div className="role-info-row">
                  <strong>Epiq Admin Only: </strong> {role.epiqOnly ? "True" : "False"}
                </div>
              </Grid>
              <Grid item sm={4} xs={12}>
                <div className="role-info-row">
                  <div><strong> Description</strong></div>
                  <div>{role.description}</div>
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}
export default Role;
