import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { IAppFeature, IFeatureManager, featureOptions, IFeature } from "../../../../../models/Features";
import RelativityService from "../../../../../services/RelativityService";
import { SettingTypes } from "../../../../common/SettingsAdmin";
import AdminService from "../../../../../services/AdminService";

const options = [{ key: "objectId" as featureOptions, index: 0 }, { key: "data" as featureOptions, index: 1 }];

export default class RelativityAccountConfigurationManager implements IFeatureManager {

  private appId: number;
  private fIndex: Record<featureOptions, number>;
  private fKey: Record<number, featureOptions>;

  public constructor(appId: number) {
    this.appId = appId;

    this.fIndex = {} as Record<featureOptions, number>;
    this.fKey = {} as Record<number, featureOptions>;

    options.forEach(o => {
      this.fIndex[o.key] = o.index;
      this.fKey[o.index] = o.key;
    });
  }

  public createAppFeatures(featureId: number, values?: Array<any>) {

    return [{
      id: 0,
      applicationId: this.appId,
      featureId: featureId,
      objectId: values && values.length > this.fIndex["objectId"] ? values[this.fIndex["objectId"]] : null,
      data: values && values.length > this.fIndex["data"] ? values[this.fIndex["data"]] : null
    }] as Array<IAppFeature>;
  }

  public getAdminSettingOptions(feature: IFeature) {

    const whichAppFeature = 0; // we only set data for one app feature record
    const objectIdValue = feature.applicationFeature && feature.applicationFeature.length && feature.applicationFeature[whichAppFeature].objectId || null;

    const profile = feature.applicationFeature && 
        feature.applicationFeature.length && 
        feature.applicationFeature[whichAppFeature].data &&
        JSON.parse(feature.applicationFeature[whichAppFeature].data) ||
        null;

    return [
      {
        id: feature.applicationFeature && feature.applicationFeature.length ? feature.applicationFeature[whichAppFeature].id : 0,
        name: "Workspace instance",
        type: SettingTypes.singleSelect,
        value: objectIdValue,
        fullWidth: true,
        singleSelectOptions: {
          placeholder: "(Type to filter selections)",
          getItems: async (keyword: string) => {
            const instFilter = !keyword? null : {
              logic: "or", filters: [
                { field: "relativityUrl", operator: "contains", value: keyword },
                { field: "name", operator: "contains", value: keyword }]
            } as CompositeFilterDescriptor;
            
            const activeFilter = {
              logic: "and", filters: [
                { field: "isActive", operator: "eq", value: true }]
            } as CompositeFilterDescriptor;
            
            const filters = !keyword? new Array<CompositeFilterDescriptor>(activeFilter) : new Array<CompositeFilterDescriptor>(instFilter, activeFilter);
            return await RelativityService.getInstances({ filters: filters });
          },
          onChange: () => { } /* We'll let SettingsAdmin overwrite this */,
          typeToListConverter: (instances: Array<any>) => {
            if (!instances) return [];

            return instances.map(i => {
              return {
                id: i.id,
                text: `${i.name} ${i.relativityUrl}`,
                data: i.id
              }
            });
          },
          preselectedItemCall: !objectIdValue ? null : async () => {
            return await RelativityService.getInstance(objectIdValue);
          }
        }
      },
      {
        id: feature.applicationFeature && feature.applicationFeature.length ? feature.applicationFeature[whichAppFeature].id : 0,
        name: "Authentication Provider",
        type: SettingTypes.singleSelect,
        value: profile ? JSON.stringify({AuthenticationProfileName: profile.AuthenticationProfileName}) : null,
        fullWidth: true,
        singleSelectOptions: {
            disableFiltering: true,
            preselectedValue: {
                id: profile ? profile.AuthenticationProfileName : null,
                text: profile ? profile.AuthenticationProfileName : null,
                data: profile ? JSON.stringify({AuthenticationProfileName: profile.AuthenticationProfileName}) : null
            },
            placeholder: "(Type to filter selections)",
            getItems: async (keyword: string) => {
                return await AdminService.getAuthenticationProfiles(this.appId);
            },
            onChange: () => { } /* We'll let SettingsAdmin overwrite this */,
            typeToListConverter: (authProfiles: Array<any>) => {
                if (!authProfiles) return [];

                return authProfiles.map(authProfile => {
                    return {
                        id: authProfile,
                        text: authProfile,
                        data: JSON.stringify({AuthenticationProfileName: authProfile})
                    }
                });
            }
        }
      }
    ];
  }

  public getKeyByIndex(featureId: number, optionIdx: number): featureOptions {
    // TODO consider putting this in FeatureManager as base class
    return this.fKey[optionIdx];
  }
}