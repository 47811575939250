import { IAppFeature, IFeatureManager, featureOptions, IFeature } from "../../../../../models/Features";
import { IAdminSettingsOptions, SettingTypes } from "../../../../common/SettingsAdmin";

const options = [{ key: "objectId" as featureOptions, index: 0 }, { key: "data" as featureOptions, index: 1 }];

export default class BasicFeatureManager implements IFeatureManager {

  private appId: number;
  private fIndex: Record<featureOptions, number>;
  private fKey: Record<number, featureOptions>;

  public constructor(appId: number) {
    this.appId = appId;

    this.fIndex = {} as Record<featureOptions, number>;
    this.fKey = {} as Record<number, featureOptions>;

    options.forEach(o => {
      this.fIndex[o.key] = o.index;
      this.fKey[o.index] = o.key;
    });
  }

  public createAppFeatures(featureId: number, values?: Array<any>) {

    return [{
      id: 0,
      applicationId: this.appId,
      featureId: featureId,
      objectId: values && values.length > this.fIndex["objectId"] ? values[this.fIndex["objectId"]] : null,
      data: values && values.length > this.fIndex["data"] ? values[this.fIndex["data"]] : null
    }] as Array<IAppFeature>;
  }

  public getAdminSettingOptions(feature: IFeature) {

    return [] as Array<IAdminSettingsOptions>;
  }

  public getKeyByIndex(featureId: number, optionIdx: number): featureOptions {
    // TODO consider putting this in FeatureManager as base class
    return this.fKey[optionIdx];
  }
}