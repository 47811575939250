import { Action, Reducer } from "redux";
import { AppThunkAction, AppThunkActionPromise } from "./";
import { User, UserDetailsModel, UpsertUserModel, SendInvite } from "../models/UserModels";
import AdminService from "../services/AdminService";
import NotificationService from "../services/NotificationService";
import GridParams from "../components/common/GridHelper";
import CommonHelper from "../components/common/utilities/CommonHelper";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IUsersState {
  isLoading: boolean;
  users: User[];
  totalUsersCount: number;
  searchText?: string;
  hasError?: boolean;
  userDetails: UserDetailsModel;
  jobId?: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface GetUserRequestAction {
  type: "GET_USERS_REQUEST";
}

interface GetUserRequestFailureAction {
  type: "GET_USERS_REQUEST_FAILURE";
  hasError?: boolean;
}

interface GetUserResponseAction {
  type: "GET_USERS_RESPONSE";
  users: User[];
  totalUsersCount: 0;
}

interface CreateUserResponseAction {
  type: "CREATE_USER_RESPONSE";
  users: User[];
}

interface SetsearchTextAction {
  type: "SET_SEARCHTEXT";
  searchText: any;
}

interface GetUserDetailsRequestAction {
  type: "GET_USER_DETAILS_REQUEST";
}

interface GetUserDetailsResponseAction {
  type: "GET_USER_DETAILS_RESPONSE";
  userDetails: UserDetailsModel;
}

interface GetUserDetailsFailureAction {
  type: "GET_USER_DETAILS_REQUEST_FAILURE";
  hasError?: boolean;
}

interface AddedJobResponseAction {
  type: "ADDED_JOB_RESPONSE";
  jobId: string;
}

interface UpdateUserDetailsCompleteAction {
  type: "UPDATE_USER_DETAILS_COMPLETE";
  hasError?: boolean;
}

interface ResetUserDetailsState {
  type: "RESET_USER_DETAILS_STATE";
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
  | GetUserRequestAction
  | GetUserRequestFailureAction
  | GetUserResponseAction
  | SetsearchTextAction
  | CreateUserResponseAction
  | GetUserDetailsRequestAction
  | GetUserDetailsResponseAction
  | GetUserDetailsFailureAction
  | UpdateUserDetailsCompleteAction
  | AddedJobResponseAction
  | ResetUserDetailsState;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  requestUsers: (gridParams: GridParams): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    dispatch({ type: "GET_USERS_REQUEST" });
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      const result = await AdminService.getUser(gridParams);

      if (result.ok) {
        dispatch({
          type: "GET_USERS_RESPONSE",
          users: result.data.results,
          totalUsersCount: result.data.totalUsersCount,
        });
      } else {
        dispatch({
          type: "GET_USERS_REQUEST_FAILURE",
          hasError: true,
        });
      }
    }
  },  

  getUserDetails: (userId: number): AppThunkActionPromise<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    dispatch({ type: "GET_USER_DETAILS_REQUEST" });
    if (appState) {
      const result = await AdminService.getUserDetails(userId);
      if (result.ok) {
        dispatch({
          type: "GET_USER_DETAILS_RESPONSE",
          userDetails: result.data,
        });
      } else {
        dispatch({
          type: "GET_USER_DETAILS_REQUEST_FAILURE",
          hasError: true,
        });
      }
    }
  },

  updateUserDetailsComplete: (hasError: boolean): AppThunkActionPromise<KnownAction> => async (dispatch, getState) => {
    dispatch({
      type: "UPDATE_USER_DETAILS_COMPLETE",
      hasError: hasError
    });
  },

  resetUserDetailsStateToDefault: (): AppThunkActionPromise<KnownAction> => async (dispatch, getState) => {
    dispatch({
      type: "RESET_USER_DETAILS_STATE"     
    });
  }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: IUsersState = {
  users: [],
  isLoading: false,
  totalUsersCount: 0,
  searchText: undefined,
  userDetails: new UserDetailsModel(),
};

export const reducer: Reducer<IUsersState> = (state: IUsersState | undefined, incomingAction: Action): IUsersState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "GET_USERS_REQUEST":
      return {
        users: state.users,
        isLoading: true,
        totalUsersCount: state.totalUsersCount,
        searchText: state.searchText,
        userDetails: state.userDetails ? state.userDetails : new UserDetailsModel(),
      };
    case "GET_USERS_RESPONSE":
      return {
        users: action.users,
        isLoading: false,
        totalUsersCount: action.totalUsersCount,
        searchText: state.searchText,
        userDetails: state.userDetails ? state.userDetails : new UserDetailsModel(),
      };
    case "GET_USERS_REQUEST_FAILURE":
      return { ...unloadedState, hasError: true };
    case "CREATE_USER_RESPONSE":
      return {
        users: action.users,
        isLoading: false,
        totalUsersCount: state.totalUsersCount,
        userDetails: state.userDetails ? state.userDetails : new UserDetailsModel(),
      };
    case "SET_SEARCHTEXT":
      return {
        users: state.users,
        isLoading: state.isLoading,
        totalUsersCount: state.totalUsersCount,
        searchText: action.searchText,
        userDetails: state.userDetails ? state.userDetails : new UserDetailsModel(),
      };
    case "GET_USER_DETAILS_REQUEST":
      return {
        users: state.users,
        isLoading: true,
        totalUsersCount: state.totalUsersCount,
        searchText: state.searchText,
        userDetails: state.userDetails ? state.userDetails : new UserDetailsModel(),
      };
    case "GET_USER_DETAILS_RESPONSE":
      return {
        users: state.users,
        isLoading: false,
        totalUsersCount: state.totalUsersCount,
        searchText: state.searchText,
        userDetails: action.userDetails,
      };
    case "GET_USER_DETAILS_REQUEST_FAILURE":
      return {
        users: state.users,
        isLoading: false,
        totalUsersCount: state.totalUsersCount,
        searchText: state.searchText,
        userDetails: state.userDetails,
        hasError: true,
      };
    case "ADDED_JOB_RESPONSE":
      return {
        jobId: action.jobId,
        isLoading: true,
        totalUsersCount: state.totalUsersCount,
        searchText: state.searchText,
        userDetails: state.userDetails,
        users: state.users,
      };
    case "UPDATE_USER_DETAILS_COMPLETE":
      return {
        ...state,
        isLoading: false
      };
    case "RESET_USER_DETAILS_STATE":
      return {
        ...state,
        userDetails: new UserDetailsModel()
      };      
  }

  return state;
};
