import * as React from "react";
import * as ReactDOM from "react-dom";
import { Grid, GridColumn, GridColumnProps } from "@progress/kendo-react-grid";
import { User } from "../../../models/UserModels";
import "./GridView.scss";
import {
  IWizardValues
} from "../../../models/Wizard";

const GridView = (val: IWizardValues) => {
  const columns = (() => {
    const cols = [];
    const fieldDataColumns = val.lookupValues as any;
    const adjustColWidth = Math.max(600 / fieldDataColumns.length);

    for (let c = 0; c <= fieldDataColumns.length - 1; c++) {
      cols.push(<GridColumn field={fieldDataColumns[c].field} width={adjustColWidth} title={fieldDataColumns[c].title} />);
    }
    return cols;
  })();

  return (
    <div className="summary-grid-view">
      <Grid data={val.value as Array<User>}>{columns}</Grid>
    </div>
  );
};

export default GridView;
