/**
 * Standard Grid component wraps BaseGrid adding some filtering functionality.
 * It allows for simple filtering using contains only and one filter column only which occurs server side.
 * filterState is a separate copy of the filter so forced delays can occur before performing the filter call to the
 * server
 */
import React, { PureComponent } from "react";
import { v4 as uuid } from "uuid";
import { GridDataStateChangeEvent, GridColumnProps, GridSortChangeEvent } from "@progress/kendo-react-grid";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import { BaseGrid, IBaseGridProps, IGridParams as IBaseGridParams } from "./BaseGrid";
import { isBoolean } from "lodash";

//this is so that all consumers of StandardGrid don't have to alter their imports for IGridParams
export interface IGridParams extends IBaseGridParams { }

export interface IGridParamsEpiq extends IGridParams
{
  epiqOnly: boolean;
}

export interface IStandardGridProps<T, TT extends IGridParams> extends IBaseGridProps<T, TT> {
  noLoadOnMount?: boolean;
  filteredDataOnly: boolean | JSX.Element;
}

type Props<T, TT extends IGridParams> = IStandardGridProps<T, TT>;

type State<TT extends IGridParams> = {
  dataState: TT;
  filterState?: CompositeFilterDescriptor;
};

const filterDelay = 550;

export class StandardGrid<T, TT extends IGridParams> extends PureComponent<Props<T, TT>, State<TT>> {
  columns: Array<JSX.Element>;
  timeout: NodeJS.Timeout;
  bypassDataRequest: boolean;
  dataStateChanging: boolean;
  filteredColumn: GridColumnProps;

  constructor(props: Props<T, TT>) {
    super(props);

    this.filteredColumn = this.props.columns.find((value) => {
      return value.filterable;
    });

    this.state = {
      dataState: {
        skip: 0,
        take: 100,
        ...this.props.dataState,
        searchText:
          this.props.filteredDataOnly && this.props.dataState.searchText === ""
            ? uuid()
            : this.props.dataState.searchText || ""
      },
      filterState:
        this.filteredColumn && this.props.dataState.searchText !== ""
          ? {
              logic: "and",
              filters: [
                { field: this.filteredColumn.field, value: this.props.dataState.searchText } as FilterDescriptor
              ]
            }
          : this.props.dataState.filter
    };

    this.bypassDataRequest = this.props.noLoadOnMount || false;
    this.dataStateChanging = false;
    this.timeout = null;
  }

  handleOnDataStateChange({ data }: GridDataStateChangeEvent) {
    this.dataStateChanging = true;

    const filterValue = data.filter
      ? (data.filter.filters[0] as FilterDescriptor).value
      : this.props.filteredDataOnly
      ? uuid()
      : "";

    const applyDataState = () => {
      this.setState({
        ...this.state,
        dataState: {
          ...this.state.dataState,
          skip: data.skip,
          take: data.take,
          searchText: filterValue,
          filter: data.filter
        }
      });
      this.dataStateChanging = false;
    };

    this.setState({ ...this.state, filterState: data.filter }, () => {
      clearTimeout(this.timeout);

      if (data.filter !== null) {
        this.timeout = setTimeout(applyDataState, filterDelay);
      } else {
        applyDataState();
      }
    });
  }

  handleOnSortChange({ sort }: GridSortChangeEvent) {
    this.setState({
      ...this.state,
      dataState: {
        ...this.state.dataState,
        orderBy: sort[0].field,
        isAscending: sort[0].dir === "asc",
        sort: sort
      }
    });
  }

  //Need to allow a way to override the data state and force a grid refresh
  resetGridState(dataStateOverride?: TT) {
    this.setState({
      ...this.state,
      dataState: { ...this.state.dataState, ...this.props.dataState, ...(dataStateOverride || {}) },
      filterState: this.props.dataState.filter
    });
  }

  render() {
    const noRecordsRender = (): JSX.Element => {
      if (this.props.filteredDataOnly && (!this.state.filterState || this.dataStateChanging)) {
        if (!isBoolean(this.props.filteredDataOnly)) {
          return this.props.filteredDataOnly as JSX.Element;
        } else {
          return <p>Please filter to search for records.</p>;
        }
      } else {
        return this.props.noRecordsRender || <p>No results found for this search.</p>;
      }
    };

    const dataLoaderProps = {
      getDataAsync: async () => {
        if (!this.bypassDataRequest) {
          this.props.dataFetch(this.state.dataState);
        } else {
          this.bypassDataRequest = false;
        }
      },
      loading: this.props.showLoadingIndicator,
      dataState: this.state.dataState
    };

    return (
      <BaseGrid
        onDataStateChange={this.handleOnDataStateChange.bind(this)}
        dataState={this.state.dataState}
        onSortChange={this.handleOnSortChange.bind(this)}
        filter={this.props.showErrorState ? null : this.state.filterState}
        filterable={this.filteredColumn !== undefined}
        filterOperators={{ text: [{ text: "grid.filterContainsOperator", operator: "contains" }] }}
        sort={this.props.showErrorState ? null : this.state.dataState.sort}
        dataLoaderProps={dataLoaderProps}
        {...this.props}
        //because we do some custom handling of noRecordsRender we need it after {...this.prop} is passed
        noRecordsRender={noRecordsRender()}
      ></BaseGrid>
    );
  }
}
