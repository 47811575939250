import { ServiceBase, ResponseMessages, ServiceResponseJson, ServiceResponse, ServiceResponseStr } from "./ServiceBase";
import CommonHelper from "../components/common/utilities/CommonHelper";
import { IGridParams } from "../components/common/grid/AdvancedGrid";
import { ServiceNowDataSource } from "../components/servicerequest/common/RequestGrid";

export interface ITicketSubmitted {
  localDateString: string;
  localTimeString: string;
  localDate: Date;
  originalValue: Date;
}
export interface ISupportTicket {
  incidentId: string;
  project: string;
  supportTicketType: string;
  description: string;
  submitted: ITicketSubmitted;
  status: string;
  createdByExternalEmail?: string;
  createdByInternalEmail?: string;
  externalWatchListAsArray: Array<string>;
  sapBillToClient: string;
}
export interface ITicketPersona {
  email: string;
  firstName: string;
  lastName: string;
}
interface ITicketResponseDetails {
  callBackNumber: string;
  description: string;
  externalEmail: string;
  externalWatchList: string;
  externalWatchListAsArray: Array<string>;
  incidentId: string;
  project: string;
  projectId: number;
  shortDescription: string;
  status: string;
  submitted: Date;
  supportTicketType: string;
  contact: ITicketPersona;
  requestedBy: ITicketPersona;
  sysId: string;
  serviceNowDataSource: string;
  sapBillToClient: string;
}
export interface ITicketDetails {
  callBackNumber: string;
  description: string;
  externalEmail: string;
  externalWatchList: string;
  incidentId: string;
  project: string;
  projectId: number;
  shortDescription: string;
  status: string;
  submitted: string;
  supportTicketType: string;
  contact: ITicketPersona;
  requestedBy: ITicketPersona;
  externalWatchListAsArray: Array<string>;
  sysId: string;
  serviceNowDataSource: string;
  sapBillToClient: string;
}

export interface IAttachedFiles {
  sysId: string;
  file: string;
  size: number;
  contentType: string;
  createdDate: ITicketSubmitted;
}

export interface IAttachments extends IGridParams {
  incidentId?: string;
}

interface IActivityResponse {
  created: Date;
  value: string;
  element: string;
}

export interface IActivity {
  created: string;
  value: string;
  element: string;
}

export interface INewActivity {
  incident: string;
  comment: string;
  jobId: string;
  watchListDataSource: boolean;
}

export interface IAddFollowers {
  incident: string;
  externalWatchList: string;
  watchListDataSource: boolean;
}


const GetServiceRequests: ResponseMessages = {
  FailedMessage: "Failed to get service request list.",
  SuccessMessage: "Successfully retrieved technical support requests."
};

const GetRelativityGroups: ResponseMessages = {
  FailedMessage: "Failed to get relativity groups.",
  SuccessMessage: "Successfully retrieved relativity groups."
};

const GetAttachments: ResponseMessages = {
  FailedMessage: "Failed to get attachments.",
  SuccessMessage: "Successfully retrieved attachments."
};

const DownloadAttachment: ResponseMessages = {
  FailedMessage: "Failed to download attachment from the service now.",
  SuccessMessage: "Successfully downloaded attachment from the service now."
};

const AddFollowers: ResponseMessages = {
  FailedMessage: "Failed to add followers to the incident.",
  SuccessMessage: "Successfully added followers to the incident."
};

const GetFTPSiteUrls: ResponseMessages = {
  FailedMessage: "Failed to get ftp site url's.",
  SuccessMessage: "Successfully retrieved ftp site url's."
};

export default class SupportService extends ServiceBase {
  static async getTicketsByEmail(email: string) {
    let url = `${SupportService.getBaseUri()}/get-by-user?email=${email}`;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetServiceRequests))
      .catch(async response => await this.handleResponseJson(response, GetServiceRequests));

    return result;
  }

  static async getTicketById(id: string, dataSource: string = ServiceNowDataSource.UNKNOWN) {
    let url = `${SupportService.getBaseUri()}/${id}/${dataSource}`;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetServiceRequests))
      .catch(async response => await this.handleResponseJson(response, GetServiceRequests));

    if (result.ok) {
      result.data.results = result.data.results.map(
        (result: ITicketResponseDetails): ITicketDetails => {
          return {
            ...result,
            submitted: CommonHelper.convertUTCDateToLocalDateString(result.submitted),
            externalWatchListAsArray: result.externalWatchList ? result.externalWatchList.split(",") : []
          };
        }
      );
    }

    return result;
  }

  static async getUserTickets(ticketsRequest: IGridParams, workItems = false, watchListDataSource = false, byProject = false) {
    const filterJSON = JSON.stringify(ticketsRequest.filters) || "";
    const sortJSON = JSON.stringify(ticketsRequest.sort) || "";
    const url = `${SupportService.getBaseUri() + (workItems ? "/work-items" : "")}?${ServiceBase.getFilterSortQueryString(
      filterJSON,
      sortJSON
    )}&${this.toQueryString({ ...ticketsRequest, watchListDataSource, byProject }, ["filters", "sort", "filter"])}`;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetServiceRequests))
      .catch(async response => await this.handleResponseJson(response, GetServiceRequests));

    if (result.ok) {
      result.data.results = result.data.results.map(
        (result: ITicketResponseDetails): ISupportTicket => {
          const submittedAsLocalDate = new Date(result.submitted);

          return {
            ...result,
            externalWatchListAsArray: result.externalWatchList ? result.externalWatchList.split(",") : [],
            sapBillToClient: result.sapBillToClient,
            submitted: {
              localDateString: result.submitted ? submittedAsLocalDate.toLocaleDateString() : null,
              localTimeString: result.submitted ? submittedAsLocalDate.toLocaleTimeString() : null,
              localDate: result.submitted ? new Date(submittedAsLocalDate.toLocaleDateString()) : null,
              originalValue: result.submitted
            }
          };
        }
      );
    }

    return result;
  }

  static async GetTicketStatusCounts(workItems = false, watchListDataSource = false, byProject = false) {

    const url = `${SupportService.getBaseUri() + (workItems ? "/work-items-status-counts?byProject=" + byProject : "/get-ticket-status-counts?watchListDataSource=" + watchListDataSource)}`

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetServiceRequests))
      .catch(async response => await this.handleResponseJson(response, GetServiceRequests));

    return result;
  }

  static async getSearchWorkItemsByProject(ticketsRequest: IGridParams & { projectId: number }, watchListDataSource = false) {
    const filterJSON = JSON.stringify(ticketsRequest.filters) || "";
    const sortJSON = JSON.stringify(ticketsRequest.sort) || "";
    const url = `${SupportService.getBaseUri()}/search-work-items-by-project?${ServiceBase.getFilterSortQueryString(
      filterJSON,
      sortJSON
    )}&${ServiceBase.toQueryString({ ...ticketsRequest, watchListDataSource }, ["filters", "sort", "filter"])}`;

    const result: ServiceResponseJson = await fetch(url, ServiceBase.fetchOptions())
      .then(async response => await ServiceBase.handleResponseJson(response, GetServiceRequests))
      .catch(async response => await ServiceBase.handleResponseJson(response, GetServiceRequests));

    if (result.ok) {
      result.data.results = result.data.results.map(
        (result: ITicketResponseDetails): ISupportTicket => {
          const submittedAsLocalDate = CommonHelper.convertUTCDateToLocalDate(result.submitted);

          return {
            ...result,
            submitted: {
              localDateString: result.submitted ? submittedAsLocalDate.toLocaleDateString() : null,
              localTimeString: result.submitted ? submittedAsLocalDate.toLocaleTimeString() : null,
              localDate: result.submitted ? new Date(submittedAsLocalDate.toLocaleDateString()) : null,
              originalValue: result.submitted
            }
          };
        }
      );
    }

    return result;
  }

  static async getTicketTypes() {
    let url = `${SupportService.getBaseUri()}/get-ticket-types`;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetServiceRequests))
      .catch(async response => await this.handleResponseJson(response, GetServiceRequests));

    return result;
  }

  static async getAttachments(gridParams: IAttachments, watchListDataSource: boolean = false) {
    let url = `${SupportService.getBaseUri()}/get-attachments-by-incident/${gridParams.incidentId}/${watchListDataSource}`;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetAttachments))
      .catch(async response => await this.handleResponseJson(response, GetAttachments));

    if (result.ok) {
      result.data.results = result.data.results.map((result: IAttachedFiles) => {
        const createdAsLocalDate = CommonHelper.convertUTCDateToLocalDate(result.createdDate);

        return {
          ...result,
          createdDate: {
            localDateString: createdAsLocalDate.toLocaleDateString(),
            localTimeString: createdAsLocalDate.toLocaleTimeString()
          }
        };
      });
    }

    return result;
  }

  static async getAttachmentBySysId(id: string) {
    let url = `${SupportService.getBaseUri()}/get-attachment-by-sys/${id}`;

    const result: ServiceResponseJson = await fetch(url, this.fetchBlobOptions())
      .then(async response => await this.handleResponseJson(response, DownloadAttachment))
      .catch(async response => await this.handleResponseJson(response, DownloadAttachment));

    return result;
  }

  static async getActivity(id: string, watchListDataSource: boolean = false, showWorkNotes: boolean = false) {
    let url = `${SupportService.getBaseUri()}/activity/${id}/${watchListDataSource}/${showWorkNotes}`;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetServiceRequests))
      .catch(async response => await this.handleResponseJson(response, GetServiceRequests));

    if (result.ok) {
      result.data.results = result.data.results.map(
        (result: IActivityResponse): IActivity => {
          const createdAsLocalDate = CommonHelper.convertUTCDateToLocalDate(result.created);

          return {
            ...result,
            created: `${createdAsLocalDate.toLocaleDateString()} ${createdAsLocalDate.toLocaleTimeString()}`
          };
        }
      );
    }

    return result;
  }

  static async updateActivity(value: INewActivity) {
    let url = `${SupportService.getBaseUri()}/comment`;

    const result: ServiceResponse = await fetch(url, { body: this.toPostBody(value), ...this.fetchPostOptions() })
      .then(async response => await this.handleResponse(response, GetServiceRequests))
      .catch(async response => await this.handleResponse(response, GetServiceRequests));

    return result;
  }

  static async CanProjectHaveAttachments(salesforceProjectId: string) {
    let url = `${SupportService.getBaseUri()}/can-have-attachments?salesforceProjectId=${salesforceProjectId}`;

    const result: ServiceResponseStr = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseStr(response, GetServiceRequests))
      .catch(async response => await this.handleResponseStr(response, GetServiceRequests));

    return result;
  }

  static async GetWorkItem(workItemId: string, showMediaDetails: boolean) {
    let url = `${SupportService.getBaseUri()}/work-item?workItem=${workItemId}&showMediaDetails=${showMediaDetails}`;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetServiceRequests))
      .catch(async response => await this.handleResponseJson(response, GetServiceRequests));

    return result;
  }

  static async getFtpSiteUrls() {
    let url = `${SupportService.getBaseUri()}/ftp-sites`;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetServiceRequests))
      .catch(async response => await this.handleResponseJson(response, GetServiceRequests));

    return result;
  }

  static async getRelativityGroupsByWorkspace(baseAddress: string, workspaceId: number, keywords: string, name: string = "", logicOperator: string = "AND") {
    let url = `${SupportService.getBaseUri()}/get-relativity-groupsBy-workspace?baseAddress = ${baseAddress}&workspaceId = ${workspaceId}&keywords = ${keywords}&name = ${name}&logicOperator=${logicOperator}`;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetRelativityGroups))
      .catch(async response => await this.handleResponseJson(response, GetRelativityGroups));

    return result;
  }

  static async addFollowersToIncident(followers: IAddFollowers) {
    let url = `${SupportService.getBaseUri()}/add-followers-ticket`;

    const result: ServiceResponseJson = await fetch(url, { body: this.toPostBody(followers), ...this.fetchPostOptions() })
      .then(async response => await this.handleResponseJson(response, AddFollowers))
      .catch(async response => await this.handleResponseJson(response, AddFollowers));

    return result;
  }

  static getBaseUri() {
    return `${(<any>window).ApplicationVariables.apiUri}support`;
  }
}
