/**
 * Advanced Grid component wraps BaseGrid adding ability to handle filtering, including multiple column filtering, using
 * a extended type of IGridParams which adds a filters property of type IFilters which has a filters property as an
 * Array<CompositeFilterDescriptor> so the data can be passed server side for more easy handling of filters.
 * You can also support filtering multiple fields on a single filter by setting the multiFieldFilterDelimiter prop
 * and using that when defining your columns you pass to the grid.
 * EX: If you set the multiFieldFilterDelimiter="|" you can use it in the field property of the ColumnProp you pass in
 * { field: "field1|field2", title: "A TITLE", cell: getMyCellContent, filterable: true }
 * filterState is a separate copy of the filter for the following reasons.
 * 1. Use it as a way to force delays before performing the filter call to the server.
 * 2. Clean unaltered filter state which the grid uses to accurately show the filter state.
 * sortState is a separte copy of the sort for the following reasons.
 * 1. Clean unaltered sort state which the grid uses to accurately show the sort state.
 */
import React, { PureComponent, RefObject } from "react";
import { v4 as uuid } from "uuid";
import { GridDataStateChangeEvent, GridColumnProps, GridSortChangeEvent, GridColumnResizeEvent } from "@progress/kendo-react-grid";
import { CompositeFilterDescriptor, FilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import { GridFilterOperators } from "@progress/kendo-react-grid/dist/npm/interfaces/GridFilterOperators";
import { BaseGrid, IBaseGridProps, IGridColumnProps } from "./BaseGrid";
import { isBoolean } from "lodash";
import "./AdvancedGrid.scss";
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import bgMessages from '../../../Localization/en.json';
loadMessages(bgMessages, 'en-US');

export interface AdvancedSortDescriptor extends SortDescriptor {
  addedCoalesceFields?: Array<string>;
}

export interface IGridParams {
  skip?: number;
  take?: number;
  sort?: Array<SortDescriptor>;
  filter?: CompositeFilterDescriptor; // typically this is the initial property we recieve from Kendo grids.
  filters?: Array<CompositeFilterDescriptor>; // typically this is the property we set as we send filters over to the APIs
  queryGroupsInfo?: boolean;
  resourceGroupId?: number;
}

export interface IGridParamsAdvanced extends IGridParams {
  batchRequests?: Array<any>;
  sort?: Array<AdvancedSortDescriptor>;
}

export interface IGridParamsAdvancedRefreshable extends IGridParamsAdvanced {
  refreshToggle: boolean;
}

export interface IGridStateMerged<T> {
  items: T[];
  merged: T[];
  count: number;
  selections: Record<number | string, T>;
}

export interface IAdvancedGridProps<T, TT extends IGridParams> extends IBaseGridProps<T, TT> {
  noLoadOnMount?: boolean;
  filteredDataOnly: boolean | JSX.Element;
  multiFieldFilterDelimiter?: string;
  moreRecordsAvailableMessage?: string;
  hidePaper?: boolean;
  filterOperators?: GridFilterOperators;
  beforeDataStateChange?: (event: TT) => void;
  strongArmRefreshCount?: number;
  onColumnResize?: (event: GridColumnResizeEvent) => void;
}

type Props<T, TT extends IGridParams> = IAdvancedGridProps<T, TT>;

type State<TT extends IGridParams> = {
  dataState: TT;
  filterState?: CompositeFilterDescriptor;
  sortState?: Array<SortDescriptor>;
  adjustedColumns?: Array<GridColumnProps>;
};

const filterDelay = 550;

export class AdvancedGrid<T, TT extends IGridParams> extends PureComponent<Props<T, TT>, State<TT>> {
  columns: Array<JSX.Element>;
  timeout: NodeJS.Timeout;
  bypassDataRequest: boolean;
  dataStateChanging: boolean;
  unfilteredGuid: string;
  gridContainer: RefObject<HTMLDivElement>;
  gridRef: Element;
  resizeObserver: any;

  constructor(props: Props<T, TT>) {
    super(props);

    this.unfilteredGuid = uuid();
    this.state = {
      dataState: {
        skip: 0,
        take: 100,
        ...this.props.dataState,
        filters: this.props.dataState.filter && this.buildFilters(null, this.props.dataState.filter),
        sort: this.props.dataState.sort && this.buildDataStateSort(this.props.dataState.sort)
      },
      filterState: this.props.dataState.filter,
      sortState: this.props.dataState.sort
    };

    this.bypassDataRequest = this.props.noLoadOnMount || false;
    this.dataStateChanging = false;
    this.timeout = null;
    this.gridContainer = React.createRef();
  }

  componentDidMount() {
    if (this.props.columns.find((column: any /*IGridColumnProps*/) => column.preCalcPercentageWidth || column.preCalcFixedWidth)) {

      this.setColumnWidths();

      //@ts-ignore
      if (typeof ResizeObserver === "undefined") return;

      /* window.addEventListener("resize", this.setColumnWidths.bind(this)); */
      //@ts-ignore
      this.resizeObserver = new ResizeObserver((entries) => {
        this.setColumnWidths();
      });

      this.resizeObserver.observe(this.gridContainer.current);
    }
  }

  componentWillUnmount() {
    /* window.removeEventListener("resize", this.setColumnWidths); */
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  setColumnWidths() {

    if (!this.gridContainer.current) return;

    console.log("grid width:", this.gridContainer.current.offsetWidth);

    const width = this.gridContainer.current.offsetWidth;
    let numberOfLeftovers = 0;
    let widthTakenBeforeLeftovers = 0;

    const changedColumns = [... this.props.columns] as Array<IGridColumnProps>;

    for (let i = 0; i < changedColumns.length; i++) {

      if (changedColumns[i].preCalcFixedWidth) {
        const calcWidth = changedColumns[i].preCalcFixedWidth;
        changedColumns[i].width = calcWidth
        widthTakenBeforeLeftovers += calcWidth;
      }
      else if (changedColumns[i].preCalcPercentageWidth) {
        const percentageConvertedWidth = Math.floor(width * changedColumns[i].preCalcPercentageWidth / 100);
        changedColumns[i].width = percentageConvertedWidth;
        widthTakenBeforeLeftovers += percentageConvertedWidth;
      }
      else {
        numberOfLeftovers++;
      }
    }

    const roomLeft = width - widthTakenBeforeLeftovers;
    let eachLeftoverWidth = roomLeft < 10 ?
      Math.floor(width / numberOfLeftovers) /* no room left! Just let horizontal scroll bars run amok as our fallback! */ :
      Math.floor(roomLeft / numberOfLeftovers) /* each leftover column gets equal share of whatever is left */;

    for (let i = 0; i < changedColumns.length; i++) {
      if (!changedColumns[i].preCalcFixedWidth && !changedColumns[i].preCalcPercentageWidth) {
        changedColumns[i].width = eachLeftoverWidth;
      }
    }

    console.log("grid column calculation results: ", changedColumns);
    this.setState({ adjustedColumns: changedColumns })
  }

  handleOnDataStateChange({ data }: GridDataStateChangeEvent) {
    const newDataState = data as TT;

    if (this.props.beforeDataStateChange) {
      this.props.beforeDataStateChange(newDataState);
    }

    if (this.props.filteredDataOnly && !this.state.dataState.filter && !newDataState.filter) return;

    const newFilters = this.buildFilters(newDataState as TT);
    const filterState: CompositeFilterDescriptor = newDataState.filter !== null ? newDataState.filter : null;

    this.dataStateChanging = true;

    const applyDataState = () => {
      this.setState({
        ...this.state,
        dataState: {
          ...this.state.dataState,
          skip: newDataState.skip,
          take: newDataState.take,
          //this is the pure untouched filter from the kendo react grid which is needed for anyone using client side filter/sort
          filter: newDataState.filter,
          filters: newFilters,
          sort: this.buildDataStateSort(newDataState.sort)
        }
      });
      this.dataStateChanging = false;
    };

    this.setState({ ...this.state, filterState: filterState, sortState: newDataState.sort }, () => {
      clearTimeout(this.timeout);

      if (newDataState.filter !== null) {
        this.timeout = setTimeout(applyDataState, filterDelay);
      } else {
        applyDataState();
      }
    });
  }

  //Need to allow a way to override the data state and force a grid refresh
  resetGridState(dataStateOverride?: TT, clearFilter?: boolean, setFilter?: boolean) {

    if (clearFilter && dataStateOverride) {
      dataStateOverride.filter = null;
      dataStateOverride.filters = [];
    }

    let filterState = null;
    if (setFilter && dataStateOverride) {
      filterState = dataStateOverride.filter;
    } else if (!clearFilter && dataStateOverride) {
      filterState = this.props.dataState.filter;
    } else if (clearFilter && !dataStateOverride && setFilter) {
      filterState = this.props.dataState.filter;
    }

    this.setState({
      ...this.state,
      dataState: { ...this.state.dataState, ...(dataStateOverride || this.props.dataState) },
      filterState,
    });
  }

  handleOnColumnResize(event: GridColumnResizeEvent) {
    if (this.props.onColumnResize) {
      this.props.onColumnResize(event);
    }
  }

  render() {
    const noRecordsRender = (): JSX.Element => {
      if (this.props.filteredDataOnly && (!this.state.filterState || this.dataStateChanging)) {
        if (!isBoolean(this.props.filteredDataOnly)) {
          return this.props.filteredDataOnly as JSX.Element;
        } else {
          return <p>Please filter to search for records.</p>;
        }
      } else {
        return this.props.noRecordsRender || <p>No results found for this search.</p>;
      }
    };
    const dataLoaderProps = {
      getDataAsync: async () => {
        if (!this.bypassDataRequest) {
          this.props.dataFetch(this.state.dataState);
        } else {
          this.bypassDataRequest = false;
        }
      },
      loading: this.props.showLoadingIndicator,
      dataState: this.state.dataState,
      strongArmRefreshCount: this.props.strongArmRefreshCount
    };
    const moreRecordsAvailableMessage = this.props.data && this.props.data.length >= this.state.dataState.take
      ? this.props.moreRecordsAvailableMessage || "Too many results to display. Adjust filters to refine results"
      : null;
    const messageRender = (): JSX.Element => {
      return <span>{moreRecordsAvailableMessage}</span>;
    };
    const showMoreRecordsAvailableMessage = this.props.data && this.props.data.length >= this.state.dataState.take;
    const gridFooterClasses = `advanced-grid-footer ${showMoreRecordsAvailableMessage ? "message" : " "}`;

    return (
      <div className="advanced-grid-wrapper" ref={this.gridContainer} >
        <LocalizationProvider language="en-US">
          <IntlProvider locale="en" >
            <BaseGrid
              onDataStateChange={this.handleOnDataStateChange.bind(this)}
              dataState={this.state.dataState}
              //set onSortChange to null here so that base grid doesn't set it.  This enables onDataStateChange to fire on sort
              onSortChange={null}
              filter={this.props.showErrorState ? null : this.state.filterState}
              filterable={this.props.columns.find((column) => column.filterable) !== undefined}
              filterOperators={this.props.filterOperators || { text: [{ text: "grid.filterContainsOperator", operator: "contains" }] }}
              sort={this.props.showErrorState ? null : this.state.sortState}
              dataLoaderProps={dataLoaderProps}
              resizable={true}
              onColumnResize={this.handleOnColumnResize.bind(this)}
              {...this.props}
              //because we do some custom handling of noRecordsRender we need it after {...this.prop} is passed
              noRecordsRender={noRecordsRender()}
              columns={this.state.adjustedColumns || this.props.columns}
            ></BaseGrid>
          </IntlProvider>
        </LocalizationProvider>
        <div className={gridFooterClasses}>{showMoreRecordsAvailableMessage && messageRender()}</div>
      </div>
    );
  }

  private buildFilters(
    newDataState: TT,
    defaultFilter: CompositeFilterDescriptor = null
  ): Array<CompositeFilterDescriptor> {
    let filters = AdvancedGrid.buildDataStateFilter(newDataState, this.props.multiFieldFilterDelimiter);

    //this is to handle iniital state filters and when filters are cleared
    if (filters.length === 0) {
      if (defaultFilter) {
        filters.push(defaultFilter);
      } else if (this.state.dataState.filters && this.state.dataState.filters.length > 0) {
        filters.push({
          logic: "and",
          filters: [
            {
              ...this.state.dataState.filters[0].filters[0],
              value: this.props.filteredDataOnly ? this.unfilteredGuid : ""
            }
          ]
        });
      }
    }

    return filters;
  }

  private static buildDataStateFilter(
    newDataState: IGridParams,
    multiFieldFilterDelimiter: string
  ): Array<CompositeFilterDescriptor> {
    const buildFilterDescriptors = (filterDescriptor: FilterDescriptor): Array<FilterDescriptor> => {
      let theseFilters = new Array<FilterDescriptor>();
      const fields = (filterDescriptor.field as string).split(multiFieldFilterDelimiter);

      fields.forEach((field) => {
        theseFilters.push({ ...filterDescriptor, field });
      });

      return theseFilters;
    };
    let newFilters = new Array<CompositeFilterDescriptor>();

    if (newDataState && newDataState.filter && newDataState.filter.filters) {
      newDataState.filter.filters.forEach((filterDescriptor) => {
        const filters = buildFilterDescriptors(filterDescriptor as FilterDescriptor);

        newFilters.push({ logic: filters.length > 1 ? "or" : "and", filters });
      });
    }

    return newFilters;
  }

  private buildDataStateSort(sort: Array<SortDescriptor>): Array<SortDescriptor> {
    let sorts = new Array<SortDescriptor>();

    if (sort) {
      sort.forEach((sortDescripter) => {
        const fields = sortDescripter.field.split(this.props.multiFieldFilterDelimiter);

        fields.forEach((field) => {
          sorts.push({ ...sortDescripter, field });
        });
      });
    }

    return sorts;
  }
}
