import React from "react";
import {
  Dialog, DialogActionsBar
} from "@progress/kendo-react-dialogs";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Button, CircularProgress } from "@material-ui/core";
import { INotificationMessageModel } from "../../models/MessageModel";
import "./NotificationMessage.scss";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import ReactMarkdown from 'react-markdown';

interface IDialogBoxProps {
  visible?: boolean;
  diagCloseEvent?: (event: React.MouseEvent<HTMLElement>) => void;
  closeButtonLabelText?: string;
  notifications?: INotificationMessageModel[];
  isLoading?: boolean;
  dontShowMeAgainChangeEvent?: (event: CheckboxChangeEvent) => void;
  impersonateMode: boolean;
} 

const LinkRenderer = (props: any) => {
  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  );
}


const NotificationMessageDialog: React.FunctionComponent<IDialogBoxProps> = props => {

  const multipleNotificationWindow = () => {
    return (
      <>
        <div className="notifiction-header">
          Upcoming Maintenance Windows ({props.notifications.length})
        </div>
        <ul>
          {props.notifications.map((n) => {
            return (<li className="notification-resource">{n.title}</li>)
          })}
        </ul>
      </>
    )
  }

  const singleNotificationWindow = () => {
    return (
      <>
        <ReactMarkdown components={{ a: LinkRenderer }} children={props.notifications[0].message} />
      </>
    )
  }


  const hasRepeat = () => {
    return props.notifications.filter(x => x.repeat === true).length > 0
  }

  return (
    <span className="dialog-btn">
      {props.visible && (
        <Dialog
          closeIcon={false}
          aria-labelledby="customized-dialog-title"
          className="modal notification-modal"
        >
          <DialogTitle className="modal-title">
            {props.notifications.length === 1 ? props.notifications[0].title : `Alerts`}
          </DialogTitle>
          <DialogContent>
            <div className="notification">
              <div className="notification-content" spellCheck={false}  >
                {!props.isLoading ? (
                  <div>
                    {props.notifications.length === 1 ? singleNotificationWindow() :
                      multipleNotificationWindow()
                    }
                  </div>
                ) : (<div className="notification-loding">
                  <CircularProgress />
                </div>)}
              </div>
            </div>
          </DialogContent>
          <DialogActionsBar>
            <div className="notification-action-buttons">
              {hasRepeat() && !props.impersonateMode && (
                <div className="notification-dont-show-me">
                  <Checkbox
                    className="notification-dont-show-me-chk"
                    label={"Don’t show this to me again."}
                    disabled={props.isLoading}
                    onChange={(event: CheckboxChangeEvent) => { props.dontShowMeAgainChangeEvent(event) }}
                    name={"dontShowMeAgain"}
                  />
                </div>)}
              <div className="notification-btn-close">
                {props.closeButtonLabelText && (
                  <Button
                    variant="contained"
                    className="btn-contined"
                    color="primary"
                    name={props.closeButtonLabelText}
                    onClick={props.diagCloseEvent}
                    disabled={props.isLoading}
                  >
                    {props.closeButtonLabelText}
                  </Button>)}
              </div>
            </div>
          </DialogActionsBar>
        </Dialog>
      )}
    </span>
  );
};
export default NotificationMessageDialog;
