import React, { useEffect, useState, useRef } from "react";
import { FieldRenderProps } from "@progress/kendo-react-form";
import { updateValForField } from "./WizardElements";
import { IFieldControlButtons, IWizardLookupValues } from "../../../models/Wizard";
import SpeedTest from "../SpeedTest";
import { Dialog } from '@progress/kendo-react-dialogs';
import { DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ConsoleService from "../../../services/ConsoleService";
import './SecureFileUploadForm.scss';
import { IWizardStepProps, StandardWizard } from "./StandardWizard";
import { Popover } from "@progress/kendo-react-tooltip";

var proj = 'defaultProject';
var folder = 'defaultFolder';
var secureFormUrl = "defaultURL";

const SecureFileUploadForm = function (fieldRenderProps: FieldRenderProps) {
  const width = 750;
  const height = 750;
  const [frameLoaded, setFrameLoaded] = useState(false);
  const [showSpeedTestDialog, setShowSpeedTestDialog] = useState(false);
  const [buttons, setButtons] = useState([] as Array<IFieldControlButtons>);
  const [speedTestUrl, setSpeeTestUrl] = useState("");
  const successfulSubmission = useRef(false);
  const [showTips, SetShowTips] = useState(false);
  const divTipsElement = React.useRef<HTMLDivElement>(null);
  const onSpeedTestClick = () => {
    setShowSpeedTestDialog(true);
  };
  const onSpeedTestDialogCloseClick = () => {
    setShowSpeedTestDialog(false);
  };
  const onFrameLoad = function (e: React.SyntheticEvent<HTMLIFrameElement, Event>) {
    if (!frameLoaded) {

      setFrameLoaded(true);
      const { projectId, secureUploadDetails, appDbId } = fieldRenderProps.fieldData.GetDataParams(null, fieldRenderProps.fieldValues);

      ConsoleService.logSecureUploadState({
        projectId,
        appDbId,
        jobId: fieldRenderProps.jobId,
        formLocationId: secureUploadDetails.id,
        unsuccessfulLoad: false,
        submittedSuccessfully: false,
        formInitialized: true
      });
    }
    else {
      setTimeout(() => {
        const { projectId, secureUploadDetails, appDbId } = fieldRenderProps.fieldData.GetDataParams(null, fieldRenderProps.fieldValues);
        ConsoleService.logSecureUploadState({
          projectId,
          appDbId,
          jobId: fieldRenderProps.jobId,
          formLocationId: secureUploadDetails.id,
          unsuccessfulLoad: !successfulSubmission.current,
          submittedSuccessfully: successfulSubmission.current,
          formInitialized: false
        });
      }, 3000);
    }
  };

  useEffect(() => {
    var envsecureFormUrl = window.ApplicationVariables.secureFormUrl;
    const { secureUploadDetails } = fieldRenderProps.fieldData.GetDataParams(null, fieldRenderProps.fieldValues);

    setSpeeTestUrl(secureUploadDetails.speedTestUrl);

    var newURL = secureUploadDetails && secureUploadDetails.secureFormURL;
    console.log("********       oldURL", envsecureFormUrl);
    console.log("********       newURL", newURL);
    if (newURL) {
      secureFormUrl = newURL;
    } else {
      secureFormUrl = envsecureFormUrl;
    }
    console.log("******** secureFormUrl", secureFormUrl);

    var d = new Date();
    var month = d.getMonth() < 10 ? "0" + (d.getMonth() + 1).toString() : (d.getMonth() + 1).toString();
    var day = d.getDate() < 10 ? "0" + d.getDate().toString() : d.getDate().toString();
    var year = d.getFullYear();
    var hour = d.getHours() < 10 ? "0" + d.getHours().toString() : d.getHours().toString();
    var minute = d.getMinutes() < 10 ? "0" + d.getMinutes().toString() : d.getMinutes().toString();
    var datetime = year + month + day + hour + minute;
    console.log("datetime : ", datetime);

    folder = fieldRenderProps.jobId + "_" + datetime;
    secureFormUrl = secureFormUrl.concat("?foldername=").concat(folder);

    // updateValForField("secureFileUploadFolder", fieldRenderProps, "uploading...");
    window.addEventListener("message", (event) => {
      console.log("secureFileUpload event", event);

      if (event.data === "UploadSuccess") {
        successfulSubmission.current = true;
        const lookupValues = { ftpSiteUrl: secureUploadDetails && secureUploadDetails.ftpSiteUrl } as IWizardLookupValues;
        updateValForField("UploadSuccess", fieldRenderProps, folder, lookupValues);
      }
    }, false);

    setButtons([{
      text: "GO BACK",
      onClick: onSpeedTestDialogCloseClick,
      isAlignLeft: true
    }]);

  }, []);

  const speedTestSteps = new Array<IWizardStepProps>(
    {
      stepConfig: {
        label: "Request: Use Easy Upload"
      },
      stepContent: () => {
        return (
          <>
            <h2> SPEED TEST</h2>
            <p>This test can provide current information about the speed associated with your network connection to Epiq.  This information can be helpful if a transfer is taking more time than expected.</p>
            <SpeedTest
              height={423}
              width={700}
              speedTestUrl={speedTestUrl}
              jobId={fieldRenderProps.jobId}
            />
          </>
        );
      }
    }
  );

  return (
    <>
      <div className="dv-upload-tips" ref={divTipsElement} onClick={(e) => {
        SetShowTips(true);
      }} >
        <span className="upload-tips-icon"> <i className="fa-regular fa-message-question"></i></span>
        Tips for Uploading
      </div>
      <div>
        <Popover
          show={showTips}
          anchor={divTipsElement.current && divTipsElement.current}
          position={"top"}
          callout={true}
          className={"secure-form-upload-tips-popover"}
        >
          <div className="popover">
            <div className="xmark"><CloseIcon htmlColor="#406FD9" onClick={() => SetShowTips(!showTips)} /></div>
            <div className="popover-tips-head">
              <span className="upload-tips-icon"> <i className="fa-regular fa-message-question"></i></span>
              <b>Tips for Uploading</b>
            </div>
            <div className="pop-content">Streamline your upload speed and performance by implementing these best practices.</div>
            <ul className="pop-content-sub">
              <li>Zip files before uploading them, including PST files. </li>
              <li> Upload 5GB to 10GB per zip file as a best practice.</li>
              <li> Smaller zip files transfer faster. Consider breaking a large  zip file into multiple smaller zip files.</li>
            </ul>
            <div className="pop-content-footer">Easy Upload lets you transfer files to Epiq any time you need to, at your convenience.</div>
          </div>
        </Popover>
      </div>
      <div id='' className="easy-upload-form-content">
        <iframe width='100%' height='320px' className='secure-form-iframe' id='secureFormIframe' onLoad={onFrameLoad} src={secureFormUrl}
          sandbox="allow-downloads
            allow-forms
            allow-same-origin
            allow-scripts"
        />
        <div className="easy-upload-form-sub-content">
          <div>
            <div className="secure-file-error-message">
              <span className="info-icon">Upload your files and click Submit.  Then, click Next.</span>
            </div>
          </div>
          {!!speedTestUrl && <button type={"button"} onClick={onSpeedTestClick}
            className="k-button btn-secondary k-button-icontext speed-test-button">
            <span className="fa-regular fa-wrench button-icon"></span>
            RUN SPEED TEST
          </button>}
        </div>
      </div>
      {showSpeedTestDialog && <div className="speed-test-form-content">
        <Dialog onClose={() => { }} width={width} height={height} closeIcon={true}>
          <DialogTitle className="modal-title">
            Request: Use Easy Upload
            <IconButton
              className="modal-close"
              aria-label="close"
              disabled={false}
              onClick={onSpeedTestDialogCloseClick}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <StandardWizard
              hideStepIndicator={true}
              hideFooterStepIndicator={true}
              buttonDefinitions={buttons}
              steps={speedTestSteps}
              onSubmitClick={() => { }}
            >
            </StandardWizard>
          </DialogContent>
        </Dialog>
      </div>}
    </>
  );
}

export default SecureFileUploadForm;
