import * as React from "react";
import { connect } from "react-redux";
import * as HeaderStore from "../../../../store/Header";
import { IApplicationState } from "../../../../store";
import * as UserSession from "../../../../store/Session";
import * as UserActivityStore from "../../../../store/UserActivity";
import {
  GridCellProps,
  GridColumnProps
} from "@progress/kendo-react-grid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import Paper from "@material-ui/core/Paper";
import { Button } from "@progress/kendo-react-buttons";
import "./UserActivity.scss";
import ExportUserActivity from "./ExportUserActivity";
import { AdvancedGrid, IGridParams } from "../../../common/grid/AdvancedGrid";
import { UserActivity } from "../../../../models/UserActivityModels";
import { FilterDescriptor } from "@progress/kendo-data-query";
import * as Lodash from "lodash";

interface IProps {
  history: any;
}

type Props = IProps &
  HeaderStore.IHeaderState &
  typeof HeaderStore.actionCreators &
  UserActivityStore.IUserActivityState &
  typeof UserActivityStore.actionCreators &
  UserSession.ISessionState &
  typeof UserSession.actionCreators;

type State = {
  dataState: IGridParams;
  exportDisable: boolean;
  exportModal: boolean;
};

class UserActivityList extends React.PureComponent<Props, State> {
  lastSelectedIndex: any;
  export: any;
  grid: any;
  exportFileName: string;
  columns: Array<GridColumnProps>;
  myGrid: AdvancedGrid<UserActivity, IGridParams>;

  constructor(props: Props) {
    super(props);

    this.state = {
      dataState: {
        skip: 0,
        take: 100,
        sort: [{ field: "when", dir: "desc" }]
      },
      exportModal: false,
      exportDisable: false
    };

    this.columns = new Array<GridColumnProps>(
      {
        field: "when",
        format: "{0:g}",
        title: "DATE/TIME",
        filterable: true,
        filter: "date"
      },
      {
        field: "actor.userName",
        title: "USER INFO",
        filterable: true,
        filter: "text"
      },
      {
        field: "action",
        title: "ACTIVITY",
        filterable: true,
        filter: "text"
      },
      {
        field: "description",
        title: "EVENT DESCRIPTION",
        filterable: true,
        filter: "text"
      },
      {
        field: "targets",
        title: "Targets",
        filterable: true,
        filter: "text",
        sortable: false,
        headerClassName: "no-sort",
        cell: this.getTargetInfoCell
      },
      {
        field: "additionalInfo",
        title: "ADDITIONAL INFO",
        filterable: true,
        filter: "text",
        sortable: false,
        headerClassName: "no-sort",
        cell: this.getAdditionalInfoCellContent
      }
    );

    const date = new Date();

    this.exportFileName = `Admin Activity ${("0" + (date.getMonth() + 1).toString()).substr(-2) +
      ("0" + date.getDate().toString()).substr(-2) +
      date.getFullYear().toString()
      }.xls`;
  }

  getTargetInfoCell(props: any) {
    var dataItem = props.dataItem;
    let symbol = (dataItem.targets[0].id && dataItem.targets[0].name) ? '-' : '';

    if (dataItem.targets[0].id === dataItem.targets[0].name) {
      return (<td>{dataItem.targets[0].id}</td>);
    }

    return (<td>{dataItem.targets[0].id} {symbol} {dataItem.targets[0].name}</td>);
  }
  getAdditionalInfoCellContent(props: GridCellProps) {
    console.log(props);
    const cellValue =
      props.dataItem["additionalInfo"] && props.dataItem["additionalInfo"].length > 0
        ? `${props.dataItem["additionalInfo"][0].value}`
        : "";

    return <td>{cellValue}</td>;
  }
  fetchActivity(gridParams: IGridParams) {
    const activityListFilters = gridParams.filters
      ? gridParams.filters.map(compositeFilter => {
        const dateTypeFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "when"
        ) as FilterDescriptor;

        if (dateTypeFilter) {
          let updatedFilters = new Array<FilterDescriptor>();

          if (Lodash.isDate(dateTypeFilter.value)) {
            const startDate = new Date(dateTypeFilter.value.setHours(0, 0, 0, 0));
            const endDate = new Date(dateTypeFilter.value.setHours(23, 59, 59, 999));

            updatedFilters.push({ ...dateTypeFilter, field: "When", value: new Date(startDate.toUTCString()), operator: "gte" } as FilterDescriptor);
            updatedFilters.push({ ...dateTypeFilter, field: "When", value: new Date(endDate.toUTCString()), operator: "lte" } as FilterDescriptor);
          } else {
            updatedFilters.push({ ...dateTypeFilter, field: "When", value: new Date(new Date("01/01/1970").toUTCString()), operator: "lte" } as FilterDescriptor);
          }

          return {
            ...compositeFilter,
            filters: updatedFilters
          };
        }

        return compositeFilter;
      })
      : [];

    this.setState({ ...this.state, dataState: { ...gridParams, filters: activityListFilters } as IGridParams });
    this.props.getUserActivity({ ...gridParams, filters: activityListFilters } as IGridParams);
  }

  filterElements = (element: any) => {
    if (element.tagName === "EM") {
      return true;
    }
    return false;
  };

  activityEventDescription(props: any) {
    const description = props.dataItem.description;
    return (
      <td>
        <div className="user-activity">
          <p>
            {" "}
            <em title={description}>{description}</em>{" "}
          </p>
        </div>
      </td>
    );
  }

  exportGrid = async () => {
    this.setState({ ...this.state, exportModal: true });
  };

  handleExportModalClose = () => {
    this.setState({ ...this.state, exportModal: false });
  }

  handleExportClick() {
    this.setState({ ...this.state, exportModal: true });
  }

  private targetInfoCell(props: any) {
    var dataItem = props.dataItem;
    let symbol = (dataItem.targets[0].id && dataItem.targets[0].name) ? '-' : '';

    if (dataItem.targets[0].id === dataItem.targets[0].name) {
      return (<td>{dataItem.targets[0].id}</td>);
    }

    return (<td>{dataItem.targets[0].id} {symbol} {dataItem.targets[0].name}</td>);
  }

  gridToolBarRender(): JSX.Element {

    if (!this.props.sessionData.permissions.has("EpiqAdminExportActivityView")) return <></>;

    return (
      <div className="toolbar-button-container">
        <Button className="" primary={true} onClick={this.handleExportClick.bind(this)} >
          EXPORT
        </Button>
      </div>
    );
  }

  componentWillUnmount = () => {
    this.props.setUserActivityToDefault();
  }

  render() {
    return (
      <>
        <Paper className="base-grid-wrapper user-activity-grid">
          <div>
            <Tooltip openDelay={2} position="right" filter={this.filterElements}>
              <div id="activity-list-grid">
                <AdvancedGrid
                  ref={(gridInstance: AdvancedGrid<UserActivity, IGridParams>) => {
                    this.myGrid = gridInstance;
                  }}
                  showErrorState={this.props.hasError}
                  showLoadingIndicator={this.props.isLoading}
                  sortable={{ mode: "single" }}
                  data={this.props.userActivity}
                  dataFetch={this.fetchActivity.bind(this)}
                  dataState={this.state.dataState}
                  columns={this.columns}
                  paging={false}
                  totalRecords={this.props.totalUserActivityCount}
                  noRecordsRender={<p>No activity found.</p>}
                  noLoadOnMount={true}
                  filteredDataOnly={<p>Please filter to search for activity.</p>}
                  multiFieldFilterDelimiter="|"
                  filterOperators={{
                    text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
                    date: [{ text: "grid.filterEqOperator", operator: "eq" }]
                  }}
                  gridToolbarContent={this.gridToolBarRender()}
                />
              </div>
            </Tooltip>
          </div>
        </Paper>
        <ExportUserActivity
          handleExportModalClose={this.handleExportModalClose}
          isExportSelectorOpen={this.state.exportModal}
        />
      </>
    );
  }
}

export default connect(
  (state: IApplicationState) => ({ ...state.headerState, ...state.userActivityState, ...state.sessionState }),
  {
    ...HeaderStore.actionCreators,
    ...UserActivityStore.actionCreators
  }
)(UserActivityList as any);
