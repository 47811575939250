import * as React from "react";
import { Input } from "@progress/kendo-react-inputs";
import ErrorIcon from "@material-ui/icons/Error";
import { ChipList, ChipProps, Chip, ChipListDataChangeEvent, Button } from "@progress/kendo-react-buttons";
import "./TextInputChipList.scss";
import { InputChangeEvent } from "@progress/kendo-react-inputs/dist/npm/input/interfaces/InputChangeEvent";

export enum ChipValidationRules {
  Email = "Email",
  Number = "Number",
  CustomValidation = "CustomValidation"
}

export enum ValidationRules {
  Required = "Required",
  Email = "Email",
  Number = "Number",
  CustomValidation = "CustomValidation"
}

enum InternalValidationRules {
  Unique = "Unique"
}

export interface ITextInputChipProps extends ChipProps {
  isValid: boolean;
}

interface IValidationBase {
  predicate?: string;
  errorMessage: string;
}

export interface IValidation extends IValidationBase {
  rule: ValidationRules;
}

interface IInternalValidation extends IValidationBase {
  rule: InternalValidationRules;
}

interface IValidationResultBase {
  isValid: boolean;
  isComponentValidation: boolean;
}
interface IValidationResult extends IValidationResultBase {
  validationResult: string;
}

interface ITextInputChipListProps {
  label?: string;
  placeholder?: string;
  validations?: Array<IValidation>;
  name: string;
  chipsRemovable?: boolean;
  existingChips?: Array<ITextInputChipProps>;
  onValueChange?: (value: string, name?: string, isValid?: boolean) => void;
  onBlur?: (value: string, name?: string, isValid?: boolean) => void;
  onDataChange?: (newState: Array<ITextInputChipProps>, name?: string, isValid?: boolean, inputText?: string) => void;
  noInputText?: string;
  inputText?: string;
}

type Props = ITextInputChipListProps;

type State = {
  data: Array<ITextInputChipProps>;
  value: string;
  errorMessage: string;
  chipErrorMessage: string;
  isTouchedOnce: boolean;
};

const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);

export class TextInputChipList extends React.Component<Props, State> {
  validations: Array<IValidation | IInternalValidation>;
  inputValueIsValid: boolean;

  constructor(props: Props) {
    super(props);

    this.state = {
      data: props.existingChips || new Array<ITextInputChipProps>(),
      value: props.inputText || "",
      errorMessage: "",
      chipErrorMessage: "",
      isTouchedOnce: props.inputText ? true : false
    };

    //Need to ensure required is the last validation we check so that the non component level messages show if input value is
    //invalid and not the required message.
    this.validations = [...this.props.validations.filter(v => v.rule !== ValidationRules.Required)];
    //insert a internal validation to enforce unique values
    this.validations.push({ rule: InternalValidationRules.Unique, errorMessage: "Duplicate value found in list." });

    if (this.props.validations.length > this.validations.length) {
      var requiredValidationIndex =
        this.props.validations && this.props.validations.findIndex(v => v.rule === ValidationRules.Required);

      if (requiredValidationIndex > -1) {
        this.validations = [...this.validations, ...this.props.validations.splice(requiredValidationIndex, 1)];
      }
    }
  }

  componentDidMount() {
    const { isValid, isComponentValidation } = this.validate();
    this.inputValueIsValid = isComponentValidation ? true : isValid;
  }

  handleOnValueChange(event: InputChangeEvent) {
    const value = event.value;

    this.setState({ value }, () => {
      const { isValid, isComponentValidation } = this.validate();

      this.inputValueIsValid = isComponentValidation ? true : isValid;

      if (this.props.onValueChange) {
        this.props.onValueChange(value, this.props.name, isValid);
      }
    });
  }

  handleOnDataChange(event: ChipListDataChangeEvent) {
    this.setState({ data: event.value }, async () => {
      const { isValid } = this.validate();

      this.inputValueIsValid = isValid;

      if (this.props.onDataChange) {
        this.props.onDataChange(event.value, this.props.name, isValid, this.state.value);
      }
    });
  }

  handleOnKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter" && this.state.value.length > 0 && this.inputValueIsValid) {
      this.insertChip();
    }
  }

  handleOnBlur(event: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>) {
    return setTimeout(async () => {
      const { isValid } = this.validate();

      if (this.props.onBlur) {
        this.props.onBlur(event.currentTarget.value.trim(), this.props.name, isValid);
      }
    }, 100);
  }

  handleOnFocus() {
    this.setState({ isTouchedOnce: true });
  }

  handleOnAddClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (this.state.value.length > 0 && this.inputValueIsValid) {
      this.insertChip();
    }
  }

  render() {
    const shouldDisplayError = this.state.isTouchedOnce && this.state.errorMessage.length > 0;

    return (
      <div className="text-input-chip-list-container">
        <div className={shouldDisplayError ? "form-input has-error" : "form-input"}>
          <label>{this.props.label}</label>
          <div className="input-container">
            <Input
              value={this.state.value}
              type="text"
              placeholder={this.props.placeholder}
              name={this.props.name}
              onChange={this.handleOnValueChange.bind(this)}
              onKeyPress={this.handleOnKeyPress.bind(this)}
              onFocus={this.handleOnFocus.bind(this)}
              onBlur={this.handleOnBlur.bind(this)}
            />
            <Button disabled={(this.state.value.length > 0 && this.state.errorMessage === "") ? false : true} primary={true} onClick={this.handleOnAddClick.bind(this)}>Enter</Button>
          </div>
          <div className={`chip-list-container ${this.state.data.length > 0 ? "has-items" : "is-empty"}`}>
            {this.state.data.length == 0 && this.props.noInputText ? <span>{this.props.noInputText}</span> : <></>}
            <ChipList
              data={this.state.data}
              onDataChange={this.handleOnDataChange.bind(this)}
              chip={(props: ChipProps) => (
                <>
                  <Chip {...props} type={props.dataItem.type} removable={this.props.chipsRemovable || false} />
                </>
              )}
            />
          </div>
          {shouldDisplayError ? (
            <div className="error-info">
              <ErrorIcon className="error-icon" />
              <span className="erro-msg">{this.state.errorMessage}</span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }

  private insertChip() {
    const newChip: ITextInputChipProps = {
      text: this.state.value,
      value: this.state.value,
      type: "info",
      isValid: true
    };

    this.setState({ data: [...this.state.data, newChip], value: "" }, async () => {
      const { isValid } = this.validate();

      if (this.props.onDataChange) {
        this.props.onDataChange(this.state.data, this.props.name, isValid);
      }
    });
  }

  private runValidations(validations: Array<IValidation | IInternalValidation>): IValidationResult {
    let validationResult: string = "";
    let isComponentValidation: boolean = false;

    if (validations) {
      for (let i = 0; i < validations.length; i++) {
        const validationToRun = validations[i];

        isComponentValidation = validationToRun.rule === ValidationRules.Required;
        validationResult = this.validator(validationToRun);

        if (validationResult) {
          break;
        }
      }
    }

    return { isValid: !validationResult, validationResult, isComponentValidation };
  }

  private validate(): IValidationResultBase {
    if (!this.validations || this.validations.length === 0 || !this.state.isTouchedOnce)
      return { isValid: true, isComponentValidation: true };

    const { isValid, validationResult, isComponentValidation } = this.runValidations(this.validations);

    this.setState({ errorMessage: validationResult });

    return { isValid, isComponentValidation };
  }

  private validator(validation: IValidation | IInternalValidation) {
    const { value, data } = this.state;

    switch (validation.rule) {
      case ValidationRules.Email:
        return value === "" || emailRegex.test(value) ? "" : validation.errorMessage || "Invalid.";
      case InternalValidationRules.Unique:
        return !data.find(chip => chip.text === value) ? "" : validation.errorMessage;
      case ValidationRules.Required:
        return data.length > 0 ? "" : validation.errorMessage;
      case ValidationRules.CustomValidation:
        return validation.predicate && validation.predicate === "true" ? validation.errorMessage : "";
      default:
        return "";
    }
  }
}
