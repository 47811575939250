import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";

export interface IInternalMessagingState {
  requestAdminContextClearCount:number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface ISetNotificationRequestAdminContextClearCount {
  type: "SET_REQUEST_ADMIN_CONTEXT_CLEAR_COUNT";
  requestAdminContextClearCount:number;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = ISetNotificationRequestAdminContextClearCount;

export const actionCreators = {
  requestAdminContextClear: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch({
        type: "SET_REQUEST_ADMIN_CONTEXT_CLEAR_COUNT",
        requestAdminContextClearCount: appState.internalMessagingState.requestAdminContextClearCount + 1
      });
    }
  }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: IInternalMessagingState = {
  requestAdminContextClearCount: 0
};

export const reducer: Reducer<IInternalMessagingState> = (
  state: IInternalMessagingState | undefined,
  incomingAction: Action
): IInternalMessagingState => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "SET_REQUEST_ADMIN_CONTEXT_CLEAR_COUNT":
      return { ...state, requestAdminContextClearCount: (action as ISetNotificationRequestAdminContextClearCount).requestAdminContextClearCount };
    default:
      return state;
  }
};
