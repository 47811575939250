import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
//import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import { IApplicationState, reducers } from "./";

export default function configureStore(initialState?: IApplicationState) {
  const middleware = [thunk];

  const rootReducer = combineReducers({
    ...reducers
  });

  console.log("process.env.NODE_ENV currently set to :" + process.env.NODE_ENV);

  if (process.env.NODE_ENV === "development") {
    //middleware.push(reduxImmutableStateInvariant());
  }

  const enhancers = [];
  const windowIfDefined = typeof window === "undefined" ? null : (window as any);
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
  }

  return createStore(rootReducer, initialState, compose(applyMiddleware(...middleware), ...enhancers));
}
