import * as React from "react";
import { IAssignmentDetailsParams, IResourceGroupResourceParams } from "../../../models/ResourceModels";
import { Permission } from "../../../models/Role";
import AdminService from "../../../services/AdminService";
import "./Assignments.scss";
import ResourceService from "../../../services/ResourceService";
import { IGroupUserRequestParams } from "../../../models/RequestModels";
import { Grid as KendoGrid, GridColumn, GridColumnProps, GridCellProps, GridNoRecords } from "@progress/kendo-react-grid";
import { Grid, Paper, CircularProgress } from "@material-ui/core";
import { ResourceTypes } from "../../../models/Enums";
import GridHeader from "../../common/grid/GridHeader";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { AdvancedGrid, IGridParams } from "../../common/grid/AdvancedGrid";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { EmployerDomain } from "../../../models/EmployerModel";

type Props = {
  resourceId: number;
  resourceType: number;
  epiqOnly: string;
  automated?: string;
  description: string;
};

type assignmentDetails = {
  name: string,
  username: string,
  when: string,
  lastLogin: string,
  resourceType: string
}

type State = {
  gridItems: Array<assignmentDetails>;
  isLoading: boolean;
  hasError: boolean;
  domains: Array<EmployerDomain>;
};

class AssignmentDetails extends React.PureComponent<Props, State> {
  myGrid: AdvancedGrid<assignmentDetails, IAssignmentDetailsParams>;
  dataState: IAssignmentDetailsParams = {
    skip: 0,
    take: 500,
    searchValue: "",
    sort: []
  };
  pendingRequest: any;
  requestStarted = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      gridItems: [],
      isLoading: false,
      hasError: false,
      domains: []
    }
  }

  getGridResults = async (dataState: IAssignmentDetailsParams) => {
    const { resourceType } = this.props

    if (this.requestStarted) {
      clearTimeout(this.pendingRequest);
      this.pendingRequest = setTimeout(() => {
        this.getGridResults(dataState);
      }, 300);
      return;
    }

    this.requestStarted = true;
    this.setState({ isLoading: true });
    switch (resourceType) {
      case ResourceTypes.USER_GROUP:
        await this.getUserGroupUsers(dataState);
        break;
      case ResourceTypes.ROLE:
        await this.getRolesPermissions(dataState);
        break;
      case ResourceTypes.RESOURCE_GROUP:
        await this.getResourceGroupsResources(dataState);
        break;
    }
    this.setState({ isLoading: false });
    this.requestStarted = false;
  }

  async getUserGroupUsers(dataState: IAssignmentDetailsParams) {

    const searchValue = dataState && dataState.searchValue ? dataState.searchValue : null;

    const params: IGroupUserRequestParams = {
      ...dataState,
      groupId: this.props.resourceId,
    }

    var filter = searchValue != null ? [
      {
        logic: "or",
        filters: [{
          field: "firstName",
          operator: "contains",
          value: searchValue
        },
        {
          field: "lastName",
          operator: "contains",
          value: searchValue
        },
        {
          field: "username",
          operator: "contains",
          value: searchValue
        }]
      }] as CompositeFilterDescriptor[] : [];

    const result = await AdminService.getGroupUsers({ ...params, filters: filter });
    const assignmentDetails = result && result.data && result.data.results ? result.data.results.map((item: any) => {
      const resourceResult = {
        name: item.lastName + ", " + item.firstName,
        username: item.username,
        when: item.createdDate,
        lastLogin: item.lastLoginDate
      } as assignmentDetails;

      return resourceResult;
    }) : [];

    const groupDomainResult = await AdminService.getUserGroupDomainsDetails(this.props.resourceId);
    const domainDetails = groupDomainResult.ok && groupDomainResult.data ? groupDomainResult.data.map((ed: EmployerDomain) => {
      const domainResult = {
        id: ed.id,
        domain: ed.domain,
      } as EmployerDomain;

      return domainResult;

    }) : [];

    this.setState({
      hasError: !result.ok,
      gridItems: assignmentDetails,
      domains: domainDetails
    });
  }

  async getRolesPermissions(dataState: IAssignmentDetailsParams) {

    const searchValue = dataState && dataState.searchValue ? dataState.searchValue : null;

    var filter = searchValue != null ? [{
      logic: "or",
      filters: [{
        field: "permissionName",
        operator: "contains",
        value: searchValue
      }]
    } as CompositeFilterDescriptor] as CompositeFilterDescriptor[] : [];

    const result = await AdminService.getPermissionsListByRoleId(this.props.resourceId,
      { ...dataState, filters: filter }
    );

    const assignmentDetails = result && result.data && result.data.results ? result.data.results.map((item: Permission) => {
      const resourceResult = {
        name: item.permissionName
      } as assignmentDetails;

      return resourceResult;
    }) : [];

    this.setState({
      hasError: !result.ok,
      gridItems: assignmentDetails
    });
  }

  async getResourceGroupsResources(dataState: IAssignmentDetailsParams) {

    const searchValue = dataState && dataState.searchValue ? dataState.searchValue : null;
    const params: IResourceGroupResourceParams = {
      ...dataState,
      resourceGroupId: this.props.resourceId
    }

    var filter = searchValue != null ? [{
      logic: "or",
      filters: [{
        field: "resourceTypeNavigation.displayName",
        operator: "contains",
        value: searchValue
      },
      {
        field: "name",
        operator: "contains",
        value: searchValue
      }]
    } as CompositeFilterDescriptor] as CompositeFilterDescriptor[] : [];

    let defaultSort = [{ field: 'name', order: 'asc' }]

    const result = await ResourceService.getResourceGroupResources({ ...params, filters: filter, sort: defaultSort });
    const assignmentDetails = result && result.data && result.data.results ? result.data.results.map((item: any) => {
      const resourceResult = {
        name: item.name,
        resourceType: item.resourceTypeNavigation ? item.resourceTypeNavigation.displayName : ""
      } as assignmentDetails;

      return resourceResult;
    }) : [];

    this.setState({
      hasError: !result.ok,
      gridItems: assignmentDetails
    });
  }

  getNameCell = (props: GridCellProps) => {
    const { username, name } = props.dataItem;
    const resourceType = this.props.resourceType;

    if (resourceType == ResourceTypes.USER_GROUP) {
      return (
        <td>
          <span title={name}>{name}</span><br />
          <span title={username}>{username}</span>
        </td>
      );
    }

    return <td>{name}</td>
  }

  getDescriptionCell = (props: GridCellProps) => {
    const lastLogin = props.dataItem.lastLogin ? new Date(props.dataItem.lastLogin).toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit' }) : "";
    const resourceType = this.props.resourceType;

    if (resourceType == ResourceTypes.USER_GROUP) {
      return (
        <td title={lastLogin}>{lastLogin}</td>
      );
    }

    return <td>{props.dataItem.resourceType}</td>
  }

  search = async (value: string) => {
    await this.getGridResults({ ...this.dataState, searchValue: value });
  }

  columns = () => {
    let columns = [
      {
        field: "name",
        title: "Name",
        cell: this.getNameCell,
        filterable: false,
        sortable: false,
        headerClassName: "no-sort"
      }
    ] as GridColumnProps[];

    if (this.props.resourceType != 1) {
      columns.push({
        field: "option",
        title: this.props.resourceType == 0 ? "Last Login" : "Resource Type",
        cell: this.getDescriptionCell,
        filterable: false,
        sortable: false,
        headerClassName: "no-sort"
      });
    }

    return columns;
  };

  render() {
    const { resourceType } = this.props;
    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <div className="description-panel">
              <PanelBar expandMode="single">
                <PanelBarItem title="Description" expanded={true}>
                  <fieldset className="description">
                    {this.props.description}
                  </fieldset>
                </PanelBarItem>
              </PanelBar>
            </div>
          </Grid>
          <Grid item xs={12}>
            <GridHeader
              searchButton={false}
              searchDisabled={false}
              searchPlaceholder={"Search"}
              onChangeSearch={this.search}
              minSearchCharacters={3}
              headerTypeName={this.props.epiqOnly}
              automated={this.props.automated}
              displayCount={this.state.gridItems.length}
              groupDomains={this.state.domains}
            />
            <div className={resourceType != ResourceTypes.RESOURCE_GROUP ? "resourceGrid" : ""}>
              <AdvancedGrid
                ref={(gridInstance: AdvancedGrid<assignmentDetails, IAssignmentDetailsParams>) => {
                  this.myGrid = gridInstance;
                }}
                showErrorState={this.state.hasError}
                showLoadingIndicator={this.state.isLoading}
                data={this.state.gridItems}
                dataFetch={this.getGridResults}
                dataState={this.dataState}
                columns={this.columns()}
                paging={false}
                noRecordsRender={<p>No information to display.</p>}
                noLoadOnMount={false}
                filteredDataOnly={false}
                multiFieldFilterDelimiter="|"
                total={this.state.gridItems ? this.state.gridItems.length : 0}
                moreRecordsAvailableMessage="Displaying first 500 items. Click View Details to refine results."
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
};

export default AssignmentDetails;
