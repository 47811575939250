import React, { useEffect, useRef, useState } from "react";
import { Dialog } from '@progress/kendo-react-dialogs';
import CloseIcon from "@material-ui/icons/Close";
import { DialogContent, DialogTitle, IconButton, DialogActions, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { IAdminContext } from "../../../models/AdminContext";
import { ComboBox, ComboBoxChangeEvent, ComboBoxFilterChangeEvent, ListItemProps } from "@progress/kendo-react-dropdowns";
import * as SessionStore from "../../../store/Session";
import * as InternalMessagingStore from "../../../store/InternalMessaging";
import { IApplicationState } from "../../../store";
import { IRelClientCase } from "../../../models/RelativityModel";
import { Button } from "@progress/kendo-react-buttons";
import AdminContextService from "../../../services/AdminContextService";
import NotificationService from "../../../services/NotificationService";

import "./ContextSwitcher.scss";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import { SessionManager } from "../../../SessionManager";
import { AdminRoutes, adminRoutes } from "../../../models/Routing";

interface IProps {
  match?: any;
}

type Props = IProps & SessionStore.ISessionState & InternalMessagingStore.IInternalMessagingState & typeof SessionStore.actionCreators;

const ContextSwitcher = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [switchingProgress, setSwitchingProgress] = useState(false);
  const [contexts, setContexts] = useState<IAdminContext[]>([]);
  const [hasNoRelClients, setHasNoRelClients] = useState(props.context.hasNoRelClients);
  const [selectedContext, setSelectedContext] = useState<IAdminContext>(props.context.admin);
  const [clients, setClients] = useState<IRelClientCase[]>([]);
  const [showClientComboBox, setShowClientComboBox] = useState(props.context && props.context.admin ? !props.context.hasNoRelClients && !props.context.onlyOne : false);
  const [onlyOneClient, setOnlyOneClient] = useState(false);
  const [selectedClient, setSelectedClient] = useState<IRelClientCase>(props.context.relativityClient);
  const [sessionManager] = useState(new SessionManager());

  useEffect(() => {
    if (props.requestAdminContextClearCount > 0 && selectedContext) {
      clearContext();
      NotificationService.showWarningToast("Context cleared: This page manages users outside of the Client Admin context.");
    }
  },[props.requestAdminContextClearCount]);

  useEffect(() => {
    if (!props.context.hasNone) {
      getContexts();
    }

    if (selectedContext && props.context && props.context.admin && selectedContext.contextResourceGroupId != props.context.admin.contextResourceGroupId) {
      // the other tab changed the context, update to reflect changes
      setSelectedContext(props.context.admin);
      getContextClients(props.context.admin.contextResourceGroupId);
    } else if (!props.context.admin) {
      setSelectedContext(props.context.admin);
      setClients([]);
    } else if (selectedContext) {
      getContextClients(selectedContext.contextResourceGroupId);
    }

  }, [props.context]);

  const closePopup = function () {
    setOpen(false);
  };

  const getContexts = async (clientFilter?: string) => {
    //setIsLoading(true);
    const result = await AdminContextService.getMyAdminContext(clientFilter ? { filters: [{ logic: "and", filters: [{ field: "name", operator: "contains", value: clientFilter }] }] } : {});

    if (result.ok) {
      setContexts(result.data.results);
    } else {
      setHasError(true);
      NotificationService.showErrorToast("Something went wrong while fetching admin contexts.");
    }

    setIsLoading(false);
  };

  const getContextClients = async (contextResourceGroupId: number, clientFilter?: string) => {
    //setIsLoading(true);

    const filters = [{ logic: "and", filters: [{ field: "ResourceGroupId", operator: "eq", value: contextResourceGroupId }] }] as CompositeFilterDescriptor[];

    if (clientFilter) {

      const nameOrCaseFilters = [{ field: "RelClientName", operator: "contains", value: clientFilter }] as FilterDescriptor[];
      const filterAsNumber = Number(clientFilter);

      if (!isNaN(filterAsNumber)) {
        nameOrCaseFilters.push({ field: "RelativityClientId", operator: "eq", value: filterAsNumber });
      }

      filters.push({ logic: "or", filters: nameOrCaseFilters });
    }

    const result = await AdminContextService.getContextRelClients({ filters, sort: [{field: "RelClientName", dir: "asc"}] });

    if (!result.ok) {
      setHasError(true);
      NotificationService.showErrorToast("Something went wrong while fetching relativity clients.");
      setIsLoading(false);
      setHasNoRelClients(false);
      return;
    }

    setClients(result.data.results);

    if (clientFilter) {
      setIsLoading(false);
      return;
    }

    // We typically only reach here per each new context.
    if (result.data.results.length === 1) {
      setSelectedClient(result.data.results[0]);
      setShowClientComboBox(true);
      setHasNoRelClients(false);
      setOnlyOneClient(true);
      setSelectedClient(result.data.results[0]);
    }
    else if (result.data.results.length > 1) {
      setShowClientComboBox(true);
      setHasNoRelClients(false);
      setOnlyOneClient(false);
    }
    else {
      setShowClientComboBox(false);
      setHasNoRelClients(true);
    }

    setIsLoading(false);
  };


  const contextComboBoxFilterChange = (event: ComboBoxFilterChangeEvent) => {
    if (!event) return;

    const { filter } = event;

    getContexts(filter && filter.value ? filter.value : null);
  };

  const clientComboBoxFilterChange = (event: ComboBoxFilterChangeEvent) => {
    if (!event) return;

    const { filter } = event;

    getContextClients(selectedContext.contextResourceGroupId, filter && filter.value ? filter.value : null);
  };

  const contextSelectionChange = async (event: ComboBoxChangeEvent) => {
    if (event) {
      if (event.value !== null) {
        setSelectedContext(event.value);

        getContextClients(event.value.contextResourceGroupId, null);
        setSelectedClient(null);
      }
      else {
        setSelectedContext(null);
        setSelectedClient(null);
        setShowClientComboBox(false);
      };
    }
  };

  const relClientSeletionChange = async (event: ComboBoxChangeEvent) => {
    setSelectedClient(event.value);
  };

  const clearContext = () => {
    setSelectedContext(null);
    setSelectedClient(null);
    setShowClientComboBox(false);
    closePopup();

    sessionManager.setPreference({
      prefName: "ContextAdmin",
      value: {
        resourceGroupId: null,
        uniqueClientId: null
      }
    });

    sessionManager.setContext({
      ...props.context,
      admin: null,
      relativityClient: null
    });
  }

  const isEpiqAdmin = () => {
    return props.sessionData.permissions.has("EpiqAdminGetApplication") ||
      props.sessionData.permissions.has("EpiqAdminGetUser") ||
      props.sessionData.permissions.has("EpiqAdminListUsers") ||
      props.sessionData.permissions.has("EpiqAdminListApplication");
  }

  const onSwitchClick = async () => {

    sessionManager.setPreference({
      prefName: "ContextAdmin",
      value: !selectedContext ? null : {
        resourceGroupId: selectedContext.contextResourceGroupId,
        uniqueClientId: selectedClient ? selectedClient.uniqueClientId : null
      }
    });

    sessionManager.setContext({
      ...props.context,
      admin: selectedContext,
      relativityClient: selectedClient,
      hasErrors: false,
      hasNoRelClients
    });

    setOpen(false);
  }

  const relClientDDRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {

    const item = (
      <div key={itemProps.id} className="dd-item-details">
        <div className="dd-item-name">
          <em title={itemProps.dataItem.name}>{itemProps.dataItem.name}</em>
        </div>
        <p>
          {" "}
          <em title={itemProps.dataItem.relativityClientId}>{itemProps.dataItem.relativityClientId}</em>{" "}
        </p>
      </div>
    );
    return React.cloneElement(li, li.props, item);
  };

  if (props.context.hasNone) return <></>;

  return (
    <>
      <div className="current-admin-context">Context: {props.context.admin ? props.context.admin.name : "(none)"}
        {props.context.relativityClient && <> - Client: {props.context.relativityClient.name}
        </>}
        <span className="open-context-options">
          {!props.context.onlyOne && <Button onClick={e => setOpen(true)} ><i className="fa-solid fa-ellipsis-vertical"></i></Button>}
        </span>
      </div>
      {open && <div className="admin-context-switcher-popup support-popup">
        <Dialog aria-labelledby="customized-dialog-title" closeIcon={true}>
          <DialogTitle className="modal-title">{"Select Context"}
            <IconButton
              className="modal-close"
              aria-label="close"
              disabled={false}
              onClick={closePopup}
            ><CloseIcon /></IconButton>
          </DialogTitle>
          <DialogContent>
            <div className="admin-context-switcher-content">
              {isLoading && <CircularProgress size={20} />}
              {!isLoading && <ComboBox
                data={contexts}
                textField="name"
                filterable={true}
                defaultValue={selectedContext}
                dataItemKey="id"
                onFilterChange={contextComboBoxFilterChange}
                onChange={contextSelectionChange}
                placeholder="Please select admin context"
              />}
              {!isLoading && showClientComboBox && <><br /><ComboBox
                data={clients}
                textField="name"
                defaultValue={selectedClient}
                disabled={onlyOneClient}
                itemRender={relClientDDRender}
                filterable={true}
                onFilterChange={clientComboBoxFilterChange}
                onChange={relClientSeletionChange}
                placeholder="Please select relativity client"
              /></>}
            </div>
          </DialogContent>
          <DialogActions className="modal-footer">
            {isEpiqAdmin() && < Button
              onClick={clearContext}
              className="btn btn-secondary"
              disabled={switchingProgress}
            >
              CLEAR
            </Button>}
            <Button
              onClick={closePopup}
              className="btn btn-secondary"
              disabled={switchingProgress}
            >
              CANCEL
            </Button>
            <Button
              color="primary"
              className="btn-primary"
              disabled={switchingProgress}
              onClick={() => { console.log("click"); onSwitchClick(); }}
            >
              {switchingProgress && <CircularProgress size={14} />}
              SET
            </Button>
          </DialogActions>
        </Dialog>
      </div>}
    </>);
}

export default connect((state: IApplicationState) => ({ ...state.sessionState, ...state.internalMessagingState }), {
  ...SessionStore.actionCreators,
})(ContextSwitcher);