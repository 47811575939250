import React, { useState, useEffect } from "react";
import { Switch } from "@progress/kendo-react-inputs";
import { Checkbox } from "@progress/kendo-react-inputs";
import "./SettingsAdmin.scss";
import { ServiceResponseData, ServiceResponseJson } from "../../services/ServiceBase";
import SingleSelectDropDown, { SingleSelectDropdownProps } from "./SingleSelectDropDown";

export enum SettingTypes {
  check = 0,
  options = 1,
  singleSelect = 2
}

export interface IAdminSettings {
  id: number;
  name: string;
  options?: Array<IAdminSettingsOptions>;
  enabled: boolean;
}

export interface ISettingsChangeEvent extends IAdminSettings {
  changedOptionId?: number;
  optionIndex: number;
}

export interface IAdminSettingsOptions {
  id: number;
  name: string;
  type: SettingTypes;
  value: boolean | string | number;
  singleSelectOptions?: SingleSelectDropdownProps;
  fullWidth?: boolean;
}

interface IProps {
  onChange: (event: ISettingsChangeEvent) => void;
  value: IAdminSettings;
}

export default (props: IProps) => {

  return <div className="settings-admin-control">
    <label>{props.value.name}</label>

    <div className="options">
      <>
        <div className="settings-name">Status</div>
        <div className="settings-admin-control-enabled">
          <Switch
            onChange={(event) => { props.onChange({ ...props.value, enabled: !props.value.enabled, optionIndex: -1 }); }}
            checked={props.value.enabled}
          />
        </div>
        {props.value.enabled && props.value.options && props.value.options.map((o, idx) => 
          <>
            {!o.fullWidth && <div key={`settingsname${idx}${props.value.id}`} className="settings-name">{o.name}</div>}
            <div key={`settingsctrl${idx}${props.value.id}`} className={"settings-ctrl " + (o.fullWidth ? "settings-ctrl-full-space" : "")}>

              {o.fullWidth && <p>{o.name}</p>}

              {o.type === SettingTypes.check && <Checkbox
                value={o.value}
                className=""
                onChange={(e) => {
                  const updateOptions = [...props.value.options];
                  updateOptions[idx].value = e.value;
                  props.onChange({ ...props.value, options: updateOptions, changedOptionId: o.id, optionIndex: idx });
                }}
              />}
              {o.type === SettingTypes.singleSelect && <SingleSelectDropDown
                {...o.singleSelectOptions}
                onChange={(e) => {

                  if (!e) return;

                  const updateOptions = [...props.value.options];
                  updateOptions[idx].value = e.data;
                  props.onChange({ ...props.value, options: updateOptions, changedOptionId: o.id, optionIndex: idx });
                }}
              />}
            </div>
          </>
        )}
      </>
    </div>
  </div>
};