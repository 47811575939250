import { ApplicationFeature, ApplicationFeature as AppModel } from "../../../models/ApplicationModels";
import ConsoleService from "../../../services/ConsoleService";

export default class ApplicationNav {

  static relappCheckTimeout = parseInt(window.ApplicationVariables.relativityAutoReEnableTimeout);

  static async waitLinkReady(application: ApplicationFeature) {
    if (application.autoReenable === 0) { // don't need to wait if appType isn't 2/relativity
      return;
    }

    const abortController = typeof AbortController === undefined ? null : new AbortController();

    const preNavigateTask = ConsoleService.preNavigate(application.id, abortController ? abortController.signal : null);

    if (abortController) {
      setTimeout(() => abortController.abort(), ApplicationNav.relappCheckTimeout);
      await preNavigateTask;
    } else {
      await ApplicationNav.ie11Waiter(ApplicationNav.relappCheckTimeout, preNavigateTask);
    }
  }

  static async ie11Waiter(maxWaitTimeMs: number, task: Promise<any>) {
    return new Promise<void>(resolve => {
      setTimeout(resolve, maxWaitTimeMs);
      task.then(r => resolve());
    });
  }
}