import React from "react";
import { StandardWizard, IWizardStepProps } from "../../common/wizard/StandardWizard";
import FileUploadStep, { IFileInfo } from "./FileUploadStep";
import SingleSelectDropdown, { ISingleSelectDropSelectedEvent, IStandardFilterParams } from "../../common/SingleSelectDropDown";
import { ComboBoxChangeEvent, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import ResourceService, { IResourceGroupPermission } from "../../../services/ResourceService";
import { IResourceGroups } from "../../../models/ResourceModels";
import "./FileValidation.scss";
import { JobTypeEnum } from "../../../models/Enums";

type Props = {
  onFinished: (url?: string) => void;
  allowMultiple?: boolean;
  jobType?: number
};

type State = {
  validationComplete: boolean;
  filesReadyForValidation: boolean;
  activeStep: number;
  file?: IFileInfo;
};

class CdnUploadWizard extends React.Component<Props, State> {
  uploadWizardSteps: Array<IWizardStepProps>;

  constructor(props: Props) {
    super(props);

    this.state = {
      validationComplete: false,
      filesReadyForValidation: false,
      activeStep: 0
    };

    this.uploadWizardSteps = new Array<IWizardStepProps>(
      {
        stepConfig: {
          label: "Select & Upload Files",
          isValid: undefined,
          validator: () => (this.state.validationComplete),
        },
        stepContent: () => {
          return (<FileUploadStep
            onFilesReadyForValidation={this.onFilesReadyForValidation.bind(this)}
            onFileUploaded={this.onFileUploaded.bind(this)}
            onValidationComplete={this.onValidationComplete.bind(this)}
            allowedExtensions={new Array<string>(".gif", ".jpg", ".png")}
            maxFileSize={1048576}
            allowMultiple={this.props.allowMultiple != null ? this.props.allowMultiple : true}
            jobType={this.props.jobType || JobTypeEnum.CdnUpload} />);
        }
      }
    )
  }

  handleOnStepChanged(activeStep: number) {
    //check if we are going backwards, if so we need to reset state stuff. 
    if (this.state.activeStep > activeStep) {
      this.setState({ ...this.state, activeStep, validationComplete: false, filesReadyForValidation: false });
    } else {
      this.setState({ ...this.state, activeStep });
    }
  }

  onFilesReadyForValidation() {
    console.log("rdy");
    this.setState({ ...this.state, filesReadyForValidation: true });
  }

  onValidationComplete(invalidFiles: Array<IFileInfo>) {
    this.setState({ ...this.state, validationComplete: true });
  }

  onFileUploaded(file: IFileInfo) {
    this.setState({ ...this.state, file });
  }

  onSubmit() {
    this.props.onFinished(this.state.file ? this.state.file.url : null);
  }

  render() {
    const lastStepButtonLabel = !this.state.filesReadyForValidation || this.state.filesReadyForValidation && this.state.validationComplete ? "Finish" : "Validating";

    return (
      <StandardWizard
        steps={this.uploadWizardSteps}
        lastStepButtonLabel={lastStepButtonLabel}
        onSubmitClick={this.onSubmit.bind(this)}
        onStepChanged={this.handleOnStepChanged.bind(this)}
        disablePreviousStep={this.state.filesReadyForValidation || this.state.validationComplete}
      />
    );
  }
}

export default CdnUploadWizard;