import { IFeature, IFeatureManager } from "../../../../../models/Features";
import AutoReenableFeatureManager from "./AutoReenableFeatureManager";
import WorkspaceViewManager from "./WorkspaceViewManager";
import BasicFeatureManager from "./BasicFeatureManager";
import RelativityAccountConfigurationManager from "./RelativityAccountConfigurationManager";

export default class FeatureManager implements IFeatureManager
{
  private autoReenableFeatureManager: AutoReenableFeatureManager;
  private workspaceViewManager: WorkspaceViewManager;
  private relativityAccountConfigurationManager: RelativityAccountConfigurationManager;
  private basicFeatureManager: BasicFeatureManager;
  public appId: number;

  public constructor(appId: number) {
    this.appId = appId;
    this.autoReenableFeatureManager = new AutoReenableFeatureManager(appId);
    this.workspaceViewManager = new WorkspaceViewManager(appId);
    this.relativityAccountConfigurationManager = new RelativityAccountConfigurationManager(appId);
    this.basicFeatureManager = new BasicFeatureManager(appId);
  }

  private getFeatureManager(featureId: number): IFeatureManager {

    switch (featureId) {
      case 1:
        return this.autoReenableFeatureManager;
      case 2:
      case 5:
        return this.basicFeatureManager;
      case 3:
        return this.workspaceViewManager;
      case 4:
        return this.relativityAccountConfigurationManager;
      default:
        console.error(`This feature (${featureId}) is not yet implemented with a feature manager`);
        return null;
    }
  }

  public createAppFeatures(featureId: number) {
    const fm = this.getFeatureManager(featureId);

    if (fm == null) return [];

    return fm.createAppFeatures(featureId);
  }

  public getKeyByIndex(featureId: number, optionIdx: number) {
    const fm = this.getFeatureManager(featureId);

    if(fm == null) return null;

    return fm.getKeyByIndex(featureId, optionIdx);
  }

  public getAdminSettingOptions(feature: IFeature) {
    const fm = this.getFeatureManager(feature.id);

    if (fm == null) return null;

    return fm.getAdminSettingOptions(feature);
  }
}
