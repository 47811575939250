import React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import * as HeaderStore from "../../store/Header";
import * as SessionStore from "../../store/Session";
import ResourceService from "../../services/ResourceService";
import { GridColumnProps } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Dialog, DialogCloseEvent } from "@progress/kendo-react-dialogs";
import { StandardGrid } from "../common/grid/StandardGrid";
import CommandCell from "../common/grid/columns/CommandCell";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { IResourceParams } from "../../models/ResourceModels";
import { formatDate } from "@telerik/kendo-intl";
import FileUploadWizard from "./FileUploadWizard/FileUploadWizard";
import FileService from "../../services/FileService";
import NotificationService from "../../services/NotificationService";
import { AdvancedGrid, IGridParams } from "../common/grid/AdvancedGrid";
import { FilterDescriptor } from "@progress/kendo-data-query";
import "./FileList.scss";
import _ from "lodash";

interface IProps {
  history: any;
}

interface IFileDataItemFields {
  "Description": string;
  "Doc Guid": string;
  Extension: string;
  "First Name": string;
  "Last Name": string;
  "Last Modified Date": string;
  Path: string;
}

interface IFileDataItem {
  description: string;
  displayName: string;
  groupDetails: string;
  objectId: number;
  permissionCodes: Array<string>;
  fields: IFileDataItemFields;
}

interface IFileState {
  totalFiles: number;
  files: Array<IFileDataItem>;
}

type Props = IProps &
  HeaderStore.IHeaderState &
  typeof HeaderStore.actionCreators &
  SessionStore.ISessionState &
  typeof SessionStore.actionCreators;

type State = {
  dataState: IResourceParams;
  loading: boolean;
  data: IFileState; //TODO: dfine a type
  hasError: boolean;
  showUploadWizard: boolean;
};

class FileList extends React.Component<Props, State> {
  columns: Array<GridColumnProps>;

  constructor(props: Props) {
    super(props);

    this.state = {
      dataState: {
        skip: 0,
        take: 1000,
        orderBy: "", //TODO: set order by property
        isAscending: true,
        sort: [{ field: "", dir: "asc" }], //TODO: set order by property
        typeCode: "file",
        showGroupsWithDupePermissions: true,
        joinFields: ["User"]
      },
      loading: false,
      data: {
        totalFiles: 0,
        files: new Array<IFileDataItem>()
      },
      hasError: false,
      showUploadWizard: false
    };

    this.columns = new Array<GridColumnProps>(
      { field: "displayName", title: "FILE NAME", filter: "text", filterable: true },
      { field: "groupDetails.resourceGroupName", filter: "text", title: "RESOURCE GROUP", filterable: true },
      { field: "fields.Extension", title: "FILE TYPE", format: "{0:g}" },
      { field: "fields.Last Name", title: "ADDED BY", cell: this.getUploadedBy },
      { field: "fields.Last Modified Date", title: "UPLOADED", format: "{0:g}", cell: this.getUploadedDate },
      {
        title: "",
        cell: CommandCell({
          onRemove: this.onRemove.bind(this),
          onDownload: this.onDownload.bind(this),
          hideDownload: (dataItem: any) => { return !dataItem.permissionCodes.find((p: string) => p === "ViewFile") },
          hideRemove: (dataItem: any) => { return !dataItem.permissionCodes.find((p: string) => p === "RemoveFile") }
        }),
        sortable: false,
        headerClassName: "no-sort"
      }
    );
  }

  async onDownload(dataItem: IFileDataItem) {
    const result = await FileService.getDownloadUrl(dataItem.objectId);

    if (result.ok) {
      await FileService.downloadFile(result.data.downloadUrl, 3, dataItem.displayName);
    }
  }

  async onRemove(dataItem: IFileDataItem) {
    if (!window.confirm("Are you sure you want to delete this?")) {
      return;
    }
    this.setState({ ...this.state, loading: true });

    const deletionResult = await FileService.deleteFile(dataItem.objectId);

    if (deletionResult.ok) {
      const indexToRemove = this.state.data.files.findIndex(fileDataItem => fileDataItem.objectId === dataItem.objectId);
      const files = [...this.state.data.files];

      files.splice(indexToRemove, 1);

      this.setState({ ...this.state, data: { ...this.state.data, files, totalFiles: this.state.data.totalFiles - 1 }, loading: false });
    } else {
      this.setState({ ...this.state, loading: false });
      NotificationService.showErrorToast(`Failed to delete file ${dataItem.displayName}.  Please try again or contact your administrator.`);
    }
  }

  getUploadedBy(props: any) {
    const uploadedBy = `${props.dataItem.fields["Last Name"]}, ${props.dataItem.fields["First Name"]}`;

    return (<td><span>{uploadedBy}</span></td>);
  }

  getUploadedDate(props: any) {
    const formattedValue = formatDate(new Date(props.dataItem.fields["Last Modified Date"]), "g");

    return (<td><span>{formattedValue}</span></td>);
  }

  componentWillUnmount() {
    this.props.setHeaderButtons(null);
  }

  async getFiles(dataState: IResourceParams) {
    this.setState({ ...this.state, loading: true }, async () => {
      const result = await ResourceService.getResourcesUser(this.requestResources(dataState), null);

      this.setState({
        ...this.state,
        loading: false,
        hasError: !result.ok,
        data: {
          ...this.state.data,
          files: result.ok ? result.data.results as Array<IFileDataItem> : new Array<IFileDataItem>(),
          totalFiles: result.ok ? result.data.count : 0
        }
      });
    });
  }

  requestResources(dataState: IResourceParams) {
    const request = _.cloneDeep(dataState);
    request.filters = [];

    if (request.filter) {
      for (let i = 0; i < request.filter.filters.length; i++) {
        const filter = request.filter.filters[i] as FilterDescriptor; // so far we don't nest our filters
        switch (filter.field) {
          case "displayName":
            filter.field = filter.field.toString().replace(filter.field, "File Name");
            break;
          case "groupDetails.resourceGroupName":
            filter.field = filter.field.toString().replace(filter.field, "ResourceGroupName");
            break;
        }
      }
      request.filters.push({ logic: "and", filters: request.filter.filters });
    }

    if (request.sort && request.sort.length) {
      request.orderBy = request.sort[0].field; // only one sort column is supported at this time
      request.isAscending = request.sort[0].dir === "asc";
    }

    return request;
  };

  async onRetryClick() {
    await this.getFiles(this.state.dataState);
  }

  onUploadFileClick() {
    this.setState({ ...this.state, showUploadWizard: true });
  }

  gridToolBarRender(): JSX.Element {
    const toolbarContent = this.props.sessionData.permissions.has("UploadFile") ?
      (<Button className="upload-file-btn" primary={true} onClick={this.onUploadFileClick.bind(this)} >upload</Button>) :
      null;

    return toolbarContent;
  }

  onFileUploadWizardClose(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | DialogCloseEvent, reloadGrid: boolean = false) {
    this.setState({ ...this.state, showUploadWizard: false }, () => {
      if (reloadGrid) {
        this.onRetryClick();
      }
    });
  }

  render() {
    return (
      <>
        <div className="files-grid-wrapper">
          <Tooltip openDelay={2} position="right" >
            <AdvancedGrid
              showErrorState={this.state.hasError}
              showLoadingIndicator={this.state.loading}
              data={this.state.data.files}
              dataFetch={this.getFiles.bind(this)}
              dataState={this.state.dataState}
              columns={this.columns}
              paging={false}
              totalRecords={{ value: this.state.data.files.length, label: "Files" }}
              noRecordsRender={(<p>No files found.</p>)}
              noLoadOnMount={false}
              filteredDataOnly={false}
              multiFieldFilterDelimiter="|"
              filterOperators={{
                text: [{ text: "grid.filterContainsOperator", operator: "contains" }]
              }}
              gridToolbarContent={this.gridToolBarRender()}
            />
          </Tooltip>
        </div>
        {
          this.state.showUploadWizard && (
            <Dialog
              onClose={this.onFileUploadWizardClose.bind(this)}
              aria-labelledby="customized-dialog-title"
              className="modal files-list-file-upload-modal"
            >
              <DialogTitle className="modal-title">
                File Upload Wizard
                <IconButton
                  className="modal-close"
                  aria-label="close"
                  disabled={false}
                  onClick={this.onFileUploadWizardClose.bind(this)}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <FileUploadWizard onFinished={this.onFileUploadWizardClose.bind(this, null, true)} allGroups={this.props.sessionData.superAdmin} />
              </DialogContent>
            </Dialog>
          )
        }
      </>
    );
  }
}

export default connect((state: IApplicationState) => ({ ...state.headerState, ...state.sessionState }), {
  ...HeaderStore.actionCreators,
  ...SessionStore.actionCreators
})(FileList as any);
