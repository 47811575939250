import React, { useLayoutEffect, useState } from "react";

import "./ViewMore.scss";

export interface IViewMoreProps {
    children?: (() => JSX.Element) | JSX.Element | JSX.ElementClass | string;
    lineHeight?: number;
}

export default (props: IViewMoreProps) => {
    const ref = React.createRef<HTMLDivElement>();
    const [showMore, setShowMore] = useState(false);
    const [showLink, setShowLink] = useState(false);
    
    useLayoutEffect(() => {
      if (ref.current && (ref.current.clientHeight < ref.current.scrollHeight)) {
        setShowLink(true);
      }
    }, [ref]);

    if (!props.children) return <></>;

    const toggleViewMore = () => {
      setShowMore(!showMore)
    };

    const style = props.lineHeight && !showMore ? { maxHeight: props.lineHeight } : null;

    return (<>
        <div ref={ref} className={showMore ? "" : "viewmore-partial-container"} style={style}>
          {props.children}
        </div>
        {showLink && (<>{!showMore && <>... </>}
        <span className="viewmore-link" onClick={toggleViewMore}>
          {showMore ? "view less" : "view more"}
        </span>
      </>)}
    </>)
}