import * as React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import * as SessionStore from "../../store/Session";
import IdleTimer from "react-idle-timer";
import SessionTimeoutModal from "./SessionTimeoutModal";
import { IIdleTimer, ISessionManager } from "../../SessionManager";

interface IProps {
  sessionManager: ISessionManager;
}

type Props = IProps & SessionStore.ISessionState;

const SessionTimeout = (props: Props) => {
  return (
    <React.Fragment>
      <IdleTimer
        ref={(ref: IIdleTimer) => {
          props.sessionManager.warningTimer = ref;
        }}
        element={document}
        onIdle={props.sessionManager.handleSessionIdle.bind(props.sessionManager)}
        onAction={props.sessionManager.handleSessionActionTracked.bind(props.sessionManager)}
        timeout={props.sessionManager.warningTimeout}
        throttle={props.sessionManager.userActionThrottle}
      />
      <IdleTimer
        ref={(ref: IIdleTimer) => {
          props.sessionManager.sessionWarningWindowAndTabSyncTimer = ref;
        }}
        events={[]}
        element={document}
        onIdle={props.sessionManager.handleSessionWarningWindowAndTabSync.bind(props.sessionManager)}
        timeout={props.sessionManager.windowAndTabSyncTimeout}
      />
      <IdleTimer
        ref={(ref: IIdleTimer) => {
          props.sessionManager.extendSessionTimer = ref;
        }}
        events={[]}
        element={document}
        onIdle={props.sessionManager.handleExtendSession.bind(props.sessionManager)}
        timeout={props.sessionManager.extendSessionTimeout}
      />
      {props.isIdle && (
        <IdleTimer
          ref={(ref: IIdleTimer) => {
            props.sessionManager.timeoutTimer = ref;
          }}
          events={[]}
          element={document}
          onIdle={props.sessionManager.clearSession}
          timeout={props.sessionManager.timeout}
        />
      )}
      <SessionTimeoutModal
        closeCallback={props.sessionManager.handleSessionWarningClosed.bind(props.sessionManager)}
        endSessionCallback={props.sessionManager.clearSession}
        timeout={props.sessionManager.timeout}
        warningTimeout={props.sessionManager.warningTimeout}
      />
    </React.Fragment>
  );
};

export default connect((state: IApplicationState) => state.sessionState, null)(SessionTimeout);
