import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import { IWizardSection, IFieldValueLookup, IWizardRequest, IWizardValues } from "./Wizard";

export const languageListValue = [
    { id:"English", title:"English" },
    { id:"Albanian", title:"Albanian" },
    { id:"Bulgarian (Cyrillic)", title:"Bulgarian (Cyrillic)" },
    { id:"Croatian", title:"Croatian" },
    { id:"Czech", title:"Czech" },
    { id:"Danish", title:"Danish" },
    { id:"Dutch", title:"Dutch" },
    { id:"French", title:"French" },
    { id:"Gaelic Irish", title:"Gaelic Irish" },
    { id:"Gaelic Scottish", title:"Gaelic Scottish" },
    { id:"German", title:"German" },
    { id:"Greek", title:"Greek" },
    { id:"Indonesian", title:"Indonesian" },
    { id:"Italian", title:"Italian" },
    { id:"Japanese", title:"Japanese" },
    { id:"Korean", title:"Korean" },
    { id:"Latin", title:"Latin" },
    { id:"Norwegian", title:"Norwegian" },
    { id:"Polish", title:"Polish" },
    { id:"Portuguese", title:"Portuguese" },
    { id:"Portuguese (Brazilian)", title:"Portuguese (Brazilian)" },
    { id:"Provencal", title:"Provencal" },
    { id:"Romanian", title:"Romanian" },
    { id:"Russian (Cyrillic)", title:"Russian (Cyrillic)" },
    { id:"Simplified Chinese", title:"Simplified Chinese" },
    { id:"Somali", title:"Somali" },
    { id:"Spanish", title:"Spanish" },
    { id:"Swedish", title:"Swedish" },
    { id:"Thai", title:"Thai" },
    { id:"Traditional Chinese", title:"Traditional Chinese" },
    { id:"Turkish", title:"Turkish" },
    { id:"Ukrainian (Cyrillic)", title:"Ukrainian (Cyrillic)" },
    { id:"Welsh", title:"Welsh" },
];


    export const timeZoneListValues= [
        {
            id: "Central Standard Time (Mexico)",
            title: "Central Standard Time (Mexico)"
        },
        {
            id: "Central Standard Time",
            title: "Central Standard Time"
        },
        {
            id: "US Eastern Standard Time",
            title: "US Eastern Standard Time"
        },
        {
            id: "Eastern Standard Time",
            title: "Eastern Standard Time"
        },
        {
            id: "Mountain Standard Time",
            title: "Mountain Standard Time"
        },
        {
            id: "Mountain Standard Time (Mexico)",
            title: "Mountain Standard Time (Mexico)"
        },
        {
            id: "US Mountain Standard Time",
            title: "US Mountain Standard Time"
        },
        {
            id: "Pacific Standard Time",
            title: "Pacific Standard Time"
        },
        {
            id: "Pacific Standard Time (Mexico)",
            title: "Pacific Standard Time (Mexico)"
        },
        {
            id: "Pacific SA Standard Time",
            title: "Pacific SA Standard Time"
        },
        {
            id: "Afghanistan Standard Time",
            title: "Afghanistan Standard Time"
        },
        {
            id: "Alaskan Standard Time",
            title: "Alaskan Standard Time"
        },
        {
            id: "Arab Standard Time",
            title: "Arab Standard Time"
        },
        {
            id: "Arabian Standard Time",
            title: "Arabian Standard Time"
        },
        {
            id: "Arabic Standard Time",
            title: "Arabic Standard Time"
        },
        {
            id: "Argentina Standard Time",
            title: "Argentina Standard Time"
        },
        {
            id: "Atlantic Standard Time",
            title: "Atlantic Standard Time"
        },
        {
            id: "AUS Central Standard Time",
            title: "AUS Central Standard Time"
        },
        {
            id: "AUS Eastern Standard Time",
            title: "AUS Eastern Standard Time"
        },
        {
            id: "Azerbaijan Standard Time",
            title: "Azerbaijan Standard Time"
        },
        {
            id: "Azores Standard Time",
            title: "Azores Standard Time"
        },
        {
            id: "Bahia Standard Time",
            title: "Bahia Standard Time"
        },
        {
            id: "Bangladesh Standard Time",
            title: "Bangladesh Standard Time"
        },
        {
            id: "Belarus Standard Time",
            title: "Belarus Standard Time"
        },
        {
            id: "Canada Central Standard Time",
            title: "Canada Central Standard Time"
        },
        {
            id: "Cape Verde Standard Time",
            title: "Cape Verde Standard Time"
        },
        {
            id: "Caucasus Standard Time",
            title: "Caucasus Standard Time"
        },
        {
            id: "Cen. Australia Standard Time",
            title: "Cen. Australia Standard Time"
        },
        {
            id: "Central America Standard Time",
            title: "Central America Standard Time"
        },
        {
            id: "Central Asia Standard Time",
            title: "Central Asia Standard Time"
        },
        {
            id: "Central Brazilian Standard Time",
            title: "Central Brazilian Standard Time"
        },
        {
            id: "Central Europe Standard Time",
            title: "Central Europe Standard Time"
        },
        {
            id: "Central European Standard Time",
            title: "Central European Standard Time"
        },
        {
            id: "Central Pacific Standard Time",
            title: "Central Pacific Standard Time"
        },
        {
            id: "China Standard Time",
            title: "China Standard Time"
        },
        {
            id: "Dateline Standard Time",
            title: "Dateline Standard Time"
        },
        {
            id: "E. Africa Standard Time",
            title: "E. Africa Standard Time"
        },
        {
            id: "E. Australia Standard Time",
            title: "E. Australia Standard Time"
        },
        {
            id: "E. Europe Standard Time",
            title: "E. Europe Standard Time"
        },
        {
            id: "E. South America Standard Time",
            title: "E. South America Standard Time"
        },
        {
            id: "Egypt Standard Time",
            title: "Egypt Standard Time"
        },
        {
            id: "Ekaterinburg Standard Time",
            title: "Ekaterinburg Standard Time"
        },
        {
            id: "Fiji Standard Time",
            title: "Fiji Standard Time"
        },
        {
            id: "FLE Standard Time",
            title: "FLE Standard Time"
        },
        {
            id: "Georgian Standard Time",
            title: "Georgian Standard Time"
        },
        {
            id: "GMT Standard Time",
            title: "GMT Standard Time"
        },
        {
            id: "Greenland Standard Time",
            title: "Greenland Standard Time"
        },
        {
            id: "Greenwich Standard Time",
            title: "Greenwich Standard Time"
        },
        {
            id: "GTB Standard Time",
            title: "GTB Standard Time"
        },
        {
            id: "Hawaiian Standard Time",
            title: "Hawaiian Standard Time"
        },
        {
            id: "India Standard Time",
            title: "India Standard Time"
        },
        {
            id: "Iran Standard Time",
            title: "Iran Standard Time"
        },
        {
            id: "Israel Standard Time",
            title: "Israel Standard Time"
        },
        {
            id: "Jordan Standard Time",
            title: "Jordan Standard Time"
        },
        {
            id: "Kaliningrad Standard Time",
            title: "Kaliningrad Standard Time"
        },
        {
            id: "Kamchatka Standard Time",
            title: "Kamchatka Standard Time"
        },
        {
            id: "Korea Standard Time",
            title: "Korea Standard Time"
        },
        {
            id: "Libya Standard Time",
            title: "Libya Standard Time"
        },
        {
            id: "Line Islands Standard Time",
            title: "Line Islands Standard Time"
        },
        {
            id: "Magadan Standard Time",
            title: "Magadan Standard Time"
        },
        {
            id: "Mauritius Standard Time",
            title: "Mauritius Standard Time"
        },
        {
            id: "Mid-Atlantic Standard Time",
            title: "Mid-Atlantic Standard Time"
        },
        {
            id: "Middle East Standard Time",
            title: "Middle East Standard Time"
        },
        {
            id: "Montevideo Standard Time",
            title: "Montevideo Standard Time"
        },
        {
            id: "Morocco Standard Time",
            title: "Morocco Standard Time"
        },
        {
            id: "Myanmar Standard Time",
            title: "Myanmar Standard Time"
        },
        {
            id: "N. Central Asia Standard Time",
            title: "N. Central Asia Standard Time"
        },
        {
            id: "Namibia Standard Time",
            title: "Namibia Standard Time"
        },
        {
            id: "Nepal Standard Time",
            title: "Nepal Standard Time"
        },
        {
            id: "New Zealand Standard Time",
            title: "New Zealand Standard Time"
        },
        {
            id: "Newfoundland Standard Time",
            title: "Newfoundland Standard Time"
        },
        {
            id: "North Asia East Standard Time",
            title: "North Asia East Standard Time"
        },
        {
            id: "North Asia Standard Time",
            title: "North Asia Standard Time"
        },
        {
            id: "Pakistan Standard Time",
            title: "Pakistan Standard Time"
        },
        {
            id: "Paraguay Standard Time",
            title: "Paraguay Standard Time"
        },
        {
            id: "Romance Standard Time",
            title: "Romance Standard Time"
        },
        {
            id: "Russia Time Zone 10",
            title: "Russia Time Zone 10"
        },
        {
            id: "Russia Time Zone 11",
            title: "Russia Time Zone 11"
        },
        {
            id: "Russia Time Zone 3",
            title: "Russia Time Zone 3"
        },
        {
            id: "Russian Standard Time",
            title: "Russian Standard Time"
        },
        {
            id: "SA Eastern Standard Time",
            title: "SA Eastern Standard Time"
        },
        {
            id: "SA Pacific Standard Time",
            title: "SA Pacific Standard Time"
        },
        {
            id: "SA Western Standard Time",
            title: "SA Western Standard Time"
        },
        {
            id: "Samoa Standard Time",
            title: "Samoa Standard Time"
        },
        {
            id: "SE Asia Standard Time",
            title: "SE Asia Standard Time"
        },
        {
            id: "Singapore Standard Time",
            title: "Singapore Standard Time"
        },
        {
            id: "South Africa Standard Time",
            title: "South Africa Standard Time"
        },
        {
            id: "Sri Lanka Standard Time",
            title: "Sri Lanka Standard Time"
        },
        {
            id: "Syria Standard Time",
            title: "Syria Standard Time"
        },
        {
            id: "Taipei Standard Time",
            title: "Taipei Standard Time"
        },
        {
            id: "Tasmania Standard Time",
            title: "Tasmania Standard Time"
        },
        {
            id: "Tokyo Standard Time",
            title: "Tokyo Standard Time"
        },
        {
            id: "Tonga Standard Time",
            title: "Tonga Standard Time"
        },
        {
            id: "Turkey Standard Time",
            title: "Turkey Standard Time"
        },
        {
            id: "Ulaanbaatar Standard Time",
            title: "Ulaanbaatar Standard Time"
        },
        {
            id: "UTC",
            title: "UTC"
        },
        {
            id: "UTC+12",
            title: "UTC+12"
        },
        {
            id: "UTC-02",
            title: "UTC-02"
        },
        {
            id: "UTC-11",
            title: "UTC-11"
        },
        {
            id: "Venezuela Standard Time",
            title: "Venezuela Standard Time"
        },
        {
            id: "Vladivostok Standard Time",
            title: "Vladivostok Standard Time"
        },
        {
            id: "W. Australia Standard Time",
            title: "W. Australia Standard Time"
        },
        {
            id: "W. Central Africa Standard Time",
            title: "W. Central Africa Standard Time"
        },
        {
            id: "W. Europe Standard Time",
            title: "W. Europe Standard Time"
        },
        {
            id: "West Asia Standard Time",
            title: "West Asia Standard Time"
        },
        {
            id: "West Pacific Standard Time",
            title: "West Pacific Standard Time"
        },
        {
            id: "Yakutsk Standard Time",
            title: "Yakutsk Standard Time"
        }
    ];

    export const imageOcrWorkRequestSteps =[
    {
        header: "Request: Analyze/Review Data",
        subHeader: "PICK THE DOCUMENTS",
        description:
          "Identify the documents you want Epiq to image and/or OCR. You can supply the path to the search results or folder to use.",
        staticText: "Supply the location of the documents that you want to image or OCR, or both.",
          dependency: { fieldId: "6.1", compare: "eq", value: 1 },
        fields: [
          {
            type: "input",
            name: "file_path",
            fieldId: "6.2",
            label: "Enter the search name or path to the folder containing the documents.",
            active: true,
            dependency: { fieldId: "6.1", compare: "eq", value: 1 },
          },
          {
            type: "numericTextBox",
            name: "doc_count",
            fieldId: "6.3",
            label: "Enter or select the number of associated documents.",
            active: true,
            dependency: { fieldId: "6.2", compare: "neq", value: null },
          }
        ],
        nextCondition: { fieldId: "6.3", compare: "neq", value: null }
      },
       {
          header: "Request: Analyze/Review Data",
          subHeader: "IMAGE DOCUMENTS",
          description:
            "Select whether you want Epiq to image the documents. Documents must be imaged before they can be OCR'd.",
          dependency: { fieldId: "6.1", compare: "eq", value: 1 },
          fields: [
            {
              type: "dropdown",
              name: "imaged_yes_no",
              fieldId: "6.4",
              label: "Have these documents already been imaged?",
              active: true,
              dependency: { fieldId: "6.1", compare: "eq", value: 1 },
              DropDownOptions: [{ id: "Yes", title: "Yes" }, { id: "No", title: "No" }]
            },
            {
              type: "displayOnlyField",
              name: "replace_images_text",
              fieldId: "6.4.1b",
              label: "If an image already exists, what do you want Epiq to do? Keep the existing image? Or, replace the existing image with a new image, which deletes any redactions.",
              active: false,
              dependency: { fieldId: "6.4", compare: "eq", value: "No" }
            },
            {
              type: "dropdown",
              name: "replace_images",
              fieldId: "6.4.1",
              label: "Replace existing images?",
              active: false,
              dependency: { fieldId: "6.4", compare: "eq", value: "No" },
              DropDownOptions: [{ id: "Yes", title: "Yes, replace existing images." }, { id: "No", title: "No, do not replace any existing images" }]
            },
            {
              type: "dropdown",
              name: "time_zone",
              fieldId: "6.4.2",
              label: "Select time zone",
              active: false,
              dependency: { fieldId: "6.4", compare: "eq", value: "No" },
              DropDownOptions: timeZoneListValues
            }
          ],
          nextCondition: {
            logic: "or",
            subConditions: [
              { fieldId: "6.4", compare: "eq", value: "Yes" },
              { fieldId: "6.4.2", compare: "neq", value: null }
            ]
          }
        },
        {
          header: "Request: Analyze/Review Data",
          subHeader: "OCR DOCUMENTS",
          description:
            "Select whether you want Epiq to OCR the document images. OCR creates text from image-based documents to enable searching by text and other capabilities.",
          dependency: { fieldId: "6.1", compare: "eq", value: 1 },
          fields: [
            {
              type: "dropdown",
              name: "ocr_documents",
              fieldId: "6.5",
              label: "Do you want to OCR the documents?",
              active: true,
              DropDownOptions: [{ id: "Yes", title: "Yes" }, { id: "No", title: "No" }]
            },
            {
              type: "inpnumericTextBoxut",
              name: "doc_count",
              fieldId: "6.5.1",
              label: "Enter the number of  document pages to OCR.",
              active: false,
              dependency: { fieldId: "6.5", compare: "neq", value: null },
            },
            {
              type: "dropdown",
              name: "ocr_language",
              fieldId: "6.5.5",
              label: "Select the OCR language/character set to use.",
              active: false,
              dependency: { fieldId: "6.5.1", compare: "neq", value: null },
              DropDownOptions: languageListValue
            }
          ],
          nextCondition: {
            logic: "or",
            subConditions: [
              { fieldId: "6.5", compare: "eq", value: "No" },
              { fieldId: "6.5.5", compare: "neq", value: null }
            ]
          }
        }     
] as Array<IWizardSection>;