import * as React from "react";
import CommonHelper from "../common/utilities/CommonHelper";
import { formatDate } from "@telerik/kendo-intl";
import { AdvancedGrid } from "../common/grid/AdvancedGrid";
import { GridCellProps } from "@progress/kendo-react-grid";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import JobDetailsResources from "./JobDetailsResources";
import { Tooltip } from "@progress/kendo-react-tooltip";
import "./JobDetailsPopup.scss";
import { IconButton, Paper } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as SessionStore from "../../store/Session";
import { IApprovalForUserDetails, approvalResponseTypes, approvalStatus } from "../../models/approvalModels";
import { IApplicationState } from "../../store";
import { IApprovalDetailsProps } from "../approval/ApprovalDetail";
import { connect } from "react-redux";

interface IApprovalsHistory {
  status: string;
  email: string;
  createdDate: Date;
}


type IProps = {
  approval: IApprovalForUserDetails;
  loadingPopup: boolean;
}

type Props = IProps
  & IApprovalDetailsProps
  & SessionStore.ISessionState;


// TODO Not really a popup anymore. I should rename this
const JobDetailsPopup = (props: Props) => {

  // Could probably remove this
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [gridItems, setGridItems] = React.useState<Array<IApprovalsHistory>>([]);

  const dataState = {
    skip: 0,
    take: 500
  };

  let date = "-";

  const approvalDate = props.approval.createdDate;

  if (approvalDate) {
    let localDateTime = CommonHelper.convertUTCDateToLocalDate(approvalDate);
    date = formatDate(localDateTime, "g");
  }

  const getGridResults = () => {

    if (props.approval) {

      let approvalsHistory = [] as Array<IApprovalsHistory>;

      approvalsHistory.push({
        status: "Needs Approval",
        email: props.approval.createdByUser.email,
        createdDate: props.approval.createdDate
      });

      if (props.approval.approversList) {
        props.approval.approversList.forEach((item) => {
          if (item.approverResponseId === approvalResponseTypes.approved || item.approverResponseId === approvalResponseTypes.rejected) {  // adding approved/rejected approvers only 
            approvalsHistory.push({
              status: getStatusString(item.approverResponseId),
              email: item.approverUserEmail,
              createdDate: item.approverResponseDate
            });
          }
        });
      }

      setGridItems(approvalsHistory);
    }
  }

  const filterElements: any = (element: any) => {
    if (element.tagName === "EM") {
      return true;
    }
    return false;
  };

  const getStatusString = (status: number) => {
    switch (status) {
      case approvalResponseTypes.approved:
        return "Approved";
      case approvalResponseTypes.rejected:
        return "Rejected";
      default:
        return "Needs Approval";
    }
  }

  const status = (props: GridCellProps) => {
    return (
      <td>
        <span>{props.dataItem.status}</span>
      </td>
    );
  }

  const email = (props: GridCellProps) => {
    return (
      <td>
        <em title={props.dataItem.email}>{props.dataItem.email}</em>
      </td>
    );
  }

  const createdDate = (props: GridCellProps) => {
    let date = "-";
    if (props.dataItem.createdDate) {
      let localDateTime = CommonHelper.convertUTCDateToLocalDate(props.dataItem.createdDate);
      date = formatDate(localDateTime, "g");
    }
    return (
      <td>
        <span>{date}</span>
      </td>
    );
  }

  const columns = [
    { field: "email", title: "EMAIL", filterable: false, cell: email, sortable: false, headerClassName: "no-sort" },
    { field: "status", title: "ACTION", filterable: false, cell: status, sortable: false, headerClassName: "no-sort" },
    { field: "createdDate", title: "DATE", filterable: false, cell: createdDate, sortable: false, headerClassName: "no-sort" }
  ];


  if (props.loadingPopup) {
    return (
      <div className="job-loading">
        <CircularProgress />
        <div className="job-loading-label">
          Navigating to next job. Please wait...
        </div>
      </div>
    );
  }

  if (!props.approval.payloadUnpacked || !props.approval.payloadUnpacked.payload) {
    return <div className="job-loading">
      <CircularProgress />
      <div className="job-loading-label">
        There is no data for this approval
      </div>
    </div>
  }

  const groupData = props.approval.payloadUnpacked.payload;

  return (
    <div className="job-details-popup">
      <div>
        <p><strong>Description: </strong>{props.approval.message}</p>
      </div>

      <PanelBar expandMode="single">
        <div className="job-approval-details-close-icon-button">
          <IconButton
            className="modal-close"
            aria-label="close"
            disabled={false}
            onClick={props.onClose}
          ><CloseIcon /></IconButton>
        </div>
        <PanelBarItem expanded={true} title="Additional information">
          {<fieldset className="job-additional-information">
            <div className="resorcegroup-name"><strong>Group Name: </strong>{groupData.GroupName}</div>
            <p className="resourcegroup-comments"><strong>Comments: </strong>{groupData.GroupComments}</p>

            {props.sessionData.permissions.has("EpiqAdminGetResourceGroup") ? (
              <JobDetailsResources resourceGroupId={groupData.GroupId} />) :
              (<>
                <p className="resourcegroup-comments"><strong>Existing Resources: </strong>{groupData.GroupComments}</p>
                <p>You do not have permissions to view the list of existing resources for this group</p>
              </>)
            }
          </fieldset>}
        </PanelBarItem>
        <PanelBarItem title="History">
          <div className="job-status-history">
            <Tooltip openDelay={2} position="right" filter={filterElements}>
              <AdvancedGrid
                showErrorState={hasError}
                showLoadingIndicator={isLoading}
                data={gridItems}
                dataFetch={getGridResults}
                dataState={dataState}
                columns={columns}
                paging={false}
                noRecordsRender={<p>No information to display.</p>}
                noLoadOnMount={false}
                filteredDataOnly={false}
                multiFieldFilterDelimiter="|"
                total={gridItems ? gridItems.length : 0}
                moreRecordsAvailableMessage="Displaying first 500 items. Click View Details to refine results."
              />
            </Tooltip>
          </div>
        </PanelBarItem>
      </PanelBar>

      <div>
        <p><strong>Created Date: </strong>{date}</p>
        <p><strong>Created By: </strong>{props.approval.createdByUser.lastName + ", " + props.approval.createdByUser.firstName}</p>
      </div>

    </div>
  );
}

export default connect(
  (state: IApplicationState, ownProps: IProps) => ({
    ...ownProps,
    ...state.sessionState,
  }), null
)(JobDetailsPopup);