import React, { useState, useEffect } from "react";
import SettingsAdmin, { IAdminSettings, ISettingsChangeEvent } from "../../../common/SettingsAdmin";
import AdminService from "../../../../services/AdminService";
import { IFeature, IAppFeature, IToggleAbleSystemFeature } from "../../../../models/Features";
import NotificationService from "../../../../services/NotificationService";
import { Button } from "@progress/kendo-react-buttons";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./AppFeatures.scss";
import FeatureManager from "./featureManagers/FeatureManager";

interface IFeatureSettings {
  settings: IAdminSettings;
  feature: IFeature;
}

interface IProps {
  onChange: (event: IAppFeature) => void;
  appTypeId: number;
  appId: number;
  disabledFeatureIds?: Set<number>;
  systemFeatures?: boolean;
  appTypeFeature?: Array<IToggleAbleSystemFeature>;
  noRecordsMessage?: string;
}

export default (props: IProps) => {

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [settings, setSettings] = useState<Array<IFeatureSettings>>([]);
  const [featureManager, setFeatureManager] = useState(new FeatureManager(props.appId));

  useEffect(() => {
    if (props.appTypeId && props.appId && props.systemFeatures) {
      fetchSystemAppFeatures();
    } else if (props.appTypeId && !props.systemFeatures) {
      fetchAppFeatures();
    }
  }, [props.appTypeId, props.appTypeFeature]);

  useEffect(() => {
    if (featureManager.appId !== props.appId) {
      setFeatureManager(new FeatureManager(props.appId));
    }

  }, [props.appId, props.appTypeId, isLoading, isError]);

  useEffect(() => { }, [props.disabledFeatureIds]);

  const fetchAppFeatures = async () => {
    setIsLoading(true);
    const responseRT = await AdminService.getAppTypeFeatures(props.appTypeId);
    if (!responseRT.ok) {
      setIsError(true);
      NotificationService.showErrorToast("Could not retrieve features for this app type");
      return;
    }

    let features = responseRT.data as Array<IFeature>;
    let featureSettings = null as Array<IFeatureSettings>;

    if (props.appId) {
      var enabledResponse = await AdminService.getEnabledAppFeatures(props.appId);
      if (!enabledResponse.ok) {
        NotificationService.showErrorToast("Could not retrieve features enabled for this app");
        setIsError(true);
        return;
      }

      featureSettings = asAppFeatureSettings(features, enabledResponse.data);
    }
    else {
      featureSettings = asAppFeatureSettings(features, []);
    }

    setSettings(featureSettings);
    setIsLoading(false);
  }

  const fetchSystemAppFeatures = async () => {
    setIsLoading(true);
    const responseRT = await AdminService.systemFeaturesForApp(props.appId);
    if (!responseRT.ok) {
      setIsError(true);
      NotificationService.showErrorToast("Could not retrieve system features for this app");
      return;
    }

    let features = responseRT.data as Array<IFeature>;
    let featureSettings = asAppFeatureSettings(features, features.filter(f => f.applicationFeature && f.applicationFeature.length));

    setSettings(featureSettings);
    setIsLoading(false);
  }

  const onFeatureUpdate = (e: ISettingsChangeEvent, fa: IFeature, indx: number) => {

    // we likely will only be working with 1 element
    if (!e.enabled) {
      fa.applicationFeature[0].delete = true;
    } else {
      if (fa.applicationFeature[0]) {
        fa.applicationFeature[0].delete = false;
      }
      if (e.optionIndex > -1) {
        fa.applicationFeature[0][featureManager.getKeyByIndex(fa.id, e.optionIndex)] = e.options[e.optionIndex].value;
      }
    }

    props.onChange(fa.applicationFeature[0]);

    const updatedSettings = settings;
    updatedSettings[indx].settings = e;
    updatedSettings[indx].feature = fa;

    setSettings(updatedSettings);
  }

  const settingsFromFeatures = (feature: IFeature, enabled: boolean): IAdminSettings => {
    return {
      id: feature.id,
      name: feature.name,
      options: featureManager.getAdminSettingOptions(feature),
      enabled: enabled
    }
  }

  const asAppFeatureSettings = (allFeatures: Array<IFeature>, withAppFeatures: Array<IFeature>) => {

    const featuresWithSettings = [] as Array<IFeatureSettings>;

    for (let i = 0; i < allFeatures.length; i++) {
      for (let j = 0; j < withAppFeatures.length; j++) {
        if (allFeatures[i].id === withAppFeatures[j].id) {
          allFeatures[i].applicationFeature = withAppFeatures[j].applicationFeature;
          break;
        }
      }

      const enabled = !!(allFeatures[i].applicationFeature && allFeatures[i].applicationFeature.length);

      if (!allFeatures[i].applicationFeature || !allFeatures[i].applicationFeature.length) {
        allFeatures[i].applicationFeature = featureManager.createAppFeatures(allFeatures[i].id);
      }

      featuresWithSettings.push({ feature: allFeatures[i], settings: settingsFromFeatures(allFeatures[i], enabled) });
    }

    return featuresWithSettings;
  }

  if (isError) {
    return <div className="app-features">
      <Button className="btn btn-secondary" onClick={fetchAppFeatures}>RETRY</Button>
    </div>
  }

  if (isLoading || !settings) {
    return <div className="app-features">
      <div className="loading-features">
        <CircularProgress />
      </div>
    </div>
  }

  return <div className="app-features">
    {(settings && settings.length > 0)
      ? <div className="grid-container">
        {settings.map((s, idx) => {
          if (props.disabledFeatureIds && props.disabledFeatureIds.has(s.feature.id)) {
            return <></>
          } else {
            return <div key={`appfeature${idx}`} className="app-feature">
              <SettingsAdmin onChange={e => onFeatureUpdate(e, s.feature, idx)} value={s.settings} />
            </div>
          }
        })}
      </div>
      : (props.noRecordsMessage && <div className="no-records-msg">{props.noRecordsMessage}</div>)}
  </div>
};