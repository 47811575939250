import { UpsertUserModel, User, SendInvite, UserGroup, UpsertUserGroupModel, IUpsertUserGroupUserModel } from "../models/UserModels";
import { ServiceBase, ResponseMessages, ServiceResponse, ServiceResponseJson, ServiceResponseStr } from "./ServiceBase";
import { IGridParams as AdvancedGridParams } from "../components/common/grid/AdvancedGrid";
import { ICreateAdminContextModel } from "../models/AdminContext";

const UpsertGroupUserMessages: ResponseMessages = {
  FailedMessage: "Usert users to group failed.",
  SuccessMessage: "Usert users to group succeeded."
};

const ContextInfo: ResponseMessages = {
  FailedMessage: "failed to get context info.",
  SuccessMessage: "getting context info succeeded."
};

export default class AdminContextService extends ServiceBase {
  static async upsertUsernameUsersForGroup(userGroup: IUpsertUserGroupUserModel) {
    const result: ServiceResponseJson = await fetch(AdminContextService.getBaseGroupUri() + "/upsertUsernameUsersForGroup", {
      body: this.toPostBody(userGroup),
      ...this.fetchPostOptions()
    })
    .then(response => this.handleResponseJson(response, UpsertGroupUserMessages))
    .catch(response => this.handleResponseJson(response, UpsertGroupUserMessages));

    return result;
  }

  static async createAdminContext(createModel: ICreateAdminContextModel) {
    const url = `${AdminContextService.getContextAdminBaseUri()}/create`;

    const result: ServiceResponseJson = await fetch(url, {
      body: this.toPostBody(createModel),
      ...this.fetchPostOptions()
    })
      .then(async response => await this.handleResponseJson(response, ContextInfo))
      .catch(async response => await this.handleResponseJson(response, ContextInfo));

    return result;
  }

  static async getMyAdminContext(gridParams: AdvancedGridParams) {

    const filterJSON = JSON.stringify(gridParams.filters) || "";
    const sortJSON = JSON.stringify(gridParams.sort) || "";
    const url = `${AdminContextService.getContextAdminBaseUri()}/myContext?${this.getFilterSortQueryString(filterJSON, sortJSON)}&${this.toQueryString(gridParams, ["filters", "sort", "filter"])}`;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, ContextInfo))
      .catch(async response => await this.handleResponseJson(response, ContextInfo));

    return result;
  }

  static async getContextRelClients(gridParams: AdvancedGridParams) {

    const filterJSON = JSON.stringify(gridParams.filters) || "";
    const sortJSON = JSON.stringify(gridParams.sort) || "";
    const url = `${AdminContextService.getBaseRelativityUri()}/getContextRelClients?${this.getFilterSortQueryString(filterJSON, sortJSON)}&${this.toQueryString(gridParams, ["filters", "sort", "filter"])}`;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, ContextInfo))
      .catch(async response => await this.handleResponseJson(response, ContextInfo));

    return result;
  }

  static async getAssignableRelInstanceApps(gridParams: AdvancedGridParams) {

    const filterJSON = JSON.stringify(gridParams.filters) || "";
    const sortJSON = JSON.stringify(gridParams.sort) || "";
    const url = `${AdminContextService.getAppUri()}/getAssignableRelInstanceApps?${this.getFilterSortQueryString(filterJSON, sortJSON)}&${this.toQueryString(gridParams, ["filters", "sort", "filter"])}`;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, ContextInfo))
      .catch(async response => await this.handleResponseJson(response, ContextInfo));

    return result;
  }

  static async getUser(userId: number) {
    const result: ServiceResponseJson = await fetch(`${AdminContextService.getUserBaseUri()}/getUser?userId=${userId}`, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, ContextInfo))
      .catch(async response => await this.handleResponseJson(response, ContextInfo));

    return result;
  }

  static getContextAdminBaseUri() {
    return `${(<any>window).ApplicationVariables.apiUri}adminContext`;
  }

  static getUserBaseUri() {
    return `${(<any>window).ApplicationVariables.apiUri}Users`;
  }

  static getBaseGroupUri() {
    return `${(<any>window).ApplicationVariables.apiUri}UserGroups`;
  }

  static getBaseRelativityUri() {
    return `${(<any>window).ApplicationVariables.apiUri}Relativity`;
  }

  static getAppUri() {
    return `${(<any>window).ApplicationVariables.apiUri}Applications`;
  }
}