import { Action, Reducer } from "redux";
import { AppThunkAction, AppThunkActionPromise } from "./";
import { JobInfo } from "../models/JobModels";
import JobService from "../services/JobService";
import NotificationService from "../services/NotificationService";
import { UpsertUserModel } from "../models/UserModels";
import AdminService from "../services/AdminService";

type JobType = "EDIT_USER" | "CREATE_USER";

export interface IJobState {
  jobInfos: JobInfo[];
  latestJobId?: string;
  jobType?: JobType;
}

// -----------------
// ACTIONS
interface GetJobRequestAction {
  type: "GET_JOBSTATUS_REQUEST";
}
interface GetJobResponseAction {
  type: "GET_JOBSTATUS_RESPONSE";
  jobInfos: JobInfo[];
  latestJobId?: string;
}
interface GetJobResponseFailure {
  type: "GET_JOBSTATUS_RESPONSE_FAILURE";
}
interface ClearJobTrackingAction {
  type: "GET_JOBTRACKING_CLEAR";
  jobInfos: JobInfo[];
  latestJobId?: string;
}
interface AddedJobResponseAction {
  type: "ADDED_JOB_RESPONSE";
  jobType: JobType;
  jobId: string;
}

interface ClearAllAction {
  type: "CLEAR_ALL";
}

type KnownAction = GetJobRequestAction | GetJobResponseAction | GetJobResponseFailure | ClearJobTrackingAction | AddedJobResponseAction | ClearAllAction;

export const actionCreators = {
  GetJobStatus: (jobId: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    dispatch({ type: "GET_JOBSTATUS_REQUEST" });
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      const result = await JobService.getJobStatus(jobId);
      if (result.ok) {
        const clonedJobs = [];
        let jobExists = false;
        appState.jobState.jobInfos.forEach((val) => {
          if (val.jobId === result.data.jobId) {
            jobExists = true;
            clonedJobs.push(result.data);
          } else {
            clonedJobs.push(Object.assign({}, val));
          }
        });
        if (!jobExists) {
          clonedJobs.push(result.data);
        }
        dispatch({
          type: "GET_JOBSTATUS_RESPONSE",
          jobInfos: clonedJobs,
        });
      } else {
        dispatch({
          type: "GET_JOBSTATUS_RESPONSE_FAILURE",
        });
        console.log("Error get job info.");
      }
    }
  },
  ClearJobTrackingAction: (jobId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const clonedJobs: JobInfo[] = [];
    let jobExists = false;
    const appState = getState();
    appState.jobState.jobInfos.forEach((val) => {
      if (val.jobId !== jobId) {
        clonedJobs.push(Object.assign({}, val));
      }
    });
    dispatch({
      type: "GET_JOBTRACKING_CLEAR",
      jobInfos: clonedJobs,
      latestJobId: appState.jobState.latestJobId !== null && appState.jobState.latestJobId === jobId ? null : appState.jobState.latestJobId,
    });
  },
  updateUser: (user: UpsertUserModel): AppThunkActionPromise<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      const result = await AdminService.UpdateUser(user);
      if (result.ok) {
        dispatch({
          type: "ADDED_JOB_RESPONSE",
          jobType: "EDIT_USER",
          jobId: JSON.parse(result.value),
        });
      } else {
        console.log("Error update user ");
        NotificationService.showErrorToast("Something went wrong starting update. Update user failed.");
      }
    }
  },
  createUser: (user: UpsertUserModel): AppThunkActionPromise<KnownAction> => async (dispatch, getState) => {
    console.log("about to create internal user...");
    const createUserResult = await AdminService.createUser(user);

    if (createUserResult.ok) {
      dispatch({
        type: "ADDED_JOB_RESPONSE",
        jobType: "CREATE_USER",
        jobId: JSON.parse(createUserResult.value),
      });
    } else {
      //we should consider deleting them from OKTA if we got here then the user was created in OKTA
      console.log("Error creating user in Admin Service.");
      NotificationService.showErrorToast("Something went wrong.  User was not created.");
    }
  },
  ClearAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({ type: "CLEAR_ALL" });
  },
};
// ----------------
// REDUCER -
const unloadedState: IJobState = {
  jobInfos: [],
  latestJobId: null,
};
export const reducer: Reducer<IJobState> = (state: IJobState | undefined, incomingAction: Action): IJobState => {
  if (state === undefined) {
    return unloadedState;
  }
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "GET_JOBSTATUS_REQUEST":
      return {
        jobInfos: state.jobInfos,
        latestJobId: state.latestJobId,
      };
    case "GET_JOBSTATUS_RESPONSE":
      return {
        jobInfos: action.jobInfos,
        latestJobId: action.latestJobId,
      };
    case "GET_JOBSTATUS_RESPONSE_FAILURE":
      return {
        jobInfos: state.jobInfos,
        latestJobId: state.latestJobId,
      };
    case "ADDED_JOB_RESPONSE":
      return {
        latestJobId: action.jobId,
        jobType: action.jobType,
        jobInfos: state.jobInfos,
      };
    case "CLEAR_ALL":
      return {
        ...unloadedState
      };
  }
  return state;
};
