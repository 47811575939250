/**
* Base Grid component wraps a kendo react grid and a dataloader component(this component is built by us(EPIQ))
* It allows for sorting which occurs server side.
* whatever properties you set as part of this.datastate will be observed by the dataloader and trigger a data fetch if anything
* on that object changes.
* This component is for minimal grids with no filtering and should be wrapped with additional functionality
* by the wrapping component passing props through.
*/
import React, { PureComponent } from "react";
import { Renderer } from "react-dom";
import { v4 as uuid } from "uuid";
import {
  Grid,
  GridColumn,
  GridNoRecords,
  GridDataStateChangeEvent,
  GridSortChangeEvent,
  GridPagerSettings,
  GridSortSettings,
  GridColumnProps,
  GridToolbar, GridProps, GridRowProps,
  GridColumnResizeEvent
} from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { SortDescriptor, CompositeFilterDescriptor } from "@progress/kendo-data-query";
import Button from "@material-ui/core/Button";
import { DataLoader, IDataLoaderProps } from "../DataLoader";
import { isFinite } from "lodash";
import { Paper } from "@material-ui/core";
import "./BaseGrid.scss";


export interface IGridParams {
  skip?: number;
  take?: number;
  searchText?: string;
  orderBy?: string;
  isAscending?: boolean;
  sort?: SortDescriptor[];
  filter?: CompositeFilterDescriptor;
}

export interface ITotalRecordsConfig {
  label: string | JSX.Element;
  value: number;
}

export interface IGridColumnProps extends GridColumnProps {
  preCalcPercentageWidth?: number;
  preCalcFixedWidth?: number;
}

export interface IBaseGridProps<T, TT extends IGridParams> extends GridProps {
  showLoadingIndicator: boolean;
  showErrorState: boolean;
  paging?: boolean;
  errorStateRender?: React.ReactElement<Renderer>;
  noRecordsRender?: React.ReactElement<Renderer>;
  data: Array<T>;
  dataFetch: (params: TT) => void;
  dataState: TT;
  totalRecords?: number | ITotalRecordsConfig;
  columns: Array<IGridColumnProps | GridColumnProps>;
  gridToolbarContent?: JSX.Element;
  dataLoaderProps?: IDataLoaderProps<TT>;
  hidePaper?: boolean;
}

type Props<T, TT extends IGridParams> = IBaseGridProps<T, TT>;

type State<TT extends IGridParams> = {
  dataState: TT;
  filter?: CompositeFilterDescriptor;
};

const filterDelay = 550;

export class BaseGrid<T, TT extends IGridParams> extends PureComponent<Props<T, TT>, State<TT>> {
  pagerSettings: GridPagerSettings;
  sortSettings: GridSortSettings;
  errorStateRender: React.ReactElement<Renderer>;
  noRecordsRender: React.ReactElement<Renderer>;
  columns: Array<JSX.Element>;
  timeout: NodeJS.Timeout;
  dataStateChanging: boolean;
  filteredColumn: GridColumnProps;
  id: string;

  constructor(props: Props<T, TT>) {
    super(props);

    this.id = `a${uuid()}`;
    this.filteredColumn = this.props.columns.find((value) => {
      return value.filterable;
    });

    this.state = {
      dataState: {
        skip: 0,
        take: 100,
        ...this.props.dataState
      }
    };

    this.dataStateChanging = false;
    this.errorStateRender = this.props.errorStateRender || (
      <div className="">
        <p> Oops! Something went wrong. </p>
        <p> Please check your network connection and retry. </p>
        <Button variant="contained" onClick={this.handleOnRetryClickEvent.bind(this)} className="btn">
          RETRY
        </Button>
      </div>
    );

    this.sortSettings = { allowUnsort: false, mode: "single" };
    this.pagerSettings = {
      buttonCount: 5,
      info: true,
      type: "numeric",
      pageSizes: [25, 50, 100],
      previousNext: true
    };
    this.timeout = null;
  }

  handleOnDataStateChange({ data }: GridDataStateChangeEvent) {
    this.dataStateChanging = true;

    this.setState({
      ...this.state,
      dataState: { ...this.state.dataState, skip: data.skip, take: data.take }
    });
    this.dataStateChanging = false;
  }

  handleOnRetryClickEvent() {
    this.props.dataFetch(this.state.dataState);
  }

  handleOnSortChange({ sort }: GridSortChangeEvent) {
    this.setState({
      ...this.state,
      dataState: {
        ...this.state.dataState,
        orderBy: sort[0].field,
        isAscending: sort[0].dir === "asc",
        sort: sort
      }
    });
  }

  render() {
    const noRecordsContainer = (content: JSX.Element): JSX.Element => {
      return <div className="base-grid-no-records-container">{content}</div>;
    };
    const noRecordsRender = (): JSX.Element => {
      return this.props.noRecordsRender || <p>No results found for this search.</p>;
    };

    const gridNoDataRender = this.props.showLoadingIndicator ? (
      <GridNoRecords>
        <div></div>
      </GridNoRecords>
    ) : this.props.showErrorState ? (
      <GridNoRecords>{this.errorStateRender}</GridNoRecords>
    ) : (
      <GridNoRecords>{noRecordsContainer(noRecordsRender())}</GridNoRecords>
    );

    const totalIsANumber = isFinite(this.props.totalRecords);
    const totalCountRender = (): JSX.Element => {
      const totalCount = totalIsANumber
        ? this.props.totalRecords
        : (this.props.totalRecords as ITotalRecordsConfig).value;
      const label = totalIsANumber ? "Total: " : (this.props.totalRecords as ITotalRecordsConfig).label;

      return (
        <div className="total-records-container">
          <h3 className="section-head">
            {label}
            <span className="total-count">{totalCount}</span>
          </h3>
        </div>
      );
    };
    const showTotalRecords = this.props.totalRecords !== undefined && this.props.totalRecords !== null;


    const content = (<>
      <div id={this.id}>
        <Grid
          rowRender={this.props.rowRender ? this.props.rowRender : null}
          data={this.props.data}
          onDataStateChange={this.handleOnDataStateChange.bind(this)}
          total={
            this.props.totalRecords
              ? totalIsANumber
                ? (this.props.totalRecords as number)
                : (this.props.totalRecords as ITotalRecordsConfig).value
              : null
          }
          skip={this.state.dataState.skip}
          take={this.state.dataState.take}
          pageable={this.props.paging ? this.pagerSettings : null}
          sortable={this.props.showErrorState ? false : this.sortSettings}
          sort={this.props.showErrorState ? null : this.state.dataState.sort}
          onSortChange={this.handleOnSortChange.bind(this)}
          columnVirtualization={true}
          {...this.props}
        >
          {(this.props.gridToolbarContent || showTotalRecords) && (
            <GridToolbar>
              {showTotalRecords && totalCountRender()}
              <div className="toolbar-button-container">{this.props.gridToolbarContent}</div>
            </GridToolbar>
          )}
          {gridNoDataRender}
          {this.props.columns.map((value) => {
            return <GridColumn minResizableWidth={50} {...value} key={value.field || uuid()} filterable={value.filterable || false} />;
          })}
          {this.props.children}
        </Grid>
      </div>
      <DataLoader
        getDataAsync={async () => {
          this.props.dataFetch(this.state.dataState);
        }}
        loading={this.props.showLoadingIndicator}
        dataState={this.state.dataState}
        dataContainerId={this.id}
        dataContainerClassesForScope=".k-grid-container"
        {...this.props.dataLoaderProps}
      />
    </>
    );

    if (this.props.hidePaper) {
      return <>{content}</>
    }

    return (
      <Paper className="base-grid-wrapper">
        {content}
      </Paper>)
  }
}
