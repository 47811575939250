import React, { Component } from "react";
import "./Home.scss";

type Props = {};

class Home extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return <div className="main-content">
             <div className="welcome-message">
               <h3>Welcome to Epiq Access.</h3>
               <p>Please contact your Administrator to be granted permission to specific Epiq Access features.</p>
             </div>
           </div>;
  }
}

export default Home;
