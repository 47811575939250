import React, { useState, useEffect } from "react";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import "./GridHeader.scss";
import { Button } from "@material-ui/core";
import { Application } from "../../../models/ApplicationModels";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import { EmployerDomain } from "../../../models/EmployerModel";

type Props = {
  excludeSearch?: boolean;
  searchPlaceholder?: string;
  searchButton?: boolean;
  searchDisabled?: boolean;
  headerTypeName: string;
  displayCount?: number;
  onSubmitSearch?: (value: string) => void;
  onChangeSearch?: (value: string) => void;
  addClickHandler?: () => void;
  removeClickHandler?: () => void;
  selectedCount?: number;
  automated?: string;
  canOpenPopup?: boolean;
  displayRemoveBtn?: boolean;
  minSearchCharacters?: number;
  headerButtons?: Array<string>;
  headerButtonsDisabled?: boolean;
  primaryButtonHandler?: () => void;
  secondaryButtonHandler?: () => void;
  groupDomains?: Array<EmployerDomain>;
};

export default (props: Props) => {
  const [searchText, setSearchText] = useState("");

  const inputChangedHandler = (event: any) => {
    const value = event.currentTarget.value;
    if (props.onChangeSearch) {
      props.onChangeSearch(value);
    }
    setSearchText(value);
  };

  const displayCount = props.displayCount || 0;
  const formattedDisplayCount = displayCount.toLocaleString();
  const displayAutomated = props.automated && (<><span className="automation-label">
    Automation Enabled :</span><span className="automation-value">{props.automated}</span></>
  );
  let countDisplay;
  if (props.selectedCount && props.selectedCount > 0) {
    countDisplay = (
      <h3 className={!props.displayRemoveBtn ? "section-head active-data" : ""}>
        {!props.displayRemoveBtn ? (
          <span>
            {props.selectedCount} of {displayCount} Selected
          </span>
        ) : (
          <span className="section-head">
            {props.headerTypeName} <span className="grid-count">{formattedDisplayCount}</span>
          </span>
        )}
      </h3>
    );
  } else {
    countDisplay = (
      <h3 className="section-head">
        {props.headerTypeName} <span className="grid-count">{formattedDisplayCount}</span>
      </h3>
    );
  }

  return (
    <div className="grid-header">
     
      {props.canOpenPopup && !props.selectedCount && (
        <div className="add-btn">
          <AddIcon onClick={props.addClickHandler} style={{ fontSize: 40 }} />
        </div>
      )}
      {typeof props.headerButtons !== "undefined" && props.headerButtons.find((x) => x === "Export") && (

        <Button className="export" variant="contained" color="primary" onClick={props.primaryButtonHandler} disabled={props.headerButtonsDisabled}>
          Export
        </Button>
      )}

      {typeof props.headerButtons !== "undefined" && props.headerButtons.find((x) => x === "AddUser") && (
        <Button variant="contained" color="primary" className="add-user-btn" onClick={props.primaryButtonHandler} disabled={props.headerButtonsDisabled}>
          <GroupAddIcon />Create User
        </Button>
      )}
      {typeof props.headerButtons !== "undefined" && props.headerButtons.find((x) => x === "Import") && (
        <Button variant="contained" className="import-user-btn" onClick={props.secondaryButtonHandler} disabled={props.headerButtonsDisabled}>
          Import
        </Button>
      )}
      {typeof props.headerButtons !== "undefined" && props.headerButtons.find(x => x === "CreateUserGroup") && (
        <Button variant="contained" color="primary" className="add-user-btn" onClick={props.primaryButtonHandler}>
          <GroupAddIcon />Create User Group
        </Button>
      )}

      {typeof props.headerButtons !== "undefined" && props.headerButtons.find((x) => x === "Create Role") && (
        <Button variant="contained" color="primary" className="add-user-btn" onClick={props.primaryButtonHandler} disabled={props.headerButtonsDisabled}>
          <GroupAddIcon />Create Role
        </Button>
      )}

      {props.canOpenPopup && !props.selectedCount && (
        <div className="add-btn">
          <AddIcon onClick={props.addClickHandler} style={{ fontSize: 40 }} />
        </div>
      )}
      {countDisplay}
      {displayAutomated}
      {props.groupDomains && props.groupDomains.length > 0 && (
        <>
          <div className="resource-domains">
            User must match allowed email domains:{" "}
            <div>
              {props.groupDomains.map((d) => { return d.domain }).join(", ")}
            </div>
          </div>
        </>
      )}
      {!props.excludeSearch && (
        <>
          <input
            disabled={props.searchDisabled}
            type="search"
            placeholder={props.searchPlaceholder}
            value={searchText || ""}
            onChange={(event) => inputChangedHandler(event)}
            role="filter-list"
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                if (props.onSubmitSearch) {
                  if (props.minSearchCharacters) {
                    if (event.currentTarget.value.length >= props.minSearchCharacters) {
                      props.onSubmitSearch(event.currentTarget.value);
                    } else if (event.currentTarget.value.length == 0) {
                      props.onSubmitSearch("");
                    }
                  } else {
                    props.onSubmitSearch(event.currentTarget.value);
                  }
                }

                if (props.onChangeSearch) {
                  props.onChangeSearch(event.currentTarget.value);
                }
              }
            }}
            className="input-control"
          />
          <span className={"clear-search " + (searchText ? "show" : " ")}>
            {" "}
            <ClearIcon
              onClick={() => {
                setSearchText("");

                if (props.onSubmitSearch) {
                  props.onSubmitSearch("");
                }

                if (props.onChangeSearch) {
                  props.onChangeSearch("");
                }
              }}
            />
          </span>
          <span className="search-wrapper">
            <SearchIcon />
          </span>
        </>
      )}
      {props.searchButton && !props.excludeSearch ? (
        <Button
          variant="contained"
          className="search-btn"
          color="primary"
          disabled={props.searchDisabled || (props.minSearchCharacters && searchText.length < props.minSearchCharacters)}
          onClick={() => {
            if (props.onSubmitSearch) {
              props.onSubmitSearch(searchText);
            }
          }}
          onChange={() => {
            if (props.onChangeSearch) {
              props.onChangeSearch(searchText);
            }
          }
          }
        >
          {" "}
          Search{" "}
        </Button>
      ) : (
        ""
      )}

      {props.selectedCount && props.selectedCount > 0 && props.displayRemoveBtn ? (
        <>
          <span className="counter-span">
            <span>
              {props.selectedCount} of {displayCount} Selected
            </span>
          </span>
          <Button variant="contained" className="btn-primary removebtn-wrapper" color="primary" onClick={() => props.removeClickHandler()}>
            REMOVE
          </Button>
        </>
      ) : (
        ""
      )}
    </div>
  );
};
