import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { Report as PbiReport, Page } from "powerbi-client";
import { PowerBiEmbedSet } from "../models/ReportModels";
import AnalyticsService from "../services/AnalyticsService";

export interface IEmbedSetInfo {
  isLoading: boolean;
  codedSet?: PowerBiEmbedSet;
}

export interface IReportState {
  codedEmbeds: { [key: string]: IEmbedSetInfo };
  reports?: PowerBiEmbedSet[];
  page?: IReportPageState;
  selectedReport?: PbiReport;
}

type pageLoadingStates = "started" | "complete" | "failed";

export interface IReportPageState {
  pages: Page[],
  selectedPage?: string,
  loadState: pageLoadingStates
}

// -----------------
// ACTIONS

interface GetOverviewRequestAction {
  type: "GET_OVERVIEW_REQUEST";
  embedCode: string;
}

interface GetOverviewResponseAction {
  type: "GET_OVERVIEW_RESPONSE";
  embedCode: string;
  embedSet: PowerBiEmbedSet;
}

interface GetOverviewResponseFailure {
  type: "GET_OVERVIEW_RESPONSE_FAILURE";
  embedCode: string;
}

interface SetPageListAction {
  type: "SET_PAGE_LIST_ACTION";
  pages: Page[]
}

interface SetReportAction {
  type: "SET_SELECTED_REPORT_ACTION";
  report: PbiReport;
}

interface SetPageAction {
  type: "SET_PAGE_ACTION";
  pageLoadingStatus: pageLoadingStates;
}

interface ClearAllAction {
  type: "CLEAR_ALL";
}


type KnownAction = GetOverviewRequestAction | GetOverviewResponseAction | GetOverviewResponseFailure |
    SetPageListAction | SetReportAction | SetPageAction | ClearAllAction;

export const actionCreators = {
  GetEmbedInfo: (embedCode: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    dispatch({ type: "GET_OVERVIEW_REQUEST", embedCode: embedCode });
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      const result = await AnalyticsService.GetEmbedInfo(embedCode);

      if (result.ok) {
        dispatch({
          type: "GET_OVERVIEW_RESPONSE",
          embedCode: embedCode,
          embedSet: result.data
        });
      } else {
        dispatch({
          type: "GET_OVERVIEW_RESPONSE_FAILURE",
          embedCode: embedCode
        });
        console.log("Error get report embed info.");
      }
    }
  },
  SetPageAction: (reportPageName: string, report: PbiReport): AppThunkAction<KnownAction> => async (dispatch, getState) => {    
    dispatch({ type: "SET_PAGE_ACTION", pageLoadingStatus:"started" });
    const appState = getState();
    if (appState) {
      try {
        await report.setPage(reportPageName);
        dispatch({ type: "SET_PAGE_ACTION", pageLoadingStatus:"complete" });
      }
      catch (e) {
        dispatch({ type: "SET_PAGE_ACTION", pageLoadingStatus:"failed" });
      }
    }
  },
  SetSelectedReport: (report: PbiReport): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    dispatch({ type: "SET_SELECTED_REPORT_ACTION", report });
  },
  SetPageList: (pages: Page[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
    console.log("set pages", pages);
    dispatch({ type: "SET_PAGE_LIST_ACTION", pages });
  },
  ClearAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({ type: "CLEAR_ALL" });
  }
};

// ----------------
// REDUCER -

const unloadedState: IReportState = {
  codedEmbeds: {},
  reports: null,
  page: null
};

export const reducer: Reducer<IReportState> = (
  state: IReportState | undefined,
  incomingAction: Action
): IReportState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "GET_OVERVIEW_REQUEST":
      return {
        ...state,
        page: null,
        selectedReport: null,
        codedEmbeds: {
          [action.embedCode]: { codedSet: null, isLoading: true }
        }
      };
    case "GET_OVERVIEW_RESPONSE":
      return {
        ...state,
        codedEmbeds: {
          [action.embedCode]: { codedSet: action.embedSet, isLoading: false }
        }
      };
    case "GET_OVERVIEW_RESPONSE_FAILURE":
      return {
        ...state,
        codedEmbeds: {
          [action.embedCode]: { codedSet: null, isLoading: false }
        }
      };
    case "SET_PAGE_ACTION":
      return {
        ...state,
        page: { ...state.page, loadState: action.pageLoadingStatus }
      };
    case "SET_PAGE_LIST_ACTION":
      return {
        ...state,
        page: { ...state.page, pages: action.pages }
      };
    case "SET_SELECTED_REPORT_ACTION":
      return {
        ...state,
        selectedReport: action.report
      };
    case "CLEAR_ALL":
      return {
        ...state,
        ...unloadedState
      };
  }

  return state;
};
