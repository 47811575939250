import * as React from "react";
import "./footer.css";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

export interface IFooterProps {
  linkItems?: IFooterLinkItem[];
  footerLogo?: string;
  footerLogoUrl?: string;
  footerLogoAlt?: string;
}

export interface IFooterLinkItem {
  linkTitle: string;
  linkUrl?: string;
}

const Menu = (props: IFooterLinkItem) => {
  return (
    <span>
      <a href={props.linkUrl} target="_blank">
        {props.linkTitle}
      </a>
    </span>
  );
};

const EpiqFooterComponent: React.SFC<IFooterProps> = props => {
  return (
    <div className="footer-wrapper">
      <footer className="footer">
        <Container max-width="lg">
          <Grid container>
            <Grid item sm={8} className="footer-links">
              {props.linkItems
                .slice(0, 5)
                .map(item => <Menu key={item.linkTitle} {...item} />)}
            </Grid>
            <Grid item sm={4} className="copy-right-wrapper">
              <span className="copy-right">
                <span className="copy-right-symbol">&#9400;</span>
                {new Date().getFullYear()}
                <a href={props.footerLogoUrl} target="_blank">
                  <img src={props.footerLogo} alt={props.footerLogoAlt} />
                </a>
              </span>
            </Grid>
          </Grid>
        </Container>
      </footer>
    </div>
  );
};

EpiqFooterComponent.defaultProps = {
  footerLogo: "images/epiq-logo-white.svg",
  footerLogoUrl: "http://www.epiqglobal.com/",
  footerLogoAlt: "Epiq",
  linkItems: [
    {
      linkTitle: "Disclaimer",
      linkUrl: "http://www.epiqglobal.com/en-us/disclaimer"
    },
    {
      linkTitle: "Terms of Use",
      linkUrl: "http://www.epiqglobal.com/en-us/terms-of-use"
    },
    {
      linkTitle: "Privacy Statement",
      linkUrl: "https://www.epiqglobal.com/en-us/privacy-statement"
    }
  ]
};
export { EpiqFooterComponent };
