import * as React from "react";
import { SiteBrandingModel } from "../../../../models/SiteBrandingModel";
import { Grid } from "@material-ui/core";
import "../SiteBranding.scss";
import CommonHelper from "../../../common/utilities/CommonHelper";
import { formatDate } from "@telerik/kendo-intl";
interface IProps {
  siteDetails: SiteBrandingModel;
}

type Props = IProps;

const View = (props: Props) => {
  const { siteDetails } = props;
  const createdDate = siteDetails.createdDate
    ? formatDate(CommonHelper.convertUTCDateToLocalDate(siteDetails.createdDate), "g")
    : "";
  return (
    <div className="site-details">
      <div className="site-info">
        <Grid container>
          <Grid item md={8} xs={12}>
            <div className="site-info-row">
              <strong>Created Date: </strong> {createdDate}
            </div>
            <div className="site-info-row">
              <strong>Login Page Custom URL: </strong> {siteDetails.hostName ? siteDetails.hostName : " - "}
            </div>
          </Grid>
          <Grid item md={4} xs={12}>
            <div className="site-info-row">
              <strong>Site ID: </strong> {siteDetails.siteId}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default View;
