import * as React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../store";
import RequestGrid, { RequestType } from "../common/RequestGrid";
import * as SessionStore from "../../../store/Session";
import { requestSupportSteps, createSupportRequest, wizardSummary, wizardConfirmSubmitMessage, warningMessage, jobMessagingConfig, TicketTypes } from "../../../models/ServicenowSupportRequestSettings";
import { IFieldDataDropdown, IWizardDropDownListItem, IWizardSection } from "../../../models/Wizard";
import { useEffect, useState } from "react";
import SupportService from "../../../services/SupportService";
import { JobTypeEnum } from "../../../models/Enums";
import SessionStorageApi from "../../../apis/storage/SessionStorageApi";
import { IStyleOverrides } from "../../../models/SiteBrandingModel";
import DisplayMessage from "../common/displayMessage";
import { CircularProgress } from "@material-ui/core";
import "./ServiceNowRequests.scss";

interface IProps {
  match?: any;
  history: any;
}

type Props = IProps & SessionStore.ISessionState;

const ServiceNowRequests = (props: Props) => {
  const [workRequestSteps, setWorkRequestSteps] = useState<Array<IWizardSection>>(requestSupportSteps);
  const siteInfo = SessionStorageApi.getBranding() as IStyleOverrides;
  const [canCreateTicket_whiteLabel, setCanCreateTicket_whiteLabel] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const defaultTicketTypesOrder = [TicketTypes.AccountAdministration, TicketTypes.GoAnywhereSupport, TicketTypes.ManageRelativityUsers, TicketTypes.ManageEpiqAccessUsers, TicketTypes.TechnicalSupport, TicketTypes.DRSITServices];

  const getSubHeader = (code: string) => {
    switch (code) {
      case TicketTypes.AccountAdministration:
        return "RELATIVITY HELP";
      case TicketTypes.GoAnywhereSupport:
        return "GOANYWHERE HELP";
      default:
        return "";
    }
  }

  const fetchRequestTypes = async () => {
    const requestTypeDropDown = [] as Array<IWizardDropDownListItem>;

    const result = await SupportService.getTicketTypes();
    if (result.ok) {

      if (siteInfo && siteInfo.Data && siteInfo.Data.HideTechnicalSupportFeature) { // this condition check is only needed for white label sites.
        const hasPermssion = result.data && result.data.filter((x: any) => x.permission !== null).length > 0;
        setCanCreateTicket_whiteLabel(hasPermssion);
      }

      result.data.forEach((r: any) => {
        requestTypeDropDown.push({
          id: r.code,
          title: r.ticketName,
          lookupValues: { permission: r.permission ? r.permission.permissionName : null, databaseHeader: r.ticketName, code: r.code, subHeader: getSubHeader(r.code) }
        });
      });
    }

    const sortedTicketTypes = requestTypeDropDown.sort((a, b) => {
      return (
        defaultTicketTypesOrder.indexOf(a.id as TicketTypes) - defaultTicketTypesOrder.indexOf(b.id as TicketTypes)
      );
    });

    if (props.sessionData.hideGeneralTechSupport) {
      const hasTechSupportReq = sortedTicketTypes.findIndex(x => x.id === TicketTypes.TechnicalSupport);
      if (hasTechSupportReq > -1) {
        sortedTicketTypes.splice(hasTechSupportReq, 1);
      }
    }

    const updatedSteps = workRequestSteps.map(x => {
      x.fields.map((y: any) => {
        if (y.fieldId === "1.1") {
          y.DropDownOptions = sortedTicketTypes;
          if (requestTypeDropDown.length == 1) {
            y.value = requestTypeDropDown[0].id;
          }
        }
        return y;
      });
      return x;
    });

    setWorkRequestSteps(updatedSteps);
    setIsLoading(false);
  }

  const updateUserFields = async () => {
    const firstName = props.sessionData.firstName;
    const lastName = props.sessionData.lastName;
    const email = props.sessionData.username;
    const employerId = props.sessionData.employerId;
    const updatedSteps = workRequestSteps.map(x => {
      x.fields.map((y: any) => {
        if (y.fieldId === "1.0.0") {
          y.value = firstName + " " + lastName;
        }
        if (y.fieldId === "3.1") {
          y.value = firstName;
        }
        if (y.fieldId === "3.2") {
          y.value = lastName;
        }
        if (y.fieldId === "3.3" || y.fieldId === "1.0.3") {
          y.value = email;
        }
        if (y.fieldId === "1.0.4") {
          y.value = employerId;
        }
        return y;
      });
      return x;
    });
    setWorkRequestSteps(updatedSteps);
  }

  useEffect(() => {
    (async () => {
      fetchRequestTypes();
      updateUserFields();
    })();
  }, []);


  if (!isLoading) {
    return (
      <>
        {siteInfo && siteInfo.Data && siteInfo.Data.HideTechnicalSupportFeature && !canCreateTicket_whiteLabel ? (
          <>
            <div>
              {<DisplayMessage message={siteInfo.Data.HideTechnicalSupportFeatureMessage} />}
            </div>
          </>) : (<><RequestGrid
            {...props}
            requestType={RequestType.ServiceNow}
            showCreateRequestButton={true}
            showRequestList={true}
            createWorkRequest={createSupportRequest}
            workRequestSteps={workRequestSteps}
            wizardConfirmSubmitMessage={wizardConfirmSubmitMessage}
            wizardWarningMessage={warningMessage}
            wizardSummary={wizardSummary}
            disableSubmitButton={props.sessionData.preImpersonatedUser != null}
            jobMessagingConfig={jobMessagingConfig}
            jobTypeNumber={JobTypeEnum.CreateTicket}
          /></>)
        }
      </>
    );
  }
  else {
    return (<div className="ticket-loader">
      <CircularProgress />
    </div>)
  }
};

export default connect((state: IApplicationState) => ({ ...state.sessionState }), null)(ServiceNowRequests);
