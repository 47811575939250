import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";
import ConsoleService from "../services/ConsoleService";
import { ApplicationFeature } from "../models/ApplicationModels";

export interface IApplicationsState {
  isLoading: boolean;
  applications: ApplicationFeature[];
  totalApplicationsCount: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface GetApplicationRequestAction {
  type: "GET_APPLICATIONS_REQUEST";
}

interface GetApplicationResponseAction {
  type: "GET_APPLICATIONS_RESPONSE";
  applications: ApplicationFeature[];
  totalApplicationsCount: 0;
}
interface GetApplicationResponseFailure {
  type: "GET_APPLICATIONS_RESPONSE_FAILURE";
}
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = GetApplicationRequestAction | GetApplicationResponseAction | GetApplicationResponseFailure;

export const actionCreators = {
  requestApplications: (userId: string, searchText: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    dispatch({ type: "GET_APPLICATIONS_REQUEST" });
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      const result = await ConsoleService.getMyApplications({ take: 5000 });

      if (result.ok) {
        dispatch({
          type: "GET_APPLICATIONS_RESPONSE",
          applications: result.data.results,
          totalApplicationsCount: result.data.results.length
        });
      }
      else {
        dispatch({
          type: "GET_APPLICATIONS_RESPONSE_FAILURE"
        });
        console.log("Error get applications.");
      }
    }
  }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: IApplicationsState = {
  applications: [],
  isLoading: false,
  totalApplicationsCount: 0
};

export const reducer: Reducer<IApplicationsState> = (state: IApplicationsState | undefined, incomingAction: Action): IApplicationsState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "GET_APPLICATIONS_REQUEST":
      return {
        applications: state.applications,
        isLoading: true,
        totalApplicationsCount: state.totalApplicationsCount
      };
    case "GET_APPLICATIONS_RESPONSE":
      return {
        applications: action.applications,
        isLoading: false,
        totalApplicationsCount: action.totalApplicationsCount
      };
    case "GET_APPLICATIONS_RESPONSE_FAILURE":
      return {
        applications: state.applications,
        isLoading: false,
        totalApplicationsCount: state.totalApplicationsCount
      };
    default:
      return state;
  }
};
