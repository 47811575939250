import * as React from "react";
import MultiSelectDropDown from "../common/MultiSelectDropDown";
import DropDownListItem from "../../../models/DropDownListItem";
import AdminService from "../../../services/AdminService";
import GridSelector from "../../common/grid/GridSelector";
import { Grid, CircularProgress } from "@material-ui/core";

interface IProps {
  savedListItems?: number[];
  addSelectedListItems: (selectedItems: DropDownListItem[]) => void;
  closePermissionPopup: (accepted: boolean) => void;
  isPermissionSelectorOpen: boolean;
  roleDetails: {
    id: number;
    roleName: string;
    description?: string;
  };
  addAvailable: boolean;
  epiqOnly: boolean;
}

type State = {
  isLoading: boolean;
  savedListItems: number[];
};

type Props = IProps;

class PermissionPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    
    this.state = {
      isLoading: false,
      savedListItems: this.props.savedListItems ? this.props.savedListItems :[]
      };
  }

  async componentDidMount() {    
    const { savedListItems, roleDetails } = this.props;
 
    if (savedListItems === null && roleDetails) {     
      await this.getSavedRolePermissions();
    }
  }

  getPermissions = async (searchText: string) => {
    const params = {
      skip: 0,
      take: 10000,
      searchText: searchText,
      orderBy: "PermissionName",
      isAscending: true,
      epiqOnly: this.props.epiqOnly
    };   
    const response = await AdminService.getPermissions(params);

    if (response.ok) {     
      const searchResults: DropDownListItem[] = response.data.map((p: any) => {
        return {
          id: p.id,
          name: p.permissionName,
          title: p.permissionName,
          description: p.description,
          restProps: {
            createdBy: p.createdBy,
            createdDate: p.createdDate,
            epiqOnly: p.epiqOnly,
          }
        };
      });

      return searchResults;
    } else {    
      return [] as DropDownListItem[];
    }
  };

  getSavedRolePermissions = async () => {
    const params = {
      skip: 0,
      take: 10000,
      searchText: "",
      orderBy: "PermissionName",
      isAscending: true
    };

    this.setState({ isLoading: true });
    const response = await AdminService.getPermissionsByRoleId(this.props.roleDetails.id, params);   
    
    if (response.ok && response.data.results) {      
      const savedListItems = response.data.results.map((p: any) => p.id as number) as number[]
      this.setState({ isLoading: false, savedListItems: savedListItems });
    } else {
      this.setState({ isLoading: false });     
    }
  };

  render() {
    const { isPermissionSelectorOpen, roleDetails, addAvailable, closePermissionPopup, addSelectedListItems, savedListItems } = this.props;
    const { isLoading } = this.state;
    const roleName = roleDetails && roleDetails.roleName.length > 0 ? roleDetails.roleName : "<RoleName>";

    if (roleDetails === null) {
      return null;
    }  

    return (      
      <div>
        <GridSelector
          isOpen={isPermissionSelectorOpen}
          acceptBtnText="Add"
          cancelBtnText="Cancel"
          titleText="Add Permissions"
          addAvailable={addAvailable}
          onClose={closePermissionPopup}
          addClass="add-permission-modal modal-as-sidebar"
        >
          <Grid container >
            <Grid item xs={12}>
              <h6>
                Search and add permission to <span>{roleName}</span>.
              </h6>
            </Grid>
            <Grid item xs={12}>
            {isLoading ? (
              
                <div className="drop-down-loader">
                  {" "}
                  <CircularProgress />
                </div>             
            ) : (              
                  <MultiSelectDropDown
                    addSelectedListItems={addSelectedListItems}
                    getSearchResults={this.getPermissions}
                    savedListItems={savedListItems === null ? this.state.savedListItems : savedListItems}
                    searchWidth={12}
                />
                )}
            </Grid>
         </Grid>          
        </GridSelector>
     </div>
     
    );
  }
}

export default PermissionPopup;
