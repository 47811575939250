import React from "react";
import { GridCell } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { CircularProgress } from "@material-ui/core";

// TODO TS for props, and also generic usage of interface for _localId end editField
export interface ICommandCellProps<T> {
  onEdit?: (dataItem: T) => void;
  onRemove?: (dataItem: T) => void;
  onAdd?: (dataItem: T) => void;
  onUpdate?: (dataItem: T) => void;
  onDiscard?: (dataItem: T) => void;
  onCancel?: (dataItem: T) => void;
  onSelection?: (dataItem: T) => void;
  onDownload?: (dataItem: T) => void;
  hideDownload?: (dataItem: T) => boolean;
  hideRemove?: (dataItem: T) => boolean;
  hideEdit?: (dataItem: T) => boolean;
  selectedField?: string;
  selectionMode?: string;
  editField?: string;
  saving?: boolean;
  disableAdd?: boolean;
}

//@ts-ignore
export default function CommandCell(commandCellProps: ICommandCellProps<T>) {
  return class extends GridCell {
    render() {
      const { dataItem } = this.props;
      const inEdit = dataItem[commandCellProps.editField];
      const isNewItem = dataItem._localId === undefined;
      const isSelected = dataItem[commandCellProps.selectedField];
      const selectedIcon = commandCellProps.selectionMode === "single" ? (isSelected ? "radiobutton-checked" : "radiobutton") : (isSelected ? "checkbox-checked" : "checkbox");

      // const isLocked = dataItem[commandCellProps.selectionLockedField];

      if (commandCellProps.saving && inEdit) {
        return (<td className="k-command-cell"><CircularProgress size={20} /></td>)
      }

      return inEdit ? (
        <td className="k-command-cell">
          <Button
            className="icon-button"
            icon={isNewItem ? "plus" : "save"}
            onClick={() => (isNewItem ? commandCellProps.onAdd(dataItem) : commandCellProps.onUpdate(dataItem))}
            disabled={commandCellProps.disableAdd}
          />
          <Button
            className="icon-button"
            icon={isNewItem ? "delete" : "cancel"}
            onClick={() => (isNewItem ? commandCellProps.onDiscard(dataItem) : commandCellProps.onCancel(dataItem))}
          />
        </td>
      ) : (
        <td className="k-command-cell">
          {commandCellProps.onSelection && (
            <Button
              className="icon-button"
              icon={selectedIcon}
              disabled={commandCellProps.saving}
              onClick={() => commandCellProps.onSelection(dataItem)}
            />
          )}
          {((commandCellProps.hideEdit && !commandCellProps.hideEdit(dataItem)) ||
            (!commandCellProps.hideEdit && commandCellProps.onEdit)) && (
              <Button className="icon-button" icon="edit" onClick={() => commandCellProps.onEdit(dataItem)} disabled={commandCellProps.saving} />
            )}
          {((commandCellProps.hideRemove && !commandCellProps.hideRemove(dataItem)) ||
            (!commandCellProps.hideRemove && commandCellProps.onRemove)) && (
              <Button className="icon-button" icon="delete" onClick={() => commandCellProps.onRemove(dataItem)} disabled={commandCellProps.saving} />
            )}
          {((commandCellProps.hideDownload && !commandCellProps.hideDownload(dataItem)) ||
            (!commandCellProps.hideDownload && commandCellProps.onDownload)) && (
              <Button className="icon-button" icon="download" onClick={() => commandCellProps.onDownload(dataItem)} disabled={commandCellProps.saving} />
            )}
        </td>
      );
    }
  };
}
