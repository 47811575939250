import { ServiceBase, ResponseMessages, ServiceResponseJson, ServiceResponse } from "./ServiceBase";
import { IPreferenceSerialized } from "../models/Preferences";

const GetPreferenceRequests: ResponseMessages = {
  FailedMessage: "Failed to get user preferences",
  SuccessMessage: "Successfully retrieved user preferences"
};

const SetPreferenceRequests: ResponseMessages = {
  FailedMessage: "Failed to set user preferences",
  SuccessMessage: "Successfully set user preferences"
};

export default class PreferenceService extends ServiceBase {
  static async getUserPreferences() {
    let url = `${PreferenceService.getBaseUri()}`;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetPreferenceRequests))
      .catch(async response => await this.handleResponseJson(response, GetPreferenceRequests));

    return result;
  }

  static async setUserPreference(value: IPreferenceSerialized) {
    let url = `${PreferenceService.getBaseUri()}`;

    const result: ServiceResponse = await fetch(url, { body: this.toPostBody(value), ...this.fetchPostOptions() })
      .then(async response => await this.handleResponse(response, SetPreferenceRequests))
      .catch(async response => await this.handleResponse(response, SetPreferenceRequests));

    return result;
  }

  static getBaseUri() {
    return `${(<any>window).ApplicationVariables.apiUri}userPref`;
  }
}