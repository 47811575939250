import * as React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import * as SessionStore from "../../store/Session";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import Countdown, { zeroPad } from "react-countdown";
import { Button } from "@material-ui/core";

interface IProps {
  closeCallback: () => void;
  endSessionCallback: () => void;
  warningTimeout: number;
  timeout: number;
}

interface ICountdownRenderProps {
  minutes: number;
  seconds: number;
}

type Props = IProps & SessionStore.ISessionState & typeof SessionStore.actionCreators;

const SessionLostModal = (props: Props) => {
  const reEstablishCallback = () => {
      console.log("close the session lost warning dialog");
      props.setSessionIsLost(false);
      props.closeCallback();
    },
    render = (renderProps: ICountdownRenderProps) => {
      return (
        <span>
          {zeroPad(renderProps.minutes)}:{zeroPad(renderProps.seconds)}
        </span>
      );
    };

  return (
    props.isLost && (
      <Dialog onClose={reEstablishCallback} title={"Session Lost"} width={425} height={365}>
        <div>Your Session has been lost and will expire in:</div>
        <p />
        <div style={{ fontSize: "21px", color: "#DD4843" }}>
          <Countdown renderer={render} date={Date.now() + props.timeout} />
        </div>
        <p />
        <div> You will be logged out and any unsaved data will be lost. </div>
        <p />
        <div> Do you want to try to re-establish your session? Any unsaved data will be lost.</div>
        <DialogActionsBar>
          <Button variant="contained" className="btn-contined" name="END_SESSION" onClick={props.endSessionCallback}>
            END SESSION
          </Button>
          <Button
            variant="contained"
            className="btn-contined"
            color="primary"
            name="RE-ESTABLISH"
            onClick={reEstablishCallback}
          >
            RE-ESTABLISH
          </Button>
        </DialogActionsBar>
      </Dialog>
    )
  );
};

export default connect((state: IApplicationState) => state.sessionState, SessionStore.actionCreators)(SessionLostModal);
