import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import Autorenew from "@material-ui/icons/Autorenew";
import "./GridRefreshButton.scss";

interface IProps {
  onRefreshGridClick: () => void;
}

const GridRefreshButton = (props:IProps) => {
  return (
    <Button className="refresh-btn" onClick={props.onRefreshGridClick}>
      <Autorenew />
    </Button>
  );
};

export default GridRefreshButton;