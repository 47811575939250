import { Action, Reducer } from "redux";
import { IFeature, ISubFeature } from "../SessionManager";
import { IPreference, Preferences } from "../models/Preferences";
import { IPagedReport } from "../models/Report";
import { IRoute } from "../models/Routing";
import { IContextInfo } from "../models/AdminContext";
import { IAgreementAcceptanceModel } from "../models/MessageModel";

export interface ISessionData {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  createdDate: string;
  permissions: Set<string>;
  destination: IRoute;
  totalApplications: number;
  featurePermissions: Record<string, IFeature | ISubFeature>;
  superAdmin: boolean;
  validSeconds: number;
  warningSeconds: number;
  isFederated: boolean;
  hasDashboardProjects: boolean;
  internalUsersDomains: Set<string>;
  userPrefs: Preferences;
  impersonateMode: boolean;
  preImpersonatedUser?: string;
  hasWorkspaceFeature?: boolean;
  employerId?: number;
  hasAgreements: boolean;
  isBasic: boolean;
  hideGeneralTechSupport?: boolean;
}
export interface ISessionState {
  sessionData: ISessionData;
  isIdle: boolean;
  isLost: boolean;
  pagedReports?: Array<IPagedReport>;
  changingSession?: boolean;
  sessionDeSync?: boolean;
  context?: IContextInfo;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface ISetSessionDataAction {
  type: "SET_SESSION_DATA";
  sessionData: ISessionData;
}

interface ISetSessionIsIdleAction {
  type: "SET_SESSION_IS_IDLE";
  isIdle: boolean;
}

interface ISetReportPagesNoToken {
  type: "SET_REPORT_PAGES_NO_TOKEN";
  pagedReports: Array<IPagedReport>;
}

interface ISetSessionIsLostAction {
  type: "SET_SESSION_IS_LOST";
  isLost: boolean;
}

interface ISetPreferenceAction {
  type: "SET_PREFERNCE_SUCCESS";
  preference: IPreference;
}

interface ISetPreferencesAction {
  type: "SET_PREFERNCES_SUCCESS";
  preferences: Preferences;
}

interface ISetChangingSessionAction {
  type: "SET_CHANGING_SESSION";
  changingSession: boolean;
}

interface ISetSessionDeSyncAction {
  type: "SET_SESSION_DE_SYNC";
  sessionDeSync: boolean;
}

interface ISetAdminContextAction {
  type: "SET_ADMIN_CONTEXT";
  context: IContextInfo;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = ISetSessionDataAction | ISetSessionIsIdleAction | ISetReportPagesNoToken | ISetSessionIsLostAction | ISetPreferenceAction | ISetPreferencesAction | ISetPreferencesAction | ISetChangingSessionAction | ISetSessionDeSyncAction | ISetAdminContextAction;

export const actionCreators = {
  setSessionData: (sessionData: ISessionData) => ({ type: "SET_SESSION_DATA", sessionData } as ISetSessionDataAction),
  setSessionIsIdle: (isIdle: boolean) => ({ type: "SET_SESSION_IS_IDLE", isIdle } as ISetSessionIsIdleAction),
  setReportPages: (reports: Array<IPagedReport>) =>
    ({ type: "SET_REPORT_PAGES_NO_TOKEN", pagedReports: reports } as ISetReportPagesNoToken),
  setSessionIsLost: (isLost: boolean) => ({ type: "SET_SESSION_IS_LOST", isLost } as ISetSessionIsLostAction),
  setPreference: (preference: IPreference) => ({ type: "SET_PREFERNCE_SUCCESS", preference } as ISetPreferenceAction),
  setPreferences: (preferences: Preferences) => ({ type: "SET_PREFERNCES_SUCCESS", preferences } as ISetPreferencesAction),
  setChangingSession: (changingSession: boolean) => ({ type: "SET_CHANGING_SESSION", changingSession } as ISetChangingSessionAction),
  setSessionDeSync: (sessionDeSync: boolean) => ({ type: "SET_SESSION_DE_SYNC", sessionDeSync } as ISetSessionDeSyncAction),
  setContext: (context: IContextInfo) => ({ type: "SET_ADMIN_CONTEXT", context } as ISetAdminContextAction),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: ISessionState = {
  sessionData: null,
  isIdle: false,
  isLost: false,
  pagedReports: null
};

export const reducer: Reducer<ISessionState> = (
  state: ISessionState | undefined,
  incomingAction: Action
): ISessionState => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "SET_SESSION_DATA":
      return { ...state, sessionData: (action as ISetSessionDataAction).sessionData };
    case "SET_SESSION_IS_IDLE":
      return { ...state, isIdle: (action as ISetSessionIsIdleAction).isIdle };
    case "SET_REPORT_PAGES_NO_TOKEN":
      return { ...state, pagedReports: action.pagedReports };
    case "SET_SESSION_IS_LOST":
      return { ...state, isLost: (action as ISetSessionIsLostAction).isLost };
    case "SET_PREFERNCE_SUCCESS":
      return {
        ...state, sessionData: {
          ...state.sessionData,
          userPrefs: {
            ...state.sessionData.userPrefs,
            [action.preference.prefName]: action.preference.value
          }
        }
      };
    case "SET_PREFERNCES_SUCCESS":
      return { ...state, sessionData: { ...state.sessionData, userPrefs: action.preferences } };
    case "SET_CHANGING_SESSION":
      return { ...state, changingSession: action.changingSession };
    case "SET_SESSION_DE_SYNC":
      return { ...state, sessionDeSync: action.sessionDeSync };
    case "SET_ADMIN_CONTEXT":
      return { ...state, context: action.context };
    default:
      return state;
  }
};
