import { CircularProgress } from "@material-ui/core";
import { Button } from "@progress/kendo-react-buttons";
import React, { useEffect, useState } from "react";
import { IJobDownloadRequest } from "../../models/JobModels";
import FileService from "../../services/FileService";
import JobsService from "../../services/JobService";
import "./JobResultDownloading.scss";
import { formatDate } from "@telerik/kendo-intl";
import Tooltip from "@material-ui/core/Tooltip";

type Props = IProps;
type statusTypes = "ready" | "fetchingLink" | "creating" | "downloaded" | "failed";

interface IProps {
  jobId: string;
  jobType: number;
  generateCount?: number;
  payload?: any;
  fileNamePrefix: string;
  fileNameSuffix: string;
  approvalChecked?: boolean;
  fileExtension?: string;
  toolTipTitle?: string;
  downloadButtonLabel?: string;
}

export default (props: Props) => {
  const [status, setStatus] = useState<statusTypes>("ready");
  const [regenerateDownload, setRegenerateDownload] = useState<boolean>(true);

  useEffect(
    () => {
      setRegenerateDownload(true);
    },
    [props.generateCount]
  );

  useEffect(() => {}, [status]);

  const startDownload = async function() {
    const request = {
      jobType: props.jobType,
      jobId: props.jobId,
      payload: props.payload,
      regenerateDownload,
      approvalChecked: props.approvalChecked
    };
    setRegenerateDownload(false);
    setStatus("fetchingLink");
    download(request);
  };

  const download = async function(request: IJobDownloadRequest) {
    const result = await JobsService.download(request);
    if (result.ok && result.data.downloadUrl) {
      await FileService.downloadFile(
        result.data.downloadUrl,
        3,
        props.fileNamePrefix + formatDate(new Date(), "yyyyMMdd") + " - " + props.fileNameSuffix + props.fileExtension
      );
      setStatus("downloaded");
    } else if (result.ok) {
      setStatus("creating");
      setTimeout(() => {
        download({ ...request, regenerateDownload: false });
      }, 750);
    } else {
      setRegenerateDownload(true);
      setStatus("failed");
    }
  };

  const isFetching = status == "fetchingLink" || status == "creating";
  const downloadText = status == "failed" ? "Failed Download" : props.downloadButtonLabel;

  return (
    <span className="job-downloading">
      <Tooltip placement="top"  title={props.toolTipTitle ? props.toolTipTitle : ""} arrow>
        <Button
          disabled={isFetching}
          type="button"
          className="btn-secondary fa fa-download"
          icon={"download"}
          onClick={startDownload}
        >
          {isFetching && <CircularProgress size={14} />}
          <span className="job-downloading-btn-txt">{downloadText}</span>
        </Button>
      </Tooltip>
    </span>
  );
};
