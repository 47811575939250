import * as React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import * as AuthenticationStore from "../../store/Authentication";
import OktaSignInWidget, { IAuthResponse } from "../okta/OktaSignInWidgetWrapper";
import "./Login.scss";
import { EpiqFooterComponent } from "../edge/footer/EpiqFooterComponent";
import AuthenticationService from "../../services/AuthenticationService";
import CircularProgress from "@material-ui/core/CircularProgress";
import epiqwhiteLogo from "../../images/epiq-logo-white.svg";
import authenticationStorageApi from "../../apis/storage/AuthenticationStorageApi";
import SessionStorageApi from "../../apis/storage/SessionStorageApi";
import { AppInsightsHelper } from "../../AppInsights";
import { authenticatedRoutes, Routes } from "../../models/Routing";
import { v4 as uuid } from "uuid";

import logo from "../../images/Epiq_Access_Logo_250x250-White.png";
import poweredLogo from "../../images/epiq-logo-inline.png";

interface IProps {
  history?: any;
  match?: any;
  authenticated: boolean;
}

type Props = IProps & AuthenticationStore.IAuthenticationState & typeof AuthenticationStore.actionCreators;
type State = {
  checkingAuthentication: boolean;
  requestType: string;
};

class Login extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    this.state = {
      checkingAuthentication: true,
      requestType: ""
    };
  }

  async componentDidMount() {
    if (this.props.authenticated) {
      this.props.history.push("/applications");
    }

    authenticationStorageApi.clearAll();
    this.setState({ checkingAuthentication: false });
  }

  async onSuccess(res: IAuthResponse) {
    if (res.status === "SUCCESS") {
      if (window.location.href.toLowerCase().indexOf("/welcome") > 0 || window.location.href.toLowerCase().indexOf("/reset-password") > 0 || this.state.requestType === "password-expired") {
        await AuthenticationService.SendPasswordChangeEmail(res.user.profile.login);
        this.props.history.push("/");
      }

      res.session.setCookieAndRedirect(window.ApplicationVariables.initiateLoginUri);
    } else {
      // The user can be in another authentication state that requires further action.
      // For more information about these states, see:
      //   https://github.com/okta/okta-signin-widget#rendereloptions-success-error
    }
  }

  onError(err: string) {
    console.log("error logging in", err);
  }

  returnControllerType = (type: string) => {
    if (this.setState) {
      this.setState({ requestType: type })
    }
  }

  render() {
    let token = this.props.match.params.activationToken ? this.props.match.params.activationToken : this.props.match.params.token;

    const location = this.props.history.location.Location ? this.props.history.location.Location : this.props.history.location;

    if (location.pathname.indexOf(authenticatedRoutes[Routes.Login].path) === 0) {
      authenticationStorageApi.setPreLoginPath("/");
    }
    else {
      authenticationStorageApi.setPreLoginPath(location.pathname);
    }

    return (
      <div>
        {this.state.checkingAuthentication ? (
          <div className="spin-container">
            <CircularProgress />
          </div>
        ) : (
          <div className="login-page">
            <div>
              <div className="login-box">
                <div className="login-area">
                  <div className="login-logo-section">
                    <a className="login-home-logo" href="/">
                      </a>
                      <a className="powered-by-logo" href="/">

                      </a>
                    </div>
                  <section className="login-section">
                    <OktaSignInWidget onSuccess={this.onSuccess} onError={this.onError} recoveryToken={token} returnControllerType={this.returnControllerType} />
                  </section>
                </div>
              </div>
              <div className="login-footer">
                <footer className="login-footer">
                  <div><a href="https://www.epiqglobal.com/en-us/disclaimer" target="_blank">Disclaimer</a></div>
                  <div><a href="https://www.epiqglobal.com/en-us/terms-of-use" target="_blank">Terms of Use</a></div>
                  <div><a href="https://www.epiqglobal.com/en-us/privacy-statement" target="_blank">Privacy Statement</a></div>
                  <div><a href="https://www.epiqglobal.com/en-us/epiqadmin/footer/cookie-notice" target="_blank">Cookie Notice</a></div>
                  <div className="login-footer-right">
                    <div className="copy-right">
                      © {(new Date()).getFullYear()} Epiq. All rights reserved.
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default connect((state: IApplicationState) => state.authenticationState, null)(Login as any);
