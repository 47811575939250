import { ServiceBase, ResponseMessages, ServiceResponseJson, ServiceResponse } from "./ServiceBase";
import { ISpeedTest, ISpeedTestResult } from "../models/SpeedTestResultModel";
import { IUserSpeedTestRequest } from "../components/administration/user/SpeedTest/SpeedTestList";
import CommonHelper from "../components/common/utilities/CommonHelper";
import { formatDate } from "@telerik/kendo-intl";

const GetSpeedTestRequests: ResponseMessages = {
  FailedMessage: "Failed to get speed test results",
  SuccessMessage: "Successfully retrieved speed test results"
};

const SaveSpeedTestResultRequests: ResponseMessages = {
  FailedMessage: "Failed to save speed test results",
  SuccessMessage: "Successfully saved speed test results"
};

export default class SpeedTestService extends ServiceBase {

  static async getSpeedTests(params: IUserSpeedTestRequest) {
    const filterJSON = JSON.stringify(params.filters) || "";
    const sortJSON = JSON.stringify(params.sort) || "";
    const url = `${SpeedTestService.getBaseUri()}/?${params.userId}&${SpeedTestService.getFilterSortQueryString(filterJSON, sortJSON)}&${SpeedTestService.toQueryString(params, ["filters", "sort", "filter"])}`;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetSpeedTestRequests))
      .catch(async response => await this.handleResponseJson(response, GetSpeedTestRequests));
 
      if (result.ok) {
        result.data.results = result.data.results.map((result: ISpeedTest): ISpeedTest => {
          const localDateTime = CommonHelper.convertUTCDateToLocalDate(result.createdDate);

          return {
            ...result,
            createdDate: result.createdDate ? formatDate(localDateTime, "g") : null
          };
        });
      };

    return result;
  }

  static async saveSpeedTestResult(value: ISpeedTestResult) {
    let url = `${SpeedTestService.getBaseUri()}`;

    const result: ServiceResponse = await fetch(url, { body: this.toPostBody(value), ...this.fetchPostOptions() })
      .then(async response => await this.handleResponse(response, SaveSpeedTestResultRequests))
      .catch(async response => await this.handleResponse(response, SaveSpeedTestResultRequests));

    return result;
  }

  static getBaseUri() {
    return `${(<any>window).ApplicationVariables.apiUri}speedtest`;
  }
}