import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { AdvancedGrid, AdvancedSortDescriptor, IGridParamsAdvancedRefreshable, IGridStateMerged } from "../../common/grid/AdvancedGrid";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import { GridCellProps, GridColumnProps, GridColumnResizeEvent } from "@progress/kendo-react-grid";
import { Button, Paper } from "@material-ui/core";
import { DropDownButton, DropDownButtonItemClickEvent, Button as KendoButton } from "@progress/kendo-react-buttons";
import ResourceService from "../../../services/ResourceService";
import AdminContextService from "../../../services/AdminContextService";
import AuthenticationService from "../../../services/AuthenticationService";
import * as SessionStore from "../../../store/Session";
import { NavLink } from "react-router-dom";
import { IApplicationState } from "../../../store";
import { IResourceFields, IResourceFieldsSelectable, ResourceRequestFieldsToShow } from "../../../models/ResourceModels";
import _ from "lodash";
import "./ContextUserList.scss";
import DialogBox from "../../common/DialogBox";
import { DialogCloseEvent } from "@progress/kendo-react-dialogs";
import NotificationService from "../../../services/NotificationService";
import SupportRequestPopup from "../../servicerequest/pulse/SupportRequestPopup";
import { createImportContextUsersRequest, warningMessage, createJobMessagingConfig, wizardConfirmSubmitMessage, wizardSummary, wizardSupportSteps } from "../clientContext/ContextUserImportWizardSettings";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { GridPreferences, GridUserPreferences, IGridPreference } from "../../../models/Preferences";
import { ColumnFromField, setSavedColumnsToGrid, setDefaultColumnsToGrid, getFields, saveGridColumnWidthPreferenceInDB } from "../../common/grid/columns/ColumnHelper";
import { ColumnFieldListBox } from "../../common/grid/columns/ColumnFieldListBox";
import SettingsIcon from "@material-ui/icons/Settings";
import GridSelector from "../../common/grid/GridSelector";
import SyncRelativityUsers from "./SyncRelativityUsers";
import JobService, { IGetJobStatusResponseData } from "../../../services/JobService";
import { Checkbox } from "@progress/kendo-react-inputs";
import { ServiceBase } from "../../../services/ServiceBase";
import JobsService from "../../../services/JobService";
import CommonHelper from "../../common/utilities/CommonHelper";
import { JobTypeEnum } from "../../../models/Enums";
import { IGridPreferenceModel, PreferenceTypeStringEnum } from "../../../models/PreferenceModel";

interface IProps {
  history: any;
  match?: any;
  className: string;
  selectionChange?: (selected: IResourceFields[]) => void;
  addClickHandler?: () => void;
  additionalUsers?: IResourceFields[];
  enableRemoveUser?: boolean;
  onRemoveUpdateUser?: (selected: IResourceFields) => void;
  isOpen?: boolean;
}

type Props = IProps & SessionStore.ISessionState;
const preferenceType = PreferenceTypeStringEnum.RelUserListGridPreferences;
const tableKey = "RelUserList";

const GridConfigurations = {
  PageSize: 100,
  OrderBy: "Email",
  Ascending: "asc",
  Descending: "desc"
};

const startingGridParams: IGridParamsAdvancedRefreshable = {
  skip: 0,
  take: GridConfigurations.PageSize,
  sort: [{ field: "DisplayName", dir: "asc", addedCoalesceFields: ["RelEmail"] }],
  refreshToggle: false,
  queryGroupsInfo: true,
  batchRequests: [
    {
      isDistinct: true,
      resourceFieldsToShow: ResourceRequestFieldsToShow.PermissionCode,
      onlyFields: ["Unique Relativity Id", "Name"],
      includeParentHierarchyChain: true,
      batchFilters:
        [
          {
            logic: "and",
            filters: [
              {
                field: "ResourceGroupId",
                operator: "eq",
                value: -1
              }
            ]
          }
        ]
    },
    {
      isDistinct: true,
      onlyFields: ["RelFirstName", "RelLastName", "RelEmail", "RelUserWebsite", "PermissionCode", "Name", "RelUserLastLoginDate"],
      expandPermissions: true,
      joinTables: [
        {
          name: "RelativityUser",
          joinType: 3
        }
      ]
    },
    {
      expandPermissions: true,
      separateResource: "user",
      independentBatch: true,
      joinTables: [
        {
          name: "UserStatusType",
          joinType: 3
        }
      ],
      batchFilters:
        [
          {
            logic: "and",
            filters: [
              {
                field: "ResourceGroupId",
                operator: "eq",
                value: -1
              }
            ]
          }
        ]
    },
    {
      separateResource: "user",
      joinTables: [
        {
          batchNum: 1,
          joinType: 2,
          optionalJoinType: "email"
        }
      ]
    }
  ]
};

const getDefaultUsers = () => {
  return { items: [], merged: [], count: 0, selections: {} } as IGridStateMerged<IResourceFieldsSelectable>;
}

const getResourceGroup = (dataState: IGridParamsAdvancedRefreshable, useUserBatch?: boolean): number => {

  const batchIndx = useUserBatch ? 2 : 0;

  return dataState.batchRequests[batchIndx].batchFilters[0].filters[0].value;
}

const dataStateWithContextResourceGroup = (userGroupsId: number, dataState: IGridParamsAdvancedRefreshable) => {
  const updatedDataState = _.cloneDeep(dataState);
  updatedDataState.batchRequests[0].batchFilters[0].filters[0].value = userGroupsId;
  updatedDataState.batchRequests[2].batchFilters[0].filters[0].value = userGroupsId;
  return updatedDataState;
}
const defaultColWidth = 120;
const relUserListMasterFields = {
  [tableKey]: [{ field: "Username", colWidth: defaultColWidth },
  { field: "Email", colWidth: defaultColWidth },
  { field: "FirstName", colWidth: defaultColWidth },
  { field: "LastName", colWidth: defaultColWidth },
  { field: "Name", colWidth: defaultColWidth },
  { field: "RelUserWebsite", colWidth: defaultColWidth },
  { field: "LastLoginDate", colWidth: defaultColWidth },
  { field: "RelUserLastLoginDate", colWidth: defaultColWidth },
  { field: "UserStatus", colWidth: defaultColWidth }]
} as Record<string, Array<GridPreferences>>;

const gridUserPreferences = {
  ["Username"]: { field: "Username", type: "text", displayName: "Username", isDefault: true, isMandatory: true },
  ["Email"]: { field: "Email", type: "text", displayName: "Email", isDefault: true, isMandatory: true },
  ["FirstName"]: { field: "FirstName", type: "text", displayName: "First Name", isDefault: true, isMandatory: true },
  ["LastName"]: { field: "LastName", type: "text", displayName: "Last Name", isDefault: true, isMandatory: true },
  ["LastLoginDate"]: { field: "LastLoginDate", type: "DateTime", displayName: "Epiq Access Last Login", isDefault: true },
  ["RelUserLastLoginDate"]: { field: "RelUserLastLoginDate", type: "DateTime", displayName: "Relativity Last Login", isDefault: true },
  ["Name"]: { field: "Name", type: "text", displayName: "Client" },
  ["RelUserWebsite"]: { field: "RelUserWebsite", type: "text", displayName: "Website" },
  ["UserStatus"]: { field: "UserStatus", type: "text", displayName: "User Status" },
} as Record<string, GridUserPreferences>
let columns = new Array<GridColumnProps>();

enum JobStates {
  Import_ReadyForProcessing = 5,
  Import_Processing = 6,
  Import_ProcessedError = 7,
  Import_ProcessedSuccess = 8,
  Import_DirectUsersReady = 14
}
enum ErrorMessages {
  ImportProcessSubmissionFailed = "Error occured during submitting job for sync process.",
  ImportProcessFailed = "Error occurred during sync processing.",
  FetchJobStatusFailed = "Error occured during fetching the job status details."
}

const addUserMenuList = [{ title: "Create User", id: 1 }, { title: "Sync Users", id: 2 }, { title: "Import Users", id: 3 }];

const ContextUserList = (props: Props) => {

  const gridRef = useRef<AdvancedGrid<IResourceFields, IGridParamsAdvancedRefreshable>>(null);
  const [hasError, setHasError] = useState(false);
  const [isGridLoading, setIsGridLoading] = useState(true);
  const [dataState, setDataState] = useState(props.context.admin ? dataStateWithContextResourceGroup(props.context.admin.contextResourceGroupId, startingGridParams) : null);
  const [usersMerged, setUsersMerged] = useState(getDefaultUsers());
  const [needCreateUser, setNeedCreateUser] = useState(null as IResourceFields);
  const [canCreateUser] = useState(props.sessionData.permissions.has("EpiqAdminCreateUser") || props.sessionData.permissions.has("CreateUser"));
  const filterTimeOut = useRef(null);
  const [userName, setUserName] = useState("");
  const [strongArmRefreshCount, setStrongArmRefreshCount] = useState(0);
  const [importUsersWizardOpen, setImportUsersWizardOpen] = useState(false);
  const [availableFields, setAvailableFields] = useState(new Array<GridUserPreferences>());
  const [selectedFields, setSelectedFields] = useState(new Array<GridUserPreferences>());
  const [showSettingsDialog, setShowSettingsDialog] = useState(false);
  const [potentiallySelectedRelUsers, setPotentiallySelectedRelUsers] = useState<Array<IResourceFieldsSelectable>>([]);
  const [isSyncRelUserSelectorOpen, setIsSyncRelUserSelectorOpen] = useState(false);
  const [selectedSyncRelUsers, setSelectedSyncRelUsers] = useState<Array<IResourceFieldsSelectable>>([]);
  const [enableAddSyncRelUserBtn, setEnableAddSyncRelUserBtn] = useState(false);
  const [sendInvitationEmail, setSendInvitationEmail] = useState(false);
  const [siteId, setSiteId] = useState(null);

  const checkWhiteLabelOptions = async () => {

    if (!props.context.admin) {
      setSiteId(null);
      return;
    }

    const query: IGridParamsAdvancedRefreshable = {
      skip: 0,
      take: 500,
      refreshToggle: false,
      queryGroupsInfo: true,
      batchRequests: [
        {
          expandPermissions: true,
          isDistinct: true,
          joinTables: [{ name: "WhitelabelSettings", joinType: 3 }],
          batchFilters:
            [
              {
                logic: "and",
                filters: [{
                  field: "ResourceGroupId",
                  operator: "eq",
                  value: props.context.admin.contextResourceGroupId
                }]
              }
            ]
        }
      ]
    };

    var results = await ResourceService.getResourcesDetailsBatch(query, "whitelabel");

    if (!results.ok) {
      setSiteId(null);
      return;
    }

    const onlyOneOption = results.data.results && results.data.results.length === 1;

    if (onlyOneOption) {
      setSiteId(results.data.results[0].objectId);
    } else {
      setSiteId(null);
    }
  }

  useEffect(() => {
    checkWhiteLabelOptions();
  }, []);

  useEffect(() => {
    if (props.isOpen !== false && dataState) {
      updateMergedState(usersMerged.items, usersMerged.selections);
    }
  }, [props.additionalUsers]);

  useEffect(() => {
    if (props.context.admin) {
      const existingDataState = dataState || startingGridParams;
      const oldResourceGroupId = getResourceGroup(existingDataState);
      const newState = dataStateWithContextResourceGroup(props.context.admin.contextResourceGroupId, existingDataState);
      const newResourceGroupId = getResourceGroup(newState);

      if (oldResourceGroupId !== newResourceGroupId) {
        setDataState(newState);
        gridRef && gridRef.current && gridRef.current.resetGridState({ ...newState });
        checkWhiteLabelOptions();
      }
    }
  }, [props.context]);

  if (!props.context.admin || !dataState) {
    return <Paper className={`rel-user-list-wrapper ${props.className}`}><div className="context-user-list-no-context"><div>You are not part of any admin context</div></div></Paper>
  }

  const setDynamicColumnsToGrid = () => {
    const savedGridColumns = props.sessionData.userPrefs.RelUserListGridPreferences as IGridPreference;
    if (savedGridColumns && savedGridColumns[tableKey]) {
      columns = setSavedColumnsToGrid(savedGridColumns[tableKey], gridUserPreferences, setColumnProperties, defaultColWidth);
      columns.push({
        title: "",
        cell: optionsCell,
        sortable: false,
        headerClassName: "no-sort",
        width: 60,
        resizable: false
      })

    }
    else {
      columns = setDefaultColumnsToGrid(relUserListMasterFields[tableKey], gridUserPreferences, setColumnProperties);
      columns.push({
        title: "",
        cell: optionsCell,
        sortable: false,
        headerClassName: "no-sort",
        width: 60,
        resizable: false
      })
    }
  }

  const setColumnProperties = (field: GridUserPreferences) => {
    const col = ColumnFromField(field);

    if (col.field.toLowerCase() === "username")
      col.cell = userCell;
    else if (col.field.toLowerCase() === "firstname")
      col.cell = firstNameCell;
    else if (col.field.toLowerCase() === "lastname")
      col.cell = lastNameCell;
    else if (col.field.toLowerCase() === "lastlogindate" || col.field.toLowerCase() === "reluserlastlogindate") {
      col.cell = dateCell;
      col.format = "{0:g}";
    }
    else
      col.cell = fieldCell;
    return col;
  }

  const handleShowSettingDialog = () => {
    const savedGridColumns = props.sessionData.userPrefs.RelUserListGridPreferences as IGridPreference;
    const masterFields = relUserListMasterFields[tableKey] as Array<GridPreferences>;
    const fields = getFields(savedGridColumns ? savedGridColumns[tableKey] : null, masterFields, gridUserPreferences);

    setShowSettingsDialog(true);
    setAvailableFields(fields.availableFields);
    setSelectedFields(fields.selectedFields);
  }

  const handleCloseSettingDialog = (reload: boolean) => {
    setShowSettingsDialog(false);
    if (reload) {
      setTimeout(() => {
        setDynamicColumnsToGrid();
        const newState = dataStateWithContextResourceGroup(props.context.admin.contextResourceGroupId, startingGridParams);
        setDataState(newState);
        gridRef.current.resetGridState({ ...newState }, true);
        setStrongArmRefreshCount(strongArmRefreshCount + 1);
      }, 100);
    }
  }

  const dateCell = (props: GridCellProps) => {
    const datestring = props.dataItem.fields[props.field];

    if (!datestring) {
      return <td></td>
    }

    const d = new Date(datestring)

    return <td>{d.toLocaleString()}</td>
  }

  const userCell = (props: GridCellProps) => {

    if (props.dataItem.fields.Username) {
      return <td>
        <div className="rel-user-details">
          <div className="rel-user-details-email">
            <NavLink to={{ pathname: `/administration/userDetails/${props.dataItem.objectId}`, state: { useContext: true } }}>
              <em title={props.dataItem.fields.Username}>{props.dataItem.fields.Username} </em>
            </NavLink>
          </div>
        </div>
      </td>;
    }
    else {
      return <td>{props.dataItem.fields.RelEmail}</td>
    }
  }

  const fieldCell = (props: GridCellProps) => {
    return <td>{props.dataItem.fields[props.field]}</td>
  }

  const lastNameCell = (props: GridCellProps) => {
    return <td>{props.dataItem.fields.LastName || props.dataItem.fields.RelLastName}</td>
  }

  const firstNameCell = (props: GridCellProps) => {
    return <td>{props.dataItem.fields.FirstName || props.dataItem.fields.RelFirstName}</td>
  }

  const optionsCell = (props: GridCellProps) => {

    if (!props.dataItem.objectId) {
      return <td><Button className="fas fa-user-plus add-user" onClick={e => { e.preventDefault(); addUser(props.dataItem); }} title="Add this user" /></td>
    }
    else {
      return <td></td>
      // const updateIcon = props.dataItem.fields.UpdateUser ? <></> : <Button className="fas fa-pen-to-square" onClick={e => { e.preventDefault(); editUser(props.dataItem); }} title="Edit this user" />

      // return <td><Button className="fas fa-user-minus remove-user" onClick={e => { e.preventDefault(); removeUser(props.dataItem); }} title="Remove this user" />{updateIcon}</td>
    }
  }

  const updateMergedState = (users: IResourceFieldsSelectable[], preSelected: Record<number | string, IResourceFieldsSelectable>) => {

    if (!users) {
      users = [];
    }

    for (let i = 0; i < users.length; i++) {
      if (preSelected[users[i].objectId]) {
        users[i].selected = true;
      }
    }

    if (props.additionalUsers && props.additionalUsers.length) {
      const merged = CommonHelper.dedupeObjects<IResourceFieldsSelectable>(props.additionalUsers, usersMerged.items, 'id');
      setUsersMerged({ items: users, merged, count: merged.length, selections: preSelected });
    } else {
      setUsersMerged({ items: users, merged: users, count: users.length, selections: preSelected });
    }
  }

  const addUser = async (dataItem: IResourceFields) => {
    var response = await AdminContextService.upsertUsernameUsersForGroup({ groupId: props.context.admin.memberUserGroupId, addUsersUsernames: [dataItem.fields.RelEmail] });

    if (response.ok && response.data.inValid && response.data.message == "These users are not in Epiq Access") {
      setNeedCreateUser(dataItem);
      setUserName(dataItem.fields.RelFirstName + " " + dataItem.fields.RelLastName);
      return;
    }

    if (!response.ok) {
      NotificationService.showErrorToast("There was a problem adding this user");
      return;
    }

    AuthenticationService.SetDirty().then(r => {
      if (!r.ok) {
        NotificationService.showWarningToast("Error updating your credentials, you may need to relogin in order to view this user");
      }
    });

    setStrongArmRefreshCount(strongArmRefreshCount + 1);

    gridRef.current.resetGridState(_.cloneDeep(dataState), false, true);
  }

  const removeUser = async (dataItem: IResourceFields) => {

    var response = await AdminContextService.upsertUsernameUsersForGroup({ groupId: props.context.admin.memberUserGroupId, removeUsers: [dataItem.objectId] });

    if (response.ok) {
      setStrongArmRefreshCount(strongArmRefreshCount + 1);

      gridRef.current.resetGridState(_.cloneDeep(dataState), false, true);
    }

    //let gs = [...usersMerged.items];

    //for (let i = 0; i < gs.length; i++) {
    //  if (gs[i].objectId === dataItem.objectId) {
    //    gs.splice(i, 1);
    //    break;
    //  }
    //}

    //updateMergedState(gs, usersMerged.selections);

    //props.onRemoveUpdateUser(dataItem);
  };

  const fetchDataAsync = async (gridParams: IGridParamsAdvancedRefreshable, caller?: string) => {
    setDynamicColumnsToGrid();

    if (props.isOpen === false) {
      return;
    }

    var updatedDataState = { ...gridParams };

    setIsGridLoading(true);

    updatedDataState.filters = filterQueryFormat(updatedDataState.filter);
    updatedDataState.sort = sortQueryFormat(updatedDataState.sort);
    setDataState(updatedDataState);

    var results = await ResourceService.getResourcesDetailsBatch(updatedDataState, "relativityclient");

    if (results.ok) {
      setIsGridLoading(false);
      updateMergedState(results.data.results, usersMerged.selections);
      setHasError(false);
    } else {
      console.log("Could not load grid.");
      setIsGridLoading(false);
      setHasError(true);
    }
  }

  const filterQueryFormat = (filter?: CompositeFilterDescriptor) => {
    if (!filter || !filter.filters) {
      return null;
    }

    const originalFilters = _.cloneDeep(filter.filters) as Array<FilterDescriptor>;
    const returnValue = [] as Array<CompositeFilterDescriptor>;

    for (let i = originalFilters.length - 1; i >= 0; i--) { // Just adding the "Rel" equivalent field and 

      const isDateField = (originalFilters[i].field === "LastLoginDate" || originalFilters[i].field === "RelUserLastLoginDate");

      if (isDateField && originalFilters[i].value != null) {
        originalFilters[i].operator = "eq";
      }

      if (originalFilters[i].field === "FirstName") {
        const firstNameFilters = originalFilters.splice(i, 1);
        firstNameFilters.push({ ...firstNameFilters[0], field: "RelFirstName" });

        returnValue.push({ logic: "or", filters: firstNameFilters });
      }
      else if (originalFilters[i].field === "LastName") {
        const firstNameFilters = originalFilters.splice(i, 1);
        firstNameFilters.push({ ...firstNameFilters[0], field: "RelLastName" });

        returnValue.push({ logic: "or", filters: firstNameFilters });
      }
      else if (originalFilters[i].field === "Email") {
        const firstNameFilters = originalFilters.splice(i, 1);
        firstNameFilters.push({ ...firstNameFilters[0], field: "RelEmail" });

        returnValue.push({ logic: "or", filters: firstNameFilters });
      }
      else if (originalFilters[i].field === "Username") {
        const firstNameFilters = originalFilters.splice(i, 1);
        firstNameFilters.push({ ...firstNameFilters[0], field: "RelEmail" });

        returnValue.push({ logic: "or", filters: firstNameFilters });
      }
      else if (isDateField && originalFilters[i].operator === "eq") {
        let startDate = new Date(originalFilters[i].value);
        let endDate = new Date(originalFilters[i].value);

        startDate.setUTCHours(0, 0, 0, 0);
        endDate.setUTCHours(23, 59, 59, 999);
        originalFilters[i].value = startDate.toISOString();
        originalFilters[i].operator = "gte";
        originalFilters.push({ field: originalFilters[i].field, value: endDate.toISOString(), operator: "lte" });
      }
      else {
        originalFilters[i].field = originalFilters[i].field.toString().replace("fields.", "");
      }
    }

    if (originalFilters.length > 0) {
      returnValue.push({ logic: "and", filters: originalFilters });
    }

    return returnValue;
  }

  const sortQueryFormat = (sort?: Array<AdvancedSortDescriptor>) => {
    if (!sort || sort.length === 0) {
      return null;
    }

    const updatedSorts = _.cloneDeep(sort) as Array<AdvancedSortDescriptor>;

    for (let i = 0; i < updatedSorts.length; i++) {
      if (updatedSorts[i].field === "FirstName") {
        updatedSorts[i].addedCoalesceFields = ["RelFirstName"];
      }
      if (updatedSorts[i].field === "LastName") {
        updatedSorts[i].addedCoalesceFields = ["RelLastName"];
      }
      if (updatedSorts[i].field === "Username") {
        updatedSorts[i].addedCoalesceFields = ["RelEmail"];
      }
      if (updatedSorts[i].field === "Email") { // should I coalesce both EA username and EA email to Rel email? That could be desirable
        updatedSorts[i].addedCoalesceFields = ["RelEmail"];
      }
    }

    return updatedSorts;
  }

  const createUserDialogEvent = (event: React.MouseEvent<HTMLElement>) => {
    if (event.currentTarget.textContent === "Create") {
      props.history && props.history.push({ pathname: "/administration/userDetails/0", state: { newRelUser: needCreateUser, useContext: true } });
    }

    setNeedCreateUser(null);
  }

  const dialogCloseEvent = (event: DialogCloseEvent) => {
    setNeedCreateUser(null);
  }

  const handleColumnResize = (event: GridColumnResizeEvent) => {
    if (event.end) {//Indicates that resizing is complete and the user has dropped the resize handler.
      const field = event.columns[event.index].field;
      const newWidth = event.columns[event.index].width;
      const savedGridColumns = props.sessionData.userPrefs.RelUserListGridPreferences as IGridPreference;
      const masterFields = relUserListMasterFields[tableKey] as Array<GridPreferences>;
      const gridPref = ((savedGridColumns && savedGridColumns[tableKey]) ? savedGridColumns[tableKey] : masterFields.filter(x => (gridUserPreferences[x.field] && gridUserPreferences[x.field].isDefault === true)) as Array<GridPreferences>);
      const params = {
        tableKey: tableKey,
        preferenceName: preferenceType,
        existingFields: gridPref,
        resizeColumnfield: field,
        fieldNewWidth: newWidth
      } as IGridPreferenceModel

      saveGridColumnWidthPreferenceInDB(params);
    }
  }

  const addUsersHandleChange = (event: DropDownButtonItemClickEvent) => {
    switch (event.item.id) {
      case 1:
        props.history && props.history.push({ pathname: "/administration/userDetails/0", state: { useContext: true } });
        break;
      case 2:
        setIsSyncRelUserSelectorOpen(true);
        break;
      case 3:
        setImportUsersWizardOpen(true);
        break;
    }
  }

  const gridToolBarRender = (
    <div className="toolbar-button-container">
      <div className="toolbar-button-offset">
        <KendoButton className="refresh-grid btn-secondary" onClick={() => setStrongArmRefreshCount(strongArmRefreshCount + 1)}><i className="fas fa-arrows-rotate"></i></KendoButton>
        {(canCreateUser) && (
          <>
            <DropDownButton
              items={addUserMenuList}
              text={"+ Add Users"}
              textField="title"
              onItemClick={addUsersHandleChange}
              disabled={props.sessionData.impersonateMode}
              className="toolbar-add-users-dropdown"
              icon={"k-icon k-i-chevron-up k-icon-right"}
            />
            <KendoButton className="user-setting" onClick={handleShowSettingDialog}>
              <SettingsIcon />
            </KendoButton>
          </>
        )}
      </div>
    </div>);

  const onBulkImportJobComplete = (success: boolean) => {
    if (success) {
      AuthenticationService.SetDirty().then(r => {
        if (!r.ok) {
          NotificationService.showWarningToast("Error updating your credentials, you may need to relogin in order to view the users created");
        }
      });
      setStrongArmRefreshCount(strongArmRefreshCount + 1);
    }
  }

  const handlePotentiallySelected = (selected: Array<IResourceFieldsSelectable>) => {
    setPotentiallySelectedRelUsers(selected);
    setEnableAddSyncRelUserBtn(selected && selected.length > 0);
  };

  const fetchJobStatus = async (jobId: string) => {

    if (jobId) {
      const { ok, data } = await JobsService.getJobStatus<IGetJobStatusResponseData>(jobId);

      if (ok) {
        const jobData = data.data ? JSON.parse(data.data) : null;
        const importData = jobData && jobData.ImportJobData && jobData.ImportJobData.Data;

        switch (data.statusId) {
          case JobStates.Import_ReadyForProcessing:
          case JobStates.Import_Processing:
          case JobStates.Import_DirectUsersReady:
            await ServiceBase.setTimeoutPromise(1000);
            await fetchJobStatus(jobId);
            break;
          case JobStates.Import_ProcessedError:
            if (importData && importData.Error.Message && importData.Error.Message.length > 0) {
              NotificationService.showErrorToast(`${ErrorMessages.ImportProcessFailed} ${importData.Error.Message}`, true);
            }

            AuthenticationService.SetDirty().then(r => {
              if (!r.ok) {
                NotificationService.showWarningToast("Error updating your credentials, you may need to relogin in order to view this user");
              }
            });

            break;
          case JobStates.Import_ProcessedSuccess:
            const usersCount = importData.Success.NumCreate + importData.Success.NumUpdate;
            NotificationService.showSuccessToast(`Success! Relativity users added to Epiq Access: ${usersCount}`, true);

            AuthenticationService.SetDirty().then(r => {
              if (!r.ok) {
                NotificationService.showWarningToast("Error updating your credentials, you may need to relogin in order to view this user");
              }
            });

            break;
        }
      } else {
        NotificationService.showErrorToast(`${ErrorMessages.FetchJobStatusFailed}`);
      }
    }
  }


  const handleSyncRelUserPopupClose = async (accepted: boolean) => {
    setIsSyncRelUserSelectorOpen(false);

    if (accepted) {
      const dedupeObjects = CommonHelper.dedupeObjects<IResourceFieldsSelectable>(
        potentiallySelectedRelUsers,
        selectedSyncRelUsers || new Array<IResourceFieldsSelectable>(),
        "fields.RelEmail"
      );
      const dedupeRelUsers = dedupeObjects.map(
        item => (({ ...item, selected: false } as any) as IResourceFieldsSelectable)
      ) as Array<IResourceFieldsSelectable>;

      const importedUsers = potentiallySelectedRelUsers.filter(x => x.selected === true).map(y => {
        return {
          firstName: y.fields["RelFirstName"],
          lastName: y.fields["RelLastName"],
          email: y.fields["RelEmail"],
          userName: y.fields["RelEmail"],
          sendEmailInvite: sendInvitationEmail ? "YES" : "NO"
        }
      });

      const result = await JobService.initializeJob({ jobType: JobTypeEnum.ImportUsers });
      if (result.ok) {
        NotificationService.showInfoToast("Adding Relativity users to Epiq Access.", true);

        const jobResultInfo = {
          jobId: result.data.jobId,
          jobType: JobTypeEnum.ImportUsers,
          submitData: {
            UsersDerivedFromResourceGroupId: props.context.admin.contextResourceGroupId,
            AddToRelativityClient: props.context.relativityClient && props.context.relativityClient.relativityClientId,
            ImportUsers: importedUsers,
            SiteId: siteId
          }
        };
        const submitJobData = await JobService.submitJob(jobResultInfo);
        if (submitJobData.ok) {
          await fetchJobStatus(result.data.jobId);
        } else {
          NotificationService.showErrorToast("Failed to submit job for adding relativity users to Epiq Access");
        }

      }

      setSelectedSyncRelUsers(dedupeRelUsers);
    }

    setEnableAddSyncRelUserBtn(false);
    setSendInvitationEmail(false);
    setPotentiallySelectedRelUsers([]);
  };

  const footerContent = () => {
    return (<div className="grid-selector-footer-text">
      <Checkbox
        name="sendInvitationEmail"
        value={"selectall"}
        checked={sendInvitationEmail}
        label={"Send invitation email to users"}
        onChange={(e) => setSendInvitationEmail(e.value)}
        title="Send invitation email to users"
        className="sync-rel-send-invite-checkbox"
        disabled={!(potentiallySelectedRelUsers && potentiallySelectedRelUsers.length > 0)}
      />
    </div>)
  }

  return (
    <div className="rel-user-list-wrapper">
      {props.context.admin && props.context.admin.contextResourceGroupId && (
        <>
          <SupportRequestPopup
            open={importUsersWizardOpen}
            onClose={(isCancel?: boolean) => {
              setImportUsersWizardOpen(false);
            }}
            jobTypeNumber={JobTypeEnum.ImportUsers}
            createRequestObject={createImportContextUsersRequest}
            wizardSections={wizardSupportSteps}
            wizardSummary={wizardSummary}
            warningMessage={warningMessage}
            confirmSubmitMessage={wizardConfirmSubmitMessage}
            jobMessageConfig={createJobMessagingConfig(onBulkImportJobComplete)}
            history={props.history}
            externallyManagedFields={{ "resourceGroupId": { value: props.context.admin.contextResourceGroupId, active: true }, "addToRelativityClient": { value: props.context.relativityClient && props.context.relativityClient.relativityClientId, active: true } }}
          />
          <ColumnFieldListBox
            showSettingFieldsDialog={showSettingsDialog}
            availableFields={availableFields}
            selectedFields={selectedFields}
            onSettingsDialogClose={handleCloseSettingDialog}
            tableKey={tableKey}
            preferenceName={preferenceType}
          />
        </>
      )}
      <div className="rel-user-list-instructions">
        <label>Users</label>
        <p>Click a row to view more details.</p>
        {!props.addClickHandler && gridToolBarRender}
      </div>
      <TabStrip selected={0} className="context-user-list-tabs angle-tabs" keepTabsMounted={true}>
        <TabStripTab title={"Relativity Users"}>
          <DialogBox
            title={"Create Epiq Access Account"}
            content={`New Relativity user ${userName} does not have an Epiq Access account. ${canCreateUser ? "Do you want to create an Epiq Access account for this user?" : ""}`}
            okButtonLabelText={canCreateUser ? "Cancel" : null}
            cancelButtonLabelText={canCreateUser ? "Create" : "Ok"}
            visible={!!needCreateUser}
            toggleDialogEvent={createUserDialogEvent}
            openDiaglogButtonText={null}
            diagCloseEvent={dialogCloseEvent}
            dailogInfo={"new-relativity-user-confirm"}
          />
          <div className={`${props.className}`}>
            <AdvancedGrid
              ref={(standardGridInstance: AdvancedGrid<IResourceFieldsSelectable, IGridParamsAdvancedRefreshable>) => {
                gridRef.current = standardGridInstance;
              }}
              showErrorState={hasError}
              showLoadingIndicator={isGridLoading}
              data={usersMerged.merged}
              dataFetch={fetchDataAsync}
              dataState={dataState}
              columns={columns}
              paging={false}
              noRecordsRender={<p>No Users Found.</p>}
              noLoadOnMount={false}
              filteredDataOnly={false}
              multiFieldFilterDelimiter="|"
              hidePaper={false}
              strongArmRefreshCount={strongArmRefreshCount}
              columnVirtualization={false}
              onColumnResize={handleColumnResize}
              filterOperators={{
                text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
                date: [{ text: "grid.filterIsNotNullOperator", operator: "isnotnull" }, { text: "grid.filterIsNullOperator", operator: "isnull" }]
              }}
            />
          </div>

          {props.context.admin && props.context.admin.contextResourceGroupId && (
            <div className="context-user-sync-reluser-list-wrapper">
              <GridSelector
                addClass="context-user-sync-reluser-list-modal"
                isOpen={isSyncRelUserSelectorOpen}
                acceptBtnText="Activate"
                cancelBtnText="Cancel"
                prefixTitleText="Sync Users to Epiq Access"
                titleText="Create Epiq Access accounts for selected Relativity users."
                addAvailable={enableAddSyncRelUserBtn}
                onClose={handleSyncRelUserPopupClose}
                fullWidth={false}
                maxWidth="md"
                footerChildren={footerContent()}
              >
                <SyncRelativityUsers
                  enableSelectRelUser={true}
                  selectionChange={handlePotentiallySelected}
                  contextResourceGroupId={props.context.admin.contextResourceGroupId}
                />
              </GridSelector>
            </div>
          )}

        </TabStripTab>
      </TabStrip>
    </div>
  );
}

export default connect(
  (state: IApplicationState) => ({
    ...state.sessionState
  }),
  null
)(ContextUserList);