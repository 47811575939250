import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";

export interface IHeaderState {
  buttons?: any;
  pageTitle?: string;
  breadCrumbUrl?: string;
  breadCrumbTitle?: string;
}

interface ISetHeaderButtonsAction {
  type: "SET_HEADER_BUTTONS";
  buttons?: any;
  pageTitle?: string;
  breadCrumbUrl?: string;
  breadCrumbTitle?: string;
}

type KnownAction = ISetHeaderButtonsAction;

export const actionCreators = {
  setHeaderButtons: (buttons: any, pageTitle: string = "", breadCrumbUrl: string = "", breadCrumbTitle: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({
      type: "SET_HEADER_BUTTONS",
      buttons,
      pageTitle,
      breadCrumbUrl,
      breadCrumbTitle
    });
  }
};

const unloadedState: IHeaderState = {
  buttons: [],
  pageTitle: "",
  breadCrumbUrl: "",
  breadCrumbTitle: ""
};

export const reducer: Reducer<IHeaderState> = (state: IHeaderState | undefined, incomingAction: Action): IHeaderState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "SET_HEADER_BUTTONS":
      return {
        buttons: action.buttons,
        pageTitle: action.pageTitle,
        breadCrumbUrl: action.breadCrumbUrl,
        breadCrumbTitle: action.breadCrumbTitle
      };
  }
  return state;
};
