import * as React from "react";
import { Grid, CircularProgress,Button } from "@material-ui/core";
import { Employer, EmployerDomain } from "../../../models/EmployerModel";
import { TextInput } from "../../common/TextInput";
import { Checkbox } from "@progress/kendo-react-inputs";



interface Props {
  savedDomains?: EmployerDomain[];
  editedDomain?: EmployerDomain;
  closeDomainsPopup: (accepted: boolean) => void;
  isDomainsPopUpOpened: boolean;
  employerDetails: Employer;
  isDomainAlreadyExists: boolean;
  onSaveClick: (selectedDomain: EmployerDomain) => void;
  isValidDomain: boolean;
  onSelectionChange: (selectedDomain: EmployerDomain) => void;
  isCreate: boolean;
}

type State = {
  isLoading: boolean;
  savedDomains: EmployerDomain[];
  isEmployerDomainExists: boolean;
  addAvailable: boolean;
  currentDomain: EmployerDomain;
  isFormValid: boolean;
};

enum PlaceHolders {
  employerDomain = "Employer Domain"
}

enum ErrorMessages {
  EmployerDomainExists = "Domain already exists.",
  Required = "This field is required.",
  ValidDomain = "Enter valid domain name."
}

class AddDomainsToEmployer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      savedDomains: this.props.savedDomains ? this.props.savedDomains : [],
      isEmployerDomainExists: this.props.isDomainAlreadyExists ? this.props.isDomainAlreadyExists : false,
      addAvailable: false,
      currentDomain: !this.props.isCreate && this.props.editedDomain ? this.props.editedDomain :
        {
          id: 0,
          employerId: this.props.employerDetails && this.props.employerDetails.id ? this.props.employerDetails.id : 0,
          domain: "",
          federated: false,
          treatAsFederated: false,
          createdDate: null
        } as EmployerDomain,
      isFormValid: false
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
    if (prevProps !== this.props) {
      const addAvailable = this.toggleAddButton(this.state.currentDomain);
      this.setState({...this.state, addAvailable: addAvailable});
    }
  }

  async employerDomainChange(value: string, name: string, isValid: boolean) {

    let employerDomain = { ...this.state.currentDomain, domain: value };

    if (this.props.editedDomain) {
      employerDomain.id = this.props.editedDomain.id;
      employerDomain.federated = this.props.editedDomain.federated;
      employerDomain.createdDate = this.props.editedDomain.createdDate;
    }

    const addAvailable = this.toggleAddButton(employerDomain);

    this.setState({ ...this.state, currentDomain: employerDomain, isFormValid: isValid, addAvailable: addAvailable }, () => {
      if (this.props.onSelectionChange) {
        this.props.onSelectionChange(employerDomain);
      }
    });
  }

  onTreatAsFederatedChange = (isChecked: boolean) => {
    let employerDomain = { ...this.state.currentDomain, domain: this.state.currentDomain ? this.state.currentDomain.domain:"", treatAsFederated: isChecked };
    const addAvailable = this.toggleAddButton(employerDomain);

    this.setState({ ...this.state, currentDomain: employerDomain, addAvailable: addAvailable}, () => {
      if (this.props.onSelectionChange) {
        this.props.onSelectionChange(employerDomain);
      }
    });
  };

  onSaveClick = () => {
    if (this.props.onSaveClick) {
      this.props.onSaveClick(this.state.currentDomain)
    }
  }

  onCancelClick = () => {
    if (this.props.closeDomainsPopup) {
      this.props.closeDomainsPopup(true);
    }
  }

  isDomainsDirty = (currentEmpDomain: EmployerDomain) => {
    const empDomainCurrent = { ...currentEmpDomain };
    const empDomainPrevious = { ...this.props.editedDomain };
    
    return (empDomainCurrent.domain != empDomainPrevious.domain || empDomainCurrent.treatAsFederated != empDomainPrevious.treatAsFederated);
  }

  toggleAddButton = (currentEmpDomain: EmployerDomain) => {
    const isDomainDirty = this.isDomainsDirty(currentEmpDomain);
    const isFormValid = currentEmpDomain && currentEmpDomain.domain && currentEmpDomain.domain.length > 0 && !this.props.isDomainAlreadyExists && this.props.isValidDomain;

    return (isDomainDirty && isFormValid);
  }

   render() {
    const { employerDetails } = this.props;
    const { isLoading, addAvailable, currentDomain } = this.state;
    const employerName = employerDetails && employerDetails.name.length > 0 ? employerDetails.name : "<EmployerName>";

    if (employerDetails === null) {
      return null;
     }

     const createEmployerDomain = (
       <fieldset>
         <div className="field-hint">* Required Fields</div>
         <div>
           <Grid container>
             <Grid item xs={12}>
               <div className="text-input-has-error">
                 <TextInput
                   type="text"
                   name="employerDomainName"
                   label="* Employer Domain"
                   placeholder={PlaceHolders.employerDomain}
                   defaultValue={currentDomain.domain}
                   maxLength={50}
                   legacyMode={true}
                   validations={[
                     { name: "required", errorMessage: ErrorMessages.Required },
                     {
                       name: "CustomValidation",
                       errorMessage: `${ErrorMessages.EmployerDomainExists}`,
                       predicate: `${this.props.isDomainAlreadyExists}`
                     },
                     {
                       name: "CustomValidation",
                       errorMessage: `${ErrorMessages.ValidDomain}`,
                       predicate: `${!this.props.isValidDomain}`
                     }
                   ]}
                   onChange={this.employerDomainChange.bind(this)}
                   displayCustomValidationMessage={this.props.isDomainAlreadyExists || !this.props.isValidDomain}
                 />
               </div>
               <div>
                 <Checkbox
                   defaultChecked={currentDomain.treatAsFederated}
                   name="treatAsFederated"
                   label="Treat As Federated"
                   onChange={(event) => this.onTreatAsFederatedChange(event.value)}
                 />
               </div>

             </Grid>
           </Grid>
         </div>
       </fieldset>
     );

     const footerButtons = (
       <>
         <div className="domains-buttons">
           <Button variant="contained" className="cancel-domains-btn btn-contined" onClick={this.onCancelClick}>
             Cancel
           </Button>
           <Button variant="contained" className="add-domains-btn btn-primary" color="primary" onClick={this.onSaveClick} disabled={!addAvailable}>
             {!this.props.isCreate ? "Save" : "Add"}
           </Button>
         </div>
       </>
     );

    return (
      <div>
          <Grid container >
            <Grid item xs={12}>
            {this.props.isCreate && <h6>
              Add domains to <span>{employerName}</span>.
            </h6>}
            {!this.props.isCreate && <h6>
              Update domain to <span>{employerName}</span>.
            </h6>}
            </Grid>
            <Grid item xs={12}>
              {isLoading ? (

                <div className="drop-down-loader">
                  {" "}
                  <CircularProgress />
                </div>
            ) : (
                <>
                  {createEmployerDomain}
                  {footerButtons}
                </>
              )}
            </Grid>
          </Grid>
      </div>
    );
  }
}

export default AddDomainsToEmployer;
