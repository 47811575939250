import { StorageApiBase, StorageType } from "./StorageApiBase";

class AuthenticationStorageApi extends StorageApiBase {
  private readonly tokenKey = "access_token";
  private readonly userIdKey = "user_id";
  private readonly sessionIdKey = "sessionId";
  private readonly preLoginPathKey = "pre_login_path";

  constructor(storageType: StorageType) {
    super(storageType);
  }

  clearAll(): void {
    this.clearByKey([this.tokenKey, this.userIdKey, this.sessionIdKey]);
  }

  clearAuthenticationData(): void {
    this.clearByKey([this.tokenKey, this.userIdKey]);
  }

  getToken(): string {
    return this.get(this.tokenKey);
  }

  getUserId(): string {
    return this.get(this.userIdKey);
  }

  getSessionId(): string {
    return this.get(this.sessionIdKey);
  }
  
  getAndClearPreLoginPath() : string {
    const path = this.get(this.preLoginPathKey);
    this.clearByKey([this.preLoginPathKey]);
    return path;
  }

  setPreLoginPath(preLoginPath: string) {
    this.store([[this.preLoginPathKey, preLoginPath]]);
  }

  storeAuthenticationData(userId: string, token: string): void {
    this.store([[this.tokenKey, token], [this.userIdKey, userId]]);
  }

  storeSessionId(sessionId: string): void {
    this.store([[this.sessionIdKey, sessionId]]);
  }

}

const authenticationStorageApi = new AuthenticationStorageApi(StorageType.Local);

export default authenticationStorageApi;
