import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { AdvancedGrid, IGridParams} from "../../common/grid/AdvancedGrid";
import { GridCellProps, GridColumnProps } from "@progress/kendo-react-grid";
import { Button, Paper } from "@material-ui/core";
import { IUserFactor } from "../../../models/UserModels";
import UserService from "../../../services/UserService";
import * as SessionStore from "../../../store/Session";
import { IApplicationState } from "../../../store";
import NotificationService from "../../../services/NotificationService";

interface IProps {     
  userIdpId?: string;
  userFactor?: IUserFactor[]; 
}

type Props = IProps & SessionStore.ISessionState;

const startingGridParams: IGridParams = {
};

const UserFactorList = (props: Props) => {
  const [hasError, setHasError] = useState(false);
  const [isGridLoading, setIsGridLoading] = useState(true);
  const [dataState, setDataState] = useState(startingGridParams);
  const [userFactor, setUserFactor] = useState([] as Array<IUserFactor>);

  const dateCell  = (props: GridCellProps) => {
    const d = new Date(props.dataItem[props.field])
    return <td>{d.toLocaleString()}</td>
  }

  const resetCell = (props: GridCellProps) => {
    if (props.dataItem["id"] && props.dataItem["id"].length > 0) {
      return (<td>
        <Button variant="contained"
          color="primary"
          className="btn-primary"
          onClick={() => handleClick(props.dataItem["id"])}>Reset
        </Button>
      </td>)
    }

    return (<td> </td>);
  }

  const handleClick = async (factorId: string) => {

    const response = await UserService.resetUserFactors(props.userIdpId, factorId);

    if (response.ok) {
      NotificationService.showSuccessToast("Factor is successfully reset.");
      fetchDataAsync(dataState);
    }
    else {
      NotificationService.showErrorToast("Failed to reset factor.");
    }
  };

  useEffect(() => {
    fetchDataAsync(dataState);
  }, []);


  const columns = new Array<GridColumnProps>(
    { field: "id", title: "ID", sortable: false, headerClassName: "no-sort", width: "170px" },
    { field: "factorType", title: "FACTOR TYPE", sortable: false, headerClassName: "no-sort", width: "160px" },
    { field: "status", title: "STATUS", sortable: false, headerClassName: "no-sort", width: "190px" },
    { field: "created", title: "CREATED", cell: dateCell, sortable: false, headerClassName: "no-sort", width: "180px"},
    { field: "lastUpdated", title: "LAST UPDATED", cell: dateCell, sortable: false, headerClassName: "no-sort", width: "180px" },
    { field: "Reset", title: "RESET", cell: resetCell, sortable: false, headerClassName: "no-sort", width: "160px" }
  );

  const fetchDataAsync = async (dataState: IGridParams, caller?: string) => {
    setIsGridLoading(true);      

    var results = await UserService.getUserFactors(props.userIdpId);

    if (results.ok) {
      setIsGridLoading(false);
      setUserFactor(results.data);
      setHasError(false);
    } else {
      console.log("Could not load grid.");
      setIsGridLoading(false);
      setHasError(true);
    }

    setDataState(dataState);
  } 

  return (
    <>
      <Paper>
        <AdvancedGrid
          showErrorState={hasError}
          showLoadingIndicator={isGridLoading}
          data={userFactor}
          dataFetch={fetchDataAsync}
          dataState={dataState}
          columns={columns}
          paging={false}
          noRecordsRender={<p>No Factors Found.</p>}          
          filteredDataOnly={false}
        />
      </Paper>
    </>
  );
}

export default connect(
  (state: IApplicationState) => ({
    ...state.sessionState
  }),
  null
)(UserFactorList);