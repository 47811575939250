import React, { useState, useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Dialog, DialogCloseEvent } from "@progress/kendo-react-dialogs";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import "./ImageUploadPreview.scss";
import CdnUploadWizard from "../files/FileUploadWizard/CdnUploadWizard";
import FileService from "../../services/FileService";

export interface IImageChangeEvent {
  imgUrl?: string;
}

interface IProps {
  onChange: (event: IImageChangeEvent) => void;
  value?: string;
  name: string;
  label: string;
  labelSubText?: string;
  allowMultiple?: boolean;
  jobType?: number;
}

export default (props: IProps) => {

  const [isUploadOpen, setIsUploadOpen] = useState(false);

  const onFileUploadWizardClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | DialogCloseEvent) => {
    setIsUploadOpen(false);
  }

  const onImageUploadFinished = (url?: string) => {
    setIsUploadOpen(false);
    props.onChange({ imgUrl: url });
  }

  const onDownload = async () => {
    if (!props.value) return;

    await FileService.downloadFile(props.value, 3, props.value.split('/').pop());
  }

  return <div className="upload-img-preview">
    <label>{props.label}</label>
    {props.labelSubText && <><br /><sub>{props.labelSubText}</sub></>}

    <div className="upload-control">
      <div className="preview">
        {props.value && <img className="image" src={props.value} />}
      </div>
      <div className="options">
        <button className="btn-secondary fa-regular fa-download basic-button" onClick={onDownload}> Download</button>
        <Button className="btn-secondary" icon={"plus"} onClick={() => setIsUploadOpen(true)}> Upload</Button>
      </div>
    </div>

    {isUploadOpen && (
      <Dialog
        onClose={onFileUploadWizardClose}
        aria-labelledby="customized-dialog-title"
        className="modal files-list-file-upload-modal"
      >
        <DialogTitle className="modal-title">
          File Upload Wizard
          <IconButton
            className="modal-close"
            aria-label="close"
            disabled={false}
            onClick={onFileUploadWizardClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CdnUploadWizard onFinished={onImageUploadFinished} allowMultiple={props.allowMultiple} jobType={props.jobType} />
        </DialogContent>
      </Dialog>
    )}
  </div>
};