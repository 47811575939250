import { IGridParams } from "../components/common/grid/AdvancedGrid";
import { IRelWorkspaceRequest, IRelWorkspaceUrlRequest } from "../models/RelativityModel";
import { ServiceBase, ResponseMessages, ServiceResponse, ServiceResponseJson, ServiceResponseStr } from "./ServiceBase";

export default class RelativityService extends ServiceBase {

  static getResponseMessages(type: string): ResponseMessages {
    return {
      FailedMessage: `Succeeded the call: ${type}`,
      SuccessMessage: `Failed the call: ${type}`
    }
  }

  static getBaseEndpoint() {
    return `${window.ApplicationVariables.apiUri}relativity`;
  }

  static async getMyWorkspaces(params: IGridParams, abortSignal?: AbortSignal) {

    const filterJSON = params.filters ? JSON.stringify(params.filters) : "";
    const sortJSON = JSON.stringify(params.sort) || "";
    const endpointName = "getMyWorkspaces";

    const url = `${RelativityService.getBaseEndpoint()}/${endpointName}?${this.getFilterSortQueryString(filterJSON, sortJSON)}&${this.toQueryString(params, ["filters", "sort", "filter"])}`;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async (response) => await this.handleResponseJson(response, RelativityService.getResponseMessages(endpointName)))
      .catch(async (response) => await this.handleResponseJson(response, RelativityService.getResponseMessages(endpointName)));

    return result;
  }

  static async getInstances(params: IGridParams, abortSignal?: AbortSignal) {

    const endpointName = "RelInstances";
    const filterJSON = params.filters ? JSON.stringify(params.filters) : "";

    const url = `${RelativityService.getBaseEndpoint()}/${endpointName}?${this.getFilterSortQueryString(filterJSON, null)}`;

    const result: ServiceResponseJson = await fetch(url, RelativityService.fetchOptions(abortSignal))
      .then(async (response) => await RelativityService.handleResponseJson(response, RelativityService.getResponseMessages(endpointName)))
      .catch(async (response) => await RelativityService.handleResponseJson(response, RelativityService.getResponseMessages(endpointName)));

    return result;
  }

  static async getInstance(instanceId: number, abortSignal?: AbortSignal) {

    const endpointName = "RelInstance";

    const url = `${RelativityService.getBaseEndpoint()}/${endpointName}?instanceId=${instanceId}`;

    const result: ServiceResponseJson = await fetch(url, RelativityService.fetchOptions(abortSignal))
      .then(async (response) => await RelativityService.handleResponseJson(response, RelativityService.getResponseMessages(endpointName)))
      .catch(async (response) => await RelativityService.handleResponseJson(response, RelativityService.getResponseMessages(endpointName)));

    return result;
  }

  static async getWorkspaceGroups(request: IRelWorkspaceRequest, abortSignal?: AbortSignal) {
    const url = `${RelativityService.getBaseEndpoint()}/getGroups?appId=${request.appId}&workspaceArtifactId=${request.workspaceArtifactId}&projectId=${request.projectId}`;

    const result: ServiceResponseJson = await fetch(url, RelativityService.fetchOptions(abortSignal))
      .then(async (response) => await RelativityService.handleResponseJson(response, RelativityService.getResponseMessages("getRelGroups")))
      .catch(async (response) => await RelativityService.handleResponseJson(response, RelativityService.getResponseMessages("getRelGroups")));

    return result;
  }

  static async getWorkspaceGroupUsers(request: IRelWorkspaceRequest, abortSignal?: AbortSignal) {
    const url = `${RelativityService.getBaseEndpoint()}/getUsersForWokspace?appId=${request.appId}&workspaceArtifactId=${request.workspaceArtifactId}&projectId=${request.projectId}`;

    const result: ServiceResponseJson = await fetch(url, RelativityService.fetchOptions(abortSignal))
      .then(async (response) => await RelativityService.handleResponseJson(response, RelativityService.getResponseMessages("getRelGroupUsers")))
      .catch(async (response) => await RelativityService.handleResponseJson(response, RelativityService.getResponseMessages("getRelGroupUsers")));

    return result;
  }

  static async getWorkspaceGroupDetailsAsync(request: IRelWorkspaceUrlRequest, abortSignal?: AbortSignal) {
    const url = `${RelativityService.getBaseEndpoint()}/getWorkspaceGroupDetailsAsync?url=${ServiceBase.encodeToBase64(request.url)}&workspaceArtifactId=${request.workspaceArtifactId}&projectId=${request.projectId}`;

    const result: ServiceResponseJson = await fetch(url, RelativityService.fetchOptions(abortSignal))
      .then(async (response) => await RelativityService.handleResponseJson(response, RelativityService.getResponseMessages("getRelGroupUsers")))
      .catch(async (response) => await RelativityService.handleResponseJson(response, RelativityService.getResponseMessages("getRelGroupUsers")));

    return result;
  }
}