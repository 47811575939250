import * as React from "react";
import { Grid as KendoGrid, GridColumn, GridColumnProps, GridCellProps, GridNoRecords } from "@progress/kendo-react-grid";
import { Grid, Paper, CircularProgress } from "@material-ui/core";
import { AdvancedGrid, IGridParams } from "../../common/grid/AdvancedGrid";
import GridHeader from "../../common/grid/GridHeader";
import { ServiceResponseJson } from "../../../services/ServiceBase";
import AdminService from "../../../services/AdminService";
import { EmployerDomain, IEmployerDomainsParams } from "../../../models/EmployerModel";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";

type IProps = {
  employerId: number;
};



const EmployerDomainsPopup = (props: IProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [gridItems, setGridItems] = React.useState(null as Array<EmployerDomain>);
  const dataState = {
    skip: 0,
    take: 500
  };
  let pendingRequest: any;
  let requestStarted = false;


  const search = async (value: string) => {
    await getGridResults({ ...dataState, searchValue: value });
  }

  const getGridResults = async (dataState: IEmployerDomainsParams) => {
    if (requestStarted) {
      clearTimeout(pendingRequest);
      pendingRequest = setTimeout(() => {
        getGridResults(dataState);
      }, 300);
      return;
    }

    requestStarted = true;
    setIsLoading(true);

    const searchValue = dataState && dataState.searchValue ? dataState.searchValue : null;

    var filter = searchValue != null ? [{
      logic: "or",
      filters: [{
        field: "domain",
        operator: "contains",
        value: searchValue
      }]
    } as CompositeFilterDescriptor] as CompositeFilterDescriptor[] : [];

    const result = await AdminService.getEmployerDomainListById(props.employerId,
      { ...dataState, filters: filter }
    );
    setHasError(!result.ok);
    setGridItems(result.data.results);

    setIsLoading(false);
    requestStarted = false;
  }


  const domain = (props: GridCellProps) => {
    return (
      <td>
        <span title={props.dataItem.domain}>{props.dataItem.domain}</span>
      </td>
    );
  }

  const columns = [{
    field: "domain", title: "domain", filterable: false, cell: domain,
    sortable: false,
    headerClassName: "no-sort"
  }];



  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <GridHeader
            searchButton={false}
            searchDisabled={false}
            searchPlaceholder={"Search"}
            onChangeSearch={search}
            minSearchCharacters={3}
            headerTypeName={"Domains"}
            displayCount={gridItems ? gridItems.length : 0}
          />
          <div>
            <AdvancedGrid
              showErrorState={hasError}
              showLoadingIndicator={isLoading}
              data={gridItems}
              dataFetch={getGridResults}
              dataState={dataState}
              columns={columns}
              paging={false}
              noRecordsRender={<p>No information to display.</p>}
              noLoadOnMount={false}
              filteredDataOnly={false}
              multiFieldFilterDelimiter="|"
              total={gridItems ? gridItems.length : 0}
              moreRecordsAvailableMessage="Displaying first 500 items. Click View Details to refine results."
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
export default EmployerDomainsPopup;