class CookieStorageApi {
  private readonly ssoCompletedExpKey = "ssoCompletedExp";
  
  constructor() {
  }

  getCookieValue(name: string) : string {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
    return null;
  }

  setCookie(name: string, value: string) : void {
    document.cookie = `${name}=${value}; path=/; Secure=true`;
  }

  deleteCookie(name: string) : void {
    if(this.getCookieValue(name) === null) return;

    document.cookie = `${name}=;Max-Age=-99999999;`
  }

  getSsoCompletedExp(): Date {
    try {
      var val = this.getCookieValue(this.ssoCompletedExpKey);
      if(val === null) return null;
  
      var parsed = JSON.parse(val);
      var date = new Date(parsed);
      
      if(date === null || date.toString() === 'Invalid Date') return null;

      return date;
    }
    catch {
      console.log(`Error parsing date.`);
      return null;
    }
  }

  /*
  In the event third-party cookies are blocked, we can set
  this cookie to indicate SSO was completed, so that
  when the page loads, even though it can't talk to Okta
  it can assume the user has an Okta session.
  */
  setSsoCompletedExp(): void {
    let date = new Date();
    date.setHours(date.getHours() + 2);

    this.setCookie(this.ssoCompletedExpKey, JSON.stringify(date));
  }
  
  clearSsoCompletedExp(): void {
    this.deleteCookie(this.ssoCompletedExpKey);
  }

}

const cookieStorageApi = new CookieStorageApi();

export default cookieStorageApi;