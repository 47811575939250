import React, { Component } from "react";
import NotificationService from "../../../../services/NotificationService";
import DownloadTemplate from "./DownloadTemplate";
import ReviewImport from "./ReviewImport";
import { StandardWizard, IWizardStepProps } from "../../../common/wizard/StandardWizard";
import { IFileInfo, StandardFileUpload } from "../../../common/fileupload/StandardFileUpload";
import JobManager, { JobMessagingConfig } from "../../../../JobManager";
import "../../../common/bulkImport.scss";
import { ServiceBase } from "../../../../services/ServiceBase";
import { UploadFileRestrictions, UploadOnStatusChangeEvent, UploadOnAddEvent } from "@progress/kendo-react-upload";
import JobsService, {
  IGetJobStatusResponseData,
  IInitializeJobRequestData,
  ISubmitJobRequestData
} from "../../../../services/JobService";
import CommonHelper from "../../../common/utilities/CommonHelper";
import { Checkbox, RadioGroup, RadioGroupChangeEvent } from "@progress/kendo-react-inputs";
import SingleSelectDropdown from "../../../common/SingleSelectDropDown";
import SiteBrandingService from "../../../../services/SiteBrandingService";
import { SiteBrandingModel } from "../../../../models/SiteBrandingModel";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import { JobTypeEnum } from "../../../../models/Enums";

enum ErrorMessages {
  ImportProcessSubmissionFailed = "Error occured during submitting job for import process.",
  ImportProcessFailed = "Error occurred during import processing.",
  FetchJobStatusFailed = "Error occured during fetching the job status details.",
  AllRecordsRequiresMoreInfo = "cannot import file - all records require information.",
  CannotImportEmptyFile = "Cannot import blank or empty file"
}

enum JobStates {
  ImportReadyForValidation = 1,
  ImportValidating = 2,
  ImportValidatedError = 3,
  ImportValidatedSuccess = 4,
  ImportReadyForProcessing = 5,
  ImportProcessing = 6,
  ImportProcessedError = 7,
  ImportProcessedSuccess = 8,
  ImportProcessedPartialSuccess = 9,
  ImportCancelRequested = 10,
  ImportCancelling = 11,
  ImportCancelled = 12
}

interface IUploadValidationStatus extends IGetJobStatusResponseData {
  data: string;
}

interface IImportUsersJobSubmitRequest extends ISubmitJobRequestData {
  incidentId: string;
  notifyCreator: boolean;
  siteId?: number;
  overrideSendInvite: boolean;
}

interface IImportUsersValidateJobSubmitRequest extends ISubmitJobRequestData {
  readyForValidation: boolean;
}

interface IValidationSummary {
  totalCreateCount: number;
  totalUpdateCount: number;
  totalIssueCount: number;
  validationInProgress: boolean;
}

interface IState {
  activeStep: number;
  fileName: string;
  jobId: string;
  errorMessage: string;
  validationSummary: IValidationSummary;
  showImportProgress: boolean;
  fileUploaded: boolean;
  fileUploadProcessing: boolean;
  importProcessFailed: boolean;
  uploadUrl: string;
  currentFile: any;
  incidentNumber: string;
  notifyCreator: boolean;
  emailInviteType: number;
  selectedSiteItem: SiteBrandingModel;
  sites: SiteBrandingModel[];
  overrideSendInvite: number;
}

type Props = {
  handleImportModalClose: (isRefreshGrid?: boolean) => void;
  disableCancelButton: (disableCancel: boolean) => void;
};

const initialState: IState = {
  activeStep: 0,
  fileName: "",
  jobId: "",
  errorMessage: "",
  validationSummary: {
    totalCreateCount: 0,
    totalUpdateCount: 0,
    totalIssueCount: 0,
    validationInProgress: false
  },
  showImportProgress: false,
  fileUploaded: false,
  fileUploadProcessing: false,
  importProcessFailed: false,
  uploadUrl: "",
  currentFile: null,
  incidentNumber: "",
  notifyCreator: true,
  emailInviteType: 0,
  selectedSiteItem: null,
  sites: [],
  overrideSendInvite: 0
};

class BulkUserImport extends Component<Props, IState> {
  steps: Array<IWizardStepProps>;
  uploadFileRestrictions: UploadFileRestrictions;
  uploadUrl: string;
  jobManager: JobManager;
  jobMessagingConfig: JobMessagingConfig;

  constructor(props: Props) {
    super(props);

    this.state = initialState;
    this.uploadFileRestrictions = {
      allowedExtensions: new Array<string>("xls", "xlsx"),
      maxFileSize: 10485760 //10MB as bytes
    };

    this.jobMessagingConfig = {
      specialStatusCases: {
        statusTypes: {
          [JobStates.ImportReadyForValidation]: { keepChecking: true },
          [JobStates.ImportValidating]: { keepChecking: true },
          [JobStates.ImportReadyForProcessing]: { keepChecking: true },
          [JobStates.ImportProcessing]: { keepChecking: true },
          [JobStates.ImportValidatedError]: {
            degree: "error",
            onStatus: (data) => this.onValidationResults(data, false)
          },
          [JobStates.ImportValidatedSuccess]: {
            degree: "success",
            onStatus: (data) => this.onValidationResults(data, false)
          },
          [JobStates.ImportProcessedError]: {
            degree: "error",
            onStatus: (data) => this.onComplete(data, false)
          },
          [JobStates.ImportProcessedSuccess]: {
            degree: "success",
            onStatus: (data) => this.onComplete(data, true)
          }
        }
      }
    };

    this.jobManager = new JobManager();

    this.steps = new Array<IWizardStepProps>(
      {
        stepConfig: {
          label: "DOWNLOAD",
          isValid: undefined,
          validator: () => this.state.activeStep === 0
        },
        stepContent: <DownloadTemplate />
      },
      {
        stepConfig: {
          label: "UPLOAD",
          isValid: undefined,
          validator: () => this.state.fileUploaded
        },
        stepContent: () => {
          const requestData: IInitializeJobRequestData = {
            jobType: JobTypeEnum.ImportUsers
          };

          return (
            <>
              <h2> Upload User List File</h2>
              <p>Select the user list file to import. Make sure that file was created using the provided template.</p>
              <StandardFileUpload
                restrictions={this.uploadFileRestrictions}
                onAdd={this.onAdd.bind(this)}
                onStatusChange={this.onStatusChange.bind(this)}
                requestData={requestData}
                onBeforeUpload={this.onBeforeUpload.bind(this)}
                onDataStateChanged={this.handleOnDataStateChanged.bind(this)}
              />
              <div className="max-allow-rec-count">Maximum record count is 1000.</div>
              <Checkbox
                value={this.state.notifyCreator}
                label="Send email notification for success and failure?"
                onChange={(event) => this.setState({ notifyCreator: event.value })} />
            </>
          );
        }
      },
      {
        stepConfig: {
          label: "INVITE",
          isValid: undefined,
          validator: () => (this.state.emailInviteType === 0 || (this.state.emailInviteType === 1 && this.state.selectedSiteItem && this.state.selectedSiteItem.siteId > 0))
        },
        stepContent: () => {
          const invitationTypeData = [{ label: "Standard invitation for Epiq Access", value: 0 }, { label: "Custom invitation for client-branded login page", value: 1 }];
          const inviteUsersTypeData = [{ label: "Only invite users who need to activate accounts.", value: 0 }, { label: "Invite all users, including those with active accounts.", value: 1 }];

          const handleInviteTypeChange = async (e: RadioGroupChangeEvent) => {
            this.setState({ emailInviteType: e.value, overrideSendInvite: 0, selectedSiteItem: null })
          };

          const handleInviteUserTypeChange = async (e: RadioGroupChangeEvent) => {
            this.setState({ overrideSendInvite: e.value })
          };

          const getFilter = (searchText: string) => {
            const filters = [
              {
                logic: "or",
                filters: [
                  { field: "name", operator: "contains", value: searchText },
                ] as Array<FilterDescriptor>
              }
            ] as CompositeFilterDescriptor[];

            return filters;
          }

          return (
            <>
              <h2>Invite Users</h2>
              <span>Choose the type of invitation to send and who you want to receive the invitations.</span>
              <div className="user-import-email-invite">
                <span>What type of welcome invitation do you want to send?</span>
                <RadioGroup
                  data={invitationTypeData}
                  value={this.state.emailInviteType}
                  onChange={handleInviteTypeChange}
                />
              </div>
              {this.state.emailInviteType === 1 && (
                <>
                  <div className="user-import-sites">
                    <span>Select the client name.</span>
                    <SingleSelectDropdown
                      getItems={text => SiteBrandingService.getSites({ skip: 0, take: 9999, filters: getFilter(text) })}
                      preselectedValue={this.state.selectedSiteItem
                        ? { id: this.state.selectedSiteItem.siteId as number, text: this.state.selectedSiteItem.name, data: null }
                        : null}
                      onChange={(event: any) => {
                        const selectedSite = event && event.data ? event.data : "";
                        this.setState({ ...this.state, selectedSiteItem: selectedSite, overrideSendInvite: 0 });
                      }}
                      typeToListConverter={(sb: SiteBrandingModel[]) => sb.map(item => { return { id: item.siteId, text: item.name, data: item }; })
                      }
                    />
                  </div>
                  {this.state.selectedSiteItem && this.state.selectedSiteItem.siteId > 0 && (
                    <div className="user-import-sites">
                      <span>Send invitations to these users.</span>
                      <RadioGroup
                        data={inviteUsersTypeData}
                        value={this.state.overrideSendInvite}
                        onChange={handleInviteUserTypeChange}
                      />
                    </div>)
                  }
                </>
              )}
            </>
          );
        }
      },
      {
        stepConfig: {
          label: "REVIEW & IMPORT",
          isValid: undefined,
          validator: this.canSubmitImport.bind(this),
          action: async () => {
            const validationSummary = {
              totalCreateCount: 0,
              totalUpdateCount: 0,
              totalIssueCount: 0,
              validationInProgress: true
            };

            this.setState({ validationSummary });

            const requestData: IImportUsersValidateJobSubmitRequest = {
              jobId: this.state.jobId,
              jobType: JobTypeEnum.ImportUsers,
              readyForValidation: true
            };

            await JobsService.submitJob(requestData);
            await this.fetchJobStatus();
          }
        },
        stepContent: () => {
          return (
            <ReviewImport
              validationSummary={this.state.validationSummary}
              jobId={this.state.jobId}
              selectedFileName={this.state.fileName}
              errorMessage={this.state.errorMessage}
              importProcessFailed={this.state.importProcessFailed}
              incidentNumber={this.state.incidentNumber}
              updateIncidentNumber={this.updateIncidentNumber}
              showImportProgress={this.state.showImportProgress}
            />
          );
        }
      }
    );
  }

  onComplete(data: string, success: boolean) {
    if (!data && success) {
      NotificationService.showSuccessToast(
        `Bulk import job completed successfully, but we did not get any info on it`
      );
      return;
    }
    else if (!data) {
      NotificationService.showErrorToast(`There was an unknown error with the bulk import job`);
      return;
    }

    const jobData = JSON.parse(data);
    const importData = jobData && jobData.ImportJobData && jobData.ImportJobData.Data;

    if (!success) {
      const errorMessage = success ? "" : (importData.Error.Message as string) || "Unknown error with bulk import job";

      this.setState({ showImportProgress: false, importProcessFailed: true });
      NotificationService.showErrorToast(`${ErrorMessages.ImportProcessFailed} ${errorMessage}`);
    } else {

      const deprovisionedUserList = importData.Success.DeprovisionedUserEmails.length > 0 ? `The following user(s) could not be imported because their okta status is deprovisioned:: ${importData.Success.DeprovisionedUserEmails.join(', ')}` : "";

      if (importData.Success.NumCreate === 0 && importData.Success.NumUpdate === 0 && importData.Success.DeprovisionedUserEmails.length > 0) {
        NotificationService.showWarningToast(
          `${importData.Success.NumCreate} new users added to the Users list. ${importData.Success.NumUpdate
          } users have been updated. <br/>
        ${deprovisionedUserList}`
        );
      }
      else {
        NotificationService.showSuccessToast(
          `${importData.Success.NumCreate} new users added to the Users list. ${importData.Success.NumUpdate
          } users have been updated. <br/>
        ${deprovisionedUserList}`
        );
      }
    }
  }

  onValidationResults(data: string, success: boolean) {
    let importData = null as any;
    let jobData = null as any;

    if (data) {
      jobData = JSON.parse(data);
      importData = jobData && jobData.ImportJobData && jobData.ImportJobData.Data;
    }

    const errorMessage = success ? "" : (importData && importData.Error && importData.Error.Message) as string;
    const { validationSummary } = this.state;

    validationSummary.totalCreateCount = (importData && importData.Success && importData.Success.NumCreate) || 0;
    validationSummary.totalUpdateCount = (importData && importData.Success && importData.Success.NumUpdate) || 0;
    validationSummary.totalIssueCount = (importData && importData.Success && importData.Success.NumIssue) || 0;
    validationSummary.validationInProgress = false;
    this.setState({ errorMessage: errorMessage, validationSummary: validationSummary });
  }

  canSubmitImport() {
    const { activeStep, errorMessage, validationSummary, showImportProgress, incidentNumber } = this.state;

    return (
      activeStep === 3 &&
      validationSummary.totalCreateCount + validationSummary.totalUpdateCount > 0 &&
      !showImportProgress &&
      errorMessage.length === 0 &&
      CommonHelper.validateIncidentNumberFormat(incidentNumber) &&
      !validationSummary.validationInProgress
    );
  }

  onAdd(event: UploadOnAddEvent) {
    this.setState({ ...this.state, fileName: event.newState[0].name, jobId: "", fileUploaded: false });
  }

  async onStatusChange(event: UploadOnStatusChangeEvent) {
    const { status } = event.newState[0];

    this.setState({
      ...this.state,
      fileUploadProcessing: status === 3,
      fileUploaded: status === 4,
      errorMessage:
        status === 0
          ? `Unable to upload file ${this.state.fileName}. Please try again. If this issue continues, contact support.`
          : ""
    });
  }

  async onBeforeUpload() {
    this.setState({ ...this.state, fileUploadProcessing: true });
  }

  handleOnDataStateChanged(files: Array<IFileInfo>) {
    this.setState({ jobId: files[0].jobId });
  }

  handleOnStepChanged(activeStep: number) {
    //check if we are going backwards, if so we need to reset file stuff.  If a fourth step is ever added
    //then this code may need to change to check specifically if going backwards to step 2.
    if (this.state.activeStep > activeStep && activeStep !== 2) {
      this.setState({ ...this.state, activeStep, fileName: "", jobId: "", fileUploaded: false });
    } else {
      this.setState({ ...this.state, activeStep });
    }
  }

  updateIncidentNumber = (value: string) => {
    this.setState({ incidentNumber: value });
  };

  async handleSubmit() {
    this.setState({ showImportProgress: true });

    const requestData: IImportUsersJobSubmitRequest = {
      jobId: this.state.jobId,
      jobType: JobTypeEnum.ImportUsers,
      incidentId: this.state.incidentNumber,
      notifyCreator: this.state.notifyCreator,
      siteId: this.state.selectedSiteItem ? this.state.selectedSiteItem.siteId : 0,
      overrideSendInvite: this.state.overrideSendInvite === 1 ? true : false
    };

    const processingResponse = await JobsService.submitJob(requestData);

    if (processingResponse.ok) {
      this.jobManager.addJobId(this.state.jobId, this.jobMessagingConfig, [1000]);
      this.props.handleImportModalClose(true);
    } else {
      this.setState({
        errorMessage: ErrorMessages.ImportProcessSubmissionFailed,
        showImportProgress: false,
        importProcessFailed: true
      });
    }
  }

  private async fetchJobStatus() {
    const { jobId } = this.state;

    if (jobId) {
      const { ok, data } = await JobsService.getJobStatus<IUploadValidationStatus>(jobId);

      if (ok) {
        const jobData = data.data ? JSON.parse(data.data) : null;
        const importData = jobData && jobData.ImportJobData && jobData.ImportJobData.Data;
        let validationSummary = {
          totalCreateCount: 0,
          totalUpdateCount: 0,
          totalIssueCount: 0,
          validationInProgress: false
        };

        switch (data.statusId) {
          case JobStates.ImportReadyForValidation:
          case JobStates.ImportValidating:
          case JobStates.ImportReadyForProcessing:
          case JobStates.ImportProcessing:
            await ServiceBase.setTimeoutPromise(1000);
            await this.fetchJobStatus();

            break;
          case JobStates.ImportValidatedError:
            const errorMessage = importData.Error.Message as string;

            if (importData && errorMessage && errorMessage.length > 0) {
              validationSummary.totalCreateCount = (importData.Success && importData.Success.NumCreate) || 0;
              validationSummary.totalUpdateCount = (importData.Success && importData.Success.NumUpdate) || 0;
              validationSummary.totalIssueCount = (importData.Success && importData.Success.NumIssue) || 0;
              validationSummary.validationInProgress = false;
              this.setState({ errorMessage: errorMessage, validationSummary: validationSummary });
            }

            break;
          case JobStates.ImportValidatedSuccess:
            validationSummary.totalCreateCount = importData.Success.NumCreate;
            validationSummary.totalUpdateCount = importData.Success.NumUpdate;
            validationSummary.totalIssueCount = importData.Success.NumIssue;
            validationSummary.validationInProgress = false;
            this.setState({ errorMessage: "", validationSummary: validationSummary });

            break;
          case JobStates.ImportProcessedError:
            if (importData && importData.Error.Message && importData.Error.Message.length > 0) {
              this.setState({ showImportProgress: false, importProcessFailed: true });
              NotificationService.showErrorToast(`${ErrorMessages.ImportProcessFailed} ${importData.Error.Message}`);
            }

            break;
          case JobStates.ImportProcessedSuccess:
            NotificationService.showSuccessToast(
              `${importData.Success.NumCreate} new users added to the Users list. ${importData.Success.NumUpdate
              } new users have been updated.`
            );
            const totalRecordCount = importData.Success.NumCreate + importData.Success.NumUpdate;
            this.props.handleImportModalClose(totalRecordCount > 0);

            break;
        }
      } else {
        this.setState({
          errorMessage: ErrorMessages.FetchJobStatusFailed,
          validationSummary: { ...this.state.validationSummary, validationInProgress: false }
        });
      }
    }
  }

  disableBackButton() {
    const { validationSummary, showImportProgress } = this.state;

    return showImportProgress || validationSummary.validationInProgress;
  }

  showLoadingIndicator(step: number) {
    return step === 3 && this.state.showImportProgress;
  }

  render() {
    return (
      <div>
        <StandardWizard
          lastStepButtonLabel={`Import(${this.state.validationSummary.validationInProgress
            ? "Validating"
            : this.state.validationSummary.totalCreateCount + this.state.validationSummary.totalUpdateCount
            })`}
          previousStepButtonLabel={"Back"}
          steps={this.steps}
          onStepChanged={this.handleOnStepChanged.bind(this)}
          onSubmitClick={this.handleSubmit.bind(this)}
          disablePreviousStep={this.disableBackButton.call(this)}
          showLoadingIndicatorOnNextStep={this.showLoadingIndicator.bind(this)}
        />
      </div>
    );
  }
}

export default BulkUserImport;
