import * as React from "react";
import { Container } from "@material-ui/core";
import { EpiqFooterComponent } from "../components/edge/footer/EpiqFooterComponent";
import epiqwhiteLogo from "../images/epiq-logo-white.svg";
import epiqAccessLogo from "../images/Epiq_access_logo_horz.svg";
import { Button } from "@progress/kendo-react-buttons";
import { SessionManager } from "../SessionManager";

interface IProps {
  history: any
}

type Props = IProps;

const Error = (props: Props) => {

  const sessionManager = React.useRef(new SessionManager);

  const logOut = async () => {   
    sessionManager.current.clearSession();    
    props.history.push("/");
  }

  return (
    <React.Fragment>
      <div className="header-bar">
        <Container maxWidth="lg">
          <nav className="header-nav-main">
            <span className="header-nav-main-left header-menu">
              <img className="header-nav-logo" src={epiqAccessLogo} />
            </span>
          </nav>
        </Container>
      </div>
      <Container maxWidth="lg" className="content-wrapper">
        <div className="makeStyles-root-13">
          <div className="MuiGrid-root">
            <div className="no-apps">
              <div className="msg">
                <h3>We are unable to load Epiq Access.</h3>
                <p> Please contact your network administrator for support.</p>
                <Button className="btn" onClick={logOut}>RETRY</Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <EpiqFooterComponent footerLogo={epiqwhiteLogo} />
    </React.Fragment>
  );
};
export default Error;
