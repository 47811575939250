import React, { useState } from "react";
import { Report as ReportModel, IPbiDisplay } from "../../../models/ReportModels";
import {
  ComboBox,
  ComboBoxFilterChangeEvent,
  ComboBoxChangeEvent,
  ListItemProps
} from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import bgMessages from '../../../Localization/en.json';
loadMessages(bgMessages, 'en-US');

interface Props {
  reports: IPbiDisplay[];
  onChange: (report: IPbiDisplay, event: ComboBoxChangeEvent) => void;
  preselectedValue?: string;
  onFilterChange: (report: ReportModel[]) => void;
}

const kMaxDropDownLength = 100;

const PowerBiReportDropdown = (props: Props) => {
  const [data, setData] = useState(props.reports.slice(0, kMaxDropDownLength));
  const preSelected = props.reports.find(x => x.id == props.preselectedValue);
  const [selected, setSelected] = useState(preSelected);
  let timeout: any = null;

  const projectRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const item = (
      <div key={itemProps.id} className="dd-item-details">
        <div className="dd-item-name">
          <em title={itemProps.dataItem.name}>{itemProps.dataItem.name}</em>
        </div>
        <p>
          {" "}
          <em title={itemProps.dataItem.id}>{itemProps.dataItem.id}</em>{" "}
        </p>
      </div>
    );
    return React.cloneElement(li, li.props, item);
  };

  const onReportDropDownFilter = (event: ComboBoxFilterChangeEvent) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      {
        setData(
          filterBy(props.reports, {
            logic: "or",
            filters: [
              { field: "name", operator: "contains", value: event.filter.value, ignoreCase: true },
              { field: "id", operator: "contains", value: event.filter.value, ignoreCase: true }
            ]
          }).slice(0, kMaxDropDownLength)
        );
      }
    }, 300);
  };

  return (
    <LocalizationProvider language="en-US">
      <IntlProvider locale="en" >
        <ComboBox
          data={data}
          style={{ width: "100%" }}
          filterable={true}
          onFilterChange={e => {
            onReportDropDownFilter(e);
          }}
          dataItemKey={"id"}
          textField={"name"}
          value={selected}
          onChange={e => {
            props.onChange(e.value, e);
            setSelected(e.value);
          }}
          itemRender={(li, itemprops) => projectRender(li, itemprops)}
        />
      </IntlProvider>
    </LocalizationProvider>
  );
};
export default PowerBiReportDropdown;
