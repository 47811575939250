import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import ReportEmbed from "./ReportEmbed";
import { IApplicationState } from "../../store";
import * as ReportsStore from "../../store/Report";
import * as SessionStore from "../../store/Session";
import * as UserSession from "../../store/Session";
import { PowerBiEmbedSet } from "../../models/ReportModels";
import Paper from "@material-ui/core/Paper";
import SessionStorageApi from "../../apis/storage/SessionStorageApi";
import AppStateMessages from "../session/AppStateMessages";
import ResourceServices from "../../services/ResourceService";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./Overview.scss";
import { ApplicationStateManager } from "../../AppStatusManager";
import { IReportDetails } from "../../models/ResourceModels";
import { Button } from "@material-ui/core";
import { TabStrip, TabStripTab, TabStripSelectEventArguments } from "@progress/kendo-react-layout";

interface IProps {
  history: any;
  match?: any;
}

type Props = IProps & ReportsStore.IReportState & typeof ReportsStore.actionCreators & UserSession.ISessionState;

const Overview = (props: Props) => {
  const [fetchingEmbed, setFetchingEmbed] = useState(false);
  const [fetchingResources, setFetchingResources] = useState(false);
  const [reportResourceDetails, setReportResourceDetails] = useState(null as {[id: string] : IReportDetails });
  const [selectedReport, setSelectedReport] = useState(null as IReportDetails);
  const [hasError, setHasError] = useState(false);
  const [hasNoReports, setHasNoReports] = useState(false);

  useEffect(() => {
    const overviewEmbed = selectedReport ? props.codedEmbeds[selectedReport.description] : null;

    if (!reportResourceDetails && !fetchingResources) {
      setFetchingResources(true);
      getAccessibleReportsDetails();
    }
    else if (reportResourceDetails && !fetchingResources && !fetchingEmbed && !overviewEmbed && selectedReport) {
      setFetchingEmbed(true);
      props.GetEmbedInfo(selectedReport.description);
      return;
    }

    if (selectedReport && (fetchingEmbed || !overviewEmbed) && overviewEmbed && !overviewEmbed.isLoading && overviewEmbed.codedSet) {
      setFetchingEmbed(false);
    }

    checkReportRouteChange();
  }, [props.codedEmbeds, fetchingResources, selectedReport, fetchingEmbed, hasError, props.match.params]);

  const getAccessibleReportsDetails = async () => {
    const response = await ResourceServices.getResourcesUser({ skip: 0, take: 500, typeCode: "report" });
    const reports:IReportDetails[] = response.data.results;
    if (!response.ok || !reports) {
      setHasError(true);
      return;
    }

    if (reports.length === 0) {
      setHasNoReports(true);
      console.warn("You do not have access to any reports");
      return;
    }

    const allReports:{[id: string] : IReportDetails } = {};

    reports.forEach(r => {
      allReports[r.objectId.toString()] = r;
    });

    const initialReport = allReports[props.match.params.id || reports[0].objectId.toString()];
    if (!initialReport) {
      setHasError(true);
      console.error(`report id:${props.match.params.id} is not a report you have access to`);
      return;
    }

    setSelectedReport(initialReport);
    setReportResourceDetails(allReports);
    setFetchingResources(false);
  };

  const checkReportRouteChange = function() {
    if (!reportResourceDetails || !props.match.params.id || !selectedReport || 
        props.match.params.id == selectedReport.objectId) {
      return;
    }

    const newReport = reportResourceDetails[props.match.params.id || reportResourceDetails[Object.keys(reportResourceDetails)[0]].objectId.toString()];
    if (!newReport) {
      setHasError(true);
      console.error(`report id:${props.match.params.id} is not a report you have access to`);
      return;
    }

    setSelectedReport(newReport);
    props.GetEmbedInfo(newReport.description);
    setFetchingEmbed(true);
  };

  if (hasError) {
    return (
      <Paper>
        <div className="no-projects">
          <h1>Error getting report information</h1>
          <p> Please contact your network administrator for support.</p>
        </div>
      </Paper>
    );
  }
  if (hasNoReports) {
    return (
      <Paper>
        <div className="no-projects">
          No reports to display. <br/>Contact your administrator for more information.
        </div>
      </Paper>
    );
  }

  if (reportResourceDetails && Object.keys(reportResourceDetails).length == 0) {
    return (
      <Paper>
        <div className="no-projects">
          <h1>No Reports</h1>
          <div>You currently are not associated with any dashboards.</div>
        </div>
      </Paper>
    );
  }

  if (!props.sessionData.hasDashboardProjects) { // TODO will need to change this if we have reports without RLS, may need to descrimiate between RLS vs non RLS
    return (
      <Paper>
        <div className="no-projects">
          <h1>No Projects</h1>
          <div>You currently are not associated with any groups with dashboard project access.</div>
        </div>
      </Paper>
    );
  }

  const spinner = () => {
    return (
      <Paper>
        <div className="embed-container spinner-wrapper">
          <CircularProgress />
        </div>
      </Paper>
    )
  }

  if (!reportResourceDetails) { 
    return spinner()  
  }

  const overviewEmbed = selectedReport ? props.codedEmbeds[selectedReport.description] : null;

  return (    
    <>     
      <div className="dashboard-wrapper">  
        <AppStateMessages history={props.history} appStateManager={new ApplicationStateManager()} />       
        <Paper>
          {
            overviewEmbed && overviewEmbed.isLoading ? spinner() : 
              <ReportEmbed embedSet={overviewEmbed ? overviewEmbed.codedSet : null} pageName={props.match.params.page} 
                refresh={() => props.GetEmbedInfo(selectedReport ? selectedReport.description : null)}
                bookmarkKey={selectedReport ? selectedReport.objectId : null}
                 />
          }
        </Paper>
     
      </div>
    </>
  );
};

export default connect((state: IApplicationState) => ({ ...state.reportState, ...state.sessionState }), {
  ...ReportsStore.actionCreators,
  ...SessionStore.actionCreators
})(Overview as any);
