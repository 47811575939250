import React, { Component } from "react";
import * as ReactDOM from "react-dom";
import OktaSignIn from "@okta/okta-signin-widget";
import { AppInsightsHelper } from '../../AppInsights';
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import "./okta-overrides.scss";
import "./OktaSignInWidgetWrapper.scss";
import * as Constants from "./Constants";
import LoginHelp from "../Login/LoginHelp";
import SessionStorageApi from "../../apis/storage/SessionStorageApi";
import { IStyleOverrides } from "../../models/SiteBrandingModel";


type Props = {
  recoveryToken?: string;
  onSuccess: any; //(response: IAuthResponse) => {};
  onError: any; //(error: string) => void;
  returnControllerType?: (controllerType: string) => void;
};

interface IState {
  showHelp: boolean;
}

type authContext = {
  controller: any;
};

export interface IAuthResponse {
  status: string;
  session: {
    token: string;
    setCookieAndRedirect: (redirectUrl: string) => {};
  };
  user: any;
}

let resetButtonOriginal: Element = null;
let resetPasswordUsernameInputOriginal: Element = null;
let passwordLength:any = null;

class OktaSignInWidget extends Component<Props, IState> {
  widget: any;

  constructor(props: Props) {
    super(props);

    this.hashChange = this.hashChange.bind(this);
    this.closeHelp = this.closeHelp.bind(this);

    this.state = {
      showHelp: window.location.href.toLowerCase().indexOf("#help") > 0
    }   
  }

  componentDidMount() {
    const isWelcomePage = window.location.href.toLowerCase().indexOf("/welcome") > 0;
    const invalidTokenMessage = Constants.InvalidActivationToken;
    const siteInfo = SessionStorageApi.getBranding() as IStyleOverrides;
    const el = ReactDOM.findDOMNode(this);
    const helpLink = (!siteInfo || (siteInfo && !siteInfo.HideNeedHelpLink)) ? {
      text: 'Need Help?',
      href: '#help'
    } : {};
    
    this.widget = new OktaSignIn({
      baseUrl: window.ApplicationVariables.issuer,
      authParams: {
        pkce: false
      },
      helpLinks: {  
        custom: [
          helpLink
        ]
      },
      recoveryToken: this.props.recoveryToken,
      features: {
        rememberMe: false,
        registration: false,
        idpDiscovery: true,
      },
      idpDiscovery: {
        requestContext: window.ApplicationVariables.initiateLoginUri
      },
      i18n: {
        en: {
          "primaryauth.username.placeholder": Constants.Email,
          "password.forgot.email.or.username.placeholder": Constants.Email,
          "password.forgot.email.or.username.tooltip": Constants.Email,
          "password.forgot.emailSent.desc": Constants.RecoveryEmailedText,
          "password.confirmPassword.placeholder": Constants.RepeatPassword,
          "password.forgot.sendEmail": Constants.ResetEmailButtonText,
          "error.expired.session": invalidTokenMessage,
          "errors.E0000105": invalidTokenMessage,
          goback: Constants.GoBack,
          "primaryauth.submit": Constants.LoginButtonText,
          "password.reset": Constants.ResetPasswordText,
          "error.auth.lockedOut": Constants.LockEmailText,
          "errors.E0000004": Constants.UnableLoginText,
          "signin": Constants.Signin,
          "primaryauth.title": Constants.Signin,
          "password.forgot.emailSent.title": Constants.PasswordForgotEmailSentText
        }
      }
    });

    console.log("this.widget", this.widget);

    this.widget.on("afterRender", this.onAfterRender.bind(this, siteInfo));
    this.widget.on("afterError", this.onAfterError.bind(this));
    this.widget.renderEl({ el }, this.props.onSuccess, this.props.onError);
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.hashChange);
    this.widget.remove();
  }

  hashChange() {
    this.setState({ showHelp: true });
  }

  closeHelp() {
    window.location.hash = "";
    // have to wait for the hash to actually change apparently
    setTimeout(() => {
      this.setState({ showHelp: false });
    }, 1);    
  }

  onAfterRender(siteInfo: IStyleOverrides, context: authContext) {
    function addPasswordStrength(passwordlabel: string, passwordid: string) {

      const explainSection = document.getElementsByClassName("o-form-explain")[0];
      const passwordItemsContainer = document.getElementsByClassName("password-container");
      if (!passwordItemsContainer || passwordItemsContainer.length === 0) {
        explainSection.textContent = "";
      }

      let pElement = document.createElement("p");
      pElement.classList.add("password-container");
      let strenthElement = document.createElement("span");
      let t = document.createTextNode(passwordlabel);
      strenthElement.appendChild(t);
      let checkIcon = document.createElement("span");
      checkIcon.id = passwordid;
      checkIcon.classList.add("material-icons", "checkmark");
      checkIcon.innerHTML = "check_circle";
      explainSection.prepend(pElement);
      pElement.prepend(strenthElement);
      pElement.prepend(checkIcon);
    }

    function addPasswordPolicyRules() {
      let policyDescriptionElement = document.getElementsByClassName("o-form-explain")[0] as HTMLLabelElement;

      if (policyDescriptionElement) {
        let description = policyDescriptionElement.innerText + (policyDescriptionElement.innerText.indexOf("symbol") > -1 ? "" : ", symbol");
        let policies = description.split(",").reverse();

        if (policies.length < 4) {
          // this isn't a comma delimited list of policies, but just a plain message
          return;
        }

        let passwordRules: any = [];
        // Password requirements: at least 8 characters, a lowercase letter, an uppercase letter, a number.
        policies.forEach(policy => {
          let keywords = ["characters", "number", "uppercase", "lowercase", "symbol"];
          keywords.forEach(keyword => {
            if (policy.indexOf(keyword) > -1) {
              passwordRules.push({ key: keyword, value: policy });
            }
          });
        });

        let caseSensitiveRule: string = "letters";

        passwordRules.forEach((rule: any) => {          
          if (rule.key === "symbol") {
            addPasswordStrength("At least 1 special character (! @ # $ % _ + = - &).", "specialchar");
          }
          if (rule.key === "number") {
            addPasswordStrength("At least 1 number.", "number");
          }
          if (rule.key === "lowercase") {
            addPasswordStrength("At least 1 lowercase letter.", "lowercase");
          }
          if (rule.key === "uppercase") {
            addPasswordStrength("At least 1 uppercase letter.", "uppercase");
          }
          if (rule.key === "characters") {
            let num = rule.value.match(/\d+/)[0];
            addPasswordStrength(`At least ${num} characters.`, "alphabatescount");
            passwordLength = num;
          }
        });
      }
    }

    if (context.controller === "forgot-password") {
      this.widget.authClient.options.url = window.ApplicationVariables.apiUri.split("/api/")[0];
      this.resetEmailButtonOverride();
      this.emailInputFieldOverride();
    } else {
      this.widget.authClient.options.url = window.ApplicationVariables.issuer;
    }

    if (context.controller === "password-reset-email-sent") {
      let returnToLoginButton = document.getElementsByClassName("button button-primary button-wide link-button")[0] as HTMLInputElement;
      returnToLoginButton.innerText = Constants.ReturnToLoginText; 

      const customPwdMsgTitle = siteInfo.Data && siteInfo.Data.ForgotPasswordCustomMessageTitle;
      const customPwdMsg = siteInfo && siteInfo.Data && siteInfo.Data.ForgotPasswordCustomMessage;

      let pwdResetEmailSentCls = document.getElementsByClassName("password-reset-email-sent")[0];

      if (pwdResetEmailSentCls) {
        let pwdResetEmailSentTitle = pwdResetEmailSentCls.getElementsByClassName("okta-form-title")[0] as HTMLElement;
        if (customPwdMsgTitle && pwdResetEmailSentTitle) pwdResetEmailSentTitle.innerText = customPwdMsgTitle;

        let pwdResetEmailSentMessage = pwdResetEmailSentCls.getElementsByClassName("okta-form-subtitle")[0] as HTMLElement;
        if (customPwdMsg && pwdResetEmailSentMessage) pwdResetEmailSentMessage.innerText = customPwdMsg;
      }

      AppInsightsHelper.addTelemetryInitializer(false);
      AppInsightsHelper.trackCustomEvent("Forgot Password", null, false);
    }

    if (context.controller === "recovery-loading") {
      let footer = document.getElementsByClassName("auth-footer")[0];
      if (!footer) {
        let element = document.getElementsByClassName("auth-content")[0];
        let divFooterElement = this.CreateLoginDiv();
        element.appendChild(divFooterElement);
      }
    }

    if (context.controller === "password-reset") {
      let resetPasswordElement = document.getElementsByClassName("okta-form-title o-form-head")[0] as HTMLLabelElement;
      resetPasswordElement.innerHTML = Constants.ResetPasswordLabel;

      addPasswordPolicyRules();

      let hyperLinkElement = document.getElementsByClassName("custom")[0];
      if (hyperLinkElement) {
        hyperLinkElement.remove();
      }

      let btnElement = document.getElementsByClassName("button button-primary")[0] as HTMLButtonElement;
      btnElement.value  = Constants.ResetPasswordButtonText;

      let newPassword = document.getElementsByName("newPassword")[0] as HTMLInputElement;
      if (newPassword) newPassword.onkeyup = this.validateNewPassword;

      let confirmPassword = document.getElementsByName("confirmPassword")[0] as HTMLInputElement;
      if (confirmPassword) newPassword.onkeyup = this.validateNewPassword;
    }
    else if (context.controller === "password-expired") {
      addPasswordPolicyRules();

      let newPassword = document.getElementsByName("newPassword")[0] as HTMLInputElement;
      if (newPassword) newPassword.onkeyup = this.validateNewPassword;

      let confirmPassword = document.getElementsByName("confirmPassword")[0] as HTMLInputElement;
      if (confirmPassword) newPassword.onkeyup = this.validateNewPassword;

      if (this.props.returnControllerType) {
        this.props.returnControllerType(context.controller)
      }
    }

    window.addEventListener('hashchange', this.hashChange);
  }

  private CreateLoginDiv() {
    let divFooterElement = document.createElement("div");
    divFooterElement.classList.add("auth-footer");
    let hyperLink = document.createElement("a");
    hyperLink.classList.add("link", "help", "js-back", "custom");
    hyperLink.innerText = Constants.GoBack;
    hyperLink.href = "/login";
    divFooterElement.appendChild(hyperLink);
    return divFooterElement;
  }

  onAfterError(context: authContext, error: any) {
    console.log(error.xhr.responseJSON.errorCode);
    console.log(context);
    //Check for invalid token:E0000011
    //errors.E0000011 = Invalid token provided
    if (context.controller === "primary-auth" && error.xhr.responseJSON.errorCode === "E0000011") {
      console.log("captured invalid token error");
      const passwordContainers = document.getElementsByClassName("password-container");

      for (let i = 0; i < passwordContainers.length; i++) {
        const passwordContainer = passwordContainers[i] as HTMLElement;
        passwordContainer.classList.add("hide-element");
      }
      const formContainer = document.getElementsByClassName("o-form-fieldset-container")[0] as HTMLElement;
      formContainer.classList.add("hide-element");
      const btnElement = document.getElementsByClassName("o-form-button-bar")[0] as HTMLElement;
      btnElement.classList.add("hide-element");
      const footer = document.getElementsByClassName("auth-footer");
      if (footer.length > 0) {
        const footerElement = footer[0] as HTMLElement;
        const helpElement = document.getElementsByClassName("js-help")[0] as HTMLElement;
        footerElement.removeChild(helpElement);
        const hyperLinks = document.getElementById("help-links-container") as HTMLElement;
        footerElement.removeChild(hyperLinks);
        let divFooterElement = this.CreateLoginDiv();
        footerElement.appendChild(divFooterElement);
      }
    }

    //To updated the password reset error validation messages
    if (context.controller === "password-reset" && error.xhr.responseJSON.errorCode === "E0000080") {
      const formErrorContainer = document.querySelector(".infobox-error p") as HTMLElement;
      if (formErrorContainer.innerText == Constants.PasswordResetError) {
        formErrorContainer.innerText = Constants.UpdatePasswordResetError;
      }
      if (formErrorContainer.innerText == Constants.PasswordReqError) {
        formErrorContainer.innerText = Constants.UpdatePasswordReqError;
      }
    }
  }

  validateNewPassword() {
    let element = document.getElementsByName("newPassword")[0] as HTMLInputElement;

    if (!!element) {
      let rules = [
        {
          Pattern: "(?=.*[a-z])",
          Target: "lowercase"
        },
        {
          Pattern: "(?=.*[A-Z])",
          Target: "uppercase"
        },
        {
          Pattern: "[0-9]",
          Target: "number"
        },
        {
          Pattern: "[!@#$%^&*)(+=._-]",
          Target: "specialchar"
        },
        {
          Pattern: "(?=.{"+passwordLength+",}$)",
          Target: "alphabatescount"
        }
      ];
      let password = element.value;
      for (let i = 0; i < rules.length; i++) {
        let passwordStrengthElement = document.getElementById(rules[i].Target);
        if (passwordStrengthElement != null) {
          passwordStrengthElement.classList.remove(new RegExp(rules[i].Pattern).test(password) ? "disabled" : "success");
          passwordStrengthElement.classList.add(new RegExp(rules[i].Pattern).test(password) ? "success" : "disabled");
        }
      }
    }
  }

  handleResetEmailEvent(originalElement: Element, isKeyPressEvent: boolean = false, key: number = null) {
    //if the input is empty (invalid) or the input has a value (valid) but the user has not hit enter key don't do anything.
    if ((resetPasswordUsernameInputOriginal as HTMLInputElement).value.length > 0 && ((isKeyPressEvent && key === 13) || !isKeyPressEvent)) {
      /*
       if the input is valid, we want to prevent more than one fire of the click event or keyup event when
       the key is the "enter" key, so only one email gets sent.
       Clone the existing element node with deep option
       this will strip all event listeners.
      */
      let cloneElement = originalElement.cloneNode(true);
      //Must check for parentNode otherwise compiler throws possible null error
      if (originalElement.parentNode) {
        /*
         Replace the old element with the clone.
         when the component gets re-rendered the original element will be restored. 
         */
        originalElement.parentNode.replaceChild(cloneElement, originalElement);
      }
    }
  }

  onResetEmailButtonClick(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.handleResetEmailEvent(resetButtonOriginal);
  }

  onResetButtonKeyUp(event: any) {
    this.handleResetEmailEvent(resetPasswordUsernameInputOriginal, true, event.which || event.keyCode);
  }

  emailInputFieldOverride() {
    //Get a handle to the existing reset email user input element and save it for use.
    resetPasswordUsernameInputOriginal = document.getElementById("account-recovery-username");
    /*
  * Add our keyup event listener so we can do something.
  * this will not remove the OKTA event, it will also fire and since it was
  * the first registered event on the element it will fire before ours.
  */
    resetPasswordUsernameInputOriginal.addEventListener("keyup", this.onResetButtonKeyUp.bind(this));
  }

  resetEmailButtonOverride() {
    //Get a handle to the existing reset email button and save it for use.
    resetButtonOriginal = document.getElementsByClassName("email-button")[0];
    /*
     * Add our click event listener so we can do something.
     * this will not remove the OKTA event, it will also fire and since it was
     * the first registered event on the element it will fire before ours.
     */
    resetButtonOriginal.addEventListener("click", this.onResetEmailButtonClick.bind(this));
  }

  render() {
    return <div className={"sign-in-container" + (this.state.showHelp ? " show-help" : "")}>
      <div className="help-container">
        <LoginHelp onLeave={() => this.closeHelp()} />
      </div>
    </div>;
  }
}

export default OktaSignInWidget;
