import * as React from "react";
import "./Layout.scss";

const PlainLayout = (props: { children?: React.ReactNode }) => {
  return (
    <React.Fragment>
      <div>{props.children}</div>
    </React.Fragment>
  );
};

export default PlainLayout;
