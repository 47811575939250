import React, { useState, useEffect } from "react";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import "./DropDownInput.scss";


interface IDropDownInputEdit {
  onEdit: (value?: any) => void;
  title: string;
}

interface IDropDownInputCreate {
  onCreate: () => void;
  title: string;
}

interface IProps {
  onChange: (event: DropDownListChangeEvent) => void;
  data: Array<any>;
  name: string;
  label: string;
  isLoading?: boolean;
  children?: React.ReactElement<any>;
  createDetails?: IDropDownInputCreate;
  editDetails?: IDropDownInputEdit;
  disabled?: boolean;
  value?: any;
  required?: boolean;
  textField?: string;
  dataItemKey?: string;
  defaultItem?: any;
}

export default (props: IProps) => {

  return <div className="dropdown-input">
    <label>{props.label}</label>

    {props.value && props.editDetails &&
      <button className="fas fa-pencil edit-pencil-btn" disabled={props.disabled} onClick={e => { e.preventDefault(); props.editDetails.onEdit() }} title={props.editDetails.title} />}
    {props.createDetails &&
      <button className="add-file-btn fas fa-folder-plus" disabled={props.disabled} onClick={e => { e.preventDefault(); props.createDetails.onCreate() }} title={props.createDetails.title} />}

    <DropDownList
      name={props.name}
      data={props.data}
      key="id"
      textField={props.textField}
      dataItemKey={props.dataItemKey}
      value={props.value}
      disabled={props.disabled}
      onChange={props.onChange}
      required={props.required}
      className={props.editDetails || props.createDetails ? "has-options" : ""}
      defaultItem={props.defaultItem}
    />
  </div>
};
