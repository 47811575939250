import React, { useEffect, useState } from "react";
import { CircularProgress, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import { Dialog } from '@progress/kendo-react-dialogs';
import CloseIcon from "@material-ui/icons/Close";
import SupportService, { ISupportTicket } from "../../../services/SupportService";
import WizardSummary from "../../common/wizard/WizardSummary";
import { IWizardSummary } from "../../../models/Wizard";
import WizardStateManager from "../../common/utilities/WizardStateManager";

import "./SupportRequestPopup.scss";
import { StandardWizard } from "../../common/wizard/StandardWizard";
import ViewMore from "../../common/ViewMore";

interface IWizardPops {
  wizardSummary: IWizardSummary;
}

interface IProps extends IWizardPops {
  history: any;
  open: boolean;
  request: ISupportTicket;
  onClose: () => void;
}

enum SupportTicketTypes {
  Eo_Media = "eo - media"
}

interface IReadMoreProps {
  text: string;
}

const ReadMore = (props: IReadMoreProps) => {
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  };

  return (
    <span>
      {isReadMore ? `${props.text.slice(0, 80)}${(props.text.length > 80 ? "...":"")}` : props.text}
      {props.text.length > 80 &&
        <span onClick={toggleReadMore} className="readmore-text-link">
          {isReadMore ? "view more" : "show less"}
        </span>
      }
    </span>
  )
}

type Props = IProps;

const RequestDetails = (props: Props) => {

  const [sections, setSections] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stateManager, setStatemanager] = useState(null as WizardStateManager);
  const [followedBy, setFollowedBy] = useState("");
  const [mediaDetails, setMediaDetails] = useState(null);
  const showMediaDetails = props.request.supportTicketType.toLowerCase() === SupportTicketTypes.Eo_Media;

  useEffect(() => {
    async function getWorkItem(wrId: string) {
      setLoading(true);
      const result = await SupportService.GetWorkItem(wrId, showMediaDetails);

      if (result.ok && result.data.data) {
        console.log(result.data);
        setSections(result.data.data.FormQuestions);
        setStatemanager(new WizardStateManager(result.data.data.ValuesAudit));
        setMediaDetails(result.data.mediaDetails);
        props.wizardSummary && props.wizardSummary.getFollowedByUsers && setFollowedBy(props.wizardSummary.getFollowedByUsers(result.data.data.ValuesAudit));
      } else {
        setSections([]);
      }

      setLoading(false);
    }

    if (props.request && !loading && !sections && props.open) {
      getWorkItem(props.request.incidentId);
    }

  }, [props.request.incidentId, loading]
  );

  const onClose = function () {
    setSections(null);
    setStatemanager(null);
    props.onClose();
  }

  const width = 750;

  let content = <></>

  const title = <div className="flex-container"><div className="wr-detail-title">WORK REQUEST SUMMARY </div>

    <div className="column-flex-container">
      <div><label className="addit-lbl">Request </label><b>: </b> <span>{props.request.incidentId} </span></div>
      <div><label className="addit-lbl">Status </label><b>: </b> <span> {props.request.status}</span> </div></div>
    <IconButton
      className="modal-close"
      aria-label="close"
      disabled={false}
      onClick={onClose}><CloseIcon /></IconButton>
  </div>

  const createdBy = props.request && (props.request.createdByExternalEmail ? props.request.createdByExternalEmail : props.request.createdByInternalEmail);

  const footerContent = <div className="column-flex-container">
    <div><label className="addit-lbl">Created by     </label><b>: </b> <span>{createdBy}</span> </div>
    <ViewMore>{followedBy}</ViewMore>
    <div><label className="addit-lbl">Submitted date </label><b>: </b>
      <span>{props.request.submitted.localDateString}
        {(props.request.submitted && props.request.submitted.localTimeString) ? " " + props.request.submitted.localTimeString : ""}</span> </div>
  </div>

  if (loading) {
    content = <div className="embed-container spinner-wrapper">
      <CircularProgress />
    </div>
  } else if (!sections || !sections.length) {
    console.log("audit", sections);
    content = <div>No details found for this work request or you don't have access. We don't keep detailed records of work requests created outside of Epiq Access.</div>
  } else {
    content =
      <WizardSummary
      fieldValues={stateManager.values}
      wizardSections={sections}
      dependencySatisfied={(dep, vals) => stateManager.dependencyStatus(dep, vals)}
      showMedia={showMediaDetails}
      mediaDetails={mediaDetails}
      />
  }

  return (
    props.open && <div className="support-popup">
      <Dialog aria-labelledby="customized-dialog-title" width={width} title={title} onClose={onClose} closeIcon={true}>
        <DialogContent>
          <StandardWizard
            footerContent={footerContent}
            buttonDefinitions={[]}
            hideStepIndicator={true}>
            {content}
          </StandardWizard>
        </DialogContent>
      </Dialog>
    </div>);
}

export default RequestDetails;
