import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../store";
import * as ApplicationsStore from "../../../store/Applications";
import * as SessionStore from "../../../store/Session";
import * as HeaderStore from "../../../store/Header";
import * as MainDrawerStore from "../../../store/MainDrawer";
import { ISessionManager } from "../../../SessionManager";
import { authenticatedRoutes, Routes } from "../../../models/Routing";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import useStyles from "../../../components/common/useStyles";
import DoubleArrowOutlinedIcon from "@material-ui/icons/DoubleArrowOutlined";
import { ActionBar } from "../../../components/common/ActionBar";
import AdminPanel from "./panels/AdminPanel";
import ReportPanel from "./panels/ReportPanel";
import { Drawer, DrawerContent, DrawerItemProps } from "@progress/kendo-react-layout";
import { Slide } from "@progress/kendo-react-animation";
import "@progress/kendo-theme-material/dist/all.css";
import { DrawerSelectEvent } from "@progress/kendo-react-layout/dist/npm/drawer/interfaces/DrawerSelectEvent";
import "./MainDrawer.scss";
import { AppInsightsHelper } from "../../../AppInsights";

interface IMainDrawerItem extends DrawerItemProps {
  route?: Routes;
  subRoutes?: Array<Routes>;
  onSelect?: () => void;
  displayOrderIndex: number;
  containerClassName?: string;
}
interface IProps {
  sessionManager: ISessionManager;
  history: any;
}

type Props = IProps &
  SessionStore.ISessionState &
  HeaderStore.IHeaderState &
  MainDrawerStore.IMainDrawerState &
  typeof MainDrawerStore.actionCreators &
  ApplicationsStore.IApplicationsState & { children?: React.ReactNode };

export const MainDrawer = (props: Props) => {
  const [Panel, setPanel] = useState(null);
  const navItemsDictionary: Record<string, IMainDrawerItem> = {
    [Routes.Dashboard]: { text: "Reports", icon: "fas fa-signal ", displayOrderIndex: 0, route: Routes.Dashboard },
    [Routes.Files]: { text: "Files", icon: "fas fa-archive", displayOrderIndex: 1, route: Routes.Files },
    [Routes.Applications]: {
      text: "Applications",
      icon: "fas fa-th",
      displayOrderIndex: 2,
      route: Routes.Applications
    },
    [Routes.SupportList]: {
      text: "Technical Support",
      icon: "fas fa-tools fa-flip-horizontal",
      displayOrderIndex: 3,
      route: Routes.SupportList,
      subRoutes: [Routes.SupportRequestDetails],
      className: "drawer-item-bottom",
      containerClassName: "tech-support-drawer-container"
    },
    //JWK Disabling this for now
    // [Routes.SupportSpeedTest]: {
    //   text: "Speed Test",
    //   icon: "fas fa-tachometer-alt",
    //   displayOrderIndex: 3,
    //   route: Routes.SupportSpeedTest,
    //   className: "drawer-item-bottom-2",
  
    // },
    [Routes.SupportWorkRequestList]: {
      text: "Work Requests",
      icon: "fas fa-briefcase",
      displayOrderIndex: 4,
      route: Routes.SupportWorkRequestList,
      containerClassName: "work-request-drawer-container"
    },
    [Routes.Administration]: {
      text: "Administration",
      icon: "fas fa-sliders-h",
      displayOrderIndex: 5,
      route: Routes.Administration
    },
    [Routes.Approvals]: {
      text: "Approvals",
      icon: "fas fa-check-circle",
      displayOrderIndex: 6,
      route: Routes.Approvals
    }
  };
  const [selectedId, setSelectedId] = useState(null);
  const [navItems, setNavItemsState] = useState({} as Record<string, IMainDrawerItem>);
  const AdminPanelElement = (
    <AdminPanel
      history={props.history}
      onCloseClick={() => {
        setPanel(null);
      }}
    />
  );
  const ReportPanelElement = (
    <ReportPanel
      history={props.history}
      onCloseClick={() => {
        setPanel(null);
      }}
    />
  );
  const handleOnClick = () => {
    props.setMainDrawerExpanded();

  };
  const handleOnSelect = (event: DrawerSelectEvent) => {
    const isCurrentlySelected = event.itemIndex === selectedId;
    const navItem = navItems[event.itemTarget.props.route];
    const navRoute = authenticatedRoutes[navItem.route];

    setSelectedId(event.itemIndex);

    if (navItem.onSelect) {
      navItem.onSelect();
    }

    if (navRoute.id === Routes.Administration) {
      setPanel(AdminPanelElement);
    }

    if (navRoute.id === Routes.Dashboard) {
      setPanel(ReportPanelElement);
    }

    if (!isCurrentlySelected) {
      if (navRoute.id === Routes.Administration) {
        props.history.push(props.sessionData.destination.path);
      } else {
        props.history.push(navRoute.path);
      }
    }

    AppInsightsHelper.addTelemetryInitializer();
  };
  const setNavItems = () => {
    const navItems: Record<string, IMainDrawerItem> = {};

    Object.keys(navItemsDictionary).forEach(key => {
      if (props.sessionData.featurePermissions[key].authorized) {
        navItems[key] = navItemsDictionary[key];
      }
    });

    setNavItemsState(navItems);
  };
  const setSelectedNavItem = () => {
    const currentLocationPath = (props.history.location.pathname as string).toLowerCase();

    if (currentLocationPath.startsWith(authenticatedRoutes[Routes.ChangePassword].path)) {
      setPanel(null);
      setSelectedId(-1);

      return;
    }

    if (currentLocationPath.startsWith(authenticatedRoutes[Routes.Administration].lookupSegment)) {
      setPanel(AdminPanelElement);
    } else if (currentLocationPath.startsWith(authenticatedRoutes[Routes.Dashboard].path)) {
      setPanel(ReportPanelElement);
    } else {
      setPanel(null);
    }

    const selectedIndex = Object.keys(navItems).findIndex(key => {
      const routePathMatch = currentLocationPath.startsWith(authenticatedRoutes[key].path);
      const subRoutePathMatch =
        navItems[key].subRoutes &&
        navItems[key].subRoutes.some(subRoute => {
          return currentLocationPath.startsWith(
            authenticatedRoutes[subRoute].lookupSegment || authenticatedRoutes[subRoute].path
          );
        });

      if (key === Routes.Administration) {
        return currentLocationPath.startsWith(authenticatedRoutes[Routes.Administration].lookupSegment);
      }

      return routePathMatch || subRoutePathMatch;
    });

    setSelectedId(selectedIndex);
  };

  useEffect(() => {
    setNavItems();
  }, []);

  useEffect(() => {
    setSelectedNavItem();
  }, [props.history.location, navItems]);

  const getSelectedContainerClassName = () => {
    const selectedNavItem = Object.keys(navItems).find((key, index) => index === selectedId);
    return selectedNavItem ? navItems[selectedNavItem].containerClassName : "";
  }

  return (
    <React.Fragment>
      <div className="main-drawer-wrapper">
        <div className="main-drawer-container">
          <Drawer
            className={`main-drawer ${!props.expanded ? "collapsed" : ""}`}
            expanded={props.expanded}
            position={"start"}
            mode={"push"}
            mini={true}
            miniWidth={70}
            width={210}
            items={Object.keys(navItems).map((key, index) => ({ ...navItems[key], selected: index === selectedId }))}
            onSelect={handleOnSelect}
            animation={{ duration: 200 }}
          >
            <DrawerContent className={getSelectedContainerClassName()}>
              <div className="expand-collapse-icon-button" aria-label="close" onClick={handleOnClick}>
                <DoubleArrowOutlinedIcon className="expand-collapse-icon" />
              </div>
              <Slide
                direction="right"
                transitionEnterDuration={200}
                transitionExitDuration={200}
                className="sub-panel-slide-container"
              >
                {Panel}
              </Slide>
              <Container maxWidth={false} className="content-wrapper">
                <div className={useStyles().root}>
                  <Grid className="content-container">{props.children}</Grid>
                </div>
              </Container>
            </DrawerContent>
          </Drawer>
        </div>
        <Slide direction="up" transitionEnterDuration={300} transitionExitDuration={300} className="slide-container">
          {props.buttons && props.buttons.length > 0 ? (
            <ActionBar
              pageTitle={props.pageTitle}
              breadCrumbTitle={props.breadCrumbTitle}
              breadCrumbUrl={props.breadCrumbUrl}
              buttons={props.buttons}
            />
          ) : null}
        </Slide>
      </div>
    </React.Fragment>
  );
};

export default connect(
  (state: IApplicationState, ownProps: IProps) => ({
    ...ownProps,
    ...state.applicationsState,
    ...state.sessionState,
    ...state.headerState,
    ...state.mainDrawerState
  }),
  {
    ...MainDrawerStore.actionCreators}
)(MainDrawer as any);
