import React, { useEffect, useState } from "react";
import { FieldRenderProps } from "@progress/kendo-react-form";
import { ListView, ListViewItemProps } from '@progress/kendo-react-listview';
import { updateValForField } from "./WizardElements";
import CircularProgress from "@material-ui/core/CircularProgress";

const ListViewField = function (fieldRenderProps: FieldRenderProps) {
  const [items, setItems] = useState([] as any);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = async () => {
    setLoading(true);
    const result = await fieldRenderProps.fieldData.GetData(fieldRenderProps.fieldData.GetDataParams());
    if (result.ok) {
      if (result.data.results.length > 0) {
        const convertedItems = fieldRenderProps.fieldData.DataConverter(result.data.results);
        setItems(convertedItems);
        setLoading(false);
        updateValForField(
          convertedItems ? convertedItems.map((v: any) => v.id) : null,
          fieldRenderProps,
          convertedItems ? convertedItems.map((v: any) => v.field).join(";") : null
        );
      }
      else
        setItems(new Array<string>("No reocords found"));
    }
    else {
      setLoading(false);
    }
  };

  const MyItemRender = (props: ListViewItemProps) => {
    let item = props.dataItem;

    return (<div className="list-item-name">
      {item.field ? item.field : item}
    </div>);
  }

  return (
    <div>
      {!isLoading && (
        <ListView
          data={items}
          item={MyItemRender}
          style={{
            width: '100%',
          }}
        />)}
      {isLoading && (
        <div className="align-center">
          <CircularProgress />
        </div>)
      }
    </div>
  );
}
export default ListViewField;