import React, { useEffect, useState } from "react";
import "./CollapsibleSection.scss";

interface IProps {
  initHidden?: boolean;
  title:string;
  children: (() => JSX.Element) | JSX.Element | JSX.ElementClass;
  count?: number;
  headerContent?: (() => JSX.Element) | JSX.Element | JSX.ElementClass;
  noCollapse?: boolean;
}

type Props = IProps;

const CollapsibleSection = (props: Props) => {

  const [hideContent, setHideContent] = useState(props.initHidden);

  const showHide = function() {
    if (!props.noCollapse) {
      setHideContent(!hideContent);
    }
  }

  const countPart = props.count == null ? <></> : <span className="collapsible-section-count">{props.count}</span>;
  const headerContentPart = <span className="collapse-section-header-content">{props.headerContent}</span>;

  let legend = hideContent
    ? <span onClick={() => showHide()}><legend className="collapsible-section-head collapsible-section-closed">{props.title}{countPart}{headerContentPart}</legend></span>
    : <legend className={`collapsible-section-head collapsible-section-open collapsible-section-title ${props.noCollapse ? "non-collapsible-section" : ""}`} onClick={() => showHide()}>{props.title}{countPart}{headerContentPart}</legend>;

  return (
    <div className={"collapse-section"}>
      <fieldset>
        {legend}
        <div className={"collapsible-section-content " + (hideContent ? "collapsible-section-content-hidden" : "")}>
          {props.children}
        </div>
      </fieldset>
    </div>
  );
};

export default CollapsibleSection;
