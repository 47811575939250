import { IAgreementAcceptanceModel } from "../models/MessageModel";
import { ServiceBase, ResponseMessages, ServiceResponseJson, ServiceResponse } from "./ServiceBase";

const getAgreementMessages: ResponseMessages = {
  FailedMessage: "Failed to get agreement information.",
  SuccessMessage: "Successfully retrieved agreement information."
};

const getSubmitDecisionMessages: ResponseMessages = {
  FailedMessage: "Failed to submit agreement decision.",
  SuccessMessage: "Successfully submitted agreement decision."
};

export enum AgreementDecision {
  Decline = 1,
  Accept = 2
}

export interface IAgreementDecisionResponseModel {
  isValid: boolean;
  isSessionComplete: boolean;
  nextAgreement: IAgreementAcceptanceModel
}

export default class AgreementService extends ServiceBase {

  static async getNextAgreement(abortSignal?: AbortSignal) {
    const url = `${AgreementService.getBaseUri()}/getNextAgreement`;

    const result: ServiceResponseJson = await fetch(url, ServiceBase.fetchOptions(abortSignal))
      .then(async (response) => await ServiceBase.handleResponseJson(response, getAgreementMessages))
      .catch(async (response) => await ServiceBase.handleResponseJson(response, getAgreementMessages));

    return result;
  }

  static async submitDecision(agreementId: number, decision: AgreementDecision, abortSignal?: AbortSignal) {
    const url = `${AgreementService.getBaseUri()}/submitDecision?agreementId=${agreementId}&decision=${decision}`;

    const result: ServiceResponseJson = await fetch(url, ServiceBase.fetchPostOptions(abortSignal))
      .then(async (response) => await ServiceBase.handleResponseJson(response, getSubmitDecisionMessages))
      .catch(async (response) => await ServiceBase.handleResponseJson(response, getSubmitDecisionMessages));

    return result;
  }

  static getBaseUri() {
    return `${(<any>window).ApplicationVariables.apiUri}agreement`;
  }
}