import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../../store";
import * as SessionStore from "../../../../store/Session";
import * as ReportStore from "../../../../store/Report";
import { PanelBar, PanelBarItem, PanelBarItemProps, PanelBarSelectEventArguments } from "@progress/kendo-react-layout";
import CloseIcon from "@material-ui/icons/Close";
import { IPage, IPagedReport } from "../../../../models/Report";
import "./Panel.scss";
import { CircularProgress } from "@material-ui/core";
import { AppInsightsHelper } from "../../../../AppInsights";
import AnalyticsService from "../../../../services/AnalyticsService";

interface IReportPanelBarItemProps extends PanelBarItemProps {
  index?: string;
  subPages?: Record<string, IReportPagePanelBarItemProps>;
  reportId: string;
}

interface IReportPagePanelBarItemProps extends PanelBarItemProps {
  index?: string;
  parentReportId: string;
  pageName: string;
}

interface IProps {
  history: any;
  onCloseClick: () => void;
}

type Props = IProps & SessionStore.ISessionState & ReportStore.IReportState;

export const ReportPanel = (props: Props) => {
  const [selectedId, setSelectedId] = useState(null);
  const [navItems, setNavItemsState] = useState(null as Record<string, IReportPanelBarItemProps>);
  const handleOnSelect = (event: PanelBarSelectEventArguments) => {
    const itemProp = event.target.props as IReportPanelBarItemProps & IReportPagePanelBarItemProps;
    AppInsightsHelper.addTelemetryInitializer();
    trackReportCustomEvent(itemProp);

    if (itemProp.reportId) {
      const navItem = navItems['report_' + itemProp.reportId];
      setSelectedId(itemProp.uniquePrivateKey);
      props.history.push(navItem.path);
      return;
    }

    if (!itemProp.parentReportId) {
      console.error("Could not find selected child report");
      return;
    }

    // child prop was selected
    const parentNavItem = navItems['report_' + itemProp.parentReportId];
    const navItem = parentNavItem.subPages[itemProp.pageName];
    setSelectedId(itemProp.uniquePrivateKey);
    props.history.push(navItem.path);
  };

  const trackReportCustomEvent = (selectedReport?: IReportPanelBarItemProps & IReportPagePanelBarItemProps) => {
    let reportName = "", page = "";
    let reportId = 0;

    if (selectedReport) {
      if (selectedReport.root === true) {
        const subPages = selectedReport.subPages && Object.keys(selectedReport.subPages).map(key => selectedReport.subPages[key]);
        const subPageName = subPages && subPages[0] && subPages[0].title;
        reportName = selectedReport.title as string;
        page = (subPageName ? subPageName : "") as string;
      } else {
        const parentNavItem = navItems['report_' + selectedReport.parentReportId];
        reportName = parentNavItem.title as string;
        page = (selectedReport.title ? selectedReport.title : "") as string;
      }
      reportId = parseInt(selectedReport.reportId ? selectedReport.reportId : selectedReport.parentReportId);
    } else {
      if (props.pagedReports) {
        const initialReport = props.pagedReports[0];
        if (initialReport) {
          const pageName = initialReport.pages[0] && initialReport.pages[0].displayName;
          reportName = initialReport.name;
          page = `${pageName ? `-${pageName}` : ""}`;
          reportId = initialReport.id;
        }
      }
    }

    if (reportName) {
      const report = `${reportName}${page}`
      AppInsightsHelper.trackCustomEvent("View Report", { report });
      page = page.replace(/(^-)/g, '');
      page = page.trim();
      AnalyticsService.auditClickedReport(reportId, reportName, page);
    }
  }

  const routePathFromReportPage = function (reportId: number, page?: IPage) {
    const pageCode = page ? `/${page.name}` : "";

    return `/dashboards/${reportId}${pageCode}`;
  };

  const setNavItems = () => {
    if (!props.pagedReports) {
      return;
    }

    const navItemsTemp = props.pagedReports.reduce((reportProps: Record<string, IReportPanelBarItemProps>, report) => {
      const reportId = report.id.toString();

      reportProps['report_' + reportId] = { // Concatenated with 'report_' to prevent automatic sort of Object numeric property.
        title: report.name,
        path: routePathFromReportPage(report.id),
        reportId: reportId,
        expanded: false,
        subPages: report.pages
          ? report.pages.reduce((pageProps: Record<string, IReportPagePanelBarItemProps>, page) => {
            pageProps[page.name] = {
              index: page.name,
              title: `- ${page.displayName}`,
              path: routePathFromReportPage(report.id, page),
              parentReportId: reportId,
              pageName: page.name
            };
            return pageProps;
          }, {})
          : null
      };

      return reportProps;
    }, {});

    setNavItemsState(navItemsTemp);
  };

  useEffect(() => {
    setNavItems();
    trackReportCustomEvent(null);
  }, [props.pagedReports]);

  if (!navItems) {
    return (
      <div className="admin-panel-bar">
        <div className="panel-bar-empty">
          <CircularProgress />
        </div>
      </div>
    );
  }

  return (
    <div className="admin-panel-bar">
      <PanelBar onSelect={handleOnSelect} selected={selectedId}>
        {Object.keys(navItems).map(key => (
          <PanelBarItem selected={selectedId} key={key} expanded={true} {...navItems[key]}>
            {navItems[key].subPages &&
              Object.keys(navItems[key].subPages).length &&
              Object.keys(navItems[key].subPages).map(subKey => {
                return (
                  <PanelBarItem
                    headerClassName="sub-panel-bar"
                    key={key + subKey}
                    {...navItems[key].subPages[subKey]}
                  />
                );
              })}
          </PanelBarItem>
        ))}
      </PanelBar>
      <div className="close-icon-button" aria-label="close" onClick={props.onCloseClick}>
        <CloseIcon className="close-icon" />
      </div>
    </div>
  );
};

export default connect(
  (state: IApplicationState) => ({
    ...state.sessionState,
    ...state.reportState
  }),
  null
)(ReportPanel);
