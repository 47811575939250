import * as React from "react";
import ResourceService from "../../../../services/ResourceService";
import NotificationService from "../../../../services/NotificationService";
import {
  ResourceGroupDtls,
  IResourceGroupResource,
  IResourceGroupResourceParams,
  IResourceType,
  IResource
} from "../../../../models/ResourceModels";
import { AdvancedGrid, IGridParams } from "../../../common/grid/AdvancedGrid";
import { GridColumnProps, GridFilterCellProps, GridCellProps } from "@progress/kendo-react-grid";
import { CompositeFilterDescriptor, FilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import { DropDownFilter } from "../../../common/grid/filters/DropDownFilter";
import { Button } from "@progress/kendo-react-buttons";
import { Paper, Grid, CircularProgress } from "@material-ui/core";
import CommandCell from "../../../common/grid/columns/CommandCell";

interface IProps {
  resourceGroupId: number;
  autoSelectedResources?: Array<IResourceGroupResource>;
  includeResources?: Array<IResourceGroupResource>;
  resources?: Array<IResourceGroupResource>;
  selectionChange?: (selected: IResourceGroupResource[]) => void;
  addClickHandler?: () => void;
  importClickHandler?: () => void;
  hideAddButton?: boolean;
  onRemoveUpdateResources?: (selected: IResourceGroupResource[]) => void;
  displayRemoveBtn?: boolean;
  enableRemoveResource?: boolean;
  enableSelectResource?: boolean;
  className?: string;
  permissions: Set<string>;
  isResourceGroupEpiqOnly: boolean;
}

type Props = IProps;

type State = {
  resources: Array<IResourceGroupResource>;
  hasError: boolean;
  isGridLoading: boolean;
  resourceTypes: Array<IResourceType>;
  selectedResources: Array<IResourceGroupResource>;
  filtersExist: boolean;
};

const GridConfigurations = {
  PageSize: 500,
  OrderBy: "name",
  Ascending: "asc",
  Descending: "desc"
};

const includeResourceTypeList = ["Ediscovery Project", "File", "Report", "Epiq Access", "Client Name", "Sales Contract Number", "Application", "User", "Relativity Client", "Relativity Instance", "Relativity Workspace", "Role", "User Group", "Okta Group", "Whitelabel", "Gbts Client", "SAP Sold To Client" ];

const defaultResourceTypes: Array<CompositeFilterDescriptor> = [
  {
    logic: "or",
    filters: [{ field: "resourceTypeNavigation.displayName", operator: "in", value: `$[${includeResourceTypeList.toString()}]` }
    ] as Array<FilterDescriptor>
  }
]

class ResourceList extends React.Component<Props, State> {
  columns: Array<GridColumnProps>;
  gridParams: IResourceGroupResourceParams;
  myGrid: AdvancedGrid<IResourceGroupResource, IResourceGroupResourceParams>;

  constructor(props: Props) {
    super(props);

    this.state = {
      resources: props.resources ? props.resources : new Array<IResourceGroupResource>(),
      hasError: false,
      isGridLoading: false,
      resourceTypes: new Array<IResourceType>(),
      selectedResources: new Array<IResourceGroupResource>(),
      filtersExist:false
    };

    this.columns = new Array<GridColumnProps>(
      { field: "name", title: "RESOURCE NAME", filterable: true },
      { field: "pNumberOrResourceId", title: "RESOURCE ID", filterable: true },
      { field: "esiProjectCode", title: "ESI PROJECT CODE", filterable: true },
      { field: "clientName", title: "Client Name", filterable: true },
      { field: "projectStatus", title: "Project Status", filterable: true },
      {
        field: "resourceTypeNavigation.displayName",
        title: "RESOURCE TYPE",
        cell: this.resourceType,
        filter: "text",
        editable: false,
        filterable: true,
        filterCell: (props: GridFilterCellProps) => (
          <DropDownFilter
            {...props}
            data={this.state.resourceTypes}
            textField="displayName"
            defaultSelectedOption={null}
          />
        ),
        sortable: false,
        headerClassName: "no-sort"
      }
    );

    if (props.enableSelectResource) {
      this.columns.unshift({
        title: "",
        cell: CommandCell({
          onSelection: this.selectResource,
          selectedField: "selected"
        }),
        sortable: false,
        headerClassName: "no-sort"
      });
    }

    if (props.enableRemoveResource && props.permissions.has("EpiqAdminUpdateResourceGroup")) {
      this.columns.push({
        title: "",
        cell: CommandCell({ onRemove: this.removeResource }),
        sortable: false,
        headerClassName: "no-sort"
      });
    }

    this.gridParams = {
      skip: 0,
      take: GridConfigurations.PageSize,
      sort: [{ field: GridConfigurations.OrderBy, dir: "asc" }] as SortDescriptor[],
      resourceGroupId: props.resourceGroupId,
      refreshToggle: false,
      excludeResourceGroupResources: props.enableSelectResource,
      includeResourceIds: this.props.includeResources ? this.props.includeResources.map((r, index) => r.id) : null,
      isResourceGroupEpiqOnly: this.props.isResourceGroupEpiqOnly
    } as IResourceGroupResourceParams;
  }

  componentDidMount() {
    this.fetchResourceTypes();
  }

  fetchDataAsync = async (dataState: IResourceGroupResourceParams) => {
    try {
      this.setState({ isGridLoading: true });

      if (this.props.enableSelectResource || (dataState.resourceGroupId && dataState.resourceGroupId > 0)) {
        const updatedDataState = this.getFilterCriteria(dataState);
        const includeResourceIds = updatedDataState.includeResourceIds && updatedDataState.includeResourceIds.length > 0 ? updatedDataState.includeResourceIds : null;
        const result = await ResourceService.getResourceGroupResources({ ...updatedDataState, includeResourceIds });

        if (result.ok) {
          const resultResources = result.data.results as Array<IResourceGroupResource>;
          const mappedResults: Array<IResourceGroupResource> = resultResources.map(resource => {
            return ({
              ...resource,
              selected:
                this.state.selectedResources.length > 0 &&
                  this.state.selectedResources.find(selectedResource => {
                    return selectedResource.id === resource.id;
                  })
                  ? true
                  : false
            } as any) as IResourceGroupResource;
          });

          this.setState({
            resources: mappedResults,
            isGridLoading: false
          });

          if (!this.props.enableSelectResource && this.props.selectionChange) {
            this.props.selectionChange(resultResources);
          }
        } else {
          this.setState({ isGridLoading: false, hasError: true });
          NotificationService.showErrorToast("Something went wrong while getting resources.");
        }
      } else {
        if (this.props.selectionChange && this.props.resources) {
          this.props.selectionChange([] as IResourceGroupResource[]);
        }

        this.setState({
          isGridLoading: false
        });
      }
    } catch (e) {
      this.setState({ isGridLoading: false });
      NotificationService.showErrorToast("Something went wrong while getting resources.");
    } finally {
      this.setState({ isGridLoading: false });
    }
  };

  getFilterCriteria = (dataState: IResourceGroupResourceParams) => {
    let filtersExist = false;
    let isResourceTypeFilterSelected: boolean = false;
    let resourceTypeDisplayName: string = "";
    let modifiedFilters: FilterDescriptor[] = [];
    const compositeFilterDescriptors: CompositeFilterDescriptor[] = dataState.filters
      ? dataState.filters.map(compositeFilter => {
        const filters = compositeFilter.filters.filter(value => {
          const filterDescriptor = value as FilterDescriptor;
          if (filterDescriptor.field === "resourceTypeNavigation.displayName") {
            isResourceTypeFilterSelected = true;
            resourceTypeDisplayName = (filterDescriptor.value as IResourceType).displayName;
            return includeResourceTypeList.indexOf(resourceTypeDisplayName) > -1;
          } else {
            return true;
          }
        });

        filtersExist = filters.length > 0;
        if (isResourceTypeFilterSelected && filtersExist) {
          modifiedFilters = filters.map(value => {
            const filterDescriptor = value as FilterDescriptor;
            return filterDescriptor.field === "resourceTypeNavigation.displayName"
              ? { ...filterDescriptor, value: (filterDescriptor.value as IResourceType).filterValue }
              : filterDescriptor;
          });

          return {
            ...compositeFilter,
            filters: modifiedFilters
          };
        }

        return {
          ...compositeFilter,
          filters: filters
        };
      })
      : [];

    let additionalResourceTypeFilter: Array<CompositeFilterDescriptor> = [];
    if (isResourceTypeFilterSelected && resourceTypeDisplayName) {
      additionalResourceTypeFilter = resourceTypeDisplayName !== "Relativity Workspace"
        ? [{
          logic: "and",
          filters: [{ field: "IsDeleted", operator: "eq", value: false }
          ] as Array<FilterDescriptor>
        }]
        : [];
    }

    const defaulterFilters = this.props.enableSelectResource ? [...defaultResourceTypes, ...this.getExcludeResourceIdsFilter()] : [];
    this.setState({ filtersExist });
    return filtersExist ? {
      ...dataState, filters: [...compositeFilterDescriptors,
      ...defaulterFilters, ...additionalResourceTypeFilter
      ]
    } : { ...dataState, filters: [...defaulterFilters, ...additionalResourceTypeFilter] };
  };

  getExcludeResourceIdsFilter = () => {
    if (this.props.autoSelectedResources) {
      const excludeResourceIds = this.props.autoSelectedResources.map((item, index) => {
        return item.id;
      });

      return [{
        logic: "and",
        filters: [{ field: "id", operator: "notin", value: `[${excludeResourceIds.toString()}]` }
        ] as Array<FilterDescriptor>
      }] as CompositeFilterDescriptor[];
    }

    return new Array<CompositeFilterDescriptor>();
  }

  fetchResourceTypes = async () => {
    var result = await ResourceService.getResourceTypes();
    var resourceTypes = new Array<IResourceType>();

    if (result.ok) {
      result.data.forEach((resourceType: string) => {
        includeResourceTypeList.forEach(includeFilterType => {
          if (includeFilterType.toLowerCase() === resourceType.toLowerCase()) {
            resourceTypes.push(({ displayName: resourceType, filterValue: resourceType } as any) as IResourceType)
          }
        })
      });

      this.setState({ resourceTypes });
    }
  };

  selectResource = (dataItem: IResource) => {
    const alteredResources = this.state.resources.map(resource => {
      return {
        ...resource,
        selected: resource.id === dataItem.id ? !resource.selected : resource.selected
      };
    });
    const justSelectedResources = alteredResources.filter(resource => resource.selected);

    this.setState({
      resources: alteredResources as IResourceGroupResource[],
      selectedResources: justSelectedResources as IResourceGroupResource[]
    });

    if (this.props.selectionChange) {
      this.props.selectionChange(justSelectedResources as IResourceGroupResource[]);
    }
  };

  removeResource = (dataItem: any) => {
    if (this.props.onRemoveUpdateResources) {
      const alteredResources = this.props.resources.filter(resource => resource.id !== dataItem.id);
      this.setState({
        resources: alteredResources as IResourceGroupResource[], filtersExist: alteredResources.length > 0
      });

      this.props.onRemoveUpdateResources(alteredResources as IResourceGroupResource[]);
    }
  };

  resourceType = (gridCellProps: GridCellProps) => {
    return (
      <td>
        <div>{gridCellProps.dataItem.resourceTypeNavigation.displayName}</div>
      </td>
    );
  };

  gridToolBarRender = (): JSX.Element => {
    return (
      <div>
        {this.props.resourceGroupId && this.props.resourceGroupId!=0 &&
          this.props.permissions.has("EpiqAdminUpdateResourceGroup") &&
          (<Button className="btn-secondary" onClick={this.props.importClickHandler}>
            Import
          </Button>)}

        {(this.props.permissions.has("EpiqAdminCreateResourceGroup") || this.props.permissions.has("EpiqAdminUpdateResourceGroup")) &&
          (<Button className="add-resource-group-btn" icon={"plus"} primary={true} onClick={this.props.addClickHandler}>
            Resources
          </Button>)}

      </div>
    );
  };

  refreshTheGrid = (refreshToggle: boolean) => {
    this.myGrid.resetGridState({ ...this.gridParams, refreshToggle } as IResourceGroupResourceParams);
  }

  render() {
    const { hasError, resources, isGridLoading, filtersExist } = this.state;
    const { hideAddButton, className } = this.props;
    const gridData = this.props.resources ? this.props.resources : resources;
    const totalRecords = gridData.length;

    return (
      <Paper className={`resource-list-wrapper ${className}`}>
        <AdvancedGrid
          ref={(gridInstance: AdvancedGrid<IResourceGroupResource, IResourceGroupResourceParams>) => {
            this.myGrid = gridInstance;
          }}
          showErrorState={hasError}
          showLoadingIndicator={isGridLoading}
          data={gridData}
          dataFetch={this.fetchDataAsync}
          dataState={this.gridParams}
          columns={this.columns}
          paging={false}
          noRecordsRender={<p>{this.props.resources && totalRecords === 0 && filtersExist === false ? "No resources added." : "No resources found."}</p>}
          noLoadOnMount={false}
          filteredDataOnly={false}
          totalRecords={{ value: totalRecords, label: "Resources" }}
          multiFieldFilterDelimiter="|"
          gridToolbarContent={!hideAddButton && this.gridToolBarRender()}
          moreRecordsAvailableMessage="Too many resources to display. Adjust filters to refine results"
        />
      </Paper>
    );
  }
}

export default ResourceList;
