import React from "react";
import {
  Dialog,
  DialogActionsBar,
  DialogCloseEvent
} from "@progress/kendo-react-dialogs";
import { Button, PropTypes } from "@material-ui/core";
import ReactMarkdown from 'react-markdown';
import Tooltip from "@material-ui/core/Tooltip";

interface IDialogBoxProps {
  visible?: boolean;
  toggleDialogEvent?: (event: React.MouseEvent<HTMLElement>) => void;
  diagCloseEvent?: (event: DialogCloseEvent) => void;
  content?: string | string[];
  title?: string;
  openDiaglogButtonText?: string;
  okButtonLabelText?: string;
  cancelButtonLabelText?: string;
  dailogInfo?: string;
  color?: PropTypes.Color;
  disable?: boolean;
  btnClassName?: string;
  openDiaglogButtonTitle?: string;
}

const DialogBox: React.FunctionComponent<IDialogBoxProps> = props => {
  return (
    <span className="dialog-btn">
      {props.openDiaglogButtonText ? (
        <Tooltip arrow placement="top" title={props.openDiaglogButtonTitle || ""}>
          <span>
            <button className={`k-button ${props.btnClassName || ""}`} onClick={props.toggleDialogEvent} disabled={props.disable}>
              {props.openDiaglogButtonText}
            </button>
          </span>
        </Tooltip>
      ) : (
        ""
      )}
      {props.visible && (
        <Dialog
          title={props.title}
          onClose={props.diagCloseEvent}
          className={props.dailogInfo}
        >
          {(props.content && Array.isArray(props.content)) ? props.content.map(item => { return (<ReactMarkdown children={item} />) }) : <ReactMarkdown children={props.content} />}
          <DialogActionsBar>
            {props.okButtonLabelText ? (
              <Button
                variant="contained"
                className="btn-contined"
                color={props.color}
                name={props.okButtonLabelText}
                onClick={props.toggleDialogEvent}
              >
                {props.okButtonLabelText}
              </Button>
            ) : (
              ""
            )}
            {props.cancelButtonLabelText ? (
              <Button
                variant="contained"
                className="btn-contined"
                color="primary"
                name={props.cancelButtonLabelText}
                onClick={props.toggleDialogEvent}
              >
                {props.cancelButtonLabelText}
              </Button>
            ) : (
              ""
            )}
          </DialogActionsBar>
        </Dialog>
      )}
    </span>
  );
};

DialogBox.defaultProps = {
  visible: false,
  openDiaglogButtonText: "Open Dialog",
  title: "Please confirm?",
  content: "Are you sure want to continue",
  okButtonLabelText: "Yes",
  cancelButtonLabelText: "No"
};

export default DialogBox;
