class Role {
  public id: number = 0;
  public roleName: string = "";
  public createdDate?: Date | null | undefined;
  public createdBy?: number = 0;
  public lastModifiedDate?: Date | null | undefined;
  public lastModifiedBy?: number = 0;
  public description?: string = "";
  public epiqOnly?: boolean = false;
  public isDeleted: boolean = false;
}

class UpsertRoleModel extends Role {
  public permissions?: number[] = [];
  public roleId?: number = 0;
}

class Permission {
  public id: number = 0;
  public permissionName: string = "";
  public createdDate?: Date | null | undefined;
  public createdBy: number = 0;
  public lastModifiedDate?: Date | null | undefined;
  public lastModifiedBy: number = 0;
  public description?: string = "";
  public selected?: boolean = false;
  public epiqOnly: boolean = false;
}

export { Role, Permission, UpsertRoleModel };