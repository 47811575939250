import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { AdvancedGrid, IGridParams } from "../../common/grid/AdvancedGrid";
import { GridCellProps, GridColumnProps } from "@progress/kendo-react-grid";
import { Paper } from "@material-ui/core";
import { SecureUploadLocation } from "../../../models/SecureUploadLocations";
import AdminService from "../../../services/AdminService";
import * as SessionStore from "../../../store/Session";
import { IApplicationState } from "../../../store";
import { Button } from "@progress/kendo-react-buttons";
import { NavLink } from "react-router-dom";
import "./SecureUploadLocationsList.scss";

interface IProps {
  secureUploadLocation?: SecureUploadLocation;
  history: any;
}

type Props = IProps & SessionStore.ISessionState;

const startingGridParams: IGridParams = {
};

const SecureUploadLocationsList = (props: Props) => {
  const [hasError, setHasError] = useState(false);
  const [isGridLoading, setIsGridLoading] = useState(true);
  const [dataState, setDataState] = useState(startingGridParams);
  const [secureUploadLocations, setSecureUploadLocations] = useState([] as Array<SecureUploadLocation>);


  useEffect(() => {
    fetchDataAsync(dataState);
  }, []);

  const gridToolBarRender = (): JSX.Element => {
    return props.sessionData.permissions.has("EpiqAdminCreateSecureUploadLocations") && (
      <Button className="add-role-btn" icon={"plus"} primary={true} onClick={() => handleCreateSecureUploadClick()}>
        Location
      </Button>
    );
  }

  const nameCell = (props: GridCellProps) => {
    return <td>
      <div>
        <div className="secure-location-name">
          <NavLink to={`/administration/secureUploadDetails/${props.dataItem.id}`}>
            <em title={props.dataItem.name}>{props.dataItem.name} </em>
          </NavLink>
        </div>
      </div>
    </td>;
  }

  const handleCreateSecureUploadClick = () => {
    props.history.push("/administration/secureUploadDetails/0");
  }

  const columns = new Array<GridColumnProps>(
    { field: "name", title: "NAME", sortable: false, cell: props.sessionData.permissions.has("EpiqAdminGetSecureUploadLocations") ? nameCell : null, headerClassName: "no-sort", width: "100px" },
    { field: "secureFormURL", title: "URL", sortable: false, headerClassName: "no-sort", width: "450px" },
    { field: "uploadDestination", title: "UPLOAD DESTINATION", sortable: false, headerClassName: "no-sort", width: "200px" }
  );

  const fetchDataAsync = async (dataState: IGridParams, caller?: string) => {
    setIsGridLoading(true);

    var results = await AdminService.getSecureUploadLocationsList();

    if (results.ok) {
      setIsGridLoading(false);
      setSecureUploadLocations(results.data);
      setHasError(false);
    } else {
      console.log("Could not load grid.");
      setIsGridLoading(false);
      setHasError(true);
    }

    setDataState(dataState);
  } 

  return (
    <>
      <Paper className={`secure-upload-list-wrapper`}>
        <AdvancedGrid
          showErrorState={hasError}
          showLoadingIndicator={isGridLoading}
          data={secureUploadLocations}
          dataFetch={fetchDataAsync}
          dataState={dataState}
          columns={columns}
          paging={false}
          noRecordsRender={<p>No Secure Upload Location Found.</p>}
          filteredDataOnly={false}
          totalRecords={{ value: secureUploadLocations.length, label: "Secure Upload Location" }}
          gridToolbarContent={gridToolBarRender()}
        />
      </Paper>
    </>
    );
}


export default connect(
  (state: IApplicationState) => ({
    ...state.sessionState
  }),
  null
)(SecureUploadLocationsList);