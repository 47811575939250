import * as React from "react";
import { TextInput } from "../../../common/TextInput";
import NotificationService from "../../../../services/NotificationService";
import AdminService from "../../../../services/AdminService";
import { ApplicationsType, Connector } from "../../../../models/ApplicationModels";
import { Dialog as KendoDialog, DialogCloseEvent } from "@progress/kendo-react-dialogs";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import { Button as KendoButton } from "@progress/kendo-react-buttons";
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, Grid, Button, CircularProgress } from "@material-ui/core";
import CdnUploadWizard from "../../../files/FileUploadWizard/CdnUploadWizard";

import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";

import "../Applications.scss";
import "./CreateAppType.scss";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { IAppTypeSystemFeature, IAppTypeSystemFeatureWFeature, IFeature } from "../../../../models/Features";
import "../../../common/ImageUploadPreview.scss";

const urlRegex = new RegExp(/^(https?):\/\/(-\.)?([^\s\/?\.#]+\.?)+(\/[^\s]*)?$/i);

enum ErrorMessages {
  appTypeExists = "App type name already exists.",
  required = "This field is required.",
  invalidURL = "Please enter a valid URL."
}

interface INullableConnector {
  name: string;
  id: number | null;
}

interface IProps {
  refreshAppTypeData: () => void;
  handleClose: () => void;
  selectedAppType?: ApplicationsType;
  isCreateAppType: boolean;
  updateAppTypeFeature: (appTypeFeature: Array<IAppTypeSystemFeatureWFeature>) => void;
}

interface IToggleAbleSystemFeature extends IAppTypeSystemFeatureWFeature {
  enabled?: boolean;
  hasChanges?: boolean;
}

type State = {
  applicationsType: ApplicationsType;
  isLoading: boolean;
  disableSaveButton: boolean;
  isAppTypeExists: boolean;
  isSaving: boolean;
  isUploadOpen: boolean;
  connector?: INullableConnector;
  connectorOptions: Array<INullableConnector>;
  appTypeFeature: Array<IToggleAbleSystemFeature>;
};

class CreateAppType extends React.Component<IProps, State> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      applicationsType: (props.isCreateAppType ? {
        id: 0,
        typeName: "",
        imageUrl: "",
        createdDate: new Date(),
        createdBy: 0,
        connectorId: null
      } : props.selectedAppType),
      isLoading: false,
      disableSaveButton: true,
      isAppTypeExists: false,
      isSaving: false,
      isUploadOpen: false,
      connector: null,
      connectorOptions: [],
      appTypeFeature: []
    };
    this.getConnectorOptions();
    this.getSystemFeatures(props.selectedAppType ? props.selectedAppType.id : 0);
  }

  async getConnectorOptions() {
    const cResponse = await AdminService.getAllApplicationConnectors();
    if (cResponse.ok) {
      this.setState({ connectorOptions: [{ name: "(select one)", id: null }, ...cResponse.data] });
    }
  }

  async getSystemFeatures(appTypeId: number) {
    const response = await AdminService.systemFeaturesForType(appTypeId);
    if (response.ok) {
      this.setState({appTypeFeature: response.data});
    }
  }

  toggleAppTypeFeature(appTypeFeatureIndex: number, enabled: boolean) {
    const atfs = [ ... this.state.appTypeFeature];
    const atf = atfs[appTypeFeatureIndex];

    atf.enabled = enabled;
    atf.hasChanges = true;

    if (enabled && !atf.appTypeSystemFeature) {
      atf.appTypeSystemFeature = {
        id: 0,
        featureId: atf.feature.id,
        appTypeId: this.props.selectedAppType ? this.props.selectedAppType.id : 0,
        default: false
      };
    }

    this.setState({appTypeFeature: atfs});
  }

  toggleAppTypeFeatureDefault(appTypeFeatureIndex: number, defaultATF: boolean) {
    const atfs = [ ... this.state.appTypeFeature];
    const atf = atfs[appTypeFeatureIndex];

    atf.appTypeSystemFeature.default = defaultATF;
    atf.enabled = true;
    atf.hasChanges = true;

    this.setState({appTypeFeature: atfs});
  }

  canEnable = (applicationsType: ApplicationsType, oldApplicationsType: ApplicationsType, needsChange?: boolean) => {
    return !!applicationsType.typeName
      && (needsChange ? JSON.stringify(applicationsType) !== JSON.stringify(oldApplicationsType) : true)
  }

  onAppTypeChange = (value: string | number, name: string, isValid: boolean, needsChange?: boolean) => {
    const applicationsType = this.state.applicationsType;
    const newAppType = { ...applicationsType, [name]: value };
    const disableSaveButton = isValid === false || !this.canEnable(newAppType, applicationsType, needsChange);

    this.setState({
      ...this.state,
      disableSaveButton,
      applicationsType: newAppType,
      isAppTypeExists: false
    });
  };

  getAndStartSystemFeatureUpdates() {
    return this.state.appTypeFeature.filter(sf => sf.hasChanges).map(sf => {
      if (sf.enabled) {
        return AdminService.upsertSystemFeature(sf.appTypeSystemFeature);
      }
      else if (sf.appTypeSystemFeature) {
        return AdminService.removeSystemFeature(sf.appTypeSystemFeature.featureId, sf.appTypeSystemFeature.appTypeId);
      }
    });
  }

  onSaveBtnClick = async () => {
    await this.setState({ isLoading: true, disableSaveButton: true, isSaving: true });
    const { applicationsType } = this.state;

    let mainCall = this.props.isCreateAppType ? AdminService.CreateAppType(applicationsType) : AdminService.UpdateAppType(applicationsType);

    if (!this.props.isCreateAppType) {

      // TODO we should await the mainCall in order to get the ID whenever this is a create, but that will require some minor changes to the API. For now, don't make system feaatures available until after creation
      const systemFeatureCalls = this.getAndStartSystemFeatureUpdates();

      const results = await Promise.all(systemFeatureCalls);

      if (results.some(f =>!f.ok)) {
        NotificationService.showErrorToast("Some changes to system feature settings did not save properly");
      }
    }

    const appTypeResult = await mainCall;

    if (appTypeResult.ok) {
      if (!appTypeResult.data.inValid) {
        await this.setState({ isLoading: false, disableSaveButton: false });
        this.props.handleClose();
        this.props.refreshAppTypeData();
        this.props.updateAppTypeFeature(this.state.appTypeFeature);
        NotificationService.showSuccessToast(applicationsType.typeName + (this.props.isCreateAppType ? " created." : " updated."));
      } else {
        const errMsg = appTypeResult.data && appTypeResult.data.message ? appTypeResult.data.message : "";
        if (errMsg) {
          if (errMsg == ErrorMessages.appTypeExists) {
            await this.setState({ isAppTypeExists: true, isLoading: false, isSaving: false });
            NotificationService.showErrorToast(applicationsType.typeName + " " + errMsg);
          } else {
            this.setState({ isLoading: false, isSaving: false });
            NotificationService.showErrorToast(errMsg);
          }
        }
      }
    } else {
      this.setState({ isLoading: false, isSaving: false });
      NotificationService.showErrorToast(this.props.isCreateAppType ? "Failed to create new app type." : "Failed to update app type.");
    }
  };

  onFileUploadWizardClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | DialogCloseEvent) => {
    this.setState({ ...this.state, isUploadOpen: false });
  };

  onImageUploadFinished = (url?: string) => {
    const applicationsType = this.state.applicationsType;
    const newAppType = { ...applicationsType, imageUrl: url };
    const disableSaveButton = !this.canEnable(newAppType, applicationsType, applicationsType.imageUrl != url);

    this.setState({
      ...this.state,
      isUploadOpen: false,
      applicationsType: { ...this.state.applicationsType, imageUrl: url ? url : "" },
      disableSaveButton: disableSaveButton
    });
  };

  render() {
    const { disableSaveButton, isLoading, isAppTypeExists, isSaving, applicationsType } = this.state;

    return (
      <Dialog
        maxWidth="md"
        onClose={this.props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        className="modal add-app-modal"
      >
        <DialogTitle className="modal-title">
          Add Application Type Name
          <IconButton className="modal-close" aria-label="close" onClick={this.props.handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} className="marginDivs">
              <h3>ENTER APPLICATION DETAILS</h3>
              <span>You must provide the application type name and upload the application tile image.</span>
            </Grid>
            <Grid item xs={6} className="marginDivs">
              <div className="upload-img-preview">
                <div className="upload-control">
                  <div className="preview">
                    {applicationsType.imageUrl && <img className="image" src={applicationsType.imageUrl} />}
                    {!applicationsType.imageUrl && <p>No Image Added</p>}
                  </div>
                  <div className="upload-options">
                    <KendoButton
                      
                      icon={"plus"}
                      primary={true}
                      onClick={() => this.setState({ ...this.state, isUploadOpen: true })}
                    >
                      {" "}
                      Upload
                    </KendoButton>
                  </div>
                </div>
              </div>
              {this.state.isUploadOpen && (
                <KendoDialog
                  onClose={this.onFileUploadWizardClose.bind(this)}
                  aria-labelledby="customized-dialog-title"
                  className="modal files-list-file-upload-modal"
                >
                  <DialogTitle className="modal-title">
                    File Upload Wizard
                    <IconButton
                      className="modal-close"
                      aria-label="close"
                      disabled={false}
                      onClick={this.onFileUploadWizardClose.bind(this)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <CdnUploadWizard onFinished={this.onImageUploadFinished.bind(this)} />
                  </DialogContent>
                </KendoDialog>
              )}
            </Grid>
            <Grid item xs={12} className="form-input marginDivs">
              <div className="app-type-label">* App Type Name</div>
              <TextInput
                type="text"
                placeholder="App Type Name"
                name="typeName"
                label=""
                onChange={this.onAppTypeChange}
                validations={[
                  { name: "required", errorMessage: `${ErrorMessages.required}` },
                  {
                    name: "CustomValidation",
                    errorMessage: `${ErrorMessages.appTypeExists}`,
                    predicate: isAppTypeExists.toString()
                  }
                ]}
                displayCustomValidationMessage={isAppTypeExists}
                disabled={isSaving}
                defaultValue={!this.props.isCreateAppType ? this.props.selectedAppType.typeName : ""}
              />
            </Grid>
            <Grid item xs={12} className="marginDivs">
              {this.state.connectorOptions.length > 0 &&
                <>
                <div className="app-type-label">Application Family <br/>
                  <span className="app-type-description">The family groups similar applications, like Relativity, to provide a way to add or update features.</span>
                </div>
                <DropDownList
                name="connectorId"
                data={this.state.connectorOptions}
                defaultItem={this.state.applicationsType && this.state.applicationsType.connectorId ? (this.state.connectorOptions.find(o => o.id === this.state.applicationsType.connectorId) || this.state.connectorOptions[0]) : this.state.connectorOptions[0]}
                key="createapptypedropdownfield"
                textField="name"
                dataItemKey="id"
                value={this.state.connector}
                onChange={e => {
                  this.setState({ connector: e.target.value }, () => {
                    const v = e.target.value.id as number;
                    this.onAppTypeChange(v, "connectorId", true, true);
                  });
                }
                }
              /></>}
              <div className="app-type-system-features marginDivs">
                {!this.props.isCreateAppType && <>
                  <div className='app-type-label'>Technical Support <br/>
                    <span className="app-type-description">Technical Support appears as default. As needed, disable this feature.</span>
                  </div>
                </>}
                {!this.props.isCreateAppType && this.state.appTypeFeature.map((atf, i) => {
                  return <div className="app-type-system-features-group">
                    <Checkbox
                      className="app-type-system-features-feature"
                      defaultChecked={!!atf.appTypeSystemFeature}
                      name={"apptypefeature_f" + atf.feature.id}
                      label={atf.feature.name}
                      disabled={isSaving}
                      onChange={(event: CheckboxChangeEvent) => { this.toggleAppTypeFeature(i, event.value as any as boolean) }}
                    /> {atf.appTypeSystemFeature && atf.enabled !== false && <> <Checkbox
                      className="app-type-system-features-feature-default"
                      defaultChecked={!!(atf.appTypeSystemFeature && atf.appTypeSystemFeature.default)}
                      name={"apptypefeature_default_f" + atf.feature.id}
                      label={"Set as application default"}
                      disabled={isSaving}
                      onChange={(event: CheckboxChangeEvent) => { this.toggleAppTypeFeatureDefault(i, event.value as any as boolean) }}
                    /></>}
                  </div>
                })}
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="modal-footer">
          <Button variant="contained" onClick={this.props.handleClose} className="btn-contined">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={this.onSaveBtnClick}
            color="primary"
            className="btn-primary"
            disabled={disableSaveButton && this.state.appTypeFeature.findIndex(atf => atf.hasChanges) == -1}
          >
            {isLoading && <CircularProgress size={20} />}
            save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default CreateAppType;
