import * as React from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import PersonIcon from "@material-ui/icons/Person";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import { NavLink } from "react-router-dom";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { AppInsightsHelper } from "../../../AppInsights";
import SessionStorageApi from "../../../apis/storage/SessionStorageApi";
import { IStyleOverrides } from "../../../models/SiteBrandingModel";

interface IHeaderMenuDropDownProps {
  rightMenuDropDownOptions?: IRightHeaderMenuOption[];
  userEmail?: string;
  userName?: string;
  rightMenuOptions?: IRightHeaderMenuOption[];
  isEmulating?: boolean;
  isFederated?: boolean;
  hideRightDropDownMenu?: boolean
  isSessionBasic?: boolean
}

export interface IRightHeaderMenuOption {
  onItemClick?: (event: React.MouseEvent<HTMLElement>) => void;
  title: string;
  url?: string;
}

const EpiqHeaderMenuDropDown: React.FunctionComponent<
  IHeaderMenuDropDownProps
> = props => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (
      anchorRef &&
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: any) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(
    () => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }

      prevOpen.current = open;
    },
    [open]
  );
  let tooltip: any = "";
  const siteInfo = SessionStorageApi.getBranding() as IStyleOverrides;
  return (
    <div>
      <ul className="right-main-menu">
        {props.rightMenuOptions
          ? props.rightMenuOptions.map(item => (
            <MenuItem onClick={handleClose} key={item.title}>
              <div
                ref={anchorRef}
                onClick={handleToggle}
                className="MuiButtonBase-root MuiButton-root MuiButton-text no-drop-down"
              >
                <NavLink
                  to={item.url}
                  className="nav-link"
                  isActive={(match, location) => {
                    return location.pathname.includes(item.url);
                  }}
                >
                  {item.title}
                </NavLink>
              </div>
            </MenuItem>
          ))
          : ""}
      </ul>
      <Button
        ref={anchorRef}
        role="right-menu-expand"
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {props.isEmulating ? <PeopleAltIcon /> : <PersonIcon />}
        <span className="arrow-down" />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <div className="user-info-popup">
                    <div className="user-info">
                      {props.userName.length > 30 ? (
                        <>
                          <span
                            id="user-name"
                            title={props.userName}
                            onMouseOver={event =>
                              tooltip && tooltip.handleMouseOver(event)
                            }
                            onMouseOut={event =>
                              tooltip && tooltip.handleMouseOut(event)
                            }
                          >
                            {`${props.userName.slice(0, 30)} ...`}
                          </span>
                          <Tooltip
                            ref={el => (tooltip = el)}
                            openDelay={300}
                            position="top"
                            anchorElement="target"
                            className="tooltip-email"
                          />
                        </>
                      ) : (
                        <span id="user-name">{props.userName}</span>
                      )}

                      {props.userEmail.length > 35 ? (
                        <>
                          <span
                            id="user-email"
                            title={props.userEmail}
                            onMouseOver={event =>
                              tooltip && tooltip.handleMouseOver(event)
                            }
                            onMouseOut={event =>
                              tooltip && tooltip.handleMouseOut(event)
                            }
                          >
                            {`${props.userEmail.slice(0, 35)} ...`}
                          </span>
                          <Tooltip
                            ref={el => (tooltip = el)}
                            openDelay={300}
                            position="top"
                            anchorElement="target"
                            className="tooltip-email"
                          />
                        </>
                      ) : (
                        <span id="user-email">{props.userEmail}</span>
                      )}
                    </div>
                    {(!siteInfo || (siteInfo && !siteInfo.HideEaFaqLink)) && <div id="user-faq">
                      <a href="https://www.epiqglobal.com/en-us/technologies/epiq-service-cloud/epiq-access/faqs" target="_blank">
                        Epiq Access FAQ
                      </a>
                    </div>}
                    {
                      !props.isEmulating && !props.hideRightDropDownMenu && !props.isFederated && !props.isSessionBasic && (
                        <div id="user-changePassword">
                          <NavLink
                            className="nav-link"
                            to="/ChangePassword"
                            exact
                            onClick={() => AppInsightsHelper.addTelemetryInitializer()}
                          >
                            Change Password
                          </NavLink>
                        </div>
                      )
                    }
                  </div>
                  {props.rightMenuDropDownOptions
                    ? props.rightMenuDropDownOptions.map(item => (
                      <MenuItem onClick={handleClose} key={item.title}>
                        {item.url ? (
                          <NavLink
                            className="nav-link"
                            to={item.url}
                            key={"Link" + item.title}
                            exact
                          >
                            {item.title}
                          </NavLink>
                        ) : (
                          <span
                            className="nav-link"
                            onClick={item.onItemClick}
                          >
                            {" "}
                            {item.title}
                          </span>
                        )}
                      </MenuItem>
                    ))
                    : ""}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export { EpiqHeaderMenuDropDown };
