import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useReport, Report, Dashboard } from "powerbi-report-component";
import { service, factories, models, Report as PbiReport,  } from "powerbi-client";
import PowerBiHelper from "../common/utilities/PowerBiHelper";
import { PowerBiEmbedSet } from "../../models/ReportModels";
import CircularProgress from "@material-ui/core/CircularProgress";
import PagedEmbed from "./PagedEmbed";
import { Button } from "@progress/kendo-react-buttons";
import * as SessionStore from "../../store/Session";
import { SessionManager } from "../../SessionManager";

import "./ReportEmbed.scss";
import { IApplicationState } from "../../store";
import { IPowerBiPreference, IPowerBiReportBiPreference } from "../../models/Preferences";

interface IProps {
  embedSet: PowerBiEmbedSet;
  pageName: string;
  bookmarkKey?: number;
  refresh: () => void;
}

type Props = IProps & SessionStore.ISessionState;

const ReportEmbed = (props: Props) => {
  const [report, setReport] = useState<PbiReport>(null);
  const [dashboard, setDashboard] = useState(null);
  const [expiration, setExpiration] = useState(null as string);
  const [embedItem, setEmbedItem] = useState(
    props.embedSet && props.embedSet.embedItems ? props.embedSet.embedItems[0] : null
  );
  const [loadReport, setloadReport] = useState(!(props.embedSet && props.embedSet.embedItems && props.embedSet.embedItems.length));
  const [isTileClicked, setTileClick] = useState(false);
  const [pageName, setPageName] = useState(props.pageName);
  const [sessionManager] = useState(new SessionManager());

  useEffect(() => {   
    const currentEmbedId = props.embedSet ? props.embedSet.embedItems[0].embedId : "";
  
    if ((!embedItem && props.embedSet && props.embedSet.embedItems) || (embedItem && embedItem.embedId !== currentEmbedId && isTileClicked === false) || 
      (expiration && expiration !== props.embedSet.embedTokenExpiry)) {     
      setEmbedItem(props.embedSet.embedItems[0]);
      setExpiration(props.embedSet.embedTokenExpiry);
      PowerBiHelper.setTokenExpirationListener(props.embedSet.embedTokenExpiry, () => props.refresh(), 2);
      setloadReport(false);
      if (props.pageName) {
        setPageName(props.pageName);
      }
    } else if (expiration && props.embedSet && expiration < props.embedSet.embedTokenExpiry) {
      const newExpiration = props.embedSet.embedTokenExpiry;
      if (report) {
        report.setAccessToken(props.embedSet.accessToken);
      }
      if (dashboard) {
        const dashItem = props.embedSet.embedItems.find((r) => r.isDashboard);
        dashboard.setAccessToken(dashItem.dashboardAccessToken);
      }
      setExpiration(newExpiration);
      PowerBiHelper.setTokenExpirationListener(newExpiration, () => props.refresh(), 2);
      setloadReport(false);
    }

    if (!loadReport && report && !embedItem.isDashboard && props.pageName && pageName !== props.pageName) {
      setPageName(props.pageName);
      report.setPage(props.pageName);
    }
  }, [props.embedSet, embedItem, props.pageName]);

  const reportStyle : models.ICommonEmbedConfiguration = {
    settings: {
      layoutType: models.LayoutType.Custom,
      customLayout: {
        displayOption: models.DisplayOption.FitToPage
      },
      panes: {
        pageNavigation: {
          visible: false
        }
      },
      filterPaneEnabled: true,
      navContentPaneEnabled: false,
      hideErrors: false // Use this *only* when you want to overide error experience i.e, use onError
    }
  };

  const handleReportLoad = async (report: PbiReport) => {
    setReport(report);
    console.log("report loaded", report); // get the report object from callback and store it.

    await loadBookmarkState(report);
  };

  const handleDashboardLoad = (dashboard: any) => {
    setDashboard(dashboard);
    console.log("dashboard loaded", dashboard);
  };

  const handleReportRender = async (report: any) => {
    console.log("report rendered", report);
  };

  const saveBookmarkState = async () => {
    const capturedState = await report.bookmarksManager.capture({ personalizeVisuals: true});

    let allBookmarks = props.sessionData.userPrefs ? props.sessionData.userPrefs.PowerBiBookmarks as IPowerBiPreference : null;

    const savedStructure = {
      reportId: props.bookmarkKey,
      bookmarks: [{
        name: capturedState.name,
        displayName: "Default", // if we have more than one bookmark in a single report we can consider using capturedState.displayName or taking user input
        state: capturedState.state
      }]
    } as IPowerBiReportBiPreference;

    if (!allBookmarks) {
      allBookmarks = {
        [props.bookmarkKey] : savedStructure
      }
    } else {
      allBookmarks = { ...allBookmarks, [props.bookmarkKey] : savedStructure };
    }

    sessionManager.setPreference({
      prefName: "PowerBiBookmarks",
      value: allBookmarks
    });
  }

  const loadBookmarkState = async (report: PbiReport) => {
    const savedBookmarks = props.sessionData.userPrefs ? props.sessionData.userPrefs.PowerBiBookmarks as IPowerBiPreference : null;

    if (!props.bookmarkKey || !savedBookmarks || !savedBookmarks[props.bookmarkKey] || !savedBookmarks[props.bookmarkKey].bookmarks || !savedBookmarks[props.bookmarkKey].bookmarks.length) {
      console.log("there is no bookmark to load");
      return;
    }

    try {
      // for now we only save and retrieve one bookmark
      await report.bookmarksManager.applyState(savedBookmarks[props.bookmarkKey].bookmarks[0].state);
    }catch (e) {
      console.log("error loading bookmark", e);
    }
  }

  const handleError = (error: any) => {
    console.log("power bi error", error);
  };

  if (loadReport) {
    return (
      <div className="grid-spin-container">
        <CircularProgress />
      </div>
    );
  }

  if (embedItem.isDashboard) {

    return (
      <div className="embed-container">
        <Dashboard
          tokenType="Embed"
          accessToken={embedItem.dashboardAccessToken}
          embedUrl={embedItem.embedUrl}
          embedId={embedItem.embedId}
          style={reportStyle} // style object for dashboard component
          pageView="fitToWidth" // 'fitToWidth' (default) , 'oneColumn', 'actualSize'
          onLoad={handleDashboardLoad}
          onTileClicked={(data: any) => {
            const reportId = /reportId=([^&]+)/.exec(data.reportEmbedUrl)[1];
            const reportEmbed = props.embedSet.embedItems.find((r) => r.embedId === reportId);

            if (!reportEmbed) {
              console.error("Could not find embed and token info report with id: ", reportId);
              // TODO we could just call the rest endpoint to get it if it's not already supplied
              return;
            }

            setTileClick(true);
            setEmbedItem(reportEmbed);   
            console.log("Data from tile", data);
          }}
        />
      </div>
    );
  }

  // TODO change to this once we enable external menu nav
  // return (
  //   <div className="embed-container">
  //     <PagedEmbed 
  //       embedItem={embedItem}
  //       reportStyle={reportStyle}
  //       accessToken={props.embedSet.accessToken} />
  //   </div>);

  return (
    <div className="embed-container">
      {false /*permanently disable for now*/ && props.sessionData.permissions.has("EpiqAdminUpdateReport") && <>
        <Button onClick={saveBookmarkState} disabled={!props.bookmarkKey}>Save bookmark state</Button>
        {/* <Button onClick={() => loadBookmarkState(report)}>Load bookmark state</Button> */}
      </>}
      <Report
        tokenType="Embed" // "or Aad"
        accessToken={props.embedSet.accessToken}
        embedUrl={embedItem.embedUrl}
        embedId={embedItem.embedId}
        pageName={props.pageName} // set as current page of the report
        reportMode="View" // open report in a particular mode view/edit/create
        datasetId={embedItem.datasetId} // required for reportMode = "create" and optional for dynamic databinding in `report` on `view` mode
        permissions="Read" // 'Read' | 'ReadWrite' | 'Copy' | 'Create' | 'All'
        extraSettings={{navContentPaneEnabled: false}}
        style={reportStyle}
        onLoad={handleReportLoad}
        onRender={handleReportRender} // not allowed in `create`
        onError={handleError}
      // onSelectData={this.handleDataSelected}
      // onPageChange={this.handlePageChange}
      // onTileClicked={this.handleTileClicked}
      // NOTE: There is also a Config for EmbedType = 'report' | 'dashboard' | 'tile', but it might be for hooks mode only
      />
    </div>
  );
};

export default connect(
  (state: IApplicationState) => ({
    ...state.sessionState
  }),
  null
)(ReportEmbed);
