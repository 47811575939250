import React, { useState, ReactElement } from "react";
import { Renderer } from "react-dom";
import { GridColumnProps, GridRowProps } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import CommandCell from "./columns/CommandCell";
import { v4 as uuid } from "uuid";
import { ServiceResponseJson, ServiceResponse, ServiceResponseData } from "../../../services/ServiceBase";
import { AdvancedGrid, IGridParams, IAdvancedGridProps } from "./AdvancedGrid";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { isBoolean } from "lodash";
import "./EditGrid.scss";

export interface IGridEditItem {
  id: number | undefined;
  _inEdit: boolean | undefined;
  _localId: string | undefined;
}

export interface IGridToolBarConfig {
  addButtonText?: string;
  hideAddButtonTitle?: boolean;
  cancelButtonText?: string;
  totalRecordsText: string;
  btnClass?: string;
  secondaryToolBarElements?: JSX.Element;
}

export interface ChangeErrorEvent {
  isError: boolean;
  isValid: boolean;
  message: string;
}

interface Props<T extends IGridEditItem, TT extends IGridParams> {
  itemChanged?: <T>(item: T) => void; // TODO implement this?
  /**
   * @deprecated Use `createDataItem()` instead for endpoints that return updateResponse<T>.
  */
  createItem?: (item: T) => Promise<ServiceResponseJson>;
  /**
   * @deprecated Use `updateDataItem()` instead for endpoints that return updateResponse<T>.
  */
  updateItem?: (item: T) => Promise<ServiceResponse>;
  /**
   * @deprecated Use `deleteDataItem()` instead for endpoints that return updateResponse<T>.
  */
  deleteItem?: (id: number) => Promise<ServiceResponse>;
  createDataItem?: (item: T) => Promise<ServiceResponseData<T>>;
  hideUpdateIcon?: boolean;
  updateDataItem?: (item: T) => Promise<ServiceResponseData<T>>;
  hideDeleteIcon?: boolean;
  deleteDataItem?: (id: number) => Promise<ServiceResponseData<T>>;
  dataState: TT; // TODO we might have outsider and this change the params, so might need to add a callback so outsider can get updates from in here
  getItems: (params: TT) => Promise<ServiceResponseJson>;
  columns: Array<GridColumnProps>;
  getResponseToGridConverter?: (responseData: any) => any;
  filterDescriptor?: CompositeFilterDescriptor;
  gridToolbar?: boolean | IGridToolBarConfig;
  multiFieldFilterDelimiter?: string;
  noRecordsRender: React.ReactElement<Renderer>;
  filteredDataOnly: boolean | JSX.Element;
  btnClass?: string;
  errorCreatingItem?: (event: ChangeErrorEvent) => void;
  errorUpdatingItem?: (event: ChangeErrorEvent) => void;
  errorDeletingItem?: (event: ChangeErrorEvent) => void;
  disablePlusIcon?: boolean;
  updateDisablePlusIcon?: (disable:boolean)=> void;
}

const EditGrid = <T extends IGridEditItem, TT extends IGridParams>(props: Props<T, TT>) => {
  const [backupItems, setBackupItems] = useState(new Array<IGridEditItem>());
  const [gridItems, setGridItems] = useState(new Array<IGridEditItem>());
  const [hasErrors, setHasErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const editField = "_inEdit"; // TODO see if we remove "T extends GridEditItem" and just have T, where internally we add on a new type with our new properties _inEdit and _localId

  const fetchDataAsync = async (datastate: TT) => {
    // TODO handle paging and add dataloader, pagerSettings? sort?
    setLoading(true);
    try {
      const result = await props.getItems(datastate);
      if (result.ok) {
        const data = (props.getResponseToGridConverter
          ? props.getResponseToGridConverter(result.data.results || result.data)
          : result.data.results || result.data) as IGridEditItem[];
        data.forEach((r, idx, arr) => {
          r._localId = uuid();
          arr[idx] = r;
        });

        setBackupItems(data);
        setGridItems(data);
        setLoading(false);
      } else {
        // TODO handle error and probably refactor all this, test run a failure scenario
        setHasErrors(true);
        console.error("failed to get grid items.");
      }
    } catch (ex) {
      console.error("Error getting data", ex);
      setHasErrors(true);
    }
  };

  const enterEdit = (dataItem: IGridEditItem) => {
    props.updateDisablePlusIcon(true);
    setGridItems(
      backupItems.map((r) => {
        const mappedItem = r._localId === dataItem._localId ? { ...r, _inEdit: true } : r;

        return mappedItem;
      })
    );
    console.log(backupItems);
  };

  const add = (dataItem: T) => {
    setSaving(true);
    props.createDataItem && props.createDataItem(dataItem).then((results) => {

      setSaving(false);
      if (!results.ok || results.inValid) {
        console.error("failed to create");
        props.updateDisablePlusIcon(true);
        props.errorCreatingItem && props.errorCreatingItem({isError: !results.ok, isValid: results.inValid, message: results.message });
        return;
      }

      dataItem._localId = uuid();

      const _items = [...gridItems];

      dataItem._inEdit = false;
      dataItem.id = results.data.id;
      updateItem(_items, dataItem);
      setBackupItems([..._items]);
      setGridItems([..._items]);
    });

    // @deprecated
    props.createItem && props.createItem(dataItem).then((results) => {
      setSaving(false);

      if (!results.data) {
        console.error("failed to create");
        return;
      }

      dataItem._localId = uuid();

      const _items = [...gridItems];

      dataItem._inEdit = false;
      dataItem.id = results.data.id;
      updateItem(_items, dataItem);
      setBackupItems([..._items]);
      setGridItems([..._items]);
    });
  };

  const update = (dataItem: T) => {
    const _items = [...gridItems];

    setSaving(true);
    props.updateDataItem && props.updateDataItem(dataItem).then((results) => {
      setSaving(false);

      if (!results.ok || results.inValid) {
        console.error("failed to update");
        props.updateDisablePlusIcon(true);      
        props.errorUpdatingItem && props.errorUpdatingItem({isError: !results.ok, isValid: results.inValid, message: results.message });
        return;
      }

      const updatedItem = { ...dataItem, _inEdit: false };

      updateItem(_items, updatedItem);

      setBackupItems(_items);
      setGridItems(_items);
    });

    // @deprecated
    props.updateItem && props.updateItem(dataItem).then(() => {
      setSaving(false);
      setBackupItems(_items);
      setGridItems(_items);

      const updatedItem = { ...dataItem, _inEdit: false };

      updateItem(_items, updatedItem);
    });
    // TODO on error, probably just run setHasErrors(true);
  };

  const updateItem = (itemsArray: IGridEditItem[], item: IGridEditItem) => {
    let index = itemsArray.findIndex((p) => p === item || (item._localId && p._localId === item._localId));
    if (index >= 0) {
      itemsArray[index] = { ...item };
    }
  };

  const cancel = (dataItem: IGridEditItem) => {
    setGridItems([...backupItems]);
  };

  const discard = (dataItem: IGridEditItem) => {
    setGridItems([...backupItems]);
  };

  const remove = (dataItem: T) => {
    if (!window.confirm("Are you sure you want to delete this?")) {
      return;
    }

    setSaving(true);
    
    props.deleteDataItem && props.deleteDataItem(dataItem.id).then((results) => {
    setSaving(false);

      if (!results.ok || results.inValid) {
        console.error("failed to delete");

        props.errorDeletingItem && props.errorDeletingItem({isError: !results.ok, isValid: results.inValid, message: results.message });
        return;
      }

      const _items = [...backupItems];
      removeItem(_items, dataItem);

      setBackupItems([..._items]);
      setGridItems([..._items]);
    });

    // @deprecated
    props.deleteItem && props.deleteItem(dataItem.id).then(() => {
      setSaving(false);

      const _items = [...backupItems];
      removeItem(_items, dataItem);

      setBackupItems([..._items]);
      setGridItems([..._items]);
    });
    // TODO on error, probably just run setHasErrors(true);
  };

  const itemChange = (event: any) => {
    const _items = gridItems.map((item) => {
      if (item._localId === event.dataItem._localId) {
        if (event.field == "dataItem") {
          return { ...item, ...event.dataItem };
        }
        return { ...item, [event.field]: event.value };
      } else {
        return item;
      }
    });

    setGridItems([..._items]);
  };

  const addNew = () => {
    const newDataItem = { _inEdit: true } as IGridEditItem;
    props.updateDisablePlusIcon(true);
    setGridItems([newDataItem, ...backupItems]);
  };

  const cancelCurrentChanges = () => {
    setGridItems([...backupItems]);
  };

  const removeItem = (itemsArray: IGridEditItem[], item: IGridEditItem) => {
    let index = itemsArray.findIndex((p) => p === item || (item._localId && p._localId === item._localId));
    if (index >= 0) {
      itemsArray.splice(index, 1);
    }
  };

const commandCell = CommandCell({
    onEdit: props.hideUpdateIcon ? null : enterEdit,
    onRemove: props.hideDeleteIcon ? null: remove, 

    onAdd: add,
    onDiscard: discard,
    disableAdd: props.disablePlusIcon,

    onUpdate: update,
    onCancel: cancel,

    editField: editField,
    saving: saving
  });

  const hasEditedItem = () => {
    const hasSomeInEdit = gridItems && gridItems.some((p) => p._inEdit);

    return hasSomeInEdit;
  };

  const onRowRender = (row: ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
    let propOverrides = {};

    if (props.dataItem._inEdit) {
      propOverrides = { ...propOverrides, class: `${row.props.className} in-edit-mode` };
    }

    return React.cloneElement(row, { ...propOverrides }, row.props.children);
  };

  const columnProps = [
    ...props.columns,
    ...[{ cell: commandCell, width: "106px", sortable: false, headerClassName: "no-sort" }]
  ];
  const showGridToolbar = (): boolean => {
    return props.gridToolbar == null || !isBoolean(props.gridToolbar) || (props.gridToolbar as boolean);
  };
  const gridToolbarIsBoolean = isBoolean(props.gridToolbar);
  const gridToolBarRender = (): JSX.Element => {
    let addButtonText: string;
    let cancelButtonText: string;
    let btnClass: string;

    if (props.gridToolbar && !gridToolbarIsBoolean) {
      addButtonText = (props.gridToolbar as IGridToolBarConfig).addButtonText;
      cancelButtonText = (props.gridToolbar as IGridToolBarConfig).cancelButtonText;
      btnClass = (props.gridToolbar as IGridToolBarConfig).btnClass;
    } else {
      addButtonText = "Add New";
      cancelButtonText = "Cancel current changes";
    }

    return (
      <>
        {addButtonText && <Button primary={true} icon={"plus"} disabled={hasEditedItem()} onClick={addNew}>
          {addButtonText}
        </Button>}
        { cancelButtonText && hasEditedItem() && (
          <Button title={cancelButtonText} className={btnClass} onClick={cancelCurrentChanges}>
            {cancelButtonText}
          </Button>
        )}
        { props.gridToolbar && (props.gridToolbar as IGridToolBarConfig).secondaryToolBarElements ? (props.gridToolbar as IGridToolBarConfig).secondaryToolBarElements : <> </>}
      </>
    );
  };
  const totalRecordsLabelText = (): string => {
    return gridToolbarIsBoolean
      ? "Total"
      : props.gridToolbar
        ? (props.gridToolbar as IGridToolBarConfig).totalRecordsText
        : "";
  };

  return (
    <div className="edit-grid-container">
      <AdvancedGrid
        showErrorState={hasErrors}
        showLoadingIndicator={loading}
        data={gridItems}
        dataFetch={fetchDataAsync}
        dataState={props.dataState}
        columns={columnProps}
        paging={false}
        editField={editField}
        onItemChange={itemChange}
        rowRender={onRowRender}
        noRecordsRender={props.noRecordsRender}
        noLoadOnMount={true}
        filteredDataOnly={props.filteredDataOnly}
        gridToolbarContent={showGridToolbar() && gridToolBarRender()}
        totalRecords={{ value: gridItems.length, label: totalRecordsLabelText() }}
        multiFieldFilterDelimiter={props.multiFieldFilterDelimiter}
      ></AdvancedGrid>
    </div>
  );
};

export default EditGrid;
