import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { Dialog } from '@progress/kendo-react-dialogs';
import CloseIcon from "@material-ui/icons/Close";
import { DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import UserFactorList from "./UserFactorList"
import "../../servicerequest/common/SupportRequestPopup.scss";

interface IProps {  
  userIdpId: string;
}

type Props = IProps

const UserFactorsPopup = (props: Props) => {
  const [open, setOpen] = useState(false);
  const width = 1100;

  const handleClick = async () => {
    setOpen(true);
  };

  const closePopup = function () {
    setOpen(false);
  };

  return (
    <>
      <div>    
      <Button variant="contained" color="primary" className="btn-primary" onClick={handleClick}>Reset MFA Options</Button>
      {open && <div className="support-popup">
        <Dialog aria-labelledby="customized-dialog-title" width={width} closeIcon={true}>
          <DialogTitle className="modal-title">{"MFA Factors"}
            <IconButton
              className="modal-close"
              aria-label="close"
              disabled={false}
              onClick={closePopup}
            ><CloseIcon /></IconButton>
          </DialogTitle>
          <DialogContent>
            <UserFactorList
                userIdpId={props.userIdpId}
            />            
          </DialogContent>
        </Dialog>
      </div>}
      </div>
    </>
  );
};

export default UserFactorsPopup;