import React, { PureComponent } from "react";
import {
  Upload,
  UploadFileInfo,
  UploadFileStatus,
  UploadOnProgressEvent,
  UploadProps,
  UploadOnAddEvent,
  UploadOnRemoveEvent
} from "@progress/kendo-react-upload";
import "./StandardFileUpload.scss";
import { ServiceBase } from "../../../services/ServiceBase";
import JobService, {
  IInitializeJobRequestData,
  IInitializeJobResponseData,
  IUpdateJobRequestData,
  IUpdateJobResponseData
} from "../../../services/JobService";
import { RequestOptions } from "https";
import JobsService from "../../../services/JobService";
import axios from "axios";

export const standardExtensionRestrictions = [
  ".doc",
  ".docx",
  ".jpeg",
  ".jpg",
  ".png",
  "svg",
  "xml",
  "txt",
  "gif",
  "msg",
  "zip",
  ".xls",
  ".xlsx",
  ".pdf"
];

export interface IFileInfo extends IUploadInitializeJobResponseData {
  file: UploadFileInfo;
}

interface IUploadFileInfo extends IFileInfo {
  fileGuid: string;
  uploadUri: string;
}

export interface IFileUploadUpdateJobFileInfo {
  correlationId: number;
  fileName: string;
  fileExtension: string;
  uid: string;
}
interface IFileUploadUpdateJobResponseData extends IUpdateJobResponseData {
  uploadFiles: Array<IUploadFileInfo>;
}

interface IUploadInitializeJobResponseData extends IInitializeJobResponseData {
  uploadUrl: string;
}

interface IStandardFileUploadProps<T extends IUpdateJobRequestData | IInitializeJobRequestData> extends UploadProps {
  requestData: T;
  onDataStateChanged?: (files: Array<IFileInfo>) => void;
  onUploadComplete?: () => void;
  preExistingFiles?: Array<IFileInfo>;
  hideSupportedFileTypesString?: boolean;
  maxCount?: number;
}

type Props<T extends IUpdateJobRequestData | IInitializeJobRequestData> = IStandardFileUploadProps<T>;

type State = {
  files: Array<IFileInfo>;
  totalFilesSize: number;
  maxFileSizeCount: number;
};

const isInitializeJobRequestData = (arg: any): arg is IInitializeJobRequestData => {
  return arg.jobType !== undefined && arg.jobId === undefined;
};

const convertBytesToMB = (mb: number) => mb / 1024 / 1024;

export class StandardFileUpload<T extends IUpdateJobRequestData | IInitializeJobRequestData> extends PureComponent<
  Props<T>,
  State
> {
  private scrollTarget: HTMLDivElement;
  supportedFileTypesString: string;
  maxFileSizeString: string;
  minFileSizeString: string;
  uploadInstance: Upload;

  constructor(props: Props<T>) {
    super(props);

    this.state = {
      files: props.preExistingFiles || new Array<IFileInfo>(),
      totalFilesSize: 0,
      maxFileSizeCount: 0
    };

    this.supportedFileTypesString =
      this.props.restrictions && this.props.restrictions.allowedExtensions
        ? this.props.restrictions.allowedExtensions
          .map((extension, index) => {
            return index === 0 ? `${extension}` : ` ${extension}`;
          })
          .toString()
        : null;

    //if we decide to support files larger than 999 MB, IE GIG size we will need to fix this to handle that.
    this.maxFileSizeString =
      this.props.restrictions && this.props.restrictions.maxFileSize
        ? `Maximum File Size: ${convertBytesToMB(this.props.restrictions.maxFileSize)}MB`
        : null;

    this.minFileSizeString =
      this.props.restrictions && this.props.restrictions.minFileSize
        ? `Minimum File Size: ${convertBytesToMB(this.props.restrictions.maxFileSize)}MB`
        : null;
  }

  componentDidUpdate() {
    if (this.state.totalFilesSize > this.props.restrictions.maxFileSize && this.state.maxFileSizeCount == 0) {
      this.scrollToBottom();
    }
  }

  async onProgress(event: UploadOnProgressEvent) {
    event.affectedFiles.forEach(async fileState => {
      if (fileState.progress === 100) {
        event.target.onUploadSuccess(fileState.uid);
        fileState.status = UploadFileStatus.Uploaded;
        this.updateFilesState({
          ...this.state.files.find(fileInfo => fileInfo.file.uid === fileState.uid),
          file: fileState
        });
      }

      //We internally set the failed state due to failure to get uploadUrl and JobId
      if (fileState.progress === 0 && fileState.status === UploadFileStatus.UploadFailed) {
        event.target && event.target.onUploadError(fileState.uid);
        this.updateFilesState({
          ...this.state.files.find(fileInfo => fileInfo.file.uid === fileState.uid),
          file: fileState
        });
      }
    });

    if (!event.newState.find(file => file.status !== UploadFileStatus.Uploaded)) {
      await ServiceBase.setTimeoutPromise(1000);

      if (this.props.onUploadComplete) {
        this.props.onUploadComplete();
      }
    }
  }

  async uploadFile(
    files: Array<UploadFileInfo>,
    options: { formData: FormData; requestOptions: RequestOptions },
    onProgress: (uid: string, event: ProgressEvent) => void
  ): Promise<any> {
    const initializeJob = isInitializeJobRequestData(this.props.requestData);
    let uploadUrl: string = null;
    let jobId: string = null;
    let preUploadErrorState = false;
    const fileUploader = async (file: UploadFileInfo) => {
      const fileReader = new FileReader();

      fileReader.onload = async (event: any) => {
        if (initializeJob) {
          const result = await JobService.initializeJob({ ...this.props.requestData, fileExtension: file.extension });

          preUploadErrorState = !result.ok;

          if (result.ok) {
            const data = result.data as IUploadInitializeJobResponseData;

            uploadUrl = data.uploadUrl;
            jobId = data.jobId;
          }
        }

        if (preUploadErrorState) {
          //We internally set the failed state due to failure during pre-upload steps
          file.status = UploadFileStatus.UploadFailed;
          this.onProgress({
            affectedFiles: [file],
            newState: files,
            target: this.uploadInstance
          });
        } else {
          this.setState({ ...this.state, files: [...this.state.files, { file, jobId, uploadUrl }] });

          axios.request({
            url: uploadUrl,
            method: "PUT",
            data: event.target.result,
            headers: {
              "x-ms-blob-type": "BlockBlob"
            },
            onUploadProgress: event => {
              onProgress(file.uid, event);
            }
          });
        }
      };

      fileReader.readAsArrayBuffer(file.getRawFile());
    };

    if (!initializeJob) {
      let corelationId = files.length;
      let fileInfoForUpdateJob: IFileUploadUpdateJobFileInfo[] = files.map((file: UploadFileInfo, index: number) => {
        corelationId = corelationId + 1;

        return {
          fileName: file.name,
          fileExtension: file.extension,
          correlationId: corelationId,
          uid: file.uid
        };
      });

      const requestData = { ...(this.props.requestData as IUpdateJobRequestData), files: [...fileInfoForUpdateJob] };
      const result = await JobsService.updateJobInfo(requestData);

      preUploadErrorState = !result.ok;

      if (result.ok) {
        const uploadFileResultList: IUploadFileInfo[] = (result.data as IFileUploadUpdateJobResponseData).uploadFiles;

        uploadUrl = uploadFileResultList[0].uploadUri;
        jobId = (result.data as IFileUploadUpdateJobResponseData).jobId;
      }
    }

    files.forEach(fileUploader);
  }

  async onAdd(event: UploadOnAddEvent) {
    this.onAddRemove(event.newState)

    if (this.props.onAdd) {
      this.props.onAdd(event);
    }
  }

  async onRemove(event: UploadOnRemoveEvent) {
    this.onAddRemove(event.newState)

    if (this.props.onRemove) {
      this.props.onRemove(event);
    }
  }

  private onAddRemove(newState: UploadFileInfo[]) {
    let totalFileSize = this.calculateTotalSize(newState);
    let maxSizeFile = newState.filter(x => x.size > this.props.restrictions.maxFileSize);//checking max size files.
    this.setState({ totalFilesSize: totalFileSize, maxFileSizeCount: maxSizeFile.length });
  }

  private calculateTotalSize(files: UploadFileInfo[]) {
    var totalSize = 0;
    files.map((file) => totalSize += file.size)
    return totalSize;
  }

  MaxFileSizeValidationMessage = (maxFileSize?: number) => {
    return (
      <div className="file-validation-message">
        You have exceeded maximum allowed size
        <span> {convertBytesToMB(maxFileSize)} MB.</span>
      </div>
    );
  };

  scrollToBottom = () => {
    const node: HTMLDivElement | null = this.scrollTarget; //get the element via ref

    if (node) { //current ref can be null, so we have to check
      node.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' }); //scroll to the targeted element
    }
  };

  render() {
    return (
      <div className="standard-file-upload-container">
        <Upload
          ref={(uploadInstance: Upload) => {
            this.uploadInstance = uploadInstance;
          }}
          showActionButtons={!(this.state.totalFilesSize > this.props.restrictions.maxFileSize)}
          batch={false}
          multiple={false}
          withCredentials={false}
          defaultFiles={[]}
          autoUpload={false}
          onProgress={this.onProgress.bind(this)}
          saveUrl={this.uploadFile.bind(this)}
          {...this.props}
          onAdd={this.onAdd.bind(this)}
          onRemove={this.onRemove.bind(this)}
        />
        {!this.props.hideSupportedFileTypesString &&
          this.supportedFileTypesString && (
            <div className="upload-info">Allowed File Types: {this.supportedFileTypesString}</div>
          )}
       
        {this.minFileSizeString && <div className="upload-info">{this.minFileSizeString}</div>}
        {this.maxFileSizeString && <div className="upload-info">{this.maxFileSizeString}</div>}
        {(this.state.totalFilesSize > this.props.restrictions.maxFileSize && this.state.maxFileSizeCount == 0) && (
          <div ref={(divElement: HTMLDivElement) => {
            this.scrollTarget = divElement
          }}>
            {this.MaxFileSizeValidationMessage(this.props.restrictions.maxFileSize)}
          </div>
        )}
        {this.props.maxCount &&
          <div className="max-allow-rec-count">Maximum record count is {this.props.maxCount}.</div>
        }
      </div>
    );
  }

  private updateFilesState(newFileState: IFileInfo) {
    const indexToUpdate = this.state.files.findIndex(fileInfo => fileInfo.file.uid === newFileState.file.uid);
    const files = [...this.state.files];

    files.splice(indexToUpdate, 1);
    files.splice(indexToUpdate, 0, newFileState);

    this.setState({ ...this.state, files });

    if (this.props.onDataStateChanged) {
      this.props.onDataStateChanged(files);
    }
  }
}
