import { GridPreferences, IGridPreference, IRequestPagePreferences, preferenceTypes } from "./Preferences";

export interface IGridPreferenceModel {
  tableKey: string;
  preferenceName: preferenceTypes;
  existingFields: Array<GridPreferences>;
  resizeColumnfield: string;
  fieldNewWidth: number | string;
}

export interface IRequestGridPreferenceModel extends IGridPreferenceModel {
  requestPage: string;
  selectedTabIndex?: number;
  existingRequestPagePrefs: IRequestPagePreferences;
  gridColumnPrefs?: IGridPreference;
}

export enum PreferenceTypeStringEnum {
  Application = "Application",
  UserListGridPreferences = "UserListGridPreferences",
  ApplicationListGridPreferences = "ApplicationListGridPreferences",
  ContextAdmin = "ContextAdmin",
  RelUserListGridPreferences = "RelUserListGridPreferences",
  PowerBiBookmarks = "PowerBiBookmarks",
  SupportTicketListGridPreferences = "SupportTicketListGridPreferences",
  UserAgreementPreferences = "UserAgreementPreferences",
  UserNotificationPreferences = "UserNotificationPreferences",
}