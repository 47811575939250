import React, { MouseEvent } from "react";
import { connect } from "react-redux";
import * as RoleStore from "../../../store/Role";
import { IApplicationState } from "../../../store";
import { Role, Permission } from "../../../models/Role";
import AdminService from "../../../services/AdminService";
import { formatDate } from "@telerik/kendo-intl";
import { NavLink } from "react-router-dom";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { GridColumnProps, GridFilterCellProps } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import CommonHelper from "../../common/utilities/CommonHelper";
import DropDownListItem from "../../../models/DropDownListItem";
import PermissionPopup from "./PermissionPopup";
import "./roles.scss";
import { AdvancedGrid, IGridParams } from "../../common/grid/AdvancedGrid";
import { FilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import * as SessionStore from "../../../store/Session";
import { DropDownFilter } from "../../common/grid/filters/DropDownFilter";
import { Checkbox } from "@progress/kendo-react-inputs";

const GridConfigurations = {
  PageSize: 100,
  OrderBy: "roleName",
  Ascending: "asc",
  Descending: "desc"
};

interface IProps {
  history: any;
}

interface IRolesFetchResult {
  totalRoles: number;
  roles: Array<Role>;
}

type Props = IProps & RoleStore.IRoleState & 
  typeof RoleStore.actionCreators &
  SessionStore.ISessionState;

type State = {
  dataState: IGridParams;
  isPermissionSelectorOpen: boolean;
  selectedRoleDetails: Role;
  permissionToBeSaved: Permission[];
  fetchingRoles: boolean;
  fetchResults: IRolesFetchResult;
  hasError: boolean;
};

class RolesList extends React.PureComponent<Props, State> {
  columns: Array<GridColumnProps>;

  constructor(props: Props) {
    super(props);
    this.state = {
      dataState: {
        skip: 0,
        take: GridConfigurations.PageSize,     
        sort: [{ field: GridConfigurations.OrderBy, dir: "asc" }]
      },
      isPermissionSelectorOpen: false,
      selectedRoleDetails: null,
      permissionToBeSaved: [],
      fetchingRoles: false,
      fetchResults: {
        totalRoles: 0,
        roles: new Array<Role>()
      },
      hasError: false
    };

    this.columns = new Array<GridColumnProps>(
      {
        field: "roleName",
        title: "Role Name",
        cell: this.props.sessionData.permissions.has("EpiqAdminGetRole")? this.linkedNameCustomCell : this.unLinkedNameCustomCell,
        filterable: true,
        filter: "text"
      },
      {
        field: "epiqOnly",
        title: "EPIQ Admin Only",
        cell: this.epiqAdminOnly,
        filterCell: (props: GridFilterCellProps) => (
          <DropDownFilter
            {...props}
            data={[{ name: "Epiq Admin", id: 1 }, { name: "Non-Epiq Admin", id: 0 }]}
            textField="name"
            defaultSelectedOption={null}
          />
        ),
        filterable: true,
        filter: "text"
      },
      {
        field: "createdDate",
        title: "DATE CREATED",
        cell: this.startDateCustomCell,
        format: "{0:g}",
        filterable: true,
        filter: "date"
      },
      {
        field: "description",
        title: "Description",
        cell: this.descriptionCustomeCell,
        filterable: true,
        filter: "text"
      }    
    );
  }

  async getRoles(dataState: IGridParams) {
    let isCreatedDateFilterExists = false;
    let createdDateValue: Date;
    const filters = dataState.filters
      ? dataState.filters.map(compositeFilter => {

        const epiqAdminOnlyFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "epiqOnly"
        ) as FilterDescriptor;

        if (epiqAdminOnlyFilter) {
          const epiqAdminOnlyFilterValue = epiqAdminOnlyFilter.value
            ? epiqAdminOnlyFilter.value.id == 0 || epiqAdminOnlyFilter.value.id == 1
              ? epiqAdminOnlyFilter.value.id == 0
                ? false
                : true
              : null
            : false;

          return {
            ...compositeFilter,
            filters: [
              {
                ...epiqAdminOnlyFilter,
                field: "epiqOnly",
                operator: "eq",
                value: epiqAdminOnlyFilterValue
              }
            ]
          };
        }

        const createdDateFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "createdDate"
        ) as FilterDescriptor;

        if (createdDateFilter) {
          const dateFilterValue = createdDateFilter.value;

          console.log(dateFilterValue);

          if (!isNaN(new Date(dateFilterValue).getFullYear()) && new Date(dateFilterValue).getFullYear() > 1970) {
            createdDateFilter.operator = 'eq';
          }

          createdDateValue = typeof dateFilterValue === "string" ? new Date("01/01/1901") : ((createdDateFilter.operator === 'isnull' || createdDateFilter.operator === 'isnotnull') ? null : new Date(dateFilterValue));

          if (createdDateFilter.operator === 'isnull' || createdDateFilter.operator === 'isnotnull') {
            return {
              ...compositeFilter,
              filters: [
                {
                  ...createdDateFilter,
                  field: "createdDate",
                  operator: typeof dateFilterValue === "string" && createdDateFilter.operator === 'isnotnull' ? "eq" : createdDateFilter.operator,
                  value: createdDateValue
                }
              ]
            };
          }

          isCreatedDateFilterExists = true;

          return {
            ...compositeFilter,
            filters: [
              {
                ...createdDateFilter,
                field: "createdDate",
                operator: "gte",
                value: new Date(createdDateValue.toUTCString())
              }
            ]
          };
        }

        return compositeFilter;
      })
      : [];

    if (isCreatedDateFilterExists) {
      filters.push({
        logic: "and",
        filters: [
          {
            field: "createdDate",
            operator: "lt",
            value: new Date(new Date(createdDateValue.setUTCDate(createdDateValue.getUTCDate() + 1)).toUTCString())
          }
        ]
      });
    }

    let reverseSortDescriptors: SortDescriptor[] = this.updateEpiqOnlySortDirection(dataState);

    this.setState({ ...this.state, fetchingRoles: true }, async () => {
      const result = await AdminService.getRoleList({ ...dataState, filters: filters, sort: reverseSortDescriptors });

      let updateSortDescriptors: SortDescriptor[] = this.updateEpiqOnlySortDirection(dataState);
      this.setState({ ...this.state, dataState: { ...dataState, sort: updateSortDescriptors } });

      this.setState({
        ...this.state,
        fetchingRoles: false,
        hasError: !result.ok,
        fetchResults: {
          ...this.state.fetchResults,
          roles: result.ok ? result.data.results : new Array<Role>(),
          totalRoles: result.ok && result.data.results ? (result.data.results as Array<Role>).length : 0,
        }
      });
    });
  }

  filterElements(element: any) {
    if (element.tagName === "EM") {
      return true;
    }

    return false;
  }

  addSelectedListItems(selectedPermissions: DropDownListItem[]) {
    const permissions: Permission[] = selectedPermissions.map((p) => {
      return {
        id: p.id,
        permissionName: p.name,
        ...p.restProps
      };
    });

    this.setState({ permissionToBeSaved: permissions });
  }

  handleCreateRoleClick = () => {
    this.props.history.push("/administration/roleDetails/0");
  };

  gridToolBarRender(): JSX.Element {
    return this.props.sessionData.permissions.has("EpiqAdminCreateRole") && (
      <Button className="add-role-btn" icon={"plus"} primary={true} onClick={this.handleCreateRoleClick.bind(this)}>
        Role
      </Button>
    ); 
  }

  linkedNameCustomCell(props: any) {
    const roleName = props.dataItem.roleName;
    return (
      <td>
        <div className="role-name">
          <NavLink to={`/administration/roleDetails/${props.dataItem.id}`} >
            <em title={roleName}>{roleName}</em>
          </NavLink>
        </div>
      </td>
    );
  }

  unLinkedNameCustomCell(props: any) {
    const roleName = props.dataItem.roleName;
    return (
      <td>
        <div className="role-name">
          <em title={roleName}>{roleName}</em>
        </div>
      </td>
    );
  }

  startDateCustomCell(props: any) {
    let date = "-";
    const startDate = props.dataItem.createdDate;

    if (startDate) {
      let localDateTime = CommonHelper.convertUTCDateToLocalDate(startDate);
      date = formatDate(localDateTime, "g");
    }

    return <td>{date}</td>;
  }

  descriptionCustomeCell(props: any) {
    const description = props.dataItem.description;
    return <td className="description">{description ? description : "-"}</td>;
  }

  epiqAdminOnly(props: any) {
    return <td>{props.dataItem.epiqOnly ? "Epiq Admin" : "Non-Epiq Admin"}</td>;
  };

  updateEpiqOnlySortDirection = (dataState: IGridParams) => {
    const sort: SortDescriptor[] = dataState.sort ? [...dataState.sort] : [];
    return sort.map((item, index) => {

      if (item.field === "epiqOnly") {
        item.dir = item.dir === "asc" ? "desc" : "asc"
      }

      return item;
    });
  }

  render() {
    const {
      fetchingRoles,
      fetchResults,
      dataState,
      isPermissionSelectorOpen,
      selectedRoleDetails,
      permissionToBeSaved,
      hasError
    } = this.state;

    return (
      <div className="roles-list">
        <>
          <div>
            <Tooltip openDelay={2} position="right" filter={this.filterElements} className="rool-tool-tip">
              <AdvancedGrid
                showErrorState={hasError}
                showLoadingIndicator={fetchingRoles}
                sortable={{ mode: "multiple" }}
                data={fetchResults.roles}
                dataFetch={this.getRoles.bind(this)}
                dataState={dataState}
                columns={this.columns}
                paging={false}
                totalRecords={{ value: fetchResults.totalRoles, label: "Roles" }}
                noRecordsRender={<p>No roles found.</p>}
                noLoadOnMount={true}
                filteredDataOnly={<p>Please filter to search for roles.</p>}
                multiFieldFilterDelimiter="|"
                filterOperators={{
                  text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
                  date: [{ text: "grid.filterIsNotNullOperator", operator: "isnotnull" }, { text: "grid.filterIsNullOperator", operator: "isnull" }]
                }}
                gridToolbarContent={this.gridToolBarRender()}
                moreRecordsAvailableMessage= "Too many roles to display. Adjust filters to refine results"
              />
            </Tooltip>
          </div>
        </>
      </div>
    );
  }
}

export default connect((state: IApplicationState) => ({ ...state.roleState, ...state.sessionState }), {
  ...RoleStore.actionCreators
})(RolesList as any);
