import * as React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../store";
import * as HeaderStore from "../../../store/Header";
import * as WarningMessageStore from "../../../store/WarningMessage";
import Paper from "@material-ui/core/Paper";
import { Role as RoleModel, Permission, UpsertRoleModel } from "../../../models/Role";
import { ShowEpiqOnlyControls } from "../../../models/Enums";
import CircularProgress from "@material-ui/core/CircularProgress";
import CommonHelper from "../../common/utilities/CommonHelper";
import * as RoleStore from "../../../store/Role";
import PermissionsList from "../roles/PermissionsList";
import * as SessionStore from "../../../store/Session";
import { IGridParams } from "../../common/grid/AdvancedGrid";
import Role from "./Role";
import { differenceWith } from "lodash";
import AdminService from "../../../services/AdminService";
import NotificationService from "../../../services/NotificationService";
import DialogBox from "../../common/DialogBox";

interface IProps {
  match?: any;
  history: any;
}

enum Mode {
  Edit,
  Create,
  View
}

const RoleConfigurations = {
  MinimumCharacters: 3
};

type Props = IProps &
  HeaderStore.IHeaderState &
  typeof HeaderStore.actionCreators &
  WarningMessageStore.IWarningMessageState &
  typeof WarningMessageStore.actionCreators &
  RoleStore.IRoleState &
  typeof RoleStore.actionCreators &
  SessionStore.ISessionState;

type State = {
  mode: Mode;
  updateModel: UpsertRoleModel;
  initializing: boolean;
  isFormValid: boolean;
  selectedPermissions: Permission[];
  hasEpiqOnlyError: boolean;
  isRoleAlreadyExist: boolean;
  isUpdateSaved: boolean;
  roleDetails: RoleModel;
  savedRoleDetails: RoleModel;
  canDelete: boolean;
  canReactivate: boolean;
  openDeleteConfirmPopup: boolean;
  openRoleReactivatePopup: boolean;
  delRoleDetails: RoleModel;
};

class RoleDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const mode = Number(this.props.match.params.roleId) > 0 ? Mode.Edit : Mode.Create;

    this.state = {
      mode,
      updateModel: new UpsertRoleModel(),
      initializing: true,
      isFormValid: mode !== Mode.Create,
      selectedPermissions: [],
      hasEpiqOnlyError: false,
      isRoleAlreadyExist: false,
      isUpdateSaved: false,
      roleDetails: new RoleModel(),
      savedRoleDetails: new RoleModel(),
      canDelete: false,
      canReactivate: false,
      openDeleteConfirmPopup: false,
      openRoleReactivatePopup: false,
      delRoleDetails: new RoleModel()
    };
  }

  async componentDidMount() {
    await this.props.setHeaderButtons(this.getBtnsList(true), "", "/administration/roles", "Back to role list");
    await this.ensureDataFetched();
  }

  private async ensureDataFetched() {
    if (this.state.mode === Mode.Edit) {
      const roleId: number = Number(this.props.match.params.roleId);

      const roleDetailsResult = await AdminService.getRoleDetails(roleId);

      await this.props.getRoleDetails(roleId);
      await this.getRoleBasedPermissions(roleId);

      const { permissionsToBeSaved, savedRolePermissions, hasError } = this.props;

      if (!roleDetailsResult.ok && hasError) {
        await this.props.setHeaderButtons(null);
        await this.props.saveUnsavedChanges(null, false);
        await this.props.addPermissionsToRole([]);
        this.props.history.push("/administration/roles");
      } else {
        const totalPermissions = [...permissionsToBeSaved, ...savedRolePermissions];
        const roleDetails = roleDetailsResult.data ? roleDetailsResult.data : new RoleModel();
        const initialRoleDetails = {
          id: roleDetails.id,
          roleName: roleDetails.roleName,
          createdDate: roleDetails.createdDate,
          createdBy: roleDetails.createdBy,
          lastModifiedDate: roleDetails.lastModifiedDate,
          lastModifiedBy: roleDetails.lastModifiedBy,
          description: roleDetails.description,
          epiqOnly: roleDetails.epiqOnly
        } as RoleModel;
        let updateModel = CommonHelper.convertPropsToType(roleDetails, UpsertRoleModel);
        updateModel.permissions = totalPermissions.map(p => p.id);
        updateModel.roleId = roleDetails.id;
        this.props.addPermissionsToRole(totalPermissions);
        this.setState({
          updateModel: updateModel,
          roleDetails: initialRoleDetails,
          savedRoleDetails: initialRoleDetails,
          initializing: false,
          selectedPermissions: savedRolePermissions
        });
      }
    }
    else {
      this.setState({ initializing: false });
    }

    this.props.saveUnsavedChanges(JSON.stringify(this.state.savedRoleDetails), false);
  }

  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
    if (prevState !== this.state) {
      const roleDetailsIsDirty = this.roleDetailsIsDirty();
      const permissionsListIsDirty = this.permissionsListIsDirty();
      this.props.updateUnsavedChanges(roleDetailsIsDirty || permissionsListIsDirty);
    }
  }

  getRoleBasedPermissions = async (roleId: number) => {
    const params: IGridParams = {
      skip: 0,
      take: 100,
      sort: [{ field: "PermissionName", dir: "asc" }]
    };
    await this.props.getPermissionsByRoleId(roleId, params);
  };

  getBtnsList: any = (isSaveDisabled: boolean, isSaving: boolean) => {
    const buttons: any = [
      {
        buttonLabel: "Cancel",
        type: "button",
        handleClick: this.handleCancelClick
      },

      {
        buttonLabel: this.state.mode === Mode.Create ? "Create" : "Save",
        type: "button",
        disabled: isSaveDisabled,
        handleClick: this.handleSaveClick,
        color: "primary",
        isSaving: isSaving
      }
    ];
    return buttons;
  };

  handleCancelClick = () => {
    this.props.history.push("/administration/roles");
  };

  handleSaveClick = async () => {
    const requestModel = this.state.updateModel;
    const role = { ...requestModel, permissions: this.props.permissionsToBeSaved.map(p => p.id), roleId: requestModel.id };
    await this.props.setHeaderButtons(this.getBtnsList(true, true), "", "/administration/roles", "Back to role list");
    const response = await AdminService.getRoleDetailsByRoleName(requestModel.roleName);

    if (response.ok) {
      if (response.data && response.data.isDeleted) {
        this.setState({ canReactivate: this.state.mode === Mode.Create ? true : false, openRoleReactivatePopup: true, delRoleDetails: response.data });
        return;
      }
    }

    if (requestModel.id == 0) {
      const result = await AdminService.createRole(role);
      
      if (result.ok) {
        const errorMessage = result.data && result.data.inValid === true && result.data.message ? result.data.message : "";

        if (errorMessage && errorMessage.toLocaleLowerCase() === "role already exists.") {
          this.setState({ isRoleAlreadyExist: true });
          await this.props.setHeaderButtons(this.getBtnsList(true, false), "", "/administration/roles", "Back to role list");
        }
        else {          
          this.setState({ savedRoleDetails: { ...this.state.savedRoleDetails, roleName: role.roleName, epiqOnly: role.epiqOnly, description: role.description } });
          NotificationService.showSuccessToast(`${role.roleName} Created.`);
          this.props.saveUnsavedChanges(null, false);
          this.props.history.push("/administration/roles");
        }
      }
      else {
        NotificationService.showErrorToast("Something went wrong. Role was not created.");
      }
    }
    else {
      const result = await AdminService.updateRole(role);
      if (result.ok) {
        const errorMessage = result.data && result.data.inValid === true && result.data.message ? result.data.message : "";

        if (errorMessage && errorMessage.toLocaleLowerCase() === "role already exists.") {
          this.setState({ isRoleAlreadyExist: true });
        }
        else {
          this.setState({ isUpdateSaved: true, savedRoleDetails: role });
          await this.props.saveUnsavedChanges(JSON.stringify(this.state.roleDetails), false);
          NotificationService.showSuccessToast("Update saved.");
        }

        await this.props.setHeaderButtons(this.getBtnsList(true, false), "", "/administration/roles", "Back to role list");
      }
      else {
        NotificationService.showSuccessToast("Something went wrong.Update not saved.");
      }
    }
    
    if (!this.props.isInvalid) {
      await this.getRoleBasedPermissions(requestModel.id);
    }
  };

  updateRoleDetails = (role: RoleModel) => {
    const updateModel = CommonHelper.convertPropsToType(role, UpsertRoleModel);
    this.setState({ updateModel: updateModel, roleDetails: role });
  };

  componentWillUnmount() {
    this.props.setHeaderButtons(null);
    this.props.saveUnsavedChanges(null, false);
    this.props.addPermissionsToRole([]);
  }

  updateSelectedPermisions = (permissions: Permission[], isFilterEnabled: boolean, isPermissionRemoved?: boolean) => {
    const hasEpiqError = this.validateEpiqAdminPermissions(permissions, this.state.updateModel.epiqOnly);

    if (isPermissionRemoved || !isFilterEnabled) {
      this.setState({ selectedPermissions: permissions, hasEpiqOnlyError: hasEpiqError }, this.toggleSave);
    }
    else if (isFilterEnabled) {
      this.setState({ hasEpiqOnlyError: hasEpiqError, selectedPermissions: this.props.savedRolePermissions}, this.toggleSave);
    }
    
    this.props.addPermissionsToRole(permissions);
  };

  handleRoleEdited(role: UpsertRoleModel, isValid: boolean) {
    const newRoleName = role.roleName;
    const editedRoleDetails: UpsertRoleModel = {
      ...this.state.updateModel,
      roleName: newRoleName,
      description: role.description,
      epiqOnly: role.epiqOnly
    };
    const isRoleAlreadyExist = this.state.updateModel.roleName.toLowerCase() === newRoleName.toLowerCase() && this.state.isRoleAlreadyExist ? this.state.isRoleAlreadyExist : false;
    const hasEpiqError = this.validateEpiqAdminPermissions(this.state.selectedPermissions, role.epiqOnly);

    this.setState({
      ...this.state,
      updateModel: editedRoleDetails,
      isFormValid: isValid,
      hasEpiqOnlyError: hasEpiqError,
      roleDetails: {
        id: role.id,
        roleName: role.roleName,
        createdDate: role.createdDate,
        createdBy: role.createdBy,
        lastModifiedDate: role.lastModifiedDate,
        lastModifiedBy: role.lastModifiedBy,
        description: role.description,
        epiqOnly: role.epiqOnly
      } as RoleModel,
      isRoleAlreadyExist
    }, this.toggleSave);
  }

  handleRoleEditCancelled = (role: UpsertRoleModel) => {
    this.setState({
      ...this.state, updateModel: role,
      roleDetails: {
      id: role.id,
      roleName: role.roleName,
      createdDate: role.createdDate,
      createdBy: role.createdBy,
      lastModifiedDate: role.lastModifiedDate,
      lastModifiedBy: role.lastModifiedBy,
      description: role.description,
      epiqOnly: role.epiqOnly
      } as RoleModel
    }, this.toggleSave);
  }

  roleDetailsIsDirty() {
    return this.props.initialState !== JSON.stringify(this.state.roleDetails as RoleModel);
  }

 permissionsListIsDirty() {
    if (this.state.selectedPermissions.length !== this.props.savedRolePermissions.length) return true;

   const areEqual = differenceWith(this.state.selectedPermissions, this.props.savedRolePermissions, (value1, value2) => {
      return value1.permissionName === value2.permissionName;
    });

    return areEqual.length > 0;
  }

  private toggleSave() {
    const dirtyPermissionsList = this.permissionsListIsDirty();
    const canSave = 
      (this.roleDetailsIsDirty() && this.isFormValidationsPassed()) ||
      (!this.roleDetailsIsDirty() && this.isFormValidationsPassed() && dirtyPermissionsList);

    this.props.setHeaderButtons(this.getBtnsList(!canSave), "", "/administration/roles", "Back to roles list");
  }

  handleClickDelete = async () => {
    const response = await AdminService.canDeleteRole(this.props.roleDetails.id);
   
    if (response.ok) {
      this.setState({
        canDelete: response.data,
        openDeleteConfirmPopup: true,
      });
    }
  };

  toggleDialogEvent = async (event: React.MouseEvent<HTMLElement>) => {
    if (event.currentTarget.textContent.toLocaleLowerCase() === "confirm") {
      this.setState({ openDeleteConfirmPopup: false, canDelete: false });
      let roleDetails = this.props.roleDetails;
      const result = await AdminService.deleteRole(roleDetails);
      if (result.ok) {
        NotificationService.showSuccessToast(`Deleted role: ${roleDetails.roleName}.`);
        this.props.saveUnsavedChanges(null, false);
        this.props.history.push("/administration/roles");
      }
      else {
        NotificationService.showErrorToast(`${roleDetails.roleName} role deletion failed.`);
      }
    }
    else if (event.currentTarget.textContent.toLocaleLowerCase() === "cancel" || event.currentTarget.textContent.toLocaleLowerCase() === "ok") {
      this.setState({ openDeleteConfirmPopup: false, canDelete: false });
      this.toggleSave();
    }
  };

  toggleReactivateDialogEvent = async (event: React.MouseEvent<HTMLElement>) => {
    if (event.currentTarget.textContent.toLocaleLowerCase() === "reactivate") {
      this.setState({ openRoleReactivatePopup: false });
      const result = await AdminService.reactivateRole(this.state.delRoleDetails);
      if (result.ok) {
        NotificationService.showSuccessToast(`Reactivated: ${this.state.updateModel.roleName}. `);
        this.props.saveUnsavedChanges(null, false);
        this.props.history.push("/administration/roles");
      }
      else {
        NotificationService.showErrorToast(`${this.state.updateModel.roleName} role reactivation failed.`);
      }
    }
    else if (event.currentTarget.textContent.toLocaleLowerCase() === "cancel" || event.currentTarget.textContent.toLocaleLowerCase() === "ok") {
      this.setState({ openRoleReactivatePopup: false });
      this.toggleSave();
    }
  };

  private validateEpiqAdminPermissions = (permissions: Permission[], epiqOnly: boolean)  => {
    const count = permissions.filter(item => item.epiqOnly).length;
    return (!epiqOnly && count > 0);
  }

  isFormValidationsPassed = () => {
    const {
      updateModel,
      isFormValid,
      hasEpiqOnlyError,
      isRoleAlreadyExist
    } = this.state;
    const isGroupNameValidationPassed =
      updateModel.roleName && isRoleAlreadyExist === false
        ? updateModel.roleName.length >= RoleConfigurations.MinimumCharacters
        : false;

    return (
      (isGroupNameValidationPassed && isFormValid && !hasEpiqOnlyError)
    );
  };

  resetViewMode = (isRoleInfoInEditMode: boolean) => {
    this.setState({ isUpdateSaved: isRoleInfoInEditMode });
  };

  render() {
    const { permissionsToBeSaved } = this.props;

    let showEpiqOnlyAdmin;
    if (this.props.sessionData.superAdmin) {
      showEpiqOnlyAdmin = ShowEpiqOnlyControls.ENABLE;
    } else if (this.props.sessionData.permissions.has("EpiqAdminUpdateRole")
      || this.props.sessionData.permissions.has("EpiqAdminCreateRole")) {
      showEpiqOnlyAdmin = ShowEpiqOnlyControls.SHOW
    } else {
      showEpiqOnlyAdmin = ShowEpiqOnlyControls.HIDE
    }

    let roleInfo;
    let permissions;
    if ((this.state.mode !== Mode.Create && this.props.isLoading) || this.state.initializing) {
      roleInfo = (
        <Paper className="role-grid-wrapper">
          <div className="role-loader">
            <CircularProgress />
          </div>
        </Paper>
      );
    }
    else if (this.props.hasError) {
      roleInfo = (
        <Paper className="role-details-wrapper">
          <div className="no-roles">
            <p> Oops! Something went wrong. </p>
            <p>Please check your network connection and refresh the page.</p>
          </div>
        </Paper>
      );
    }
    else {
      roleInfo = (
        <Role
          role={this.state.mode === Mode.Edit ? this.state.updateModel : new RoleModel()}
          savedRoleDetails={this.state.savedRoleDetails}
          createRole={this.state.mode === Mode.Create}
          onRoleEdited={this.handleRoleEdited.bind(this)}
          onRoleEditCancelled={this.handleRoleEditCancelled.bind(this)}
          permissions={this.props.sessionData.permissions}
          showEpiqOnlyAdmin={showEpiqOnlyAdmin}
          hasEpiqOnlyError={this.state.hasEpiqOnlyError}
          isRoleNameExist={this.state.isRoleAlreadyExist}
          isUpdateSaved={this.state.isUpdateSaved}
          resetViewMode={this.resetViewMode}
          onRoleDelete={this.handleClickDelete}
        />
      );
      permissions = <PermissionsList
        roleDetails={this.state.updateModel}
        savedPermissions={permissionsToBeSaved}
        savedDBPermissions={this.props.savedRolePermissions}
        updateSelectedPermisions={this.updateSelectedPermisions}
        epiqOnly={this.state.updateModel.epiqOnly}
        permissions={this.props.sessionData.permissions}
        isCreate={this.state.mode === Mode.Create}
      />
    }
    const confirmDeletedialog = (
      <div className="warning">
        <DialogBox
          title={this.state.canDelete ? "Confirm deletion" : "Clear role"}
          content={this.state.canDelete ? `This action deletes role: '${this.state.savedRoleDetails.roleName}'. Confirm that you want to delete this role.` : "Cannot delete an active role. Remove all permissions and assignments prior to deletion."}
          okButtonLabelText={this.state.canDelete ? "Cancel" : ""}
          cancelButtonLabelText={this.state.canDelete ? "Confirm" : "OK"}
          visible={this.state.openDeleteConfirmPopup}
          toggleDialogEvent={this.toggleDialogEvent.bind(this)}
        /></div>);
    const reactivateDialog = (
      <div className="warning">
        <DialogBox
          title={this.state.canReactivate ? "Reactivate role" : "Name exists"}
          content={this.state.canReactivate ? `Role name '${this.state.updateModel.roleName}' was previously deleted. Do you want to reactivate this deleted role?` :
            `Because role: '${this.state.updateModel.roleName}' was previously deleted, it cannot be reused when editing. Supply a different name`}
          okButtonLabelText={this.state.canReactivate ? "Cancel" : ""}
          cancelButtonLabelText={this.state.canReactivate ? "Reactivate" : "Ok"}
          visible={this.state.openRoleReactivatePopup}
          toggleDialogEvent={this.toggleReactivateDialogEvent.bind(this)}
        /></div>);

    return (
      <Paper className="role-detais-wrapper">
          {roleInfo}
        {permissions}
        {confirmDeletedialog}
        {reactivateDialog}
      </Paper>
    );
  }
}

export default connect(
  (state: IApplicationState) => ({ ...state.headerState, ...state.warningMessageState, ...state.roleState, ...state.sessionState }),
  {
    ...HeaderStore.actionCreators,
    ...WarningMessageStore.actionCreators,
    ...RoleStore.actionCreators
  }
)(RoleDetails as any);
