import React, { useState, useEffect } from "react";
import { AdvancedGrid } from "../../../common/grid/AdvancedGrid";
import AdvanceGridParams, {
  IResourceGroupAutoModel,
  IResourceParams,
  IResourceTypeDetail,
  IResourceTypeField
} from "../../../../models/ResourceModels";
import ResourceService from "../../../../services/ResourceService";
import NotificationService from "../../../../services/NotificationService";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
  ListBox,
  ListBoxToolbar,
  processListBoxData,
  processListBoxDragAndDrop,
  ListBoxToolbarClickEvent,
  ListBoxDragEvent,
  ListBoxItemClickEvent
} from "@progress/kendo-react-listbox";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import { Grid, GridColumnProps, GridFilterChangeEvent } from "@progress/kendo-react-grid";
import { Button, Grid as MaterialGrid } from "@material-ui/core";
import "./ResourceGroupAutomation.scss";
import { ColumnFromField } from "./ColumnHelper";

const SELECTED_FIELD = "selected";

interface IProps {
  match?: any;
  onFilterApplied?: (change: IResourceGroupAutoModel) => void;
  onEditChange?: (change: IResourceGroupAutoModel) => void;
  automationModel?: IResourceGroupAutoModel;
}

interface ISelectResourceTypeField extends IResourceTypeField, GridColumnProps {
  [SELECTED_FIELD]: boolean;
  field: string;
}

const GridConfigurations = {
  PageSize: 100,
  OrderBy: "Name",
  Ascending: "asc",
  Descending: "desc"
};

interface IRefreshGridParams extends IResourceParams {
  refreshToggle: boolean;
}

type Props = IProps;

const ResourceGroupAutomation = (props: Props) => {
  const startingGridParams: IRefreshGridParams = {
    skip: 0,
    take: GridConfigurations.PageSize,
    sort: [{ field: GridConfigurations.OrderBy, dir: "asc" }],
    typeCode: props.automationModel && props.automationModel.resourceTypeCode ? props.automationModel.resourceTypeCode : "ediscoveryproject", // our default
    refreshToggle: false
  };
   const nameCol = {
    field: "displayName",
    title: "name",
    filterable: false,
    sortable: false
    //columnMenu: ColumnMenu,
  };

  const hiddenAutomationFields = {
    "epiqaccess": ["Description"],
    "file": ["Description"],
    "salescontractnumber": ["Contract", "Contract Description", "Customer Name"]
  } as Record<string, Array<string>>;

  const [hasError, setHasError] = useState(false);
  const [isGridLoading, setIsGridLoading] = useState(false);
  const [dataState, setDataState] = useState(startingGridParams);
  const [isDirty, setIsDirty] = useState(props.automationModel ? props.automationModel.isDirty : false);
  const [fieldSelections, setFieldSelections] = useState({
    availableFields: [nameCol],
    columns: [nameCol],
    draggedItem: {}
  } as any);
  const [resourceTypes, setResourceTypes] = useState(null as Array<IResourceTypeDetail>);
  const [selectedResourceType, setSelectedResourceType] = useState(null as IResourceTypeDetail);
  const [fetchResults, setFetchResults] = useState<Array<AdvanceGridParams>>(new Array<AdvanceGridParams>());

  const reloadData = () => {

    const filter = props.automationModel ? props.automationModel.filter : null;

    const newState: IRefreshGridParams = {
      ...dataState,
      refreshToggle: !dataState.refreshToggle,
      filter,
      filters: [filter]
    };

    myGrid.resetGridState(newState, false, true);
  };

  const applyChanges = function () {
    if (!props.onFilterApplied || !props.automationModel || !props.automationModel.filter) {
      return;
    }

    props.onFilterApplied({
      filter: props.automationModel.filter,
      resourceTypeCode: dataState.typeCode,
      resourceTypeId: selectedResourceType.id,
      usedFields: fieldSelections.columns.map((a: { title: string }) => a.title),
      isDirty: false,
      displayName: selectedResourceType.displayName,
      requireApproval: props.automationModel.requireApproval
    });
    setIsDirty(false);
    reloadData();
  };

  let myGrid: AdvancedGrid<AdvanceGridParams, IRefreshGridParams>;

  const gridToolBarRender = () => {
    const options = resourceTypes
      ? resourceTypes.map(rt => {
        return { id: rt.code, name: rt.displayName };
      })
      : [{ id: null, name: "(Select One)" }];

    const selected = options.find(o => o.id == dataState.typeCode);

    return (
      <>
        {/* <GridRefreshButton onRefreshGridClick={reloadData} /> */}
        <div>
          <div className="resource-group-auto-field-selection-container">
            <MaterialGrid container>
              <MaterialGrid item sm={4} md={4}>
                <div className="resource-selector">
                  <h6>Automaticallly Add Resource Type</h6>
                  <DropDownList
                    name="resourceType"
                    data={options}
                    key="id"
                    textField="name"
                    dataItemKey="id"
                    value={{ id: dataState.typeCode, name: selected ? selected.name : null }}
                    disabled={!resourceTypes}
                    onChange={code => {
                      selectResourceType(code.value.id, resourceTypes);
                      myGrid.resetGridState({ ...dataState, typeCode: code.value.id }, true);
                    }}
                  />
                </div>
              </MaterialGrid>
              <MaterialGrid item sm={8} md={8}>
                <MaterialGrid container>
                  <MaterialGrid item sm={7}>
                    <div className="col k-pr-2">
                      <h6>Available Fields</h6>
                      <ListBox
                        style={{ height: 200, width: "100%" }}
                        data={fieldSelections.availableFields}
                        textField="title"
                        selectedField={SELECTED_FIELD}
                        onItemClick={(e: ListBoxItemClickEvent) => handleItemClick(e, "availableFields", "columns")}
                        onDragStart={handleDragStart}
                        onDrop={handleDrop}
                        toolbar={() => {
                          return (
                            <ListBoxToolbar
                              tools={["transferTo", "transferFrom", "transferAllTo", "transferAllFrom"]}
                              data={fieldSelections.availableFields}
                              dataConnected={fieldSelections.columns}
                              onToolClick={handleToolBarClick}
                            />
                          );
                        }}
                      />
                    </div>
                  </MaterialGrid>
                  <MaterialGrid item sm={5}>
                    <div className="col k-pl-0">
                      <h6>Selected Fields for Criteria</h6>
                      <ListBox
                        style={{ height: 200, width: "100%" }}
                        data={fieldSelections.columns}
                        textField="title"
                        selectedField={SELECTED_FIELD}
                        onItemClick={(e: ListBoxItemClickEvent) => handleItemClick(e, "columns", "availableFields")}
                        onDragStart={handleDragStart}
                        onDrop={handleDrop}
                      />
                    </div>
                  </MaterialGrid>
                </MaterialGrid>
              </MaterialGrid>
            </MaterialGrid>
          </div>
          <div className="resource-group-auto-critera-section-head">Criteria For Rule</div>
        </div>
      </>
    );
  };

  async function getResourceTypes(tryAgin: boolean): Promise<IResourceTypeDetail[]> {
    const response = await ResourceService.getResourceTypeDetails();
    if (response.ok) {
      const data = response.data as Array<IResourceTypeDetail>;
      setResourceTypes(data);
      return data;
    } else if (tryAgin) {
      return await getResourceTypes(false);
    } else {
      NotificationService.showErrorToast("Error getting resource types, maybe you can refresh this page.");
      setHasError(true);
      return [];
    }
  }

  const isDisplayedField = (field: IResourceTypeField, code: string) => {
    const findItem = hiddenAutomationFields[code] && hiddenAutomationFields[code].indexOf(field.name) > -1;
    return !findItem;
  }

  const selectResourceType = (code: string, details: Array<IResourceTypeDetail>) => {

    const selRT = details.find(d => d.code === code);
    if (!selRT) {
      return;
    }

    let cols = [] as Array<GridColumnProps>;
    let preselctedCols = [] as Array<GridColumnProps>;

    const usedFields = (props.automationModel ? props.automationModel.usedFields : []) || [];

    selRT.fields &&
      selRT.fields.forEach(f => {
        if (!f.prefix && isDisplayedField(f, code)) {
          const filterData = props.automationModel && props.automationModel.filter &&
            props.automationModel.filter.filters.find(filter => (filter as FilterDescriptor).field.toString().toLowerCase() === f.name.toLowerCase()) as FilterDescriptor;

          const col = ColumnFromField(selRT.code, f, false, "", false, filterData);

          if (usedFields.indexOf(f.name) > -1) {
            preselctedCols.push(col);
          } else {
            cols.push(col);
          }
        }
      });

    setFieldSelections({
      availableFields: cols,
      columns: preselctedCols,
      draggedItem: {}
    });

    setSelectedResourceType(selRT);
    setDataState({ ...dataState, typeCode: selRT.code });
  };

  const fetchDataAsync = async (dataState: IRefreshGridParams, caller?: string) => {
    setIsGridLoading(true);

    let rTypes = null as Array<IResourceTypeDetail>;

    // component reloaded as if it's new
    if (!dataState.typeCode) {
      dataState = startingGridParams;
    }

    if (!resourceTypes) {
      rTypes = await getResourceTypes(true);

      if (!rTypes || !rTypes.length) {
        setHasError(true);
        return;
      }

      selectResourceType(dataState.typeCode, rTypes);
    }

    const fetchResult = await ResourceService.getResourcesUser(dataState);

    if (fetchResult.ok) {
      setIsGridLoading(false);
      setFetchResults(fetchResult.data.results.fields);
      setHasError(false);
    } else {
      console.log("Could not load grid.");
      setIsGridLoading(false);
      setHasError(true);
    }

    setDataState(dataState);
  };

  const handleItemClick = (event: ListBoxItemClickEvent, data: string, connectedData: string) => {
    setFieldSelections({
      ...fieldSelections,
      [data]: fieldSelections[data].map((item: ISelectResourceTypeField) => {
        if (item.field === event.dataItem.field) {
          item[SELECTED_FIELD] = !item[SELECTED_FIELD];
        } else if (!event.nativeEvent.ctrlKey) {
          item[SELECTED_FIELD] = false;
        }
        return item;
      }),
      [connectedData]: fieldSelections[connectedData].map((item: ISelectResourceTypeField) => {
        item[SELECTED_FIELD] = false;
        return item;
      })
    });
  };

  const handleToolBarClick = (e: ListBoxToolbarClickEvent) => {
    let toolName: string = e.toolName || "";
    let result: any = processListBoxData(
      fieldSelections.availableFields,
      fieldSelections.columns,
      toolName,
      SELECTED_FIELD
    );

    const oldFields = fieldSelections.columns.map((x: { field: string; }) => x.field);
    const newFields = result.listBoxTwoData.map((x: { field: string; }) => x.field);

    const removedFields = oldFields.filter(function (x: string) {
      return !newFields.includes(x);
    });

    let filtersRemoved = false;
    let filter = null as CompositeFilterDescriptor;

    if (removedFields.length && props.automationModel && props.automationModel.filter) {
      filter = { ...props.automationModel.filter };

      for (let i = filter.filters.length - 1; i >= 0; i--) {
        const f = filter.filters[i] as FilterDescriptor;
        if (removedFields.includes(f.field)) {
          filter.filters.splice(i, 1);
          filtersRemoved = true;
        }
      }
    }

    const changedFieldSelections = {
      ...fieldSelections,
      availableFields: result.listBoxOneData,
      columns: result.listBoxTwoData
    };

    setFieldSelections(changedFieldSelections);
    if (filtersRemoved) {
      filterChange(filter, changedFieldSelections);
    }
  };

  const handleDragStart = (e: ListBoxDragEvent) => {
    setFieldSelections({
      ...fieldSelections,
      draggedItem: e
    });
  };

  const handleDrop = (e: ListBoxDragEvent) => {
    let result: any = processListBoxDragAndDrop(
      fieldSelections.availableFields,
      fieldSelections.columns,
      fieldSelections.draggedItem,
      e.dataItem,
      "name"
    );
    setFieldSelections({
      ...fieldSelections,
      availableFields: result.listBoxOneData,
      columns: result.listBoxTwoData
    });
  };

  const filterChange = (filter: CompositeFilterDescriptor, fieldSelections: any) => {

    selectedResourceType &&
      props.onEditChange &&
      props.onEditChange({
        filter,
        resourceTypeCode: dataState.typeCode,
        usedFields: fieldSelections.columns.map((a: { title: string }) => a.title),
        isDirty: isValidFilter(filter)
      });
    setIsDirty(isValidFilter(filter));
  };

  const gridFilterChange = (event: GridFilterChangeEvent) => {
    filterChange(event.filter, fieldSelections);
  };

  const isValidFilter = (filter: CompositeFilterDescriptor) => {
    const emptyfilters = filter && filter.filters.filter(filter => ((filter as FilterDescriptor).value.length === 0));
    return emptyfilters && emptyfilters.length === 0 ? true : false;
  }

  const className = fieldSelections.columns.length ? "resource-group-auto-grid" : "resource-group-auto-grid resource-group-auto-grid-disabled";

  return (
    <>
      <div className={className}>
        <AdvancedGrid
          ref={(standardGridInstance: AdvancedGrid<AdvanceGridParams, IRefreshGridParams>) => {
            myGrid = standardGridInstance;
          }}
          showErrorState={hasError}
          showLoadingIndicator={isGridLoading}
          data={fetchResults}
          dataFetch={fetchDataAsync}
          dataState={dataState}
          columns={fieldSelections.columns.length ? fieldSelections.columns : [nameCol]}
          paging={false}
          noRecordsRender={<p>no</p>}
          noLoadOnMount={false}
          filteredDataOnly={false}
          multiFieldFilterDelimiter="|"
          gridToolbarContent={gridToolBarRender()}
          hidePaper={true}
          filter={props.automationModel ? props.automationModel.filter : null}
          onFilterChange={gridFilterChange}
          filterOperators={{
            text: [
              { text: 'grid.filterContainsOperator', operator: 'contains' },
              { text: 'grid.filterEqOperator', operator: 'eq' },
            ],
            numeric: [
              { text: 'grid.filterEqOperator', operator: 'eq' },
              { text: 'grid.filterGteOperator', operator: 'gte' },
              { text: 'grid.filterGtOperator', operator: 'gt' },
              { text: 'grid.filterLteOperator', operator: 'lte' },
              { text: 'grid.filterLtOperator', operator: 'lt' },
            ],
            date: [
              { text: 'grid.filterEqOperator', operator: 'eq' },
              { text: 'grid.filterAfterOrEqualOperator', operator: 'gte' },
              { text: 'grid.filterAfterOperator', operator: 'gt' },
              { text: 'grid.filterBeforeOperator', operator: 'lt' },
              { text: 'grid.filterBeforeOrEqualOperator', operator: 'lte' },
            ],
            boolean: [
              { text: 'grid.filterEqOperator', operator: 'eq' }
            ]
          }}
        />
      </div>
      <div className="resource-group-auto-automation-manage">
        <div className="error">
          {hasError && <>There is a problem with this filter</>}
        </div>
        <div>
          <Button
            className="btn-save-automation-criteria"
            disabled={!fieldSelections.columns || fieldSelections.columns.length == 0 || !isDirty}
            onClick={() => {
              props.onEditChange && props.onEditChange(null);
              setIsDirty(false);
            }}
          >
            Reset
          </Button>
          <Button
            disabled={!fieldSelections.columns || fieldSelections.columns.length == 0 || !isDirty}
            className="btn-save-automation-criteria"
            onClick={applyChanges}>
            Apply
          </Button>
        </div>
      </div>
    </>
  );
};

export default ResourceGroupAutomation;
