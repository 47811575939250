import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { IExcelImportType } from "../../../models/ResourceModels";

type Props = {
  templateDescription?: JSX.Element,
  downloadBtnLabel: string,
  fileName?: string,
  excelColumnNames?: Array<string>;
  excelImportTypes?: Array<IExcelImportType>;
}

const DownloadExcelTemplate = (props: Props) => {
  let exportExcel: any;
  const [selectedImport, setSelectedImport] = useState(null as IExcelImportType);
  const [enableBtn, setEnableBtn] = useState(props.excelImportTypes ? true : false);

  const downLoadTemplate = () => {
    save(exportExcel);
  };

  const save = (component: any) => {
    const exportOptions = {};
    component.save(exportOptions);
  };

  const handleChange = (e: any) => {
    
    if (e.target.value !="Select Template Type") {
      setSelectedImport(props.excelImportTypes.find(c => c.keyField == e.target.value));
      setEnableBtn(false);
    }
    else {
      setEnableBtn(true);
    }
  };

  let ExcelExportComponent = ExcelExport as any;
 
  return (    
    <>
      {props.templateDescription}    
      
      <div>
        {props.excelImportTypes != null &&
          <DropDownList
            data={props.excelImportTypes.map(c => c.keyField)}
            onChange={handleChange}
            defaultItem="Select Template Type"
            className= "ddl-template-type"
          />
        }
        <Button variant="contained" onClick={downLoadTemplate} disabled={enableBtn} className="btn-contined download-template-btn">
          { props.downloadBtnLabel}
        </Button>

       
        <ExcelExportComponent ref={(exporter: any) => (exportExcel = exporter)} fileName={selectedImport ? selectedImport.fileName : props.fileName}>
          {
            (props.excelImportTypes != null && selectedImport != null) ?
              (selectedImport.valueField.map((excelColumnName) =>
                <ExcelExportColumn field={excelColumnName} key={excelColumnName} />
              ))       
              :
              props.excelColumnNames && props.excelColumnNames.map((excelColumnName) =>
              <ExcelExportColumn field={excelColumnName} key={excelColumnName} />
            )
          
          }
        </ExcelExportComponent>

      </div>
    </>
  );
};

export default DownloadExcelTemplate;