import React, { Component } from "react";
import NotificationService from "../../../../services/NotificationService";
import DownloadTemplate from "./DownloadTemplate";
import ReviewImport from "./ReviewImport";
import { StandardWizard, IWizardStepProps } from "../../../common/wizard/StandardWizard";
import { IFileInfo, StandardFileUpload } from "../../../common/fileupload/StandardFileUpload";
import { ServiceBase } from "../../../../services/ServiceBase";
import { UploadFileRestrictions, UploadOnStatusChangeEvent, UploadOnAddEvent } from "@progress/kendo-react-upload";
import JobsService, {
  IGetJobStatusResponseData,
  IInitializeJobRequestData,
  ISubmitJobRequestData
} from "../../../../services/JobService";
import CommonHelper from "../../../common/utilities/CommonHelper";
import { JobTypeEnum } from "../../../../models/Enums";

enum ErrorMessages {
  ImportProcessSubmissionFailed = "Error occured during submitting job for import process.",
  ImportProcessFailed = "Error occurred during import processing.",
  FetchJobStatusFailed = "Error occured during fetching the job status details.",
  AllRecordsRequiresMoreInfo = "cannot import file - all records require information.",
  CannotImportEmptyFile = "Cannot import blank or empty file"
}

enum JobStates {
  ImportReadyForValidation = 1,
  ImportValidating = 2,
  ImportValidatedError = 3,
  ImportValidatedSuccess = 4,
  ImportReadyForProcessing = 5,
  ImportProcessing = 6,
  ImportProcessedError = 7,
  ImportProcessedSuccess = 8,
  ImportProcessedPartialSuccess = 9,
  ImportCancelRequested = 10,
  ImportCancelling = 11,
  ImportCancelled = 12
}

interface IUploadValidationStatus extends IGetJobStatusResponseData {
  data: string;
}

interface IImportResourcesJobSubmitRequest extends ISubmitJobRequestData {
  resourceGroupId: number;
  resourceGroupName: string;
}

interface IImportResourcesValidateJobSubmitRequest extends ISubmitJobRequestData {
  readyForValidation: boolean;
  resourceGroupId: number;
  resourceGroupName: string;
}

interface IValidationSummary {
  totalCreateCount: number;
  totalUpdateCount: number;
  totalIssueCount: number;
  validationInProgress: boolean;
}

interface IState {
  activeStep: number;
  fileName: string;
  jobId: string;
  errorMessage: string;
  validationSummary: IValidationSummary;
  showImportProgress: boolean;
  fileUploaded: boolean;
  fileUploadProcessing: boolean;
  importProcessFailed: boolean;
  uploadUrl: string;
  currentFile: any;
}

type Props = {
  refreshTheGrid: (isRefreshGrid?: boolean) => void;
  disableCancelButton: (disableCancel: boolean) => void;
  resourceGroupId: number;
  resourceGroupName: string;
};

const initialState: IState = {
  activeStep: 0,
  fileName: "",
  jobId: "",
  errorMessage: "",
  validationSummary: {
    totalCreateCount: 0,
    totalUpdateCount: 0,
    totalIssueCount: 0,
    validationInProgress: false
  },
  showImportProgress: false,
  fileUploaded: false,
  fileUploadProcessing: false,
  importProcessFailed: false,
  uploadUrl: "",
  currentFile: null
};

class BulkResourcesImport extends Component<Props, IState> {
  steps: Array<IWizardStepProps>;
  uploadFileRestrictions: UploadFileRestrictions;
  uploadUrl: string;

  constructor(props: Props) {
    super(props);

    this.state = initialState;
    this.uploadFileRestrictions = {
      allowedExtensions: new Array<string>("xls", "xlsx"),
      maxFileSize: 10485760 //10MB as bytes
    };

    this.steps = new Array<IWizardStepProps>(
      {
        stepConfig: {
          label: "DOWNLOAD",
          isValid: undefined,
          validator: () => this.state.activeStep === 0
        },
        stepContent: <DownloadTemplate />
      },
      {
        stepConfig: {
          label: "UPLOAD",
          isValid: undefined,
          validator: () => this.state.fileUploaded
        },
        stepContent: () => {
          const requestData: IInitializeJobRequestData = {
            jobType: JobTypeEnum.ImportResources
          } as any;

          return (
            <>
              <h2> Upload Resources List File</h2>
              <p>Select the Resources list file to import. Make sure that file was created using the provided template.</p>
              <StandardFileUpload
                restrictions={this.uploadFileRestrictions}
                onAdd={this.onAdd.bind(this)}
                onStatusChange={this.onStatusChange.bind(this)}
                requestData={requestData}
                onBeforeUpload={this.onBeforeUpload.bind(this)}
                onDataStateChanged={this.handleOnDataStateChanged.bind(this)}
              />
            </>
          );
        }
      },
      {
        stepConfig: {
          label: "REVIEW & IMPORT",
          isValid: undefined,
          validator: this.canSubmitImport.bind(this),
          action: async () => {
            const validationSummary = {
              totalCreateCount: 0,
              totalUpdateCount: 0,
              totalIssueCount: 0,
              validationInProgress: true
            };

            this.setState({ validationSummary });

            const requestData: IImportResourcesValidateJobSubmitRequest = {
              jobId: this.state.jobId,
              jobType: JobTypeEnum.ImportResources,
              readyForValidation: true,
              resourceGroupId: this.props.resourceGroupId,
              resourceGroupName: this.props.resourceGroupName
            };

            await JobsService.submitJob(requestData);
            await this.fetchJobStatus();
          }
        },
        stepContent: () => {
          return (
            <ReviewImport
              validationSummary={this.state.validationSummary}
              jobId={this.state.jobId}
              selectedFileName={this.state.fileName}
              errorMessage={this.state.errorMessage}
              importProcessFailed={this.state.importProcessFailed}
              showImportProgress={this.state.showImportProgress}
            />
          );
        }
      }
    );
  }

  canSubmitImport() {
    const { activeStep, errorMessage, validationSummary, showImportProgress } = this.state;

    return (
      activeStep === 2 &&
      validationSummary.totalCreateCount > 0 &&
      !showImportProgress &&
      errorMessage.length === 0 &&
      !validationSummary.validationInProgress
    );
  }

  onAdd(event: UploadOnAddEvent) {
    this.setState({ ...this.state, fileName: event.newState[0].name, jobId: "", fileUploaded: false });
  }

  async onStatusChange(event: UploadOnStatusChangeEvent) {
    const { status } = event.newState[0];

    this.setState({
      ...this.state,
      fileUploadProcessing: status === 3,
      fileUploaded: status === 4,
      errorMessage:
        status === 0
          ? `Unable to upload file ${this.state.fileName}. Please try again. If this issue continues, contact support.`
          : ""
    });
  }

  async onBeforeUpload() {
    this.setState({ ...this.state, fileUploadProcessing: true });
  }

  handleOnDataStateChanged(files: Array<IFileInfo>) {
    this.setState({ jobId: files[0].jobId });
  }

  handleOnStepChanged(activeStep: number) {
    //check if we are going backwards, if so we need to reset file stuff.  If a fourth step is ever added
    //then this code may need to change to check specifically if going backwards to step 2.
    if (this.state.activeStep > activeStep) {
      this.setState({ ...this.state, activeStep, fileName: "", jobId: "", fileUploaded: false });
    } else {
      this.setState({ ...this.state, activeStep });
    }
  }

  async handleSubmit() {
    this.setState({ showImportProgress: true });

    const requestData: IImportResourcesJobSubmitRequest = {
      jobId: this.state.jobId,
      jobType: JobTypeEnum.ImportResources,
      resourceGroupId: this.props.resourceGroupId,
      resourceGroupName: this.props.resourceGroupName
    };

    const processingResponse = await JobsService.submitJob(requestData);

    if (processingResponse.ok) {
      await this.fetchJobStatus();
    } else {
      this.setState({
        errorMessage: ErrorMessages.ImportProcessSubmissionFailed,
        showImportProgress: false,
        importProcessFailed: true
      });
    }
  }

  private async fetchJobStatus() {
    const { jobId } = this.state;

    if (jobId) {
      const { ok, data }  = await JobsService.getJobStatus<IUploadValidationStatus>(jobId); 

      if (ok) {
        const jobData = data.data ? JSON.parse(data.data) : null;
        const importData = jobData && jobData.ImportJobData && jobData.ImportJobData.Data;

        let validationSummary = {
          totalCreateCount: 0,
          totalUpdateCount: 0,
          totalIssueCount: 0,
          validationInProgress: false
        };

        switch (data.statusId) {
          case JobStates.ImportReadyForValidation:
          case JobStates.ImportValidating:
          case JobStates.ImportReadyForProcessing:
          case JobStates.ImportProcessing:
            await ServiceBase.setTimeoutPromise(1000);
            await this.fetchJobStatus();

            break;
          case JobStates.ImportValidatedError:
            const errorMessage = importData.Error.Message as string;

            if (importData && errorMessage && errorMessage.length > 0) {
              validationSummary.totalCreateCount = (importData.Success && importData.Success.NumCreate) || 0;
              validationSummary.totalUpdateCount = (importData.Success && importData.Success.NumUpdate) || 0;
              validationSummary.totalIssueCount = (importData.Success && importData.Success.NumIssue) || 0;
              validationSummary.validationInProgress = false;
              this.setState({ errorMessage: errorMessage, validationSummary: validationSummary });
            }

            break;
          case JobStates.ImportValidatedSuccess:
            validationSummary.totalCreateCount = importData.Success.NumCreate;
            validationSummary.totalUpdateCount = importData.Success.NumUpdate;
            validationSummary.totalIssueCount = importData.Success.NumIssue;
            validationSummary.validationInProgress = false;
            this.setState({ errorMessage: "", validationSummary: validationSummary });

            break;
          case JobStates.ImportProcessedError:
            if (importData && importData.Error.Message && importData.Error.Message.length > 0) {
              this.setState({ showImportProgress: false, importProcessFailed: true });
              NotificationService.showErrorToast(`${ErrorMessages.ImportProcessFailed}: ${importData.Error.Message}`);
            }

            break;
          case JobStates.ImportProcessedSuccess:
            NotificationService.showSuccessToast(
              `Update saved.`
            );
            const totalRecordCount = importData.Success.NumCreate + importData.Success.NumUpdate;
            this.props.refreshTheGrid(totalRecordCount > 0);

            break;
        }
      } else {
        this.setState({
          errorMessage: ErrorMessages.FetchJobStatusFailed,
          validationSummary: { ...this.state.validationSummary, validationInProgress: false }
        });
      }
    }
  }

  disableBackButton() {
    const { validationSummary, showImportProgress } = this.state;

    return showImportProgress || validationSummary.validationInProgress;
  }

  showLoadingIndicator(step: number) {
    return step === 2 && this.state.showImportProgress;
  }

  render() {
    return (
      <div>
        <StandardWizard
          lastStepButtonLabel={`Import(${
            this.state.validationSummary.validationInProgress
              ? "Validating"
              : this.state.validationSummary.totalCreateCount
            })`}
          previousStepButtonLabel={"Back"}
          steps={this.steps}
          onStepChanged={this.handleOnStepChanged.bind(this)}
          onSubmitClick={this.handleSubmit.bind(this)}
          disablePreviousStep={this.disableBackButton.call(this)}
          showLoadingIndicatorOnNextStep={this.showLoadingIndicator.bind(this)}
        />
      </div>
    );
  }
}

export default BulkResourcesImport;
