import { IAppFeature, IFeatureManager, featureOptions, IFeature } from "../../../../../models/Features";

const options = [{ key: "epiqOnly" as featureOptions, index: 0 }];

export default class AutoReenableFeatureManager implements IFeatureManager {

  private appId: number;
  private fIndex: Record<featureOptions, number>;
  private fKey: Record<number, featureOptions>;

  public constructor(appId: number) {
    this.appId = appId;

    this.fIndex = {} as Record<featureOptions, number>;
    this.fKey = {} as Record<number, featureOptions>;

    options.forEach(o => {
      this.fIndex[o.key] = o.index;
      this.fKey[o.index] = o.key;
    });
  }

  public createAppFeatures(featureId: number, values?: Array<any>) {

    return [{
      id: 0,
      applicationId: this.appId,
      featureId: featureId,
      epiqOnly: values && values.length > this.fIndex["epiqOnly"] ? values[this.fIndex["epiqOnly"]] : false
    }] as Array<IAppFeature>;
  }

  public getAdminSettingOptions(feature: IFeature) {

    const optionSettings = [];
    const epiqOnlyUserName = "Epiq Only Users";

    const epiqOnlyValue = feature.applicationFeature && feature.applicationFeature.length && feature.applicationFeature[this.fIndex["epiqOnly"]].epiqOnly ? true : false;

    options.forEach(o => {
      const id = feature.applicationFeature && feature.applicationFeature.length ? feature.applicationFeature[o.index].id : 0;
      const name = o.key === "epiqOnly" ? epiqOnlyUserName : "";
      const value = o.key === "epiqOnly" ? epiqOnlyValue : "";
      optionSettings.push({ id, name, type: 0 /*not doing system features just yet*/, value });
    });

    return [
      { id: feature.applicationFeature && feature.applicationFeature.length ? feature.applicationFeature[0].id : 0, name: epiqOnlyUserName, type: 0, value: epiqOnlyValue }
    ];
  }

  public getKeyByIndex(featureId: number, optionIdx: number): featureOptions {
    // TODO consider putting this in FeatureManager as base class
    return this.fKey[optionIdx];
  }
}