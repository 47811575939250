import { Action, Reducer } from "redux";
import { AppThunkAction, AppThunkActionPromise } from "./";
import { Role, Permission, UpsertRoleModel} from "../models/Role";
import AdminService from "../services/AdminService";
import NotificationService from "../services/NotificationService";
import GridParams from "../components/common/GridHelper";
import CommonHelper from "../components/common/utilities/CommonHelper";
import { act } from "@testing-library/react";
import { IGridParamsEpiq } from "../components/common/grid/StandardGrid";
import { IGridParams } from "../components/common/grid/AdvancedGrid";


// STATE - This defines the type of data maintained in the Redux store.

export interface IRoleState {
  isLoading: boolean;
  totalPermissionsCount: number;
  searchText?: string;
  hasError: boolean;  
  roleDetails: Role;
  savedRolePermissions: Permission[];
  permissionsToBeSaved: Permission[];
  permissions: Permission[];
  isRoleAlreadyExists: boolean;
  isInvalid:boolean;
}

interface GetRoleDetailsRequestAction {
  type: "GET_ROLE_DETAILS_REQUEST";
}

interface GetRoleDetailsResponseAction {
  type: "GET_ROLE_DETAILS_RESPONSE";
  roleDetails: Role;
}

interface GetRoleDetailsRequestFailureAction {
  type: "GET_ROLE_DETAILS_REQUEST_FAILURE";
  hasError?: boolean;
}

interface GetPermissionsRequestAction {
  type: "GET_PERMISSIONS_REQUEST";
}

interface GetPermissionsResponseAction {
  type: "GET_PERMISSIONS_RESPONSE";
  permissions: Permission[];
}

interface GetPermissionsRequestFailureAction {
  type: "GET_PERMISSIONS_REQUEST_FAILURE";
}

interface AddPermissionRequestAction {
  type: "ADD_PERMISSION_REQUEST";
}

interface AddPermissionResponseAction {
  type: "ADD_PERMISSION_RESPONSE";
  permissionsToBeSaved: Permission[];
}

interface GetPermissionByRoleIDRequestAction {
  type: "GET_PERMISSIONS_BY_ROLE_ID_REQUEST";
}

interface GetPermissionByRoleIDResponseAction {
  type: "GET_PERMISSIONS_BY_ROLE_ID_RESPONSE";
  savedRolePermissions: Permission[];
}

interface GetPermissionByRoleIDRequestFailureAction {
  type: "GET_PERMISSIONS_BY_ROLE_ID_REQUEST_FAILURE";
}

interface CreateRoleRequestAction {
  type: "CREATE_ROLE_REQUEST";
}

interface CreateRoleResponseAction {
  type: "CREATE_ROLE_RESPONSE";
  isRoleAlreadyExists: boolean;
  isInvalid: boolean;
}

interface CreateRoleRequestFailureAction {
  type: "CREATE_ROLE_REQUEST_FAILURE"; 
}

interface UpdateRoleRequestAction {
  type: "UPDATE_ROLE_REQUEST";
}

interface UpdateRoleResponseAction {
  type: "UPDATE_ROLE_RESPONSE";
  isInvalid: boolean;
  roleDetails: Role;
}

interface UpdateRoleRequestFailureAction {
  type: "UPDATE_ROLE_REQUEST_FAILURE";
  roleDetails: Role;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

type KnownAction =
  | GetRoleDetailsRequestAction
  | GetRoleDetailsResponseAction
  | GetRoleDetailsRequestFailureAction
  | GetPermissionsRequestAction
  | GetPermissionsResponseAction
  | GetPermissionsRequestFailureAction
  | AddPermissionRequestAction
  | AddPermissionResponseAction  
  | GetPermissionByRoleIDRequestAction
  | GetPermissionByRoleIDResponseAction
  | GetPermissionByRoleIDRequestFailureAction
  | CreateRoleRequestAction
  | CreateRoleResponseAction
  | CreateRoleRequestFailureAction
  | UpdateRoleRequestAction
  | UpdateRoleResponseAction
  | UpdateRoleRequestFailureAction

// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  getRoleDetails: (roleId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    dispatch({ type: "GET_ROLE_DETAILS_REQUEST" });  
    if (appState) {
      const result = await AdminService.getRoleDetails(roleId);
      if (result.ok) {       
        dispatch({
          type: "GET_ROLE_DETAILS_RESPONSE",
          roleDetails: result.data
        });
      } else {
        dispatch({
          type: "GET_ROLE_DETAILS_REQUEST_FAILURE"       
        });
      }
    }
  },

  addPermissionsToRole: (permissions: Permission[]): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    dispatch({ type: "ADD_PERMISSION_REQUEST" });

    if (appState) {
      dispatch({
        type: "ADD_PERMISSION_RESPONSE",
        permissionsToBeSaved: permissions
      });
    }
  },

  getPermissionsByRoleId: (roleId: number, gridParams:IGridParams): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    dispatch({ type: "GET_PERMISSIONS_BY_ROLE_ID_REQUEST" });

    if (appState) {
      const result = await AdminService.getPermissionsListByRoleId(roleId, gridParams);
      if (result.ok) {
        dispatch({
          type: "GET_PERMISSIONS_BY_ROLE_ID_RESPONSE",
          savedRolePermissions: result.data.results
        });
      } else {
        dispatch({
          type: "GET_PERMISSIONS_BY_ROLE_ID_REQUEST_FAILURE"
        });
      }
    }
  },

  getPermissions: (gridParams: IGridParamsEpiq): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    dispatch({ type: "GET_PERMISSIONS_REQUEST" });

    if (appState) {
      const result = await AdminService.getPermissions(gridParams);
      if (result.ok) {
        dispatch({
          type: "GET_PERMISSIONS_RESPONSE",
          permissions: result.data.results 
        });
      } else {
        dispatch({
          type: "GET_PERMISSIONS_REQUEST_FAILURE"
        });
      }
    }
  },

  createRole: (role: UpsertRoleModel): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    dispatch({ type: "CREATE_ROLE_REQUEST" });

    if (appState) {
      const result = await AdminService.createRole(role);
  
      if (!result.ok) {
        dispatch({
          type: "CREATE_ROLE_REQUEST_FAILURE"
        });
        NotificationService.showErrorToast("Something went wrong. Role not created.");
      } else if (result.data.inValid) {
        dispatch({
          type: "CREATE_ROLE_RESPONSE",
          isRoleAlreadyExists:result.data.message.toLowerCase() === "role already exists.",
          isInvalid:result.data.inValid
        });
        NotificationService.showErrorToast(result.data.message);
      } else {
        NotificationService.showSuccessToast(role.roleName + " created.");
        dispatch({
          type: "CREATE_ROLE_RESPONSE",
          isRoleAlreadyExists:false,
          isInvalid:result.data.inValid
        });
      }
    }
  },

  updateRoleDetails: (roleDetails: UpsertRoleModel): AppThunkActionPromise<KnownAction> => async (dispatch, getState) => {
    dispatch({ type: "UPDATE_ROLE_REQUEST" });
    const appState = getState();

    if (appState) {
      const result = await AdminService.updateRole(roleDetails);

      if (!result.ok) {
        dispatch({
          type: "UPDATE_ROLE_REQUEST_FAILURE",
          roleDetails: { ...appState.roleState.roleDetails, roleName: roleDetails.roleName, description: roleDetails.description }
        });
        NotificationService.showErrorToast("Something went wrong. Update not saved.");
      } else if (result.data.inValid) {
        dispatch({
          type: "UPDATE_ROLE_RESPONSE",
          roleDetails: { ...appState.roleState.roleDetails, roleName: roleDetails.roleName, description: roleDetails.description },
          isInvalid:result.data.inValid
        });
        NotificationService.showErrorToast(result.data.message);
      } else {
        NotificationService.showSuccessToast("Update saved.");
        dispatch({
          type: "UPDATE_ROLE_RESPONSE",
          roleDetails: { ...appState.roleState.roleDetails, roleName: roleDetails.roleName, description: roleDetails.description },
          isInvalid:result.data.inValid
        });
      }
    }
  }
};

// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: IRoleState = {
  isLoading: false,
  totalPermissionsCount: 0,
  searchText: "",
  hasError: false,
  roleDetails: null,
  savedRolePermissions: [],
  permissionsToBeSaved: [],
  permissions: [],
  isRoleAlreadyExists:false,
  isInvalid:false
};

export const reducer: Reducer<IRoleState> = (
  state: IRoleState | undefined,
  incomingAction: Action
): IRoleState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {   
    case "GET_ROLE_DETAILS_REQUEST":
      return {
        ...state,
        isLoading: true,
        hasError: false
      };
    case "GET_ROLE_DETAILS_RESPONSE":
      return {
        ...state,
        roleDetails: action.roleDetails,
        isLoading: false,
        hasError: false       
      };
    case "GET_ROLE_DETAILS_REQUEST_FAILURE":
      return {
        ...state,    
        isLoading: false,
        hasError: true
      };
    case "ADD_PERMISSION_REQUEST":
      return {
        ...state
      };
    case "ADD_PERMISSION_RESPONSE":
      return {
        ...state,
        permissionsToBeSaved: action.permissionsToBeSaved
      };  
    case "GET_PERMISSIONS_BY_ROLE_ID_REQUEST":
      return {
        isLoading:true,
        ...state
      };
    case "GET_PERMISSIONS_BY_ROLE_ID_RESPONSE":
      return {
        ...state,
        isLoading: false,
        hasError: false,
        savedRolePermissions: action.savedRolePermissions
      };  
    case "GET_PERMISSIONS_BY_ROLE_ID_REQUEST_FAILURE":
      return {
        ...state,
        isLoading: false,
        hasError:true
      };
    case "GET_PERMISSIONS_REQUEST":
      return {
        isLoading: true,
        ...state
      };
    case "GET_PERMISSIONS_RESPONSE":
      return {
        ...state,
        isLoading: false,
        hasError: false,
        permissions: action.permissions
      };
    case "GET_PERMISSIONS_REQUEST_FAILURE":
      return {
        ...state,
        isLoading: false,
        hasError: true
      };
    case "CREATE_ROLE_REQUEST":
      return {
        isLoading: true,
        ...state
      };
    case "CREATE_ROLE_RESPONSE":
      return {
        ...state,
        isLoading: false,
        hasError: false,
        isRoleAlreadyExists: action.isRoleAlreadyExists,
        isInvalid: action.isInvalid
      };
    case "CREATE_ROLE_REQUEST_FAILURE":
      return {
        ...state,
        isLoading: false,
        hasError:true
      };
    case "UPDATE_ROLE_REQUEST":
      return {
        isLoading: true,
        ...state
      };
    case "UPDATE_ROLE_RESPONSE":
      return {
        ...state,
        isLoading: false,
        roleDetails: action.roleDetails,
        hasError: false,
        isInvalid: action.isInvalid
      };
    case "UPDATE_ROLE_REQUEST_FAILURE":
      return {
        ...state,
        isLoading: false,
        roleDetails: action.roleDetails,
        hasError:true
      };
    default: {
      return state;
    }
  }

  return state;

};
