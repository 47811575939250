import React, { useState, useEffect, useRef, ReactElement } from "react";
import { ComboBox, ComboBoxFilterChangeEvent, ComboBoxChangeEvent, DropDownList, DropDownListChangeEvent, ListItemProps } from "@progress/kendo-react-dropdowns";
import { ServiceResponseJson } from "../../services/ServiceBase";
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import bgMessages from '../../Localization/en.json';
import { Checkbox } from "@progress/kendo-react-inputs";
import "./MultiSelectDropDown.scss";
loadMessages(bgMessages, 'en-US');

export interface IMultiSelectDropSelectedEvent<T> {
  data: Array<T>;
  backingEvent?: MultiSelectDropDownChangeEvent;
}

export interface IStandardFilterParams {
  searchText: string;
  skip: number;
  take: number;
}

export interface MultiSelectDropDownItem<T> {
  id: number | string; // TODO dynamic to use props.dataItemKey, but then I can't make it an exportable interface. Revisit when TS updates and have more options
  text: string; // TODO same as above only for props.textField
  data: T;
}

export type MultiSelectDropDownChangeEvent = ComboBoxChangeEvent | DropDownListChangeEvent;

export interface MultiSelectDropdownProps<T extends object = any, TT extends Record<string, TT> = any> {
  getItems?: (search: any) => Promise<ServiceResponseJson> | ServiceResponseJson;
  onChange: (event: IMultiSelectDropSelectedEvent<T>) => void;
  textToRequestConverter?: (inputText: string) => any;
  disableFiltering?: boolean;
  name?: string;
  placeholder?: string;
  preloadGetData?: boolean;
  data?: Array<T>;
  textField?: string;
  dataItemKey?: string;
  className?: string;
  itemClassName?: string;
  value?: Array<T>;
}

function updateSelections<T extends object = any>(selectedItems: T[], e: MultiSelectDropDownChangeEvent, setSelectedItems: React.Dispatch<React.SetStateAction<T[]>>) {

  const index = selectedItems.findIndex(d => (d as any).id === e.value.id);
  const updatedSelections = [...selectedItems];

  if (index === -1) {
    updatedSelections.push(e.value);
    setSelectedItems(updatedSelections);
  }
  else {
    updatedSelections.splice(index, 1);
    setSelectedItems(updatedSelections);
  }

  return updatedSelections;
}

const MultiSelectDropDown = <T extends object = any, TT extends Record<string, TT> = any>(props: MultiSelectDropdownProps<T, TT>) => {

  const getPreloadedSelections = () : Array<T> => {
    if (!props.value || !props.value.length) {
      return [];
    }

    return props.value;
  }

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(props.data || []);
  const [error, setError] = useState(false);
  const searchRef = useRef("");
  const timeoutRef = useRef(null);
  const [selectedItems, setSelectedItems] = useState<Array<T>>(getPreloadedSelections());

  useEffect(() => {
    if (!props.disableFiltering) throw 'Filtering mode not supported yet';
  }, [loading, error]);

  useEffect(() => {
    setSelectedItems(getPreloadedSelections());
  }, [props.value]);

  useEffect(() => {
  }, [selectedItems]);

  const setHasError = () => {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 2000);
  }

  const getBasicData = async () => {
    if (props.data && !props.getItems) {
      return;
    }

    setError(false);
    setLoading(true);

    const result = await props.getItems("");

    if (result.ok) {
      // TODO implement when we need this component to support dynamic filtering
      //setData(props.typeToListConverter(result ? (result.data.results || result.data) : null, props.existingFieldValues));
      //setData(props.typeToListConverter(result ? (result.data.results || result.data) : null));
    }
    else {
      setHasError();
    }

    setLoading(false);
  }

  const onOpen = async (event: any) => {
    if (!props.preloadGetData && event.nativeEvent.type !== "input") {
      getBasicData();
    }
  }

  const itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {

    const selected = selectedItems.findIndex(d => (d as any).id === itemProps.dataItem.id) > -1;
    let className = li.props.className;

    if (props.itemClassName) {
      className = className + " " + props.itemClassName;
    }

    const item = (
      <div key={itemProps.id} className="mdd-item-details">
        <div className="mdd-item-name">
        <Checkbox value={selected} className="multiselectdropdown-item-selection-checkbox" />
          {itemProps.dataItem[props.textField]}
        </div>
      </div>
    );
    return React.cloneElement(li, { ... li.props, className}, item);
  };

  const filterChange = async (event: ComboBoxFilterChangeEvent) => {

    // TODO work in here is needed when we support dynamic filtering
    console.log(`MultiSelectDropDown==>filterChange==> ${event}`);
    clearTimeout(timeoutRef.current);
    searchRef.current = event.syntheticEvent.target.value;
    timeoutRef.current = setTimeout(async () => {
      setLoading(true);
      setError(false);

      const result = await props.getItems(props.textToRequestConverter ? props.textToRequestConverter(searchRef.current) : searchRef.current);

      if (result.ok) {
        // Filtering with network calls isn't supported yet
        //setData(props.typeToListConverter(result.data.results || result.data, props.existingFieldValues));
        //setData(props.typeToListConverter(result.data.results || result.data));
      } else {
        setHasError();
      }

      setLoading(false);
    }, 300);
  };

  const style = error ? { width: "100%", backgroundColor: "rgba(204, 0, 0, 0.19)" } : { width: "100%" };
  const icon = error ? "k-i-exclamation-circle" : null;

  function valueRender(element: ReactElement<HTMLSpanElement>, value: any) {
    const outputValue = selectedItems.length == 0 ? "" : (selectedItems[0] as any)[props.textField];

    const children = selectedItems.length < 2 ? [<>{outputValue}</>] :
        [<>{outputValue}</>, <> +{selectedItems.length -1}</>];

    return React.cloneElement(element, { ...element.props }, children);
  }

  return <div className={"multi-select-drop-down-wrapper " + (props.className || "")}>
    <LocalizationProvider language="en-US">
      <IntlProvider locale="en" >
        {!props.disableFiltering &&
          <ComboBox
            data={data}
            style={style}
            filterable={true}
            onFilterChange={filterChange}
            allowCustom={false}
            dataItemKey={props.dataItemKey}
            textField={props.textField}
            onChange={(e) => {
              const updatedSelections = updateSelections<T>(selectedItems, e, setSelectedItems);

              props.onChange({
                data: updatedSelections,
                backingEvent: e
              });
            }}
            onOpen={onOpen}
            loading={loading}
            name={props.name}
            iconClassName={icon}
            placeholder={props.placeholder}
          />}
        {props.disableFiltering && <DropDownList
          data={data}
          style={style}
          dataItemKey={props.dataItemKey}
          textField={props.textField}
          itemRender={itemRender}
          valueRender={valueRender}
          value={null}
          onChange={(e) => {
            const updatedSelections = updateSelections<T>(selectedItems, e, setSelectedItems);

            props.onChange({
              data: updatedSelections,
              backingEvent: e
            });
          }}
          onOpen={onOpen}
          loading={loading}
          iconClassName={icon}
          name={props.name}
        />}
      </IntlProvider>
    </LocalizationProvider>
  </div>;
}

MultiSelectDropDown.defaultProps = {
  textField: "text",
  dataItemKey: "id",
  disableFiltering:true
};

export default MultiSelectDropDown;
