import React, { Component } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import { User } from "../../models/UserModels";
import { Route, Switch } from "react-router-dom";
import { adminRoutes, Routes } from "../../models/Routing";
import { ISessionData } from "../../store/Session";
import { ISubFeature } from "../../SessionManager";

type State = {
  users?: Array<User> | null;
};

interface IProps {
  sessionData: ISessionData;
  history: any;
  routeProps: any;
  match: any;
}

type Props = IProps;

class Administration extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { users: null };
  }

  render() {
    const { subFeatures } = this.props.sessionData.featurePermissions[Routes.Administration] as ISubFeature;
    return (
      <div>
        <Switch>
          {Object.keys(subFeatures).map(key => {
            if (subFeatures[key].authorized) {
              const route = adminRoutes[subFeatures[key].routeId];

              return (
                <Route {...this.props.routeProps} exact={route.exact} path={route.path} component={route.component} />
              );
            }
          })}
          ;
        </Switch>
      </div>
    );
  }
}

//export default Administration as any;
export default connect((state: IApplicationState) => ({ ...state.sessionState }), null)(Administration as any);
