import React from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";

export interface MenuOption {
  title: string;
  navigationUrl: string;
}

interface IEpiqMenuListProps {
  menuOptions?: MenuOption[];
  menuTitle: string;
  menuUrl: string;
}

const useStyles: any = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  paper: {
    marginRight: theme.spacing(2)
  }
}));

const EpiqMenuList: React.FunctionComponent<IEpiqMenuListProps> = props => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen: any) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (
      anchorRef &&
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: any) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen: any = React.useRef(open);
  React.useEffect(
    () => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }

      prevOpen.current = open;
    },
    [open]
  );

  return (
    <div className={classes.root}>
      <div>
        {props.menuOptions ? (
          <div
            className="MuiButtonBase-root MuiButton-root MuiButton-text"
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            {" "}
            <NavLink
              onClick={
                e =>
                  e.preventDefault() /*do not navigate, clicking should just open dropdown*/
              }
              className="nav-link"
              to="{props.menuUrl}"
              isActive={(match, location) => {
                return location.pathname.includes(props.menuUrl);
              }}
            >
              {props.menuTitle}
              <span className="arrow-down" />
            </NavLink>
          </div>
        ) : (
          <div
            ref={anchorRef}
            onClick={handleToggle}
            className="MuiButtonBase-root MuiButton-root MuiButton-text no-drop-down"
          >
            <NavLink
              to={props.menuUrl}
              className="nav-link"
              isActive={(match, location) => {
                return (
                  location.pathname.includes(props.menuUrl) ||
                  (location.pathname.split("/")[1] === "" &&
                    props.menuTitle.toLowerCase() === "applications")
                );
              }}
            >
              {props.menuTitle}
            </NavLink>
          </div>
        )}
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={handleClose}
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                >
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {props.menuOptions &&
                      props.menuOptions.map(item => (
                        <MenuItem onClick={handleClose} key={item.title}>
                          {" "}
                          <NavLink
                            className="nav-link"
                            exact
                            to={item.navigationUrl}
                            key={"Link" + item.title}
                          >
                            {item.title}
                          </NavLink>{" "}
                        </MenuItem>
                      ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};

export { EpiqMenuList };
