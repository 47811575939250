import * as React from "react";
import { NavLink } from "react-router-dom";
import { IGroupUserRequestParams } from "../../../../models/RequestModels";
import AdminService from "../../../../services/AdminService";
import NotificationService from "../../../../services/NotificationService";
import { Button } from "@progress/kendo-react-buttons";
import { User } from "../../../../models/UserModels";
import { GridColumnProps, GridFilterCellProps } from "@progress/kendo-react-grid";
import { SortDescriptor, FilterDescriptor, CompositeFilterDescriptor } from "@progress/kendo-data-query";
import "./GroupUserList.scss";
import { AdvancedGrid, IGridParams } from "../../../common/grid/AdvancedGrid";
import { DropDownFilter } from "../../../common/grid/filters/DropDownFilter";
import CommandCell from "../../../common/grid/columns/CommandCell";
import ErrorIcon from "@material-ui/icons/Error";
import CommonHelper from "../../../common/utilities/CommonHelper";
import { IGridColumnProps } from "../../../common/grid/BaseGrid";


interface UserType {
  name: string;
  id: number;
}

type Props = {
  users?: User[] | undefined;
  groupId?: number;
  selectionChange?: (selected: User[]) => void;
  addClickHandler?: () => void;
  onRemoveUsers?: (selected: User[]) => void;
  permissions: Set<string>;
  selectedDomains?: string[];
};

type State = {
  hasError: boolean;
  isLoading: boolean;
  dataState: IGroupUserRequestParams;
  isFilterExists: boolean;
};

class GroupUserList extends React.PureComponent<Props, State> {
  columns: Array<IGridColumnProps>;

  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
      isLoading: false,
      isFilterExists: false,
      dataState: {
        skip: 0,
        take: 500,
        sort: [{ field: "lastName|firstName", dir: "asc" }],
        groupId: props.groupId
      }
    }

    this.columns = new Array<IGridColumnProps>(
      {
        field: "lastName|firstName",
        title: "USERS",
        cell: props.permissions.has("EpiqAdminGetUser") ? this.usersBackground : this.usersBackgroundNoLink,
        filterable: true,
        filter: "text",
        preCalcFixedWidth: 150
      },
      {
        field: "username",
        title: "EMAIL",
        cell: this.userEmail,
        filterable: true,
        filter: "text",
        preCalcPercentageWidth: 21
      },
      { field: "lastLoginDate", title: "LAST LOGIN", format: "{0:g}", filterable: true, filter: "date", preCalcPercentageWidth: 17 },
      { field: "invitationsentDate", title: "INVITATION SENT", format: "{0:g}", filterable: true, filter: "date", preCalcPercentageWidth: 17 },
      { field: "createdDate", title: "DATE CREATED", format: "{0:g}", filterable: true, filter: "date", preCalcPercentageWidth: 17 }
    );

    if (props.permissions.has("EpiqAdminUpdateUserGroup")) {
      this.columns.push({
        title: "",
        cell: CommandCell({ onRemove: this.removeUser }),
        sortable: false,
        headerClassName: "no-sort"
      });
    }
  }

  getLastLoginDateFilter = (
    gridFilters: Array<CompositeFilterDescriptor>,
    dateColumnName: string = "lastLoginDate"
  ) => {
    // This function is used for date column filter in all advance grid pages.
    let isLastLoginDateFilterExists = false;
    let lastLoginDateValue: Date;
    const filters = gridFilters
      ? gridFilters.map(compositeFilter => {
        const lastLoginDateFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === dateColumnName
        ) as FilterDescriptor;

        if (lastLoginDateFilter) {
          const dateFilterValue = lastLoginDateFilter.value;

          if (dateFilterValue === "")
            return compositeFilter;

          if (!isNaN(new Date(dateFilterValue).getFullYear()) && new Date(dateFilterValue).getFullYear() > 1970) {
            lastLoginDateFilter.operator = 'eq';
          }

          lastLoginDateValue = typeof dateFilterValue === "string" ? new Date("01/01/1901") : ((lastLoginDateFilter.operator === 'isnull' || lastLoginDateFilter.operator === 'isnotnull') ? null : new Date(dateFilterValue));

          if (lastLoginDateFilter.operator === 'isnull' || lastLoginDateFilter.operator === 'isnotnull') {
            return {
              ...compositeFilter,
              filters: [
                {
                  ...lastLoginDateFilter,
                  field: dateColumnName,
                  operator: lastLoginDateFilter.operator,
                  value: lastLoginDateValue
                }
              ]
            };
          }

          isLastLoginDateFilterExists = true;

          return {
            ...compositeFilter,
            filters: [
              {
                ...lastLoginDateFilter,
                field: dateColumnName,
                operator: "gte",
                value: new Date(lastLoginDateValue.toUTCString())
              }
            ]
          };
        }

        return compositeFilter;
      })
      : [];

    if (isLastLoginDateFilterExists) {
      filters.push({
        logic: "and",
        filters: [
          {
            field: dateColumnName,
            operator: "lt",
            value: new Date(new Date(lastLoginDateValue.setUTCDate(lastLoginDateValue.getUTCDate() + 1)).toUTCString())
          }
        ]
      });
    }

    return filters;
  };

  loadUsers = async (dataState: IGroupUserRequestParams) => {
    try {
      this.setState({ isLoading: true });
      let filters = dataState.filters;

      if (filters) {
        filters = this.getLastLoginDateFilter(filters);
        filters = this.getLastLoginDateFilter(filters, 'invitationsentDate');
        filters = this.getLastLoginDateFilter(filters, 'createdDate');
      }

      let reverseSortDescriptors: SortDescriptor[] = this.updateUserTypeSortDirection(dataState);
      const result = await AdminService.getGroupUsers({
        ...dataState, filters: filters, sort: reverseSortDescriptors
      });

      let updateSortDescriptors: SortDescriptor[] = this.updateUserTypeSortDirection(dataState);
      const resultUsers = result ? result.data.results : null;

      if (this.props.selectionChange) {
        this.props.selectionChange(resultUsers);
      }

      this.setState({ dataState: { ...dataState, filters: filters, sort: updateSortDescriptors }, isFilterExists: (dataState.filters && dataState.filters.length > 0) });
    } catch (e) {
      this.setState({ hasError: true });
      NotificationService.showErrorToast("Something went wrong while getting users.");
    } finally {
      this.setState({
        isLoading: false
      })
    }
  }

  updateUserTypeSortDirection = (dataState: IGroupUserRequestParams) => {
    const sort: SortDescriptor[] = dataState.sort ? [...dataState.sort] : [];

    return sort.map((item, index) => {
      if (item.field === "superAdmin") {
        item.dir = item.dir === "asc" ? "desc" : "asc"
      }

      return item;
    });
  }

  removeUser = (cellProps: any) => {
    if (this.props.onRemoveUsers) {
      const alteredUsers = this.props.users.filter(resource => resource.id !== cellProps.id);
      this.props.onRemoveUsers(alteredUsers as User[]);
    }
  };

  isSuperAdmin = (props: any) => {
    const superAdmin = props.dataItem.superAdmin ? "Global Admin" : "User";

    return (
      <td>
        {superAdmin}
      </td>
    );
  };

  usersBackground = (cellProps: any) => {
    const { lastName, firstName, username } = cellProps.dataItem;
    const fullName = lastName + ", " + firstName;
    const domains = this.props.selectedDomains && this.props.selectedDomains.length > 0 ? this.props.selectedDomains.map(item => item.toLowerCase()) : null;
    const isInvalidDomainUser = domains ? !(domains.indexOf(CommonHelper.getEmailDomain(username).toLowerCase()) > -1) : false;
    const className = isInvalidDomainUser ? "invalid-domain-users" : "";

    return (
      <td>
        <div className="user-details">
          <div className={`user-name ${className}`}>
            <NavLink to={`/administration/userDetails/${cellProps.dataItem.id}`}>
              {isInvalidDomainUser ? <ErrorIcon /> : ""}
              <em title={fullName}>{fullName} </em>
            </NavLink>
          </div>
        </div>
      </td>
    );
  };

  usersBackgroundNoLink = (cellProps: any) => {
    const { lastName, firstName, username } = cellProps.dataItem;
    const fullName = lastName + ", " + firstName;
    const domains = this.props.selectedDomains && this.props.selectedDomains.length > 0 ? this.props.selectedDomains.map(item => item.toLowerCase()) : null;
    const isInvalidDomainUser = domains ? !(domains.indexOf(CommonHelper.getEmailDomain(username).toLowerCase()) > -1) : false;
    const className = isInvalidDomainUser ? "invalid-domain-users" : "";

    return (
      <td>
        <div className="user-details">
          <div className={`user-name ${className}`}>
            {isInvalidDomainUser ? <ErrorIcon /> : ""}
            <em title={fullName}>{fullName} </em>
          </div>
        </div>
      </td>
    );
  };

  userEmail(props: any) {
    const username = props.dataItem.username;
    return (
      <td className="email-cell">
        <em title={username}>{username}</em>
      </td>
    );
  }

  gridToolBarRender = (): JSX.Element => {
    return this.props.permissions.has("EpiqAdminUpdateUserGroup") && (
      <>
        <Button className="add-user-btn" icon={"plus"} primary={true} onClick={this.props.addClickHandler}>
          Users
        </Button>
      </>
    );
  }

  render() {
    const { hasError, isLoading, dataState, isFilterExists } = this.state;
    const data = this.props.users;

    return (
      <div className="base-grid-wrapper group-user-list">
        <AdvancedGrid
          showErrorState={hasError}
          showLoadingIndicator={isLoading}
          sortable={{ mode: "multiple" }}
          data={data}
          dataFetch={this.loadUsers}
          dataState={dataState}
          columns={this.columns}
          paging={false}
          noRecordsRender={<p>{isFilterExists ? "No users found." : "No users added."}</p>}
          noLoadOnMount={false}
          filteredDataOnly={false}
          multiFieldFilterDelimiter="|"
          filterOperators={{
            text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
            date: [{ text: "grid.filterIsNotNullOperator", operator: "isnotnull" }, { text: "grid.filterIsNullOperator", operator: "isnull" }]
          }}
          gridToolbarContent={this.gridToolBarRender()}
        />
      </div>
    );
  }
}

export default GroupUserList;
