import React from "react";
import { INotificationMessageModel } from "../../../models/MessageModel";
import CommonHelper from "../../common/utilities/CommonHelper";
import ReactMarkdown from 'react-markdown';

interface INotificationAlertProps {
  notifications?: INotificationMessageModel[];
}

const LinkRenderer = (props: any) => {
  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  );
}

const EpiqHeaderNotificationAlert: React.FunctionComponent<INotificationAlertProps> = props => {
  return (<div className="notification-container">
    {props.notifications && props.notifications.length > 0 ? props.notifications.map((item) => {
      return (<div className="notifications-box" >
        <div className="notification-content">
          <div className="notifyrow">
            <div className={`notification-title-icon notifycol ${item.postedResolvedMessage ? "green-icon" : "orange-icon"}`}>
              <i className="fa-sharp fa-solid fa-megaphone "></i>
            </div>
            <div className={`notification-title notifycol ${item.postedResolvedMessage ? "green-title" : ""}`}>
              {item.title}
            </div>
            <div className="notification-date notifycol">
              {CommonHelper.convertUTCDateToLocalDateString(item.lastmodifiedDate ? item.lastmodifiedDate : item.createdDate, "MMDDYYY", "/")}
            </div>
          </div>
          <div className="notification-message">
            <ReactMarkdown components={{ a: LinkRenderer }} children={item.message} />
          </div>
        </div>
      </div>)
    }) : <>
      <div className="notification-empty-message">
        No alerts available.
      </div>
    </>}
  </div>)
};

export { EpiqHeaderNotificationAlert };