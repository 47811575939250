import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import JobManager from "../../JobManager";
import NotificationService from "../../services/NotificationService";
import { IApplicationState } from "../../store";
import { IJobState } from "../../store/Jobs";
import { ISessionState } from "../../store/Session";

type Props = IProps & IJobState & ISessionState;

interface IProps {
  match?: any;
  history?: any;
}

const JobTracker = (props: Props) => {

  const [jobManager] = useState(new JobManager());
  const statusHistoryRef = useRef({} as Record<string, number>);

  useEffect(() => {
    const trackedJobs = jobManager.trackedStatuses(props.jobInfos);

    trackedJobs.forEach(status => {
      if (!status.info.statusId || !status.info.data || !status.config) {
        return;
      }

      if (status.config.approvalPendingMessage && status.info.pendingApproval) {
        if (status.config.onApprovalNeeded) {
          status.config.onApprovalNeeded();
        }

        NotificationService.showInfoToast(status.config.approvalPendingMessage, null, true);
        jobManager.stopTrackingJob(status.info.jobId);
        return;
      }

      if (status.config.specialStatusCases && status.config.specialStatusCases.statusTypes[status.info.statusId]) {
        const statusType = status.config.specialStatusCases.statusTypes[status.info.statusId];

        if (statusType.onStatus) {
          statusType.onStatus(status.info.data);
        }

        if ((!statusType.onStatus || statusType.statusCallbackHasNoMessage) && statusType.message && statusHistoryRef.current[status.info.jobId] !== status.info.statusId) {
          // only if we haven't shown the banner already
          const notifier =
            statusType.degree == "error" ? NotificationService.showErrorToast
              : statusType.degree == "warning" ? NotificationService.showWarningToast
                : statusType.degree == "success" ? NotificationService.showSuccessToast
                  : NotificationService.showInfoToast

          notifier(statusType.message, statusType.degree !== "info")

          statusHistoryRef.current[status.info.jobId] = status.info.statusId;
        }

        if (!statusType.keepChecking) {
          jobManager.stopTrackingJob(status.info.jobId);
        }

        return;
      }

      const data = JSON.parse(status.info.data);

      if (data.SuccessStages && data.SuccessStages.indexOf && data.SuccessStages.indexOf(status.info.statusId) > -1) {
        status.config.genericSuccessMessage && NotificationService.showSuccessToast(status.config.genericSuccessMessage, true);

        jobManager.stopTrackingJob(status.info.jobId);
      } else if (data.FailureStages && data.FailureStages.indexOf && data.FailureStages.indexOf(status.info.statusId) > -1) {
        const errMsg = props.sessionData.superAdmin && data.ErrorMessage ? data.ErrorMessage.replace('\n', '<br/>') : status.config.genericErrorMessage;
        status.config.genericErrorMessage && NotificationService.showErrorToast(errMsg, true);

        jobManager.stopTrackingJob(status.info.jobId);
      }
    });

  }, [props.jobInfos]);

  return <></>
}

export default connect((state: IApplicationState, ownProps: IProps) => { return { ...ownProps, ...state.jobState, ...state.sessionState } }, null)(JobTracker as any);
