import { FilterDescriptor } from "@progress/kendo-data-query";
import { GridCellProps, GridColumnProps } from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import SiteBrandingService from "../../../services/SiteBrandingService";
import { Button } from "@progress/kendo-react-buttons";
import { AdvancedGrid, IGridParams } from "../../common/grid/AdvancedGrid";
import { connect } from "react-redux";
import * as SessionStore from "../../../store/Session";
import { IApplicationState } from "../../../store";
import { SiteBrandingModel } from "../../../models/SiteBrandingModel";

type IProps = {
  history: any;
};

type Props = IProps & SessionStore.ISessionState;

const SiteBrandingList = (props: Props) => {
  const [sites, setSites] = useState<SiteBrandingModel[]>([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const dataState: IGridParams = {
    skip: 0,
    take: 100,
    sort: [{ field: "name", dir: "asc" }]
  };

  const fetchSites = async (dataState: IGridParams) => {
    setIsLoading(true);

    var filters = dataState.filters
      ? dataState.filters.map(compositeFilter => {

        const numericFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "settingId" || (filter as FilterDescriptor).field === "siteId"
        ) as FilterDescriptor;

        if (numericFilter) {
          const isFilterValueString = numericFilter.value && typeof (numericFilter.value) === "string"

          if (isFilterValueString) {
            return {
              ...compositeFilter,
              filters: [
                {
                  ...numericFilter,
                  value: -1
                }
              ]
            };
          }
          else if (isFilterValueString === null) {
            return {
              ...compositeFilter,
              filters: [
                {
                  ...numericFilter,
                  operator: "gte",
                  value: 0
                }
              ]
            };
          }

        }

        return compositeFilter;
      }) : [];

    const result = await SiteBrandingService.getSites({ ...dataState, filters: filters });

    if (result.ok) {
      setSites(result.data.results);
      setCount(result.data.count);

    } else {
      setHasError(true);
      console.log("Something went wrong while fetching sites.");
    }

    setIsLoading(false);

  }

  const clientNameCellLink = (props: GridCellProps) => {
    const name = props.dataItem.name;
    return (
      <td>
        <div className="site-name">
          <NavLink to={`/administration/siteBrandingDetails/${props.dataItem.siteId}`} >
            <em title={name}>{name}</em>
          </NavLink>
        </div>
      </td>
    );
  }

  const clientNameCell = (props: GridCellProps) => {
    const name = props.dataItem.name;
    return (
      <td>
        {name}
      </td>
    );
  }

  const columns = new Array<GridColumnProps>(
    { field: "name", title: "CLIENT NAME", cell: props.sessionData.permissions.has("EpiqAdminGetSiteBranding") ? clientNameCellLink : clientNameCell, filterable: true, filter: "text" },
    { field: "hostName", title: "URL", filterable: true, filter: "text" },
    { field: "settingId", title: "ID", filterable: true, filter: "numeric" },
    { field: "siteId", title: "SITE ID", filterable: true, filter: "numeric" }
  );

  const handleCreateSiteClick = () => {
    props.history.push("/administration/siteBrandingDetails/0");
  }

  const gridToolBarRender = (): JSX.Element => {
    return props.sessionData.permissions.has("EpiqAdminCreateSiteBranding") && (
      <Button className="add-site-btn" icon={"plus"} primary={true} onClick={() => handleCreateSiteClick()}>
        Site Branding
      </Button>
    );
  }

  return (
    <div className="sites-admin-wrapper">
      <AdvancedGrid
        showErrorState={hasError}
        showLoadingIndicator={isLoading}
        sortable={{ mode: "multiple" }}
        data={sites}
        dataFetch={fetchSites}
        dataState={dataState}
        columns={columns}
        paging={false}
        totalRecords={{ value: count, label: "Site Branding" }}
        noRecordsRender={<p>No client names found.</p>}
        noLoadOnMount={true}
        filteredDataOnly={<p>Please filter to search for client names.</p>}
        multiFieldFilterDelimiter="|"
        filterOperators={{
          text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
          numeric: [{ text: "grid.filterEqOperator", operator: "eq" }]
        }}
        gridToolbarContent={gridToolBarRender()}
        moreRecordsAvailableMessage="Too many sites to display. Adjust filters to refine results"
      />
    </div>
  );
};

export default connect((state: IApplicationState) => ({ ...state.sessionState }), {
})(SiteBrandingList as any);