export const RecoveryEmailedText =
  "An email with a recover link has been sent to {0}. Contact support@epiqglobal.com if you did not receive it.";
export const ReturnToLoginText = "Return to Login";
export const Email = "Email";
export const InvalidToken = "Your reset password link has expired. Please return to the Login page to reset your password.";
export const GoBack = "Return to Log in";
export const InvalidActivationToken = "This account link has expired. Return to the Login page and click Forgot Password, which will send you an updated link via email. \n For any additional login issues, contact Global Support Services at support@epiqglobal.com.";
export const RepeatPassword = "Confirm Password";
export const ResetEmailButtonText = "Reset via email";
export const LoginButtonText = "Log In";
export const LockEmailText = "Account is locked due to multiple invalid login attempts. Please contact your administrator to unlock your account.";
export const PasswordResetError = "Password cannot be your current password";
export const UpdatePasswordResetError = "New password cannot be same as previous password. Please enter a new password.";
export const PasswordReqError = "Password requirements were not met. Password requirements: at least 8 characters, a lowercase letter, an uppercase letter, a number.";
export const UpdatePasswordReqError = "New password does not meet the minimum security requirements";
export const ResetPasswordText = "Reset Credentials";
export const UnableLoginText = "Unable to log in, please verify your credentials.";
export const Signin = "Log In";
export const PasswordForgotEmailSentText = "Email Sent";
export const ResetPasswordButtonText = "Reset";
export const ResetPasswordLabel = "Reset Password";
