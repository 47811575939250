import { ServiceBase, ResponseMessages, ServiceResponseJson, ServiceResponse } from "./ServiceBase";
import SanitizeTextHelper from "../components/common/utilities/SanitizeTextHelper";


const DeleteFileMessages: ResponseMessages = {
  FailedMessage: "Deletion of file failed.",
  SuccessMessage: "Deletion of file succeeded."
};

const DownloadFileMessages: ResponseMessages = {
  FailedMessage: "Could not get file download info.",
  SuccessMessage: "Successfully retrieved file download info.",
};

export default class FileService extends ServiceBase {
  static async deleteFile(objectId: number) {
    const url = `${FileService.getBaseUri()}/?fileId=${objectId}`;
    const result: ServiceResponse = await fetch(url, { ...this.fetchDeleteOptions() })
      .then(async (response) => await this.handleResponse(response, DeleteFileMessages))
      .catch(async (response) => await this.handleResponse(response, DeleteFileMessages));

    return result;
  }

  static async getDownloadUrl(objectId: number) {
    const url = `${FileService.getBaseUri()}/getdownloadurl?fileId=${objectId}`;
    const result: ServiceResponseJson = await fetch(url, { ...this.fetchOptions() })
      .then(async (response) => await this.handleResponseJson(response, DownloadFileMessages))
      .catch(async (response) => await this.handleResponseJson(response, DownloadFileMessages));

    return result;
  }

  static async downloadFile(url: string, retryMax: number, fileName: string) {
    return ServiceBase.fetchRetry(
      url,
      {
        method: "GET"
      },
      retryMax
    )
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const anchorElement = document.createElement("a");

        anchorElement.style.display = "none";
        anchorElement.href = SanitizeTextHelper.sanitizeUrl(url);
        anchorElement.download = fileName;
        document.body.appendChild(anchorElement);
        anchorElement.click();
        window.URL.revokeObjectURL(url);
        return true;
      })
      .catch((error: any) => {
        console.error(`Could not download file at ${url}`, error);
        return false;
      });
  };

  static getBaseUri() {
    // TODO we might move this to a generic 'jobs' path down the road if we deal with non-user stuff
    return `${(<any>window).ApplicationVariables.apiUri}files`;
  }
}
