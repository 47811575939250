import React, { useEffect, useState } from "react";
import "./BreadCrumb.scss";

export interface IBreadCrumbPart {
    title?: string;
    element?: JSX.Element;
}

interface IProps {
    history?: any;
    path: Array<IBreadCrumbPart>;
}

type Props = IProps;

const BreadCrumb = (props: Props) => {

    return <div className="breadcrumb-container">
        {props.path.map((p, i) => {
            return (
                <>{i > 0 && <div className="breadcrumb-separator"></div>}
                <div className="breadcrumb-path">{p.element}{p.title}</div></>)
        })}
    </div>
}

export default BreadCrumb;