import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { AdvancedGrid, IGridParams } from "../../common/grid/AdvancedGrid";
import { GridCellProps, GridColumnProps } from "@progress/kendo-react-grid";
import { Paper, Grid, CircularProgress } from "@material-ui/core";
import { SecureUploadLocation, Environment, UpsertLocationModel } from "../../../models/SecureUploadLocations";
import AdminService from "../../../services/AdminService";
import * as SessionStore from "../../../store/Session";
import { IApplicationState } from "../../../store";
import "./SecureUploadDetails.scss";
import SecureUploadInfo from "./SecureUploadInfo";
import EnvironmentList from "./EnvironmentList";
import * as HeaderStore from "../../../store/Header";
import * as WarningMessageStore from "../../../store/WarningMessage";
import NotificationService from "../../../services/NotificationService";
import CommonHelper from "../../common/utilities/CommonHelper";
import { differenceWith } from "lodash";
import { Button } from "@progress/kendo-react-buttons";
import GridSelector from "../../common/grid/GridSelector";

interface IRefreshGridParams extends IGridParams {
  refreshToggle: boolean;
}

interface IProps {
  setHeaderButtons: any;
  match?: any;
  history: any;
}

enum Mode {
  Edit,
  Create,
  View
}

const LocationConfigurations = {
  MinimumCharacters: 3
};

type Props = IProps & HeaderStore.IHeaderState &
  typeof HeaderStore.actionCreators &
  WarningMessageStore.IWarningMessageState &
  typeof WarningMessageStore.actionCreators &
  SessionStore.ISessionState;

const gridProps: IGridParams = {
};

type State = {
  mode: Mode;
  updateModel: UpsertLocationModel;
  initializing: boolean;
  isFormValid: boolean;
  selectedEnvironments: Environment[];
  removedEnvironments: Environment[];
  potentiallySelectedEnvironments: Environment[];
  savedEnvironments: Environment[];  
  isLocAlreadyExist: boolean;
  isUpdateSaved: boolean;
  locationDetails: SecureUploadLocation;
  savedLocationDetails: SecureUploadLocation;
  isLoading: boolean;
  hasError: boolean;
  dataState: IGridParams;
  openAddEnvironmentsPopup: boolean;
  showAddEnvironmentsButton: boolean;
  enableAddEnvironmentButton: boolean;
  isAppSelectorOpen: boolean;  
  enableAddButton: boolean;
};

class SecureUploadDetails extends React.Component<Props, State> {
  state: State;
  constructor(props: Props) {
    super(props);

    const mode = Number(this.props.match.params.id) > 0 ? Mode.Edit : Mode.Create;

    this.state = {
      mode,
      updateModel: new UpsertLocationModel(),
      initializing: true,
      isFormValid: mode !== Mode.Create,
      selectedEnvironments: [],
      removedEnvironments: [],
      potentiallySelectedEnvironments: [],
      savedEnvironments: [],      
      isLocAlreadyExist: false,
      isUpdateSaved: false,
      locationDetails: new SecureUploadLocation(),
      savedLocationDetails: new SecureUploadLocation(),
      isLoading: false,
      hasError: false,
      dataState: gridProps,
      openAddEnvironmentsPopup: false,
      showAddEnvironmentsButton: false,
      enableAddEnvironmentButton: false,
      isAppSelectorOpen: false,      
      enableAddButton: false
    };
  }

  async componentDidMount() {
    await this.props.setHeaderButtons(this.getBtnsList(true), "", "/administration/secureuploadlocations", "Back to secure upload location list");
    await this.ensureDataFetched();
  }

  private async ensureDataFetched() {
    if (this.state.mode === Mode.Edit) {
      const locationId: number = Number(this.props.match.params.id);

      const locationDetailsResult = await AdminService.getSecureUploadLocation(locationId);

      this.setState({ ...this.state, isLoading: true });
      if (!locationDetailsResult.ok) {
        await this.props.setHeaderButtons(null);
        await this.props.saveUnsavedChanges(null, false);
        this.setState({ ...this.state, hasError: true });
        this.props.history.push("/administration/secureuploadlocations");
      } else {
        const locationDetails = locationDetailsResult.data ? locationDetailsResult.data : new SecureUploadLocation();
        this.setState({
          updateModel: CommonHelper.convertPropsToType(locationDetails, UpsertLocationModel),
          locationDetails: locationDetails,
          savedLocationDetails: CommonHelper.cloneObject(locationDetails),
          initializing: false,
          isLoading: false,
          hasError: false
        });
      }
    }
    else {
      this.setState({ initializing: false });
    }

    this.props.saveUnsavedChanges(JSON.stringify(this.state.locationDetails), false);
  }

  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
    if (prevState !== this.state) {
      const locDetailsIsDirty = this.locationDetailsIsDirty();
      this.props.updateUnsavedChanges(locDetailsIsDirty);
    }
  }

  getLocationEnviornments = async (dataState: IGridParams) => {
    const result = await AdminService.getLocationEnvironmentListById(this.props.match.params.id);

    if (result) {
      const environments = result.data ? result.data : [];
      this.setState({ ...this.state, savedEnvironments: environments, selectedEnvironments: environments });
    }
  }

  getBtnsList: any = (isSaveDisabled: boolean, isSaving: boolean) => {
    const buttons: any = [
      {
        buttonLabel: "Cancel",
        type: "button",
        handleClick: this.handleCancelClick
      },

      {
        buttonLabel: "Save",
        type: "button",
        disabled: isSaveDisabled,
        handleClick: this.handleSaveClick,
        color: "primary",
        isSaving: isSaving
      }
    ];
    return buttons;
  };

  handleCancelClick = () => {
    this.props.history.push("/administration/secureuploadlocations");
  };

  handleSaveClick = async () => {
    const requestModel = this.state.updateModel;
    const { selectedEnvironments, removedEnvironments } = this.state;

    requestModel.addEnvironments = selectedEnvironments.map(s => s.id);   
    requestModel.removeEnvironments = removedEnvironments.map(s => s.id);

    const location = requestModel;

    await this.props.setHeaderButtons(this.getBtnsList(true, true), "", "/administration/secureuploadlocations", "Back to secure upload location list");
    if (requestModel.id == 0) {
      const result = await AdminService.createLocation(location);

      if (result.ok) {
        const errorMessage = result.data && result.data.inValid === true && result.data.message ? result.data.message : "";

        if (errorMessage && errorMessage.toLocaleLowerCase() === "location already exists.") {
          this.setState({ isLocAlreadyExist: true });
          await this.props.setHeaderButtons(this.getBtnsList(true, false), "", "/administration/secureuploadlocations", "Back to secure upload location list");
        }
        else {
          this.getLocationEnviornments(this.state.dataState);
          const locObj = CommonHelper.cloneObject(this.state.savedLocationDetails) as SecureUploadLocation;
          locObj.id = location.id;
          locObj.name = location.name;
          locObj.secureFormURL = location.secureFormURL;
          locObj.uploadDestination = location.uploadDestination;
          this.setState({ savedLocationDetails: locObj, });
          NotificationService.showSuccessToast(`${locObj.name} Created.`);
          this.props.saveUnsavedChanges(null, false);
          this.props.history.push("/administration/secureuploadlocations");
        }
      }
      else {
        NotificationService.showErrorToast("Something went wrong. Location was not created.");
      }
    }
    else {
      const result = await AdminService.updateLocation(location);
      if (result.ok) {
        const errorMessage = result.data && result.data.inValid === true && result.data.message ? result.data.message : "";

        if (errorMessage && errorMessage.toLocaleLowerCase() === "location already exists.") {
          this.setState({ isLocAlreadyExist: true });
        }
        else {
          this.getLocationEnviornments(this.state.dataState);
          this.setState({ isUpdateSaved: true, savedLocationDetails: location, locationDetails: location });
          await this.props.saveUnsavedChanges(JSON.stringify(this.state.locationDetails), false);
          NotificationService.showSuccessToast("Update saved.");
          this.props.history.push("/administration/secureuploadlocations");
        }

        await this.props.setHeaderButtons(this.getBtnsList(true, false), "", "/administration/secureuploadlocations", "Back to secure upload location list");
      }
      else {
        NotificationService.showErrorToast("Something went wrong.Update not saved.");
      }
    }
  };

  updateLocDetails = (loc: SecureUploadLocation) => {
    const updateModel = CommonHelper.convertPropsToType(loc, UpsertLocationModel);
    this.setState({ updateModel: updateModel, locationDetails: loc });
  };

  componentWillUnmount() {
    this.props.setHeaderButtons(null);
    this.props.saveUnsavedChanges(null, false);
  }

  updateSelectedEnvironments = (selectedSavedEnvironments: Environment[]) => {
    this.setState({ ...this.state, selectedEnvironments: selectedSavedEnvironments }, this.toggleSave);
  };
     
  onRemoveUpdateEnvironments(removedEnv: Environment) {
    const { selectedEnvironments, removedEnvironments } = this.state;
    const selected = [...selectedEnvironments]

    for (let s = 0; s < selectedEnvironments.length; s++) {
      if (selectedEnvironments[s].id == removedEnv.id) {
        // not removing from DB since we just added it
        selected.splice(s, 1);
        this.setState({ selectedEnvironments: selected });
        return;
      }
    }

    this.setState({ removedEnvironments: removedEnvironments.concat(removedEnv) }, this.toggleSave);
  }

  updateSavedEnvironments = (updatedEnvironments: Environment[], dataState: IGridParams) => {
    this.setState({ ...this.state, savedEnvironments: updatedEnvironments, selectedEnvironments: updatedEnvironments, dataState: dataState }, this.toggleSave);
  }

  handleAddEnvs() {
    this.setState({ isAppSelectorOpen: true });
  }

  handleEnvsPopupClose(accepted: boolean) {
    
    if (accepted) {
      const potentiallySelected = [... this.state.potentiallySelectedEnvironments];
      let removed = [... this.state.removedEnvironments];

      removed = removed.filter(x => !potentiallySelected.some(p => p.id === x.id));

      const deduped = SecureUploadLocation.dedupeEnvs(
        potentiallySelected,
        this.state.selectedEnvironments || new Array<Environment>()
      );
      const dedupedEnvs = deduped.map((item) => ({ ...item, selected: false }));

      this.setState({ ...this.state, selectedEnvironments: dedupedEnvs, isAppSelectorOpen: false, enableAddButton: false, removedEnvironments: removed }, this.toggleSave);
    }
    else {
      this.setState({ ...this.state, isAppSelectorOpen: false, enableAddButton: false }, this.toggleSave);
    }
  }

  handleAppsPopupOpen() {
    this.toggleSave();
  }

  handleLocationEdited(loc: SecureUploadLocation, isValid: boolean) {
    const newLocName = loc.name;
    const newSecureFormURL = loc.secureFormURL;
    const newUploadDestination = loc.uploadDestination;
    const editedLocDetails: SecureUploadLocation = {
      ...this.state.locationDetails,
      name: newLocName,
      secureFormURL: newSecureFormURL,
      uploadDestination: newUploadDestination,
      ftpSiteUrl: loc.ftpSiteUrl,
      speedTestUrl: loc.speedTestUrl
    };

    const isLocAlreadyExist = this.state.updateModel.name.toLowerCase() === newLocName.toLowerCase() && this.state.isLocAlreadyExist ? this.state.isLocAlreadyExist : false;

    this.setState({
      ...this.state,
      updateModel: CommonHelper.convertPropsToType(editedLocDetails, UpsertLocationModel),
      isFormValid: isValid,
      locationDetails: editedLocDetails,
      isLocAlreadyExist
    }, this.toggleSave);
  }

  handleLocationEditCancelled = (loc: SecureUploadLocation) => {
    this.setState({
      ...this.state, updateModel: CommonHelper.convertPropsToType(loc, UpsertLocationModel),
      locationDetails: CommonHelper.cloneObject(loc)
    }, this.toggleSave);
  }

  locationDetailsIsDirty() {
    return this.props.initialState !== JSON.stringify(this.state.locationDetails as SecureUploadLocation);
  }  

  environmentsListIsDirty() {
    return this.state.selectedEnvironments.length > 0 || this.state.removedEnvironments.length > 0;
  }

  private toggleSave() {
    //const dirtyEnvironmentsList = this.environmentsListIsDirty();
    const canSave =
      (this.locationDetailsIsDirty() && this.isFormValidationsPassed())
      || (!this.locationDetailsIsDirty() && this.isFormValidationsPassed());// && dirtyEnvironmentsList ); 
    this.props.setHeaderButtons(this.getBtnsList(!canSave), "", "/administration/secureuploadlocations", "Back to secure upload location list");
  }
  
  private validateEnvironment = (environments: Environment[]) => {
    return environments.length > 0;
  }

  isFormValidationsPassed = () => {
    const {
      updateModel,
      isFormValid,
      isLocAlreadyExist
    } = this.state;
    const isLocNameValidationPassed =
      updateModel.name && isLocAlreadyExist === false
        ? updateModel.name.length >= LocationConfigurations.MinimumCharacters
        : false;
    
    //const isEnvironmentValidationPassed = this.validateEnvironment(this.state.selectedEnvironments);

    return (
      (isLocNameValidationPassed && isFormValid) // && isEnvironmentValidationPassed)
    );
  };

  resetViewMode = (isLocInfoInEditMode: boolean) => {
    this.setState({ isUpdateSaved: isLocInfoInEditMode });
  };

  closeAddEnvironmentsPopup = (accepted: boolean) => {
    this.setState({ ...this.state, openAddEnvironmentsPopup: false });
  }

  addEnvironmentsClick = () => {
    this.setState({ ...this.state, openAddEnvironmentsPopup: true })
  }

  handleEnvironmentPopupOpen() {
    this.toggleSave();
  } 

  handlePotentiallySelected(selected: Array<Environment>) {
    this.setState({ ...this.state, potentiallySelectedEnvironments: selected, enableAddButton: selected && selected.length > 0 });
  }

  render() {

    const { mode, initializing, locationDetails, savedLocationDetails, isLocAlreadyExist, isUpdateSaved, isLoading, hasError, savedEnvironments } = this.state;
    let locationInfo;
    if ((mode !== Mode.Create && isLoading) || initializing) {
      locationInfo = (
        <Paper className="loc-grid-wrapper">
          <div className="loc-loader">
            <CircularProgress />
          </div>
        </Paper>
      );
    }
    else if (hasError) {
      locationInfo = (
        <Paper className="loc-details-wrapper">
          <div className="no-loc">
            <p> Oops! Something went wrong. </p>
            <p>Please check your network connection and refresh the page.</p>
          </div>
        </Paper>
      );
    }
    else {
      locationInfo = (
        <>
          <SecureUploadInfo
            location={this.state.mode === Mode.Edit ? this.state.locationDetails : new SecureUploadLocation()}
            savedLocationDetails={savedLocationDetails}
            isLocationNameExist={isLocAlreadyExist}
            isUpdateSaved={isUpdateSaved}
            createLocation={mode === Mode.Create}
            permissions={this.props.sessionData.permissions}
            onLocationEdited={this.handleLocationEdited.bind(this)}
            resetViewMode={this.resetViewMode}
          />          
          <GridSelector
            isOpen={this.state.isAppSelectorOpen}
            onOpen={this.handleAppsPopupOpen.bind(this)}
            acceptBtnText="Add"
            cancelBtnText="Cancel"
            titleText="Add Environments"
            addAvailable={
              this.state.enableAddButton
            }
            onClose={this.handleEnvsPopupClose.bind(this)}
            fullWidth={true}
          >
            {this.state.isAppSelectorOpen && <EnvironmentList
              typeName={"Environments"}
              selectionChange={this.handlePotentiallySelected.bind(this)}
              hideAddButton={true}
              enableSelectEnvironment={true}
              permissions={this.props.sessionData.permissions}
              isOpen={this.state.isAppSelectorOpen}
              locationDetails={locationDetails}
            />}
          </GridSelector>
          <EnvironmentList
            locationDetails={locationDetails}
            typeName={"Environments"}
            additionalEnvironments={this.state.selectedEnvironments}
            disableAddBtn={false}
            addClickHandler={this.handleAddEnvs.bind(this)}
            canOpenPopup={true}
            displayRemoveBtn={true}
            onRemoveUpdateEnvironments={this.onRemoveUpdateEnvironments.bind(this)}
            enableRemoveEnvironment={true}
            permissions={this.props.sessionData.permissions}            
          />
        </>)
    }

    return (
      <Paper className="loc-detais-wrapper">
        {locationInfo}
      </Paper>
    );
  }
}

export default connect((state: IApplicationState) => ({ ...state.headerState, ...state.warningMessageState, ...state.sessionState }), {
  ...HeaderStore.actionCreators,
  ...WarningMessageStore.actionCreators
})(SecureUploadDetails as any);
