import React, { useState, useRef, useEffect } from "react";
import ApprovalService from "../../services/ApprovalService";
import NotificationService from "../../services/NotificationService";
import { IApproval, IApprovalForUserDetails } from "../../models/approvalModels";
import * as SessionStore from "../../store/Session";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SubTicketTypes, TicketTypes } from "../../models/ServicenowSupportRequestSettings";
import AddUsersApproval from "./AddUsersApproval";
import * as HeaderStore from "../../store/Header";
import * as MainDrawerStore from "../../store/MainDrawer";
import { IApplicationState } from "../../store";
import { connect } from "react-redux";
import { ISubHeaderbtns } from "../edge/header/EpiqSubHeaderComponent";
import JobDetailsPopup from "../job/JobDetailsPopup";
import { Dialog } from '@progress/kendo-react-dialogs';
import { DialogContent, DialogTitle, DialogActions } from "@material-ui/core";
import { Button } from "@progress/kendo-react-buttons";
import "./ApprovalDetail.scss";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { TextInput } from "../common/TextInput";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
export interface IApprovalDetailsProps {
  onClose: () => void;
}

interface IProps {
  approvalId: number;
  canApprove: boolean;
  onFinish: (approve?: boolean, comment?: string, rejectionType?: Array<number>) => void;
}

type Props = IProps
  & IApprovalDetailsProps
  & HeaderStore.IHeaderState
  & MainDrawerStore.IMainDrawerState
  & SessionStore.ISessionState
  & typeof HeaderStore.actionCreators;

export type ApprovalDetailsComponent = { approvalDetails: IApprovalForUserDetails };

const ApprovalDetails = (props: Props) => {

  const [error, setError] = useState(false);
  const [approval, setApproval] = useState<IApprovalForUserDetails>(null);
  const [showRejectionPopUp, setShowRejectionPopUp] = useState(false);
  const [rejectionType, setRejectionType] = React.useState([] as Array<number>);
  const [comment, setComment] = React.useState("");
  const [disableBtn, setDisableBtn] = useState(true);
  const width = 625;


  const handleChange = (event: any, rejectionId: number) => {
    if (event.value === true) {
      rejectionType.push(rejectionId);
      setRejectionType(rejectionType)
    }
    else if (event.value === false && rejectionType.indexOf(rejectionId) > -1) {
      let filteredArray = [];
      filteredArray = rejectionType.filter(item => item != rejectionId)
      setRejectionType(filteredArray)
    }
  }

  const handleTextChange = (value: string) => {
    setComment(value);
    if (value) {
      setDisableBtn(false);
    }
    else {
      setDisableBtn(true);
    }

  };

  const handleSubmit = () => {
    closeRejectionPopUp();
    props.setHeaderButtons([], "", "", " ");
    props.onFinish(false, comment, rejectionType);
  }
  const closeRejectionPopUp = function () {
    setShowRejectionPopUp(false);
    setDisableBtn(true);
    props.setHeaderButtons(getBtnsList(false, false, null), "", "", " ");
  };

  useEffect(() => {

    if (!props.approvalId) return;

    const getApproval = async function () {
      const result = await ApprovalService.approvalDetails(props.approvalId);

      if (!result.ok || !result.data || !result.data.payloadUnpacked) {
        setError(true);
        NotificationService.showErrorToast("error getting approval information");
        return;
      }

      props.setHeaderButtons(getBtnsList(!props.canApprove, false, result.data), "", "", " ");
      setApproval(result.data);
    }

    getApproval();

  }, [props.approvalId]);

  useEffect(() => {
    return () => {
      props.setHeaderButtons([], "", "", " ");
    }
  }, []);

  const onClose = (approve?: boolean, comment?: string) => {
    if (!approve && typeof approve !== "undefined") {
      props.setHeaderButtons(getBtnsList(true, false, null), "", "", " ");
      setShowRejectionPopUp(true);
    }
    else {
      props.setHeaderButtons([], "", "", " ");
      props.onFinish(approve, comment);
    }
  }

  const getBtnsList = (disabled: boolean, isSaving: boolean = false, approval: IApprovalForUserDetails = null): ISubHeaderbtns[] => {

    let canApprove = true;
    let tooltip: string = null;

    if (approval != null && approval.createdByUser != null && approval.createdByUser.username == props.sessionData.username) {
      canApprove = false;
      tooltip = "Approval not available for tickets you submit.";
    }
    else if (!props.sessionData.permissions.has("EpiqAdminUpdateApprovals") && approval.approversList.findIndex(au => au.approverUserEmail === props.sessionData.username) === -1) {
      canApprove = false;
      tooltip = "You do not have permission to approve this.";
    }
    if (!canApprove || disabled) {
      canApprove = false;
    }

    const buttons: any = [
      {
        buttonLabel: "Reject",
        type: "button",
        className: "disapprove-button",
        handleClick: () => onClose(false, null),
        disabled: !canApprove,
        isSaving
      },
      {
        buttonLabel: "Approve",
        type: "button",
        className: "approve-button",
        handleClick: () => onClose(true, null),
        tooltip,
        disabled: !canApprove,
        isSaving
      }
    ];

    return buttons;
  }

  if (!approval && !error) {
    return <CircularProgress size={20} />;
  }
  else if (error || !approval.payloadUnpacked || !approval.payloadUnpacked.payload) {
    return <>We couldn't find this approval or you don't have access</>
  }

  if (approval.payloadUnpacked.payload.Fields && approval.payloadUnpacked.payload.Fields.ImportUsers) {
    const isRelativityRequest = (approval.payloadUnpacked.payload.Fields.RequestTypeCode === SubTicketTypes.AddUserRelativityAccess || approval.payloadUnpacked.payload.Fields.RequestTypeCode === SubTicketTypes.RemoveUserRelativityAccess)
    const isEpiqAccessRequest = (approval.payloadUnpacked.payload.Fields.RequestTypeCode === SubTicketTypes.AddUserEpiqAccess || approval.payloadUnpacked.payload.Fields.RequestTypeCode === TicketTypes.ManageEpiqAccessUsers) 

    return <><AddUsersApproval approvalDetails={approval} onClose={() => onClose()} mainDrawerExpanded={props.expanded} />

      {showRejectionPopUp && <div className="support-popup">
        <Dialog aria-labelledby="customized-dialog-title" width={width} closeIcon={true}>
          <DialogTitle className="modal-title">{"Reason for Rejection"}
            <IconButton
              className="modal-close"
              aria-label="close"
              disabled={false}
              onClick={closeRejectionPopUp}
            ><CloseIcon /></IconButton>
          </DialogTitle>
          <DialogContent>
            <div className="approval-rejection-popup-container">Select one or more reasons for rejecting the request.
              <div className="">
                <div className="approval-reject-reason">
                  <div className="approval-reject-checkbox-left">
                    <Checkbox
                      defaultChecked={false}
                      name={"project"}
                      label={"Project"}
                      onChange={(event: CheckboxChangeEvent) => { handleChange(event, 1) }}
                    />
                  </div>
                  {isRelativityRequest && (
                    <div className="approval-reject-checkbox-relgroup">
                      <Checkbox
                        defaultChecked={false}
                        name={"relativityGroupName"}
                        label={"Relativity Group Name"}
                        onChange={(event: CheckboxChangeEvent) => { handleChange(event, 3) }}
                      />
                    </div>)
                  }
                  {isEpiqAccessRequest && (
                     <div className="approval-reject-checkbox-usergroup">
                      <Checkbox
                        defaultChecked={false}
                        name={"epiqAccessUserGroup"}
                        label={"Epiq Access User Group"}
                        onChange={(event: CheckboxChangeEvent) => { handleChange(event, 5) }}
                      />
                    </div>
                  )}
                </div>
                <div className="approval-reject-reason">
                  {isRelativityRequest && (
                    <>
                      <div className="approval-reject-checkbox">
                        <Checkbox
                          defaultChecked={false}
                          name={"workspace"}
                          label={"Workspace"}
                          onChange={(event: CheckboxChangeEvent) => { handleChange(event, 2) }}
                        />
                      </div>
                      <div className="approval-reject-checkbox-permission">
                        <Checkbox
                          defaultChecked={false}
                          name={"permissionsTemplate"}
                          label={"Permissions Template"}
                          onChange={(event: CheckboxChangeEvent) => { handleChange(event, 4) }}
                        />
                      </div>
                    </>
                  )}
                  {isEpiqAccessRequest && (
                    <div className="approval-reject-checkbox-oktgroup">
                      <Checkbox
                        defaultChecked={false}
                        name={"oktaGroup"}
                        label={"Okta Group"}
                        onChange={(event: CheckboxChangeEvent) => { handleChange(event, 6) }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="approval-reject-comments">Add comments.
                <div className="approval-reject-textbox">
                  <TextInput type="text" name="comment" onChange={value => handleTextChange(value)} />
                </div>
              </div>
            </div>

          </DialogContent>
          <DialogActions className="modal-footer">
            <Button
              onClick={handleSubmit}
              className="btn btn-primary"
              disabled={disableBtn}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>}</>
  }
  else if (approval.payloadUnpacked.payload.GroupId) {
    return <JobDetailsPopup loadingPopup={false/* will need to revisit this TODO */} approval={approval} onClose={() => onClose()} />
  }

  return <div>Unknown approval type</div>
}

export default connect(
  (state: IApplicationState, ownProps: IProps) => ({
    ...ownProps,
    ...state.headerState,
    ...state.sessionState,
    ...state.mainDrawerState
  }),
  {
    ...HeaderStore.actionCreators
  }
)(ApprovalDetails as any);