import React, { useState, useEffect } from "react";
import {
  Grid,
  GridColumn,
  GridToolbar,
  GridItemChangeEvent,
  GridRowClickEvent,
  GridCellProps,
  GridColumnProps,
  GridHeaderCellProps,
  GridRowProps
} from "@progress/kendo-react-grid";
import { User } from "../../../models/UserModels";
import { FieldRenderProps } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import "./SelectGridField.scss";
import { updateValForField, updateStatusForField } from "./WizardElements";
import { IWizardLookupValues, IGridDataColumns, IFieldValidation, ILookupCompletion } from "../../../models/Wizard";
import Tooltip from "@material-ui/core/Tooltip";
import CommandCell from "../grid/columns/CommandCell";
import { CircularProgress } from "@material-ui/core";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import _ from "lodash";

const initGridData = (data: Array<any>, fields: Array<string>, selectedData: Array<any>) => {

  if (fields && fields.length && selectedData && selectedData.length && selectedData[0].selectGridTempId == null) {
    for (let i = 0; i < selectedData.length; i++) {
      let id = '';
      for (let fi = 0; fi < fields.length; fi++) {
        id += (selectedData[i][fields[fi]] + '--');
      }

      selectedData[i].selectGridTempId = id;
    }
  }

  return data.map((e: any, i: number) => {

    let id = '';
    if (fields && fields.length) {
      for (let fi = 0; fi < fields.length; fi++) {
        id += (data[i][fields[fi]] + '--');
      }
    }
    else {
      id = i.toString();
    }

    const preSelected = selectedData != null && selectedData.findIndex(d => d.selectGridTempId == id) > -1;

    e.selectGridTempId = id;
    e.selected = preSelected;

    return e;
  });
}

const SelectGridField = (fieldRenderProps: FieldRenderProps) => {
  const fieldDataColumns: Array<IGridDataColumns> = fieldRenderProps.fieldData.columns;
  const readOnlyMode = !fieldRenderProps.fieldData.GetData;
  const preSelectedData = fieldRenderProps && fieldRenderProps.valInfo && fieldRenderProps.valInfo.value
    ? fieldRenderProps.valInfo.value
    : null;
  const [selectedCount, setSelectedCount] = React.useState<number>(0);
  const [data, setData] = React.useState<Array<any>>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [hasCheckedAll, setHasCheckedAll] = useState(false);

  useEffect(() => {
    if (!data) {
      getResources();
    }
    else {
      setLoading(false);
    }

  }, []);

  useEffect(() => {}, [loading, error, data]);

  async function getResources() {
    if (readOnlyMode && preSelectedData) {
      setData(preSelectedData);
      setLoading(false);
      return;
    }
    else if (!fieldRenderProps.fieldData.GetData && fieldRenderProps.fieldData.defaultValueFunc) {
      setData(fieldRenderProps.fieldData.defaultValueFunc(fieldRenderProps.fieldValues));
      setLoading(false);
      return;
    }
    else if (!fieldRenderProps.fieldData.GetData) {
      setData([]);
      setLoading(false);
      return;
    }

    setError(false);
    setLoading(true);

    const results = await fieldRenderProps.fieldData.GetData(fieldRenderProps.fieldData.GetDataParams(null, fieldRenderProps.fieldValues));

    if (results.ok) {
      setData(initGridData(results.data, fieldRenderProps.fieldData.idFieldsCsv ? fieldRenderProps.fieldData.idFieldsCsv.split(',') : null, preSelectedData));
      setHasCheckedAll(!!results.data && !!preSelectedData && preSelectedData.length === results.data.length);
    }
    else {
      setError(true);
    }

    setLoading(false);
  }

const renderHeaderCell = (props: GridHeaderCellProps) => {
    const column = fieldDataColumns.filter(f => f.field == props.field)[0];
    return (
      <Tooltip
        classes={{ tooltip: "mui-tooltip-no-maxwidth" }}
        title={column.toolTipText ? column.toolTipText : column.title}
        placement="top"
        arrow
      >
        <a className="k-link">{column.title}</a>
      </Tooltip>
    );
  };

  function handleHeaderCheckBoxSelectAllChange(e: CheckboxChangeEvent): void {
    setHasCheckedAll(e.value);
    const updated = _.clone(data);

    for (let i = 0; i < updated.length; i++) {
      updated[i].selected = e.value;
    }

    setSelectedCount(e.value ? updated.length : 0);
    setData(updated);

    if (e.value) {
      updateGridFieldData(updated);
    } else {
      updateGridFieldData(null);
    }
  }

  const firstCell = (cellProps: GridCellProps) => {

    return (
      <td>
          {fieldRenderProps.fieldData.GetData && <>
          <Checkbox
            type="checkbox"
            name={"row" + cellProps.dataItem.selectGridTempId}
            checked={cellProps.dataItem.selected}
            onClick={e => selectResource(cellProps.dataItem)}
            label={""}
            title=""
          />
          {" "}</>
          }
          {cellProps.dataItem[fieldDataColumns[0].field]}
      </td>
    );
  };

  const headerCheckBoxSelectAll = (props: GridHeaderCellProps) => {

    return (
      <div className="select-all-sync-apps-header">
        {fieldRenderProps.fieldData.GetData && <>
        <Checkbox
          name="checkBoxSelectAll"
          checked={hasCheckedAll}
          label={""}
          onChange={(e) => handleHeaderCheckBoxSelectAllChange(e)}
          title=""
        />
        {" "}</>
        }
        {fieldDataColumns[0].title}
      </div>);
  }

  const selectResource = (dataItem: any) => {
    const updated = _.clone(data);
    const eachSelected = [];

    for (let i = 0; i < updated.length; i++) {
      if (dataItem.selectGridTempId == updated[i].selectGridTempId) {
        updated[i].selected = !updated[i].selected;
      }
      if (updated[i].selected) {
        eachSelected.push(updated[i]);
      }
    }

    setHasCheckedAll(eachSelected.length == updated.length);
    setSelectedCount(eachSelected.length);
    setData(updated);
    updateGridFieldData(eachSelected.length ? eachSelected : null);
  };

  const columns = (() => {
    const cols = [];
    for (let c = 0; c <= fieldDataColumns.length - 1; c++) {
      cols.push(
        <GridColumn
          cell={c == 0 ? firstCell: null}
          field={fieldDataColumns[c].field}
          headerCell={c == 0 ? headerCheckBoxSelectAll : renderHeaderCell}
          title={fieldDataColumns[c].title}
          sortable={false}
        />
      );
    }

    return cols;
  })();

  const updateGridFieldData = (gridData: Array<any>) => {
    updateValForField(gridData ? [...gridData] : null, fieldRenderProps, null, (fieldDataColumns as unknown) as IWizardLookupValues);
  };

  if (error) {
    return <div>Could not load groups</div>
  }

  if (loading) {
    return <div>
      <CircularProgress />
    </div>
  }

  return (
    <div>
      {" "}
      <div className="select-grid-user-wizard-element">
        <Grid
          data={data}
          //editField="inEdit"
          //onRowClick={rowClick}
          //cellRender={renderCell}
          //rowRender={rowRender}
        >
          {columns}
        </Grid>
        <div className="grid-count">
          <i className="bi bi-file-text" />&nbsp; Total: {readOnlyMode ? (data ? data.length : 0) : selectedCount}
          {!readOnlyMode && <> of {(data ? data.length : 0)}</>}
        </div>
      </div>
    </div>
  );
};

export default SelectGridField;
