import { IGridParams as AdvanceGridParams } from "../components/common/grid/AdvancedGrid";

class Employer
{
    public id: number = 0;
  public name: string = "";
  public createdDate: Date;
  public createdBy: number = 0;
  public lastModifiedDate: Date;
  public lastModifiedBy: number = 0;
  public employerDomain: string = "";
  public totalDomainCount: number = 0;
  public epiqClient: boolean = false;
  public epiqDept: string = "Other";
  public entityType: string = "Other";
  public description: string = "";
}
class EmployerSelectable extends Employer {
  selected?: boolean;
}

class UpsertEmployerModel extends Employer {
  public employerDomains: EmployerDomain[] = [];
}

class EmployerDomain
{
    public id: number = 0;
  public employerId: number = 0;
  public employerName: string = "";
  public domain: string = "";
  public createdDate: Date;
  public createdBy: number = 0;
  public lastModifiedDate: Date;
  public lastModifiedBy: number = 0;
  public isSelected: boolean = false;
  public federated: boolean = false;
  public treatAsFederated: boolean = false;
}

class MoveDomainModel
{
  public domainId: number = 0;
  public domainName: string = "";
  public currentEmployerId: number = 0;
  public currentEmployer: string = "";
  public targetEmployerId: number = 0;
  public targetEmployer: string = "";
}

 export interface IEmployerDomainsParams extends AdvanceGridParams {
  searchValue?: string;
}

export { Employer, EmployerDomain, UpsertEmployerModel, MoveDomainModel, EmployerSelectable }
