import React, { useEffect, useRef, useState } from "react";
import ApprovalService from "../../../services/ApprovalService";
import NotificationService from "../../../services/NotificationService";
import ViewMore from "../../common/ViewMore";
import CircularProgress from "@material-ui/core/CircularProgress";
import { TicketTypes } from "../../../models/ServicenowSupportRequestSettings";
import InfoIcon from "@material-ui/icons/Info";

interface IProps {
  projectId: number;
  requestTypeCode: string;
  permissions: Set<string>;
}

const ProjectApprovers = (props: IProps) => {
  const [approvers, setApprovers] = useState([]);
  const [approversLoaded, setApproversLoaded] = useState(false);
  var approverList = new Array<string>();

  useEffect(() => {
    if (!props.projectId) return;

    const getApproval = async function () {
      const result = await ApprovalService.getApproversFromProject(props.projectId, props.requestTypeCode);

      if (!result.ok || !result.data) {
        NotificationService.showErrorToast("Error getting approvers information from project");
        return;
      }
      else {
        result.data.forEach((approver: string) =>
          approverList.push(approver));
      }
      setApprovers(approverList);
      setApproversLoaded(true);
    }
    getApproval();

  }, []);

  return (
    <>    
        {((props.requestTypeCode === TicketTypes.ManageRelativityUsers && !props.permissions.has("EpiqAdminSubmitTechnicalTicket-RelativityUserRequestBypassApproval")) ||
          (props.requestTypeCode === TicketTypes.ManageEpiqAccessUsers && !props.permissions.has("EpiqAdminSubmitTechnicalTicket-EpiqAccessUserRequestBypassApproval")))
        && <div className="wizard-summary-alert">When you submit this request, it is sent to an approval queue for review. Once approved, the support ticket will be available for you to track.
          {approversLoaded ? (approvers.length > 0 ?
            <div className="wizard-summary-approver-list">
              <div className="wizard-summary-approvers">Approvers:</div>
              <ViewMore lineHeight={34}>{approvers.map(v => v.toString()).join(", ")}</ViewMore>
            </div>
            : <div>
              <div className="wizard-summary-approvers"><InfoIcon className="wizard-summary-approvers-info" /> Approvers:</div>
              None (Project Manager not assigned to project.)
            </div>) : <CircularProgress size={20} /> }
        </div>
        }
    </>
  );
};

export default ProjectApprovers;