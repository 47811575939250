import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import {
  IWizardSection,
  IFieldValueLookup,
  IWizardRequest,
  IWizardValues,
  IWizardSummary,
  IWizardWarningMessage,
  IApplicationDatabaseFields,
  IWizardDropDownListItem,
  ISavableWizard,
  IGridDataColumns
} from "./Wizard";
import ResourceService from "../services/ResourceService";
import SupportService from "../services/SupportService";
import { timeZoneListValues, languageListValue } from "./ImageOcrWorkRequestSteps";
import { shippingListValues } from "./ShippingListValues";
import { ftpUrlList, TrackDataWorkRequestSteps } from "./TrackDataWorkRequestSteps";
import { JobMessagingConfig } from "../JobManager";
import AdminService from "../services/AdminService";
import { ILMProjectDeleteWorkRequestSteps } from "./ILMProjectDeleteWorkRequestSteps";
import { ServiceResponseJson } from "../services/ServiceBase";
import { User } from "../models/UserModels";
import easyUploadAdditionalSummary from "../components/common/wizard/SummaryUtility";

export enum DBRecordType {
  Epiq_Discovery_Processing = "Epiq Discovery (Transactional)",
  Nuix = "Nuix",
  DMX14_Processing = "DMX14 - Processing",
  Relativity_Hosting = "Relativity Hosting",
  Relativity_Processing = "Relativity Processing"
}

export const workRequestSteps = [
  {
    fields: [
      {
        type: "singleselect",
        fieldId: "0.1.1",
        name: "Project",
        label: "What project would you like to work on today?",
        active: true,
        GetData: ResourceService.getResourcesUser,
        postChange: async (valueInfo: IWizardValues) => {
          try {
            if (!valueInfo.lookupValues) {
              return Promise.resolve({ ...valueInfo, lookupValues: { attachmentsAllowed: false } });
            }
            const allowed = await SupportService.CanProjectHaveAttachments(
              valueInfo.lookupValues["SalesForceId"].toString()
            );
            return {
              ...valueInfo,
              lookupValues: { ...valueInfo.lookupValues, attachmentsAllowed: allowed.value === "true" }
            };
          } catch {
            return { ...valueInfo, lookupValues: { ...valueInfo.lookupValues, attachmentsAllowed: false } };
          }
        },
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            const text = `${item.displayName} (${item.fields["Project Number"]})`;
            return {
              text,
              id: item.objectId,
              data: {
                lookupValues: {
                  SalesForceId: item.fields["SalesForceId"],
                  projectNumber: item.fields["Project Number"],
                  projectName: item.displayName,
                  deliveryLocation: item.fields["Delivery Location"],
                  environmentLocationHostingAppDb: item.fields["Environment Location Hosting App DB"],
                  projectStatus: item.fields["Project Status"],
                  projectEmailDlName: item.fields["ProjectEmailDistributionListName"],
                  projectPrimaryEmail: item.fields["ProjectPrimaryEmail"],
                  projectSecondaryEmail: item.fields["ProjectSecondaryEmail"],
                  projectAlternatePMEmail: item.fields["ProjectAlternatePMEmail"],
                  pulseProjectType: item.fields["Pulse Project Type"]
                }
              }
            };
          });
        },
        GetDataParams: (searchText: string) => {
          const filters = [
            {
              logic: "or",
              filters: [{ field: "Project Status", operator: "neq", value: "Closed" }] as Array<FilterDescriptor>
            },
            {
              logic: "or",
              filters: [
                { field: "DisplayName", operator: "contains", value: searchText },
                { field: "Description", operator: "contains", value: searchText }
              ] as Array<FilterDescriptor>
            }
          ] as CompositeFilterDescriptor[];

          return {
            take: 100,
            typeCode: "ediscoveryproject",
            permissionsDelimited: "SubmitWorkRequest-TrackMedia,SubmitWorkRequest-SecureFileUpload,SubmitWorkRequest-SecureFileUploadandProcess,SubmitWorkRequest-ProcessData,SubmitWorkRequest-AnalyzeOrReview,SubmitWorkRequest-ExportData,SubmitWorkRequest-ProjectClosure,SubmitWorkRequest-MTMDataLoad",
            showGroupsWithDupePermissions: true,
            flattenResults: false,
            orderBy: "Name",
            filters: searchText ? filters : [filters[0]]
          };
        }
      },
      {
        type: "hiddenlookup",
        fieldId: "hiddenProjectPMCheck",
        name: "Project PM Email Check",
        lookupCompletion: (dataResult: any) => {

          if (dataResult) {
            const isMTMExist = dataResult.pulseProjectType && dataResult.pulseProjectType.split(";").indexOf("MTM") > -1;
            if (isMTMExist || (dataResult.projectEmailDlName || dataResult.projectPrimaryEmail || dataResult.projectSecondaryEmail || dataResult.projectAlternatePMEmail)) {
              return { success: true, value: true };
            }
          }

          return { success: false, value: false };
        },
        lookupError:
          "Project Manager not assigned to project. Notify your Epiq contact before entering this request.",
        label: "Project PM Email Check",
        watchField: "0.1.1",
        resetForField: "0.1.1",
        active: false,
        dependency: { fieldId: "0.1.1", compare: "neq", value: null },
        GetData: (dataResult: any) => {
          console.log(dataResult);
          const result = {
            ok: true,
            data: dataResult
          } as ServiceResponseJson;
          return result;
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          return {
            projectEmailDlName: fieldValues["0.1.1"].lookupValues && fieldValues["0.1.1"].lookupValues["projectEmailDlName"],
            projectPrimaryEmail: fieldValues["0.1.1"].lookupValues && fieldValues["0.1.1"].lookupValues["projectPrimaryEmail"],
            projectSecondaryEmail: fieldValues["0.1.1"].lookupValues && fieldValues["0.1.1"].lookupValues["projectSecondaryEmail"],
            projectAlternatePMEmail: fieldValues["0.1.1"].lookupValues && fieldValues["0.1.1"].lookupValues["projectAlternatePMEmail"],
            pulseProjectType: fieldValues["0.1.1"].lookupValues && fieldValues["0.1.1"].lookupValues["pulseProjectType"]
          };
        }
      },
      {
        type: "dropdown",
        fieldId: "0.1.2",
        label: "What would you like to do?",
        name: "Work Category",
        active: false,
        resetForField: "0.1.1",
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "0.1.1", compare: "neq", value: null },
            { fieldId: "hiddenProjectPMCheck", compare: "eq", value: true }
          ]
        },
        getDropdownOptions: (fieldValues: IFieldValueLookup) => {
          const permissions = fieldValues["projectPermissions"].lookupValues && fieldValues["projectPermissions"].lookupValues["Permissions"];
          if (permissions) {
            const options = [
              {
                id: 7,
                title: "Use Easy Upload",
                permission: "SubmitWorkRequest-SecureFileUpload,SubmitWorkRequest-SecureFileUploadandProcess",
                lookupValues: {
                  databaseHeader: "Use Easy Upload",
                  databaseLabel: "Select the hosting database where this data should be loaded.",
                  ftpUrlLabel: "What is the SFTP Site URL?"
                }
              },
              {
                id: 1,
                title: "Track FTP or Shipped Data",
                permission: "SubmitWorkRequest-TrackMedia",
                lookupValues: {
                  databaseHeader: "Track FTP or Shipped Data",
                  databaseLabel: "Select the hosting database where this data should be loaded.",
                  ftpUrlLabel: "What is the SFTP Site URL?"
                }
              },
              {
                id: 2,
                title: "Process Data for Review",
                permission: "SubmitWorkRequest-ProcessData",
                lookupValues: {
                  databaseHeader: "Process Data for Review",
                  databaseLabel: "Select the hosting database where this data should be loaded.",
                  ftpUrlLabel: "Provide the URL for the FTP site where you uploaded the file."
                }
              },
              {
                id: 3,
                title: "Analyze / Review Data",
                permission: "SubmitWorkRequest-AnalyzeOrReview",
                lookupValues: {
                  databaseHeader: "Analyze/Review Data",
                  databaseLabel: "Select the hosting database that contains the documents to analyze.",
                  tellUsAboutLabel: "analyze or review task you want to request.",
                  ftpUrlLabel: "What is the SFTP Site URL?"
                }
              },
              {
                id: 4,
                title: "Export Data",
                permission: "SubmitWorkRequest-ExportData",
                lookupValues: {
                  databaseHeader: "Export Data",
                  databaseLabel: "Select the hosting database that contains the documents to export.",
                  timingDescription:
                    "Our standard turnaround time for a work request like this is typically around a day, depending on the size of the load. More complicated requests will require additional time.",
                  ftpUrlLabel: "What is the SFTP Site URL?"
                }
              },
              {
                id: 5,
                title: "Archive (ARM), Delete Data, or Close Project",
                permission: "SubmitWorkRequest-ProjectClosure",
                lookupValues: {
                  databaseHeader: "Archive (ARM), Delete Data, or Close Project"
                }
              },
              {
                id: 9,
                title: "Return Media",
                permission: "SubmitWorkRequest-ProjectClosure",
                lookupValues: {
                  databaseHeader: "Return Media"
                }
              },
              {
                id: 8,
                title: "Update Metrics That Matter Reports",
                permission: "SubmitWorkRequest-MTMDataLoad",
                lookupValues: {
                  databaseHeader: "Update Metrics That Matter Reports"
                }
              }
            ]

            return getAuthorizedProjectPermissionDDItems(options, permissions);
          }
        },
      },
      {
        type: "dropdown",
        fieldId: "3",
        label: "Select the option that best represents the work you want to request.",
        name: "Requested Action",
        resetForField: "0.1.2",
        active: false,
        dependency: { fieldId: "0.1.2", compare: "eq", value: 5 },
        DropDownOptions: [
          {
            id: 5,
            title: "Archive (ARM) a Relativity workspace",
            lookupValues: {
              databaseHeader: "Archive (ARM) a Relativity workspace",
              requestType: "Archive Relativity",
              describeSubHeader: "select workspace to ARchive",
              describePageDescription: "When authorized to archive (ARM) a Relativity workspace, Epiq backs up all stored data for that workspace. You can archive one workspace per work request."
            }
          },
          {
            id: 1,
            title: "Delete a database",
            lookupValues: {
              databaseHeader: "Authorize Database Deletion",
              requestType: "Database Deletion",
              describeSubHeader: "SELECT DATABASE TO DELETE",
              describePageDescription: "When authorized to delete a processing or hosting database, Epiq destroys all stored data for that database. For a processing database, this action removes the ability to filter or dedupe against that data. When deleting a hosting database, that data is no longer available for review or production."
            }
          },
          {
            id: 2,
            title: "Delete media",
            lookupValues: {
              databaseHeader: "Authorize Media Deletion",
              requestType: "Media Deletion",
              describeSubHeader: "SELECT MEDIA TO DELETE",
              describePageDescription: "When authorized to delete media, Epiq destroys the original media that was uploaded, sent via ftp, or shipped. After deletion, the media is no longer available to Epiq. Select the media you want deleted from the following list."
            }
          },
          {
            id: 4,
            title: "Close a project and delete all data",
            lookupValues: {
              databaseHeader: "Authorize Project Closure",
              requestType: "Project Closure",
              describeSubHeader: "REVIEW MEDIA IN PROJECT",
              describePageDescription: "When authorized to close a project, Epiq destroys all project data, including original media, processing databases, and hosting databases. Review the list of media provided that will be destroyed with this project closure. If you need the media returned before closure, first submit a Return Media request (link below)."
            },
          }
        ],
      },
      {
        type: "hiddenlookup",
        fieldId: "hiddenProjectDLName",
        name: "Project Email DL Name",
        lookupCompletion: (dataResult: any) => {
          const projectDLNames = ["@epiqglobal", "@epiqsystems", "@dtiglobal"];
          let isValidProjectDLName = false;

          projectDLNames.forEach((item: string) => {
            if (dataResult && (!dataResult.projectEmailDlName) || (dataResult.projectEmailDlName.length > 0 && dataResult.projectEmailDlName.toLowerCase().indexOf(item) > -1)) {
              isValidProjectDLName = true;
              return;
            }
          });

          return { success: isValidProjectDLName };
        },
        lookupError:
          "Cannot close project work request due to project distribution email address. Contact your client service representative.",
        watchField: "3",
        label: "Project Email DL Name",
        resetForField: "0.1.1,3",
        active: false,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "0.1.1", compare: "neq", value: null },
            { fieldId: "3", compare: "eq", value: 4 },
          ]
        },
        GetData: (dataResult: any) => {
          console.log(dataResult);
          const result = {
            ok: true,
            data: dataResult
          } as ServiceResponseJson;
          return result;
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          return { projectEmailDlName: fieldValues["0.1.1"].lookupValues && fieldValues["0.1.1"].lookupValues["projectEmailDlName"] };
        }
      },
      {
        type: "dropdown",
        fieldId: "11",
        label: "Select the option that best represents the work you want to request.",
        name: "Requested Action",
        resetForField: "0.1.2",
        active: false,
        dependency: { fieldId: "0.1.2", compare: "eq", value: 6 },
        DropDownOptions: [
          {
            id: 1,
            title: "I want to delete a database.",
            lookupValues: {
              describeSubHeader: "SELECT DATABASE TO DELETE",
              describePageDescription: "When authorized to delete a processing or hosting database, Epiq destroys all stored data for that database. For a processing database, this action removes the ability to filter or dedupe against that data. When deleting a hosting database, that data is no longer available for review or production."
            }
          }
        ]
      },
      {
        type: "dropdown",
        fieldId: "2",
        label: "Select the option that best represents the work you want to request.",
        name: "Requested Action",
        resetForField: "0.1.2",
        active: false,
        dependency: { fieldId: "0.1.2", compare: "eq", value: 2 },
        DropDownOptions: [
          {
            id: 1,
            title: "I want to process and load previously uploaded data.",
          },
          {
            id: 3,
            title: "I want to format search terms for keyword filtering.",
          },
          {
            id: 5,
            title: "I want to filter previously processed data.",
            lookupValues: {
              hitsDescription:
                "After applying the filtering as requested, confirm that you want Epiq to proceed with loading the new documents to the hosting database.",
              describeSubHeader: "DESCRIBE THE DATA TO FILTER"
            }
          },
          {
            id: 4,
            title: "Other request/my request isn't listed.",
            lookupValues: {
              subHeader: "OTHER REQUEST",
              dynamicLabel: "Describe your request.",
              dynamicdescription:
                "Provide information about the work you would like to request, including details about the data. We will assess your request as soon as possible. Epiq personnel will contact you shortly, typically within a day."
            }
          },
        ],
      },
      {
        type: "dropdown",
        fieldId: "15",
        label: "Select the option that best represents the work you want to request.",
        name: "Requested Action",
        resetForField: "0.1.2",
        active: false,
        dependency: { fieldId: "0.1.2", compare: "eq", value: 9 },
        DropDownOptions: [
          {
            id: 1,
            title: "Return the actual hard drive you shipped to Epiq",
            lookupValues: {
              describePageDescription: 'Review the list of physical media you shipped to Epiq for this project. Physical media includes hard drives, thumb drives, and other physical storage devices. Then, select the physical media that you want Epiq to return.'
            },
          },
          {
            id: 2,
            title: "Return media that you provided via FTP",
            lookupValues: {
              describePageDescription: 'Review the list of media you provided to Epiq via FTP. Then, select the media that you want returned from the following list. As a result of submitting this request, Epiq will return this media via FTP.'
            },
          }
        ],
      },
      {
        type: "dropdown",
        fieldId: "6",
        label: "Select the option that best represents the work you want to request.",
        name: "Requested Action",
        resetForField: "0.1.2",
        active: false,
        dependency: { fieldId: "0.1.2", compare: "eq", value: 3 },
        DropDownOptions: [
          {
            id: 1,
            title: "I want to image documents.",
            lookupValues: {
              timingDescription:
                "Our standard turnaround time for a work request like this is typically around a day, depending on the size of the load. More complicated requests will require additional time.",
              dynamicDescription: "Identify the documents you want Epiq to image. You can supply the path to the search results or folder to use.",
              extraSummary: "Supply the location of the documents that you want to image."
            }
          },
          {
            id: 5,
            title: "I want to OCR documents.",
            lookupValues: {
              timingDescription:
                "Our standard turnaround time for a work request like this is typically around a day, depending on the size of the load. More complicated requests will require additional time.",
              dynamicDescription: "Identify the documents you want Epiq to OCR. You can supply the path to the search results or folder to use.",
              extraSummary: "Supply the location of the documents that you want to OCR."
            }
          },
          {
            id: 2,
            title: "I want to load third-party data.",
            lookupValues: {
              describePageDescription:
                "To process your request, we need to know more about where you want to load the third-party data."
            }
          },
          {
            id: 3,
            title: "I want to run analytics on documents.",
            lookupValues: {
              describePageDescription:
                "To process your request, we need to know more about the data to analyze, including the database that contains the documents and the search or folder that identifies the document set. Your search or folder must include full families.",
              timingDescription:
                "Our standard turnaround time for a work request like this is typically around a day, depending on the size of the data set. More complicated requests will require additional time.",
              extraSummary:
                "Then, this work request is sent to your primary Project Manager for review and quality control prior to performing the analysis.",
              databaseLabel: "Select the hosting database that contains the documents to analyze."
            }
          },
          {
            id: 4,
            title: "Other request/my request isn't listed.",
            lookupValues: {
              subHeader: "OTHER REQUEST",
              dynamicLabel: "Describe your request.",
              dynamicdescription:
                "Provide information about the work you would like to request, including details about the data. We will assess your request as soon as possible. Epiq personnel will contact you shortly, typically within a day."
            }
          }
        ],
      },
      {
        type: "dropdown",
        fieldId: "7",
        label: "Select the option that best represents the work you want to request.",
        name: "Request",
        active: false,
        dependency: { fieldId: "0.1.2", compare: "eq", value: 4 },
        DropDownOptions: [
          {
            id: 1,
            title: "I want to export documents.",
            lookupValues: {
              describePageDescription:
                "To process your request, we need to know more about the data to export, including location. Specify what you need to export, including image, text, and/or native files. You can also include a metadata DAT file that lists Begin and End Production Bates Numbers or DocIDs, and paths to text and native files."
            }
          },
          {
            id: 2,
            title: "Other request/my request isn't listed.",
            lookupValues: {
              subHeader: "OTHER REQUEST",
              dynamicLabel: "Describe your request.",
              dynamicdescription:
                "Provide information about the work you would like to request, including details about the data. We will assess your request as soon as possible. Epiq personnel will contact you shortly, typically within a day."
            }
          }
        ]
      },
      {
        type: "dropdown",
        fieldId: "8",
        label: "Select the option that best represents the work you want to request.",
        name: "Requested Action",
        active: false,
        requiredWhenActive: true,
        resetForField: "0.1.2",
        dependency: { fieldId: "0.1.2", compare: "eq", value: 1 },
        getDropdownOptions: (fieldValues: IFieldValueLookup) => {
          const permissions = fieldValues["projectPermissions"].lookupValues && fieldValues["projectPermissions"].lookupValues["Permissions"];
          if (permissions) {
            const options = [
              {
                id: 2,
                title: "Track data that I transferred via FTP.",
                permission: "SubmitWorkRequest-TrackMedia",
                lookupValues: {
                  timingDescription:
                    "Our standard turnaround time for a work request like is typically under 4 hours, depending on the size of the data set. More complicated requests will require additional time.",
                  estimatedSizeDescription:
                    "For proper placement, provide the estimated size of the data you uploaded via FTP. Choose the option that most closely reflects the actual size of the upload to ensure a efficient upload process."
                }
              },
              {
                id: 1,
                title: "Track data that I shipped.",
                permission: "SubmitWorkRequest-TrackMedia",
                lookupValues: {
                  timingDescription:
                    "Our standard turnaround time for a work request like this is typically more than 4 hours but less than a day after the shipment is received at the Epiq facility, depending on the size of the data set. More complicated requests will require additional time.",
                  estimatedSizeDescription:
                    "For proper placement, provide the estimated size of the data you shipped. Choose the option that most closely reflects the actual size of the upload to ensure a efficient upload process."
                }
              },
              {
                id: 4,
                title: "Other request/my request isn't listed.",
                permission: "SubmitWorkRequest-TrackMedia",
                lookupValues: {
                  subHeader: "OTHER REQUEST",
                  dynamicLabel: "Describe your request.",
                  dynamicdescription:
                    "Provide information about the work you would like to request, including details about the data. We will assess your request as soon as possible. Epiq personnel will contact you shortly, typically within a day."
                }
              },
            ]
            return getAuthorizedProjectPermissionDDItems(options, permissions);
          }
        }
      },
      {
        type: "dropdown",
        fieldId: "12",
        label: "Select the option that best represents the work you want to request.",
        name: "Requested Action",
        active: false,
        requiredWhenActive: true,
        resetForField: "0.1.2",
        dependency: { fieldId: "0.1.2", compare: "eq", value: 7 },
        getDropdownOptions: (fieldValues: IFieldValueLookup) => {
          const projectPermissionsLookup = fieldValues["projectPermissions"] && fieldValues["projectPermissions"].lookupValues;
          const permissions = projectPermissionsLookup && projectPermissionsLookup["Permissions"];
          if (permissions) {
            const hasLoadProcessPermission = projectPermissionsLookup["hasLoadProcessPermission"];
            const options = [{
              id: 1,
              title: "Transfer files that need processing.",
              permission: "SubmitWorkRequest-SecureFileUpload,SubmitWorkRequest-SecureFileUploadandProcess",
              lookupValues: {
                timingDescription: "TO DO: fill in the timingDescription in the wizard setup.",
                placeholderDesc: "Include the media description",
                dynamicDescription: hasLoadProcessPermission ? "Optionally, provide any additional notes or instructions for your Project Manager." : "Use our secure Easy Upload tool to safely provide Epiq with your media files. Upload a combined total of 10GB by dragging files from File Explorer and dropping them here, or clicking the link to browse and attach files to upload. When your files appear, click Submit in Secure File Upload.",
                databaseHeader: "Easy Upload, Process, and Load for Review",
                subHeader: "Post Processing Instructions"
              }
            },
            {
              id: 2,
              title: "Transfer third-party data with a load file.",
              permission: "SubmitWorkRequest-SecureFileUpload",
              lookupValues: {
                timingDescription: "TO DO: fill in the timingDescription in the wizard setup.",
                placeholderDesc: "Include details about the media, such as the recipient party and production volume",
                dynamicDescription: "Use our secure Easy Upload tool to safely provide Epiq with your media files. Upload a combined total of 10GB by dragging files from File Explorer and dropping them here, or clicking the link to browse and attach files to upload. When your files appear, click Submit in Secure File Upload."
              }
            }];
            return getAuthorizedProjectPermissionDDItems(options, permissions);
          }
        }
      },
      {
        type: "dropdown",
        fieldId: "14",
        label: "Select the option that best represents the work you want to request.",
        name: "Requested Action",
        active: false,
        requiredWhenActive: true,
        resetForField: "0.1.2",
        dependency: { fieldId: "0.1.2", compare: "eq", value: 8 },
        DropDownOptions: [
          {
            id: 1,
            title: "Upload data for my Metrics That Matter subscription.",
            lookupValues: {
              timingDescription: "Our standard turnaround time for a work request in Metrics that Matter is typically two business days from submission date.",
              estimatedSizeDescription: "For proper placement, provide the estimated size of the data you plan to upload. Choose the option that most closely reflects the actual size of the upload to ensure a efficient upload process.",
              dynamicDescription: "Use our secure Easy Upload tool to safely provide Epiq with your files. To use this upload, drag files from File Explorer and drop them in this box. You can also click the browse to attach files link and select the files to upload. After dragging or selecting files, click Submit in Secure File Upload."
            }
          },
        ]
      },
      {
        type: "hiddenlookup",
        fieldId: "projectPermissions",
        name: "Project Permissions",
        lookupCompletion: (dataResult: any) => {
          return {
            success: true,
            lookupValues: {
              "Permissions": dataResult && dataResult.length > 0 ? dataResult : null,
              hasLoadProcessPermission: dataResult && dataResult.length > 0 && dataResult.some((c: any) => c.permissionName === "SubmitWorkRequest-SecureFileUploadandProcess")
            }
          };
        },
        lookupError:
          "Error determining Project Permissions for this project. Please contact your client service representative.",
        watchField: "0.1.1",
        label: "Project Permissions",
        resetForField: "0.1.1",
        active: false,
        dependency: { fieldId: "0.1.1", compare: "neq", value: null },
        GetData: ResourceService.getUserResourcePermissions,
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          return fieldValues["0.1.1"].value
        }
      },
      {
        type: "hiddenlookup",
        fieldId: "hiddenProcessingAppDb",
        name: "Processing Database",
        lookupCompletion: (dataResult: any, fieldValues: IFieldValueLookup) => {

          const hasLoadProcessPermission = fieldValues["projectPermissions"].lookupValues && fieldValues["projectPermissions"].lookupValues["hasLoadProcessPermission"];

          if (!dataResult || dataResult.count === 0) {
            return { success: fieldValues && (fieldValues["12"] && fieldValues["12"].value == 1) || (fieldValues["8"] && (fieldValues["8"].value == 1 || fieldValues["8"].value == 2)) ? true : false, value: "Undetermined" };
          } else if (fieldValues && fieldValues["2"] && (fieldValues["2"].value === 1 || fieldValues["2"].value === 3 || fieldValues["2"].value === 5) &&
            dataResult.results[0].fields["Db Record Type"] === DBRecordType.Relativity_Processing) {
            return { success: false, value: "Undetermined" }
          }

          const text =
            dataResult.results[0].fields["Relativity Host"] ||
            dataResult.results[0].fields["Database Name"] ||
            dataResult.results[0].fields["Db Record Type"] ||
            "Undefined";
          return {
            success: true,
            value: dataResult.results[0].fields["AppDbId"],
            text,
            lookupValues: { "Db Record Type": dataResult.results[0].fields["Db Record Type"] }
          };
        },
        lookupError:
          "Error determining processing database for this project. Please contact your client service representative.",
        watchField: "0.1.1",
        label: "Processing Database",
        resetForField: "0.1.1",
        active: false,
        dependency: {
          logic: "or",
          subConditions: [{ fieldId: "8", compare: "eq", value: 1 },
          { fieldId: "8", compare: "eq", value: 2 },
          { fieldId: "12", compare: "eq", value: 1 },
          { fieldId: "2", compare: "eq", value: 1 },
          { fieldId: "2", compare: "eq", value: 3 },
          { fieldId: "2", compare: "eq", value: 5 }
          ]
        },
        GetData: ResourceService.getResourcesUser,
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            const text =
              item.fields["Relativity Host"] ||
              item.fields["Database Name"] ||
              item.fields["Db Record Type"] ||
              "Undefined";
            return {
              text,
              id: item.fields["AppDbId"],
              data: { lookupValues: { "Db Record Type": item.fields["Db Record Type"] } }
            };
          });
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          const filters = [
            {
              logic: "or",
              filters: [{ field: "ObjectId", operator: "eq", value: fieldValues["0.1.1"].value }] as Array<
                FilterDescriptor
              >
            }
          ] as CompositeFilterDescriptor[];

          return {
            take: 2,
            typeCode: "ediscoveryproject",
            permissionsDelimited: "SubmitWorkRequest-TrackMedia,SubmitWorkRequest-SecureFileUpload,SubmitWorkRequest-SecureFileUploadandProcess,SubmitWorkRequest-ProcessData,SubmitWorkRequest-AnalyzeOrReview,SubmitWorkRequest-ExportData,SubmitWorkRequest-ProjectClosure,SubmitWorkRequest-MTMDataLoad",
            showGroupsWithDupePermissions: true,
            flattenResults: true,
            dedupeFieldName: "AppDbId",
            joinFields: ["ApplicationDatabases"],
            filters: filters,
            orderBy: "AppDb CreatedDate",
            filterPresets: ["ActiveApps", "ProcessingApps"]
          };
        }
      },
      {
        type: "hiddenlookup",
        fieldId: "hiddenHostingLocation",
        name: "Hosting Location",
        lookupCompletion: (dataResult: any, fieldValues: IFieldValueLookup) => {
          if (!dataResult || dataResult.count === 0) {
            return { success: fieldValues && ((fieldValues["12"] && fieldValues["12"].value == 2) || (fieldValues["8"] && (fieldValues["8"].value == 1 || fieldValues["8"].value == 2))) ? true : false, value: "Undetermined" };
          }
          return {
            success: true,
            value: dataResult.results[0].fields["EnvironmentLocation"] ? dataResult.results[0].fields["EnvironmentLocation"] : null
          };
        },
        lookupError:
          "Error determining hosting location for this project. Please contact your client service representative.",
        watchField: "0.1.1",
        label: "Hosting Location",
        resetForField: "0.1.1",
        active: false,
        dependency: {
          logic: "or",
          subConditions: [{ fieldId: "8", compare: "eq", value: 1 },
          { fieldId: "8", compare: "eq", value: 2 },
          { fieldId: "12", compare: "eq", value: 2 },
          { fieldId: "7", compare: "eq", value: 1 },
          { fieldId: "6", compare: "eq", value: 1 },
          { fieldId: "6", compare: "eq", value: 2 },
          { fieldId: "6", compare: "eq", value: 3 },
          { fieldId: "6", compare: "eq", value: 5 }
          ]
        },
        GetData: ResourceService.getResourcesUser,
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          const filters = [
            {
              logic: "or",
              filters: [{ field: "ObjectId", operator: "eq", value: fieldValues["0.1.1"].value }] as Array<
                FilterDescriptor
              >
            }
          ] as CompositeFilterDescriptor[];

          return {
            take: 1,
            typeCode: "ediscoveryproject",
            permissionsDelimited: "SubmitWorkRequest-TrackMedia,SubmitWorkRequest-SecureFileUpload,SubmitWorkRequest-ProcessData,SubmitWorkRequest-AnalyzeOrReview,SubmitWorkRequest-ExportData,SubmitWorkRequest-ProjectClosure,SubmitWorkRequest-MTMDataLoad",
            showGroupsWithDupePermissions: true,
            flattenResults: true,
            dedupeFieldName: "AppDbId",
            joinFields: ["ApplicationDatabases"],
            filters: filters,
            orderBy: "ApplicationDbId",
            filterPresets: ["ActiveApps", "HostingApps"]
          };
        }
      },
      {
        type: "hiddenlookup",
        fieldId: "hiddenProcessingAndHostingApps",
        name: "ProcessingAndHostingApps",
        lookupCompletion: (dataResult: any, fieldValues: IFieldValueLookup) => {
          return {
            success: false,
            value: false
          };
        },
        lookupError:
          "Error determining processing and hosting database for this project. Please contact your client service representative.",
        watchField: "hiddenProcessingAppDb",
        label: "Processing And Hosting Database",
        resetForField: "0.1.1,12",
        active: false,
        dependency: {
          logic: "and",
          subConditions: [
            {
              logic: "or",
              subConditions: [
                { fieldId: "12", compare: "eq", value: 1 },
                { fieldId: "12", compare: "eq", value: 2 },
                { fieldId: "8", compare: "eq", value: 1 },
                { fieldId: "8", compare: "eq", value: 2 },
              ]
            },
            {
              logic: "and",
              subConditions: [
                { fieldId: "hiddenProcessingAppDb", compare: "eq", value: "Undetermined" },
                { fieldId: "hiddenHostingLocation", compare: "eq", value: "Undetermined" }
              ]
            }
          ]
        }
      },
      {
        type: "hiddenlookup",
        fieldId: "hiddenRelHostingAppDb",
        name: "Check Rel Database",
        lookupCompletion: (dataResult: any) => {
          if (!dataResult || !dataResult.results) return { success: false };

          for (let i = 0; i < dataResult.results.length; i++) {
            if (dataResult.results[i].fields["Db Record Type"] === DBRecordType.Relativity_Hosting)
              return { success: true, value: dataResult.results[i].fields["AppDbId"] };
          }

          return { success: false };
        },
        lookupError:
          "At this time, your project is not set up to request analytics. Select a different project or contact your Project Manager to request this type of work.",
        watchField: "0.1.1",
        label: "Hosting Database",
        resetForField: "0.1.1",
        active: false,
        dependency: { fieldId: "6", compare: "eq", value: 3 },
        GetData: ResourceService.getResourcesUser,
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            return {
              text: item.fields["Db Record Type"],
              id: item.fields["AppDbId"]
            };
          });
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          const filters = [
            {
              logic: "or",
              filters: [{ field: "ObjectId", operator: "eq", value: fieldValues["0.1.1"].value }] as Array<
                FilterDescriptor
              >
            },
          ] as CompositeFilterDescriptor[];

          return {
            take: 2,
            typeCode: "ediscoveryproject",
            permissionsDelimited: "SubmitWorkRequest-ProjectClosure",
            showGroupsWithDupePermissions: true,
            flattenResults: true,
            dedupeFieldName: "AppDbId",
            joinFields: ["ApplicationDatabases"],
            filters: filters,
            filterPresets: ["NotDeletedPendingHostingApps"]
          };
        }
      },
      {
        type: "hiddenlookup",
        fieldId: "hiddenProjectStatus",
        name: "Project Status",
        lookupCompletion: (dataResult: any) => {
          if (dataResult && dataResult.projectStatus !== "Pending Closed") {
            return { success: true };
          }

          return { success: false };
        },
        lookupError:
          "Currently closing this project. To get started, select another project.",
        watchField: "0.1.1",
        label: "Project Status",
        resetForField: "0.1.1",
        active: false,
        dependency: {
          logic: "or",
          subConditions: [
            { fieldId: "3", compare: "eq", value: 1 },
            { fieldId: "3", compare: "eq", value: 4 },
            { fieldId: "3", compare: "eq", value: 2 },
            { fieldId: "15", compare: "eq", value: 1 },
            { fieldId: "15", compare: "eq", value: 2 }
          ]
        },
        GetData: (dataResult: any) => {
          const result = {
            ok: true,
            data: dataResult
          } as ServiceResponseJson;
          return result;
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          return { projectStatus: fieldValues["0.1.1"].lookupValues && fieldValues["0.1.1"].lookupValues["projectStatus"] };
        }
      },
      {
        type: "hiddenlookup",
        fieldId: "hiddenMTMPulseProjectTypeCheck",
        name: "Pulse Project Type",
        lookupCompletion: (dataResult: any) => {
          if (dataResult && dataResult.pulseProjectType) {
            const isMTMExist = dataResult.pulseProjectType.split(";").indexOf("MTM") > -1;
            return { success: isMTMExist };
          }

          return { success: false };
        },
        lookupError:
          "To continue this request, change the selected project to a Metrics that Matter project.",
        watchField: "0.1.1",
        label: "Pulse Project Type",
        resetForField: "0.1.1",
        active: false,
        dependency: { fieldId: "14", compare: "eq", value: 1 },
        GetData: (dataResult: any) => {
          const result = {
            ok: true,
            data: dataResult
          } as ServiceResponseJson;
          return result;
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          return { pulseProjectType: fieldValues["0.1.1"].lookupValues && fieldValues["0.1.1"].lookupValues["pulseProjectType"] };
        }
      },
      {
        type: "hiddenlookup",
        fieldId: "hiddenHostingRelAppDb",
        name: "Relativity Database",
        lookupCompletion: (dataResult: any) => {
          if (dataResult && dataResult.results.length > 0) return { success: true };

          return { success: false };
        },
        lookupError:
          "No active workspace to archive. Contact your Project Manager for details.",
        watchField: "0.1.1",
        label: "Hosting Rel Database",
        resetForField: "0.1.2",
        active: false,
        dependency: { fieldId: "3", compare: "eq", value: 5 },
        GetData: ResourceService.getResourcesUser,
        DataConverter: (dataItems: any) => {
          console.log(dataItems);
          return dataItems.map((item: any) => {
            return {
              text: item.fields["Db Record Type"],
              id: item.fields["AppDbId"]
            };
          });
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          const filters = [
            {
              logic: "or",
              filters: [{ field: "ObjectId", operator: "eq", value: fieldValues["0.1.1"].value }] as Array<
                FilterDescriptor
              >
            },
            {
              logic: "and",
              filters: [
                { field: "Db Record Type", operator: "contains", value: DBRecordType.Relativity_Hosting }
              ] as Array<FilterDescriptor>
            }
          ] as CompositeFilterDescriptor[];

          return {
            take: 1,
            typeCode: "ediscoveryproject",
            permissionsDelimited: "SubmitWorkRequest-ProjectClosure",
            showGroupsWithDupePermissions: true,
            flattenResults: true,
            dedupeFieldName: "AppDbId",
            joinFields: ["ApplicationDatabases"],
            filters: filters,
            filterPresets: ["NotDeletedPendingArchiveHostingApps"]
          };
        }
      },
      {
        type: "hiddenlookup",
        fieldId: "hiddenEasyUploadProcessingAppDb",
        name: "Processing Database",
        lookupCompletion: (dataResult: any, fieldValues: IFieldValueLookup) => {
          return {
            success: false,
            value: false
          };
        },
        lookupError:
          "Error determining processing database for this project. Please contact your client service representative.",
        watchField: "hiddenProcessingAppDb",
        label: "Processing Database",
        resetForField: "hiddenProcessingAppDb",
        active: false,
        dependency: {
          logic: "or",
          subConditions: [
            {
              logic: "and",
              subConditions: [
                { fieldId: "12", compare: "eq", value: 1 },
                { fieldId: "hiddenProcessingAppDb", compare: "eq", value: "Undetermined" }
              ]
            },
          ]
        }
      },
      {
        type: "hiddenlookup",
        fieldId: "hiddenEasyUploadHostingAppDb",
        name: "Hosting Database",
        lookupCompletion: (dataResult: any, fieldValues: IFieldValueLookup) => {
          return {
            success: false,
            value: false
          };
        },
        lookupError:
          "Error determining hosting database for this project. Please contact your client service representative.",
        watchField: "hiddenHostingLocation",
        label: "Hosting Database",
        resetForField: "hiddenHostingLocation,12",
        active: false,
        dependency: {
          logic: "and",
          subConditions: [
            {
              logic: "or",
              subConditions: [
                { fieldId: "hiddenHostingLocation", compare: "eq", value: "Undetermined" },
              ]
            },
            {
              logic: "and",
              subConditions: [
                { fieldId: "12", compare: "eq", value: 2 }
              ]
            }
          ]
        }
      },
      {
        type: "singleselect",
        fieldId: "2.2",
        resetForField: "0.1.1",
        name: "Hosting Database",
        label: "Select the hosting database.",
        active: false,
        requiredWhenActive: true,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "12", compare: "eq", value: 2 },
            { fieldId: "hiddenHostingLocation", compare: "neq", value: "Undetermined" },
            { fieldId: "hiddenHostingLocation", compare: "neq", value: null }
          ]
        },
        GetData: ResourceService.getResourcesUser,
        DataConverter: (dataItems: any) => {
          return [{ id: null, text: "Not Applicable" }].concat(
            dataItems.map((item: any) => {
              const text =
                item.fields["Relativity Host"] ||
                item.fields["Database Name"] ||
                item.fields["EqD Database Name"] ||
                item.fields["Db Record Type"] ||
                "Undefined";
              return {
                text,
                id: item.fields["AppDbId"],
                data: { lookupValues: { "Db Record Type": item.fields["Db Record Type"], "Platform": item.fields["Platform"] } }
              };
            })
          );
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          const filters = [
            {
              logic: "or",
              filters: [{ field: "ObjectId", operator: "eq", value: fieldValues["0.1.1"].value }] as Array<
                FilterDescriptor
              >
            }
          ] as CompositeFilterDescriptor[];

          if (searchText) {
            filters.push({
              logic: "or",
              filters: [
                { field: "Relativity Host Id", operator: "contains", value: searchText },
                { field: "Relativity Host", operator: "contains", value: searchText },
                { field: "Database Name", operator: "contains", value: searchText },
                { field: "EqD Database Name", operator: "contains", value: searchText },
                { field: "Db Record Type", operator: "contains", value: searchText }
              ] as Array<FilterDescriptor>
            });
          }

          const eoMediaValue = fieldValues["8"] ? fieldValues["8"].value : 0;
          const eoMediaUploadValue = fieldValues["12"] ? fieldValues["12"].value : 0
          if (eoMediaValue === 1 || eoMediaUploadValue === 1 || eoMediaUploadValue === 2) {
            filters.push({
              logic: "or",
              filters: [
                { field: "Platform", operator: "neq", value: "Relativity Processing (RSMF)" },
                { field: "Db Record Type", operator: "neq", value: DBRecordType.Relativity_Hosting }
              ] as Array<FilterDescriptor>
            });
          }

          return {
            take: 100,
            typeCode: "ediscoveryproject",
            permissionsDelimited: "SubmitWorkRequest-TrackMedia,SubmitWorkRequest-SecureFileUpload,SubmitWorkRequest-ProcessData,SubmitWorkRequest-AnalyzeOrReview,SubmitWorkRequest-ExportData",
            showGroupsWithDupePermissions: true,
            flattenResults: true,
            dedupeFieldName: "AppDbId",
            joinFields: ["ApplicationDatabases"],
            filters: filters,
            filterPresets: ["ActiveApps", "HostingApps"]
          };
        }
      },
      {
        type: "hiddenlookup",
        fieldId: "hiddenSecureUpload",
        name: "Secure Upload",
        lookupCompletion: (dataResult: any) => {
          console.log("dataResult: ", dataResult);
          if (!dataResult || dataResult.count === 0) return { success: false };
          return {
            success: true,
            value: dataResult
          };
        },
        lookupError:
          "Error determining Secure Upload for this project. Please contact your client service representative.",
        watchField: "hiddenHostingLocation",
        label: "Hosting Location",
        resetForField: "2.2,14",
        active: false,
        dependency: {
          logic: "or",
          subConditions: [
            {
              logic: "and",
              subConditions: [
                { fieldId: "12", compare: "eq", value: 1 },
                { fieldId: "hiddenProcessingAppDb", compare: "neq", value: "Undetermined" },
                { fieldId: "hiddenProcessingAppDb", compare: "neq", value: null },
              ],
            },
            {
              logic: "and",
              subConditions: [
                { fieldId: "2.2", compare: "neq", value: null },
                { fieldId: "12", compare: "eq", value: 2 },
              ]
            },
            { fieldId: "14", compare: "eq", value: 1 }
          ]
        },
        GetData: AdminService.getSecureUploadDetails,
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {

          const appDbId = getSelectedAppDbId(fieldValues);

          if (appDbId) {
            return { projectId: fieldValues["0.1.1"].value, appDbId };
          }
          else if (fieldValues["14"] && fieldValues["14"].value == 1) {
            return { projectId: fieldValues["0.1.1"].value }
          }
        }
      }
    ],
    nextCondition: {
      logic: "and",
      subConditions: [
        { fieldId: "0.1.2", compare: "neq", value: null },
        {
          logic: "or",
          subConditions: [
            { fieldId: "1.1", compare: "neq", value: null },
            { fieldId: "2", compare: "eq", value: 1 },
            { fieldId: "2", compare: "eq", value: 3 },
            { fieldId: "2", compare: "eq", value: 4 },
            { fieldId: "2", compare: "eq", value: 5 },
            { fieldId: "3", compare: "neq", value: null },
            { fieldId: "6", compare: "eq", value: 1 },
            { fieldId: "6", compare: "eq", value: 2 },
            { fieldId: "6", compare: "eq", value: 3 },
            { fieldId: "6", compare: "eq", value: 4 },
            { fieldId: "6", compare: "eq", value: 5 },
            { fieldId: "7", compare: "eq", value: 1 },
            { fieldId: "7", compare: "eq", value: 2 },
            { fieldId: "8", compare: "eq", value: 1 },
            { fieldId: "8", compare: "eq", value: 2 },
            { fieldId: "8", compare: "eq", value: 4 },
            { fieldId: "14", compare: "eq", value: 1 },
            { fieldId: "15", compare: "eq", value: 1 },
            { fieldId: "15", compare: "eq", value: 2 },
            {
              logic: "and",
              subConditions: [
                { fieldId: "12", compare: "eq", value: 1 },
                { fieldId: "hiddenProcessingAppDb", compare: "neq", value: null },
              ]
            },
            {
              logic: "and",
              subConditions: [
                { fieldId: "12", compare: "eq", value: 2 },
                { fieldId: "2.2", compare: "neq", value: null },
                { fieldId: "hiddenHostingLocation", compare: "neq", value: null },
                { fieldId: "hiddenHostingLocation", compare: "neq", value: "Undetermined" },
              ]
            }
          ]
        }
      ]
    },
    header: "New Request",
    subHeader: "WELCOME TO EPIQ WORK REQUESTS",
    summaryTitle: "REQUEST",
    description:
      'To help us address your request, select a request from the list that best matches your needs. If the request you want isn\'t listed, select "Other request" or contact your Project Manager for assistance.'
  },
  {
    header: "Request: [`fieldLookup:0.1.2``lookupName:databaseHeader`]",
    subHeader: "ESTIMATED FILE SIZE",
    summaryTitle: "ESTIMATED FILE SIZE",
    description:
      "[`fieldLookup:8,12``lookupName:estimatedSizeDescription``defaultStr:Select the estimated size of the data you plan to upload, choose the option that most closely reflects the actual size of the upload to ensure a efficient upload process.`]",
    dependency: {
      logic: "or",
      subConditions: [
        { fieldId: "8", compare: "eq", value: 1 },
        { fieldId: "8", compare: "eq", value: 2 },
      ]
    },
    fields: [
      {
        type: "dropdown",
        name: "Estimated Size",
        fieldId: "9.5",
        label: "What is the estimated size of the upload (GB)?",
        active: true,
        requiredWhenActive: true,
        resetForField: "0.1.2",
        getDropdownOptions: (fieldValues: IFieldValueLookup) => {
          let ddOptions = [{ id: 1, title: "less than 1 GB" },
          { id: 5, title: "1-5 GB" },
          { id: 10, title: "5-10 GB" },
          { id: 50, title: "Greater than 10 GB" }]

          return ddOptions;
        },
      }
    ],
    nextCondition: { fieldId: "9.5", compare: "neq", value: null },
  },
  ...TrackDataWorkRequestSteps,
  ...ILMProjectDeleteWorkRequestSteps,
  {
    header: "Request: [`fieldLookup:0.1.2``lookupName:databaseHeader`]",
    subHeader: "[`fieldLookup:2``lookupName:describeSubHeader``defaultStr:DESCRIBE THE DATA`]",
    summaryTitle: "DATA",
    description:
      "[`fieldLookup:6,7``lookupName:describePageDescription``defaultStr:To process your request, we need to know more about the data that was previously provided and where you want the data loaded.`]",
    dependency: {
      logic: "or",
      subConditions: [
        { fieldId: "2", compare: "eq", value: 1 },
        { fieldId: "2", compare: "eq", value: 5 },
        { fieldId: "6", compare: "eq", value: 1 },
        { fieldId: "6", compare: "eq", value: 2 },
        { fieldId: "6", compare: "eq", value: 3 },
        { fieldId: "6", compare: "eq", value: 5 },
        { fieldId: "7", compare: "eq", value: 1 }
      ]
    },
    fields: [
      {
        type: "dropdown",
        fieldId: "4.1",
        label: "Identify where to apply the filtering.",
        name: "Apply to",
        requiredWhenActive: true,
        active: false,
        dependency: { fieldId: "2", compare: "eq", value: 5 },
        DropDownOptions: [
          {
            id: 1,
            title: "Apply to all processed data across case."
          },
          {
            id: 2,
            title: "Apply to specific custodians."
          },
          {
            id: 3,
            title: "Apply to specific data/media."
          }
        ]
      },
      {
        type: "displayOnlyField",
        name: "media_filtering",
        fieldId: "4.1c",
        label: "Select the media where you want the filtering applied. ",
        active: false,
        dependency: { fieldId: "4.1", compare: "eq", value: 3 }
      },
      {
        type: "singleselect",
        name: "Data/media",
        fieldId: "2.1",
        resetForField: "0.1.1",
        label:
          "Identify the data/media to use. Type the filename, data delivery format (FTP or harddrive), or date provided (MM/DD/YYYY).",
        active: false,
        requiredWhenActive: true,
        dependency: {
          logic: "or",
          subConditions: [
            { fieldId: "2", compare: "eq", value: 1 },
            { fieldId: "6", compare: "eq", value: 2 },
            { fieldId: "4.1", compare: "eq", value: 3 }
          ]
        },

        GetData: ResourceService.getResourcesUser,
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            let text = `${item.fields.MediaName}: ${new Date(item.fields.MediaDateRecieved).toLocaleDateString()} - ${item.fields.MediaType
              }`;
            if (item.fields.MediaDescription) text = `${text} - ${item.fields.MediaDescription}`;
            return {
              text,
              id: item.fields.MediaId,
              data: { lookupValues: { MediaName: item.fields.MediaName } }
            };
          });
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          const filters = [
            {
              logic: "or",
              filters: [{ field: "ObjectId", operator: "eq", value: fieldValues["0.1.1"].value }] as Array<
                FilterDescriptor
              >
            },
            {
              logic: "or",
              filters: [
                { field: "MediaName", operator: "contains", value: searchText },
                { field: "MediaType", operator: "contains", value: searchText },
                { field: "MediaDescription", operator: "contains", value: searchText }
              ] as Array<FilterDescriptor>
            }
          ] as CompositeFilterDescriptor[];

          //const date = searchText ? /^.*?(\d{2}\/\d{2}\/\d{4}).*?$/.exec(searchText) : null;
          const date = searchText ? /^.*?(\d{4}\-\d{2}\-\d{2}).*?$/.exec(searchText) : null;
          if (date) {
            filters[0].filters.push({ field: "MediaDateRecieved", operator: "eq", value: date[1] });
          }

          return {
            take: 100,
            typeCode: "ediscoveryproject",
            permissionsDelimited: "SubmitWorkRequest-ProcessData,SubmitWorkRequest-AnalyzeOrReview",
            showGroupsWithDupePermissions: true,
            flattenResults: true,
            dedupeFieldName: "MediaId",
            joinFields: ["Medias"],
            filters: searchText ? filters : [filters[0]],
            filterPresets: ["ActiveMedia"]
          };
        }
      },
      {
        type: "singleselect",
        fieldId: "2.2",
        resetForField: "0.1.1",
        name: "Hosting Database",
        label: "[`fieldLookup:0.1.2``lookupName:databaseLabel`]",
        active: false,
        requiredWhenActive: true,
        dependency: {
          logic: "or",
          subConditions: [
            { fieldId: "6", compare: "eq", value: 1 },
            { fieldId: "6", compare: "eq", value: 3 },
            { fieldId: "6", compare: "eq", value: 5 },
            { fieldId: "7", compare: "eq", value: 1 },
            {
              logic: "and",
              subConditions: [
                { fieldId: "6", compare: "eq", value: 2 },
                { fieldId: "2.1", compare: "neq", value: null, inactiveSetsResult: false }
              ]
            },
            {
              logic: "and",
              subConditions: [
                { fieldId: "2", compare: "eq", value: 1 },
                { fieldId: "2.1", compare: "neq", value: null, inactiveSetsResult: false }
              ]
            }
          ]
        },
        GetData: ResourceService.getResourcesUser,
        DataConverter: (dataItems: any) => {
          return [{ id: null, text: "Not Applicable" }].concat(
            dataItems.map((item: any) => {
              const text =
                item.fields["Relativity Host"] ||
                item.fields["Database Name"] ||
                item.fields["EqD Database Name"] ||
                item.fields["Db Record Type"] ||
                "Undefined";
              return {
                text,
                id: item.fields["AppDbId"],
                data: { lookupValues: { "Db Record Type": item.fields["Db Record Type"], "Platform": item.fields["Platform"] } }
              };
            })
          );
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          const filters = [
            {
              logic: "or",
              filters: [{ field: "ObjectId", operator: "eq", value: fieldValues["0.1.1"].value }] as Array<
                FilterDescriptor
              >
            }
          ] as CompositeFilterDescriptor[];

          if (searchText) {
            filters.push({
              logic: "or",
              filters: [
                { field: "Relativity Host Id", operator: "contains", value: searchText },
                { field: "Relativity Host", operator: "contains", value: searchText },
                { field: "Database Name", operator: "contains", value: searchText },
                { field: "EqD Database Name", operator: "contains", value: searchText },
                { field: "Db Record Type", operator: "contains", value: searchText }
              ] as Array<FilterDescriptor>
            });
          }

          const processingDbType = fieldValues["hiddenProcessingAppDb"] && fieldValues["hiddenProcessingAppDb"].lookupValues["Db Record Type"];
          const processPrefilter = fieldValues["2"] ? fieldValues["2"].value : 0;
          const deliveryLoc = fieldValues["0.1.1"].lookupValues["deliveryLocation"];
          const isValidDeliveryLoc = deliveryLoc === "ATL DC" || deliveryLoc === "BOS DC" || deliveryLoc === "POR DC"
            || deliveryLoc === "SEA DC" || deliveryLoc === "CORP" || deliveryLoc === "DEN FAL" || deliveryLoc === "Electronic Discovery - US";

          if (processingDbType === DBRecordType.Nuix && processPrefilter === 1 && isValidDeliveryLoc) {
            filters.push({
              logic: "or",
              filters: [
                { field: "Db Record Type", operator: "eq", value: DBRecordType.Relativity_Hosting }
              ] as Array<FilterDescriptor>
            });
          }

          return {
            take: 100,
            typeCode: "ediscoveryproject",
            permissionsDelimited: "SubmitWorkRequest-TrackMedia,SubmitWorkRequest-ProcessData,SubmitWorkRequest-AnalyzeOrReview,SubmitWorkRequest-ExportData",
            showGroupsWithDupePermissions: true,
            flattenResults: true,
            dedupeFieldName: "AppDbId",
            joinFields: ["ApplicationDatabases"],
            filters: filters,
            filterPresets: ["ActiveApps", "HostingApps"]
          };
        }
      },
      {
        type: "input",
        name: "Folder",
        fieldId: "5.4",
        label: "Provide the folder where this data should be loaded.",
        active: false,
        requiredWhenActive: true,
        dependency: {
          logic: "and",
          subConditions: [{ fieldId: "2.2", compare: "neq", value: null }, { fieldId: "6", compare: "eq", value: 2 }]
        }
      },
      {
        type: "displayOnlyField",
        name: "relSearchFolder",
        fieldId: "8.2b",
        label:
          "Identify the path to the documents you want to analyze. The search or folder MUST contain full families.",
        active: false,
        dependency: {
          logic: "and",
          subConditions: [{ fieldId: "2.2", compare: "neq", value: null }, { fieldId: "6", compare: "eq", value: 3 }]
        }
      },
      {
        type: "input",
        name: "Search or Folder",
        fieldId: "8.2",
        requiredWhenActive: true,
        label: "Provide the full path to the non-private search name or folder that contains the documents to analyze.",
        active: false,
        dependency: {
          logic: "and",
          subConditions: [{ fieldId: "2.2", compare: "falsy", value: false }, { fieldId: "6", compare: "eq", value: 3 }]
        }
      },
      {
        type: "numericTextField",
        name: "Document Count",
        fieldId: "8.3",
        min: 1,
        requiredWhenActive: true,
        validation: { compare: "gt", value: 0, failureMessage: "Must be one or more documents" },
        label: "Enter the document count from the saved search or folder.",
        active: false,
        dependency: {
          logic: "and",
          subConditions: [{ fieldId: "8.2", compare: "neq", value: null }, { fieldId: "6", compare: "eq", value: 3 }]
        }
      },
      {
        type: "dropdown",
        name: "Estimated Size",
        fieldId: "2.3",
        label: "What is the estimated size of the data upload (GB)?",
        active: false,
        requiredWhenActive: true,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "2", compare: "eq", value: 1 },
            { fieldId: "2.2", compare: "neq", value: null, inactiveSetsResult: false }
          ]
        },
        DropDownOptions: [
          { id: 1, title: "less than 1 GB" },
          { id: 5, title: "1-5 GB" },
          { id: 10, title: "5-10 GB" },
          { id: 50, title: "Greater than 10 GB" }
        ]
      },
      {
        type: "displayOnlyField",
        name: "specify_custodian",
        fieldId: "4.1b",
        label:
          "Specify the custodian using this format: last name, first name, middle initial. Press Enter to add another custodian, as needed.",
        active: false,
        dependency: { fieldId: "4.1", compare: "eq", value: 2 }
      },
      {
        type: "textarea",
        maxLength: 255,
        fieldId: "2.4.4",
        label: "Type the custodian(s).",
        requiredWhenActive: true,
        name: "Custodian",
        active: false,
        showCharacterCount: true,
        dependency: { fieldId: "4.1", compare: "eq", value: 2 }
      },
      {
        type: "input",
        name: "Search or Folder",
        fieldId: "7.2",
        label: "Provide the full path to the non-private search name or folder that contains the documents to export. ",
        active: false,
        requiredWhenActive: true,
        dependency: {
          logic: "and",
          subConditions: [{ fieldId: "7", compare: "eq", value: 1 }, { fieldId: "2.2", compare: "falsy", value: false }]
        }
      },
      {
        type: "numericTextField",
        name: "Document Count",
        fieldId: "5.8.1",
        min: 1,
        label: "Enter the document count from the saved search or folder.",
        active: false,
        requiredWhenActive: true,
        dependency: { fieldId: "7.2", compare: "falsy", value: false }
      },
      {
        type: "multidropdown",
        name: "Export Selections",
        fieldId: "7.4",
        label: "Select all of the export file types you need.",
        active: false,
        requiredWhenActive: true,
        dependency: { fieldId: "5.8.1", compare: "falsy", value: false },
        DropDownOptions: [
          { id: "Image files", title: "Image files" },
          { id: "Native files", title: "Native files" },
          { id: "Document Level Text File", title: "OCR extracted text files" },
          { id: "Metadata", title: "Metadata file" }
        ]
      }
    ]
  },
  {
    header: "Request: Analyze/Review Data",
    subHeader: "SET ANALYSIS OPTIONS",
    summaryTitle: "ANALYSIS",
    description:
      "To perform analysis on the documents, provide information about the type of analysis you need.  Analytics services enable you to categorize documents and streamline the review process.",
    dependency: { fieldId: "6", compare: "eq", value: 3 },
    fields: [
      {
        type: "displayOnlyField",
        name: "analyticsInstructions",
        fieldId: "8.5b",
        label:
          "You can request one or more of the following analytics. Near duplicates identifies textually similar documents. Email Threading identifies email relationships. Email Name Normalization matches data from email headers to normalize it. Language Detection identifies primary and secondary languages used. Repeated Content filter finds text to suppress from the Analytics Index.",
        active: false,
        dependency: { fieldId: "6", compare: "eq", value: 3 }
      },
      {
        type: "multidropdown",
        name: "Type",
        fieldId: "8.5",
        label: "Select the analytics to perform.",
        active: true,
        requiredWhenActive: true,
        DropDownOptions: [
          { id: "Structured Analytics - Near Duplicate", title: "Near Duplicates" },
          { id: "Structured Analytics - Email Threading", title: "Email Threading" },
          { id: "Structured Analytics – Name Normalization", title: "Email Name Normalization" },
          { id: "Structured Analytics - Language Detection", title: "Language Detection" },
          { id: "Structured Analytics - Repeated Content", title: "Repeated Content" }
        ]
      },
      {
        type: "dropdown",
        name: "Incremental?",
        fieldId: "8.5.2.1",
        active: false,
        requiredWhenActive: true,
        label: "Should Epiq add these documents as incremental to an existing email threading or near dupe set?",
        dependency: {
          logic: "or",
          subConditions: [
            { fieldId: "8.5", compare: "contains", value: "Structured Analytics - Near Duplicate" },
            { fieldId: "8.5", compare: "contains", value: "Structured Analytics - Email Threading" }
          ]
        },
        DropDownOptions: [
          { id: "Incremental Build", title: "Yes, add as incremental to an existing set" },
          { id: "Full Build", title: "No existing set" }
        ]
      }
    ]
  },
  {
    header: "Request: Process Data for Review",
    subHeader: "IDENTIFY THE CUSTODIANS",
    summaryTitle: "CUSTODIANS",
    description:
      "Provide one or more custodians to assign to this data. The custodian is the original owner of the data that will populate the Custodian field. Add the names using this format: last name, first name, middle initial. Press Enter to add another custodian.",
    dependency: { fieldId: "2", compare: "eq", value: 1 },
    fields: [
      {
        type: "dropdown",
        fieldId: "2.4.1",
        label: "Ingestion Type",
        name: "Ingestion Type",
        active: true,
        require: true,
        DropDownOptions: [
          { id: "Partial Media", title: "Partial Media" },
          { id: "Entire Media", title: "Entire Media" }
        ]
      },
      {
        type: "dropdown",
        fieldId: "2.4.2",
        label: "Custodian Assignment Type",
        name: "Custodian Assignment Type",
        active: true,
        require: true,
        DropDownOptions: [
          { id: "Single Custodian", title: "Single Custodian" },
          { id: "Multi-Custodian", title: "Multiple Custodians" }
        ]
      },
      {
        type: "input",
        max: 255,
        name: "Single Custodian Name",
        fieldId: "2.4.3",
        resetForField: "2.4.2",
        requiredWhenActive: true,
        label: "Single Custodian Name",
        validation: { compare: "regexp", regExp: "^[0-9A-Za-z\\ \\\"\\#\\$\\%\\&\\'\\(\\)\\+\\-\\,\\_\\.\\;\\^\\[\\]\\{\\}]*$", value: "", failureMessage: "You have entered an invalid character in Single Custodian Name field." },
        active: false,
        dependency: {
          logic: "and", subConditions: [{ fieldId: "2.4.2", compare: "eq", value: "Single Custodian" }, { fieldId: "hiddenProcessingAppDb", compare: "neq", value: DBRecordType.Epiq_Discovery_Processing, lookupName: "Db Record Type" }]
        }
      },
      {
        type: "input",
        max: 255,
        name: "First Name or Organization",
        fieldId: "2.4.5",
        resetForField: "2.4.2",
        requiredWhenActive: true,
        label: "First Name or Organization",
        active: false,
        validation: {
          logic: "and",
          subConditions: [{ compare: "regexp", regExp: ".*[a-zA-Z]{1}.*[a-zA-Z]{1}.*", value: "", failureMessage: "First Name or Organization must contain at least two alpha characters (a-z OR A-Z)." },
          { compare: "regexp", regExp: "^[0-9A-Za-z\\ \\#\\$\\&\\'\\+\\-\\.\\_\\^\\[\\]\\{\\}]+$", value: "", failureMessage: "You have entered an invalid character in First Name or Organization field" }]
        },
        dependency: {
          logic: "and", subConditions: [{ fieldId: "2.4.2", compare: "eq", value: "Single Custodian" }, { fieldId: "hiddenProcessingAppDb", compare: "eq", value: DBRecordType.Epiq_Discovery_Processing, lookupName: "Db Record Type" }]
        },
        cssName: () => "column-span2"
      },
      {
        type: "input",
        max: 255,
        name: "Last Name",
        fieldId: "2.4.6",
        resetForField: "2.4.2",
        requiredWhenActive: false,
        hideFieldIdLabel: true,
        label: "Last Name",
        active: false,
        validation: { compare: "regexp", regExp: "^[0-9A-Za-z\\ \\#\\$\\&\\'\\+\\-\\.\\_\\^\\[\\]\\{\\}]*$", value: "", failureMessage: "You have entered an invalid character in Last Name field" },
        dependency: {
          logic: "and", subConditions: [{ fieldId: "2.4.2", compare: "eq", value: "Single Custodian" }, { fieldId: "hiddenProcessingAppDb", compare: "eq", value: DBRecordType.Epiq_Discovery_Processing, lookupName: "Db Record Type" }]
        },
        cssName: () => "column-span2"
      },
      {
        type: "textarea",
        maxLength: 255,
        fieldId: "2.4.4",
        resetForField: "2.4.2",
        label: "Type the custodian(s).",
        name: "Custodian",
        active: false,
        requiredWhenActive: true,
        showCharacterCount: true,
        dependency: { fieldId: "2.4.2", compare: "eq", value: "Multi-Custodian" }
      },
      {
        type: "hiddenlookup",
        fieldId: "hiddenCustodiansValidation",
        name: "Custodians validation",
        lookupCompletion: (dataResult: any) => {
          let isValid = false;
          if (dataResult && dataResult.Custodians) {
            isValid = /.*[a-zA-Z]{1}.*[a-zA-Z]{1}.*/.test(dataResult.Custodians)
              && /^[0-9A-Za-z\ \#\$\&\'\+\-\.\_\^\[\]\{\}\r\n]+$/.test(dataResult.Custodians);
          }
          return { success: isValid };
        },
        lookupError:
          "You have entered an invalid character (or) It does not contain at least two alpha characters (a-z OR A-Z) in Custodians field.",
        watchField: "2.4.4",
        label: "Custodians validation",
        resetForField: "2.4.4",
        active: false,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "2.4.2", compare: "eq", value: "Multi-Custodian" },
            { fieldId: "hiddenProcessingAppDb", compare: "eq", value: DBRecordType.Epiq_Discovery_Processing, lookupName: "Db Record Type" },
            { fieldId: "2.4.4", compare: "neq", value: null },
            { fieldId: "2.4.4", compare: "neq", value: "" },
          ]
        },
        GetData: (dataResult: any) => {
          const result = {
            ok: true,
            data: dataResult
          } as ServiceResponseJson;
          return result;
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          return { Custodians: fieldValues["2.4.4"] && fieldValues["2.4.4"].value ? fieldValues["2.4.4"].value : "" };
        }
      }
    ],
    nextCondition: {
      logic: "or",
      subConditions: [{ logic: "and", subConditions: [{ fieldId: "2.4.3", compare: "neq", value: null }, { fieldId: "hiddenProcessingAppDb", compare: "neq", value: DBRecordType.Epiq_Discovery_Processing, lookupName: "Db Record Type" }] }, { logic: "and", subConditions: [{ fieldId: "2.4.5", compare: "neq", value: null }, { fieldId: "hiddenProcessingAppDb", compare: "eq", value: DBRecordType.Epiq_Discovery_Processing, lookupName: "Db Record Type" }] }, { fieldId: "2.4.4", compare: "neq", value: null }]
    }
  },
  {
    header: "Request: Process Data for Review",
    subHeader: "PROVIDE SEARCH LOCATION",
    description:
      "Indicate where you plan to run the search terms after approving the formatting. Processing is where data is extracted through search, deduplication, and filtering. Hosting is where review, export, and production occurs. In addition, search terms applied in Processing can later be run in Hosting via a separate request.",

    dependency: {
      logic: "or",
      subConditions: [
        { fieldId: "2", compare: "eq", value: 3 }
      ]
    },
    fields: [
      {
        type: "dropdown",
        name: "Search Location",
        fieldId: "2.1.1",
        label: "Select where you want to run the search terms.",
        active: false,
        dependency: { fieldId: "2", compare: "eq", value: 3, inactiveSetsResult: true },
        DropDownOptions: [
          { id: 1, title: "Hosting DB" },
          { id: 2, title: "Processing DB" }
        ]
      },
      {
        type: "singleselect",
        fieldId: "2.2a",
        resetForField: "0.1.1",
        name: "Database",
        label: "Select the hosting database for the data.",
        active: false,
        requiredWhenActive: true,
        dependency: { fieldId: "2.1.1", compare: "eq", value: 1 },
        GetData: ResourceService.getResourcesUser,
        DataConverter: (dataItems: any) => {
          return [{ id: null, text: "Not Applicable" }].concat(
            dataItems.map((item: any) => {
              const text =
                item.fields["Relativity Host"] ||
                item.fields["Database Name"] ||
                item.fields["EqD Database Name"] ||
                item.fields["Db Record Type"] ||
                "Undefined";
              return {
                text,
                id: item.fields["AppDbId"],
                data: { lookupValues: { "Db Record Type": item.fields["Db Record Type"], "Platform": item.fields["Platform"] } }
              };
            })
          );
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          const filters = [
            {
              logic: "or",
              filters: [{ field: "ObjectId", operator: "eq", value: fieldValues["0.1.1"].value }] as Array<
                FilterDescriptor
              >
            },
            {
              logic: "or",
              filters: [
                { field: "Relativity Host Id", operator: "contains", value: searchText },
                { field: "Relativity Host", operator: "contains", value: searchText },
                { field: "Database Name", operator: "contains", value: searchText },
                { field: "EqD Database Name", operator: "contains", value: searchText },
                { field: "Db Record Type", operator: "contains", value: searchText }
              ] as Array<FilterDescriptor>
            }
          ] as CompositeFilterDescriptor[];

          return {
            take: 100,
            typeCode: "ediscoveryproject",
            permissionsDelimited: "SubmitWorkRequest-TrackMedia,SubmitWorkRequest-SecureFileUpload,SubmitWorkRequest-ProcessData,SubmitWorkRequest-AnalyzeOrReview,SubmitWorkRequest-ExportData",
            showGroupsWithDupePermissions: true,
            flattenResults: true,
            dedupeFieldName: "AppDbId",
            joinFields: ["ApplicationDatabases"],
            filters: searchText ? filters : [filters[0]],
            filterPresets: ["ActiveApps", "HostingApps"]
          };
        }
      }
    ],
    nextCondition: {
      logic: "or",
      subConditions: [
        { fieldId: "2.1.1", compare: "eq", value: 2 },
        {
          logic: "and",
          subConditions: [
            { fieldId: "2.1.1", compare: "eq", value: 1 },
            { fieldId: "2.2a", compare: "neq", value: null }
          ]
        }
      ]
    }
  },
  {
    header: "Request: Process Data for Review",
    subHeader: "PROVIDE SEARCH TERMS",
    summaryTitle: "SEARCH TERMS",
    description:
      "To process the data, we need information about the search terms to apply. Let us know whether you previously provided us with search terms for formatting or want to provide search terms now.",

    dependency: {
      logic: "or",
      subConditions: [
        { fieldId: "2", compare: "eq", value: 1 },
        { fieldId: "2", compare: "eq", value: 3 },
        { fieldId: "2", compare: "eq", value: 5 }
      ]
    },
    fields: [
      {
        type: "dropdown",
        name: "Previously provided search terms",
        fieldId: "2.5.1.1",
        label: "Have you already provided the search terms for this data to Epiq for formatting?",
        active: false,
        dependency: { fieldId: "2", compare: "neq", value: 3, inactiveSetsResult: true },
        DropDownOptions: [
          { id: 1, title: "Yes, my search has been formatted." },
          { id: 2, title: "No, I want to upload my search term file now." },
          { id: 3, title: "Skip, do not apply any search terms." }
        ]
      },
      {
        type: "input",
        name: "Search Name",
        fieldId: "2.5.2.1",
        label: "Supply the name you want to use to identify this search.",
        active: false,
        dependency: {
          logic: "or",
          subConditions: [{ fieldId: "2.5.1.1", compare: "eq", value: 2 }, { fieldId: "2", compare: "eq", value: 3 }]
        }
      },
      {
        type: "fileupload",
        name: "New Filename",
        fieldId: "2.5.2.2",
        label: "Upload the file that contains your search terms. You can upload a TXT or CSV file.",
        active: false,
        allowedFileExtensions: ["txt", "csv"],
        dependency: {
          logic: "or",
          subConditions: [{ fieldId: "2.5.1.1", compare: "eq", value: 2 }, { fieldId: "2", compare: "eq", value: 3 }]
        }
      },
      {
        type: "dropdown",
        name: "Split Search Terms",
        fieldId: "2.5.2.3",
        hideSelectOne: true,
        label:
          "When formatting the search terms, by default for long strings, we split the terms into multiple shorter strings. We recommend this because it performs the same search but can improve performance.",
        resetForField: "0.1.2",
        active: false,
        dependency: {
          logic: "or",
          subConditions: [{ fieldId: "2.5.1.1", compare: "eq", value: 2 }, { fieldId: "2", compare: "eq", value: 3 }]
        },
        DropDownOptions: [{ id: "Yes", title: "Yes, split to increase performance." }, { id: "No", title: "No" }]
      },
      {
        type: "singleselect",
        name: "Search Name",
        fieldId: "2.5.1.2",
        label: "Select the name of the search you want applied to the data.",
        resetForField: "0.1.1",
        active: false,
        dependency: { fieldId: "2.5.1.1", compare: "eq", value: 1 },
        GetData: SupportService.getSearchWorkItemsByProject,
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            //const dateFormat = item.submitted && item.submitted.localDate ? ((item.submitted.getMonth() > 8) ? (item.submitted.getMonth() + 1) : ('0' + (item.submitted.getMonth() + 1))) + '/' + ((item.submitted.getDate() > 9) ? item.submitted.getDate() : ('0' + item.submitted.getDate())) + '/' + item.submitted.getFullYear() : "";
            const text = `${item.incidentId}: ${item.submitted.localDateString} - ${item.shortDescription}`;
            return { text, id: item.id };
          });
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          const filters = [
            {
              logic: "or",
              filters: [{ field: "shortDescription", operator: "contains", value: searchText }] as Array<
                FilterDescriptor
              >
            }
          ] as CompositeFilterDescriptor[];

          return {
            take: 100,
            filters: searchText ? filters : null,
            projectId: fieldValues["0.1.1"].value
          };
        }
      }
    ],
    nextCondition: {
      logic: "or",
      subConditions: [
        { fieldId: "2.5.1.2", compare: "neq", value: null },
        { fieldId: "2.5.1.1", compare: "eq", value: 3 },
        {
          logic: "and",
          subConditions: [
            { fieldId: "2.5.2.1", compare: "neq", value: null },
            { fieldId: "2.5.2.2", compare: "neq", value: null },
            { fieldId: "2.5.2.3", compare: "neq", value: null, trueWhenInactive: true }
          ]
        }
      ]
    }
  },
  {
    header: "Request: Analyze/Review Data",
    subHeader: "PROVIDE LOAD INFORMATION",
    summaryTitle: "LOAD INFORMATION",
    description:
      "To process your request, we need more information about the data to load, including the type of documents in the load. Typically, the third-party provides this information in the Load file.",
    dependency: { fieldId: "6", compare: "eq", value: 2 },
    fields: [
      {
        type: "displayOnlyField",
        name: "data_to_load",
        fieldId: "5.0.1b",
        label: "Tell us about the data to load. Is it a full production or loose files (PDFs)?",
        active: false,
        dependency: { fieldId: "6", compare: "eq", value: 2 }
      },
      {
        type: "dropdown",
        name: "Load Content",
        fieldId: "5.0.1",
        label: "Which selection best describes the data?",
        active: false,
        dependency: { fieldId: "6", compare: "eq", value: 2 },
        DropDownOptions: [
          {
            id: 1,
            title: "Load contains a full production."
          },
          { id: 2, title: "Load contains loose files." },
          { id: 3, title: "Unsure, contact my Project Manager." }
        ]
      },
      {
        type: "displayOnlyField",
        name: "data_to_load",
        fieldId: "5.3b",
        label:
          "Production loads typically provide multiple types of files, including Metadata, Images, Natives, and Text. Loose files include one or more load file types.",
        active: false,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "5.0.1", compare: "neq", value: null },
            { fieldId: "5.0.1", compare: "neq", value: 3 }
          ]
        }
      },
      {
        type: "multidropdown",
        name: "Load Data Types",
        fieldId: "5.3",
        label: "Select all load types that apply.",
        active: false,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "5.0.1", compare: "neq", value: null },
            { fieldId: "5.0.1", compare: "neq", value: 3 }
          ]
        },

        DropDownOptions: [
          { id: "Metadata", title: "Metadata" },
          { id: "Images", title: "Images" },
          { id: "Natives", title: "Natives" },
          { id: "Text", title: "Text" }
        ]
      },
      {
        type: "dropdown",
        name: "Load Images As",
        fieldId: "5.3.2.1",
        label: "Epiq can load the images as Original Images or Production Images. How do you want to load the images?",
        active: false,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "5.3", compare: "hascontent", value: true },
            { fieldId: "5.3", compare: "contains", value: "Images" }
          ]
        },
        DropDownOptions: [
          {
            id: "Original Image Set",
            title: "Original Images"
          },
          { id: "Production Image Set", title: "Production Images" }
        ]
      }
    ],
    nextCondition: {
      logic: "or",
      subConditions: [
        { fieldId: "5.0.1", compare: "eq", value: 3 },
        { fieldId: "5.3", compare: "contains", value: "Metadata" },
        { fieldId: "5.3", compare: "contains", value: "Natives" },
        { fieldId: "5.3", compare: "contains", value: "Text" },
        {
          logic: "and",
          subConditions: [
            { fieldId: "5.0.1", compare: "neq", value: 3 },
            { fieldId: "5.3", compare: "contains", value: "Images" },
            { fieldId: "5.3.2.1", compare: "neq", value: null }
          ]
        }
      ]
    }
  },
  {
    header: "Request: Analyze/Review Data",
    subHeader: "SET DOCUMENT IDENTIFIER",
    summaryTitle: "DOCUMENT IDENTIFIER",
    description:
      "To load the data, we need to know how to identify the new documents. Specify whether to use the Load file DocID with or without an EPQ prefix, or provide other instructions.",
    dependency: { fieldId: "6", compare: "eq", value: 2 },
    fields: [
      {
        type: "dropdown",
        name: "Identification",
        fieldId: "5.5",
        label: "How should Epiq identify the new documents?",
        active: false,
        dependency: { fieldId: "6", compare: "eq", value: 2 },
        DropDownOptions: [
          {
            id: "Client Provided (Loadfile/Xref)",
            title: "Use the load file DocID/Begin Bates."
          },
          { id: "Prefix Default (EPQ_######_########)", title: "Add EPQ to prefix the load file DocID." },
          { id: "Custom (See Mapping)", title: "Use custom instructions." }
        ]
      },
      {
        type: "input",
        name: "Customize",
        fieldId: "5.5.1",
        label: "Provide the custom DocID instructions.",
        active: false,
        dependency: {
          logic: "and",
          subConditions: [{ fieldId: "5.5", compare: "eq", value: "Custom (See Mapping)" }]
        }
      },
      {
        type: "dropdown",
        name: "Image?",
        fieldId: "5.6",
        label: "If the load image set contains some documents without images, do you want Epiq to image them?",
        active: false,
        dependency: {
          logic: "and",
          subConditions: [
            {
              logic: "or",
              subConditions: [
                { fieldId: "5.5.1", compare: "falsy", value: false, inactiveSetsResult: true },
                { fieldId: "5.5", compare: "neq", value: "Custom (See Mapping)", inactiveSetsResult: true }
              ]
            },
            { fieldId: "5.3", compare: "contains", value: "Natives" },
            { fieldId: "5.5", compare: "neq", value: null }
          ]
        },

        DropDownOptions: [
          {
            id: "Yes",
            title: "Yes"
          },
          { id: "No", title: "No" }
        ]
      },
      {
        type: "dropdown",
        name: "OCR?",
        fieldId: "5.7",
        label: "If the load contains some documents without text, do you want Epiq to OCR them?",
        active: false,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "5.5.1", compare: "falsy", value: false, inactiveSetsResult: true },
            { fieldId: "5.3", compare: "contains", value: "Images" },
            { fieldId: "5.5", compare: "neq", value: null },
            { fieldId: "5.6", compare: "falsy", value: false, inactiveSetsResult: true }
          ]
        },
        DropDownOptions: [
          {
            id: "Yes",
            title: "Yes"
          },
          { id: "No", title: "No" }
        ]
      }
    ],
    nextCondition: {
      logic: "and",
      subConditions: [
        { fieldId: "5.5", compare: "neq", value: null },
        { fieldId: "5.5.1", compare: "falsy", value: false, inactiveSetsResult: true },
        { fieldId: "5.6", compare: "neq", value: null, inactiveSetsResult: true },
        { fieldId: "5.7", compare: "neq", value: null, inactiveSetsResult: true }
      ]
    }
  },
  {
    header: "Request: Analyze/Review Data",
    subHeader: "SUPPLY DOCUMENT COUNT",
    summaryTitle: "DOCUMENT COUNT",
    description:
      "Provide the expected document counts for this third-party load. The cover letter from the third-party typically lists the document count by type and the total count.",
    dependency: { fieldId: "6", compare: "eq", value: 2 },
    fields: [
      {
        type: "displayOnlyField",
        name: "number_of_docs",
        fieldId: "5.8b",
        label: "The following questions require information about the number of documents in the load.",
        active: false,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "5.0.1", compare: "neq", value: null },
            { fieldId: "5.0.1", compare: "neq", value: 3 }
          ]
        }
      },
      {
        type: "dropdown",
        name: "Supply Document Count?",
        fieldId: "5.8",
        label: "Do you know the number of documents in the load file?",
        resetForField: "0.1.2",
        active: false,
        dependency: { fieldId: "6", compare: "eq", value: 2 },
        DropDownOptions: [
          {
            id: 1,
            title: "Yes, I can supply that information."
          },
          { id: 2, title: "No, I don't have the document counts." }
        ]
      },
      {
        type: "numericTextField",
        name: "Total Documents",
        fieldId: "5.8.1",
        min: 1,
        label: "Enter the expected document count from the third-party load.",
        active: false,
        dependency: {
          logic: "and",
          subConditions: [{ fieldId: "5.8", compare: "neq", value: null }, { fieldId: "5.8", compare: "neq", value: 2 }]
        }
      },
      {
        type: "numericTextField",
        name: "Native Count",
        fieldId: "5.9",
        min: 1,
        label: "Enter the expected native count.",
        active: false,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "5.3", compare: "contains", value: "Natives" },
            { fieldId: "5.8", compare: "neq", value: 2 },
            { fieldId: "5.8", compare: "neq", value: null },
            { fieldId: "5.8.1", compare: "neq", value: null }
          ]
        }
      },
      {
        type: "numericTextField",
        name: "Image Count",
        fieldId: "5.10",
        min: 1,
        label: "Enter the expected image count.",
        active: false,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "5.3", compare: "contains", value: "Images" },
            { fieldId: "5.8", compare: "neq", value: 2 },
            { fieldId: "5.8", compare: "neq", value: null },
            { fieldId: "5.8.1", compare: "neq", value: null },
            { fieldId: "5.9", compare: "falsy", value: false, inactiveSetsResult: true }
          ]
        }
      }
    ],
    nextCondition: {
      logic: "or",
      subConditions: [
        { fieldId: "5.8", compare: "eq", value: 2 },
        {
          logic: "and",
          subConditions: [
            { fieldId: "5.8", compare: "neq", value: null },
            { fieldId: "5.8.1", compare: "neq", value: null, inactiveSetsResult: true },
            { fieldId: "5.9", compare: "neq", value: null, inactiveSetsResult: true },
            { fieldId: "5.10", compare: "neq", value: null, inactiveSetsResult: true }
          ]
        }
      ]
    }
  },
  {
    header: "Request: Analyze/Review Data",
    subHeader: "PICK THE DOCUMENTS",
    summaryTitle: "PICK THE DOCUMENTS",
    description:
      "[`fieldLookup:6``lookupName:dynamicDescription`]",
    staticText: "[`fieldLookup:6``lookupName:extraSummary`]",
    dependency: {
      logic: "or", subConditions: [{ fieldId: "6", compare: "eq", value: 1 }, { fieldId: "6", compare: "eq", value: 5 }]
    },
    fields: [
      {
        type: "input",
        name: "Path to Search/Folder",
        fieldId: "6.2",
        label: "Enter the search name or path to the folder containing the documents.",
        active: true,
        dependency: {
          logic: "or", subConditions: [{ fieldId: "6", compare: "eq", value: 1 }, { fieldId: "6", compare: "eq", value: 5 }]
        }
      },
      {
        type: "numericTextField",
        name: "Total Documents",
        fieldId: "6.3",
        min: 1,
        label: "Enter the number of associated documents.",
        active: true,
        dependency: { fieldId: "6.2", compare: "neq", value: null }
      }
    ],
    nextCondition: {
      logic: "and",
      subConditions: [{ fieldId: "6.3", compare: "neq", value: null }, { fieldId: "6.3", compare: "neq", value: 0 }]
    }
  },
  {
    header: "Request: Analyze/Review Data",
    subHeader: "IMAGE DOCUMENTS",
    summaryTitle: "IMAGES",
    description:
      "Select whether you want Epiq to image the documents.",
    dependency: { fieldId: "6", compare: "eq", value: 1 },
    fields: [
      {
        type: "hiddenField",
        name: "Create Images",
        fieldId: "6.4",
        label: "Have these documents already been imaged?",
        active: true,
        dependency: { fieldId: "6", compare: "eq", value: 1 },
        value: "No",
        altDisplayValue: "Yes"
      },
      {
        type: "displayOnlyField",
        name: "keep_replace_images",
        fieldId: "6.4.1b",
        label:
          "If an image already exists, what do you want Epiq to do? Keep the existing image? Or, replace the existing image with a new image, which deletes any redactions.",
        active: false
      },
      {
        type: "dropdown",
        name: "Replace images",
        fieldId: "6.4.1",
        label: "Replace existing images?",
        active: false,
        DropDownOptions: [
          { id: "Yes", title: "Yes, replace existing images." },
          { id: "No", title: "No, do not replace any existing images" }
        ]
      },
      {
        type: "displayOnlyField",
        name: "replace_images_text",
        fieldId: "6.4.2b",
        label:
          "The time zone setting applies a uniform time zone to specific date fields in time-based documents, such as Sent Date and Received Date in email.",
        active: false
      },
      {
        type: "dropdown",
        name: "Time Zone",
        fieldId: "6.4.2",
        label: "Select time zone",
        active: false,
        DropDownOptions: timeZoneListValues
      }
    ],
    nextCondition: {
      logic: "or",
      subConditions: [
        { fieldId: "6.4.2", compare: "neq", value: null }
      ]
    }
  },
  {
    header: "Request: Analyze/Review Data",
    subHeader: "OCR DOCUMENTS",
    summaryTitle: "OCR",
    description:
      "Select whether you want Epiq to OCR the document images. OCR creates text from image-based documents to enable searching by text and other capabilities.",
    dependency: { fieldId: "6", compare: "eq", value: 5 },
    fields: [
      {
        type: "hiddenField",
        name: "OCR Images",
        fieldId: "6.5",
        label: "Do you want to OCR the documents?",
        active: true,
        value: "Yes"

      },
      {
        type: "numericTextField",
        name: "Total Pages",
        fieldId: "6.5.1",
        min: 1,
        label: "Enter the number of  document pages to OCR.",
        active: false,
        dependency: { fieldId: "6", compare: "eq", value: 5 }
      },
      {
        type: "dropdown",
        name: "OCR Language",
        fieldId: "6.5.5",
        label: "Select the OCR language/character set to use.",
        active: false,
        dependency: { fieldId: "6", compare: "eq", value: 5 },
        DropDownOptions: languageListValue
      }
    ],
    nextCondition: {
      logic: "and",
      subConditions: [
        { fieldId: "6.5.1", compare: "neq", value: 0 },
        { fieldId: "6.5.1", compare: "neq", value: null },
        { fieldId: "6.5.5", compare: "neq", value: null }
      ]
    }
  },
  {
    header: "Request: Process Data for Review",
    subHeader: "FILTER BY DATES",
    summaryTitle: "DATE FILTER",
    description:
      "Let us know if you want to apply a date filter to the data. If so, we can filter the data by a date range you supply and promote those hits.",
    dependency: {
      logic: "or", subConditions: [
        { fieldId: "2", compare: "eq", value: 1 },
        { fieldId: "2", compare: "eq", value: 5 }
      ]
    },
    fields: [
      {
        type: "dropdown",
        fieldId: "2.5.3",
        label: "Do you want Epiq to filter the processed data by a specific range of dates?",
        name: "Apply",
        active: true,
        //dependency: { fieldId: "2.6", compare: "eq", value: 2 },
        DropDownOptions: [{ id: "Yes", title: "Yes" }, { id: "No", title: "No" }]
      },
      {
        type: "date",
        fieldId: "2.5.3.1",
        label: "Start Date",
        name: "Start Date",
        active: false,
        dependency: { fieldId: "2.5.3", compare: "eq", value: "Yes" },
        validation: { fieldId: "2.5.3.2", compare: "lt", failureMessage: "start date must be less than end date" }
      },
      {
        type: "date",
        fieldId: "2.5.3.2",
        label: "End Date",
        name: "End Date",
        active: false,
        dependency: { fieldId: "2.5.3", compare: "eq", value: "Yes" },
        validation: {
          fieldId: "2.5.3.1",
          compare: "gt",
          failureMessage: "end date must be greater than start date"
        }
      }
    ],
    nextCondition: {
      logic: "or",
      subConditions: [
        { fieldId: "2.5.3", compare: "eq", value: "No" },
        {
          logic: "and",
          subConditions: [
            { fieldId: "2.5.3.1", compare: "neq", value: null },
            { fieldId: "2.5.3.2", compare: "neq", value: null }
          ]
        }
      ]
    }
  },
  {
    header: "Request: Process Data for Review",
    subHeader: "PROMOTE HITS",
    summaryTitle: "PROMOTE",
    description:
      "[`fieldLookup:2``lookupName:hitsDescription``defaultStr:After processing and filtering (if needed), confirm that you want Epiq to proceed with loading the new documents to the hosting database.`]",
    dependency: {
      logic: "or", subConditions: [
        { fieldId: "2", compare: "eq", value: 1 },
        { fieldId: "2", compare: "eq", value: 5 }
      ]
    },
    fields: [
      {
        type: "dropdown",
        name: "Promote hits?",
        fieldId: "2.6",
        label: "Do you want to promote the new documents?",
        active: true,
        resetForField: "0.1.2",
        requiredWhenActive: true,
        dependency: { fieldId: "2.5.3", compare: "neq", value: null },
        DropDownOptions: [
          { id: "Yes", title: "Yes, proceed with loading new documents." },
          { id: "No", title: "No, send me a Search Hit report for approval before loading." }
        ]
      },
      {
        type: "singleselect",
        fieldId: "2.2b",
        resetForField: "0.1.1",
        name: "Hosting Database",
        label: "[`fieldLookup:0.1.2``lookupName:databaseLabel`]",
        active: false,
        requiredWhenActive: true,
        dependency: {
          logic: "and",
          subConditions: [{ fieldId: "2", compare: "eq", value: 5 }]
        },
        GetData: ResourceService.getResourcesUser,
        DataConverter: (dataItems: any) => {
          return [{ id: null, text: "Not Applicable" }].concat(
            dataItems.map((item: any) => {
              const text =
                item.fields["Relativity Host"] ||
                item.fields["Database Name"] ||
                item.fields["EqD Database Name"] ||
                item.fields["Db Record Type"] ||
                "Undefined";
              return {
                text,
                id: item.fields["AppDbId"],
                data: { lookupValues: { "Db Record Type": item.fields["Db Record Type"], "Platform": item.fields["Platform"] } }
              };
            })
          );
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          const filters = [
            {
              logic: "or",
              filters: [{ field: "ObjectId", operator: "eq", value: fieldValues["0.1.1"].value }] as Array<
                FilterDescriptor
              >
            },
            {
              logic: "or",
              filters: [
                { field: "Relativity Host Id", operator: "contains", value: searchText },
                { field: "Relativity Host", operator: "contains", value: searchText },
                { field: "Database Name", operator: "contains", value: searchText },
                { field: "EqD Database Name", operator: "contains", value: searchText },
                { field: "Db Record Type", operator: "contains", value: searchText }
              ] as Array<FilterDescriptor>
            }
          ] as CompositeFilterDescriptor[];

          return {
            take: 100,
            typeCode: "ediscoveryproject",
            permissionsDelimited: "SubmitWorkRequest-ProcessData",
            showGroupsWithDupePermissions: true,
            flattenResults: true,
            dedupeFieldName: "AppDbId",
            joinFields: ["ApplicationDatabases"],
            filters: searchText ? filters : [filters[0]],
            filterPresets: ["ActiveApps", "HostingApps"]
          };
        }
      },
      {
        type: "dropdown",
        name: "Create Images",
        fieldId: "4.6",
        label:
          "For the promoted documents, Epiq loads the Natives. Do you also want the documents converted to images?",
        active: false,
        requiredWhenActive: true,
        dependency: {
          logic: "or",
          subConditions: [
            {
              logic: "and",
              subConditions: [
                { fieldId: "2", compare: "eq", value: 1 },
                { fieldId: "2.6", compare: "falsy", value: false },
                { fieldId: "2.6", compare: "neq", value: "No" },
                {
                  fieldId: "hiddenProcessingAppDb",
                  compare: "eq",
                  value: DBRecordType.DMX14_Processing,
                  lookupName: "Db Record Type"
                }
              ]
            },
            {
              logic: "and",
              subConditions: [
                { fieldId: "2", compare: "eq", value: 5 },
                { fieldId: "2.2b", compare: "neq", value: null },
                { fieldId: "2.6", compare: "falsy", value: false },
                { fieldId: "2.6", compare: "neq", value: "No" },
                {
                  fieldId: "hiddenProcessingAppDb",
                  compare: "eq",
                  value: DBRecordType.DMX14_Processing,
                  lookupName: "Db Record Type"
                }
              ]
            }
          ]
        },
        DropDownOptions: [{ id: "Yes", title: "Yes" }, { id: "No", title: "No" }]
      },
      {
        type: "dropdown",
        name: "OCR Images",
        fieldId: "2.8",
        label:
          "OCR creates text from image-based documents to enable searching by text and other capabilities. Do you want to OCR the documents?",
        active: false,
        requiredWhenActive: true,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "2", compare: "eq", value: 1 },
            { fieldId: "2.6", compare: "falsy", value: false },
            { fieldId: "2.6", compare: "neq", value: "No" },
            { fieldId: "hiddenProcessingAppDb", compare: "eq", value: DBRecordType.Nuix, lookupName: "Db Record Type" }
          ]
        },
        DropDownOptions: [{ id: "Case Setting", title: "Yes" }, { id: "None", title: "No" }]
      },
      {
        type: "dropdown",
        name: "Dedupe",
        fieldId: "4.7",
        label: "Do you want to dedupe this promoted data against existing data?",
        active: false,
        requiredWhenActive: true,
        dependency: {
          logic: "or",
          subConditions: [
            {
              logic: "and",
              subConditions: [
                { fieldId: "2", compare: "eq", value: 1 }
              ]
            },
            {
              logic: "and",
              subConditions: [
                { fieldId: "2", compare: "eq", value: 5 },
                { fieldId: "hiddenProcessingAppDb", compare: "neq", value: DBRecordType.DMX14_Processing, lookupName: "Db Record Type" }
              ]
            }
          ]
        },
        DropDownOptions: [{ id: "Global", title: "Yes, globally dedupe" }, { id: "Custodian", title: "Yes, dedupe by custodian" }, { id: "None", title: "No, do not dedupe" }]
      }
    ]
  },
  {
    header: "Request: Export Data",
    subHeader: "INCLUDE NATIVE FILES",
    summaryTitle: "NATIVE FILES",
    description:
      "Provide the location of the Native files. Choose to export all Native files from the export location provided in the previous page or supply a separate search name or folder for specific Native files. For example, when exporting only Native files in XLS format, provide the path to where that subset is located.",
    dependency: { logic: "and", subConditions: [{ fieldId: "7", compare: "eq", value: 1 }, { fieldId: "7.4", compare: "contains", value: "Native files" }] },
    fields: [
      {
        type: "dropdown",
        name: "Different Search or Folder",
        fieldId: "7.4.1",
        label:
          "Are the native files located in a separate search name or folder?",
        active: true,
        requiredWhenActive: true,
        dependency: { fieldId: "7.4", compare: "contains", value: "Native files" },
        DropDownOptions: [
          { id: "Yes", title: "Yes" },
          { id: "No", title: "No" },
        ]
      },
      {
        type: "input",
        name: "Native Search or Folder",
        fieldId: "7.4.2",
        label:
          "Provide the full path to the non-private search name or folder that contains the documents to export.",
        active: false,
        requiredWhenActive: true,
        dependency: { fieldId: "7.4.1", compare: "eq", value: "Yes" }
      }
    ],
    nextCondition: {
      logic: "or",
      subConditions: [
        { fieldId: "7.4.1", compare: "eq", value: "No" },
        { fieldId: "7.4.2", compare: "falsy", value: false }
      ]
    }
  },
  {
    header: "Request: Export Data",
    subHeader: "INCLUDE IMAGE FILES",
    summaryTitle: "IMAGE FILES",
    description:
      "To include image files, select the image format and type. PDF format provides an image you can view and TIFF format creates a non-viewable format for transfer purposes. Choose which image files to include: Production or Original Images. When you select Production Images, the system exports the highest bates numbered production images, stamped with existing redactions and branding.",
    dependency: { logic: "and", subConditions: [{ fieldId: "7", compare: "eq", value: 1 }, { fieldId: "7.4", compare: "contains", value: "Image files" }] },
    fields: [
      {
        type: "dropdown",
        name: "Format",
        fieldId: "7.7",
        label:
          "For a readable format, choose a PDF option. Epiq creates separate PDFs for each document, including attachments. When exporting images to another system, select machine-readable TIFF. Select the image file format.",
        active: false,
        dependency: { fieldId: "7.4", compare: "contains", value: "Image files" },
        DropDownOptions: [
          { id: "PDF (Searchable)", title: "PDF, searchable" },
          { id: "PDF (Non-searchable)", title: "PDF image, non-searchable" },
          { id: "Single Page TIF/JPG", title: "Single page (TIFF/JPG)" },
          { id: "Multi-page TIF", title: "Multi-page TIFF" }
        ]
      },
      {
        type: "dropdown",
        name: "Image Type",
        fieldId: "7.7.1",
        label:
          "For image type, select Production Images only, Original Images only (no stamping or redactions), or a combination (when no Production Images exist, export Original Images).",
        active: false,
        dependency: { fieldId: "7.7", compare: "neq", value: null },
        DropDownOptions: [
          {
            id: "Production Images only (where absent, exclude image)",
            title: "Production Images only (where absent, exclude image)"
          },
          {
            id: "Original Images only", title: "Original Images only"
          },
          {
            id: "Production Images (where absent, use Original Image)",
            title: "Production Images (where absent, use Original Image)"
          }
        ]
      }
    ],
    nextCondition: { fieldId: "7.7.1", compare: "neq", value: null }
  },
  {
    header: "Request: Export Data",
    subHeader: "EXPORT DELIVERY METHOD",
    summaryTitle: "EXPORT DELIVERY",
    description:
      "After creating the export, Epiq can post the resulting export to an SFTP site or copy it to an encrypted USB and ship it via courier. For SFTP delivery, Epiq provides files in RAR file format.",
    dependency: { fieldId: "7", compare: "eq", value: 1 },
    fields: [
      {
        type: "dropdown",
        name: "Method",
        fieldId: "7.10",
        label: "How do you want the export delivered to you?",
        active: true,
        DropDownOptions: [
          { id: "Delivery to SFTP site", title: "Delivery to SFTP site" },
          { id: "Ship encrypted USB", title: "Ship encrypted USB" }
        ]
      },
      {
        type: "input",
        name: "SFTP URL",
        fieldId: "7.10.1.1",
        label: "[`fieldLookup:0.1.2``lookupName:ftpUrlLabel`]",
        resetForField: "0.1.2",
        active: false,
        dependency: { fieldId: "7.10", compare: "eq", value: "Delivery to SFTP site" }
      },
      {
        type: "numericTextField",
        name: "Copies",
        fieldId: "7.10.2.1",
        min: 1,
        label: "How many copies of the data do you need?",
        active: false,
        dependency: { fieldId: "7.10", compare: "eq", value: "Ship encrypted USB" }
      },
      {
        type: "dropdown",
        name: "Label",
        fieldId: "7.10.2.2",
        label: "Provide instructions for labeling the encrypted USB. Standard label includes project #, request #, and ship date.",
        active: false,
        dependency: { fieldId: "7.10.2.1", compare: "falsy", value: false },
        DropDownOptions: [
          { id: "Case,Job,Contents", title: "Yes, standard label " },
          { id: "No Label", title: "No, do not label" },
          { id: "Custom", title: "Yes, apply a custom label" }
        ]
      },
      {
        type: "textarea",
        name: "Custom Label",
        fieldId: "7.10.2.2b",
        label: "Supply the custom label.",
        active: false,
        dependency: { fieldId: "7.10.2.2", compare: "eq", value: "Custom" }
      },
      {
        type: "textarea",
        name: "Address",
        fieldId: "7.10.2.3",
        label: "Enter the name and address for the courier delivery.",
        resetForField: "0.1.2",
        active: false,
        dependency: {
          logic: "or",
          subConditions: [
            {
              logic: "and",
              subConditions: [
                { fieldId: "7.10.2.2", compare: "neq", value: null },
                { fieldId: "7.10.2.2", compare: "neq", value: "Custom" },
                { fieldId: "7.10.2.2b", compare: "eq", value: null, inactiveSetsResult: true }
              ]
            },

            {
              logic: "and",
              subConditions: [
                { fieldId: "7.10.2.2", compare: "eq", value: "Custom" },
                { fieldId: "7.10.2.2b", compare: "falsy", value: false, inactiveSetsResult: true }
              ]
            }
          ]
        }
      }
    ],
    nextCondition: {
      logic: "or",
      subConditions: [
        { fieldId: "7.10.1.1", compare: "falsy", value: false },
        { fieldId: "7.10.2.3", compare: "falsy", value: false }
      ]
    }
  },
  {
    header: "Request: Track FTP or Shipped Data",
    subHeader: "SHIPPING DETAIL",
    summaryTitle: "SHIPPING",
    description:
      "Provide more information about where the data was shipped to, the Courier you used, such as Fedx or UPS, and the tracking number for the shipment. In addition, let us know if the data is encrypted.",
    dependency: { fieldId: "8", compare: "eq", value: 1 },
    fields: [
      {
        type: "dropdown",
        name: "Shipped To",
        fieldId: "9.1.1",
        label: "Select the Epiq facility where you shipped the hard drive.",
        active: true,
        requiredWhenActive: true,
        dependency: { fieldId: "8", compare: "eq", value: 1 },
        DropDownOptions: shippingListValues
      },
      {
        type: "input",
        name: "Courier/Tracking #",
        fieldId: "9.1.2",
        label: "Provide the Courier name and tracking number.",
        active: false,
        requiredWhenActive: true,
        dependency: { fieldId: "9.1.1", compare: "falsy", value: false }
      },
      {
        type: "dropdown",
        name: "Encrypted?",
        fieldId: "9.3",
        label: "Is the data you shipped encrypted?",
        active: false,
        requiredWhenActive: true,
        dependency: { fieldId: "9.1.2", compare: "falsy", value: false },
        DropDownOptions: [{ id: "Yes", title: "Yes" }, { id: "No", title: "No" }]
      },
      {
        type: "passwordField",
        name: "Password",
        subType: "password",
        fieldId: "9.3.1",
        label: "Enter the password for the shipped data.",
        active: false,
        requiredWhenActive: true,
        dependency: { fieldId: "9.3", compare: "eq", value: "Yes" }
      }
    ]
  },
  {
    header: "Request: [`fieldLookup:0.1.2``lookupName:databaseHeader`]",
    subHeader: "DATA PLACEMENT",
    summaryTitle: "PLACEMENT",
    description:
      "This request sets up tracking on your data. To help us place the data in the appropriate location, we need to know the type of work intended for the data.",
    //dependency: { fieldId: "8", compare: "eq", value: 1 }, DELETE ME
    dependency: {
      logic: "or",
      subConditions: [
        { fieldId: "8", compare: "eq", value: 1 },
        { fieldId: "8", compare: "eq", value: 2 }
      ]
    },
    fields: [
      {
        type: "dropdown",
        name: "Expected Purpose",
        fieldId: "9.4",
        label: "What do you expect to do with this data?",
        resetForField: "0.1.2",
        active: true,
        requiredWhenActive: true,
        //dependency: { fieldId: "8", compare: "eq", value: 1 },
        dependency: {
          logic: "or",
          subConditions: [
            { fieldId: "8", compare: "eq", value: 1 },
            { fieldId: "8", compare: "eq", value: 2 }
          ]
        },
        DropDownOptions: [
          { id: "Processing", title: "Process data" },
          { id: "3rd Party Load", title: "Load as third-party data" },
          { id: "Unknown", title: "Other/not sure yet" }
        ],
        cssName: (existingFieldVals: IFieldValueLookup) => {
          const valueInfo: IWizardValues = existingFieldVals["9.4"];

          return valueInfo && (valueInfo.value === "Processing") && "info-icon";
        },
        fieldAdditionalNotes: (existingFieldVals: IFieldValueLookup) => {
          const valueInfo: IWizardValues = existingFieldVals["9.4"];

          return (valueInfo && (valueInfo.value === "Processing") ?
            `This request informs Epiq to download the data and assign the media number. After this request completes, 
          you will need to start a new work request to process or load the data.` : "")
        },
      },

    ]
  },
  {
    header: "Request: Use Easy Upload",
    subHeader: "PROVIDE DATA SOURCE & CUSTODIANS",
    summaryTitle: "DATA & CUSTODIAN",
    description: "Select the data source and enter one or more custodians for the data you are uploading. This information helps identify the origin or type of files, as well as who had administrative control of the data.",
    dependency: {
      logic: "or",
      subConditions: [
        { fieldId: "12", compare: "eq", value: 1 },
      ]
    },
    fields: [
      {
        type: "dropdown",
        name: "Data Source",
        fieldId: "12.1.1",
        label: "Select the Data Source.",
        active: true,
        requiredWhenActive: true,
        DropDownOptions: [
          { id: "Loose Files", title: "Loose Files" },
          { id: "Email", title: "Email" },
          { id: "Personal Computer", title: "Computer" },
          { id: "Hard Drive", title: "External Hard Drive" },
          { id: "M365 OneDrive", title: "M365 Data" },
          { id: "Google Drive", title: "Google Drive" },
          { id: "Custom", title: "Other Data Source" },
          { id: "None", title: "Skip, Uncertain" },
        ]
      },
      {
        type: "input",
        name: "Other Data Source",
        fieldId: "12.1.2",
        label: "Enter the Data Source.",
        resetForField: "12.1.1",
        active: false,
        validation: { compare: "regexp", regExp: "^[A-Za-z0-9]+[A-Za-z0-9 ]*$", value: "", failureMessage: "You have entered an invalid character in other data source field." },
        max: 50,
        requiredWhenActive: true,
        dependency: { fieldId: "12.1.1", compare: "eq", value: "Custom" },
      },
      {
        type: "editGridField",
        fieldId: "12.1.3",
        resetForField: "0.1.2",
        name: "Custodians",
        label: "Enter one or more Custodians.",
        labelCaption: "Type the first and last name or only the organization name. Click the + button to add more.",
        columns: [
          {
            field: "firstName", title: "First Name or Organization", editor: "text", validations: [{ name: "required", errorMessage: "First Name is required." }, {
              name: "regexValidation", regExp: "^(?=.*[a-zA-Z].*[a-zA-Z])[0-9A-Za-z\\ \\#\\$\\&\\'\\+\\-\\.\\_\\^\\[\\]\\{\\}]*$", errorMessage: "You have entered an invalid character (or) must contain at least two alpha characters in First Name or Organization field."
            }]
          },
          {
            field: "lastName", title: "Last Name", editor: "text", validations: [{
              name: "regexValidation", regExp: "^[0-9A-Za-z\\ \\#\\$\\&\\'\\+\\-\\.\\_\\^\\[\\]\\{\\}]*$", errorMessage: "You have entered an invalid character in Last Name field"
            }]
          },
        ] as IGridDataColumns[],
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        maxRecordCount: 10,
        addButtonLabel: "Custodian",
        getBulkImport: (fieldValues: IFieldValueLookup) => {
          return [] as Array<string>;
        },
        dependency: {
          logic: "or",
          subConditions: [
            { fieldId: "12.1.1", compare: "neq", value: null },
          ]
        }
      }
    ],
    nextCondition:
    {
      logic: "or",
      subConditions: [
        {
          logic: "and",
          subConditions: [
            { fieldId: "12.1.1", compare: "eq", value: "Custom" },
            { fieldId: "12.1.2", compare: "neq", value: null },
            { fieldId: "12.1.3", compare: "neq", value: null },
          ]
        },
        {
          logic: "and",
          subConditions: [
            { fieldId: "12.1.1", compare: "neq", value: "Custom" },
            { fieldId: "12.1.3", compare: "neq", value: null },
          ]
        },
      ]
    }
  },
  {
    header: "Request: Use Easy Upload",
    subHeader: "ADD A DESCRIPTION & ARCHIVE DETAILS",
    summaryTitle: "MEDIA & ENCRYPTION",
    description: "Provide a description of the media you can use for quick identification. In addition, if the data is contained in a password protected ZIP or RAR file, provide the password for access.",
    dependency: {
      logic: "or",
      subConditions: [
        { fieldId: "12", compare: "eq", value: 1 },
        { fieldId: "12", compare: "eq", value: 2 },
      ]
    },
    fields: [
      {
        type: "textarea",
        maxLength: 255,
        fieldId: "12.2.1",
        label: "Describe the media.",
        requiredWhenActive: true,
        name: "Media Description",
        resetForField: "12",
        active: true,
        showCharacterCount: true,
        placeholder: (fieldValues: IFieldValueLookup) => {
          const hasLoadProcessPermission = fieldValues["projectPermissions"] && fieldValues["projectPermissions"].lookupValues && fieldValues["projectPermissions"].lookupValues["hasLoadProcessPermission"];
          return fieldValues && fieldValues["12"].value === 1 ?
            hasLoadProcessPermission ? "Collected via local machine, use standard foldering." : "Include the media description."
            : "Include details about the media, such as the recipient party and production volume.";
        }
      },
      {
        type: "input",
        name: "Password",
        subType: "password",
        fieldId: "12.2.2",
        resetForField: "12",
        label: "Is the data password protected? If so, enter the password.",
        active: false,
        requiredWhenActive: false,
        dependency: { fieldId: "12.2.1", compare: "neq", value: null },
      },
    ],
    nextCondition:
    {
      logic: "and",
      subConditions: [
        { fieldId: "12.2.1", compare: "neq", value: null },
      ]
    }
  },
  {
    header: "Request: Use Easy Upload",
    subHeader: "DEDUPE & FOLDERING",
    summaryTitle: "MEDIA & ENCRYPTION",
    description: "Choose whether to process the transferred data. When you process the data, provide the deduplication method and the folder path in the workspace. If not processed, the data is transferred and held as evidence intake until you contact your Project Manager with processing instructions.",
    dependency: {
      logic: "and",
      subConditions: [
        { fieldId: "12", compare: "eq", value: 1 },
        { fieldId: "projectPermissions", compare: "eq", value: true, lookupName: "hasLoadProcessPermission" },
        { fieldId: "hiddenProcessingAppDb", compare: "eq", value: DBRecordType.Epiq_Discovery_Processing, lookupName: "Db Record Type" },
        { fieldId: "12.2.1", compare: "neq", value: null }
      ]
    },
    fields: [{
      type: "radiogroup",
      label: "Are you processing this data?",
      name: "Processing Data",
      fieldId: "12.3.1",
      requiredWhenActive: true,
      hideFieldIdLabel: true,
      active: true,
      radioButtonOptions: [
        {
          id: 1,
          title: "Yes, transfer, process, and load for Review."
        },
        {
          id: 2,
          title: "No, transfer data only."
        }
      ]
    },
    {
      type: "singleselect",
      fieldId: "2.2c",
      resetForField: "12.3.1",
      name: "Hosting Database",
      label: "Select the hosting database.",
      active: false,
      requiredWhenActive: true,
      dependency: { fieldId: "12.3.1", compare: "eq", value: 1 },
      GetData: ResourceService.getResourcesUser,
      DataConverter: (dataItems: any) => {
        return [{ id: null, text: "Not Applicable" }].concat(
          dataItems.map((item: any) => {
            const text =
              item.fields["Relativity Host"] ||
              item.fields["Database Name"] ||
              item.fields["EqD Database Name"] ||
              item.fields["Db Record Type"] ||
              "Undefined";
            return {
              text,
              id: item.fields["AppDbId"],
              data: { lookupValues: { "Db Record Type": item.fields["Db Record Type"], "Platform": item.fields["Platform"] } }
            };
          })
        );
      },
      GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
        const filters = [
          {
            logic: "or",
            filters: [{ field: "ObjectId", operator: "eq", value: fieldValues["0.1.1"].value }] as Array<
              FilterDescriptor
            >
          }
        ] as CompositeFilterDescriptor[];

        if (searchText) {
          filters.push({
            logic: "or",
            filters: [
              { field: "Relativity Host Id", operator: "contains", value: searchText },
              { field: "Relativity Host", operator: "contains", value: searchText },
              { field: "Database Name", operator: "contains", value: searchText },
              { field: "EqD Database Name", operator: "contains", value: searchText },
              { field: "Db Record Type", operator: "contains", value: searchText }
            ] as Array<FilterDescriptor>
          });
        }

        filters.push({
          logic: "or",
          filters: [
            { field: "Platform", operator: "neq", value: "Relativity Processing (RSMF)" },
            { field: "Db Record Type", operator: "neq", value: DBRecordType.Relativity_Hosting }
          ] as Array<FilterDescriptor>
        });

        return {
          take: 100,
          typeCode: "ediscoveryproject",
          permissionsDelimited: "SubmitWorkRequest-TrackMedia,SubmitWorkRequest-SecureFileUpload,SubmitWorkRequest-SecureFileUploadandProcess,SubmitWorkRequest-ProcessData,SubmitWorkRequest-AnalyzeOrReview,SubmitWorkRequest-ExportData",
          showGroupsWithDupePermissions: true,
          flattenResults: true,
          dedupeFieldName: "AppDbId",
          joinFields: ["ApplicationDatabases"],
          filters: filters,
          filterPresets: ["ActiveApps", "HostingApps"]
        };
      }
    },
    {
      type: "dropdown",
      name: "Dedupe",
      fieldId: "12.3.2",
      resetForField: "12.3.1",
      label: "Do you want to dedupe this promoted data against existing data?",
      active: false,
      requiredWhenActive: true,
      dependency: { fieldId: "2.2c", compare: "neq", value: null },
      DropDownOptions: [{ id: "Global", title: "Yes, globally dedupe" }, { id: "Custodian", title: "Yes, dedupe by custodian" }, { id: "None", title: "No, do not dedupe" }]
    },
    {
      type: "dropdown",
      name: "Foldering",
      fieldId: "12.3.3",
      resetForField: "12.3.1",
      label: "How do you want your documents foldered?",
      active: false,
      requiredWhenActive: true,
      hideFieldIdLabel: true,
      dependency: { fieldId: "12.3.2", compare: "neq", value: null },
      DropDownOptions: [{ id: "1", title: "Custodian" },
      { id: "2", title: "Custodian/Data Source" },
      { id: "3", title: "Custodian/Data Source/Load Date" },
      { id: "4", title: "Production Volume" },
      { id: "Custom", title: "Custom" }
      ],
      cssName: () => "half-wrap"
    },
    {
      type: "input",
      name: "Custom Label",
      fieldId: "12.3.4",
      resetForField: "12.3.3",
      hideFieldIdLabel: true,
      label: "Enter custom field.",
      active: false,
      requiredWhenActive: true,
      cssName: () => "half-wrap",
      dependency: { fieldId: "12.3.3", compare: "eq", value: "Custom" },
    }
    ],
    nextCondition:
    {
      logic: "or",
      subConditions: [
        { fieldId: "12.3.1", compare: "eq", value: 2 },
        {
          logic: "and",
          subConditions: [
            { fieldId: "12.3.3", compare: "eq", value: "None" },
            { fieldId: "12.3.4", compare: "neq", value: null }
          ]
        },
        {
          logic: "and",
          subConditions: [
            { fieldId: "12.3.3", compare: "neq", value: "None" },
            { fieldId: "12.3.3", compare: "neq", value: null },
          ]
        }
      ]
    }
  },
  {
    header: "Request: [`fieldLookup:0.1.2``lookupName:databaseHeader`]",
    subHeader: "UPLOAD YOUR FILES",
    summaryTitle: "SECURE FILE UPLOAD ",
    description: "[`fieldLookup:12,14``lookupName:dynamicDescription``defaultStr:Use our secure Easy Upload tool to safely provide Epiq with your media files. To use this upload, drag files from File Explorer and drop them in this box. You can also click the browse to attach files link and select the files to upload. After dragging or selecting media files, click Submit in Secure File Upload.`]",
    dependency: {
      logic: "or",
      subConditions: [
        { fieldId: "12", compare: "eq", value: 1 },
        { fieldId: "12", compare: "eq", value: 2 },
        { fieldId: "14", compare: "eq", value: 1 }
      ]
    },
    id: "secureupload",
    fields: [
      {
        type: "securefileupload",
        name: "Upload ID",
        fieldId: "10.1",
        label: "Upload your files and click Submit.  Then, click Next.",
        active: false,
        resetForField: "2.2",
        cssName: () => "wizard-secure-file-upload",
        requiredWhenActive: false,
        dependency: { fieldId: "hiddenSecureUpload", compare: "neq", value: null },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          return {
            projectId: fieldValues["0.1.1"].value,
            secureUploadDetails: fieldValues["hiddenSecureUpload"] && fieldValues["hiddenSecureUpload"].value,
            appDbId: getSelectedAppDbId(fieldValues)
          };
        }
      }
    ],
    nextCondition:
    {
      logic: "and",
      subConditions: [
        { fieldId: "10.1", compare: "contains", value: "UploadSuccess" }
      ]
    }
  },
  {
    header: "Request: [`fieldLookup:0.1.2``lookupName:databaseHeader`]",
    subHeader: "ADD MEDIA DESCRIPTION",
    summaryTitle: "MEDIA DESCRIPTION",
    description: "Provide a description of the media to help identify the purpose and scope. While this field is optional, it can help you quickly identify a particular media when your project contains multiple media.",
    dependency: {
      logic: "or",
      subConditions: [
        {
          logic: "or",
          subConditions: [
            { fieldId: "8", compare: "eq", value: 1 },
            { fieldId: "8", compare: "eq", value: 2 }
          ]
        },
        {
          logic: "and",
          subConditions: [
            { fieldId: "14", compare: "eq", value: 1 },
            { fieldId: "10.1", compare: "contains", value: "UploadSuccess" }
          ]
        }
      ]
    },
    fields: [
      {
        type: "textarea",
        maxLength: 250,
        fieldId: "9.6",
        label: "Describe the media.",
        requiredWhenActive: false,
        name: "",
        active: false,
        showCharacterCount: true,
        placeholder: "Add description"
      }
    ]
  },
  {
    header: "Request: Upload New Data",
    subHeader: "PROVIDE ARCHIVE DETAILS",
    summaryTitle: "ENCRYPTION",
    description: "Specify whether the uploaded files are archive files (for example, ZIP or RAR). If you provided an archive file, let us know if it is password protected. Then, provide the archive password so that Epiq can access the files.",
    dependency: {
      logic: "or",
      subConditions: [
        { fieldId: "14", compare: "eq", value: 1 }
      ]
    },
    fields: [
      {
        type: "dropdown",
        name: "Encrypted?",
        fieldId: "10.2",
        label: "Is the data you provided in a password protected ZIP or RAR file?",
        active: true,
        requiredWhenActive: false,
        DropDownOptions: [{ id: "Yes", title: "Yes" }, { id: "No", title: "No" }]
      },
      {
        type: "passwordField",
        name: "Password",
        subType: "password",
        fieldId: "10.3",
        label: "Enter the password for the uploaded files.",
        active: false,
        requiredWhenActive: true,
        dependency: { fieldId: "10.2", compare: "eq", value: "Yes" }
      }
    ],
    nextCondition:
    {
      logic: "and",
      subConditions: [
        { fieldId: "10.2", compare: "neq", value: null },
      ]
    }
  },
  {
    header: (existingFieldVals: IFieldValueLookup) => {
      return "Request: " + existingFieldVals["0.1.2"].lookupValues["databaseHeader"];
    },
    subHeader: "WORK REQUEST TIMING",
    summaryTitle: "TIMING",
    summaryDescription: (existingFieldVals: IFieldValueLookup) => {
      const requestActionProjectClosure = existingFieldVals["3"] && existingFieldVals["3"].value == 4;
      const requestActionPartialProjectDeletion = existingFieldVals["3"] && existingFieldVals["3"].value == 1;
      const requestActionMediaDeletion = existingFieldVals["3"] && existingFieldVals["3"].value == 2;
      const requestActionMTMDataLoad = existingFieldVals["14"] && existingFieldVals["14"].value == 1;
      const requestActionReturnMedia = existingFieldVals["0.1.2"] && existingFieldVals["0.1.2"].value == 9;
      const requestActionSingleProjectArchive = existingFieldVals["3"] && existingFieldVals["3"].value == 5;

      if (requestActionProjectClosure) {
        return "Our standard turnaround time for a work request like this is typically two weeks from submission date, depending on the size of the data set.";
      }
      else if (requestActionPartialProjectDeletion) {
        return "Our standard turnaround time for a work request like this is typically two weeks from submission date, depending on the size of the data set."
      }
      else if (requestActionSingleProjectArchive) {
        return "Our standard turnaround time for a work request like this is typically two weeks from submission date, depending on the size of the workspace."
      }
      else if (requestActionMediaDeletion) {
        return "Our standard turnaround time for a work request like this is typically two weeks from submission date, depending on the size of the media."
      }
      else if (requestActionMTMDataLoad) {
        return "Our standard turnaround time for a work request in Metrics that Matter is typically two business days from submission date."
      }
      else if (requestActionReturnMedia) {
        return "Our standard turnaround time for a work request like this is typically 24-48 hours from submission date for up to 20 media items. When more media is selected, the request can take longer."
      }
      else {
        return "";
      }
    },
    hideSummaryEdit: (existingFieldVals: IFieldValueLookup) => {
      const reqProjectClosure = existingFieldVals["3"] && existingFieldVals["3"].value == 4 ? existingFieldVals["3"].value : 0;
      const reqPartialProjectDeletion = existingFieldVals["3"] && existingFieldVals["3"].value == 1 ? existingFieldVals["3"].value : 0;
      const reqMediaDeletion = existingFieldVals["3"] && existingFieldVals["3"].value == 2 ? existingFieldVals["3"].value : 0;
      const reqMTMDataLoad = existingFieldVals["14"] && existingFieldVals["14"].value == 1;

      const result = (reqProjectClosure || reqPartialProjectDeletion || reqMediaDeletion || reqMTMDataLoad) ? true : false;
      return result;
    },
    description: (existingFieldVals: IFieldValueLookup) => {
      const isSmallSizeTicketProcessing = isSmallSizeProcessing(existingFieldVals);
      const workCategory = existingFieldVals["0.1.2"] ? existingFieldVals["0.1.2"].value : 0;
      const requestActionForReviewData = existingFieldVals["6"] ? existingFieldVals["6"].value : 0;
      const requestActionForTrack = existingFieldVals["8"] ? existingFieldVals["8"].value : 0;
      const recordsCount = existingFieldVals["6.3"] ? existingFieldVals["6.3"].value : 0;
      const requestActionProjectClosure = existingFieldVals["3"] && existingFieldVals["3"].value == 4;
      const requestActionPartialProjectDeletion = existingFieldVals["3"] && existingFieldVals["3"].value == 1;
      const requestActionMediaDeletion = existingFieldVals["3"] && existingFieldVals["3"].value == 2;
      const requestActionReturnMedia = existingFieldVals["0.1.2"] && existingFieldVals["0.1.2"].value == 9;
      const requestActionMTMDataLoad = existingFieldVals["14"] && existingFieldVals["14"].value == 1;
      const requestActionSingleDBArchive = existingFieldVals["3"] && existingFieldVals["3"].value == 5;

      if (requestActionForTrack === 1) {
        return "Our standard turnaround time for a work request like this is typically more than 4 hours but less than a day after the shipment is received at the Epiq facility, depending on the size of the data set. More complicated requests will require additional time.";
      }
      else if (requestActionForTrack === 2) {
        return "Our standard turnaround time for a work request like is typically under 4 hours, depending on the size of the data set. More complicated requests will require additional time.";
      }
      else if (requestActionForReviewData === 3) {
        return "Our standard turnaround time for a work request like this is typically around a day, depending on the size of the data set. More complicated requests will require additional time.";
      }
      else if (workCategory === 4) {
        return "Our standard turnaround time for a work request like this is typically around a day, depending on the size of the load. More complicated requests will require additional time.";
      }
      else if (workCategory === 7) {
        return "Our standard turnaround time for a work request like this is typically a day. More complicated requests will require additional time.";
      }
      else if (requestActionForReviewData === 1 || requestActionForReviewData === 5) {
        if (recordsCount && recordsCount > 2000) {
          return "Our standard turnaround time for a work request like this is typically around a day, depending on the size of the load. More complicated requests will require additional time.";
        }
        else {
          return "Our standard turnaround time for a work request like this is typically around 4 hours, depending on the size of the load. More complicated requests will require additional time."
        }
      }
      else if (requestActionProjectClosure) {
        return "Our standard turnaround time for a work request like this is typically two weeks from submission date, depending on the size of the data set.";
      }
      else if (requestActionPartialProjectDeletion) {
        return "Our standard turnaround time for a work request like this is typically two weeks from submission date, depending on the size of the data set."
      }
      else if (requestActionSingleDBArchive) {
        return "Our standard turnaround time for a work request like this is typically two weeks from submission date, depending on the size of the workspace."
      }
      else if (requestActionMediaDeletion) {
        return "Our standard turnaround time for a work request like this is typically two weeks from submission date, depending on the size of the media."
      }
      else if (requestActionReturnMedia) {
        return "Our standard turnaround time for a work request like this is typically 24-48 hours from submission date for up to 20 media items. When more media is selected, the request can take longer."
      }
      else if (requestActionMTMDataLoad) {
        return "Our standard turnaround time for a work request in Metrics that Matter is typically two business days from submission date."
      }

      if (isSmallSizeTicketProcessing) {
        return "Our standard turnaround time for a work request like this without formatting search terms is typically around 4 hours. More complicated requests will require additional time."
      }

      return "Our standard turnaround time for a work request like this without formatting search terms is typically around a day. More complicated requests will require additional time.";
    },
    dependency: {
      logic: "or",
      subConditions: [
        { fieldId: "2", compare: "eq", value: 1 },
        { fieldId: "2", compare: "eq", value: 3 },
        { fieldId: "2", compare: "eq", value: 5 },
        { fieldId: "6", compare: "eq", value: 1 },
        { fieldId: "6", compare: "eq", value: 2 },
        { fieldId: "6", compare: "eq", value: 3 },
        { fieldId: "6", compare: "eq", value: 5 },
        { fieldId: "7", compare: "eq", value: 1 },
        { fieldId: "8", compare: "eq", value: 1 },
        { fieldId: "8", compare: "eq", value: 2 },
        { fieldId: "3", compare: "eq", value: 1 },
        { fieldId: "3", compare: "eq", value: 2 },
        { fieldId: "3", compare: "eq", value: 4 },
        { fieldId: "3", compare: "eq", value: 5 },
        { fieldId: "14", compare: "eq", value: 1 },
        { fieldId: "15", compare: "eq", value: 1 },
        { fieldId: "15", compare: "eq", value: 2 }
      ]
    },
    fields: [
      {
        type: "dropdown",
        name: "Expedite request?",
        fieldId: "2.10",
        label: "Do you need this work request expedited?",
        active: false,
        resetForField: "0.1.2",
        dependency: {
          logic: "or",
          subConditions: [
            { fieldId: "2.6", compare: "neq", value: null },
            { fieldId: "2.5.2.3", compare: "neq", value: null },
            { fieldId: "6", compare: "neq", value: null },
            { fieldId: "5.8", compare: "neq", value: null },
            { fieldId: "7.10.1.1", compare: "falsy", value: false },
            { fieldId: "7.10.2.3", compare: "falsy", value: false },
            { fieldId: "9.4", compare: "falsy", value: false },
          ]
        },
        DropDownOptions: [{ id: 1, title: "Yes" }, { id: 2, title: "No" }]
      },
      {
        type: "textarea",
        name: "Reason",
        fieldId: "2.10.1",
        label: "Describe the need to expedite.",
        active: false,
        dependency: { fieldId: "2.10", compare: "eq", value: 1 }
      },
      {
        type: "datetime",
        fieldId: "2.10.2",
        label: "What is your requested completion time?",
        name: "Date/Time",
        active: false,
        min: new Date(),
        dependency: { fieldId: "2.10", compare: "eq", value: 1 }
      }
    ],
    nextCondition: {
      logic: "or",
      subConditions: [
        { fieldId: "2.10", compare: "eq", value: 2 },
        {
          logic: "and",
          subConditions: [
            { fieldId: "2.10.1", compare: "neq", value: null },
            { fieldId: "2.10.2", compare: "neq", value: null }
          ]
        },
        {
          logic: "or",
          subConditions: [
            { fieldId: "3.3", compare: "neq", value: null },
            { fieldId: "3", compare: "neq", value: null }
          ]
        },
        { fieldId: "14", compare: "eq", value: 1 },
        { fieldId: "15", compare: "eq", value: 1 },
        { fieldId: "15", compare: "eq", value: 2 }
      ]
    }
  },
  {
    header: "Request: [`fieldLookup:0.1.2``lookupName:databaseHeader`]",
    subHeader: "SEND REPORT BY EMAIL",
    description: "When this request is submitted, a [`fieldLookup:3``lookupName:requestType`] Authorization report is created. We can email this report to you and to other email addresses you supply.",
    summaryTitle: "SEND REPORT BY EMAIL",
    dependency: {
      logic: "and",
      subConditions: [
        { fieldId: "3", compare: "neq", value: 5 },
        {
          logic: "or",
          subConditions: [
            { fieldId: "3.3", compare: "neq", value: null },
            { fieldId: "11.1", compare: "neq", value: null },
            { fieldId: "3.1", compare: "neq", value: null },
            { fieldId: "3.4", compare: "neq", value: null },
          ]
        }
      ]
    },
    fields: [
      {
        type: "radiogroup",
        label: "Do you want an emailed copy of the [`fieldLookup:3``lookupName:requestType`] Authorization report?",
        name: "To Submitter",
        fieldId: "13.1",
        requiredWhenActive: true,
        hideFieldIdLabel: true,
        active: true,
        radioButtonOptions: [
          {
            id: 1,
            title: "Yes",
            selected: true
          },
          {
            id: 2,
            title: "No"
          }
        ]
      },
      {
        type: "inputChiplist",
        fieldId: "13.2",
        label: "Send the report to these additional email addresses.",
        name: "To Specified Addresses",
        active: false,
        hideFieldIdLabel: true,
        dependency: { fieldId: "13.1", compare: "eq", value: 1 },
      }
    ],
    nextCondition: {
      logic: "or",
      subConditions: [
        { fieldId: "13.1", compare: "eq", value: 1 },
        { fieldId: "13.1", compare: "eq", value: 2 }
      ]
    }
  },
  {
    fields: [
      {
        type: "textarea",
        fieldId: "2.11",
        label: "[`fieldLookup:2``lookupName:dynamicLabel``defaultStr:Add your comments.`]",
        name: "",
        active: true,
        hideIfEmpty: true
      }
    ],
    header: "Request: [`fieldLookup:0.1.2``lookupName:databaseHeader`]",
    subHeader: "[`fieldLookup:2,6,7,8,12``lookupName:subHeader``defaultStr:ADD COMMENTS`]",
    summaryTitle: "COMMENTS",
    hideSummaryIfFieldsEmpty: true,
    dependency: {
      logic: "or",
      subConditions: [
        {
          logic: "and",
          subConditions: [{ fieldId: "12", compare: "neq", value: 2 },
          { fieldId: "12", compare: "neq", value: 1 }]
        },
        {
          logic: "and",
          subConditions: [
            { fieldId: "12", compare: "eq", value: 1 },
            { fieldId: "projectPermissions", compare: "eq", value: true, lookupName: "hasLoadProcessPermission" },
          ]
        }
      ]
    },
    description:
      "[`fieldLookup:2,6,7,8,12``lookupName:dynamicdescription``defaultStr:Provide any additional comments or notes that you want us to know as we address your work request.`]",
    nextCondition: {
      logic: "or",
      subConditions: [
        {
          logic: "and",
          subConditions: [
            { fieldId: "2", compare: "neq", value: 4 },
            { fieldId: "6", compare: "neq", value: 4 },
            { fieldId: "7", compare: "neq", value: 2 },
            { fieldId: "8", compare: "neq", value: 4 }
          ]
        },
        {
          logic: "and",
          subConditions: [
            {
              logic: "or",
              subConditions: [
                { fieldId: "2.11", compare: "neq", value: null },
                { fieldId: "2.10", compare: "eq", value: 2 }
              ]
            },
            {
              logic: "or",
              subConditions: [
                { fieldId: "2", compare: "eq", value: 4 },
                { fieldId: "6", compare: "eq", value: 4 },
                { fieldId: "7", compare: "eq", value: 2 },
                { fieldId: "8", compare: "eq", value: 4 },
                { fieldId: "13.1", compare: "eq", value: 1 },
                { fieldId: "13.1", compare: "eq", value: 2 }
              ]
            }
          ]
        }
      ]
    }
  }
] as Array<IWizardSection>;

const getAuthorizedProjectPermissionDDItems = (options: Array<IWizardDropDownListItem>, projectPermissions: any) => {
  if (projectPermissions) {
    options = options.filter(dd => {
      if (dd.permission && projectPermissions && !projectPermissions.some((c: any) => dd.permission.split(',').indexOf(c.permissionName) !== -1)) {
        return false;
      } else {
        return true;
      }
    });
    return options;
  }
}

export const wizardSummary: IWizardSummary = {
  title: "WORK REQUEST SUMMARY",
  description:
    "This summary shows the selections you made for this work request. Please review your selections. To make changes, click the Edit pencil to return to a section. When you are satisfied with this summary, submit your request. [`fieldLookup:2,6``lookupName:extraSummary``defaultStr:`]",
  canDownload: (request: IWizardRequest) => {
    return request != null && (request["hasILMProjectClosure"] === true || request["hasILMPartialProjectDeletion"] === true || request["hasILMMediaDeletion"] === true || request["hasEasyUpload"] === true);
  },
  downloadButtonLabel: (request: IWizardRequest) => {
    return request != null && (request["hasEasyUpload"] === true) ? "Download Summary Report" : "Download";
  },
  getConfirmRequiredFields: (request: IWizardRequest) => {
    if (request != null) {
      if (request["hasILMProjectClosure"] === true) {
        return "I authorize the closure of this project on behalf of the company I represent: \n**[`fieldLookup:0.1.1``lookupName:projectName`] ([`fieldLookup:0.1.1``lookupName:projectNumber`])**";
      } else if (request["hasILMPartialProjectDeletion"] === true) {
        const appDbAssignments = request["applicationDatabaseAssignment"] && request["applicationDatabaseAssignment"] as Array<IApplicationDatabaseFields>;
        const database = (appDbAssignments && appDbAssignments.length > 0) ? (appDbAssignments[0].databaseName ? `\nDatabase: **${appDbAssignments[0].databaseName}**` : "\nDatabase:") : "";
        return `I authorize the deletion of this database on behalf of the company I represent: ${database} \nProject: **[\`fieldLookup:0.1.1\`\`lookupName:projectName\`] ([\`fieldLookup:0.1.1\`\`lookupName:projectNumber\`])**`;
      }
      else if (request["hasILMMediaDeletion"] === true) {
        return `I authorize the deletion of the selected media on behalf of the company I represent. \n**Project:** [\`fieldLookup:0.1.1\`\`lookupName:projectName\`] ([\`fieldLookup:0.1.1\`\`lookupName:projectNumber\`])`;
      }
    }
    return "";
  },
  getFollowedByUsers: (values: IFieldValueLookup) => {
    const isSendEmailReport = values["13.1"] && values["13.1"].value === 1;
    const followedByUsers = isSendEmailReport && values["13.2"] && (values["13.2"].value ? values["13.2"].value as string : "");
    return followedByUsers || "";
  },
  getSummaryExtraDescription: (values: IFieldValueLookup) => {
    const ILMValue = values && values["3"] && values["3"].value && values["3"].value != 5 ? parseInt(values["3"].value.toString()) : 0;
    const easyUpload = values && values["0.1.2"] && values["0.1.2"].value && values["0.1.2"].value === 7;
    const hasProcessingData = values && values["12.3.1"] && values["12.3.1"].value && values["12.3.1"].value === 1;

    if (ILMValue) {
      return `Client agrees to release all rights in any data storage device, including but not limited to any servers, tablets, laptops, hard drives, tapes, USB drives, mobile devices or other storage media and acknowledges that Epiq may use such data storage devices in connection with future projects provided such devices have undergone appropriate data destruction practices in accordance with Epiq policies. To the extent that this paragraph conflicts with the terms of the Agreement, this paragraph shall control.`
    }
    else if (easyUpload && !hasProcessingData) {
      return easyUploadAdditionalSummary();
    }
  }
};

export const wizardWarningMessage: IWizardWarningMessage = {
  title: "Cancel the work request?",
  description:
    "Closing the Work Request wizard removes any selections you made. Do you want to cancel this work request?"
};

export const wizardSaveForLater: ISavableWizard = {
  enabled: (lookup, page) => {
    if (!lookup["12"] || !lookup["9.4"]) return true;

    return lookup["12"].value != 12
      && lookup["9.4"].value != null
      && page < 25; // TODO i don't like pages as an indicator, but using this for now since there is nothing else to indicate our position
  },
  message: {
    title: "Save the work request for later",
    description:
      `Saving a work request enables you to save the current choices and return to the same work request.
    This is useful when you need to verify choices or obtain more information before submitting it.
    Do you want to save this work request for later and close the Work Request wizard?`,
  }
};

export const jobMessagingConfig: JobMessagingConfig = {
  genericErrorMessage: "Failed to submit work request",
  genericSuccessMessage: "Your work request was submitted.",
  genericInfoMessage: "Work request submit has been initiated. We will notify you once complete.",
  cancelMessage: "Cancel Work Request"
};

export const wizardConfirmSubmitMessage: IWizardWarningMessage = {
  getTitle: (existingFieldVals: IFieldValueLookup) => {
    const workCategory = existingFieldVals["0.1.2"] ? existingFieldVals["0.1.2"].value : 0;
    const action = existingFieldVals["3"] ? existingFieldVals["3"].value : 0;
    const requestActionMtm = existingFieldVals["14"] ? existingFieldVals["14"].value : 0;

    if (workCategory === 5 && action === 4) {
      return "Submit Project Closure Request";
    } else if (workCategory === 5 && action === 1) {
      return "Submit Database Deletion Request";
    }
    else if (workCategory === 5 && action === 2) {
      return "Submit Media Deletion Request";
    } else if (requestActionMtm === 1) {
      return "Submit Metrics That Matter Report Request";
    } else {
      return "Submit Work Request";
    }

  },
  getDescription: (existingFieldVals: IFieldValueLookup) => {
    const workCategory = existingFieldVals["0.1.2"] ? existingFieldVals["0.1.2"].value : 0;
    const action = existingFieldVals["3"] ? existingFieldVals["3"].value : 0;
    const task = existingFieldVals["8"] ? existingFieldVals["8"].value : 0;
    const requestActionMtm = existingFieldVals["14"] ? existingFieldVals["14"].value : 0;
    let confirmMsgs = new Array<string>();

    const projectName = existingFieldVals["0.1.1"].lookupValues["projectName"];
    const projectNumber = existingFieldVals["0.1.1"].lookupValues["projectNumber"];
    if (workCategory === 5 && action === 4) {
      confirmMsgs.push(`On behalf of the company I represent, I authorize that Epiq close **"${projectName} (${projectNumber})"**, which includes the permanent destruction of the media, processing databases, and hosting databases stored at Epiq for this project.`);
      confirmMsgs.push("Confirm that you understand this and authorize this project closure.");
    } else if (workCategory === 5 && action === 1) {
      let database = "";
      if (existingFieldVals["11.1"]) {
        if (existingFieldVals["11.1"].value === 1) {
          database = existingFieldVals["11.2"].lookupValues && existingFieldVals["11.2"].lookupValues["Database"] as string;
        }
        else if (existingFieldVals["11.1"].value === 2) {
          database = existingFieldVals["11.3"].lookupValues && existingFieldVals["11.3"].lookupValues["Database"] as string;
        }
      }
      confirmMsgs.push(`On behalf of the company I represent, I authorize that Epiq permanently deletes database **"${database}"** from project **"${projectName} (${projectNumber})"**.`);
      confirmMsgs.push("Confirm that you understand this and authorize this database deletion.");
    } else if (workCategory === 5 && action === 2) {
      confirmMsgs.push(`On behalf of the company I represent, I authorize that Epiq permanently deletes selected media from project **"${projectName} (${projectNumber})"**.`);
      confirmMsgs.push("Confirm that you understand this and authorize this media deletion.");
    } else if (workCategory === 14) {
      confirmMsgs.push("Upon submission, your data will be received by a member of our Legal Transformation Services group.  They will be in touch should we have any additional questions.");
    } else if (requestActionMtm === 1) {
      confirmMsgs.push("Confirm that you want to upload this Metrics that Matter data to the Legal Transformation Services team. If any questions arise about this data, a member of this team will contact you.");
    }
    else {
      (workCategory === 1 && task != 3) && confirmMsgs.push('This request informs Epiq to download the data and assign the media number. After this request completes, you will need to start a new work request to process or load the data.');
      confirmMsgs.push('Confirm that you reviewed the Work Request Summary and want to submit this work request.');
    }

    return confirmMsgs;

  }
};

// TODO maybe should make this configurable?
export const createWorkRequest = function (valueDictionary: IFieldValueLookup) {
  const request = {} as IWizardRequest;
  const getFieldValue = function (id: string) {
    return valueDictionary[id] ? valueDictionary[id].value : null;
  };
  const val = function (id: string) {
    if (!valueDictionary[id] || !valueDictionary[id].active) return null;

    if (!Array.isArray(valueDictionary[id].value)) return valueDictionary[id].value;

    return (valueDictionary[id].value as Array<string>).join(";");
  };
  const lookup = function (id: string, lookup: string) {
    return valueDictionary[id] ? (valueDictionary[id].lookupValues ? valueDictionary[id].lookupValues[lookup] : null) : null;
  };
  const isLessThanHours = function (id: string, hours: number) {
    if (!valueDictionary[id] || !valueDictionary[id].value) return false;

    const compareDate = new Date(valueDictionary[id].value as string);
    compareDate.setHours(compareDate.getHours() - hours);

    return compareDate <= new Date();
  };

  const addDays = function (date: Date, days: number) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  const addHours = function (date: Date, hours: number) {
    var result = new Date(date);
    result.setHours(result.getHours() + hours);
    return result;
  }

  const text = function (id: string) {
    if (!valueDictionary[id] || !valueDictionary[id].active) return null;
    return (valueDictionary[id].text);
  };

  const getEDProcessingRelatedWrFields = function (custodianList: string) {
    const relWrRequest = {} as IWizardRequest;
    relWrRequest["headline"] = "Epiq Access Work Request - Epiq Discovery - Processing";
    relWrRequest["hasProcessing"] = true;
    relWrRequest["processingAppDatabaseId"] = val("hiddenProcessingAppDb");
    relWrRequest["pMDocumentRelease"] = "Yes";
    relWrRequest["estimatedSize"] = "10";
    relWrRequest["pDLocation"] = "Do Not Follow PD";
    relWrRequest["ingestionType"] = "Entire Media";

    if (dbRecordType === DBRecordType.Epiq_Discovery_Processing) {
      relWrRequest["ProcessingType"] = "Promote and Load to Hosting workspace";
    }

    relWrRequest["dataSourceNew"] = request["dataSourceNew"];
    relWrRequest["dataSource"] = request["dataSource"];
    relWrRequest["folderTree"] = request["folderTree"];
    relWrRequest["dedupOptions"] = request["dedupOptions"];
    relWrRequest["projectId"] = request["projectId"];
    relWrRequest["projectName"] = request["projectName"];
    relWrRequest["eaProjectId"] = request["eaProjectId"];
    relWrRequest["hostingAppDatabase"] = request["hostingAppDatabase"];
    relWrRequest["hostingAppDatabaseName"] = request["hostingAppDatabaseName"];
    relWrRequest["firstOrganizationName"] = request["firstOrganizationName"];
    relWrRequest["lastName"] = request["lastName"];
    relWrRequest["custodianAssignmentType"] = request["custodianAssignmentType"];

    relWrRequest["ShouldSubmit"] = false;

    let relWRInstructions = custodianList ? `${custodianList}\n` : "";
    relWrRequest["pMInstructions"] = val("2.11") || "";

    if (relWRInstructions.length) {
      relWrRequest["pMInstructions"] += "\n\n" + relWRInstructions;
    }

    if (relWrRequest["pMInstructions"]) {
      relWrRequest["enablePMInstructions"] = true;
    }

    return relWrRequest;
  }

  const dbRecordType = lookup("hiddenProcessingAppDb", "Db Record Type");
  const hasPreProcFiltering = val("2.5.1.1") === 1 || val("2.5.1.1") === 2 || val("2.5.3") === "Yes";
  const size = val("2.3");
  const promoteHits = val("2.6");
  const dedupeEpiqValue = val("4.7") === "Global" ? "Across Database" : val("4.7") === "Custodian" ? "Within Custodian" : val("4.7");
  const dedupePMValue = val("4.7") === "Global" ? "Dedup-Global (Contact PM if there is no template or more than one template that matches dedup requirements)"
    : val("4.7") === "Custodian" ? "Dedup-Custodian (Contact PM if there is no template or more than one template that matches dedup requirements)"
      : val("4.7") === "None" ? "No Dedup (Contact PM if there is no template or more than one template that matches dedup requirements)" : null;
  const environmentLocationHostingAppDb = lookup("0.1.1", "environmentLocationHostingAppDb");


  let extraInstructions = "";

  if (val("2") === 1 && dbRecordType === DBRecordType.DMX14_Processing && size >= 1) {
    request["dMX14ProcessingTemplate"] = dedupePMValue;
  }
  else if (val("2") === 1 && dbRecordType === DBRecordType.Nuix && (hasPreProcFiltering || size >= 1)) {
    request["deduplicationMethodNuix"] = val("4.7");
    request["oCR"] = val("2.8");
  }
  else if (val("2") === 1 && dbRecordType === DBRecordType.Epiq_Discovery_Processing && (hasPreProcFiltering || size >= 1)) {
    request["dedupOptions"] = dedupeEpiqValue;
    request["dataSource"] = "None";
  }

  if (val("2") === 1 && (hasPreProcFiltering || size >= 1) && val("2.4.2") === "Multi-Custodian") {
    request["ShouldSubmit"] = false;
  }

  // key pathways
  request["headlinePrefix"] = "Epiq Access Work Request - ";
  request["hasProcessing"] = val("2") === 1;
  request["hasEsiFormatSearchTerms"] = val("2") === 3;
  request["hasImaging"] = val("6") === 1;
  request["hasOcr"] = val("6") === 5;
  request["hasProcessingFiltering"] = hasPreProcFiltering;
  request["hasThirdPartyHosting"] = val("6") === 2;
  request["hasFormatFilterRelease"] = val("2") === 5;
  request["hasCreateExportHosting"] = val("7") === 1;
  request["hasRelativityAnalytics"] = val("6") === 3;
  request["hasEOMedia"] = val("8") === 1 || val("8") === 2 || val("12") === 1 || val("12") === 2;
  request["hasILMProjectClosure"] = val("3") === 4;
  request["hasILMPartialProjectDeletion"] = val("3") === 1;
  request["hasILMMediaDeletion"] = val("3") === 2;
  request["hasMTMDataLoad"] = val("14") === 1;
  request["hasEOShipping"] = val("0.1.2") === 9;
  request["HasILMSingleDbArchiveDormant"] = val("3") === 5;
  request["hasEasyUpload"] = val("12") === 1 || val("12") === 2;
  request["hasLoadAndProcessing"] = val("12.3.1") === 1;

  request["projectId"] = lookup("0.1.1", "SalesForceId");
  request["projectName"] = text("0.1.1");
  request["eaProjectId"] = val("0.1.1");

  request["mediaId"] = val("2.1");

  if (val("2.1")) {
    request["eDiscoveryMediaAssignment"] = [val("2.1").toString()];
    request["headline"] = "Epiq Access Work Request - " + lookup("2.1", "MediaName");
  }
  if (val("12") === 1 || val("12") === 2) {
    // Create new Date instance
    var date = new Date()
    // Adding a day or hours
    request["requestedTime"] = request["hasLoadAndProcessing"] ? addHours(date, 6).toISOString() : addDays(date, 1).toISOString();
  }
  else {
    request["requestedTime"] = val("2.10.2");
  }

  request["processingAppDatabaseId"] = val("hiddenProcessingAppDb");
  request["hostingAppDatabase"] = val("2.2") || val("2.2a") || val("2.2b") || val("2.2c");
  request["hostingAppDatabaseName"] = text("2.2") || text("2.2a") || text("2.2b") || text("2.2c");
  request["ingestionType"] = val("2.4.1") ? val("2.4.1") : "";
  request["custodianAssignmentType"] = val("2.4.2") ? val("2.4.2") : "";
  request["singleCustodianName"] = val("2.4.3") ? val("2.4.3") : "";
  request["firstOrganizationName"] = val("2.4.5") ? val("2.4.5") : "";
  request["lastName"] = val("2.4.6") ? val("2.4.6") : "";
  request["custodians"] = val("2.4.4") ? val("2.4.4").toString().replace(/\n/g, "\r\n") : "";
  request["preApproved"] = promoteHits;

  if (val("2.3")) {
    request["estimatedSize"] = val("2.3");
  }

  //request["performImaging"] = val("2.7") ? "Yes" : val("2.7") === false ? "No" : null;

  //request["oCR"] = val("2.8") ? "Case Setting" : null;

  request["startDate"] = val("2.5.3.1") ? new Date(val("2.5.3.1").toString()).toLocaleDateString() : "";
  request["endDate"] = val("2.5.3.2") ? new Date(val("2.5.3.2").toString()).toLocaleDateString() : "";

  if (hasPreProcFiltering) {
    request["filterCurrentIngestion"] = "Yes";
    request["keywordSearchTerms"] = val("2.5.1.1") === 1 || val("2.5.1.1") === 2 ? "Yes" : "No";
    request["filterAcrossCase"] = "No";
  }
  if (hasPreProcFiltering && lookup("hiddenProcessingAppDb", "Db Record Type") === DBRecordType.Nuix) {
    request["content"] = true;
    request["nameSubject"] = true;
    request["relatedWR"] = val("2.5.1.2") ? val("2.5.1.2") : "";
  }

  if (val("2.5.3") === "Yes") {
    request["IncludeDateFilter"] = "Yes-Top Level Docs (Standard)";
  }

  if (request["hasFormatFilterRelease"]) {
    request["fieldsToSearch"] = "Standard";
    request["content"] = true;
    request["nameSubject"] = true;
    request["keywordSearchTerms"] = val("2.5.1.1") === 1 || val("2.5.1.1") === 2 ? "Yes" : "No";
    request["excludeDocsInHosting"] = "Yes";
    request["searchDuplicateCustodian"] = "Yes";
    request["filterAcrossCase"] = (val("4.1") === 1 || val("4.1") === 2) ? "Yes" : "No";
    request["deduplicationMethodNuix"] = val("4.7");
    request["directFilter"] = (dbRecordType === DBRecordType.Nuix) ? ((val("2.5.1.1") === 3 && val("2.5.3") === "No") ? "Yes" : "No") : (val("4.1") === 2 || val("4.1") === 3 ? "Yes" : "No");
    request["searchHitReport"] = promoteHits === "No" ? "Yes" : "No";
    request["dedupOptions"] = dedupeEpiqValue;

    if (dbRecordType === DBRecordType.DMX14_Processing) {
      request["performRecipientFilter"] = "No";
    }

    if (dbRecordType === DBRecordType.Epiq_Discovery_Processing || dbRecordType === DBRecordType.DMX14_Processing) {
      request["syntaxFormat"] = dbRecordType === DBRecordType.DMX14_Processing ? "Oracle (DMX)" : "Elastic (Epiq Discovery)";
      request["docsToSearch"] = val("4.1") === 3 ? (val("2.1") && lookup("2.1", "MediaName")) : (val("4.1") === 2 ? (request["custodians"] ? `${request["custodians"]}\n` : "") : "All");
      if (request["docsToSearch"]) {
        request["eDiscoveryMediaAssignment"] = [];
      }
    }

  }

  if (dbRecordType === DBRecordType.Nuix) {
    if (hasPreProcFiltering || request["hasFormatFilterRelease"]) {
      request["content"] = request["nameSubject"] = (val("2.5.1.1") === 1 || val("2.5.1.1") === 2);

      if (request["keywordSearchTerms"] === "No") {
        request["performRecipientFilter"] = "No";
      } else {
        request["performRecipientFilter"] = "Yes";
      }

    }

    let platform = null;
    if (request["hasFormatFilterRelease"]) {
      platform = lookup("2.2b", "Platform");
      request["evidenceIds"] = lookup("2.1", "MediaName");
      request["eDiscoveryMediaAssignment"] = [];
    }
    else if (hasPreProcFiltering || size >= 1) {
      platform = lookup("2.2", "Platform");
    }

    request["releaseEcaEnvironment"] = (platform === "ECA" || platform === "ECA-Review Combined") ? true : false;
  }

  if (val("2.5.1.1") === 1) {
    if (request["hasFormatFilterRelease"] && (dbRecordType === DBRecordType.Epiq_Discovery_Processing || dbRecordType === DBRecordType.DMX14_Processing)) {
      request["searchName"] = text("2.5.1.2") ? text("2.5.1.2") : ""
    }
    else {
      request["searchName"] = text("2.5.1.2") ? text("2.5.1.2") : "";

      if (dbRecordType === DBRecordType.DMX14_Processing) {
        extraInstructions += `Search Name: ${request["searchName"]}\n`;
      }
    }

  } else if (val("2.5.1.1") === 2 || val("2") === 3) {
    if (request["hasEsiFormatSearchTerms"] || (request["hasFormatFilterRelease"] && (dbRecordType === DBRecordType.Epiq_Discovery_Processing || dbRecordType === DBRecordType.DMX14_Processing))) {
      request["searchName"] = val("2.5.2.1");
    }
    else {
      extraInstructions += `Search Name: ${val("2.5.2.1") ? val("2.5.2.1") : ""}\n`;
    }
  }

  if (val("2") === 1 && dbRecordType === DBRecordType.Epiq_Discovery_Processing && val("2.5.1.1") === 2) {
    request["fieldsToSearch"] = "Standard";
  }

  if (val("8.2")) {
    request["searchName"] = val("8.2");
  }

  request["dataType"] = val("5.3");
  request["imageLoadType"] = val("5.3.2.1");
  request["folderTree"] = val("5.4");
  request["docIDSource"] = val("5.5");
  request["performOCR"] = val("5.7");
  request["expectedRecordFileCount"] = val("5.8.1");
  request["expectedNativeCount"] = val("5.9");
  request["expectedImageCount"] = val("5.10");

  const splitSearchTerms = val("2.5.2.3");
  if (
    splitSearchTerms &&
    (request["hasEsiFormatSearchTerms"] ||
      (request["hasFormatFilterRelease"] &&
        (dbRecordType === DBRecordType.DMX14_Processing || dbRecordType === DBRecordType.Epiq_Discovery_Processing || dbRecordType.toLocaleString().includes("Epiq Discovery"))))
  ) {
    request["splitSearchTerms"] = splitSearchTerms;
  } else if (splitSearchTerms) {
    extraInstructions += `Split Search Terms: ${splitSearchTerms}\n`;
  }

  if (val("6") === 2) {
    request["documentOverwriteSettings"] = "Append Only";
    request["headline"] = "Epiq Access Work Request - 3rd Party Load - " + lookup("2.1", "MediaName");
    request["performImaging"] = val("5.6") || "No";
  } else if (request["hasRelativityAnalytics"]) {
    const analytics = val("8.5");
    request["includeFullFamily"] = "Yes";
    request["relativityAnalyticsServices"] = analytics;
    request["rAStructuredAnalyticsBuild"] = val("8.5.2.1");
    request["rAConceptAnalyticsIndexBuild"] = "N/A";

    if (analytics) {
      const analyticsList = analytics.toString().split(";");
      const emailThreadNormExist = analyticsList.some((el) => (el === "Structured Analytics - Email Threading" || el === "Structured Analytics – Name Normalization"));
      if (emailThreadNormExist) {
        request["AnalyticsProfile"] = "Default";
        request["NameNormalizationSetting"] = "Use Email Header Fields";
      } else {
        request["AnalyticsProfile"] = "";
        request["NameNormalizationSetting"] = "";
      }
    }
  }

  request["totalRecordCount"] = val("6.3") || val("8.3");

  if (val("6") === 1 || val("6") === 5) {
    request["totalDocumentCount"] = val("6.3");
  }

  if (val("6") === 1) {
    request["searchName"] = val("6.2");
    request["replaceImagesInHosting"] = val("6.4.1");
    request["defaultTimeZone"] = val("6.4.2");
    request["imagingErrorResolution"] = "Retry errors twice (default)";
  }
  if (val("6") === 5) {
    request["searchName"] = val("6.2");
    request["oCRDocumentSet"] = "Data Source";
    request["expectedPageCount"] = val("6.5.1");
    request["totalPageCount"] = val("6.5.1");
    request["defaultOCRLanguage"] = val("6.5.5");
    request["onlyOCRProductionDocsWithRedactions"] = "No";

    if (val("6.5.5") != "English") {
      request["NonEnglishLanguageRequested"] = "Yes";
    } else {
      request["NonEnglishLanguageRequested"] = "No";
    }
    request["oCRProfile"] = "Default OCR";
    request["destinationField"] = "OCR Text";
    request["oCRDocumentSetSource"] = val("6.2");
    request["Imaging_Error_Resolution__c"] = "Retry errors twice (default)";
  }

  if (request["hasCreateExportHosting"]) {
    request["exportSavedSearch"] = val("7.2");
    request["headline"] = "Epiq Access Work Request - Hosting Export - " + request["exportSavedSearch"];
    request["totalDocumentCount"] = val("5.8.1");
    request["encryptionPassword"] = "Epiq Standard Password (10 Character - Randomly Generated)";

    if (
      val("7.4") != null &&
      val("7.4")
        .toString()
        .includes("Document Level Text File")
    ) {
      request["oCRType"] = "Document Level Text File";
      request["oCREncoding"] = "Unicode (UTF-8)";

      if (environmentLocationHostingAppDb === "US (SDC)")
        request["textPrecedence"] = "OCR Redacted Text; OCR Text; Extracted Text (SDC Default)";
      else
        request["textPrecedence"] = "Production OCR Text; Extracted Text (LV/CA/UK/APAC default)";

    } else {
      request["oCRType"] = "No OCR included";
      request["oCREncoding"] = "";
    }

    if (
      val("7.4") != null &&
      val("7.4")
        .toString()
        .includes("Native files")
    ) {
      request["exportNativeFiles"] = "Yes";
    } else {
      request["exportNativeFiles"] = "No";
    }

    if (
      val("7.4") != null &&
      val("7.4")
        .toString()
        .includes("Metadata")
    ) {
      request["metadataFiles"] = "Concordance(.dat)";
      request["metadataEncoding"] = "Unicode (UTF-8)";
    } else {
      request["metadataFiles"] = "";
      request["metadataEncoding"] = "";
    }

    if (val("7.4.1") === "Yes") {
      request["exportNativeSavedSearch"] = val("7.4.2");
    }

    if (val("7.7.1") != null) {
      const selectedOption = val("7.7.1").toString();

      if (selectedOption.includes("Production Images only (where absent, exclude image)")) {
        request["includeProducedImages"] = "Yes";
        request["includeOriginalImages"] = "No";
      } else if (selectedOption.includes("Original Images only")) {
        request["includeOriginalImages"] = "Yes";
        request["includeProducedImages"] = "No";
      } else if (selectedOption.includes("Production Images (where absent, use Original Image)")) {
        request["includeProducedImages"] = "Yes";
        request["includeOriginalImages"] = "Yes";
      }
    }

    if (
      val("7.7") != null &&
      val("7.7")
        .toString()
        .includes("Single Page TIF/JPG")
    ) {
      request["imageLoadFile"] = "Opticon";
      request["imageFileType"] = "Single Page TIF/JPG";
    } else if (
      val("7.7") != null &&
      val("7.7")
        .toString()
        .includes("Multi-page TIF")
    ) {
      request["imageLoadFile"] = "Opticon";
      request["imageFileType"] = "Multi-page TIF";
    } else if (
      val("7.7") != null &&
      val("7.7")
        .toString()
        .includes("PDF (Searchable)")
    ) {
      request["imageFileType"] = "PDF (Searchable)";
      request["imageLoadFile"] = "Not Required";
    } else if (
      val("7.7") != null &&
      val("7.7")
        .toString()
        .includes("PDF (Non-searchable)")
    ) {
      request["imageFileType"] = "PDF (Non-searchable)";
      request["imageLoadFile"] = "Not Required";
    } else {
      request["imageFileType"] = "N/A";
    }

    if (val("7.10") === "Delivery to SFTP site") {
      request["fTPDelivery"] = "Yes";
      request["deliveryType"] = "FTP";
      request["encryptionMethod"] = "RAR";
    } else {
      request["fTPDelivery"] = "No";
      request["deliveryType"] = "Hard Drive";
      request["encryptionMethod"] = "Hardware/Physical";
    }

    request["numberOfExportSets"] = val("7.10.2.1");
    request["labelingInstructions"] = val("7.10.2.2");

    request["recipientsAddress"] = val("7.10.2.3");
    request["fTPLocation"] = val("7.10.1.1");
    if (val("7.10.2.2b") !== null) {
      extraInstructions += `Custom Label - Describe: ${val("7.10.2.2b")}\n`;
    }
  }

  if (request["hasEOMedia"]) {
    request["mediaType"] = val("12") === 1 ? "Processing" : val("12") === 2 ? "3rd Party Load" : val("9.4");
    request["mediaDescription"] = (val("12") === 1 || val("12") === 2) ? val("12.2.1") : val("9.6");
    request["mediaRequirements"] = "Create Media ID, Run Media Report, and Stage to Network";
    const mediaEstimatedSize = val("9.5") ? val("9.5") as number : 0;
    request["estimatedSize"] = (val("12") === 1 || val("12") === 2) ? 10 : mediaEstimatedSize;
    request["expressRequest"] = mediaEstimatedSize <= 5 ? true : false;
    request["customWorkflows"] = "None";
    if (request["mediaType"] !== "Processing") {
      request["processingAppDatabaseId"] = null;
    }
    if (val("8") === 1) {
      request["encrypted"] = val("9.3");
      request["encryptionPassword"] = val("9.3.1");
      request["deliveryType"] = "Hard Drive";
      request["sourceFilePath"] = "None";
      request["ShouldSubmit"] = true;
      request["requestedFiles"] = "All Files";
      extraInstructions += `Epiq Facility: ${val("9.1.1")}\n Courier Name and Tracking #: ${val("9.1.2")}\n`;
    } else if (val("8") === 2) {
      request["deliveryType"] = "FTP";
      request["sourceFilePath"] = val("9.2.2");
      const ftpSiteVal = val("9.2.1") ? val("9.2.1").toString() : "";
      const ftpSite = ftpSiteVal.replace("https://", "https###")
        .replace("file://", "file###").replace("ftp://", "ftp###");

      if (ftpUrlList.indexOf(ftpSiteVal) > -1) {
        request["ShouldSubmit"] = false;
        request["FTPSite"] = ftpSite;
      } else {
        request["ShouldSubmit"] = false;
        request["FTPSite"] = "Non-Epiq external FTP site";
        request["externalFTPLocation"] = ftpSite;
        extraInstructions +=
          "Non-Epiq external FTP site: " + ftpSite + "\n";
      }
      request["encrypted"] = val("9.3");
      request["encryptionPassword"] = val("9.3.1");
      request["requestedFiles"] = "All Files";
      extraInstructions += "Before submitting the work request, please verify the file location path and Requested Files value as 'All Files' vs 'Select Files Only'.\n"
    }
    else if (request["hasEasyUpload"]) { //user easy upload
      request["deliveryType"] = "FTP";
      const secureUploadFtpSiteUrl = lookup("10.1", "ftpSiteUrl");
      if (secureUploadFtpSiteUrl) {
        request["FTPSite"] = (secureUploadFtpSiteUrl as string).replace("https://", "https###");
      }
      else {
        request["FTPSite"] = ("https://transfer.epiqsystems.com").replace("https://", "https###");
      }
      if (text("10.1")) { // might be empty if we save for later
        request["sourceFilePath"] = "[GOAREPLACE]" + text("10.1").replace("UploadSuccess", "") + "\\";
      }
      request["encrypted"] = val("12.2.2") ? "Yes" : "No";
      if (val("12.2.2")) {
        request["encryptionPassword"] = val("12.2.2");
      }
      request["ShouldSubmit"] = true;
      request["requestedFiles"] = "All Files";
      request["secureFileUploadId"] = text("10.1");

      if (val("12") === 1) { //processing
        const custodiansArray = getFieldValue("12.1.3") ? getFieldValue("12.1.3") as User[] : null;
        const dataSource = val("12.1.1");

        const custodiansList = () => {
          if (custodiansArray && custodiansArray.length > 0) {
            let custodians = "";
            for (let i = 0; i < custodiansArray.length; i++) {
              if (custodiansArray[i].lastName) {
                custodians = custodians + `${custodiansArray[i].lastName.trim()}, ${custodiansArray[i].firstName.trim()} \n`
              }
              else {
                custodians = custodians + `${custodiansArray[i].firstName.trim()} \n`
              }
            }
            return `Custodians: ${custodians}`;
          }
          return "";
        }

        const cList = custodiansList();
        let edProcessingCustodians = "";

        if (custodiansArray.length > 0 && custodiansArray.length === 1) {
          request["firstOrganizationName"] = custodiansArray[0].firstName;
          request["lastName"] = custodiansArray[0].lastName;
          request["custodianAssignmentType"] = "Single Custodian";
        }
        else if (cList) {
          request["custodianAssignmentType"] = "Multi-custodian";
          extraInstructions += `${cList}\n`;
          edProcessingCustodians = cList;
        }

        request["custodians"] = (custodiansArray && custodiansArray.length > 0) ? custodiansArray.map((d) => {
          if (d.lastName) {
            return d.lastName.trim() + ", " + d.firstName.trim()
          } else {
            return d.firstName.trim();
          }
        }).join(":") : null;

        request["dataSourceNew"] = dataSource === "None" ? null : dataSource;
        request["dataSource"] = dataSource === "Custom" ? val("12.1.2") : null;

        if (request["hasLoadAndProcessing"]) {
          request["ShouldSubmit"] = true;
          request["folderTree"] = val("12.3.3") === "Custom" ? val("12.3.4") : text("12.3.3");

          const dedupeValue = val("12.3.2");
          if (dbRecordType === DBRecordType.Epiq_Discovery_Processing) {
            request["dedupOptions"] = dedupeValue === "Global"
              ? "Across Database" : dedupeValue === "Custodian"
                ? "Within Custodian" : dedupeValue;
          }

          request["relatedWorkRequestFields"] = getEDProcessingRelatedWrFields(edProcessingCustodians);
        }

      }
    }

    if (request["mediaType"] === "Unknown") {
      request["EnvironmentLocationStaging"] = val("hiddenHostingLocation");
      request["processingAppDatabaseId"] = val("hiddenProcessingAppDb");
    }
  }
  // TODO determine if this is nuix exclusive, since field shows regardless of app db type -> allows for DMX 14
  //request["deduplicationMethodNuix"] = val("2.9");

  // TODO i could condense these conditions if there aren't any unique scenarios down the road, but for now I'm be redundant incase things get specialized


  // ESI General work requests
  const requestType = valueDictionary["0.1.2"].value;
  const deliveryLocation = lookup("0.1.1", "deliveryLocation");
  const pdLocation = (deliveryLocation === "Electronic Discovery - US" || deliveryLocation === "SEA DC") ? "KC Pub" : "VDI";

  if (requestType === 1) {
    request["generalWorkRequestType"] = "Evidence Operations";
  } else if (requestType === 2) {
    request["generalWorkRequestType"] = "Litigation Support";
  } else if (requestType === 3 || requestType === 4) {
    request["generalWorkRequestType"] = "Hosting";
  }
  if (dbRecordType === DBRecordType.Nuix && !request["hasFormatFilterRelease"] && !request["hasEOMedia"]) {
    extraInstructions += request["custodians"] ? `Custodian: ${request["custodians"]}\n` : "";
  }
  if (hasPreProcFiltering && dbRecordType === DBRecordType.DMX14_Processing) {
    request["performImaging"] = val("4.6");
    request["pDLocation"] = pdLocation;
    request["dataSource"] = "None";

    if (request["hasFormatFilterRelease"] && dedupePMValue) {
      extraInstructions += `De-Duplication: ${dedupePMValue} \n`;
    }
    if (val("2") === 1) {
      extraInstructions += request["startDate"] ? `Date Filter: Yes\n Start Date: ${request["startDate"]}\n End Date: ${request["endDate"]}\n` : `Date Filter: No\n`;
    }
  }

  if (dbRecordType === DBRecordType.Epiq_Discovery_Processing && hasPreProcFiltering) {
    request["pDLocation"] = pdLocation;
    request["relatedWR"] = val("2.5.1.2") ? val("2.5.1.2") : "";

  }

  if (!hasPreProcFiltering && dbRecordType === DBRecordType.DMX14_Processing) {
    if (size >= 1) {
      request["pDLocation"] = pdLocation;
      request["dataSource"] = "None";
    }

    if (request["hasFormatFilterRelease"] && dedupePMValue) {
      extraInstructions += `De-Duplication: ${dedupePMValue} \n`;
    }
    request["includeImages"] = val("4.6");
  }

  request["priorityNotes"] = val("2.10.1");

  if (((dbRecordType === DBRecordType.Epiq_Discovery_Processing || dbRecordType === DBRecordType.DMX14_Processing || dbRecordType === DBRecordType.Nuix)
    && (request["hasProcessing"] || request["hasFormatFilterRelease"]))
    || val("6") === 2) {
    request["pMDocumentRelease"] = "Yes";
  }

  request["presentationPowerPointHandling"] = "Include";
  request["spreadsheetExcelHandling"] = "Include";

  if (request["hasILMProjectClosure"]) {
    const processingAppDbs = lookup("3.2", "Data");
    const hostingAppDbs = lookup("3.3", "Data");

    let assignDbs = [] as Array<IApplicationDatabaseFields>;
    if (processingAppDbs) {
      assignDbs = processingAppDbs as Array<IApplicationDatabaseFields>;
    }

    if (hostingAppDbs) {
      assignDbs = assignDbs.concat(hostingAppDbs as Array<IApplicationDatabaseFields>);
    }

    request["applicationDatabaseAssignment"] = assignDbs;
    request["projectStatus"] = "Pending Closed";
    request["originalClientEvidence"] = val("3.1") ? "Destroy" : "Not Applicable";
    request["processingDataDisposition"] = val("3.2") ? "Destroy Data (PRO330)" : "Not Applicable";
    request["hostingDataDisposition"] = val("3.3") ? "Destroy Data (PRO330)" : "Not Applicable";
    request["iNCBillingPageUpdateCADCLVDC"] = "NA - project not billed";
    request["iNCDisableUserRequest"] = "NA - user users exist";
  }

  if (request["hasILMPartialProjectDeletion"]) {
    const selectDb = val("11.1");
    const partialProcessAppDb = lookup("11.2", "Data");
    const partialHostingAppDbs = lookup("11.3", "Data");

    let assignDbs = [] as Array<IApplicationDatabaseFields>;
    if (selectDb === 1) {
      assignDbs = partialProcessAppDb && partialProcessAppDb as Array<IApplicationDatabaseFields>;
    } else {
      assignDbs = partialHostingAppDbs && partialHostingAppDbs as Array<IApplicationDatabaseFields>;
    }

    request["applicationDatabaseAssignment"] = assignDbs;
    request["processingDataDisposition"] = val("11.2") ? "Destroy Database (PRO330)" : "Not Applicable";
    request["hostingDataDisposition"] = val("11.3") ? "Destroy Database (PRO330)" : "Not Applicable";
    request["iNCBillingPageUpdateCADCLVDC"] = "NA - project not billed";
    request["iNCDisableUserRequest"] = "NA - user users exist";
  }

  if (request["HasILMSingleDbArchiveDormant"]) {
    const assignDbs = lookup("3.5", "Data") as Array<IApplicationDatabaseFields>;
    request["headline"] = "Epiq Access Work Request - ILM - Single Db Archive/Dormant";
    request["applicationDatabaseAssignment"] = assignDbs;
    request["iNCBillingPageUpdateCADCLVDC"] = "NA - project not billed";
    request["iNCDisableUserRequest"] = "NA - user users exist";
    request["processingDataDisposition"] = "Not Applicable";
    request["hostingDataDisposition"] = "ARM Export to Client (Relativity Only)";
    request["ShouldSubmit"] = true;
    request["encrypted"] = "Yes";
    request["encryptionPassword"] = "Epiq Standard Password (10 Character - Randomly Generated)";

    if (val("15.1.2") === 1) {
      const phoneNum = val("15.3.1") ? `Ph: ${val("15.3.1")}` : "";
      request["deliveryType"] = "Physical Media";
      request["recipientsAddress"] = val("15.2.1") + "\n" + phoneNum;
      request["encryptionMethod"] = "Hardware/Physical";
      request["labelingInstructions"] = val("15.9");
      if (val("15.10")) {
        extraInstructions += "Custom Label: " + val("15.10");
      }
      request["numberOfExportSets"] = val("15.12");
    }
    else {
      request["deliveryType"] = "FTP";
      request["encryptionMethod"] = "Zip"
      request["ftpLocation"] = val("15.6.1");
      const ftpSiteVal = val("15.5.1") ? val("15.5.1").toString() : "";
      const ftpSite = ftpSiteVal.replace("https://", "https###")
        .replace("file://", "file###").replace("ftp://", "ftp###");

      if (val("hiddenIsExternalFTPARM")) {
        request["externalFTPLocation"] = val("15.6.1")
        request["externalFTPUsername"] = val("15.7.1")
        request["externalFTPPassword"] = val("15.8.1")

        extraInstructions += `FTP Site URL: Non-Epiq external FTP site (${ftpSite}) \n\n  Username: ${val("15.7.1")} \n\n Password: ${val("15.8.1")}`;
      }
      else {
        request["sourceFilePath"] = val("15.6.1");
        extraInstructions += `FTP Site URL: ${ftpSite}`;
      }
    }
  }

  if (request["hasILMProjectClosure"] || request["hasILMPartialProjectDeletion"] || request["hasILMMediaDeletion"]) {
    request["sendIlmReportByEmail"] = val("13.1") === 1 ? true : false;
    request["additionalSendReportEmails"] = val("13.2");
  }

  if (request["hasILMMediaDeletion"]) {
    const deleteMedias = lookup("3.4", "selectedItems") as Array<any>;
    const deleteMediaIds = deleteMedias ? deleteMedias.map(m => m.SalesforceMediaId) : [];
    request["eDiscoveryMediaAssignment"] = deleteMediaIds;
  }

  if (request["hasMTMDataLoad"]) {
    request["headline"] = "Epiq Access MTM Dataload";
    request["deliveryType"] = "FTP";
    request["FTPSite"] = ("https://transfer.epiqsystems.com").replace("https://", "https###");
    request["mediaType"] = "MTM";
    const secureFileUploadText = text("10.1");
    if (secureFileUploadText) { // might be empty if we save for later
      request["sourceFilePath"] = "[GOAREPLACE]" + secureFileUploadText.replace("UploadSuccess", "") + "\\";
    }
    request["encrypted"] = val("10.2");
    request["encryptionPassword"] = val("10.3");
    request["ShouldSubmit"] = true;
    const mediaDescription = val("9.6");
    if (mediaDescription) {
      extraInstructions += "Media Description: " + mediaDescription + "\n";
    }
  }

  if (request["hasEOShipping"]) {
    const returnMedias = lookup("15.1", "selectedItems") as Array<any>;
    const returnMediaIds = returnMedias ? returnMedias.map(m => m.SalesforceMediaId) : [];
    request["eDiscoveryMediaAssignment"] = returnMediaIds;
    request["ShouldSubmit"] = true;
    request["mediaType"] = "Ship Project  Media";
    const phoneNum = val("15.3") ? `Ph: ${val("15.3")}` : "";

    if (val("15") === 1) { //Return the actual hard drive you shipped to Epiq
      request["deliveryType"] = "Physical Media";
      request["recipientsAddress"] = val("15.2") + "\n" + phoneNum;
      request["mediaType"] = "Ship Project Media";
    }
    else if (val("15") === 2) { //Return media that you provided via FTP or Hard-drive
      request["deliveryType"] = (val("15.4") && val("15.4") === 1) ? "FTP" : "Physical Media";
      request["mediaType"] = (val("15.4") && val("15.4") === 1) ? "Ship Project Media" : "Copy project media and ship (original or client deliverable)";

      if (val("15.4") === 1) {
        const mediaFtpSiteVal = val("15.5") ? val("15.5").toString() : "";
        const mediaFtpSite = mediaFtpSiteVal.replace("https://", "https###")
          .replace("file://", "file###").replace("ftp://", "ftp###");

        if (val("hiddenIsExternalFTP")) {
          request["FTPSite"] = "Non-Epiq external FTP site";
          request["externalFTPLocation"] = val("15.6")
          request["externalFTPUsername"] = val("15.7")
          request["externalFTPPassword"] = val("15.8")
        }
        else {
          const splitUrl = mediaFtpSiteVal.split('://');
          if (splitUrl.length === 1)
            request["FTPSite"] = `https###${mediaFtpSite}`;
          else
            request["FTPSite"] = mediaFtpSite;

          request["sourceFilePath"] = val("15.6");
        }
      } else if (val("15.4") === 2) {
        request["recipientsAddress"] = val("15.2") + "\n" + phoneNum;
        request["labelingInstructions"] = val("15.9");
        request["CustomRequestsSpecialInstructions"] = val("15.10") && val("15.10");
        request["ofSets"] = val("15.11");
      }
    }
  }

  request["pMInstructions"] = val("2.11") || "";
  if (extraInstructions.length) {
    request["pMInstructions"] += "\n\n" + extraInstructions;
  }

  if (request["pMInstructions"]) {
    request["enablePMInstructions"] = true;
  }

  const searchLocation = val("2.1.1");
  if (val("2") === 3 && searchLocation) {
    let searchLocationDbType = lookup("hiddenProcessingAppDb", "Db Record Type");
    if (searchLocation == 1 && val("2.2a")) {
      searchLocationDbType = lookup("2.2a", "Db Record Type");
    }

    const syntaxFormat = getSyntaxFormat(searchLocationDbType.toString().toLowerCase());
    request["syntaxFormat"] = syntaxFormat;
  }

  // request["filterAcrossCase"] = "No"; -- keep as null for now

  // 2.5.2.2 is notes and attachments

  // MISSING Pre-Approved, Perform Imaging, PM Document Release

  // "DMX - Prefiltering" ---------------

  // "Epiq Discovery - Prefiltering" and Processing (DMX) - TASK MISSING Pre-Approved, Perform Imaging, PM Document Release
  // Processing (DMX) task is also MISSING DMX14 Processing Template, PD Location, Custodians, Data Source

  // "DMX - Processing" & "Epiq Discovery - Processing" has "Include Images"
  // "DMX - Processing" & "Epiq Discovery - Processing" is MISSING Pre-Approved, Perform Imaging

  // "Nuix - Prefiltering" has "OCR", "Filter Current Ingestion", "Filter Across Case", "Include Date Filter", "Start Date", "End Date", "Keyword Search Terms", "Content", "Name (Subject)", "Perform Recipient Filter"

  // "Nuix-Processing" has "OCR", "Deduplication Method (Nuix)"
  // "Nuix-Processing" MISSING DMX14 Processing Template, PD Location, Custodians, Data Source

  // "Nuix processing TASK missing DMX14 Processing Template, PD Location, Custodians, Data Source, Pre-Approved, Perform Imaging, PM Document Release

  return request;
};

const getSyntaxFormat = function (dbRecordType: string) {
  switch (dbRecordType) {
    case DBRecordType.DMX14_Processing.toLowerCase():
    case "dmx13":
      return "Oracle (DMX)";
    case DBRecordType.Nuix.toLowerCase():
      return "DT_Search_Nuix";
    case DBRecordType.Epiq_Discovery_Processing.toLowerCase():
      return "Elastic (Epiq Discovery)";
    case "relativity processing":
    case DBRecordType.Relativity_Hosting.toLowerCase():
      return "DT Search (Relativity)";
    default:
      return dbRecordType;
  }
}

const getSelectedAppDbId = function (fieldValues: IFieldValueLookup) {
  const exportDataType = fieldValues["12"] && fieldValues["12"].value;
  if (exportDataType === 1) {
    return fieldValues["hiddenProcessingAppDb"].value;
  }
  else if (exportDataType === 2) {
    return fieldValues["2.2"].value;
  }
  else {
    return null;
  }
}

//To verify WR is smallsize ticket or not
const isSmallSizeProcessing = function (existingFieldVals: IFieldValueLookup) {
  const dbRecordType = (existingFieldVals['hiddenProcessingAppDb'] && existingFieldVals['hiddenProcessingAppDb'].lookupValues) ? existingFieldVals['hiddenProcessingAppDb'].lookupValues['Db Record Type'].toString() : "";
  const hasPreProcFiltering = (existingFieldVals["2.5.1.1"] && (existingFieldVals["2.5.1.1"].value === 1 || existingFieldVals["2.5.1.1"].value === 2)) || (existingFieldVals["2.5.3"] && existingFieldVals["2.5.3"].value === "Yes");
  const size = existingFieldVals["2.3"] ? existingFieldVals["2.3"].value : 0;
  const task = existingFieldVals["2"] ? existingFieldVals["2"].value : 0;

  return (task === 1 && (dbRecordType.toLowerCase() === 'nuix' || dbRecordType.toLowerCase() === 'epiq discovery processing' || dbRecordType.toLowerCase() === 'dmx14 - processing') && size <= 5);
}
