import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../store";
import * as SessionStore from "../../../store/Session";
import { AdvancedGrid, IGridParams } from "../../common/grid/AdvancedGrid";
import { GridColumnProps, GridCellProps, GridColumnResizeEvent } from "@progress/kendo-react-grid";
import { Application, ApplicationStatus } from "../../../models/ApplicationModels";
import { Button } from "@progress/kendo-react-buttons";
import { NavLink } from "react-router-dom";
import "./Applications.scss";
import AdminService from "../../../services/AdminService";
import { FilterDescriptor, CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { GridPreferences, GridUserPreferences, IGridPreference } from "../../../models/Preferences";
import { ColumnFromField, setSavedColumnsToGrid, setDefaultColumnsToGrid, getFields, saveGridColumnWidthPreferenceInDB } from "../../common/grid/columns/ColumnHelper";
import SettingsIcon from "@material-ui/icons/Settings";
import { ColumnFieldListBox } from "../../common/grid/columns/ColumnFieldListBox";
import Tooltip from "@material-ui/core/Tooltip";
import { IGridPreferenceModel, PreferenceTypeStringEnum } from "../../../models/PreferenceModel";

const defaultColWidth = 200;
const preferenceType = PreferenceTypeStringEnum.ApplicationListGridPreferences;
const tableKey = "ApplicationList";
const applicationListMasterFields = {
  [tableKey]: [{ field: "name", colWidth: defaultColWidth },
  { field: "epiqOnly", colWidth: defaultColWidth },
  { field: "instanceName", colWidth: defaultColWidth },
  { field: "url", colWidth: defaultColWidth },
  { field: "description", colWidth: defaultColWidth },
  { field: "statusId", colWidth: defaultColWidth },
  { field: "typeName", colWidth: defaultColWidth },
  { field: "connectorType", colWidth: defaultColWidth },
  { field: "ssoEnabled", colWidth: defaultColWidth }
  ]
} as Record<string, Array<GridPreferences>>;

const gridUserPreferences = {
  ["name"]: { field: "name", type: "text", displayName: "App Name", isDefault: true, isMandatory: true },
  ["epiqOnly"]: { field: "epiqOnly", type: "DropDown", displayName: "Epiq Admin Only", filterDropdownData: [{ name: "Epiq Admin", id: 1 }, { name: "Non Epiq Admin", id: 0 }], isDefault: true },
  ["instanceName"]: { field: "instanceName", type: "text", displayName: "Instance Name", isDefault: true },
  ["url"]: { field: "url", type: "text", displayName: "Website URL", isDefault: true },
  ["description"]: { field: "description", type: "text", displayName: "Description", isDefault: true },
  ["statusId"]: { field: "statusId", type: "DropDown", displayName: "Status", filterDropdownData: [{ name: "Active", id: 1, selected: true }, { name: "Deactivated", id: 2 }, { name: "Deleted", id: 3 }], isDefault: true, isMandatory: true },
  ["typeName"]: { field: "typeName", type: "text", displayName: "Application Type Name" },
  ["connectorType"]: { field: "connectorType", type: "text", displayName: "Connector Type" },
  ["ssoEnabled"]: { field: "ssoEnabled", type: "DropDown", displayName: "SSO Enabled", filterDropdownData: [{ name: "Yes", id: 1 }, { name: "No", id: 0 }] },
} as Record<string, GridUserPreferences>

type IProps = {
  history: any;
  applications: any;
};

interface IAppStatus {
  id: number;
  name: string;
};

type Props = IProps &
  SessionStore.ISessionState;

const appStatuses = new Array<IAppStatus>({ name: "Active", id: 1 }, { name: "Deactivated", id: 2 }, { name: "Deleted", id: 3 });

const gridParams: IGridParams = {
  skip: 0,
  take: 500,
  sort: [{ field: "name", dir: "asc" }],
  filter: { logic: "and", filters: [{ field: "statusId", operator: "eq", value: appStatuses[0] }] },
};
let myGrid: AdvancedGrid<Application, IGridParams>;

const Applications = (props: Props) => {
  const [applications, setApplications] = useState<Application[]>(props.applications);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [availableFields, setAvailableFields] = useState<Array<GridUserPreferences>>([]);
  const [selectedFields, setSelectedFields] = useState<Array<GridUserPreferences>>([]);
  const [showSettingsDialog, setShowSettingsDialog] = useState(false);
  const [columns, setColumns] = useState<Array<GridColumnProps>>([]);
  const [dataState, setDataState] = useState(gridParams);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setDynamicColumnsToGrid();
  }, [refresh]);

  const setDynamicColumnsToGrid = () => {
    const savedGridColumns = props.sessionData.userPrefs.ApplicationListGridPreferences as IGridPreference;
    if (savedGridColumns && savedGridColumns[tableKey]) {
      setColumns(setSavedColumnsToGrid(savedGridColumns[tableKey], gridUserPreferences, setColumnProperties, defaultColWidth));
    }
    else {
      setColumns(setDefaultColumnsToGrid(applicationListMasterFields[tableKey], gridUserPreferences, setColumnProperties));
    }
  }

  const setColumnProperties = (field: GridUserPreferences) => {
    const col = ColumnFromField(field);
    if (col.field.toLowerCase() === "name") {
      col.cell = props.sessionData.permissions.has("EpiqAdminGetApplication") ? appNameCell : appNameCellWithOutLink;
    }
    else if (col.field.toLowerCase() === "epiqonly")
      col.cell = epiqAdminOnly;
    else if (col.field.toLowerCase() === "statusid")
      col.cell = statusColumn;
    else if (col.field.toLowerCase() === "ssoenabled") {
      col.cell = ssoEnabledColumn;
    }
    else if (col.field.toLowerCase() === "description") {
      col.cell = appDescription;
    }

    return col;
  }

  const fetchApps = async (dataState: IGridParams) => {
    setIsLoading(true);
    const filters = dataState.filters
      ? dataState.filters.map(compositeFilter => {

        const epiqAdminOnlyFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "epiqOnly"
        ) as FilterDescriptor;
        const appStatusFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "statusId"
        ) as FilterDescriptor;

        const ssoEnabledFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "ssoEnabled"
        ) as FilterDescriptor;

        if (epiqAdminOnlyFilter) {
          const epiqAdminOnlyFilterValue = epiqAdminOnlyFilter.value
            ? epiqAdminOnlyFilter.value.id == 0 || epiqAdminOnlyFilter.value.id == 1
              ? epiqAdminOnlyFilter.value.id == 0
                ? false
                : true
              : null
            : false;
          return {
            ...compositeFilter,
            filters: [
              {
                ...epiqAdminOnlyFilter,
                field: "epiqOnly",
                operator: "eq",
                value: epiqAdminOnlyFilterValue
              }
            ]
          };
        }

        if (appStatusFilter) {
          const appStatusFilterValue = (appStatusFilter.value && appStatusFilter.value.id)
            ? appStatusFilter.value.id : 0;

          return {
            ...compositeFilter,
            filters: [
              {
                ...appStatusFilter,
                field: "statusId",
                operator: "eq",
                value: appStatusFilterValue
              }
            ]
          };
        }

        if (ssoEnabledFilter) {
          const ssoEnabledFilterValue = ssoEnabledFilter.value
            ? ssoEnabledFilter.value.id == 0 || ssoEnabledFilter.value.id == 1
              ? ssoEnabledFilter.value.id == 0
                ? false
                : true
              : null
            : false;
          return {
            ...compositeFilter,
            filters: [
              {
                ...ssoEnabledFilter,
                field: "ssoEnabled",
                operator: "eq",
                value: ssoEnabledFilterValue
              }
            ]
          };
        }

        return compositeFilter;
      })
      : [];
    const result = await AdminService.getApplicationsList({ ...dataState, filters: filters });

    if (result.ok) {

      setApplications(result.data.results);
      setCount(result.data.count);
      setDataState(dataState);

    } else {
      setHasError(true);
      console.log("Something went wrong while fetching applications.");
    }

    setIsLoading(false);

  }

  const handleAddApplication = () => {
    props.history.push("/administration/app/details/0");
  }

  const appNameCell = (props: GridCellProps) => {
    const name = props.dataItem.name;
    return (
      <td>
        {props.dataItem.statusId != ApplicationStatus.deleted ?
          <>
            <div className="app-name">
              <NavLink to={`/administration/app/details/${props.dataItem.id}`}>
                {name}
              </NavLink>
            </div>
          </> :
          <div className="app-name"> {name} </div>
        }
      </td>
    );
  }

  const appNameCellWithOutLink = (props: GridCellProps) => {
    const name = props.dataItem.name;
    return (
      <td>

        <div className="app-name">
          <em title={name}>
            {name}
          </em>
        </div>

      </td>
    );
  }

  const epiqAdminOnly = (props: any) => {
    return <td>{props.dataItem.epiqOnly ? "Epiq Admin" : "Non-Epiq Admin"}</td>;
  };

  const statusColumn = (props: any) => {
    return <td>{props.dataItem.statusId === ApplicationStatus.active ? "Active" : (props.dataItem.statusId === ApplicationStatus.deactivated ? "Deactivated" : "Deleted")}</td>;
  };

  const ssoEnabledColumn = (props: any) => {
    return <td>{props.dataItem.ssoEnabled ? "Yes" : "No"}</td>;
  };

  const appDescription = (props: any) => {
    return <td>
      <Tooltip classes={{ tooltip: "mui-tooltip-no-maxwidth tooltip-app-desc" }} enterDelay={2} title={props.dataItem.description} placement="top" arrow>
        <p>{props.dataItem.description}</p>
      </Tooltip>
    </td>;
  }

  const handleShowSettingDialog = () => {
    const savedGridColumns = props.sessionData.userPrefs.ApplicationListGridPreferences as IGridPreference;
    const masterFields = applicationListMasterFields[tableKey] as Array<GridPreferences>;
    const fields = getFields(savedGridColumns ? savedGridColumns[tableKey] : null, masterFields, gridUserPreferences);

    setAvailableFields(fields.availableFields);
    setSelectedFields(fields.selectedFields);
    setShowSettingsDialog(true);
  }

  const handleCloseSettingDialog = (reload: boolean) => {
    setShowSettingsDialog(false);
    setIsLoading(reload);
    if (reload) {
      setTimeout(async () => {
        const filter = { logic: "and", filters: [{ field: "statusId", operator: "eq", value: appStatuses[0] }] } as CompositeFilterDescriptor;
        const newState: IGridParams = {
          skip: 0,
          take: 500,
          sort: [{ field: "name", dir: "asc" }],
          filter: filter,
          filters: [filter] as CompositeFilterDescriptor[]
        };  //reset the grid data

        myGrid.resetGridState(newState, false, true);
        setDataState(newState);
        setRefresh(!refresh);
        setIsLoading(false);
      }, 500) // session object is taking some time to update when set the preferences, so I put the delay time out.
    }
  }

  const handleColumnResize = (event: GridColumnResizeEvent) => {
    if (event.end) {//Indicates that resizing is complete and the user has dropped the resize handler.
      const field = event.columns[event.index].field;
      const newWidth = event.columns[event.index].width;
      const savedGridColumns = props.sessionData.userPrefs.ApplicationListGridPreferences as IGridPreference;
      const masterFields = applicationListMasterFields[tableKey] as Array<GridPreferences>;
      const gridPref = ((savedGridColumns && savedGridColumns[tableKey]) ? savedGridColumns[tableKey] : masterFields.filter(x => (gridUserPreferences[x.field] && gridUserPreferences[x.field].isDefault === true)) as Array<GridPreferences>);
      const params = {
        tableKey: tableKey,
        preferenceName: preferenceType,
        existingFields: gridPref,
        resizeColumnfield: field,
        fieldNewWidth: newWidth
      } as IGridPreferenceModel

      saveGridColumnWidthPreferenceInDB(params);
    }
  }

  const gridToolBarRender = (): JSX.Element => {
    return (
      <div className="toolbar-button-container">
        <Button className="user-setting" onClick={handleShowSettingDialog}>
          <SettingsIcon />
        </Button>
        {props.sessionData.permissions.has("EpiqAdminCreateApplication") && (
          <Button className="add-user-btn" icon={"plus"} primary={true} onClick={handleAddApplication}> Application</Button>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="applications-admin-wrapper">
        <AdvancedGrid
          ref={(standardGridInstance: AdvancedGrid<Application, IGridParams>) => {
            myGrid = standardGridInstance;
          }}
          showErrorState={hasError}
          showLoadingIndicator={isLoading}
          sortable={{ mode: "multiple" }}
          data={applications}
          dataFetch={fetchApps}
          dataState={dataState}
          columns={columns}
          paging={false}
          totalRecords={{ value: count, label: "Applications" }}
          noRecordsRender={<p>No applications found.</p>}
          noLoadOnMount={false}
          filteredDataOnly={<p>Please filter to search for applications.</p>}
          multiFieldFilterDelimiter="|"
          filterOperators={{
            text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
            date: [{ text: "grid.filterEqOperator", operator: "eq" }]
          }}
          gridToolbarContent={gridToolBarRender()}
          columnVirtualization={false}
          onColumnResize={handleColumnResize}
        />
      </div>

      <ColumnFieldListBox
        showSettingFieldsDialog={showSettingsDialog}
        availableFields={availableFields}
        selectedFields={selectedFields}
        onSettingsDialogClose={handleCloseSettingDialog}
        tableKey={tableKey}
        preferenceName={preferenceType}
      />

    </>
  );
};

export default connect(
  (state: IApplicationState) => ({
    ...state.sessionState,
  })
)(Applications as any);
