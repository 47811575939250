import React, { useEffect, useState } from "react";
import { ISessionManager, SessionManager } from "../SessionManager";
import { connect } from "react-redux";
import * as SessionStore from "../store/Session";
import { IApplicationState } from "../store";
import * as AuthenticationStore from "../store/Authentication";
import HeaderWrapper from "../components/common/HeaderWrapper";
import { EpiqFooterComponent } from "../components/edge/footer/EpiqFooterComponent";
import epiqwhiteLogo from "../images/epiq-logo-white.svg";
import "@progress/kendo-theme-material/dist/all.css";
import "./Layout.scss";

interface IProps {
  sessionManager: ISessionManager;
  history: any;
}

enum Action {
  Accept = "accept",
  Decline = "decline"
}

type Props = IProps &
  SessionStore.ISessionState &
  AuthenticationStore.IAuthenticationState &
  typeof AuthenticationStore.actionCreators &
{ children?: React.ReactNode };

export const BasicAuthenticatedLayout = (props: Props) => {
  useEffect(() => {
  });

  const content = (
    <>
      <React.Fragment>
        {props.sessionData && 
          <HeaderWrapper sessionManager={props.sessionManager} />
        }

        <div>{props.children}</div>

        <EpiqFooterComponent footerLogo={epiqwhiteLogo} />
      </React.Fragment>
    </>
  )

  return (
    <>
      {content}
    </>
  )
};

export default connect((state: IApplicationState) => ({ ...state.authenticationState, ...state.sessionState }), {
  ...AuthenticationStore.actionCreators,
  ...SessionStore.actionCreators,
})(BasicAuthenticatedLayout);
