import React, { useEffect, useRef, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  GridColumnProps
} from "@progress/kendo-react-grid";
import {
  IWizardSection,
  IFieldData,
  IFieldValueLookup,
  IWizardDependencyConditionDescriptor,
  IWizardDependencyCondition,
  IWizardValues,
  IWizardSummary,
  IAdditionalSummaryFields,
  IMediaDetails
} from "../../../models/Wizard";
import WizardHelper from "../utilities/WizardHelper";
import SummaryListView from "./SummaryListView";
import GridView from "./GridView";
import "./WizardSummary.scss";
import ReactMarkdown from "react-markdown";
import { User } from "../../../models/UserModels";
import CommonHelper from "../utilities/CommonHelper";

interface IProps {
  fieldValues: IFieldValueLookup;
  wizardSummary?: IWizardSummary;
  wizardSections: Array<IWizardSection>;
  dependencySatisfied: (dependency: IWizardDependencyConditionDescriptor | IWizardDependencyCondition, valDictionary: IFieldValueLookup) => boolean;
  setPage?: (pageNum: number, valDictionary: IFieldValueLookup) => void;
  showMedia?: boolean;
  mediaDetails?: IMediaDetails;
  permissions?: Set<string>;
}

type Props = IProps;

const getLabel = WizardHelper.getLabel;

const WizardSummary = (props: Props) => {

  const getMediaDetailsSummary = (mediaDetails: IMediaDetails) => {
    return <>
      {mediaDetails ? <></> : <div className="media-await-input">Awaiting input for media listing</div>}
      <div className="flex-container">
        <div className="request-div">
          OVERVIEW
        </div>
      </div>
      <div className="inner-section summary-half-wrap">
        <div className="field-name">Media ID</div>
        <div className="field-value">{(mediaDetails && mediaDetails.mediaName) || "-"} </div>
      </div>
      <div className="inner-section summary-half-wrap">
        <div className="field-name">Extracted File Count</div>
        <div className="field-value">{mediaDetails && mediaDetails.extractedFileCount !== null ? mediaDetails.extractedFileCount : "-"}</div>
      </div>
      <div className="inner-section summary-half-wrap">
        <div className="field-name">Extracted Size(GB)</div>
        <div className="field-value">{mediaDetails && mediaDetails.extractedSizeInGb !== null ? mediaDetails.extractedSizeInGb : "-"}</div>
      </div>
      <div className="inner-section summary-half-wrap">
        <div className="field-name">Media Description</div>
        <div className="field-value">{(mediaDetails && mediaDetails.mediaDescription) || "-"}</div>
      </div>
      <hr className="media-details-break" />
    </>
  }
  const fieldSummaryText = function (val: IWizardValues, field: IFieldData) {
    if (val == null && field.type === "checkbox") {
      return "No";
    } else if (val == null && field.value) {
      return field.value;
    } else if (!val && field.noInputText) {
      return field.noInputText;
    } else if (val == null) {
      return "";
    }

    if (val.altText) {
      return val.altText;
    } else if (val.text && !(field.type === "multiselect" && field.renderType == "users")) {
      return val.text;
    }

    if (field.type === "checkbox") {
      return val.value ? "Yes" : "No";
    } else if (field.subType == "password") {
      return val.value ? "Supplied" : "Not Supplied";
    }
    else if (val.value === null) {
      return "";
    }

    if ((field.type === "tableViewField") || (field.type === "multiselect" && field.renderType == "users")) {
      return <SummaryListView skip={0} take={15} items={val.value as Array<string>} />
    }

    if (field.type === "editGridField" || field.type === "selectGrid") {
      return <GridView {...val} />
    }

    if (field.hasMarkdown) {
      const description = val.value && CommonHelper.getNewLineForMarkDown(val.value.toString());
      return <ReactMarkdown skipHtml={true} children={description} />;
    }

    return val.value ? val.value.toString() : "";
  };

  return (
    <div className="wizard-summary-content">
      {props.wizardSummary && <>
        <div className="work-request-summary">{props.wizardSummary.title}</div>
        <div className="summary-description">{WizardHelper.getLabel(props.wizardSummary.description, props.fieldValues)}</div>
        {props.wizardSummary.getApprovalInfo && props.wizardSummary.getApprovalInfo(props.fieldValues, props.permissions)}
        {props.wizardSummary.getSummaryExtraDescription && props.wizardSummary.getSummaryExtraDescription(props.fieldValues) && <div className="summary-extra-description">{props.wizardSummary.getSummaryExtraDescription(props.fieldValues)}</div>}
      </>}
      {props.showMedia && getMediaDetailsSummary(props.mediaDetails)}
      {props.wizardSections.map((section, index) => {

        if (section.hideSummary) {
          return <></>;
        }

        const sectionFilter = section.fields
          .filter(field => {
            return (
              props.dependencySatisfied(field.dependency, props.fieldValues) &&
              field.type !== "hiddenlookup" &&
              field.type !== "displayOnlyField" &&
              field.hideFieldInSummary !== true &&
              (field.hideIfEmpty ? (props.fieldValues ? props.fieldValues[field.fieldId] && props.fieldValues[field.fieldId].value : true) : true)
            );
          });

        if (!props.dependencySatisfied(section.dependency, props.fieldValues) || (sectionFilter.length === 0 && section.hideSummaryIfFieldsEmpty)) {
          return <></>;
        }

        const hideEdit = section.hideSummaryEdit && section.hideSummaryEdit(props.fieldValues);

        return (
          <>
            <div className="flex-container">
              <div className="request-div">
                {section.summaryTitle === undefined ? section.header : getLabel((section.summaryTitle), (props.fieldValues))}
              </div>
              {props.setPage && !hideEdit && <div>
                <Button icon="pencil" onClick={t => props.setPage(index, props.fieldValues)} />
              </div>}
            </div>
            {section.summaryDescription && <div className="summary-description">{section.summaryDescription(props.fieldValues)}</div>}
            {sectionFilter
              .map(field => {
                const addFields = props.fieldValues && props.fieldValues[field.fieldId] && props.fieldValues[field.fieldId].lookupValues && props.fieldValues[field.fieldId].lookupValues["AdditionalSummaryFields"] as IAdditionalSummaryFields;
                return (
                  <div key={`summaryitemkey-${field.name}`} className={`inner-section ${field.summaryCssName || ""}`}>
                    {field.name && <div className="field-name">{field.name}</div>}
                    <div className="field-value">{fieldSummaryText(props.fieldValues ? props.fieldValues[field.fieldId] : null, field)}</div>
                    {addFields &&
                      Object.keys(addFields).map((key) => {
                        return (
                          <div>
                            <div className="field-name">{key}</div>
                            <div className="field-value">{addFields[key]}</div>
                          </div>
                        );
                      })}
                  </div>
                );
              })}
            <hr className="section-break" />
          </>
        );
      })}
      <div className="wizard-footer-summary">
        {props.wizardSummary && props.wizardSummary.footerSummary && props.wizardSummary.footerSummary((props.fieldValues))}
      </div>
    </div>
  );
}

export default WizardSummary;