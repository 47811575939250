import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { GridCellProps  } from "@progress/kendo-react-grid";
import { AdvancedGrid, IGridParams } from "../../common/grid/AdvancedGrid";
import ResourceService from "../../../services/ResourceService";
import "./ResourceList.scss";
import { ResourceTypes } from "../../../models/Enums";
import AssignmentDetails from "./AssignmentDetails";
import * as SessionStore from "../../../store/Session";
import GridSelector from "../../common/grid/GridSelector";
import { IApplicationState } from "../../../store";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";


interface IProps{
  resourceId:number;
}

type Props = IProps & SessionStore.ISessionState;

type selectedResource = {
  type: number;
  id: number;
  name: string;
  epiqOnly?: boolean;
  automated?: string;
  showViewButton: false;
  description: string;
}

const ResourceAssignments = (props:Props) => {
  
  const resourceId = props.resourceId;
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const [selectedResourceDetails, setSelectedResourceDetails] = useState({} as selectedResource);
  const [isOpenDetailsView, setIsOpenDetailsView] = useState(false);
  const [isOpenTooltip, setIsOpenTooltip] = useState(true);
  const permissions = props.sessionData.permissions;

  const dataState: IGridParams = {
    skip: 0,
    take: 100
  };

  const fetchResourceAssignmentsData = async (dataState: IGridParams) => {
    setIsLoading(true);
    const filter = [{
      logic: "or",
      filters: [{
        field: "resourceId",
        operator: "eq",
        value: resourceId
      }]
    } as CompositeFilterDescriptor] as CompositeFilterDescriptor[];
    const result = await ResourceService.getResourceAssignments({ ...dataState, filters: filter});
    if (result.ok) {
      setAssignments(result.data.results);
    } else {
      setHasError(true);
      console.log("Something went wrong while fetching assignments.");
    }

    setIsLoading(false);
  };


  const userGroupCell = (props: GridCellProps) => {
    const selectedResource = {
      type: ResourceTypes.USER_GROUP,
      id: props.dataItem.usersGroup.groupId,
      name: props.dataItem.usersGroup.groupName,
      epiqOnly: props.dataItem.usersGroup.epiqOnly,
      showViewButton: permissions.has("EpiqAdminGetUserGroup"),
      description: props.dataItem.usersGroup.comments
    } as selectedResource

    return <td>{permissions.has("EpiqAdminGetUserGroup") ? assignmentCellLink(selectedResource) : assignmentCell(selectedResource)}</td>;
  
  }

  const roleCell = (props: GridCellProps) => {
    const selectedResource = {
      type: ResourceTypes.ROLE,
      id: props.dataItem.role.id,
      name: props.dataItem.role.roleName,
      epiqOnly: props.dataItem.role.epiqOnly,
      showViewButton: permissions.has("EpiqAdminGetRole"),
      description: props.dataItem.role.description
    } as selectedResource;

    return <td>{permissions.has("EpiqAdminGetRole") ? assignmentCellLink(selectedResource) : assignmentCell(selectedResource)}</td>;
  }

  const resourceGroupCell = (props: GridCellProps) => {
     const selectedResource = {
      type: ResourceTypes.RESOURCE_GROUP,
      id: props.dataItem.resourcesGroupId,
      name: props.dataItem.resourcesGroup.groupName,
      epiqOnly: props.dataItem.resourcesGroup.epiqOnly,
      automated: (props.dataItem.resourcesGroup.automationFilter != null ? "Yes" : "No"),
      showViewButton: permissions.has("EpiqAdminGetResourceGroup"),
      description: props.dataItem.resourcesGroup.description
    } as selectedResource

    return (
      <td>
        {props.dataItem.resourcesGroupId ? (
          <>
            {permissions.has("EpiqAdminGetResourceGroup") ? assignmentCellLink(selectedResource) : assignmentCell(selectedResource)}
          </>
        ) : (
            <>{props.dataItem.singleResource.name}</>
          )}
      </td>
    );
  }

  const assignmentCellLink = (selectedResourceDetails: selectedResource) => {
    return (
      <div className="assignmentLinks">
        <a
          className="k-link"
          onClick={e => {
            setIsOpenTooltip(false);
            setSelectedResourceDetails(selectedResourceDetails);
            setIsOpenDetailsView(true);
          }}
        >
          <em title={selectedResourceDetails.name} onMouseOver={() => setIsOpenTooltip(true)}>
            {selectedResourceDetails.name}
          </em>
        </a>
      </div>
    );
  };

  const assignmentCell = (selectedResourceDetails: selectedResource) => {
    return (
      <div className="assignmentLinks">
        <em title={selectedResourceDetails.name} onMouseOver={() => setIsOpenTooltip(true)}>{selectedResourceDetails.name}</em>
      </div>
    )
  };

  const handleAddUsersPopupClose = () => {
    setIsOpenDetailsView(false);
  };

  const getViewButtonLink = () => {
    let url = "";

    switch (selectedResourceDetails.type) {
      case ResourceTypes.USER_GROUP:
        url = url + `/administration/userGroupDetails/${selectedResourceDetails.id}`;
        break;
      case ResourceTypes.ROLE:
        url = url + `/administration/roleDetails/${selectedResourceDetails.id}`;
        break;
      case ResourceTypes.RESOURCE_GROUP:
        url = url + `/administration/resourceGroupDetails/${selectedResourceDetails.id}`;
        break;
    }

    return url;
  };

  const columns = [
    { field: "usersGroup.GroupName", title: "USER GROUP", cell: userGroupCell, filterable: false, sortable: false, headerClassName: "no-sort" },
    { field: "role.RoleName", title: "ROLE", cell: roleCell, filterable: false, sortable: false, headerClassName: "no-sort" },
    {
      field: "resourcesGroup.GroupName",
      title: "RESOURCE GROUP",
      cell: resourceGroupCell,
      filterable: false,
      sortable: false,
      headerClassName: "no-sort"
    }
  ];

  return (
    <div className="resource-assignment-list">
      <Tooltip openDelay={2} position="right" open={isOpenTooltip}>
        <AdvancedGrid
          showErrorState={hasError}
          showLoadingIndicator={isLoading}
          data={assignments}
          dataFetch={fetchResourceAssignmentsData}
          dataState={dataState}
          columns={columns}
          paging={false}
          noRecordsRender={<p>No Assignments found.</p>}
          noLoadOnMount={false}
          filteredDataOnly={false}
          multiFieldFilterDelimiter="|"
          filterOperators={{
            text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
            date: [{ text: "grid.filterEqOperator", operator: "eq" }],
            numeric: [{ text: "grid.filterEqOperator", operator: "eq" }]
          }}
          moreRecordsAvailableMessage="Too many assignments to display. Adjust filters to refine results"
        />
      </Tooltip>
      <GridSelector
        isOpen={isOpenDetailsView}
        viewBtnText={selectedResourceDetails.showViewButton ? "VIEW DETAILS" : ""}
        viewBtnUrl={getViewButtonLink()}
        prefixTitleText={
          ResourceTypes[selectedResourceDetails.type]
            ? ResourceTypes[selectedResourceDetails.type].replace("_", " ")
            : ""
        }
        titleText={selectedResourceDetails.name}
        addAvailable={false}
        onClose={handleAddUsersPopupClose}
        addClass="details-view-modal modal-as-sidebar"
      >
        <AssignmentDetails
          resourceId={selectedResourceDetails.id}
          resourceType={selectedResourceDetails.type}
          epiqOnly={selectedResourceDetails.epiqOnly ? "Epiq Admin" : "Non-Epiq Admin"}
          automated={selectedResourceDetails.automated}
          description={ selectedResourceDetails.description}
        />
      </GridSelector>
    </div>
  );
}

export default connect((state: IApplicationState) => ({ ...state.sessionState }), {})(ResourceAssignments as any);