import React, { Component } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../../store";
import * as UserGroupStore from "../../../../store/UserGroup";
import * as HeaderStore from "../../../../store/Header";
import * as WarningMessageStore from "../../../../store/WarningMessage";
import { Constants } from "../../../formElements/Inputs";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import GridSelector from "../../../common/grid/GridSelector";
import { UserGroup } from "../../../../models/UserModels";
import { User } from "../../../../models/UserModels";
import { ShowEpiqOnlyControls } from "../../../../models/Enums";
import GroupUserList from "../../../administration/user/usergroups/GroupUserList";
import { TextInput } from "../../../common/TextInput";
import { Checkbox } from "@progress/kendo-react-inputs";
import AddUserToGroup from "../usergroups/AddUsersToGroup";
import "../../../../settings/kendo-drop-downs.scss";
import * as SessionStore from "../../../../store/Session";
import CommonHelper from "../../../common/utilities/CommonHelper";
import "./UserGroups.scss";
import ErrorIcon from "@material-ui/icons/Error";

let usergroup: UserGroup = {
  groupId: 0,
  groupName: "",
  comments: ""
};

const GroupConfigurations = {
  MinimumCharacters: 3
};

enum Group {
  clientName = "Find client by name or keyword",
  userGroupName = "(e.g. Review Managers)",
  description = "Description"
}

enum ErrorMessages {
  GroupExists = "User group already exists.",
  Required = "This field is required.",
  EpiqAdminOnlyError = "Cannot set Epiq Admin Only flag when group contains external users."
}

interface IProps {
  history: any;
}

type Props = IProps &
  UserGroupStore.IUserGroupState &
  typeof UserGroupStore.actionCreators &
  HeaderStore.IHeaderState &
  typeof HeaderStore.actionCreators &
  WarningMessageStore.IWarningMessageState &
  typeof WarningMessageStore.actionCreators &
  SessionStore.ISessionState;

type State = {
  comments: string;
  groupName: string;
  commentsLength: number;
  isGroupExist: boolean;
  selectedUsers: User[];
  potentiallySelectedUsers: User[];
  isUserSelectorOpen: boolean;
  isEpiqOnly: boolean;
};

class CreateUserGroup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      comments: "",
      groupName: "",
      commentsLength: 0,
      isGroupExist: false,
      selectedUsers: [],
      potentiallySelectedUsers: [],
      isUserSelectorOpen: false,
      isEpiqOnly: false
    };

    this.props.setHeaderButtons(this.getBtnsList(true), "", "/administration/userGroups", "Back To User Group List");
  }

  async componentDidMount() {
    this.props.setHeaderButtons(this.getBtnsList(true), "", "/administration/userGroups", "Back To User Group List");
  }

  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
    if (prevState != this.state) {
      let isEnable = !(this.state.groupName.length >= GroupConfigurations.MinimumCharacters);

      if (this.state.isEpiqOnly && this.state.selectedUsers.length > 0) {
        isEnable = this.isListHasNonEpiqUsers() > 0 ? true : false;
      }

      this.props.setHeaderButtons(
        this.getBtnsList(isEnable),
        "",
        "/administration/userGroups",
        "Back To User Group List"
      );
      let isUnsavedChangesExist =
        this.state.commentsLength > 0 || this.state.selectedUsers.length > 0 || this.state.groupName.length > 0;
      this.props.updateUnsavedChanges(isUnsavedChangesExist);
    }
  }

  componentWillUnmount() {
    this.props.setHeaderButtons(null);
    this.props.saveUnsavedChanges(null, false);
  }

  onChange = (value: string) => {
    this.setState({ isGroupExist: false, groupName: value });
  };

  requiredFieldValidatorNoShow = (value: string) => {
    return value && value.trim().length > 0 ? "" : Constants.NO_SHOW_VALIDATION_MESSAGE;
  };

  getBtnsList: any = (isEnable: boolean, isSaving: boolean) => {
    const buttons: any = [
      {
        buttonLabel: "Cancel",
        type: "button",
        handleClick: this.handleCancelClick
      },
      {
        buttonLabel: "Create",
        handleClick: this.createUserGroupOnclick,
        color: "primary",
        disabled: isEnable,
        formId: "CreateUserGroupFormId",
        isSaving: isSaving
      }
    ];
    return buttons;
  };

  handleChange = (value: string) => {
    this.setState({ comments: value, commentsLength: value.length });
  };

  handleCancelClick = () => {
    this.props.history.push("/administration/userGroups");
  };

  createUserGroupOnclick = async () => {
    const { groupName, comments, isEpiqOnly } = this.state;
    const usergroup = {
      groupId: 0,
      groupName: groupName,
      comments: comments,
      addUsers: this.state.selectedUsers.map(user => user.id),
      removeUsers: [] as number[],
      epiqOnly: isEpiqOnly,
      domainIds: [] as number[]
    };
    this.props.setHeaderButtons(
      this.getBtnsList(true, true),
      "",
      "/administration/userGroups",
      "Back To User Group List"
    );
    await this.props.createUserGroup(usergroup);

    if (this.props.isInvalid || this.props.hasError) {
      this.setState({ isGroupExist: this.props.isGroupAlreadyExists });
      this.props.setHeaderButtons(this.getBtnsList(true), "", "/administration/userGroups", "Back To User Group List");
    } else {
      this.props.updateUnsavedChanges(false);
      this.props.history.push("/administration/userGroups");
    }
  };

  handleUsersPopupClose = (accepted: boolean) => {
    if (accepted) {
      const dedupeObjects = CommonHelper.dedupeObjects<User>(
        this.state.potentiallySelectedUsers,
        this.state.selectedUsers || new Array<User>(),
        "id"
      );
      const dedupedUsers = dedupeObjects.map(
        item => (({ ...item, selected: false } as any) as User)
      ) as Array<User>;
      this.setState({ selectedUsers: dedupedUsers, isUserSelectorOpen: false, potentiallySelectedUsers: [] });
    } else {
      this.setState({ isUserSelectorOpen: false, potentiallySelectedUsers: [] });
    }
  };

  selectionChange = (selected: User[]) => {
    this.setState({ selectedUsers: selected });
  };

  onAddPotentiallySelectedUsers = (potentiallySelectedUsers: User[]) => {
    this.setState({ potentiallySelectedUsers });
  };

  onRemoveUsers = (users: User[]) => {
    this.setState({ selectedUsers: users });
  };

  handleAddUsers = () => {
    this.setState({ isUserSelectorOpen: true });
  };

  onCheckBoxChange = (isChecked: boolean) => {
    this.setState({ isEpiqOnly: isChecked });
  };

  private isListHasNonEpiqUsers() {
    const count = this.state.selectedUsers.filter(item => {
      if (!this.isEpiqUser(item.username))
        return true;
    }).length;
    return count;
  }

  isEpiqUser = (email: string) => {

    const userDomain = email && email.substring(email.indexOf('@') + 1, email.length);
    const epiqDomains = this.props.sessionData.internalUsersDomains;
    return epiqDomains && epiqDomains.has(userDomain.toLowerCase());
  }

  render() {
    const { isEpiqOnly } = this.state;
    const showErrorMessage = (isEpiqOnly && this.isListHasNonEpiqUsers() > 0) ? true : false;

    let showEpiqOnlyAdmin;
    if (this.props.sessionData.superAdmin) {
      showEpiqOnlyAdmin = ShowEpiqOnlyControls.ENABLE;
    } else if (this.props.sessionData.permissions.has("ShowEpiqOnlyUserGroups")) {
      showEpiqOnlyAdmin = ShowEpiqOnlyControls.SHOW
    } else {
      showEpiqOnlyAdmin = ShowEpiqOnlyControls.HIDE
    }

    return (
      <Paper className="create-user-wrapper  no-separator">
        <div className="add-user papergrid-space">
          <h3 className="section-head">Create User Group</h3>
          <div className="field-hint">* Required Fields</div>
          <fieldset className="create-user-group-form">
            <Grid container className="user-create">
              <Grid item sm={6} md={4}>
                <div className="text-input-has-error">
                  <TextInput
                    type="text"
                    name="groupName"
                    legacyMode={true}
                    label="* User Group Name"
                    placeholder={Group.userGroupName}
                    maxLength={250}
                    validations={[
                      { name: "required", errorMessage: ErrorMessages.Required },
                      {
                        name: "CustomValidation",
                        errorMessage: `${ErrorMessages.GroupExists}`,
                        predicate: `${this.state.isGroupExist ? true : false}`
                      }
                    ]}
                    onChange={this.onChange}
                    displayCustomValidationMessage={this.state.isGroupExist ? true : false}
                  />
                </div>
                <div className="text-input-has-error">
                  {(showEpiqOnlyAdmin == ShowEpiqOnlyControls.ENABLE ||
                    showEpiqOnlyAdmin == ShowEpiqOnlyControls.SHOW) && <Checkbox
                      defaultChecked={isEpiqOnly ? true : false}
                      onChange={(event) => this.onCheckBoxChange(event.value)}
                      name="epiqOnly"
                      label="Epiq Admin Only"
                      disabled={showEpiqOnlyAdmin == ShowEpiqOnlyControls.ENABLE ? false : true}
                    />
                  }
                </div>
                {showErrorMessage && (
                  <div className="error-message">
                    <div className="error-info">
                      <span>
                        <ErrorIcon />
                      </span>
                      <span className="error-msg">{ErrorMessages.EpiqAdminOnlyError}</span>
                    </div>
                  </div>
                )}
              </Grid>
              <Grid item sm={6} md={4}>
                <TextInput
                  type="textarea"
                  label="Description"
                  name="description"
                  legacyMode={true}
                  onChange={this.handleChange}
                  notesLength={this.state.commentsLength}
                  maxLength={255}
                  placeholder="Enter a short description."
                />
              </Grid>
            </Grid>
          </fieldset>
        </div>

        <div className="grid-wrapper add-users-to-group">
          <GridSelector
            isOpen={this.state.isUserSelectorOpen}
            acceptBtnText="Add"
            cancelBtnText="Cancel"
            titleText="Add Users"
            addAvailable={this.state.potentiallySelectedUsers.length > 0}
            onClose={this.handleUsersPopupClose}
            addClass="add-user-group-modal  modal-as-sidebar"
          >
            <AddUserToGroup
              groupName={this.state.groupName}
              onAddUpdateUsers={this.onAddPotentiallySelectedUsers}
              {...this.props}
              selectedGroupUsers={this.state.selectedUsers}
            />
          </GridSelector>
          <GroupUserList
            users={this.state.selectedUsers}
            addClickHandler={this.handleAddUsers}
            selectionChange={this.selectionChange}
            onRemoveUsers={this.onRemoveUsers}
            permissions={this.props.sessionData.permissions}
          />
        </div>
      </Paper>
    );
  }
}

export default connect(
  (state: IApplicationState) => ({ ...state.headerState, ...state.userGroupState, ...state.warningMessageState, ...state.sessionState }),
  {
    ...HeaderStore.actionCreators,
    ...UserGroupStore.actionCreators,
    ...WarningMessageStore.actionCreators
  }
)(CreateUserGroup as any);
