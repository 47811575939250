import * as React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import * as SessionStore from "../../store/Session";
import IdleTimer from "react-idle-timer";
import SessionLostModal from "./SessionLostModal";
import { IIdleTimer, ISessionManager } from "../../SessionManager";

interface IProps {
  sessionManager: ISessionManager;
}

type Props = IProps & SessionStore.ISessionState;

const SessionLost = (props: Props) => {
  return (
    <React.Fragment>
      <IdleTimer
        ref={(ref: IIdleTimer) => {
          props.sessionManager.sessionLostWindowAndTabSyncTimer = ref;
        }}
        events={[]}
        element={document}
        onIdle={props.sessionManager.handleSessionLostWindowAndTabSync.bind(props.sessionManager)}
        timeout={props.sessionManager.windowAndTabSyncTimeout}
      />
      {props.isLost && (
        <IdleTimer
          ref={(ref: IIdleTimer) => {
            props.sessionManager.timeoutTimer = ref;
          }}
          events={[]}
          element={document}
          onIdle={props.sessionManager.clearSession}
          timeout={props.sessionManager.timeout}
        />
      )}
      <SessionLostModal
        closeCallback={props.sessionManager.handleSessionLostWarningClosed.bind(props.sessionManager)}
        endSessionCallback={props.sessionManager.clearSession}
        timeout={props.sessionManager.timeout}
        warningTimeout={props.sessionManager.warningTimeout}
      />
    </React.Fragment>
  );
};

export default connect((state: IApplicationState) => state.sessionState, null)(SessionLost);
