import React, { ReactNode, useEffect, useState } from "react";
import { SiteBrandingModel } from "../../../../models/SiteBrandingModel";
import { Editor, EditorTools } from "@progress/kendo-react-editor";
import SiteBrandingService from "../../../../services/SiteBrandingService";
import NotificationService from "../../../../services/NotificationService";
import * as SessionStore from "../../../../store/Session";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { CircularProgress } from "@material-ui/core";
import { TextInput } from "../../../common/TextInput";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import "./BrandedEmails.scss";

interface IProps {
    siteDetails?: SiteBrandingModel;
    onFormInputChangeEvent: (value: string, name: string, isValid: boolean) => void;
}

interface IPreviewProps {
    srcDoc: string;
}

interface IEmailTemplates {
    emailInviteTemplate: string;
    emailInviteFederatedTemplate: string;
    emailForgotPasswordTemplate: string;
    emailPasswordChangeTemplate: string;
    emailForgotPasswordFederatedTemplate: string;
}

type Props = IProps //& SessionStore.ISessionState;

const BrandedEmails = (props: Props) => {

    const [templates, setTemplates] = useState<IEmailTemplates>(null);
    const [errorMessage, setErrorMessage] = useState<string>(null);
    const [templateLoading, setTemplateLoading] = useState<boolean>(true);
    const [customizeEmails, setCustomizeEmails] = useState<boolean>(!!(props.siteDetails.emailForgotPassword || props.siteDetails.emailInvite || props.siteDetails.emailInviteFederated || props.siteDetails.emailPasswordChange || props.siteDetails.emailForgotPasswordFederated));
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
    const [showFederated, setShowFederated] = useState<boolean>(false);
    const [showPreview, setShowPreview] = useState<boolean>(false);

    useEffect(() => {
        const getEmailTemplates = async () => {
            const results = await SiteBrandingService.getEmailTemplates();

            if (!results.ok) {
                setErrorMessage("Failed to load email templates");
            }

            setTemplateLoading(false);
            setTemplates(results.data);
        }

        getEmailTemplates();
    }, []);

    useEffect(() => {}, [errorMessage, customizeEmails, props.siteDetails, templates]);

    // TODO if we keep this code and not scrap it, then I should make an enum of this at least
    // 1- invite
    // 2- invite (federated)
    // 3- forgot password
    // 4- password changed
    // 5- forgot password (federated)
    const sendEmailTest = async () => {

        let emailId = 0;

        if (selectedTabIndex == 0 && !showFederated) {
            emailId = 1;
        }
        else if (selectedTabIndex == 0) {
            emailId = 2;
        }
        else if (selectedTabIndex == 1 && showFederated) {
            emailId = 5;
        }
        else if (selectedTabIndex == 1) {
            emailId = 3;
        }
        else if (selectedTabIndex == 2) {
            emailId = 4;
        }

        const response = await SiteBrandingService.sendTestEmail(emailId, props.siteDetails.siteId);

        if (response.ok) {
            NotificationService.showSuccessToast("Test email sent");
        }
        else {
            NotificationService.showErrorToast("Failed to send test email");
        }
    }

    if (templateLoading || !templates) {
        return (<div className="embed-container spinner-wrapper">
            <CircularProgress />
        </div>);
    }

    if (errorMessage) {
        return <div>{errorMessage}</div>;
    }

    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelectedTabIndex(e.selected);
    };

    const ButtonHeader = <div className="branded-emails-top-buttons">
                <ButtonGroup>
                    <Button className={"btn-switch-left " + (!showFederated ? "btn-switch-unselected" : "btn-switch-selected")} onClick={() => setShowFederated(true)}>Federated</Button>
                    <Button className={"btn-switch-right " + (showFederated ? "btn-switch-unselected" : "btn-switch-selected")} onClick={() => setShowFederated(false)}>Non-Federated</Button>
                </ButtonGroup>
                <div className="branded-emails-top-right-buttons">
                    <Button className="btn-secondary" onClick={() => sendEmailTest()}>Generate Test Email</Button>
                </div>
            </div>;

    const ShowPreviewComp = (props: IPreviewProps) => {
        return <>
            <div>
                <Checkbox
                    value={showPreview}
                    label="Preview Mode"
                    className="form-input checkbox-field-space"
                    onChange={(event: CheckboxChangeEvent) => setShowPreview(event.value as any as boolean)}
                />
            </div>

            {showPreview && <iframe style={{width: "650px", height: "710px"}} srcDoc={props.srcDoc}></iframe>}
        </>
    }

    return <div className="branded-email-admin">
              <Checkbox
                defaultChecked={customizeEmails}
                name="customizeEmails"
                label="Customize Emails"
                className="form-input checkbox-field-space"
                onChange={(event: CheckboxChangeEvent) => {
                    setCustomizeEmails(event.value as any as boolean);
                    if (!event.value) {
                        props.onFormInputChangeEvent(null, "emailInvite", true);
                        props.onFormInputChangeEvent(null, "subjectForEmailInvite", true);
                        props.onFormInputChangeEvent(null, "emailInviteFederated", true);
                        props.onFormInputChangeEvent(null, "subjectForEmailInviteFederated", true);
                        props.onFormInputChangeEvent(null, "emailForgotPassword", true);
                        props.onFormInputChangeEvent(null, "subjectForEmailForgotPassword", true);
                        props.onFormInputChangeEvent(null, "emailForgotPasswordFederated", true);
                        props.onFormInputChangeEvent(null, "subjectForEmailForgotPasswordFederated", true);
                        props.onFormInputChangeEvent(null, "emailPasswordChange", true);
                        props.onFormInputChangeEvent(null, "subjectForEmailPasswordChange", true);
                    }
                    else {
                        props.onFormInputChangeEvent(props.siteDetails.emailInvite ? props.siteDetails.emailInvite : templates.emailInviteTemplate, "emailInvite", true);
                        props.onFormInputChangeEvent(props.siteDetails.emailInviteFederated ? props.siteDetails.emailInviteFederated : templates.emailInviteFederatedTemplate, "emailInviteFederated", true);
                        props.onFormInputChangeEvent(props.siteDetails.emailForgotPassword ? props.siteDetails.emailForgotPassword : templates.emailForgotPasswordTemplate, "emailForgotPassword", true);
                        props.onFormInputChangeEvent(props.siteDetails.emailForgotPasswordFederated ? props.siteDetails.emailForgotPasswordFederated : templates.emailForgotPasswordFederatedTemplate, "emailForgotPasswordFederated", true);
                        props.onFormInputChangeEvent(props.siteDetails.emailPasswordChange ? props.siteDetails.emailPasswordChange : templates.emailPasswordChangeTemplate, "emailPasswordChange", true);
                    }
                }}
              />

        {customizeEmails && <TabStrip selected={selectedTabIndex} onSelect={handleSelect} className="angle-tabs" keepTabsMounted={true}>
            <TabStripTab title={"Invite"}>
                {ButtonHeader}
                {!showFederated && <>
                <TextInput 
                    type="text" 
                    defaultValue={props.siteDetails.subjectForEmailInvite}
                    name="subjectForEmailInvite" 
                    label="Subject" 
                    onChange={props.onFormInputChangeEvent}
                />
                <TextInput
                    type="textarea"
                    defaultValue={props.siteDetails.emailInvite}
                    name="emailInvite"
                    label="Body"
                    onChange={props.onFormInputChangeEvent}
                />
                <ShowPreviewComp srcDoc={props.siteDetails.emailInvite}></ShowPreviewComp>
                </>}
                {showFederated && <>
                <TextInput 
                    type="text" 
                    defaultValue={props.siteDetails.subjectForEmailInviteFederated} 
                    name="subjectForEmailInviteFederated" 
                    label="Subject" 
                    onChange={props.onFormInputChangeEvent} 
                />
                <TextInput
                    type="textarea"
                    defaultValue={props.siteDetails.emailInviteFederated}
                    name="emailInviteFederated"
                    label="Body"
                    onChange={props.onFormInputChangeEvent}
                />

                <ShowPreviewComp srcDoc={props.siteDetails.emailInviteFederated}></ShowPreviewComp>
                </>}
            </TabStripTab>
            <TabStripTab title={"Forgot Password"}>
                {ButtonHeader}
                {!showFederated && <>
                <TextInput 
                    type="text" 
                    defaultValue={props.siteDetails.subjectForEmailForgotPassword} 
                    name="subjectForEmailForgotPassword" 
                    label="Subject" 
                    onChange={props.onFormInputChangeEvent} 
                />
                <TextInput
                    type="textarea"
                    defaultValue={props.siteDetails.emailForgotPassword}
                    name="emailForgotPassword"
                    label="Body"
                    onChange={props.onFormInputChangeEvent}
                />
                <ShowPreviewComp srcDoc={props.siteDetails.emailForgotPassword}></ShowPreviewComp>
                </>}
                {showFederated && <>
                <TextInput
                    type="text"
                    defaultValue={props.siteDetails.subjectForEmailForgotPasswordFederated}
                    name="subjectForEmailForgotPasswordFederated"
                    label="Subject"
                    onChange={props.onFormInputChangeEvent}
                />
                <TextInput
                    type="textarea"
                    defaultValue={props.siteDetails.emailForgotPasswordFederated}
                    name="emailForgotPasswordFederated"
                    label="Body"
                    onChange={props.onFormInputChangeEvent}
                />
                <ShowPreviewComp srcDoc={props.siteDetails.emailForgotPasswordFederated}></ShowPreviewComp>
                </>}
            </TabStripTab>
            <TabStripTab title={"Password Change"}>
                {ButtonHeader}
                <TextInput
                    type="text"
                    defaultValue={props.siteDetails.subjectForEmailPasswordChange}
                    name="subjectForEmailPasswordChange"
                    label="Subject"
                    onChange={props.onFormInputChangeEvent}
                />
                <TextInput
                    type="textarea"
                    defaultValue={props.siteDetails.emailPasswordChange}
                    name="emailPasswordChange"
                    label="Body"
                    onChange={props.onFormInputChangeEvent}
                />
                <ShowPreviewComp srcDoc={props.siteDetails.emailPasswordChange}></ShowPreviewComp>
            </TabStripTab>
        </TabStrip>}
    </div>;
};

export default BrandedEmails;