import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import React, { useState, useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { AdvancedGrid, IGridParams } from "../../common/grid/AdvancedGrid";
import { GridCellProps, GridColumnProps, GridFilterCellProps } from "@progress/kendo-react-grid";
import { NavLink } from "react-router-dom";
import * as SessionStore from "../../../store/Session";
import { MessageStatusEnum, CommonMessageModel, MessageTypeIdsEnum, IMessageTypeModel } from "../../../models/MessageModel";
import { IApplicationState } from "../../../store";
import { connect } from "react-redux";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import MessageService from "../../../services/MessageService";
import { DropDownFilter } from "../../common/grid/filters/DropDownFilter";
import CommonHelper from "../../common/utilities/CommonHelper";
import { formatDate } from "@telerik/kendo-intl";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import "./Message.scss";

type IProps = {
  history: any;
};

type Props = IProps & SessionStore.ISessionState;
let myGrid: AdvancedGrid<CommonMessageModel, IGridParams>;

const msgStatusList = [MessageStatusEnum.Active, MessageStatusEnum.Ready, MessageStatusEnum.Ended, MessageStatusEnum.NotActive];
const mainDropdownMessageList = [{ id: MessageTypeIdsEnum.EULA_AGREEMENT, name: "Eula Agreement" }, { id: MessageTypeIdsEnum.DEFAULT, name: "Messages" }] as IMessageTypeModel[];
const notificationMessageList = [{ id: MessageTypeIdsEnum.UPCOMING_MAINTENANCE, name: "Upcoming Maintenance" }, { id: MessageTypeIdsEnum.URGENT_MAINTENANCE, name: "Urgent Maintenance" }] as IMessageTypeModel[];
const messageActiveTypes = [{ name: "Enabled", id: 1 }, { name: "Disabled", id: 0 }] as IMessageTypeModel[];

const dataState: IGridParams = {
  skip: 0,
  take: 100,
  filter: {
    logic: "and",
    filters: [{ field: "status", operator: "eq", value: msgStatusList[0] }, { field: "active", operator: "eq", value: messageActiveTypes[0] }
    ] as Array<FilterDescriptor>
  },

  sort: [{ field: "name", dir: "asc" }],
};

const customFilter = {
  filters: [
    {
      logic: "and",
      filters: [{ field: "status", operator: "eq", value: msgStatusList[0] }] as Array<FilterDescriptor>
    },
    {
      logic: "and",
      filters: [{ field: "active", operator: "eq", value: messageActiveTypes[0] }] as Array<FilterDescriptor>
    },
  ] as CompositeFilterDescriptor[]
}

const MessageList = (props: Props) => {
  const stateMessageType = props.history.location.state && props.history.location.state.messageType;
  const [messages, setMessages] = useState<CommonMessageModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [count, setCount] = useState(0);
  const [initializing, setInitializing] = useState(true);
  const [messageType, setMessageType] = useState(stateMessageType ? stateMessageType : MessageTypeIdsEnum.DEFAULT); //-- default message type

  useEffect(() => {
    if (!initializing) {
      fetchMessages(dataState);
    }
  }, [messageType]);


  const fetchMessages = async (dataState: IGridParams) => {
    setIsLoading(true);

    if (!dataState.filters)
      dataState.filters = customFilter.filters;

    let filters = getModifiedCorrectDateFilter(dataState.filters, "startDate");
    filters = getModifiedCorrectDateFilter(filters, "endDate");
    filters = getStatusFilter(dataState.filters);



    let messageFilters = filters && dataState.filter
      ? filters.map(compositeFilter => {

        const activationFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "active"
        ) as FilterDescriptor;

        const messageTypeFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "messageTypeId"
        ) as FilterDescriptor;


        if (activationFilter) {
          const activationFilterValue = activationFilter.value
            ? activationFilter.value.id == 0 || activationFilter.value.id == 1
              ? activationFilter.value.id == 0
                ? false
                : true
              : null
            : false;
          return {
            ...compositeFilter,
            filters: [
              {
                ...activationFilter,
                field: "active",
                operator: "eq",
                value: activationFilterValue
              }
            ]
          };
        }

        if (messageTypeFilter) {
          const messageTypeFilterValue = messageTypeFilter.value && messageTypeFilter.value.id
          return {
            ...compositeFilter,
            filters: [
              {
                ...messageTypeFilter,
                field: "messageTypeId",
                operator: "eq",
                value: messageTypeFilterValue
              }
            ]
          };
        }

        return compositeFilter;
      }) : [];




    const result = messageType === MessageTypeIdsEnum.EULA_AGREEMENT ? await MessageService.getMessages({ ...dataState, filters: messageFilters }) :
      await MessageService.getNotificationMessages(messageType,
        { ...dataState, filters: messageFilters });

    if (result.ok) {
      setMessages(result.data.results);
      setCount(result.data.count);
      setHasError(false);
      setInitializing(false);
    } else {
      setHasError(true);
      console.log("Something went wrong while fetching employers.");
    }

    setIsLoading(false);
  }

  const getModifiedCorrectDateFilter = (
    gridFilters: Array<CompositeFilterDescriptor>,
    dateColumnName: string = "createdDate"
  ) => {
    // This function is used for date column filter in all advance grid pages.
    let isCreatedDateFilterExists = false;
    let createdDateValue: Date;
    const filters = gridFilters
      ? gridFilters.map(compositeFilter => {
        const createdDateFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === dateColumnName
        ) as FilterDescriptor;

        if (createdDateFilter) {
          const dateFilterValue = createdDateFilter.value;

          if (!isNaN(new Date(dateFilterValue).getFullYear()) && new Date(dateFilterValue).getFullYear() > 1970) {
            createdDateFilter.operator = 'eq';
          }

          createdDateValue = typeof dateFilterValue === "string" ? new Date("01/01/1901") : ((createdDateFilter.operator === 'isnull' || createdDateFilter.operator === 'isnotnull') ? null : new Date(dateFilterValue));

          if (createdDateFilter.operator === 'isnull' || createdDateFilter.operator === 'isnotnull') {
            return {
              ...compositeFilter,
              filters: [
                {
                  ...createdDateFilter,
                  field: dateColumnName,
                  operator: typeof dateFilterValue === "string" && createdDateFilter.operator === 'isnotnull' ? "eq" : createdDateFilter.operator,
                  value: createdDateValue
                }
              ]
            };
          }

          isCreatedDateFilterExists = true;

          return {
            ...compositeFilter,
            filters: [
              {
                ...createdDateFilter,
                field: dateColumnName,
                operator: "gte",
                value: new Date(createdDateValue.toUTCString())
              }
            ]
          };
        }
        return compositeFilter;
      })
      : [];

    if (isCreatedDateFilterExists) {
      filters.push({
        logic: "and",
        filters: [
          {
            field: dateColumnName,
            operator: "lt",
            value: new Date(new Date(createdDateValue.setUTCDate(createdDateValue.getUTCDate() + 1)).toUTCString())
          }
        ]
      });
    }

    return filters;
  };

  const getStatusFilter = (gridFilters: Array<CompositeFilterDescriptor>,) => {
    let stausFilterExists: FilterDescriptor = null;
    let filters = gridFilters
      ? gridFilters.map(compositeFilter => {
        const statusFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "status"
        ) as FilterDescriptor;

        if (statusFilter)
          stausFilterExists = statusFilter;

        return compositeFilter;

      }) : [];

    if (stausFilterExists) {
      filters.push({
        logic: "and",
        filters: [
          {
            field: stausFilterExists.field,
            operator: stausFilterExists.operator,
            value: stausFilterExists.value
          }
        ]
      });
    }

    return filters;
  }

  const messageNameCellLink = (props: GridCellProps) => {
    const name = props.dataItem.name;
    const link = messageType === MessageTypeIdsEnum.EULA_AGREEMENT ? `/administration/messageDetails/${props.dataItem.agreementId}` : `/administration/notificationDetails/${props.dataItem.notificationId}`;

    return (
      <td>
        <div className="emp-name">
          <NavLink to={{ pathname: link, state: { messageType: messageType } }} >
            <em title={name}>{name}</em>
          </NavLink>
        </div>
      </td>
    );
  }

  const messageNameCell = (props: GridCellProps) => {
    const name = props.dataItem.name;
    return (
      <td>
        <em className="emp-name" title={name}>{name}</em>
      </td>
    );
  }

  const activationCell = (props: GridCellProps) => {
    return <td>{props.dataItem.active ? "Enabled" : "Disabled"}</td>;
  };

  const messageTypeCell = (props: GridCellProps) => {
    return <td className="message-type-cell">{props.dataItem.messageTypeId === MessageTypeIdsEnum.UPCOMING_MAINTENANCE ? notificationMessageList[0].name : notificationMessageList[1].name}</td>;
  };


  const startDateCell = (props: GridCellProps) => {
    let date = "-";
    let startDate = props.dataItem.startDate;

    if (startDate) {
      let localDateTime = CommonHelper.convertUTCDateToLocalDate(startDate);
      date = formatDate(localDateTime, "g");
    }

    return <td>{date}</td>;
  };

  const endDateCell = (props: GridCellProps) => {
    let date = "N/A";
    let startDate = props.dataItem.endDate;

    if (startDate) {
      let localDateTime = CommonHelper.convertUTCDateToLocalDate(startDate);
      date = formatDate(localDateTime, "g");
    }

    return <td>{date}</td>;
  };

  const statusCell = (props: GridCellProps) => {
    return <td>{props.dataItem.status}</td>;
  };

  const employersCell = (props: GridCellProps) => {
    return <td>
      <em className="msg-employer-list" title={props.dataItem.employers}>{props.dataItem.employers}</em>
    </td>;
  };

  const appInstanceCell = (props: GridCellProps) => {
    return <td>
      <em className="msg-employer-list" title={props.dataItem.resourceDelimeter}>{props.dataItem.resourceDelimeter}</em>
    </td>;
  };

  const domainsCell = (props: GridCellProps) => {
    return <td>
      <em className="msg-domain-list" title={props.dataItem.domains}>{props.dataItem.domains}</em>
    </td>;
  };


  const columns = () => {
    let cols = new Array<GridColumnProps>(
      { field: "name", title: "Name", cell: props.sessionData.permissions.has("EpiqAdminGetAgreement") ? messageNameCellLink : messageNameCell, filterable: true, filter: "text" },

      { field: "startDate", title: "Start Date", filterable: true, filter: "date", format: "{0:g}", cell: startDateCell },
      { field: "endDate", title: "End Date", filterable: true, filter: "date", format: "{0:g}", cell: endDateCell },
      {
        field: "active", title: "Activation", filterable: true, filter: "text", className: "msg-list-activation", filterCell: (props: GridFilterCellProps) => (
          <DropDownFilter
            {...props}
            data={messageActiveTypes}
            textField="name"
            defaultSelectedOption={null}
          />
        ),
        cell: activationCell
      },
      {
        field: "status", title: "Status", filterable: true, filter: "text", className: "msg-list-status", filterCell: (props: GridFilterCellProps) => (
          <DropDownFilter
            {...props}
            data={msgStatusList}
            defaultSelectedOption={null}
          />
        ), cell: statusCell
      }
    );

    if (messageType === MessageTypeIdsEnum.EULA_AGREEMENT) {
      cols.splice(1, 0, { field: "employers", title: "Employers", filterable: true, cell: employersCell })
      cols.splice(2, 0, { field: "domains", title: "Domains", filterable: true, cell: domainsCell })
    }
    else if (messageType === MessageTypeIdsEnum.DEFAULT) {
      cols.splice(1, 0, {
        field: "messageTypeId", title: "Message Type", filterable: true, filter: "text", className: "msg-list-message-type", filterCell: (props: GridFilterCellProps) => (
          <DropDownFilter
            {...props}
            data={notificationMessageList}
            textField="name"
            defaultSelectedOption={null}
          />
        ),
        cell: messageTypeCell
      })
      cols.splice(2, 0, { field: "resourceDelimeter", title: "App instance", filterable: true, sortable: false, cell: appInstanceCell })
    }

    return cols;
  }

  const filterElements = (element: any) => {
    if (element.tagName === "EM") {
      return true;
    }
    return false;
  };

  const handleMessageTypeChange = (type: number) => {

    setMessageType(type);
    setMessages([]);
    setCount(0);
    myGrid.resetGridState(null, true, true);
  }

  return (<div className="service-grid">
    <div className="request-grid-list">
      <h3>Messages</h3>
      <p>Click a message to view more details.</p>
      <div className="request-tabs">
        <div className="request-tab-add-button">
          <div className="message-type">
            <DropDownList
              style={{ width: "160px" }}
              data={mainDropdownMessageList}
              textField="name"
              dataItemKey="id"
              defaultValue={stateMessageType ? mainDropdownMessageList.find(x => x.id == stateMessageType) : mainDropdownMessageList[1]}
              onChange={(e) => { handleMessageTypeChange(e.value.id) }}
            />
          </div>
          <div>
            {props.sessionData.permissions.has("EpiqAdminCreateAgreement") && <Button
              className="add-request-btn"
              icon={"plus"}
              primary={true}
              onClick={() => {
                props.history.push({
                  pathname: "/administration/messageDetails/0", state: { messageType: messageType }
                });
              }}
            >
              MESSAGE
            </Button>}
          </div>
        </div>
        <TabStrip selected={0} className="angle-tabs" keepTabsMounted={true}>
          <TabStripTab title={<label>Messages <span className="msg-list-count">{count >= 100 ? "100+" : count.toString()}</span></label>}>
            <div className="message-list-grid-wrapper">
              <Tooltip openDelay={2} position="right" filter={filterElements}>
                <AdvancedGrid
                  ref={(standardGridInstance: AdvancedGrid<CommonMessageModel, IGridParams>) => {
                    myGrid = standardGridInstance;
                  }}
                  showErrorState={hasError}
                  showLoadingIndicator={isLoading}
                  sortable={{ mode: "multiple" }}
                  data={messages}
                  dataFetch={fetchMessages}
                  dataState={dataState}
                  columns={columns()}
                  paging={false}
                  noRecordsRender={<p>No messages found.</p>}
                  noLoadOnMount={false}
                  filteredDataOnly={false}
                  multiFieldFilterDelimiter="|"
                  filterOperators={{
                    text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
                    date: [{ text: "grid.filterIsNotNullOperator", operator: "isnotnull" }, { text: "grid.filterIsNullOperator", operator: "isnull" }]
                  }}
                  moreRecordsAvailableMessage="Too many messages to display. Adjust filters to refine results"
                />
              </Tooltip>
            </div>
          </TabStripTab>
        </TabStrip>
      </div>
    </div>
  </div>)
}

export default connect(
  (state: IApplicationState) => ({
    ...state.sessionState
  }),
  null
)(MessageList as any);