import React, { useEffect, useState, useRef } from "react";
import SupportService, { IAttachedFiles, IAttachments } from "../../../../services/SupportService";
import { StandardGrid } from "../../../common/grid/StandardGrid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { GridCellProps, GridColumnProps } from "@progress/kendo-react-grid";
import "./Attachments.scss";
import CommonHelper from "../../../common/utilities/CommonHelper";
import { NavLink } from "react-router-dom";
import NotificationService from "../../../../services/NotificationService";
import CollapsibleSection from "../../../administration/common/CollapsibleSection";
import { Button } from "@progress/kendo-react-buttons";

interface IProps {
  incidentId: string;
  watchListDataSource: boolean;
  permissions: Set<string>;
  window?: any;
  onAddAttachment: () => void;
  addingAttachment?: boolean;
}

type Props = IProps;

const Attachments = (props: Props) => {
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attachments, setattachments] = useState<Array<any>>(new Array<any>());  

  const dataState: IAttachments = {
    incidentId: props.incidentId
  };

  useEffect(() => {

    if (!props.addingAttachment) {
      getAttachmentsList(dataState);
    }
  }, [props.addingAttachment]);

  const getAttachmentsList = async (dataState: IAttachments) => {
    setLoading(true);
    const result = await SupportService.getAttachments({ ...dataState }, props.watchListDataSource);

    if (result.ok) {
      setLoading(false);
      setattachments(result.data.results);
    } else {
      setHasError(true);
      setattachments(null);
    }
  };

  const sizeCell = (props: GridCellProps) => {
    const dataItem: IAttachedFiles = props.dataItem;
    const formattedSize = CommonHelper.formatBytes(dataItem.size, 1);
    return <td>{formattedSize}</td>;
  };

  const createdDateCell = (props: GridCellProps) => {
    const dataItem: IAttachedFiles = props.dataItem;

    return (
      <td>
        <div className="created-date">
          <div>{dataItem.createdDate.localDateString}</div>
          <div className="created-time">{dataItem.createdDate.localTimeString}</div>
        </div>
      </td>
    );
  };

  const fileCell = (gridProps: GridCellProps) => {  
    const dataItem: IAttachedFiles = gridProps.dataItem;

    return (
      <td>
        { props.permissions && props.permissions.has("EpiqAdminViewSupportAttachments") ?
          <div className="file-name">
            <NavLink to="#">
              <em title={dataItem.file} onClick={() => { downloadSupportDocument(dataItem) }}>{dataItem.file}</em>
            </NavLink>
          </div> : <> {dataItem.file} </>
       }
      </td> 
    );
  };

  const columns = new Array<GridColumnProps>(
    { field: "file", title: "Title", cell: fileCell, sortable: false, headerClassName: "no-sort" },
    {
      field: "createdDate",
      title: "Date Submitted",
      cell: createdDateCell,
      sortable: false,
      headerClassName: "no-sort"
    }
  );

  const downloadSupportDocument = async (fileDetails: IAttachedFiles) => {
    const response = await SupportService.getAttachmentBySysId(fileDetails.sysId)

    if (response.ok) {
      const arrayBuffer: Uint8Array = CommonHelper.base64ToArrayBuffer(response.data);
      CommonHelper.convertByteArrayToFile(fileDetails.contentType, fileDetails.file, arrayBuffer);
    } else {
      NotificationService.showErrorToast("Failed to download an attachment from the service now.");
    }
  }

const attachmentsHeaderOptions = <Button primary={false} onClick={(e) => {
  e.preventDefault();
  e.stopPropagation();
  props.onAddAttachment();
}}><i className="fa-solid fa-paperclip"></i> Upload Files</Button>

 return (
  <CollapsibleSection title="Attached Files" count={attachments ? attachments.length : null} headerContent={attachmentsHeaderOptions}>
    <div className="ticket-attachment-details">
      <Tooltip openDelay={2} position="right">
        <StandardGrid
          showErrorState={hasError}
          showLoadingIndicator={loading}
          data={attachments}
          dataFetch={getAttachmentsList}
          dataState={dataState}
          columns={columns}
          paging={false}
          noRecordsRender={<p>No attachments</p>}
          noLoadOnMount={true}
          filteredDataOnly={false}
          hidePaper={true}
        />
      </Tooltip>
    </div>
  </CollapsibleSection>);
};

export default Attachments;
