import React, { useState, useEffect, useRef, ReactElement } from "react";
import { GridColumnProps, GridCellProps, GridFilterCellProps, GridFilterChangeEvent, GridRowProps } from "@progress/kendo-react-grid";
import { IGridParams, IGridStateMerged, AdvancedGrid } from "../../common/grid/AdvancedGrid";
import CommandCell from "../../common/grid/columns/CommandCell";
import NotificationService from "../../../services/NotificationService";
import AdminContextService from "../../../services/AdminContextService";
import CommonHelper from "../../common/utilities/CommonHelper";
import { IRelInstanceSelectable } from "../../../models/RelativityModel";
import AdminService from "../../../services/AdminService";

export interface IRelativityInstanceRequest extends IGridParams {

}

type Props = {
  additionalRelInstances?: IRelInstanceSelectable[] | undefined;
  enableSelectInstance?: boolean;
  selectionChange?: (selected: IRelInstanceSelectable) => void;
};

const defaultAppState = { items: [], merged: [], count: 0, selections: {} } as IGridStateMerged<IRelInstanceSelectable>;

const RelativityInstanceList = (props: Props) => {
  const [relInstances, setRelInstances] = useState(defaultAppState);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const filterTimeOut = useRef(null);
  const gridRef = useRef<AdvancedGrid<IRelInstanceSelectable, IRelativityInstanceRequest>>(null);

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 100,
    sort: [{ field: "name", dir: "asc" }]
  } as IRelativityInstanceRequest);


  useEffect(() => {
    const updated = { ...relInstances }
    updated.selections = {};
    for (let i = 0; i < updated.items.length; i++) {
      updated.items[i].selected = false;
    }
    setRelInstances(updated);
  }, []);

  const updateMergedState = (instances: IRelInstanceSelectable[], preSelected: Record<number | string, IRelInstanceSelectable>) => {

    if (!instances) {
      instances = [];
    }

    for (let i = 0; i < instances.length; i++) {
      if (preSelected[instances[i].id]) {
        instances[i].selected = true;
      }
    }

    if (props.additionalRelInstances && props.additionalRelInstances.length) {
      const merged = CommonHelper.dedupeObjects<IRelInstanceSelectable>(props.additionalRelInstances, relInstances.items, 'id');
      setRelInstances({ items: instances, merged, count: merged.length, selections: preSelected });
    } else {
      setRelInstances({ items: instances, merged: instances, count: instances.length, selections: preSelected });
    }
  }

  const loadApps = async (dataState: IRelativityInstanceRequest) => {
    setIsLoading(true);
    try {
      const request = { ...dataState };
      request.filters = [];

      if (request.filter) {
        let filters = request.filter.filters;
        request.filters.push({ logic: "and", filters: filters });
      }
      request.filters.push({ logic: "and", filters: [{ field: "IsActive", operator: "eq", value: true }]});

      const result = await AdminService.getRelInstances(request);

      if (result.ok) {
        updateMergedState(result.data.results, dataState.filter ? relInstances.selections : {});
        setHasError(false);
      }
      else {
        setRelInstances(defaultAppState);
        NotificationService.showErrorToast("Something went wrong while getting environments.");
        setHasError(true);
      }

    } catch (e) {
      setRelInstances(defaultAppState);
      NotificationService.showErrorToast("Something went wrong while getting environments.");
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const selectRow = (dataItem: IRelInstanceSelectable) => {
    const changes = { ...relInstances };
    for (let i = 0; i < changes.items.length; i++) {
      if (dataItem.id === changes.items[i].id) {
        changes.items[i].selected = true;

        if (!changes.selections[changes.items[i].id]) {
          changes.selections[changes.items[i].id] = changes.items[i];
        }
      } else {
        if (changes.items[i].selected) {
          changes.items[i].selected = false;
          delete changes.selections[changes.items[i].id];
        }
      }

    }

    setRelInstances(changes);

    const selection = Object.keys(changes.selections).map(e => changes.selections[e]);
    props.selectionChange && props.selectionChange(selection[0]);
  };


  const gridFilterChange = (event: GridFilterChangeEvent) => {
    const newState = { ...dataState, filter: event.filter };
    setDataState(newState);
    if (filterTimeOut.current) {
      clearTimeout(filterTimeOut.current);
    }
    filterTimeOut.current = setTimeout(() => {
      gridRef.current.resetGridState(newState, false, true);
    }, 500);
  };

  const columns = new Array<GridColumnProps>(
    { field: "name", title: "Relativity Instance Name", sortable: true, filterable: true, filter: "text", width: "200px" },
    { field: "relativityUrl", title: "Relativity URL", sortable: true, filterable: true, filter: "text", width: "200px" }
  );

  if (props.enableSelectInstance) {
    columns.unshift({
      title: "",
      cell: CommandCell({
        onSelection: selectRow,
        selectedField: "selected",
        selectionMode: "single"
      }),
      sortable: false,
      headerClassName: "no-sort"
    });
  }



  return (
    <div className="applications-grid-wrapper rel-instance-grid-wrapper">
      <AdvancedGrid
        ref={(advancedGridInstance: AdvancedGrid<IRelInstanceSelectable, IRelativityInstanceRequest>) => {
          gridRef.current = advancedGridInstance;
        }}
        showErrorState={hasError}
        showLoadingIndicator={isLoading}
        data={relInstances.merged}
        dataFetch={loadApps}
        dataState={dataState}
        columns={columns}
        paging={false}
        noRecordsRender={<p>No relativity instances found.</p>}
        noLoadOnMount={false}
        filteredDataOnly={false}
        selectedField="selected"
        filterOperators={{
          text: [{ text: "grid.filterContainsOperator", operator: "contains" }]
        }}
        filter={dataState.filter}
        onFilterChange={gridFilterChange}
      />
    </div>
  );
}


export default RelativityInstanceList;