enum ShowEpiqOnlyControls {
    ENABLE="ENABLE",
    SHOW="SHOW",
    HIDE="HIDE",
}

enum ResourceTypes {
  USER_GROUP = 0,
  ROLE = 1,
  RESOURCE_GROUP = 2
}

enum apiErrorTypes {
  /* When adding new types, please strive to only add re-usable generic problem patterns. 
   * If the whole API endpoint blows up, we can continue with 500 errors instead. 
   * An http 200 with an errorType is intended for when nuanced responses are needed */

  AlreadyExists = 1, // When creating and some uniqe field (e.g. name) is already taken
  PartialDataSaved = 2, // When some database data saved, but additional database changes failed
  DataSavedExternalFailed = 3, // When database data is saved, but followup calls failed (Most common example are failures when updating data outside of EA dbs)
}

enum JobTypeEnum {
  Generic = 1,
  Upload = 2,
  CreateTicket = 3,
  ImportUsers = 4,
  UpdateTicket = 5,
  ImportResources = 6,
  CreateWorkRequest = 7,
  ExportActivity = 8,
  ClientSetUp = 9,
  Invitation = 10,
  Approval = 11,
  CdnUpload = 12,
  DeleteAppUsers = 13,
  CdnWhiteLabelUpload = 14
}

export { ShowEpiqOnlyControls, ResourceTypes, apiErrorTypes, JobTypeEnum };