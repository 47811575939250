import React, { useEffect } from 'react';
import { FieldRenderProps } from '@progress/kendo-react-form';
import { ILookupCompletion } from "../../../models/Wizard";
import { updateValForField, updateStatusForField } from './WizardElements';
import { Input } from '@progress/kendo-react-inputs';

const HiddenLookupField = function(fieldRenderProps: FieldRenderProps) {

  const [watchValue, setWatchValue] = React.useState(undefined);

  useEffect(() => {
    const newLookupVal = fieldRenderProps.watchField && fieldRenderProps.fieldValues[fieldRenderProps.watchField] ? 
      fieldRenderProps.fieldValues[fieldRenderProps.watchField].value : null;
    if (newLookupVal !== watchValue) {
      getResources();
      setWatchValue(newLookupVal);
    }
  }, [fieldRenderProps.watchField && fieldRenderProps.fieldValues[fieldRenderProps.watchField]]);

  async function getResources() {
    try {
      updateStatusForField(true, fieldRenderProps, false);
      const results = await fieldRenderProps.fieldData.GetData(fieldRenderProps.fieldData.GetDataParams(null, fieldRenderProps.fieldValues));

      if (results.ok) {

        const lookupInfo = fieldRenderProps.fieldData.lookupCompletion(results.data, fieldRenderProps.fieldValues) as ILookupCompletion;

        if (!lookupInfo.success) {
          updateStatusForField(false, fieldRenderProps, true, lookupInfo.errorMessage);
        } else {
          updateStatusForField(false, fieldRenderProps, false);
        }
        updateValForField(lookupInfo.value ? lookupInfo.value : null, fieldRenderProps, null, lookupInfo.lookupValues);
      } else {
        updateStatusForField(false, fieldRenderProps, true);
      }
    } catch(err) {
      updateStatusForField(false, fieldRenderProps, true);
    }
  }

  return (
    <div style={{display:"none"}}>
      <Input
        key={`inputfieldhidden${fieldRenderProps.seedKey}`}
        hidden={true}
    /></div>
  );
};

export default HiddenLookupField;
