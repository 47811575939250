import React from "react";
import { IFileInfo, standardExtensionRestrictions, StandardFileUpload } from "../../../common/fileupload/StandardFileUpload";
import { UploadOnAddEvent, UploadOnProgressEvent, UploadOnRemoveEvent } from "@progress/kendo-react-upload";

interface IAddAttachmentsProps {
  jobId: string;
  jobType: number;
  ticketNumber?: string;
  addRemoveFiles?: (attachmentsCount: number) => void;
  onUploadComplete?: () => void;
  onBeforeUpload?: () => void;
  onDataStateChanged?: (files: Array<IFileInfo>) => void;
}

const uploadFileRestrictions = {
  allowedExtensions: standardExtensionRestrictions,
  maxFileSize: 10485760 //10MB as bytes
};

type Props = IAddAttachmentsProps;

type State = {};

class AddAttachments extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  handleAddRemove(event: UploadOnAddEvent | UploadOnRemoveEvent) {
    if (this.props.addRemoveFiles) {
      this.props.addRemoveFiles(event.newState.length);
    }
  }

  handleOnBeforeUpload() {
    if (this.props.onBeforeUpload) {
      this.props.onBeforeUpload();
    }
  }

  handleOnDataStateChanged(files: Array<IFileInfo>) {
    if (this.props.onDataStateChanged) {
      this.props.onDataStateChanged(files);
    }
  }

  handleOnUploadComplete() {
    if (this.props.onUploadComplete) {
      this.props.onUploadComplete();
    }
  }

  render() {
    const fileAttachmentRequestData = {
      jobId: this.props.jobId,
      jobType: this.props.jobType,
      ticketNumber: this.props.ticketNumber
    };

    return (
      <StandardFileUpload
        requestData={fileAttachmentRequestData}
        multiple={true}
        restrictions={uploadFileRestrictions}
        onAdd={this.handleAddRemove.bind(this)}
        onRemove={this.handleAddRemove.bind(this)}
        onUploadComplete={this.handleOnUploadComplete.bind(this)}
        onBeforeUpload={this.handleOnBeforeUpload.bind(this)}
        onDataStateChanged={this.handleOnDataStateChanged.bind(this)}
      />
    );
  }
}

export default AddAttachments;
