import OktaAuth from "@okta/okta-auth-js";
import { ServiceBase, ResponseMessages, ServiceResponseJson, ServiceResponse } from "./ServiceBase";
import authenticationStorageApi from "../apis/storage/AuthenticationStorageApi";
import { AppInsightsHelper } from "../AppInsights";

const authClient = new OktaAuth({
  url: window.ApplicationVariables.issuer
});

const ExtendSessionMessages: ResponseMessages = {
  FailedMessage: "Failed to extend the user's session.",
  SuccessMessage: "Extend session call succeeded."
};

const GetAccessTokenMessages: ResponseMessages = {
  FailedMessage: "Failed to get access token.",
  SuccessMessage: "Get access token call succeeded."
};

const CreateSessionMessages: ResponseMessages = {
  FailedMessage: "Failed to create session.",
  SuccessMessage: "Create session call succeeded."
}

const GetSessionMessages: ResponseMessages = {
  FailedMessage: "Failed to get session data.",
  SuccessMessage: "Get session data call succeeded."
};

const SendPasswordChangeMessage: ResponseMessages = {
  FailedMessage: "failed to send password change confirmation email.",
  SuccessMessage: "send password change confirmation email is succeeded."
};

const DeleteSessionMessages: ResponseMessages = {
  FailedMessage: "Failed to delete user session.",
  SuccessMessage: "Successfully deleted user session."
};

const SendLockedEmailMessages: ResponseMessages = {
  FailedMessage: "Failed to send locked out email to user.",
  SuccessMessage: "Successfully sent locked out email to user."
};

const ClearCookiesMessages: ResponseMessages = {
  FailedMessage: "Failed to clear all local cookies.",
  SuccessMessage: "Successfully cleared all local cookies."
};

const VerifySessionMessages: ResponseMessages = {
  FailedMessage: "Failed to verify session.",
  SuccessMessage: "Verify session call succeeded."
}

type VerifySessionResponse = {
  exists: boolean;
  agreementsRequired: boolean;
}

export default class AuthenticationService extends ServiceBase {
  static async createSession(idpAccessToken: string) {

    const url = `${window.ApplicationVariables.apiUri}Session/create`;

    const result: ServiceResponseJson = await fetch(url, {
      body: `"${idpAccessToken}"`,
      ...this.fetchPostOptions()
    })
      .then(async (response) => await this.handleResponseJson(response, CreateSessionMessages))
      .catch(async (response) => await this.handleResponseJson(response, CreateSessionMessages));

    return result;
  }

  static async getAccessToken() {
    const url = `get-access-token`;
    const result: ServiceResponseJson = await fetch(url)
      .then(async (response) => await this.handleResponseJson(response, GetAccessTokenMessages))
      .catch(async (response) => await this.handleResponseJson(response, GetAccessTokenMessages));

    return result;
  }

  static async getSessionData() {
    const result: ServiceResponseJson = await fetch(`${window.ApplicationVariables.apiUri}session`, {
      ...this.fetchOptions()
    })
    .then(async response => await this.handleResponseJson(response, GetSessionMessages))
    .catch(async response => await this.handleResponseJson(response, GetSessionMessages));

    return result;
  }

  static async SendPasswordChangeEmail(username: string) {
    const url = window.ApplicationVariables.apiUri + "v1/password/confirmation";
    const result: ServiceResponse = await fetch(url, {
      body: this.toPostBody({ username }),
      ...this.fetchPostOptions()
    })
      .then(response => this.handleResponse(response, SendPasswordChangeMessage))
      .catch(response => this.handleResponse(response, SendPasswordChangeMessage));

    return result;
  }

  static async checkAccessTokenCookie() {
    let response = await this.getAccessToken();
    if(!response.ok) {
      console.log(`Failed to get access token or it was invalid.`);
      return null;
    }

    return response.data;
  }
  
  static async checkEpiqAccessSession(): Promise<VerifySessionResponse> {
    let retval = {
      exists: false,
      agreementsRequired: false
    };
    
    let token = authenticationStorageApi.getToken();
    if(!token) {
      return retval;
    }

    let response: ServiceResponseJson = await fetch(`${window.ApplicationVariables.apiUri}session/verify`, { ...this.fetchOptions() })
      .then(async (response) => await this.handleResponseJson(response, VerifySessionMessages))
      .catch(async (response) => await this.handleResponseJson(response, VerifySessionMessages));


    console.log('Verify Session Response', response);
    
    if(!response.ok) {
      console.log(`Your existing Epiq Access session is invalid.`);
      authenticationStorageApi.clearAll();

      return retval;
    }

    const data = response.data as VerifySessionResponse;

    return data;
  }

  static async checkOktaSession() {
    let activeSessionExists = false;

    try {
      let session = await authClient.session.get();

      activeSessionExists = session && session.status === "ACTIVE";

      if (activeSessionExists) {
        authenticationStorageApi.storeSessionId(session.id);
        console.log("You're authenticated with okta!, with session id:", session.id);
      } else {
        if (session.status === "MFA_REQUIRED" || session.status === "MFA_ENROLL") {
          window.location.href = window.ApplicationVariables.appEmbedLink;
        }
      }
    } catch (exception) {
      console.error("could not check whether an okta session existed");
    }

    return activeSessionExists;
  }

  static async deleteUserSession() {
    // First delete EA session on the server
    try {
      const url = `${window.ApplicationVariables.apiUri}users/logout`;

      await fetch(url, { ...this.fetchDeleteOptions() })
        .then(async response => await this.handleResponse(response, DeleteSessionMessages))
        .catch(async response => await this.handleResponse(response, DeleteSessionMessages));
    } catch (exception) {
      console.error(`Exception occurred when trying to sign user out of Console.  Exception:${exception}`);
    }

    // Whether that succeeded or not, clear out all EA session data on the browser
    authenticationStorageApi.clearAll();
    AppInsightsHelper.clearAuthenticatedUserContext();

    await fetch("clear")
      .then(async response => await this.handleResponseJson(response, ClearCookiesMessages))
      .catch(async response => await this.handleResponseJson(response, ClearCookiesMessages));

    console.log("Cleared EA user session. Signing out of Okta.");

    // Okta signout attempt might return a 404 incorrectly because third-party cookies are blocked.
    // If that happens, ensure an Okta logout using a redirect. Use as a last-resort because
    // the screen will flicker (and it's going to be more work to try to change that behavior).
    try {
      var existingOktaSession = await this.checkOktaSession();
      if(!existingOktaSession) {
        this.oktaSignOutRedirect();
        return;
      }

      authClient.signOut();
    }
    catch(exception) {
      console.error(`Exception occurred when trying to sign user out of OKTA.  Exception:${exception}`);
      this.oktaSignOutRedirect();
    }
  }

  static oktaSignOutRedirect() {
    window.location.href = `${window.ApplicationVariables.issuer}/login/signout?fromURI=${window.ApplicationVariables.baseUrl}`;
  }

  static async impersonateUserSession(impersonatedId: string) {
    const url = `${window.ApplicationVariables.apiUri}session/create-impersonated-session?impersonatedId=${impersonatedId}`;

    const result: ServiceResponseJson = await fetch(url, { ...this.fetchPutOptions() })
      .then(async (response) => await this.handleResponseJson(response, ExtendSessionMessages))
      .catch(async (response) => await this.handleResponseJson(response, ExtendSessionMessages));

      return result;
  }

  static async endImpersonatedUserSession() {
    const url = `${window.ApplicationVariables.apiUri}session/end-impersonated-session`;

    const result: ServiceResponseJson = await fetch(url, { ...this.fetchPutOptions() })
      .then(async (response) => await this.handleResponseJson(response, ExtendSessionMessages))
      .catch(async (response) => await this.handleResponseJson(response, ExtendSessionMessages));

    return result;
  }

  static async extendUserSession() {
    const url = `${window.ApplicationVariables.apiUri}session/extend`;

    await fetch(url, { ...this.fetchPutOptions() })
      .then(async (response) => await this.handleResponse(response, ExtendSessionMessages))
      .catch(async (response) => await this.handleResponse(response, ExtendSessionMessages));
  }

  static async SendLockedOutEmail(username: string) {
    const url = window.ApplicationVariables.apiUri + "users/sendLockedOutEmail";
    const result: ServiceResponse = await fetch(url, {
      body: this.toPostBody({ username }),
      ...this.fetchPostOptions()
    })
      .then(response => this.handleResponse(response, SendLockedEmailMessages))
      .catch(response => this.handleResponse(response, SendLockedEmailMessages));

    return result;
  }

  static async SetDirty() {
    const result: ServiceResponse = await fetch(`${window.ApplicationVariables.apiUri}session/set-dirty`, { ...this.fetchOptions() })
      .then(async response => await this.handleResponse(response, DeleteSessionMessages))
      .catch(async response => await this.handleResponse(response, DeleteSessionMessages));

    return result;
  }
}
