import React, { Component, useState, useEffect, useRef } from "react";
import {
  MultiSelect,
  MultiSelectChangeEvent,
  MultiSelectFilterChangeEvent,
  ListItemProps
} from "@progress/kendo-react-dropdowns";
import { User } from "../../../../models/UserModels";
import AdminService from "../../../../services/AdminService";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import { TagData } from "@progress/kendo-react-dropdowns/dist/npm/MultiSelect/TagList";
import NotificationService from "../../../../services/NotificationService";
import Tooltip from "@material-ui/core/Tooltip";
import CommonHelper from "../../../common/utilities/CommonHelper";
import { Button, PropTypes } from "@material-ui/core";
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import bgMessages from '../../../../Localization/en.json';
loadMessages(bgMessages, 'en-US');

type Props = {
  users?: User[] | undefined;
  groupId?: number;
  typeName?: string;
  onAddUpdateUsers?: (selected: User[]) => void;
  selectedGroupUsers: User[];
  groupName?: string;
  selectedDomains?: string[];
};

const AddUsersToGroup = (props: Props) => {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [isLodaing, setIsLoading] = useState(false);
  const [openDropDown, SetOpenDropDown] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [isDomainNotAllowed, setIsDomainNotAllowed] = useState(false);
  const multiSelectRef = useRef(null);

  useEffect(() => { }, [users]);

  const handleChange = async (event: MultiSelectChangeEvent) => {
    const values = event.target.value;
    const lastItem = values[values.length - 1];
    const filteredUsers = users.filter(item => item !== lastItem);
    setUsers(filteredUsers);
    setSelectedUsers([...values]);
    props.onAddUpdateUsers(values.length > 0 ? values : []);
    SetOpenDropDown(values.length > 0);
  };

  const onSelectAllClick = () => {
    multiSelectRef.current.state.text = "";
    const selectedUsersArr = [...selectedUsers, ...users];
    setSelectedUsers(selectedUsersArr);
    props.onAddUpdateUsers(selectedUsersArr.length > 0 ? selectedUsersArr : []);
    SetOpenDropDown(false);
  }

  const isFilteredValueValidDomain = (filteredValue: string) => {
    const domainRegex = new RegExp(/^([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}$/i);
    return domainRegex.test(filteredValue.trim());
  }

  const itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = usersBackground(itemProps.dataItem);
    return React.cloneElement(li, li.props, itemChildren);
  };

  const tagRender = (tagData: TagData, li: React.ReactElement<HTMLLIElement>) =>
    React.cloneElement(li, li.props, [
      <Tooltip
        key={"addusertooltip" + tagData.data[0].id}
        title={tagData.data[0].username}
        placement="top-start"
        arrow
        className="email-tool-tip"
      >
        <span key={"addusername" + tagData.data[0].id} id={tagData.data[0].id}>
          {" "}
          {tagData.data[0].fullName}{" "}
        </span>
      </Tooltip>,

      li.props.children[1]
    ]);

  let UserGroupName = props.groupName ? props.groupName : "<GroupName>";

  const filterChange = (event: MultiSelectFilterChangeEvent) => {
    let searchValue = event.filter.value;
    if (!CommonHelper.isNullOrWhiteSpace(searchValue) && searchValue.length >= 3) {
      setUsers([]);
      getUsers(setUsers, searchValue.trim());
    }
    if (searchValue.length === 0) {
      SetOpenDropDown(false);
    }
  };

  const removeSelectedUsers = (searchResults: any, selectedUsers: User[]) => {
    for (let i = searchResults.length - 1; i >= 0; i--) {
      for (let j = 0; j < selectedUsers.length; j++) {
        if (searchResults[i] && searchResults[i].id === selectedUsers[j].id) {
          searchResults.splice(i, 1);
        }
      }
    }
    return searchResults;
  };

  const getUsers = async (setUsers: React.Dispatch<React.SetStateAction<User[]>>, values: any) => {
    const params = {
      skip: 0,
      searchText: values,
      take: 500
    };
    setIsLoading(true);
    try {
      const result = await AdminService.getUser(params, props.groupId, props.selectedDomains);

      if (result.ok) {
        let searchResults = result.data.results.map((dataItem: any) =>
          Object.assign({ fullName: dataItem.lastName + ", " + dataItem.firstName }, dataItem)
        );

        let selectedUserList = selectedUsers && selectedUsers.length > 0 ? selectedUsers : new Array<User>(); // Selected users in the popup
        selectedUserList = props.selectedGroupUsers && props.selectedGroupUsers.length > 0 ? selectedUserList.concat(props.selectedGroupUsers) : selectedUserList; // Concatenate already selected users from the grid
        searchResults = removeSelectedUsers(searchResults, selectedUserList); // remove already selected users from search results.
        setUsers(searchResults);

        if (params.searchText && params.searchText.replace('@', '') && isFilteredValueValidDomain(params.searchText.replace('@', ''))) {
          setShowHeader(true);

          let emailDomain = params.searchText;
          if (params.searchText.indexOf('@') > -1) {
            emailDomain = params.searchText.substring(params.searchText.indexOf("@") + 1, params.searchText.length);
          }

          if (props.selectedDomains && props.selectedDomains.length > 0 && searchResults.length === 0 && !checkAllowedDomainOrnot(props.selectedDomains, emailDomain)) {
            setIsDomainNotAllowed(true);
          }
          else {
            setIsDomainNotAllowed(false);
          }
        }
        else {
          setShowHeader(false);
          setIsDomainNotAllowed(false);
        }
      }

      setIsLoading(false);
      SetOpenDropDown(true);
    } catch (e) {
      setUsers([]);
      NotificationService.showErrorToast("Something went wrong while getting users.");
    } finally {
      setIsLoading(false);
    }
  };

  const formattedlastlogindate = (userlastlogindate?: Date) => {
    return userlastlogindate
      ? CommonHelper.convertUTCDateToLocalDateString(userlastlogindate, "MMDDYYY", "/")
      : "-";
  };

  const checkAllowedDomainOrnot = (selectedDomains: string[], searchDomain: string) => {
    const allowedDomain = selectedDomains.filter(x => x.toLowerCase() === searchDomain.toLowerCase());
    return allowedDomain.length > 0 ? true : false;
  }

  const focusedItemIndex = (data: any, inputText: string, textField?: string) => {
    let text = inputText.toLowerCase();
    const index = data.findIndex((item: any) =>
      String(textField ? item[textField] : item)
        .toLowerCase()
        .includes(text.trim())
    );

    return index > -1
      ? index
      : data.findIndex((item: any) =>
        String(textField ? item["username"] : item)
          .toLowerCase()
          .includes(text.trim())
      );
  };

  const usersBackground = (dataItem: any) => {
    const fullName = dataItem.fullName;
    const username = dataItem.username;
    const lastLoginDate = formattedlastlogindate(dataItem.lastLoginDate);

    return (
      <div key={dataItem.id} className="dd-item-details user-group-user-name-wrapper">
        <Grid container>
          <Grid item md={9} sm={9} className="user-name-wrapper">
            <div className="dd-item-name">
              <em title={fullName}>{fullName}</em>
            </div>
            <p className="email">
              {" "}
              <em title={username}>{username}</em>{" "}
            </p>
          </Grid>
          <Grid item md={3} sm={3} className="last-login-wrapper">
            <div className="last-login-container">
              <p className="last-login-label">Last Login</p>
              <p className="last-login">
                {" "}
                <em title={lastLoginDate}>{lastLoginDate}</em>{" "}
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  const listNoDataRender = (element: React.ReactElement<HTMLDivElement>) => {
    const noData = (
      <div className="model-popup-norecords-message">
        Domain not allowed.
      </div>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  };

  return (
    <div>
      <Grid container className="user-create">
        <Grid item xs={12}>
          <div className="model-add-user-header">
            Search and add users to <span>{UserGroupName}</span>.
            {props.selectedDomains && props.selectedDomains.length > 0 && (
              <>
                {" "}
                User must match allowed email domains:{" "}
                <div className="domain-names">
                  {props.selectedDomains.join(", ")}.
                </div>
              </>)
            }

          </div>
          <div className="multi-select-wrapper">
            <span className="search-wrapper">
              <SearchIcon />
            </span>
            <LocalizationProvider language="en-US">
              <IntlProvider locale="en" >
                <MultiSelect
                  data={users}
                  onChange={handleChange}
                  filterable={true}
                  header={<Button style={(showHeader && !isDomainNotAllowed) ? { marginLeft: "10px" } : { marginLeft: "10px", display: "none" }}
                    className="btn-select" onClick={onSelectAllClick}> Select All </Button>}
                  value={selectedUsers}
                  itemRender={(li, itemprops) => itemRender(li, itemprops)}
                  listNoDataRender={listNoDataRender}
                  onFilterChange={e => filterChange(e)}
                  loading={isLodaing}
                  textField="fullName"
                  dataItemKey="id"
                  placeholder="Search"
                  tagRender={(tagData, li) => tagRender(tagData, li)}
                  opened={(users.length > 0 && openDropDown) || isDomainNotAllowed}
                  onBlur={() => {
                    SetOpenDropDown(false);
                  }}
                  onFocus={() => {
                    SetOpenDropDown(true);
                  }}
                  focusedItemIndex={focusedItemIndex}
                  popupSettings={{
                    height: '45vh'
                  }}
                  ref={multiSelectRef}
                />
              </IntlProvider>
            </LocalizationProvider>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddUsersToGroup;
