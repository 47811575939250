import * as React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../../store";
import * as UserGroupStore from "../../../../store/UserGroup";
import * as HeaderStore from "../../../../store/Header";
import * as WarningMessageStore from "../../../../store/WarningMessage";
import GroupUserList from "../../../administration/user/usergroups/GroupUserList";
import GridSelector from "../../../common/grid/GridSelector";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import SaveUserGroup from "./SaveUserGroup";
import { UserGroup, UpsertUserGroupModel } from "../../../../models/UserModels";
import { ShowEpiqOnlyControls } from "../../../../models/Enums";
import { User } from "../../../../models/UserModels";
import CommonHelper from "../../../common/utilities/CommonHelper";
import AddUserToGroup from "../usergroups/AddUsersToGroup";
import * as SessionStore from "../../../../store/Session";
import AdminService from "../../../../services/AdminService";
import NotificationService from "../../../../services/NotificationService";
import DialogBox from "../../../common/DialogBox";
import { EmployerDomain } from "../../../../models/EmployerModel";
import DropDownListItem from "../../../../models/DropDownListItem";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";

interface IProps {
  match?: any;
  history: any;
}

const GroupConfigurations = {
  MinimumCharacters: 3
};

type Props = IProps &
  HeaderStore.IHeaderState &
  typeof HeaderStore.actionCreators &
  UserGroupStore.IUserGroupState &
  typeof UserGroupStore.actionCreators &
  WarningMessageStore.IWarningMessageState &
  typeof WarningMessageStore.actionCreators &
  SessionStore.ISessionState;

type State = {
  mode: Mode;
  initializing: boolean;
  isFormIsValid: boolean;
  updateModel: UpsertUserGroupModel;
  userGroupDetails: UserGroup;
  savedUserGroupDetails: UserGroup;
  isUserSelectorOpen: boolean;
  selectedUsers: User[];
  savedUsers: User[];
  potentiallyAddedUsers: User[];
  isSavedEditedDetailsIntoDB: boolean;
  isGroupNameExists: boolean;
  hasEpiqOnlyError: boolean;
  hasError: boolean;
  openGroupReactivatePopup: boolean;
  canReactivate: boolean;
  selectedPotentiallyAddedUsers: User[];
  openUserConfirmationPopup: boolean;
  selectedDomains: DropDownListItem[];
  savedDomains: DropDownListItem[];
  hasDomainUserError: boolean;
};

enum Mode {
  Edit,
  Create,
  View
}

class UserGroupDetails extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const mode = Number(this.props.match.params.groupId) > 0 ? Mode.Edit : Mode.Create;

    this.state = {
      mode: mode,
      isFormIsValid: false,
      initializing: true,
      userGroupDetails: new UserGroup(),
      updateModel: new UpsertUserGroupModel(),
      isUserSelectorOpen: false,
      selectedUsers: [],
      savedUsers: [],
      potentiallyAddedUsers: [],
      isSavedEditedDetailsIntoDB: false,
      isGroupNameExists: false,
      hasEpiqOnlyError: false,
      hasError: false,
      savedUserGroupDetails: new UserGroup(),
      openGroupReactivatePopup: false,
      canReactivate: false,
      selectedPotentiallyAddedUsers: [],
      openUserConfirmationPopup: false,
      selectedDomains: [],
      savedDomains: [],
      hasDomainUserError: false
    };

  }

  async componentDidMount() {
    this.enableSave(false);
    this.ensureDataFetched();
  }

  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {

    if (prevState !== this.state) {
      const isUnsavedChangesExist = this.isDirtyChangesExist();
      this.props.updateUnsavedChanges(isUnsavedChangesExist);
      const isFormValid = this.isFormValidationsPassed();
      this.enableSave(isUnsavedChangesExist && isFormValid);
    }

  }

  isDirtyChangesExist = () => {
    const { selectedUsers, savedUsers, updateModel, selectedDomains, savedDomains, hasDomainUserError } = this.state;

    return (
      this.props.initialState !== JSON.stringify(updateModel) ||
        this.getUsersAfterExclude(selectedUsers, savedUsers).length > 0 ||
        this.getUsersAfterExclude(savedUsers, selectedUsers).length > 0 ||
        this.getDomainsAfterExclude(selectedDomains, savedDomains).length > 0 ||
        this.getDomainsAfterExclude(savedDomains, selectedDomains).length > 0
    );
  };

  isFormValidationsPassed = () => {
    const {
      updateModel,
      selectedUsers,
      savedUsers,
      isFormIsValid,
      mode,
      isGroupNameExists,
      hasEpiqOnlyError,
      selectedDomains,
      savedDomains,
      hasDomainUserError
    } = this.state;
    const isGroupNameValidationPassed =
      updateModel.groupName && isGroupNameExists === false
        ? updateModel.groupName.length >= GroupConfigurations.MinimumCharacters
        : false;

    return (
      (isGroupNameValidationPassed && isFormIsValid && !hasEpiqOnlyError && !hasDomainUserError) ||
      (mode == Mode.Edit
        ? isGroupNameValidationPassed && !hasDomainUserError && (
          (this.getUsersAfterExclude(selectedUsers, savedUsers).length > 0 ||
            this.getUsersAfterExclude(savedUsers, selectedUsers).length > 0) ||
          this.getDomainsAfterExclude(selectedDomains, savedDomains).length > 0 ||
          this.getDomainsAfterExclude(savedDomains, selectedDomains).length > 0)
        : false)
    );
  };

  handleCancelClick = () => {
    this.props.history.push("/administration/userGroups");
  };

  getUsersAfterExclude = (allUsers: Array<User>, excludeUserList: Array<User>) => {
    return allUsers
      ? allUsers.filter((user: User) => {
        const excludeUser =
          (excludeUserList &&
            excludeUserList.find((preSelectedUser: User) => {
              return preSelectedUser.id === user.id;
            })) ||
          false;

        return !excludeUser;
      })
      : new Array<User>();
  };

  getDomainsAfterExclude = (allDomains: Array<DropDownListItem>, excludeDomainList: Array<DropDownListItem>) => {
    return allDomains
      ? allDomains.filter((domain: DropDownListItem) => {
        const excludeDomain =
          (excludeDomainList &&
            excludeDomainList.find((preSelectedDomain: DropDownListItem) => {
              return preSelectedDomain.id === domain.id;
            })) ||
          false;

        return !excludeDomain;
      })
      : new Array<DropDownListItem>();
  };

  handleSaveClick = async () => {
    const { updateModel, selectedUsers, savedUsers, mode, selectedDomains, savedDomains } = this.state;
    let requestModel = CommonHelper.cloneObject(updateModel);
    requestModel.addUsers = this.getUsersAfterExclude(selectedUsers, savedUsers).map(item => {
      return item.id;
    })
    requestModel.removeUsers = this.getUsersAfterExclude(savedUsers, selectedUsers).map(item => {
      return item.id;
    })
    requestModel.domainIds = selectedDomains.map(item => { return item.id });

    this.props.setHeaderButtons(
      this.getBtnsList(true, true),
      "",
      "/administration/userGroups",
      "Back to user group list"
    );

    const response = await AdminService.getUserGroupDetailsByName(requestModel.groupName);
    if (response.ok) {
      if (response.data && response.data.isDeleted) {
        this.setState({ canReactivate: mode === Mode.Create ? true : false, openGroupReactivatePopup: true, userGroupDetails: response.data })// re-activate user group only on create mode.
        return;
      }
    }

    if (mode == Mode.Create) {
      const result = await AdminService.createUserGroup(requestModel);

      if (result.ok) {
        const errorMessage = result.data && result.data.inValid === true && result.data.message ? result.data.message : "";

        if (errorMessage && errorMessage.toLocaleLowerCase() === "usergroup already exists.") {
          this.setState({ isGroupNameExists: true });
          this.props.setHeaderButtons(this.getBtnsList(true), "", "/administration/userGroups", "Back To User Group List");
        }
        else if (errorMessage && errorMessage.toLocaleLowerCase().includes("this user group cannot be marked as epiq only")) {
          this.setState({ hasEpiqOnlyError: true });
          NotificationService.showErrorToast(result.data.message);
        }
        else if (errorMessage && errorMessage.toLocaleLowerCase() === "invalid domain users.") {
          this.setState({ hasDomainUserError: true })
        }
        else {
          NotificationService.showSuccessToast(`${requestModel.groupName} Created.`);
          this.props.saveUnsavedChanges(null, false);
          this.props.history.push("/administration/userGroups");
        }

      }
      else {
        NotificationService.showErrorToast("Something went wrong. UserGroup was not created.");
      }

    }
    else {
      const result = await AdminService.updateUserGroup(requestModel);

      if (result.ok) {

        if (result.data.inValid == true && result.data.message.toLocaleLowerCase() === "usergroup already exists.") {
          this.setState({ isGroupNameExists: true });
        }
        else if (result.data.inValid == true && result.data.message.toLocaleLowerCase() === "invalid domains users.") {
          this.setState({ hasDomainUserError: true })
        }
        else if (result.data.inValid == true) {
          this.setState({ hasEpiqOnlyError: true });
          NotificationService.showErrorToast(result.data.message);
        }
        else {

          this.props.saveUnsavedChanges(JSON.stringify(updateModel), false);


          let updatedUsersList = new Array<User>();
          let updatedDomainsList = new Array<DropDownListItem>();

          const addedNewUserList = this.getUsersAfterExclude(selectedUsers, savedUsers);
          const removedNewUsersList = this.getUsersAfterExclude(savedUsers, selectedUsers);

          const addedNewDomainList = this.getDomainsAfterExclude(selectedDomains, savedDomains);
          const removedDomainList = this.getDomainsAfterExclude(savedDomains, selectedDomains);

          if (addedNewUserList) {
            updatedUsersList = [...addedNewUserList, ...savedUsers];
          }

          if (removedNewUsersList) {
            updatedUsersList = this.getUsersAfterExclude(updatedUsersList.slice(), removedNewUsersList);
          }

          if (addedNewDomainList) {
            updatedDomainsList = [...addedNewDomainList, ...savedDomains];
          }

          if (removedDomainList) {
            updatedDomainsList = this.getDomainsAfterExclude(updatedDomainsList.slice(), removedDomainList);
          }

          this.setState({
            savedUserGroupDetails: requestModel,
            isSavedEditedDetailsIntoDB: true,
            isFormIsValid: false,
            potentiallyAddedUsers: [],
            selectedPotentiallyAddedUsers: [],
            savedUsers: updatedUsersList,
            selectedUsers: updatedUsersList,
            selectedDomains: updatedDomainsList,
            savedDomains: updatedDomainsList

          });
          NotificationService.showSuccessToast("Update saved.");
        }

        this.props.setHeaderButtons(this.getBtnsList(true), "", "/administration/userGroups", "Back To User Group List");
      }
      else {
        NotificationService.showErrorToast("Something went wrong.Update not saved.");
      }

    }

  };

  getBtnsList: any = (isSaveDisabled: boolean, isSaving: boolean) => {
    const buttons: any = [
      {
        buttonLabel: "Cancel",
        type: "button",
        handleClick: this.handleCancelClick
      },

      {
        buttonLabel: "Save",
        type: "button",
        disabled: isSaveDisabled,
        color: "primary",
        handleClick: this.handleSaveClick,
        isSaving: isSaving
      }
    ];
    return buttons;
  };

  private async ensureDataFetched() {

    if (this.state.mode === Mode.Edit) {
      let groupId: number = Number(this.props.match.params.groupId);
      const result = await AdminService.getUserGroupDetails(groupId);

      if (result.ok) {

        const userGroupDomainsResult = await AdminService.getUserGroupDomainsDetails(groupId);

        if (userGroupDomainsResult.ok) {
          const userGroupDomains = userGroupDomainsResult.data && userGroupDomainsResult.data.map((ed: EmployerDomain) => {
            return {
              id: ed.id,
              name: ed.domain,
              restProps: {}
            };
          })

          let updateModel = CommonHelper.convertPropsToType(result.data, UpsertUserGroupModel);
          this.setState({ updateModel: updateModel, savedUserGroupDetails: CommonHelper.cloneObject(result.data), selectedDomains: userGroupDomains, savedDomains: userGroupDomains });

          this.props.saveUnsavedChanges(JSON.stringify(updateModel), false);
          this.setState({ initializing: false });
        }
      }
      else {
        this.setState({ initializing: false, hasError: true });
        this.props.saveUnsavedChanges(null, false);
        this.props.history.push("/administration/userGroups");
      }

    }
    else {
      await this.props.saveUnsavedChanges(JSON.stringify(this.state.updateModel), false);
      this.setState({ initializing: false });

    }
  }

  private enableSave(canSave: boolean) {
    this.props.setHeaderButtons(
      this.getBtnsList(!canSave),
      "",
      "/administration/userGroups",
      "Back to user group list"
    );
  }

  handleAddUsersPopupClose = (accepted: boolean) => {
    if (accepted) {
      const dedupeUsers = CommonHelper.dedupeObjects<User>(
        this.state.potentiallyAddedUsers,
        this.state.selectedUsers || new Array<User>(),
        "id"
      );
      const hasEpiqError = this.validateEpiqAdminUsers(dedupeUsers, this.state.updateModel.epiqOnly);

      this.setState({ selectedUsers: dedupeUsers, isUserSelectorOpen: false, potentiallyAddedUsers: [], hasEpiqOnlyError: hasEpiqError });
    } else {
      this.setState({ isUserSelectorOpen: false, potentiallyAddedUsers: [] });
    }
  };

  onAddPotentiallySelectedUsers = (potentiallyAddedUsers: User[]) => {
    this.setState({ selectedPotentiallyAddedUsers: potentiallyAddedUsers });
  }

  onAddUsersToUserGroupClick = () => {
    this.setState({ openUserConfirmationPopup: true });
  }

  onRemoveUsers = (users: User[]) => {
    const hasEpiqError = this.validateEpiqAdminUsers(users, this.state.updateModel.epiqOnly);
    const domains = this.state.selectedDomains && this.state.selectedDomains.map((d) => { return d.name.toLowerCase() });
    const hasDomainError = this.validateUserGroupDomainUsers(users, domains);

    this.setState({ selectedUsers: users, hasEpiqOnlyError: hasEpiqError, hasDomainUserError: hasDomainError });
  };

  handleAddUsers = () => {
    this.setState({ isUserSelectorOpen: true });
  };

  updateUserGroupDetails = (userGroup: UserGroup, isValid: boolean) => {
    const newGroupName = userGroup.groupName;
    const oldGroupName = this.state.updateModel.groupName;
    const groupName = userGroup.groupName;
    const editedUserGroupDetails: UpsertUserGroupModel = {
      ...this.state.updateModel,
      groupName: groupName,
      comments: userGroup.comments,
      epiqOnly: userGroup.epiqOnly
    };
    const hasEpiqError = this.validateEpiqAdminUsers(this.state.selectedUsers, userGroup.epiqOnly);

    this.setState({
      ...this.state,
      isGroupNameExists:
        newGroupName.toLowerCase() === oldGroupName.toLowerCase() && this.state.isGroupNameExists
          ? this.state.isGroupNameExists
          : false,
      updateModel: editedUserGroupDetails,
      isFormIsValid: this.props.initialState !== JSON.stringify(userGroup) ? isValid : false,
      hasEpiqOnlyError: hasEpiqError
    });

  };

  selectionChange = (selected: User[]) => {
    const hasEpiqError = this.validateEpiqAdminUsers(selected, this.state.updateModel.epiqOnly);

    this.setState({ selectedUsers: selected, savedUsers: selected, hasEpiqOnlyError: hasEpiqError });
  };

  componentWillUnmount() {
    this.props.setHeaderButtons(null);
    this.props.saveUnsavedChanges(null, false);
  }

  private validateEpiqAdminUsers(selectedUsers: User[], epiqOnly: boolean) {
    const count = epiqOnly && selectedUsers.length > 0 ? selectedUsers.filter(item => {
      if (!this.isEpiqUser(item.username))
        return true;
    }).length : 0;
    return (count > 0);
  }

  private validateUserGroupDomainUsers(selectedUsers: User[], selectedDomains: string[]) {
    const count = (selectedDomains.length > 0 && selectedUsers.length > 0) ? selectedUsers.filter(item =>
      !(selectedDomains.indexOf(CommonHelper.getEmailDomain(item.username).toLowerCase()) > -1)
    ).length : 0;
    return count > 0 ? true : false;
  }

  isEpiqUser = (email: string) => {
    const userDomain = email && email.substring(email.indexOf('@') + 1, email.length);
    const epiqDomains = this.props.sessionData.internalUsersDomains;
    return epiqDomains && epiqDomains.has(userDomain.toLowerCase());
  }

  resetViewMode = (isUserGroupInfoInEditMode: boolean) => {
    this.setState({ isSavedEditedDetailsIntoDB: isUserGroupInfoInEditMode });
  };

  async toggleDialogEvent(event: React.MouseEvent<HTMLElement>) {
    if (event.currentTarget.textContent.toLocaleLowerCase() === "reactivate") {
      this.setState({ openGroupReactivatePopup: false })
      this.state.userGroupDetails.isDeleted = false;
      const result = await AdminService.deleteOrRestoreUserGroup(this.state.userGroupDetails);
      if (result.ok && !result.data.invalid) {
        NotificationService.showSuccessToast(`Reactivated user group: ${this.state.userGroupDetails.groupName} `);
        this.props.saveUnsavedChanges(null, false);
        this.props.history.push("/administration/userGroups");
      }
      else {
        NotificationService.showErrorToast(result.data.message);
      }
    }
    else if (event.currentTarget.textContent.toLocaleLowerCase() === "cancel" || event.currentTarget.textContent.toLocaleLowerCase() === "ok") {
      this.setState({ openGroupReactivatePopup: false });
    }
  }

  async toggleUserConfirmationDialogEvent(event: React.MouseEvent<HTMLElement>) {
    const { selectedUsers, selectedPotentiallyAddedUsers, hasEpiqOnlyError } = this.state;
    if (event.currentTarget.textContent.toLocaleLowerCase() === "yes") {
      const updatedUsers = [...selectedUsers, ...selectedPotentiallyAddedUsers];
      const hasEpiqError = this.validateEpiqAdminUsers(updatedUsers, this.state.updateModel.epiqOnly);
      const domains = this.state.selectedDomains && this.state.selectedDomains.map((d) => { return d.name.toLowerCase() });
      const hasDomainError = this.validateUserGroupDomainUsers(updatedUsers, domains);

      this.setState({ potentiallyAddedUsers: selectedPotentiallyAddedUsers, selectedUsers: updatedUsers, hasEpiqOnlyError: hasEpiqError, hasDomainUserError: hasDomainError });
    }
    this.setState({ openUserConfirmationPopup: false, selectedPotentiallyAddedUsers: [], potentiallyAddedUsers: [] });
  }

  addSelectedListItems = (selectedItems: DropDownListItem[]) => {
    const newDomains: DropDownListItem[] = selectedItems.map((d) => {
      return {
        id: d.id,
        name: d.name,
        ...d.restProps
      };
    });

    const domains = newDomains.map((d) => { return d.name.toLowerCase() });
    const hasDomainError = this.validateUserGroupDomainUsers(this.state.selectedUsers, domains);

    this.setState({ selectedDomains: newDomains, hasDomainUserError: hasDomainError });
  };


  render() {
    let userGroupDetails;

    const totalCountRender = (): JSX.Element => {
      return (
        <div className="total-records-container">
          {"Users"}
          <span className="total-count">{this.state.selectedUsers.length}</span>
        </div>
      );
    };

    if ((this.state.mode !== Mode.Create && this.props.isLoading) || this.state.initializing) {
      userGroupDetails = (
        <Paper className="user-grid-wrapper">
          <div className="no-users">
            <CircularProgress />
          </div>
        </Paper>
      );
    } else if (this.state.hasError) {
      userGroupDetails = (
        <Paper className="user-grid-wrapper">
          <div className="no-users">
            <p> Oops! Something went wrong. </p>
            <p>Please check your network connection and refresh the page.</p>
          </div>
        </Paper>
      );
    } else {
      let showEpiqOnlyAdmin;
      if (this.props.sessionData.superAdmin) {
        showEpiqOnlyAdmin = ShowEpiqOnlyControls.ENABLE;
      } else if (this.props.sessionData.permissions.has("ShowEpiqOnlyUserGroups")) {
        showEpiqOnlyAdmin = ShowEpiqOnlyControls.SHOW
      } else {
        showEpiqOnlyAdmin = ShowEpiqOnlyControls.HIDE
      }
      userGroupDetails = (
        <SaveUserGroup
          {...this.props}
          createUserGroup={this.state.mode === Mode.Create}
          updateUserGroupDetails={this.updateUserGroupDetails}
          savedUserGroupDetails={this.state.savedUserGroupDetails}
          showEpiqOnlyAdmin={showEpiqOnlyAdmin}
          permissions={this.props.sessionData.permissions}
          isUpdateSaved={this.state.isSavedEditedDetailsIntoDB}
          isGroupExist={this.state.isGroupNameExists}
          hasEpiqOnlyError={this.state.hasEpiqOnlyError}
          resetViewMode={this.resetViewMode}
          addSelectedListItems={this.addSelectedListItems}
          preselectedDomains={this.state.selectedDomains}
          hasDomainUserError={this.state.hasDomainUserError}
        />
      );
    }

    const users =
      (
        <div>
          <GridSelector
            isOpen={this.state.isUserSelectorOpen}
            acceptBtnText="Add"
            cancelBtnText="Cancel"
            titleText="Add Users"
            prefixTitleText="USER GROUP"
            addAvailable={this.state.selectedPotentiallyAddedUsers.length > 0}
            onApply={this.onAddUsersToUserGroupClick}
            onClose={this.handleAddUsersPopupClose}
            addClass="add-user-usergroup-modal modal-as-sidebar"
          >
            <AddUserToGroup
              groupId={this.state.updateModel.groupId}
              groupName={this.state.updateModel.groupName}
              onAddUpdateUsers={this.onAddPotentiallySelectedUsers}
              {...this.props}
              selectedGroupUsers={this.state.selectedUsers}
              selectedDomains={this.state.selectedDomains ? this.state.selectedDomains.map(item => { return item.name }) : []}
            />
            </GridSelector>
          <GroupUserList
            users={this.state.selectedUsers}
            addClickHandler={this.handleAddUsers}
            groupId={this.props.match.params.groupId}
            selectionChange={this.selectionChange}
            onRemoveUsers={this.onRemoveUsers}
            permissions={this.props.sessionData.permissions}
            selectedDomains={this.state.selectedDomains.map((d) => { return d.name })}
          />
        </div>
      );
    const dialog = (
      <div className="warning">
        <DialogBox
          title={this.state.canReactivate ? "Reactivate user group" : "Name exists"}
          content={this.state.canReactivate ? `User group name '${this.state.userGroupDetails.groupName}' was previously deleted. Do you want to reactivate this deleted user group?` :
            `Because user group name: '${this.state.userGroupDetails.groupName}' was previously deleted, it cannot be reused when editing. Supply a different name`}
          okButtonLabelText={this.state.canReactivate ? "Cancel" : ""}
          cancelButtonLabelText={this.state.canReactivate ? "Reactivate" : "Ok"}
          visible={this.state.openGroupReactivatePopup}
          toggleDialogEvent={this.toggleDialogEvent.bind(this)}
        /></div>);

    const userConfirmationDialog = (
      <div className="warning">
        <DialogBox
          title={"Add users to user group"}
          content={`This action adds ${this.state.selectedPotentiallyAddedUsers.length} users to the user group${this.state.updateModel.groupName ? ": " + this.state.updateModel.groupName : " <GroupName>"}. Do you want to add these users?`}
          okButtonLabelText={"No"}
          cancelButtonLabelText={"Yes"}
          visible={this.state.openUserConfirmationPopup}
          toggleDialogEvent={this.toggleUserConfirmationDialogEvent.bind(this)}
        /></div>);

    return (
      <Paper className="user-group-detais-wrapper no-separator">
        <div className="pnlbargroup">
          {userGroupDetails}
          <div className="pnl-user-group-list">
            <PanelBar >
              <PanelBarItem className="pnl-item-user-group-list" expanded={true} title={totalCountRender()}>
                {users}
              </PanelBarItem>
            </PanelBar>
          </div>
        </div>
        {dialog}
        {userConfirmationDialog}
      </Paper >
    );
  }
}

export default connect(
  (state: IApplicationState) => ({ ...state.headerState, ...state.userGroupState, ...state.warningMessageState, ...state.sessionState }),
  {
    ...HeaderStore.actionCreators,
    ...UserGroupStore.actionCreators,
    ...WarningMessageStore.actionCreators
  }
)(UserGroupDetails as any);
