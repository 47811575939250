import * as React from "react";
import SearchIcon from "@material-ui/icons/Search";
import { MultiSelect, MultiSelectChangeEvent, MultiSelectFilterChangeEvent, ListItemProps } from "@progress/kendo-react-dropdowns";
import { useState } from "react";
import { User } from "../../../models/UserModels";
import CommonHelper from "../../common/utilities/CommonHelper";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import NotificationService from "../../../services/NotificationService";
import DropDownListItem from "../../../models/DropDownListItem";
import AdminService from "../../../services/AdminService";

type Props = {
  users?: User[] | undefined;
  onAddUpdateUsers?: (selected: User[]) => void;
};

const AddUsersToClientAdmin = (props: Props) => {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [isLodaing, setIsLoading] = useState(false);

  const getUsers = async (searchText?: string) => {
    const params = {
      skip: 0,
      searchText: searchText,
      take: 100
    };
    setIsLoading(true);
    try {
      const result = await AdminService.getUser(params);
      if (result.ok) {
        let searchResults = result.data.results.map((dataItem: any) =>
          Object.assign({ fullName: dataItem.lastName + ", " + dataItem.firstName }, dataItem)
        );
        searchResults = removeSelectedUsers(searchResults, selectedUsers); // remove already selected users from search results.
        setUsers(searchResults);
        setOpenDropDown(true);
      }
    } catch {
      setUsers([]);
      NotificationService.showErrorToast("Something went wrong while getting users.");
    } finally {
      setIsLoading(false);
    }

  };

  const removeSelectedUsers = (searchResults: any, selectedUsers: User[]) => {
    for (let i = searchResults.length - 1; i >= 0; i--) {
      for (let j = 0; j < selectedUsers.length; j++) {
        if (searchResults[i] && searchResults[i].id === selectedUsers[j].id) {
          searchResults.splice(i, 1);
        }
      }
    }
    return searchResults;
  };

  const handleChange = (event: MultiSelectChangeEvent) => {
    const values = event.target.value;
    const lastItem = values[values.length - 1];
    const filteredUsers = users.filter(item => item !== lastItem);
    setUsers(filteredUsers);
    setSelectedUsers([...values]);
    props.onAddUpdateUsers(values.length > 0 ? values : []);
    setOpenDropDown(values.length > 0);
  };

  const itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = (
      <div className="multiselect-items">
        <div className="dd-item-name">
          <span title={itemProps.dataItem.fullName}>{itemProps.dataItem.fullName}</span>
        </div>
        <p className="email">
          {" "}
          <span title={itemProps.dataItem.username}>{itemProps.dataItem.username}</span>{" "}
        </p>
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  const filterChange = (event: MultiSelectFilterChangeEvent) => {
    let searchValue = event.filter.value;
    if (!CommonHelper.isNullOrWhiteSpace(searchValue) && searchValue.length >= 3) {
      setUsers([]);
      getUsers(searchValue);
    }
    if (searchValue.length === 0) {
      setOpenDropDown(false);
    }
  };

  const focusedItemIndex = (data: any, inputText: string, textField?: string) => {
    let text = inputText.toLowerCase();
    const index = data.findIndex((item: any) =>
      String(textField ? item[textField] : item)
        .toLowerCase()
        .includes(text.trim())
    );

    return index > -1
      ? index
      : data.findIndex((item: any) =>
        String(textField ? item["username"] : item)
          .toLowerCase()
          .includes(text.trim())
      );
  };

  return (
    <div className="users-multiselect-wrapper">
      <span className="search-wrapper">
        <SearchIcon />
      </span>
      <MultiSelect
        className="multiselect-users"
        name="adminUsers"
        data={users}
        itemRender={itemRender}
        filterable={true}
        onFilterChange={e => filterChange(e)}
        textField="fullName"
        dataItemKey="id"
        onChange={handleChange}
        loading={isLodaing}
        placeholder="Search"
        opened={(users.length > 0 && openDropDown)}
        onBlur={() => {
          setOpenDropDown(false);
        }}
        onFocus={() => {
          setOpenDropDown(true);
        }}
        focusedItemIndex={focusedItemIndex}
      />
    </div>
  )
}

export default AddUsersToClientAdmin;