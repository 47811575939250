import * as React from "react";
import { ISessionManager } from "../../SessionManager";
import HeaderWrapper from "../../components/common/HeaderWrapper";
import { EpiqFooterComponent } from "../../components/edge/footer/EpiqFooterComponent";
import epiqwhiteLogo from "../../images/epiq-logo-white.svg";
import Paper from "@material-ui/core/Paper";
import "./PrivacyStatement.scss";

interface IProps {
    sessionManager: ISessionManager;
    history: any;
}

type Props = IProps & { children?: React.ReactNode };

const PrivacyStatement = (props: Props) =>
  <div>
    <HeaderWrapper sessionManager={props.sessionManager} hideRightDropDownMenu={true} />
    <div className="privacy-content-wrapper">
    <Paper className="privacy-container">
      <h2>Privacy Notice</h2>
        <div className="privacy-inner-container">      
      <h3>Introduction</h3>
      <p>Epiq Systems, Inc. ("<b>Company</b>" or "<b>We</b>" or "<b>Epiq</b>") respect your privacy and are committed to protecting it through our compliance with this Privacy Notice ("Notice").</p>
      <p>This Notice describes the types of information we may collect from you or that you may provide when you use our SaaS solution (our "<b>Services</b>").
      It also contains our practices for collecting, using, maintaining, protecting, and disclosing that information and the rights that you have in connection with it.</p>
      <p>Please read this Notice carefully to understand our policies and practices regarding your information and how we will treat it.
          If you do not agree with our policies and practices, you may choose not to use the Services. By accessing or using the Services, you agree to this Privacy Notice.
      This Notice may change from time to time (see <a href="https://www.epiqglobal.com/en-us/privacy-statement#ChangesToOurPrivacyPolicy" target="_blank">Changes to Our Privacy Notice</a>). By continuing to use our Services after we make updates to this Notice, you are consenting to those changes.
      We recommend that you periodically check this Notice for updates. </p>
      <h3>Children Under the Age of 16</h3>
      <p>Our Services are not intended for children under 16 years of age. No one under age 16 may provide any personal information through our Services. We do not knowingly collect personal information from children under 16.
      If you are under 16, do not use or provide any information through our Services or through any of its features. </p>
      <p>California residents under 16 years of age may have additional rights regarding the collection and sale of their personal information.
      Please see <a href="https://www.epiqglobal.com/en-us/privacy-statement#YourCaliforniaPrivacyRights" target="_blank"> Your California Privacy Rights </a> for more information.</p>
      <h3>Information We Collect About You and How We Collect It</h3>
      <p>We may require basic information which identifies you as an individual ("personal information") to transact business with you, on behalf of the company where you work, as our client. </p>
      <h4>We may collect the following information:</h4>
      <ul>
        <li>First name and last name</li>
        <li>Job title or description</li>
        <li>Company or organization name</li>
        <li>Company address, including country</li>
        <li>Contact information including email address and telephone number(s)</li>
        <li>Usernames (account alias) and passwords to allow users to login and use the Services</li>
        <li>Technical information required to provide access to systems and networks such as IP addresses, internet connection and login information</li>
        <li>Information pertinent to fulfilling business transactions on behalf of a client</li>
        <li>Metadata, such as logs, for usage information and activity logs, with identifying characteristics such as creator or author of a transaction, names of individuals who have accessed or downloaded file(s), the time file(s) were accessed or downloaded and IP addresses of users</li>
      </ul>
        <h4>We collect this information:</h4>
      <ul>
        <li>Directly from you when you provide it to us.</li>
        <li>Automatically as you navigate through our website and use our Services. Information collected automatically may include usage details, IP addresses. </li>
        <li>Through cookies, web beacons, and other tracking technologies.</li>
        <li>From third parties, such as our business partners.</li>
      </ul>
      <p>We do not collect sensitive information such as racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, genetic data and biometric data. </p>
      <h4>Information We Collect Through Automatic Data Collection Technologies</h4>
      <p>As you use and interact with our Services, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:</p>
      <ul>
        <li>Details of your use of our Services, including traffic data, location data, logs, and other communication data and the resources that you access and use.</li>
        <li>Information about your computer and internet connection, including your IP address, operating system, and browser type.</li>
      </ul>
      <p>We also may use these technologies to collect information about your online activities over time and across third-party websites or other online services (behavioral tracking).</p>
      <p>The information we collect automatically is statistical data and may include personal information or we may maintain it or associate it with personal information we collect in other ways or receive from third parties. This helps us improve our Services and deliver a better and more personalized service. It also allows to:</p>
      <ul>
        <li>Estimate our audience size and usage patterns.</li>
        <li>Store information about your preferences, allowing us to customize our Services according to your individual interests.</li>
        <li>Increase performance.</li>
        <li>Recognize you when you return to our Services website.</li>
      </ul>
      <h4>The technologies we use for this automatic data collection may include:</h4>
      <ul>
        <li><b>Cookies (or browser cookies)</b>. A cookie is a small file placed on the hard drive of your computer.
        You may refuse to accept browser cookies by activating the appropriate setting on your browser.
        However, if you select this setting you may be unable to access certain parts of the Services.
        Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to the Services.
        To read about our cookie notice, click here.</li>
        <li><b>Web Beacons</b>. Pages of our the Services and our e-mails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit Us, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity). </li>
        <p>We do not collect personal information automatically, but we may tie this information to personal information about you that we collect from other sources or you provide to us.</p>
      </ul>
      <h4>How We Use Your Information</h4>
      <p>We may use information that we collect about you or that you provide to us, including any personal information, as set out herein. </p>
      <h3>Business Transactions</h3>
      <p>We may collect and use personal information to administer client accounts, business transactions and share information across Epiq’s systems, and with related corporate entities. 
      For further information on sharing, see Disclosure of Your Information. 
      This includes transferring and accessing personal information in the United States and in other sites or countries where we host servers or utilize third party services.
        In any event, Epiq will be responsible for the secure access of personal information.</p>
      <h3>Use of Services</h3>
      <p>We may collect and use personal information to help us administer our Services:</p>
      <ul>
        <li>Analyze system usage to help maintain the systems.</li>
        <li>Provide customer support.</li>
        <li>Optimize system operation and enable product development and improvements.</li>
        <li>Support compliance with applicable laws and regulations.</li>
      </ul>
      <h3>Legitimate Business Purposes</h3>
      <p>We may further collect and use personal information when necessary for other legitimate business purposes including:</p>
      <ul>
        <li>Conducting business more effectively and efficiently, for example, by utilizing resources globally in order to provide better support. </li>
        <li>Enforcing terms of use, billing, or other contract-related activity.</li>
        <li>In the event of a merger, sale, or other transfer of company assets.</li>
      </ul>
      <h3>Legal Purposes</h3>
      <p>We may also collect and use personal information where necessary to comply with laws and regulations, defend Epiq, its subsidiaries, affiliates and third party service providers against claims, or protect our rights, employees and property. </p>
      <h3>Disclosure of Your Information</h3>
      <h4>Within Epiq</h4>
      <p>Employees, contractors and agents of Epiq may be given access to personal information. Access to personal information will be limited to the performance of their duties and the reason for processing.
        Such personnel are required to keep information confidential and not permitted to use it for any other purposes. </p>
          <p>Epiq is headquartered in the United States with offices globally, please see <a href="https://www.epiqglobal.com/en-us/about/locations" target="_blank">Global Locations</a>. As a global business, we provide our Services, support and operations in different countries and personal information may be shared among Epiq’s subsidiaries and affiliates in the United States. </p>
      <h4>Third Party Service Providers</h4>
      <p>We do not sell, rent, share, or otherwise disclose personal information collected through your use of the Services to third parties except as provided for herein or when we have your permission. We share personal information with third-parties for the purposes of providing services and support including those service providers who work on our behalf and require access to personal information in order to do that work. When we permit such access, we implement appropriate technical and organization measures to ensure the confidentiality and security of personal information and that it is only used in a manner consistent with this Notice.   </p>
        <p>Epiq has entered into data protection agreements with its third-party service providers which include:</p>
        <table cellPadding="0" cellSpacing="0">
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th>Location</th>
        </tr>
        <tr>
          <td>Okta Inc.</td>
          <td>Identity management, single sign-on and authentication</td>
          <td>United States</td>
        </tr>
        <tr>
          <td>ServiceNow, Inc.</td>
          <td>Information technology workflow and ticketing platform</td>
          <td>United States</td>
        </tr>
        <tr>
          <td>SalesForce.com Inc.</td>
          <td>Customer relationship management platform </td>
          <td>United States</td>
        </tr>
        <tr>
          <td>Microsoft Corporation</td>
          <td>Azure public cloud</td>
          <td>United States</td>
        </tr>
      </table>
      <h4>Required by Law</h4>
      <p>We may disclose personal information to third parties as required by law including: </p>
      <ul>
        <li>Compliance with legal, regulatory or contractual obligations.</li>
        <li>Responding to administrative or judicial process or any other lawful request by authorities.</li>
        <li>To enforce or apply our <a href="https://www.epiqglobal.com/en-us/terms-of-use" target="_blank">terms of use</a>, other agreements or policies, including for billing and collection purposes.</li>
        <li>If necessary, to exercise or defend against potential or actual litigation. </li>
        <li>Protect the security and integrity of the Services.</li>
        <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Epiq, our clients and others.
          This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction. </li>
        <li>In connection with the sale, assignment or other transfer of all or part of our business or assets.</li>
        <li>For any other purpose disclosed by us when you provide the information.</li>
        <li>As directed by you and/or with your consent.</li>
      </ul>
      <h4>Aggregated and Anonymized Data</h4>
      <p>We may disclose aggregated information about our users, and information that does not identify any individual without restriction. </p>
      <h4>Controlling Your Information</h4>
      <p>You can request correction, update, and deletion of your personal information.
        Please clink on the link below to <a href="https://app.smartsheet.com/b/form/8659dfa583ac4f22a9a963594d135d0a" target="_blank">submit a request:</a> To update or delete your personal information or correct an inaccuracy, we may ask you to verify your identity and cooperate with us in our effort.</p>
        <p><a href="https://app.smartsheet.com/b/form/8659dfa583ac4f22a9a963594d135d0a" target="_blank">Data Subject Request</a></p>
      <p>For general questions about the collection of personal data by Epiq or to file a complaint under the GDPR, please contact Epiq:</p>
      <p>Epiq <br/>
      Attn: Privacy Officer <br />
      501 Kansas Avenue  <br />
      Kansas City, KS 66105-1309 <br />
            <a href="mailTo:privacyshield@epiqglobal.com">privacyshield@epiqglobal.com</a><br />
      </p>
      <p>We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.</p>
        <h3>Your California Privacy Rights</h3>
      <p>If you are a California resident, California law may provide you with additional rights regarding our use of your personal information. </p>
          <p>California's "Shine the Light" law (Civil Code Section § 1798.83) permits users of our App that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to <a href="mailTo:privacyshield@epiqglobal.com">privacyshield@epiqglobal.com</a> or write us at: 501 Kansas Avenue, Kansas City, KS 66105-1309.</p>
      <p>The California Consumer Protection Act requires businesses to disclose whether they sell personal information. We do not sell personal information. </p>
      <h3>Your EEA Privacy Rights</h3>
          <p>If you are a European Economic Area (EEA) resident, European Union (EU) law may provide you with additional rights regarding our use of your personal information including objection to processing, restriction of processing, or to request portability. To exercise these rights, please contact us at <a href="mailTo:privacyshield@epiqglobal.com">privacyshield@epiqglobal.com</a>. </p>
      <p>As provided for in this Notice, we may need to transfer your personal information to countries other than the ones in which the information was originally collected or provided. When we export your data, we take all necessary steps to ensure that such export complies with applicable laws through appropriate transfer mechanism ensuring an adequate level of data protection in EU law, including standard contractual clauses.</p>
      <p>As a resident of the EU, you have the right to submit a complaint to the EU data protection authority about our collection and use of your personal information. Further, if we have collected and processed your personal information with your consent, then you may withdraw your consent anytime. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your personal information conducted in reliance on lawful processing grounds other than consent. </p>
      <h4>Data Security</h4>
      <p>We have implemented appropriate technical, physical and administrative measures to safeguard personal information from accidental loss and from unauthorized access, use, alteration, and disclosure.
        All information you provide to us is stored on secure servers behind firewalls. </p>
      <p>Our security policies and processes are independently reviewed and assessed. We maintain an industry leading compliance and security programs which are assessed by third parties. We hold a number of industry certifications including ISO 27001, Cyber Essentials, TISAX, etc. In addition to retaining these certifications, we are aligned to HIPAA, NIST, and FISMA frameworks.</p>
      <h3>Retention</h3>
      <p>We retain personal information for no longer than as is necessary in relation to the business purposes for which such personal information is collected or provided and to fulfill legal requirements. </p>
      <h3>Changes to Our Privacy Policy</h3>
      <p>If we later change our Notice, we will post those changes on our homepage so our users are always aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it.</p>
      <h3>Contact Information</h3>
      <p>To ask questions or comment about this Notice and our privacy practices, contact us at: </p>
      <h4>Epiq</h4>
      <p>Attn: Privacy Officer<br />
      501 Kansas Avenue <br />
      Kansas City, KS 66105-1309<br />
            <a href="mailTo:privacyshield@epiqglobal.com">privacyshield@epiqglobal.com</a> <br />
        </p>
      </div>
        </Paper>
        <EpiqFooterComponent footerLogo={epiqwhiteLogo} />
    </div>
  </div>


export default PrivacyStatement;
