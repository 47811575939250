import ResourceService from "../../../services/ResourceService";
import { IFieldValueLookup, IWizardRequest, IWizardSection, IWizardSummary, IWizardWarningMessage, IGridDataColumns } from "../../../models/Wizard";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import { JobMessagingConfig } from "../../../JobManager";

export const wizardSupportSteps = [
  {
    fields: [
      {
        type: "hiddenField",
        label: "resourceGroupId",
        name: "resourceGroupId",
        fieldId: "resourceGroupId",
        active: true,
        hideFieldInSummary: true,
      },
      {
        type: "hiddenField",
        label: "addToRelativityClient",
        name: "addToRelativityClient",
        fieldId: "addToRelativityClient",
        active: true,
        hideFieldInSummary: true,
      },
      {
        type: "downLoadExcelTemplate",
        label: "Download Template",
        name: "Download Excel",
        fieldId: "9.2",
        active: true,
        hideFieldInSummary: true,
        hideFieldIdLabel: true,
        addButtonLabel: "Download Template",
        description: "Enter a maximum of 50 users, one per row with last name, first name, and email. Then, save the bulk import users file.",
        exportExcelSettings: (dataItems: any) => {
          const exportItems = {
            fileName: "Relativity User Import Template",
            fields: [
              { field: "firstName", title: "FirstName" },
              { field: "lastName", title: "LastName" },
              { field: "username", title: "UserName" },
              { field: "email", title: "Email" },
            ]
          }
          return exportItems;
        }
      },
      {
        type: "fileupload",
        name: "File Name",
        fieldId: "9.3",
        hideFieldIdLabel: true,
        label: "Upload the import users file",
        description: "Enter a maximum of 50 users, one per row with last name, first name, and email. Then, save the import users file.",
        active: true,
        bulkImportMode: true,
        requiredWhenActive: true,
        restrictAutoUpload: true,
        showSupportedFileTypesString: true,
        multiple: false,
        allowedFileExtensions: ["xls", "xlsx"],
        maxFileSize: 10485760, //10MB as bytes
      }
    ],
    header: "Import Relativity Users",
    subHeader: "PROVIDE USER INFORMATION",
    description: "You can add or update up to 50 users to both Epiq Access and Relativity. To start, download the template. To fill out the import users template, enter a row for each user you want to add or update. When done, save and then upload the import users file.",
    summaryTitle: "FILE UPLOAD",
    nextCondition: {
      logic: "or",
      subConditions: [
        {
          logic: "and",
          subConditions: [
            { fieldId: "9.3", compare: "neq", value: null }
          ]
        }
      ]
    }
  },
  {
    fields: [
      {
        type: "editGridField",
        fieldId: "5.1",
        resetForField: "9.3",
        name: "Users",
        label: "Provide up to 50 users.",
        columns: [
          { field: "firstName", title: "First Name", editor: "text", validations: [{ name: "required", errorMessage: "First Name is required." }], placeHolder: "Enter first name" },
          { field: "lastName", title: "Last Name", editor: "text", validations: [{ name: "required", errorMessage: "Last Name is required." }], placeHolder: "Enter last name" },
          { field: "username", title: "Username / UPN", editor: "text", validations: [{ name: "required", errorMessage: "Username is required." }, { name: "customUserName", errorMessage: "Enter proper Email Id" }, { name: "unique", errorMessage: "User already exists with same username." }, { name: "usernameFormat", errorMessage: "Username must have '@' with something after" }], placeHolder: "Enter username", toolTipText: "To find an Epiq UPN, have user go to SharePoint, click the account icon, and give you the email address shown." },
          { field: "email", title: "Email", editor: "text", validations: [{ name: "required", errorMessage: "Email is required" }, { name: "email", errorMessage: "Enter proper Email Id" }], placeHolder: "Enter email" }

        ] as IGridDataColumns[],
        hideFieldIdLabel: true,
        maxRecordCount: 50,
        addButtonLabel: "User",
        getBulkImport: (fieldValues: IFieldValueLookup) => {
          return fieldValues["9.3"] && fieldValues["9.3"].lookupValues ? fieldValues["9.3"].lookupValues : null;
        },
        dependency: {
          logic: "or",
          subConditions: [
            { fieldId: "9.3", compare: "neq", value: null }
          ]
        }
      }
    ],
    header: "Import Relativity Users",
    subHeader: "USER INFORMATION",
    summaryTitle: "USER INFORMATION",
    description: "Verify the list of users you provided who need Epiq Access and Relativity access. If the uploaded file contained missing information, it appears at the top of the table for you to revise before continuing.",
    nextCondition: {
      logic: "and",
      subConditions: [
        { fieldId: "5.1", compare: "neq", value: null },
        { fieldId: "5.1", compare: "neq", value: [] }
      ]
    }
  },
  {
    header: "Import Relativity Users",
    subHeader: "INVITE USERS",
    summaryTitle: "INVITE INFORMATION",
    description: "Choose the type of invitation to send and who you want to receive the invitations.",
    nextCondition: {
      logic: "or",
      subConditions: [
        { fieldId: "10.0", compare: "eq", value: 2 },
        { fieldId: "10.1", compare: "eq", value: 1 },
        { fieldId: "10.3", compare: "neq", value: null }
      ]
    },
    fields: [
      {
        type: "radiogroup",
        label: "Do you want to automatically send invitation emails to these users?",
        name: "Send Invite?",
        fieldId: "10.0",
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        radioButtonOptions: [
          {
            id: 1,
            title: "Yes, send invitations to the users."
          },
          {
            id: 2,
            title: "No invitations needed."
          }
        ]
      },
      {
        type: "radiogroup",
        label: "What type of welcome invitation do you want to send?",
        name: "Invite Type",
        fieldId: "10.1",
        dependency: { fieldId: "10.0", compare: "eq", value: 1 },
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        radioButtonOptions: [
          {
            id: 1,
            title: "Standard invitation for Epiq Access."
          },
          {
            id: 2,
            title: "Custom invitation for client-branded login page."
          }
        ]
      },
      {
        type: "singleselect",
        fieldId: "10.2",
        name: "Branding",
        label: "Select the client name.",
        hideFieldIdLabel: true,
        tooltipTitle: "Select Okta Group.",
        requiredWhenActive: true,
        dependency: { fieldId: "10.1", compare: "eq", value: 2 },
        GetData: ResourceService.getResourcesUser,
        AutoSelectPreference: "first",
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            return {
              text: item.fields["DisplayName"],
              id: item.objectId
            };
          });
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {

          const filters = [{
            field: "ResourceGroupId",
            operator: "eq",
            value: fieldValues["resourceGroupId"].value
          }] as Array<FilterDescriptor>;

          if (searchText) {
            filters.push({
              field: "DisplayName",
              operator: "contains",
              value: searchText
            });
          }

          const filtersComposite = [
            {
              logic: "and",
              filters: filters
            }
          ] as CompositeFilterDescriptor[];

          return {
            take: 100,
            typeCode: "whitelabel",
            showGroupsWithDupePermissions: true,
            permissionsDelimited: "InviteWithWhitelabelSite",
            flattenResults: false,
            filters: filtersComposite
          };
        }
      },
      {
        type: "radiogroup",
        label: "Send invitation to these users.",
        name: "Invite Scope",
        fieldId: "10.3",
        dependency: {
          logic: "or",
          subConditions: [{ fieldId: "10.1", compare: "eq", value: 1 }, { fieldId: "10.2", compare: "neq", value: null }]
        },
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        radioButtonOptions: [
          {
            id: 1,
            title: "Only invite users who need to activate accounts."
          },
          {
            id: 2,
            title: "Invite all users, including those with active accounts."
          }
        ]
      }
    ]
  },
  {
    header: "Import Relativity Users",
    subHeader: "SELECT OKTA GROUP",
    summaryTitle: "OKTA GROUP",
    description: "Specify whether your company requries Epiq multi-factor authentication (MFA) as part of the login process for these users. If you know these users require MFA, choose the applicable Okta Group.",
    nextCondition: {
      logic: "or",
      subConditions: [
        { fieldId: "7.1", compare: "eq", value: 2 },
        { fieldId: "7.2", compare: "neq", value: null }
      ]
    },
    fields: [
      {
        type: "radiogroup",
        label: "Do you require multifactor authentication (MFA) for these users?",
        labelCaption: "If multi-factor authentication is required, select the existing Okta Group to authenticate the new users.",
        name: "Multi-factor authenticaion",
        fieldId: "7.1",
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        radioButtonOptions: [
          {
            id: 1,
            title: "Yes, we require MFA for these users."
          },
          {
            id: 2,
            title: "No MFA required."
          }
        ]
      },
      {
        type: "singleselect",
        fieldId: "7.2",
        resetForField: "7.1",
        name: "Okta Group",
        label: "To enable multi-factor authentication, select the existing Okta Group.",
        hideFieldIdLabel: true,
        active: false,
        tooltipTitle: "Select Okta Group.",
        requiredWhenActive: true,
        dependency: { fieldId: "7.1", compare: "eq", value: 1 },
        GetData: ResourceService.getResourcesUser,
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            return {
              text: item.fields["Group Name"],
              id: item.objectId
            };
          });
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {

          const filters = [{
            field: "ResourceGroupId",
            operator: "eq",
            value: fieldValues["resourceGroupId"].value
          }] as Array<FilterDescriptor>;
      
          if (searchText) {
            filters.push({
              field: "Group Name",
              operator: "contains",
              value: searchText
            });
          }

          const filtersComposite = [
            {
              logic: "and",
              filters: filters
            }
          ] as CompositeFilterDescriptor[];

          return {
            take: 100,
            typeCode: "oktagroup",
            showGroupsWithDupePermissions: true,
            flattenResults: false,
            filters: filtersComposite
          };
        }
      }
    ]
  }
] as Array<IWizardSection>;

export const createImportContextUsersRequest = function (valueDictionary: IFieldValueLookup) {
    const request = {} as IWizardRequest;
  
    const getFieldValue = function (id: string) {
      return valueDictionary[id] ? valueDictionary[id].value : null;
    };

    request["UsersDerivedFromResourceGroupId"] = getFieldValue("resourceGroupId");
    request["AddToRelativityClient"] = getFieldValue("addToRelativityClient");
    request["ImportUsers"] = getFieldValue("5.1");
    request["OverrideSendInvite"] = getFieldValue("10.3") === 2;
    request["SiteId"] = getFieldValue("10.2");

    const oktaValue =  getFieldValue("7.2") as number;

    request["EaOktaGroupIds"] = oktaValue ? [oktaValue] : null; 
  
    return request;
  };


  export const wizardConfirmSubmitMessage: IWizardWarningMessage = {
    title: "Submit User Import",
    getDescription: (existingFieldVals: IFieldValueLookup) => {
      let confirmMsgs = new Array<string>();
      confirmMsgs.push('Confirm that you want to submit these users to import.');
      return confirmMsgs;
    }
  };
  
  
  export const wizardSummary: IWizardSummary = {
    title: "IMPORT SUMMARY",
    description: "This summary shows the selections you made for bulk import. Please review your selections. To make changes, click the Edit pencil to return to a section. When you are satisfied with this summary, submit your user import.",
  };
  
  
  export const warningMessage: IWizardWarningMessage = {
    title: "Cancel the import wizard?",
    description:
      "Closing the wizard drops any users you are attempting to import. Do you want to cancel this wizard?"
  };


  enum JobStates {
    Import_ProcessedError = 7,
    Import_ProcessedSuccess = 8,
  }
  
  export const createJobMessagingConfig = function(callback: (success: boolean) => void) {
    return {
      genericErrorMessage: "New request data submission failed.",
      genericSuccessMessage: "New request data submitted successfully.",
      specialStatusCases: {
        statusTypes: {
          [JobStates.Import_ProcessedError]: {
            degree: "error",
            message: "Failed to import users.",
            onStatus: () => callback(false),
            statusCallbackHasNoMessage: true
          },
          [JobStates.Import_ProcessedSuccess]: {
            degree: "success",
            message: "Users were imported successfully.",
            onStatus: () => callback(true),
            statusCallbackHasNoMessage: true
          }
        }
      }
    } as JobMessagingConfig
  };