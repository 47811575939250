import * as React from "react";
import {
  MultiSelect,
  MultiSelectChangeEvent,
  MultiSelectFilterChangeEvent,
  ListItemProps,
  MultiSelectBlurEvent,
  MultiSelectFocusEvent
} from "@progress/kendo-react-dropdowns";
import DropDownListItem from "../../../models/DropDownListItem";
import Grid, { GridSize } from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import Tooltip from "@material-ui/core/Tooltip";
import "./multiSelect.scss";
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import bgMessages from '../../../Localization/en.json';
loadMessages(bgMessages, 'en-US');

const DefaultConfigurations = {
  MinSearchCharacters: 3,
  searchWidth: 3 as GridSize,
  Placeholder: "Search"
};

interface IProps {
  savedListItems?: number[];
  addSelectedListItems: (selectedItems: DropDownListItem[]) => void;
  getSearchResults: (searchText: string) => Promise<DropDownListItem[]>;
  labelText?: string;
  placeholder?: string;
  className?: string;
  minSearchCharacters?: number;
  preselected?: DropDownListItem[];
  searchWidth?: GridSize;
}

interface TagData {
  data: DropDownListItem[];
  text: string;
}

type State = {
  searchText: string;
  showDropDownOptions: boolean;
  isLoading: boolean;
  filteredListItems: DropDownListItem[];
  selectedListItems: DropDownListItem[];
  savedListItems: number[];
};

type Props = IProps;

class MultiSelectDropDown extends React.PureComponent<Props, State> {
  pendingRequest: any;
  requestStarted = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      showDropDownOptions: false,
      isLoading: false,
      searchText: "",
      filteredListItems: [],
      savedListItems: this.props.savedListItems ? this.props.savedListItems : [],
      selectedListItems: []
    };
  }

  handleChange = (event: MultiSelectChangeEvent) => {
    const values: DropDownListItem[] = event.target.value;
    const lastItem = values[values.length - 1];
    const filteredListItems = this.state.filteredListItems.filter(item => item !== lastItem);
    this.props.addSelectedListItems(values);
    this.setState({
      filteredListItems: filteredListItems,
      selectedListItems: values,
      showDropDownOptions: values.length > 0
    });
  };

  filterChange = async (event: MultiSelectFilterChangeEvent) => {
    const searchText = event.filter.value.trim();
    const minSearchCharacters = this.props.minSearchCharacters
      ? this.props.minSearchCharacters
      : DefaultConfigurations.MinSearchCharacters;

    if (searchText.length >= minSearchCharacters) {
      this.setState({ searchText: searchText, isLoading: true });

      await this.getSearchResults(searchText);
    }

    if (searchText.length === 0 && minSearchCharacters !== 0) {
      this.setState({ showDropDownOptions: false });
    }
  };

  getSearchResults = async (searchText: string) => {
    const { savedListItems, selectedListItems } = this.state;

    if (this.requestStarted) {
      clearTimeout(this.pendingRequest);
      this.pendingRequest = setTimeout(() => {
        this.getSearchResults(searchText);
      }, 300);
      return;
    }

    this.requestStarted = true;
    let searchResults: DropDownListItem[] = await this.props.getSearchResults(searchText);
    const filteredList = this.removeSelectedListItems(searchResults, [
      ...savedListItems,
      ...selectedListItems.map(item => item.id)
    ]);
    this.setState({ filteredListItems: filteredList, showDropDownOptions: true, isLoading: false });
    this.requestStarted = false;
  };

  removeSelectedListItems = (searchResults: DropDownListItem[], selectedListItems: number[]) => {
    for (let i = searchResults.length - 1; i >= 0; i--) {
      for (let j = 0; j < selectedListItems.length; j++) {
        if (searchResults[i] && searchResults[i].id === (selectedListItems[j] as number)) {
          searchResults.splice(i, 1);
        }
      }
    }

    return searchResults;
  };

  focusedItemIndex = (data: any, inputText: string, textField?: string) => {
    let text = inputText.toLowerCase().trim();
    return data.findIndex((item: any) =>
      String(textField ? item[textField] : item)
        .toLowerCase()
        .includes(text)
    );
  };

  itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    if (!itemProps.selected) {
      const itemChildren = this.customDropDownOption(itemProps.dataItem);
      return React.cloneElement(li, li.props, itemChildren);
    }

    return null;
  };

  customDropDownOption = (dataItem: DropDownListItem) => {
    const shortName = dataItem.name;
    const description = dataItem.description;

    return (
      <div key={dataItem.id} className="multi-select-list-items">
        <div className="item-name">
          <em title={shortName}>{shortName}</em>
        </div>
        {description && (
          <p className="description">
            {" "}
            <em title={description}>{description}</em>{" "}
          </p>
        )}
      </div>
    );
  };

  tagRender = ({ data }: TagData, li: React.ReactElement<HTMLLIElement>) => {
    return React.cloneElement(li, li.props, [
      <Tooltip title={data[0].title.length > 10 ? data[0].title : ""} placement="top-start" key={data[0].id} arrow>
        <span id={data[0].id.toString()} key={data[0].id}>
          {" "}
          {data[0].name.length > 10 ? `${data[0].name.slice(0, 10)} ...` : data[0].name}{" "}
        </span>
      </Tooltip>,
      li.props.children[1]
    ]);
  };

  showDropDownOptions = () => {
    this.setState({ showDropDownOptions: true });
  };

  hideDropDownOptions = () => {
    this.setState({ showDropDownOptions: false });
  };

  render() {
    const { isLoading, showDropDownOptions, filteredListItems } = this.state;
    const { labelText, className, placeholder } = this.props;

    return (
      <div>
        <Grid container className={className}>
          {<Grid item xs={this.props.searchWidth || DefaultConfigurations.searchWidth}>
            {labelText ? <label> {labelText} </label> : ""}
            <div className="multi-select-wrapper">
              <span className="search-wrapper">
                <SearchIcon />
              </span>
              <LocalizationProvider language="en-US">
                <IntlProvider locale="en" >
                  <MultiSelect
                    data={filteredListItems}
                    onChange={this.handleChange}
                    filterable={true}
                    onFilterChange={this.filterChange}
                    loading={isLoading}
                    itemRender={this.itemRender}
                    textField="name"
                    dataItemKey="id"
                    placeholder={placeholder ? placeholder : DefaultConfigurations.Placeholder}
                    opened={filteredListItems.length > 0 && showDropDownOptions}
                    onBlur={this.hideDropDownOptions}
                    onFocus={this.showDropDownOptions}
                    focusedItemIndex={this.focusedItemIndex}
                    tagRender={this.tagRender}
                    defaultValue={this.props.preselected}
                  />
                </IntlProvider>
              </LocalizationProvider>
            </div>
          </Grid>
          }
        </Grid>
      </div>
    );
  }
}

export default MultiSelectDropDown;
