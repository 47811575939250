export default class PowerBiHelper {
  static setTokenExpirationListener(tokenExpiration: string, refreshFunc: () => void, minutesToRefresh = 2) {
    // get current time
    var currentTime = Date.now();
    var expiration = Date.parse(tokenExpiration);
    var safetyInterval = minutesToRefresh * 60 * 1000;

    // time until token refresh in milliseconds
    var timeout = expiration - currentTime - safetyInterval;

    // if token already expired, generate new token and set the access token
    if (timeout <= 0) {
      refreshFunc();
    }
    // set timeout so minutesToRefresh minutes before token expires, token will be updated
    else {
      console.log("Report Embed Token will be updated in " + timeout + " milliseconds.");
      return setTimeout(function () {
        refreshFunc();
      }, timeout);
    }
  }
}
