import React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../store";
import * as SessionStore from "../../../store/Session";
import Paper from "@material-ui/core/Paper";
import { DataLoader } from "../../common/DataLoader";
import "./JobDetails.scss";
import CollapsibleSection from "../common/CollapsibleSection";
import BreadCrumb, { IBreadCrumbPart } from "../../common/BreadCrumb";
import { IJobDetailResult } from "../../../models/JobModels";
import JobsService, { IJobRequestData } from "../../../services/JobService";
import GridRefreshButton from "../../common/GridRefreshButton";

interface IProps {
    match?: any;
    history: any;
}

type Props = IProps & SessionStore.ISessionState;

type State = {
    isLoading: boolean;
    jobDetails: IJobDetailResult;
    hasError: boolean;
    breadcrumbParts?: Array<IBreadCrumbPart>;
    refresh: number;
};

class JobDetail extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const sourceTitle = "Job Queue";

        this.state = {
            isLoading: true,
            hasError: false,
            jobDetails: {
                description: "",
                jobId: "",
                createdDate: "",
                lastModifiedDate: "",
                jobState: "",
                jobTypeName: "",
                jobTypeDisplay: "",
                userName: "",
                lastName: "",
                firstName: "",
                resourceGroupName: "",
                data: ""
            },
            breadcrumbParts: [{ title: sourceTitle }, { title: this.props.match.params.jobId }],
            refresh: 0
        };
    }

    async dataFetch() {
        if(!this.state.isLoading) {
            this.setState({isLoading: true});
        }

        const result = await JobsService.getJobDetails({ jobId: this.props.match.params.jobId } as IJobRequestData);
        const breadcrumbParts = [... this.state.breadcrumbParts];
        let newJobDetailsState = this.state.jobDetails;

        if (result.ok) {
            newJobDetailsState = result.data;

            const statusClass = `job-breadcrumb-jobstate ${newJobDetailsState.jobState
                .toLowerCase()
                .trim()
                .replace(/\s+|[,\/]/g, "-")}`;

            breadcrumbParts[1].element = <span className={statusClass}>{newJobDetailsState.jobState}</span>
        } else {
            newJobDetailsState.jobState = "Job doesn't exist or you do not have access.";
        }

        this.setState({ isLoading: false, hasError: !result.ok, jobDetails: newJobDetailsState, breadcrumbParts });
    }

    handleCloseClick() {
        const path = "/administration/job/jobqueue";
        const url = this.props.history.location.state ? {
            pathname: path, state: { dataState: this.props.history.location.state.dataState }
        } : { pathname: path }

        this.props.history.push(url);
    }

    render() {
        return (
            <>
                <div className="job-details-container">
                    <div className="div-header">
                        <div>
                            <div className="tech-header">Job Details</div>
                            <div className="tech-subHeader">View information about the job.</div>
                        </div>
                        <GridRefreshButton onRefreshGridClick={() => this.setState({refresh: this.state.refresh + 1})} />
                    </div>
                    <Paper>
                        <div className="job-details-wrapper">
                            <div className="details-header-container">
                                <BreadCrumb path={this.state.breadcrumbParts} />
                                <span className="k-icon k-i-x k-icon-32" onClick={this.handleCloseClick.bind(this)}></span>
                            </div>

                            {!this.state.hasError && !this.state.isLoading && (<div className="job-details-container">
                                <div className="job-details">
                                    <div className="info-section">
                                        <div className="info-item">
                                            <div className="info-label">Job Type</div>
                                            <div>
                                                {this.state.jobDetails.jobTypeDisplay}
                                            </div>
                                        </div>
                                        <div className="info-item">
                                            <div className="info-label">Submitted By</div>
                                            <div>{this.state.jobDetails.lastName}, {this.state.jobDetails.firstName}</div>
                                        </div>
                                        <div className="info-item">
                                            <div className="info-label">Submitted Date</div>
                                            <div>{this.state.jobDetails.createdDate}</div>
                                        </div>
                                        <div className="info-item">
                                            <div className="info-label">Last Updated</div>
                                            <div>{this.state.jobDetails.lastModifiedDate}</div>
                                        </div>
                                    </div>
                                    <div className="info-section">
                                        <div className="info-item">
                                            <div className="info-label">Description</div>
                                            <div className="long-description">
                                                {this.state.jobDetails.description}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <CollapsibleSection title="Data"  >
                                        <>
                                            <div className="data-container">
                                                <pre>{JSON.stringify(JSON.parse(this.state.jobDetails.data), undefined, 2)}</pre>
                                            </div>
                                        </>
                                    </CollapsibleSection>
                                </div>
                            </div>)}
                        </div>
                    </Paper>
                    <DataLoader
                        loading={this.state.isLoading}
                        dataState={this.state}
                        getDataAsync={this.dataFetch.bind(this)}
                        dataContainerClassesForScope=".request-details-wrapper"
                        strongArmRefreshCount={this.state.refresh}
                    />
                </div>
            </>
        );
    }
}

export default connect((state: IApplicationState) => ({ ...state.sessionState }), null)(JobDetail);
