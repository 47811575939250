import React, { useState } from "react";
import { IExcelImportType } from "../../../../models/ResourceModels";
import DownloadExcelTemplate from '../../common/DownloadExcelTemplate';

const DownloadTemplate = () => {
  const downLoadInfo = <div><h2>Download and Complete Template</h2>
                        <p>
                        To start, download the import resources template. Complete a row to add a resource 
                        or to update an existing resource group, and save the file. If you need time to complete the template,
                        close this window and click IMPORT when ready to proceed.
                        </p>
                        <p>If the import resources file is complete, click NEXT.</p>
                        </div >;
 
  let excelImportTypes: Array<IExcelImportType> = new Array<IExcelImportType>();

  excelImportTypes.push({ keyField: "Project", valueField: ["ProjectNumber", "ProjectName", "SFDCProjectId"], fileName: "Project Template.xls" });
  excelImportTypes.push({ keyField: "Sales Contract Number", valueField: ["SAPClientNumber", "SAPClientName", "SAPContractId"], fileName: "Contract Template.xls" });

  return ( 
    <DownloadExcelTemplate
      templateDescription={downLoadInfo}
      downloadBtnLabel="Download Template"
      excelImportTypes={excelImportTypes}
       />
  );
};

export default DownloadTemplate;
