import React, { useState, useRef, useEffect } from "react";
import {
  Dialog, DialogActionsBar
} from "@progress/kendo-react-dialogs";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Button, CircularProgress, PropTypes } from "@material-ui/core";
import { IAgreementAcceptanceModel } from "../../models/MessageModel";
import "./Agreements.scss";
import ReactMarkdown from 'react-markdown';
import { AgreementDecision } from "../../services/AgreementService";
import Tooltip from "@material-ui/core/Tooltip";
interface IDialogBoxProps {
  toggleDialogEvent?: (decision: AgreementDecision) => void;
  diagCloseEvent?: (event: React.MouseEvent<HTMLElement>) => void;
  acceptButtonLabelText?: string;
  declineButtonLabelText?: string;
  closeButtonLabelText?: string;
  color?: PropTypes.Color;
  agreement?: IAgreementAcceptanceModel
  isLoading?: boolean;
  modalWidth?: string | number;
}

const LinkRenderer = (props: any) => {
  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  );
}

const AgreementDialog: React.FunctionComponent<IDialogBoxProps> = props => {
  const [bottom, setBottom] = useState(false);
  const ref = useRef(null);

  const handleScroll = (e: any) => {
    const enablebutton = !bottom ? Math.abs(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) < 200  : bottom;
    setBottom(enablebutton)
  };

  useEffect(() => {
    if (ref && ref.current) {
      const hasOverflow = ref.current.scrollHeight > ref.current.clientHeight;
      setBottom(!hasOverflow);
    }
  }, [props.isLoading])

  return (
    <span className="dialog-btn">
      {(
        <Dialog
          closeIcon={false}
          aria-labelledby="customized-dialog-title"
          className="modal agreement-acceptance-modal"
          width={props.modalWidth}
        >
          <DialogTitle className="modal-title">
            {props.agreement.title}
          </DialogTitle>
          <DialogContent>
            <div className="agreement">
              <div className="agreement-content" onScroll={handleScroll} spellCheck={false} ref={ref} >
                {!props.isLoading ? (
                  <ReactMarkdown components={{ a: LinkRenderer }} children={props.agreement.message} />
                ) : (<div className="agreement-loding">
                  <CircularProgress />
                </div>)}
              </div>
            </div>
          </DialogContent>
          <DialogActionsBar>
            <div className="agreement-action-buttons">
              {props.closeButtonLabelText && (
                <Button
                  variant="contained"
                  className="btn-contined"
                  color={props.color}
                  name={props.closeButtonLabelText}
                  onClick={props.diagCloseEvent}
                >
                  {props.closeButtonLabelText}
                </Button>)}
              {props.declineButtonLabelText ? (
                <Button
                  variant="contained"
                  className="btn-contined"
                  color={props.color}
                  name={props.declineButtonLabelText}
                  onClick={() => props.toggleDialogEvent(AgreementDecision.Decline)}
                  disabled={props.isLoading}
                >
                  {props.declineButtonLabelText}
                </Button>
              ) : (
                ""
              )}
              {props.acceptButtonLabelText ? (
                <Tooltip classes={{ tooltip: "mui-tooltip-maxwidth-300", }} disableHoverListener={bottom} enterDelay={2} title={<span style={{ fontSize: "14px" }} >Read through and scroll to the bottom of this agreement, then click ACCEPT.</span>} placement="top" arrow>
                  <div style={{ display: "inline-block" }}>
                    <Button
                      variant="contained"
                      className="btn-contined"
                      color="primary"
                      name={props.acceptButtonLabelText}
                      onClick={() => props.toggleDialogEvent(AgreementDecision.Accept)}
                      disabled={props.isLoading || !bottom}
                    >
                      {props.acceptButtonLabelText}
                    </Button>
                  </div>
                </Tooltip>
              ) : (
                ""
              )}
            </div>
          </DialogActionsBar>
        </Dialog>
      )}
    </span>
  );
};
export default AgreementDialog;
