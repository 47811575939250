import * as ApplicationState from "./store/AppStatus";

export interface IApplicationStateManager {
  checkStateAsync: () => void;
  checkFrequencyMS: number;
  addWatcherAsync: (watcherCode: string) => void;
  removeWatcher: (watcherCode: string) => void;
}

let appStateManagerInstance: ApplicationStateManager = null;

export class ApplicationStateManager implements IApplicationStateManager {
  private readonly store: any;
  trackers: Set<string>;
  interval: number;
  initDone: boolean;
  loadingInit: boolean;

  constructor(store?: any) {
    if (appStateManagerInstance) {
      return appStateManagerInstance;
    } else if (store == null) {
      throw new Error("The store for application state manager must be instantiated at least once");
    }

    appStateManagerInstance = this;
    this.initDone = false;
    this.loadingInit = false;
    this.store = store;
    this.interval = null;
    this.trackers = new Set<string>();
  }

  get checkFrequencyMS() {
    return this.store.getState().appStatus.checkFrequencySeconds * 1000;
  }

  async checkStateAsync() {
    const dispatch = ApplicationState.actionCreators.getAppStatus();
    this.store.dispatch(ApplicationState.actionCreators.getAppStatus());
    return await dispatch;
  }

  async addWatcherAsync(watcherCode: string) {
    console.log("ADD this.trackers.size", this.trackers.size);
    this.trackers.add(watcherCode);
    this.startIntervalAsync();
    console.log("POST ADD this.trackers.size", this.trackers.size);
  }

  removeWatcher(watcherCode: string) {
    console.log("===== REMOVE watcher, size:", this.trackers.size);
    this.trackers.delete(watcherCode);
    if (this.trackers.size == 0) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  private async startIntervalAsync() {
    const appStatus = this.store.getState().appStatus.status;
    if (!appStatus) {
      await this.checkStateAsync();
    }
    if (this.interval == null) {
      this.interval = window.setInterval(() => this.checkStateAsync(), 10000);
    }
  }
}
