import React, { useState } from "react";
import DownloadExcelTemplate from '../../common/DownloadExcelTemplate';

const DownloadTemplate = () => {
  const downLoadInfo = <div><h2>Download and Complete Template</h2>
                       <p>
                          To start, download the import users template. Complete a row to add a user
                          or to update an existing user, and save the file. If you need time to complete the template,
                          close this window and click IMPORT when ready to proceed.
                       </p>
                       <p>If the import users file is complete, click NEXT.</p>
                       </div >;
  const columnTitles = ["FirstName", "LastName","Username", "Email", "ApplicationName", "AppAssignOrRemove", "SendEmailInvite", "OktaGroupId", "EpiqAccessUserGroup"];

  return (
    <DownloadExcelTemplate
      templateDescription={downLoadInfo}
      downloadBtnLabel="Download Template"
      fileName="Import User Template.xls"
      excelColumnNames={columnTitles} />
  );
};

export default DownloadTemplate;
