import { ServiceBase, ResponseMessages, ServiceResponseJson, ServiceResponse, ServiceResponseData, ServiceResponseStr } from "./ServiceBase";
import { OktaChangePasswordRequest, PasswordResetEmailInvitation } from "../models/ChangePasswordModel";
import AuthenticationStorageApi from "../apis/storage/AuthenticationStorageApi";
import { IFetchssoOktaGroup } from "../models/UserModels";
import { ICheckObjectCreationModel } from "../models/ResourceModels";

const UpdatePasswordMessages: ResponseMessages = {
  FailedMessage: "Failed to update password.",
  SuccessMessage: "Successfully updated password."
};

const PasswordPolicyMessages: ResponseMessages = {
  FailedMessage: "Failed to get password policies.",
  SuccessMessage: "Successfully fetched the password policies."
};

const SSoGroupMessages: ResponseMessages = {
  FailedMessage: "Failed to fetch sso groups.",
  SuccessMessage: "Successfully fetched sso groups."
};

const GetDisableDeactivactionStatusMessages: ResponseMessages = {
  FailedMessage: "Could not check whether user can disable deactivate.",
  SuccessMessage: "Succeeded in check whether user can disable deactivate."
};

const UserFactorsMessages: ResponseMessages = {
  FailedMessage: "Failed to get user factor.",
  SuccessMessage: "Successfully fetched user factor."
};

const ResetUserFactorsMessages: ResponseMessages = {
  FailedMessage: "Failed to reset user factor.",
  SuccessMessage: "Successfully reset user factor."
};

const GetCredMessages: ResponseMessages = {
  FailedMessage: "Failed to credential info.",
  SuccessMessage: "Successfully retrieved cred info."
};

const ResetPasswordEmailMessages: ResponseMessages = {
  FailedMessage: "Failed to send reset password email.",
  SuccessMessage: "Successfully sent reset password email."
};
export default class UserService extends ServiceBase {
  static async UpdatePassword(oktaChangePasswordRequest: OktaChangePasswordRequest) {
    const result = await fetch(`${UserService.getBaseUri()}changepassword`, {
      body: this.toPostBody(oktaChangePasswordRequest),
      ...this.fetchPostOptions()
    })
      .then(response => this.handleResponseJson(response, UpdatePasswordMessages))
      .catch(response => this.handleResponseJson(response, UpdatePasswordMessages));

    return result;
  }

  static async GetPasswordPolicies() {
    const url = `${UserService.getBaseUri()}passwordpolicies?oktaUserId=${AuthenticationStorageApi.getUserId()}`;
    const result: ServiceResponseJson = await fetch(url, { ...this.fetchOptions() })
      .then(async response => await this.handleResponseJson(response, PasswordPolicyMessages))
      .catch(async response => await this.handleResponseJson(response, PasswordPolicyMessages));

    return result;
  }

  //static async getSsoGroups(params: GridParams, abortSignal?: AbortSignal) {
  static async getSsoGroupsByUser(take: number, groupName?: string, userIdpId?: string, restrictContextId?: number, abortSignal?: AbortSignal) {

    const url = UserService.getBaseUri() + "getOktaGroupsByUser?take=" + take + (groupName && groupName.length ? "&groupName=" + groupName : "")
      + (userIdpId ? "&userIdpId=" + userIdpId : "")
      + (restrictContextId ? "&restrictContextId=" + restrictContextId : "");
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async (response) => await this.handleResponseJson(response, SSoGroupMessages))
      .catch(async (response) => await this.handleResponseJson(response, SSoGroupMessages));

    return result;
  }

  static async getSsoGroups(take: number, groupName?: string, abortSignal?: AbortSignal) {

    const url = UserService.getBaseUri() + "getOktaGroups?take=" + take + (groupName && groupName.length ? "&groupName=" + groupName : "");
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async (response) => await this.handleResponseJson(response, SSoGroupMessages))
      .catch(async (response) => await this.handleResponseJson(response, SSoGroupMessages));

    return result;
  }

  static async getSsoUsersOktaGroups(take: number, groupName?: string, abortSignal?: AbortSignal) {

    const url = UserService.getBaseUri() + "getUsersOktaGroups?take=" + take + (groupName && groupName.length ? "&groupName=" + groupName : "");
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async (response) => await this.handleResponseJson(response, SSoGroupMessages))
      .catch(async (response) => await this.handleResponseJson(response, SSoGroupMessages));

    return result;
  }

  static async getSsoUsers(ssoGroupId?: string, abortSignal?: AbortSignal) {

    const url = UserService.getBaseUri() + `getOktaUsersByGroup?groupId=${ssoGroupId}`;
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async (response) => await this.handleResponseJson(response, SSoGroupMessages))
      .catch(async (response) => await this.handleResponseJson(response, SSoGroupMessages));

    return result;
  }

  static async getSsoGroup(ssoGroupId?: string, abortSignal?: AbortSignal) {

    const url = UserService.getBaseUri() + `getOktaGroup?groupId=${ssoGroupId}`;
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async (response) => await this.handleResponseJson(response, SSoGroupMessages))
      .catch(async (response) => await this.handleResponseJson(response, SSoGroupMessages));

    return result;
  }

  static async createEAUserGroupFromSso(ssoGroupId: string, abortSignal?: AbortSignal) {
    const result = await fetch(`${UserService.getBaseUri()}addOktaGroupAsResource`, {
      body: this.toPostBody(ssoGroupId),
      ...this.fetchPostOptions()
    })
      .then(response => this.handleResponseJson(response, UpdatePasswordMessages))
      .catch(response => this.handleResponseJson(response, UpdatePasswordMessages));

    return result;
  }

  static async getUserFactors(userIdpId: string, abortSignal?: AbortSignal) {

    const url = UserService.getBaseUri() + `getUserFactorsAsync?userIdpId=${userIdpId}`;
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async (response) => await this.handleResponseJson(response, UserFactorsMessages))
      .catch(async (response) => await this.handleResponseJson(response, UserFactorsMessages));

    return result;
  }

  static async resetUserFactors(userIdpId: string, factorId: string) {

    const url = UserService.getBaseUri() + `resetUserFactorsAsync?userIdpId=${userIdpId}&factorId=${factorId}`;
    const result: ServiceResponseJson = await fetch(url, this.fetchDeleteOptions())
      .then(async (response) => await this.handleResponseJson(response, ResetUserFactorsMessages))
      .catch(async (response) => await this.handleResponseJson(response, ResetUserFactorsMessages));

    return result;
  }

  static async getCredInfo(credentialKey: string) {

    const url = UserService.getBaseUri() + `getCredentialInfo?credentialKey=${credentialKey}}`;
    const result: ServiceResponseJson = await fetch(url, this.fetchDeleteOptions())
      .then(async (response) => await this.handleResponseJson(response, GetCredMessages))
      .catch(async (response) => await this.handleResponseJson(response, GetCredMessages));

    return result;
  }

  static async getOktaGroups(params: IFetchssoOktaGroup, abortSignal?: AbortSignal) {
    const url = UserService.getBaseUri() + "getOktaGroups?take=" + params.take + (params.groupName && params.groupName.length > 0 ? "&groupName=" + params.groupName : "");
    const result: ServiceResponseJson = await fetch(url, ServiceBase.fetchOptions(abortSignal))
      .then(async (response) => await ServiceBase.handleResponseJson(response, SSoGroupMessages))
      .catch(async (response) => await ServiceBase.handleResponseJson(response, SSoGroupMessages));

    return result;
  }

  static async canCreateUser(email: string, abortSignal?: AbortSignal) {
    const url = UserService.getBaseUri() + "canCreateUser?email=" + email;
    const result: ServiceResponseData<ICheckObjectCreationModel> = await fetch(url, ServiceBase.fetchOptions(abortSignal))
      .then(async (response) => await ServiceBase.handleResponseData<ICheckObjectCreationModel>(response, SSoGroupMessages))
      .catch(async (response) => await ServiceBase.handleResponseData<ICheckObjectCreationModel>(response, SSoGroupMessages));

    return result;
  }

  static async checkAdminCanDeactivateUser(userId: number, userIdpId: string) {
    const url = `${UserService.getBaseUri()}checkAdminCanDeactivateUser?userId=${userId}&userIdpId=${userIdpId}`;

    const result: ServiceResponseStr = await fetch(url, ServiceBase.fetchOptions())
      .then(async response => await ServiceBase.handleResponseStr(response, GetDisableDeactivactionStatusMessages))
      .catch(async response => await ServiceBase.handleResponseStr(response, GetDisableDeactivactionStatusMessages));

    return result;
  }

  static async sendPasswordResetEmailInvitation(passwordResetEmailInvitation: PasswordResetEmailInvitation) {
    const result = await fetch(`${UserService.getBaseUri()}sendPasswordResetEmail`, {
      body: this.toPostBody(passwordResetEmailInvitation),
      ...this.fetchPostOptions()
    })
      .then(response => this.handleResponseJson(response, ResetPasswordEmailMessages))
      .catch(response => this.handleResponseJson(response, ResetPasswordEmailMessages));

    return result;
  }

  static getBaseUri() {
    return `${(<any>window).ApplicationVariables.apiUri}users/`;
  }
}
