import React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../../store";
import CommonHelper from "../../../common/utilities/CommonHelper";
import ResourceServices from "../../../../services/ResourceService";
import { ResourceGroupDtls } from "../../../../models/ResourceModels";
import { formatDate } from "@telerik/kendo-intl";
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { GridColumnProps, GridFilterCellProps } from "@progress/kendo-react-grid";
import { AdvancedGrid, IGridParams } from "../../../common/grid/AdvancedGrid";
import { NavLink } from "react-router-dom";
import "./ResourceGroups.scss";
import { FilterDescriptor } from "@progress/kendo-data-query";
import * as SessionStore from "../../../../store/Session";
import { DropDownFilter } from "../../../common/grid/filters/DropDownFilter";

const GridConfigurations = {
  PageSize: 100,
  OrderBy: "groupName",
  Ascending: "asc",
  Descending: "desc"
};

interface IProps {
  history: any;
  multiFieldFilterDelimiter?: string;
}

interface IResourceGroupsFetchResult {
  totalResourceGroups: number;
  resourceGroups: Array<ResourceGroupDtls>;
}

type Props = IProps & SessionStore.ISessionState;

type State = {
  isLoading: boolean;
  hasError: boolean;
  dataState: IGridParams;
  fetchingResourceGroups: boolean;
  fetchResults: IResourceGroupsFetchResult;
};

class ResourceGroupsList extends React.PureComponent<Props, State> {
  columns: Array<GridColumnProps>;

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
      hasError: false,
      dataState: {
        skip: 0,
        take: GridConfigurations.PageSize,
        sort: [{ field: GridConfigurations.OrderBy, dir: "asc" }]
      },
      fetchingResourceGroups: false,
      fetchResults: {
        totalResourceGroups: 0,
        resourceGroups: new Array<ResourceGroupDtls>()
      }
    };

    this.columns = new Array<GridColumnProps>(
      {
        field: "groupName",
        title: "RESOURCE GROUP NAME",
        cell: this.props.sessionData.permissions.has("EpiqAdminGetResourceGroup") ? this.resourceGroupName : this.resourceGroupNameNoLink,
        filterable: true,
        filter: "text"
      },
      {
        field: "epiqOnly",
        title: "EPIQ Admin Only",
        cell: this.epiqAdminOnly,
        filterCell: (props: GridFilterCellProps) => (
          <DropDownFilter
            {...props}
            data={[{ name: "Epiq Admin", id: 1 }, { name: "Non-Epiq Admin", id: 0 }]}
            textField="name"
            defaultSelectedOption={null}
          />
        ),
        filterable: true,
        filter: "text"
      },
      {
        field: "automationFilter",
        title: "Automated",
        cell: this.automated,
        filterCell: (props: GridFilterCellProps) => (
          <DropDownFilter
            {...props}
            data={[{ name: "Yes", id: true }, { name: "No", id: false }]}
            textField="name"
            defaultSelectedOption={null}
          />
        ),
        filterable: true,
        filter: "text"
      },
      {
        field: "createdDate",
        title: "DATE CREATED",
        cell: this.createdDate,
        filterable: true,
        filter: "date"
      },
      { field: "description", title: "DESCRIPTION", cell: this.description, filterable: true, filter: "text" }
    );
  }

  async getResourceGroups(dataState: IGridParams) {
    let isCreatedDateFilterExists = false;
    let createdDateValue: Date;
    const filters = dataState.filters
      ? dataState.filters.map(compositeFilter => {
        const epiqAdminOnlyFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "epiqOnly"
        ) as FilterDescriptor;

        if (epiqAdminOnlyFilter) {
          const epiqAdminOnlyFilterValue = epiqAdminOnlyFilter.value
            ? epiqAdminOnlyFilter.value.id == 0 || epiqAdminOnlyFilter.value.id == 1
              ? epiqAdminOnlyFilter.value.id == 0
                ? false
                : true
              : null
            : false;

          return {
            ...compositeFilter,
            filters: [
              {
                ...epiqAdminOnlyFilter,
                field: "epiqOnly",
                operator: "eq",
                value: epiqAdminOnlyFilterValue
              }
            ]
          };
        }

        const automatedFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "automationFilter"
        ) as FilterDescriptor;

        if (automatedFilter) {
          
          const automatedFilterValue = automatedFilter.value.id;

          if (automatedFilterValue != null) {
            return {
              ...compositeFilter,
              filters: [
                {
                  ...automatedFilter,
                  field: "automationFilter",
                  operator: automatedFilterValue == true ? "neq" : automatedFilter.operator,
                  value: null
                }
              ]
            };
          }
        }

        const createdDateFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "createdDate"
        ) as FilterDescriptor;

        if (createdDateFilter) {
          const dateFilterValue = createdDateFilter.value;

          if (!isNaN(new Date(dateFilterValue).getFullYear()) && new Date(dateFilterValue).getFullYear() > 1970) {
            createdDateFilter.operator = 'eq';
          }

          createdDateValue = typeof dateFilterValue === "string" ? new Date("01/01/1901") : ((createdDateFilter.operator === 'isnull' || createdDateFilter.operator === 'isnotnull') ? null : new Date(dateFilterValue));

          if (createdDateFilter.operator === 'isnull' || createdDateFilter.operator === 'isnotnull') {
            return {
              ...compositeFilter,
              filters: [
                {
                  ...createdDateFilter,
                  field: "createdDate",
                  operator: typeof dateFilterValue === "string" && createdDateFilter.operator === 'isnotnull' ? "eq" : createdDateFilter.operator,
                  value: createdDateValue
                }
              ]
            };
          }

          isCreatedDateFilterExists = true;

          return {
            ...compositeFilter,
            filters: [
              {
                ...createdDateFilter,
                field: "createdDate",
                operator: "gte",
                value: new Date(createdDateValue.toUTCString())
              }
            ]
          };
        }

        return compositeFilter;
      })
      : [];

    if (isCreatedDateFilterExists) {
      filters.push({
        logic: "and",
        filters: [
          {
            field: "createdDate",
            operator: "lt",
            value: new Date(new Date(createdDateValue.setUTCDate(createdDateValue.getUTCDate() + 1)).toUTCString())
          }
        ]
      });
    }

    this.setState({ ...this.state, fetchingResourceGroups: true }, async () => {
      const result = await ResourceServices.getResourceGroupsList({ ...dataState, filters: filters });

      if (result.ok && result.data) {
        this.setState({
          ...this.state,
          hasError: !result.ok,
          fetchResults: {
            ...this.state.fetchResults,
            resourceGroups: result.data.results,
            totalResourceGroups: result.data.count
          }
        });
      }

      this.setState({ ...this.state, fetchingResourceGroups: false });
    });
  }

  resourceGroupName(props: any) {
    return (
      <td>
        <div className="group-details">
          <div className="group-name">
            <NavLink to={`/administration/resourceGroupDetails/${props.dataItem.id}`}>
              <em title={props.dataItem.groupName}> {props.dataItem.groupName} </em>
            </NavLink>
          </div>
        </div>
      </td>
    );
  }

  resourceGroupNameNoLink(props: any) {
    return (
      <td>
        <div className="group-details">
          <div className="group-name">
            <em title={props.dataItem.groupName}> {props.dataItem.groupName} </em>
          </div>
        </div>
      </td>
    );
  }

  createdDate(props: any) {
    let date = "-";
    if (props.dataItem.createdDate) {
      let createdDate = props.dataItem.createdDate;
      let localDateTime = CommonHelper.convertUTCDateToLocalDate(createdDate);
      date = formatDate(localDateTime, "g");
    }
    return <td>{date}</td>;
  }

  description(props: any) {
    return <td className="description">{props.dataItem.description ? props.dataItem.description : "-"}</td>;
  }

  epiqAdminOnly(props: any) {
    return <td>{props.dataItem.epiqOnly ? "Epiq Admin" : "Non-Epiq Admin"}</td>;
  };

  automated(props: any) {
    return <td>{props.dataItem.automationFilter ? "Yes" : "No"}</td>;
  };

  handleCreateResourceGroupClick() {
    this.props.history.push("/administration/resourceGroupDetails/0");
  }

  gridToolBarRender(): JSX.Element {
    return (
      <>
        {this.props.sessionData.permissions.has("EpiqAdminCreateResourceGroup") && <Button
          className="add-resource-group-btn"
          icon={"plus"}
          primary={true}
          onClick={this.handleCreateResourceGroupClick.bind(this)}
        >
          Resource Group
        </Button>}
      </>
    );
  }

  render() {
    const { hasError, dataState, fetchingResourceGroups, fetchResults } = this.state;

    return (
      <div className="resource-group-list">
        <Tooltip openDelay={2} position="right">
          <AdvancedGrid
            showErrorState={hasError}
            showLoadingIndicator={fetchingResourceGroups}
            data={fetchResults.resourceGroups}
            dataFetch={this.getResourceGroups.bind(this)}
            dataState={dataState}
            columns={this.columns}
            paging={false}
            totalRecords={{ value: fetchResults.totalResourceGroups, label: "Resource Groups" }}
            noRecordsRender={<p>No resource groups found.</p>}
            noLoadOnMount={true}
            filteredDataOnly={<p>Please filter to search for resource groups.</p>}
            multiFieldFilterDelimiter="|"
            filterOperators={{
              text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
              date: [{ text: "grid.filterIsNotNullOperator", operator: "isnotnull" }, { text: "grid.filterIsNullOperator", operator: "isnull" }]
            }}
            gridToolbarContent={this.gridToolBarRender()}
          />
        </Tooltip>
      </div>
    );
  }
}

//export default ResourceGroupsList;
export default connect((state: IApplicationState) => ({ ...state.sessionState }), {})(ResourceGroupsList as any);
