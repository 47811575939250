import React, { useState, useEffect, useRef } from "react";
import { GridColumnProps, GridFilterChangeEvent } from "@progress/kendo-react-grid";
import { IGridParams, IGridStateMerged, AdvancedGrid } from "../../common/grid/AdvancedGrid";
import CommandCell from "../../common/grid/columns/CommandCell";
import NotificationService from "../../../services/NotificationService";
import AdminContextService from "../../../services/AdminContextService";
import CommonHelper from "../../common/utilities/CommonHelper";
import { IRelClientSelectable, IRelInstanceSelectable } from "../../../models/RelativityModel";
import AdminService from "../../../services/AdminService";
import { FilterDescriptor } from "@progress/kendo-data-query";

export interface IRelativityClientRequest extends IGridParams {

}

type Props = {
  additionalRelClients?: IRelClientSelectable[] | undefined;
  enableSelectClient?: boolean;
  selectionChange?: (selected: IRelClientSelectable[]) => void;
  instance?: IRelInstanceSelectable;
};

const defaultAppState = { items: [], merged: [], count: 0, selections: {} } as IGridStateMerged<IRelClientSelectable>;

const RelativityClientList = (props: Props) => {
  const [relClients, setRelClients] = useState(defaultAppState);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const filterTimeOut = useRef(null);
  const gridRef = useRef<AdvancedGrid<IRelClientSelectable, IRelativityClientRequest>>(null);

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 100,
    sort: [{ field: "name", dir: "asc" }]
  } as IRelativityClientRequest);


  useEffect(() => {
    const updated = { ...relClients }
    updated.selections = {};
    for (let i = 0; i < updated.items.length; i++) {
      updated.items[i].selected = false;
    }
    setRelClients(updated);
  }, []);

  const updateMergedState = (clients: IRelClientSelectable[], preSelected: Record<number | string, IRelClientSelectable>) => {

    if (!clients) {
      clients = [];
    }

    for (let i = 0; i < clients.length; i++) {
      if (preSelected[clients[i].uniqueClientId]) {
        clients[i].selected = true;
      }
    }

    if (props.additionalRelClients && props.additionalRelClients.length) {
      const merged = CommonHelper.dedupeObjects<IRelClientSelectable>(props.additionalRelClients, relClients.items, 'uniqueClientId');
      setRelClients({ items: clients, merged, count: merged.length, selections: preSelected });
    } else {
      setRelClients({ items: clients, merged: clients, count: clients.length, selections: preSelected });
    }
  }

  const loadApps = async (dataState: IRelativityClientRequest) => {
    setIsLoading(true);
    try {
      const request = { ...dataState };
      request.filters = [];

      if (request.filter) {
        request.filters.push({ logic: "and", filters: request.filter.filters });
      }

      const filters = request.filters.map(compositeFilter => {

        const numericFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "relativityClientId"
        ) as FilterDescriptor;

        if (numericFilter) {
          const isFilterValueString = numericFilter.value && typeof (numericFilter.value) === "string"

          if (isFilterValueString) {
            return {
              ...compositeFilter,
              filters: [
                {
                  ...numericFilter,
                  value: -1
                }
              ]
            };
          }
          else if (isFilterValueString === null) {
            return {
              ...compositeFilter,
              filters: [
                {
                  ...numericFilter,
                  operator: "gte",
                  value: 0
                }
              ]
            };
          }

        }

        return compositeFilter;
      });

      const result = await AdminService.getClientsResourceByInstance(props.instance.uniqueInstanceId, { ...request, filters: filters });

      if (result.ok) {
        updateMergedState(result.data.results, dataState.filter ? relClients.selections : {});
        setHasError(false);
      }
      else {
        setRelClients(defaultAppState);
        NotificationService.showErrorToast("Something went wrong while getting relativity clients.");
        setHasError(true);
      }

    } catch (e) {
      setRelClients(defaultAppState);
      NotificationService.showErrorToast("Something went wrong while getting relativity clients.");
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const selectRow = (dataItem: IRelClientSelectable) => {
    const changes = { ...relClients };
    for (let i = 0; i < changes.items.length; i++) {
      if (dataItem.uniqueClientId === changes.items[i].uniqueClientId) {
        changes.items[i].selected = !changes.items[i].selected;
        if (!changes.items[i].selected) {
          delete changes.selections[changes.items[i].uniqueClientId];
        } else {
          changes.selections[changes.items[i].uniqueClientId] = changes.items[i];
        }
      }
    }

    setRelClients(changes);

    props.selectionChange && props.selectionChange(Object.keys(changes.selections).map(e => changes.selections[e]));
  };


  const gridFilterChange = (event: GridFilterChangeEvent) => {
    const newState = { ...dataState, filter: event.filter };
    setDataState(newState);
    if (filterTimeOut.current) {
      clearTimeout(filterTimeOut.current);
    }
    filterTimeOut.current = setTimeout(() => {
      gridRef.current.resetGridState(newState, false, true);
    }, 500);
  };

  const columns = new Array<GridColumnProps>(
    { field: "name", title: "Relativity Client Name", sortable: true, filterable: true, filter: "text", width: "200px" },
    { field: "relativityClientId", title: "Relativity Artifact ID", sortable: true, filterable: true, filter: "numeric", width: "200px" }
  );

  if (props.enableSelectClient) {
    columns.unshift({
      title: "",
      cell: CommandCell({
        onSelection: selectRow,
        selectedField: "selected"
      }),
      sortable: false,
      headerClassName: "no-sort"
    });
  }



  return (
    <div className="applications-grid-wrapper rel-client-grid-wrapper">
      <AdvancedGrid
        ref={(advancedGridInstance: AdvancedGrid<IRelClientSelectable, IRelativityClientRequest>) => {
          gridRef.current = advancedGridInstance;
        }}
        showErrorState={hasError}
        showLoadingIndicator={isLoading}
        data={relClients.merged}
        dataFetch={loadApps}
        dataState={dataState}
        columns={columns}
        paging={false}
        noRecordsRender={<p>No relativity clients found.</p>}
        noLoadOnMount={false}
        filteredDataOnly={false}
        selectedField="selected"
        filterOperators={{
          text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
          numeric: [{ text: "grid.filterEqOperator", operator: "eq" }]
        }}
        filter={dataState.filter}
        onFilterChange={gridFilterChange}
      />
    </div>
  );
}

export default RelativityClientList;