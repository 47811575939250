import {
  ServiceBase,
  ResponseMessages,
  ServiceResponse,
  ServiceResponseJson,
  ServiceResponseData
} from "./ServiceBase";
import { UpsertReportModel } from "../models/ReportModels";
import { IGridParams } from "../components/common/grid/AdvancedGrid";
import { IPagedReport } from "../models/Report";

const GetEmbedReportMessages: ResponseMessages = {
  FailedMessage: "Failed to get embedded report information.",
  SuccessMessage: "Successfully retrieved embedded report information."
};

const GetGroupMessages: ResponseMessages = {
  FailedMessage: "Failed to report group information.",
  SuccessMessage: "Successfully retrieved report group information."
};

const CreateReportMessages: ResponseMessages = {
  FailedMessage: "Create report failed.",
  SuccessMessage: "Create report succeeded."
};

const UpdateReportMessages: ResponseMessages = {
  FailedMessage: "Update report failed.",
  SuccessMessage: "Update report succeeded."
};

const DeleteReportMessages: ResponseMessages = {
  FailedMessage: "Delete report failed.",
  SuccessMessage: "Delete report succeeded."
};

const GetReportMessages: ResponseMessages = {
  FailedMessage: "Get reports failed.",
  SuccessMessage: "Get reports succeeded."
};

const RefreshAllDatasetsMessages: ResponseMessages = {
  FailedMessage: "Refresh all datasets failed.",
  SuccessMessage: "Refresh all datasets succeeded."
};

const GetJobStatusInfo: ResponseMessages = {
  FailedMessage: "Get Job Status Info failed.",
  SuccessMessage: "Get Job Status Info succeeded."
};

const AuditViewReportMessages: ResponseMessages = {
  FailedMessage: "Failed to audit the report view.",
  SuccessMessage: "Successfully audit the report view."
};

export default class AnalyticsService extends ServiceBase {
  static async GetEmbedInfo(embedCode: string) {
    const url = `${AnalyticsService.getBaseUri()}/getbycode/?code=${embedCode}`;
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetEmbedReportMessages))
      .catch(async response => await this.handleResponseJson(response, GetEmbedReportMessages));

    return result;
  }

  static async GetGroupInfo() {
    const url = `${AnalyticsService.getBaseUri()}/getgroupinfo`;
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetEmbedReportMessages))
      .catch(async response => await this.handleResponseJson(response, GetEmbedReportMessages));

    return result;
  }

  static async GetGroups() {
    const url = `${AnalyticsService.getBaseUri()}/getgroupinfo`;
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetGroupMessages))
      .catch(async response => await this.handleResponseJson(response, GetGroupMessages));

    return result;
  }

  static async GetReports(reportRequest: IGridParams, abortSignal?: AbortSignal) {
    const filterJSON = JSON.stringify(reportRequest.filters) || "";
    const sortJSON = JSON.stringify(reportRequest.sort) || "";
    const url = `${AnalyticsService.getBaseUri()}/report?${this.getFilterSortQueryString(
      filterJSON,
      sortJSON
    )}&${this.toQueryString(reportRequest, ["filters", "sort", "filter"])}`;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async response => await this.handleResponseJson(response, GetReportMessages))
      .catch(async response => await this.handleResponseJson(response, GetReportMessages));

    return result;
  }

  static async UpdateReport<T>(report: UpsertReportModel) {
    const url = `${AnalyticsService.getBaseUri()}/report`;

    const result: ServiceResponseData<T> = await fetch(url, { body: JSON.stringify(report), ...this.fetchPutOptions() })
      .then(async response => await this.handleResponseData<T>(response, UpdateReportMessages))
      .catch(async response => await this.handleResponseData<T>(response, UpdateReportMessages));

    return result;
  }

  static async CreateReport<T>(report: UpsertReportModel) {
    const url = `${AnalyticsService.getBaseUri()}/report`;
    const result: ServiceResponseData<T> = await fetch(url, { body: this.toPostBody(report), ...this.fetchPostOptions() })
      .then(async response => await this.handleResponseData<T>(response, CreateReportMessages))
      .catch(async response => await this.handleResponseData<T>(response, CreateReportMessages));

    return result;
  }

  static async DeleteReport(reportId: number) {
    const url = `${AnalyticsService.getBaseUri()}/report?reportId=${reportId}`;
    const result: ServiceResponse = await fetch(url, this.fetchDeleteOptions())
      .then(async response => await this.handleResponse(response, CreateReportMessages))
      .catch(async response => await this.handleResponse(response, CreateReportMessages));

    return result;
  }

  static async RefreshAllDatasets() {
    const url = `${AnalyticsService.getBaseUri()}/refresh-all-datasets`;
    const result: ServiceResponse = await fetch(url, this.fetchPostOptions())
      .then(async response => await this.handleResponse(response, RefreshAllDatasetsMessages))
      .catch(async response => await this.handleResponse(response, RefreshAllDatasetsMessages));

    return result;
  }

  static async GetReportPages() {
    const url = `${AnalyticsService.getBaseUri()}/pages`;
    const result: ServiceResponseData<Array<IPagedReport>> = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseData<Array<IPagedReport>>(response, GetGroupMessages))
      .catch(async response => await this.handleResponseData<Array<IPagedReport>>(response, GetGroupMessages));

    return result;
  }

  static getBaseUri() {
    return `${(<any>window).ApplicationVariables.apiUri}reporting`;
  }

  static async getJobStatusInfo() {
    const url = `${AnalyticsService.getBaseUri()}/getJobStatusInfo`;
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetJobStatusInfo))
      .catch(async response => await this.handleResponseJson(response, GetJobStatusInfo));

    return result;
  }

  static async auditClickedReport(reportId: number, report: string, page: string, abortSignal?: AbortSignal) {
    const url = `${AnalyticsService.getBaseUri()}/auditClickedReport`;
    const result: ServiceResponseJson = await fetch(url, {
      body: this.toPostBody({ reportId : reportId, reportName : report, page : page }), ...this.fetchPostOptions(abortSignal)
    })
      .then(async (response) => await this.handleResponseJson(response, AuditViewReportMessages))
      .catch(async (response) => await this.handleResponseJson(response, AuditViewReportMessages));

    return result;
  }

}
