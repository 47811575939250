import React from "react";
import { ITicketDetails } from "../../../../services/SupportService";
import "./Details.scss";
import "../../../CommonParts.scss";
import ReactMarkdown from 'react-markdown';
import CollapsibleSection from "../../../administration/common/CollapsibleSection";
import ViewMore from "../../../common/ViewMore";
import { Button } from "@progress/kendo-react-buttons";
import { TextInputChipList, ValidationRules, ITextInputChipProps } from "../../../common/TextInputChipList";
import DialogBox from "../../../common/DialogBox";
import SupportService from "../../../../services/SupportService";
import NotificationService from "../../../../services/NotificationService";
import { DialogCloseEvent } from "@progress/kendo-react-dialogs";
import CommonHelper from "../../../common/utilities/CommonHelper";

interface IProps {
  ticketDetails?: ITicketDetails;
  watchListDataSource?: boolean;
  disableAddFollowers?: boolean;
}

interface IElementToValidate {
  name: string;
  isValid: boolean;
}


type Props = IProps;

type State = {
  elementsToValidate: Array<IElementToValidate>;
  addFollowers: boolean;
  showSaveFollowersConfirmation: boolean;
  externalWatchList: string;
  updatedExternalWatchListArray: Array<string>;
};

class Details extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      elementsToValidate: new Array<IElementToValidate>({ name: "externalWatchList", isValid: true }),
      addFollowers: false,
      showSaveFollowersConfirmation: false,
      externalWatchList: this.props.ticketDetails.externalWatchListAsArray.join(","),
      updatedExternalWatchListArray: this.props.ticketDetails.externalWatchListAsArray
    }
  }

  addFollowerHeaderOptions = <Button primary={false} onClick={(e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ addFollowers: true })

  }}><i className="fa-solid fa-plus"></i> Followers</Button>

  onWatchListChange(newState: Array<ITextInputChipProps>, name: string, isValid: boolean) {
    this.setElementValidationState({ name, isValid }, () => {
      let externalWatchList = new Array<string>();

      newState.forEach(chipProps => {
        return externalWatchList.push(chipProps.text);
      });

      this.setState({
        externalWatchList: externalWatchList.join(",")
      });
    });
  }

  showFollowerPopup = () => {
    this.setState({ showSaveFollowersConfirmation: true });
  }

  private setElementValidationState(newValidationState: IElementToValidate, callBack: () => void) {
    const indexToUpdate = this.state.elementsToValidate.findIndex(element => element.name === newValidationState.name);
    const elementsToValidate = [...this.state.elementsToValidate];

    if (indexToUpdate === -1) {
      callBack();
    } else {
      elementsToValidate.splice(indexToUpdate, 1);
      elementsToValidate.splice(indexToUpdate, 0, newValidationState);

      this.setState({ ...this.state, elementsToValidate }, callBack);
    }
  }

  getWatchList = () => {
    let externalWatchList = new Array<ITextInputChipProps>();
    if (this.state.updatedExternalWatchListArray.length > 0) {
      this.state.updatedExternalWatchListArray.forEach(x => {
        const newChip: ITextInputChipProps = {
          text: x.trim(),
          value: x.trim(),
          type: "info",
          isValid: true
        };
        externalWatchList.push(newChip);
      });
    }
    return externalWatchList;
  }

  async toggleDialogEvent(event: React.MouseEvent<HTMLElement>) {
    if (event.currentTarget.textContent.toLocaleLowerCase() === "confirm") {
      this.setState({ showSaveFollowersConfirmation: false, addFollowers: false });

      let existingExternalWatchListArray = this.state.externalWatchList ? this.state.externalWatchList.split(',') : [];

      if (existingExternalWatchListArray.length > 0) {
        existingExternalWatchListArray = existingExternalWatchListArray.filter(function (item, pos) {
          return existingExternalWatchListArray.indexOf(item) == pos;
        }) // removing duplicates if any
      }

      const result = await SupportService.addFollowersToIncident({ externalWatchList: existingExternalWatchListArray.join(","), incident: this.props.ticketDetails.incidentId, watchListDataSource: this.props.watchListDataSource })
      if (result.ok) {
        if ((result.data.message as string) === "success") {
          this.setState({ updatedExternalWatchListArray: existingExternalWatchListArray, externalWatchList: existingExternalWatchListArray.join(",") });
          NotificationService.showSuccessToast("Successfully updated the followers.");
        }
        else {
          NotificationService.showErrorToast("Unable to add followers to this incident.");
        }
      }
      else {
        NotificationService.showErrorToast("Unable to add followers to this incident.");
      }
    }
    else if (event.currentTarget.textContent.toLocaleLowerCase() === "cancel") {
      this.setState({ showSaveFollowersConfirmation: false });
    }
  }

  dialogCloseEvent(event: DialogCloseEvent) {
    this.setState({ showSaveFollowersConfirmation: false });
  }

  enableSave = () => {
    return (JSON.stringify(this.state.externalWatchList ? this.state.externalWatchList.split(',').sort() : []) === JSON.stringify(this.state.updatedExternalWatchListArray.sort()));
  }

  render() {
    const description = this.props.ticketDetails.description
      && CommonHelper.getNewLineForMarkDown(this.props.ticketDetails.description);

    return (<>
      <CollapsibleSection title={"Request Details"}>
        <div className="ticket-details">
          <div className="info-section">
            <div className="info-item">
              <div className="info-label">Submitted By</div>
              <div>
                {this.props.ticketDetails.requestedBy.lastName}, {this.props.ticketDetails.requestedBy.firstName}
              </div>
            </div>
            <div className="info-item">
              <div className="info-label">Ticket Type</div>
              <div>{this.props.ticketDetails.supportTicketType}</div>
            </div>
            <div className="info-item">
              <div className="info-label">Summary</div>
              <div className="short-description">{this.props.ticketDetails.shortDescription}</div>
            </div>
          </div>
          <div className="info-section">
            <div className="info-item">
              <div className="info-label">Date Submitted</div>
              <div>{this.props.ticketDetails.submitted}</div>
            </div>
            <div className="info-item">
              <div className="info-label">Project</div>
              <div className="project-id">{this.props.ticketDetails.project}</div>
            </div>
            <div className="info-item">
              <div className="info-label">Description</div>
              <div className="long-description">
                <ViewMore><ReactMarkdown>{description}</ReactMarkdown></ViewMore>
              </div>
            </div>
          </div>
        </div>
      </CollapsibleSection>
      <CollapsibleSection title={"Contact Information"} headerContent={!this.props.disableAddFollowers ? this.addFollowerHeaderOptions : null}><>
        <div className="request-contact-section">
          <div className="item-bubble"><i className="fa-light fa-user"></i> {this.props.ticketDetails.contact.firstName} {this.props.ticketDetails.contact.lastName}</div>
          <div className="item-bubble"><i className="fa-light fa-envelope"></i> {this.props.ticketDetails.contact.email}</div>
          <div className="item-bubble"><i className="fa-regular fa-phone"></i> {this.props.ticketDetails.callBackNumber}</div>
        </div>

        <div className="ticket-details">
          <div className="info-section">
            <div className="info-item">
              <div className="info-label">Followers</div>
              {!this.state.addFollowers && this.state.updatedExternalWatchListArray.length > 0 && (<div>
                <ViewMore><>
                  {this.state.updatedExternalWatchListArray.map(item => (
                    <div>{item}</div>
                  ))}
                </></ViewMore>
              </div>)}
              {this.state.addFollowers && (<><div className="follower-watch-list">
                <TextInputChipList
                  label="To add a follower, type the email, click Enter, and then click Save."
                  name="externalWatchList"
                  placeholder="example@epiqglobal.com"
                  chipsRemovable={true}
                  validations={[{ rule: ValidationRules.Email, errorMessage: "Please enter a valid email." }]}
                  onDataChange={this.onWatchListChange.bind(this)}
                  existingChips={this.getWatchList()}
                />
              </div>
                <div className="follower-action">
                  <Button className="btn btn-secondary cancel-fol-btn" onClick={(e) => {
                    this.setState({ addFollowers: false, externalWatchList: this.state.updatedExternalWatchListArray.join(",") })
                  }}>
                    Cancel
                  </Button>
                  <Button className="save-fol-btn" primary={true} disabled={this.enableSave()} onClick={this.showFollowerPopup}>
                    Save
                  </Button>
                </div>
                <div>
                  <DialogBox
                    title={"Confirm updates"}
                    content={"Contact Information contains changes that are not saved. Do you want to save the updated followers before proceeding?"}
                    diagCloseEvent={this.dialogCloseEvent.bind(this)}
                    okButtonLabelText={"CANCEL"}
                    cancelButtonLabelText={"CONFIRM"}
                    visible={this.state.showSaveFollowersConfirmation}
                    toggleDialogEvent={this.toggleDialogEvent.bind(this)}
                    openDiaglogButtonText={""}
                  />
                </div>
              </>
              )}
            </div>
          </div>
        </div></>
      </CollapsibleSection>
    </>);
  }
}

export default Details;
