import React, { useState, useEffect, useRef, ReactElement } from "react";
import { GridColumnProps, GridCellProps, GridFilterCellProps, GridFilterChangeEvent, GridRowProps } from "@progress/kendo-react-grid";
import { Application, ApplicationStatus } from "../../../../models/ApplicationModels";
import ConsoleService from "../../../../services/ConsoleService";
import NotificationService from "../../../../services/NotificationService";
import { IGridParams, IGridStateMerged, AdvancedGrid } from "../../../common/grid/AdvancedGrid";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import CommandCell from "../../../common/grid/columns/CommandCell";
import { DropDownFilter } from "../../../common/grid/filters/DropDownFilter";
import "./ApplicationList.scss";
import { Tooltip } from "@progress/kendo-react-tooltip";


export interface IUserApplicationRequest extends IGridParams {
  externalUserId?: string;
  userId?: number;
  limitResourceGroupId?: number
}

type Props = {
  additionalApplications?: Application[] | undefined;
  excludedAppIds?: number[];
  excludeUserId?: number;
  userId?: number;
  typeName?: string;
  selectionChange?: (selected: Application[]) => void;
  addClickHandler?: () => void;
  canOpenPopup?: boolean;
  displayRemoveBtn?: boolean;
  enableRemoveApplication?: boolean;
  enableSelectApplication?: boolean;
  hideAddButton?: boolean;
  onRemoveUpdateApps?: (selected: Application) => void;
  permissions: Set<string>;
  disableAddBtn?: boolean;
  isEpiqUser?: boolean;
  isOpen?: boolean;
  limitResourceGroupId?: number;
};

const defaultAppState = { items: [], merged: [], count: 0, selections: {} } as IGridStateMerged<Application>;

const ApplicationList = (props: Props) => {
  const [applications, setApplications] = useState(defaultAppState);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const filterTimeOut = useRef(null);
  const gridRef = useRef<AdvancedGrid<Application, IUserApplicationRequest>>(null);

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 100,
    sort: [{ field: "name", dir: "asc" }],
    userId: props.userId,
    limitResourceGroupId: props.limitResourceGroupId
  } as IUserApplicationRequest);

  const abortController = typeof AbortController === undefined ? new AbortController() : undefined;
  const abortSignal = abortController ? abortController.signal : undefined;

  useEffect(() => {
    updateMergedState(applications.items, applications.selections);
  }, [props.additionalApplications]);

  useEffect(() => {
    if (props.isOpen === false) {
      const updated = { ...applications }
      updated.selections = {};
      for (let i = 0; i < updated.items.length; i++) {
        updated.items[i].selected = false;
      }
      setApplications(updated);
    }
  }, [props.isOpen]);

  const updateMergedState = (apps: Application[], preSelected: Record<number | string, Application>) => {

    if (!apps) {
      apps = [];
    }

    for (let i = 0; i < apps.length; i++) {
      if (preSelected[apps[i].id]) {
        apps[i].selected = true;
      }
    }

    if (props.additionalApplications && props.additionalApplications.length) {
      const merged = Application.dedupeApps(apps, props.additionalApplications);
      setApplications({ items: apps, merged, count: merged.length, selections: preSelected });
    } else {
      setApplications({ items: apps, merged: apps, count: apps.length, selections: preSelected });
    }
  }

  const loadApps = async (dataState: IUserApplicationRequest) => {
    setIsLoading(true);
    try {
      const filters = dataState.filters as Array<CompositeFilterDescriptor>;
      const applicationListFilters = filters
        ? filters.map((compositeFilter) => {
          const epiqAdminOnlyFilter: FilterDescriptor = compositeFilter.filters.find(
            filter => (filter as FilterDescriptor).field === "epiqOnly"
          ) as FilterDescriptor;

          if (epiqAdminOnlyFilter) {
            const epiqAdminOnlyFilterValue = epiqAdminOnlyFilter.value
              ? epiqAdminOnlyFilter.value.id == 0 || epiqAdminOnlyFilter.value.id == 1
                ? epiqAdminOnlyFilter.value.id == 0
                  ? false
                  : true
                : null
              : false;
              
            return {
              ...compositeFilter,
              filters: [
                {
                  ...epiqAdminOnlyFilter,
                  field: "epiqOnly",
                  operator: "eq",
                  value: epiqAdminOnlyFilterValue
                }
              ]
            };
          }

          return compositeFilter;
        })
        : [];

        if (props.excludedAppIds && props.excludedAppIds.length) {
          applicationListFilters.push({
            logic: "and",
            filters: [{
              field: "id",
              operator: "notin",
              value: props.excludedAppIds,
              isArray: true,
              hasInt: true
            } as FilterDescriptor]
          });
        }

      const result = props.excludeUserId ?
        await ConsoleService.getAppsWithExclusion({ ...dataState, filters: applicationListFilters }, props.excludeUserId, abortSignal) :
        await ConsoleService.getApplications({ ...dataState, filters: applicationListFilters }, abortSignal);

      if (result.ok) {
        updateMergedState(result.data.results, dataState.filter ? applications.selections : {});
        setHasError(false);
      }
      else {
        setApplications(defaultAppState);
        NotificationService.showErrorToast("Something went wrong while getting applications.");
        setHasError(true);
      }

    } catch (e) {
      setApplications(defaultAppState);
      NotificationService.showErrorToast("Something went wrong while getting applications.");
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const selectRow = (dataItem: Application) => {

    const changes = { ...applications };

    for (let i = 0; i < changes.items.length; i++) {
      if (dataItem.id === changes.items[i].id) {
        changes.items[i].selected = !changes.items[i].selected;
        if (!changes.items[i].selected) {
          delete changes.selections[changes.items[i].id];
        } else {
          changes.selections[changes.items[i].id] = changes.items[i];
        }
      }
    }

    setApplications(changes);

    props.selectionChange(Object.keys(changes.selections).map(e => changes.selections[e]));
  };

  const removeApplication = (dataItem: any) => {

    let apps = [...applications.items];

    for (let i = 0; i < apps.length; i++) {
      if (apps[i].id === dataItem.id) {
        apps.splice(i, 1);
        break;
      }
    }

    updateMergedState(apps, applications.selections);

    props.onRemoveUpdateApps(dataItem);
  };

  const epiqAdminOnly = (props: any) => {
    return <td>{props.dataItem.epiqOnly ? "Epiq Admin" : "Non-Epiq Admin"}</td>;
  };

  const appDescription = (props: any) => {
    return <td title={props.dataItem.description}>{props.dataItem.description}</td>;
  }

  const columns = new Array<GridColumnProps>(
    { field: "name", title: "APP NAME", filterable: true },
    { field: "instanceName", title: "INSTANCE NAME", filterable: true, filter: "text" },
    { field: "url", title: "WEBSITE URL", filterable: true, filter: "text" },
    {
      field: "epiqOnly",
      title: "EPIQ Admin Only",
      cell: epiqAdminOnly,
      filterable: true,
      filterCell: (props: GridFilterCellProps) => (
        <DropDownFilter
          {...props}
          data={[{ name: "Epiq Admin", id: 1 }, { name: "Non-Epiq Admin", id: 0 }]}
          textField="name"
          defaultSelectedOption={null}
        />
      )
    },
    { field: "description", title: "Description", filterable: true, filter: "text", cell: appDescription },
  );

  if (props.enableSelectApplication) {
    columns.unshift({
      title: "",
      cell: CommandCell({
        onSelection: selectRow,
        selectedField: "selected"
      }),
      sortable: false,
      headerClassName: "no-sort"
    });
  }

  if (props.enableRemoveApplication && (props.permissions.has("EpiqAdminUpdateUser") || props.permissions.has("AssignApplication")) && !props.disableAddBtn) {
    columns.push({
      title: "",
      cell: CommandCell({ onRemove: removeApplication }),
      sortable: false,
      headerClassName: "no-sort"
    });
  }

  const gridToolBarRender = (): JSX.Element => {
    return (
      (props.permissions.has("EpiqAdminUpdateUser") || props.permissions.has("AssignApplication")) && (
        <Button
          className="add-user-btn"
          icon={"plus"}
          primary={true}
          onClick={props.addClickHandler}
          disabled={props.disableAddBtn}
        >
          Applications
        </Button>
      )
    );
  };

  const onRowRender = (row: ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
    let propOverrides = {};

    if (props.dataItem.statusId == ApplicationStatus.deactivated) {
      propOverrides = { ...propOverrides, class: "k-state-disabled" };
    }

    return React.cloneElement(row, { ...propOverrides }, row.props.children);
  };

  return (
    <div className="applications-grid-wrapper">
      <Tooltip openDelay={2} position="top">
        <AdvancedGrid
          ref={(advancedGridInstance: AdvancedGrid<Application, IUserApplicationRequest>) => {
            gridRef.current = advancedGridInstance;
          }}
          showErrorState={hasError}
          showLoadingIndicator={isLoading}
          data={applications.merged}
          dataFetch={loadApps}
          dataState={dataState}
          columns={columns}
          paging={false}
          totalRecords={{ value: applications.count, label: "Application Access" }}
          noRecordsRender={props.isOpen ? <p>No applications found.</p> : <p>No applications added.</p>}
          noLoadOnMount={false}
          filteredDataOnly={false}
          gridToolbarContent={!props.hideAddButton && gridToolBarRender()}
          selectedField="selected"
          filterOperators={{
            text: [{ text: "grid.filterContainsOperator", operator: "contains" }]
          }}
          rowRender={props.enableSelectApplication ? onRowRender : null}
        />
      </Tooltip>
    </div>
  );
};

export default ApplicationList;
