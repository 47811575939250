import React, { useState, useEffect } from "react";
import { IGridStateMerged, AdvancedGrid, IGridParamsAdvancedRefreshable, AdvancedSortDescriptor } from "../../common/grid/AdvancedGrid";
import { GridCellProps, GridColumnProps, GridHeaderCellProps } from "@progress/kendo-react-grid";
import CommandCell from "../../common/grid/columns/CommandCell";
import NotificationService from "../../../services/NotificationService";
import CommonHelper from "../../common/utilities/CommonHelper";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import ResourceService from "../../../services/ResourceService";
import _ from "lodash";
import { IResourceFieldsSelectable, ResourceRequestFieldsToShow } from "../../../models/ResourceModels";
import { Checkbox } from "@progress/kendo-react-inputs";


interface IProps {
  additionalRelUsers?: IResourceFieldsSelectable[] | undefined;
  enableSelectRelUser?: boolean;
  selectionChange?: (selected: IResourceFieldsSelectable[]) => void;
  contextResourceGroupId: number;
};

type Props = IProps;

const defaultAppState = { items: [], merged: [], count: 0, selections: {} } as IGridStateMerged<IResourceFieldsSelectable>;

const GridConfigurations = {
  PageSize: 100,
  OrderBy: "RelEmail",
  Ascending: "asc",
  Descending: "desc"
};

const dataStateWithContextResourceGroup = (userGroupsId: number, dataState: IGridParamsAdvancedRefreshable) => {
  const updatedDataState = _.cloneDeep(dataState);
  updatedDataState.batchRequests[0].batchFilters[0].filters[0].value = userGroupsId;
  updatedDataState.batchRequests[2].batchFilters[0].filters[0].value = userGroupsId;
  return updatedDataState;
}

const startingGridParams: IGridParamsAdvancedRefreshable = {
  skip: 0,
  take: GridConfigurations.PageSize,
  sort: [{ field: "RelEmail", dir: "asc" }],
  filter: { logic: "and", filters: [{ field: "Username", operator: "isnull" }] },
  refreshToggle: false,
  queryGroupsInfo: true,
  batchRequests: [
    {
      isDistinct: true,
      resourceFieldsToShow: ResourceRequestFieldsToShow.PermissionCode,
      onlyFields: ["Unique Relativity Id", "Name"],
      includeParentHierarchyChain: true,
      batchFilters:
        [
          {
            logic: "and",
            filters: [
              {
                field: "ResourceGroupId",
                operator: "eq",
                value: -1
              }
            ]
          }
        ]
    },
    {
      isDistinct: true,
      onlyFields: ["RelFirstName", "RelLastName", "RelEmail", "PermissionCode"],
      expandPermissions: true,
      joinTables: [
        {
          name: "RelativityUser",
          joinType: 3
        }
      ]
    },
    {
      expandPermissions: true,
      separateResource: "user",
      independentBatch: true,
      joinTables: [
        {
          name: "UserStatusType",
          joinType: 3
        }
      ],
      batchFilters:
        [
          {
            logic: "and",
            filters: [
              {
                field: "ResourceGroupId",
                operator: "eq",
                value: -1
              }
            ]
          }
        ]
    },
    {
      separateResource: "user",
      joinTables: [
        {
          batchNum: 1,
          joinType: 2,
          optionalJoinType: "email"
        }
      ]
    }
  ]
};

const SyncRelativityUsers = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [syncRelUsers, setSyncRelUsers] = useState(defaultAppState);
  const [hasCheckedAll, setHasCheckedAll] = useState(false);
  const [dataState, setDataState] = useState(dataStateWithContextResourceGroup(props.contextResourceGroupId, startingGridParams));
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    const updated = { ...syncRelUsers }
    updated.selections = {};
    for (let i = 0; i < updated.items.length; i++) {
      updated.items[i].selected = false;
    }
    setSyncRelUsers(updated);
    setInitialLoad(false);
  }, []);

  const updateMergedState = (clients: IResourceFieldsSelectable[], preSelected: Record<number | string, IResourceFieldsSelectable>) => {

    if (!clients) {
      clients = [];
    }

    for (let i = 0; i < clients.length; i++) {
      if (preSelected[clients[i].fields["RelEmail"]]) {
        clients[i].selected = true;
      }
    }

    if (props.additionalRelUsers && props.additionalRelUsers.length) {
      const merged = CommonHelper.dedupeObjects<IResourceFieldsSelectable>(props.additionalRelUsers, syncRelUsers.items, 'fields.RelEmail');
      setSyncRelUsers({ items: clients, merged, count: merged.length, selections: preSelected });
    } else {
      setSyncRelUsers({ items: clients, merged: clients, count: clients.length, selections: preSelected });
    }

    if (clients.length > 0) {
      const hasAnyNonSelected = clients.find(x => x.selected !== true);
      setHasCheckedAll(hasAnyNonSelected ? false : true);
    } else {
      setHasCheckedAll(false);
    }
   
  }

  const filterQueryFormat = (filter?: CompositeFilterDescriptor) => {
    if (!filter || !filter.filters) {
      return null;
    }

    const originalFilters = _.cloneDeep(filter.filters) as Array<FilterDescriptor>;
    const returnValue = [] as Array<CompositeFilterDescriptor>;

    for (let i = originalFilters.length - 1; i >= 0; i--) { // Just adding the "Rel" equivalent field and 

      if (originalFilters[i].field === "RelFirstName") {
        const firstNameFilters = originalFilters.splice(i, 1);
        firstNameFilters.push({ ...firstNameFilters[0], field: "RelLastName" });

        returnValue.push({ logic: "or", filters: firstNameFilters });
      }
      else if (originalFilters[i].field === "RelEmail") {
        const firstNameFilters = originalFilters.splice(i, 1);

        returnValue.push({ logic: "or", filters: firstNameFilters });
      }
      else {
        originalFilters[i].field = originalFilters[i].field.toString().replace("fields.", "");
      }
    }

    if (originalFilters.length > 0) {
      returnValue.push({ logic: "and", filters: originalFilters });
    }

    return returnValue;
  }

  const sortQueryFormat = (sort?: Array<AdvancedSortDescriptor>) => {
    if (!sort || sort.length === 0) {
      return null;
    }

    const updatedSorts = _.cloneDeep(sort) as Array<AdvancedSortDescriptor>;

    for (let i = 0; i < updatedSorts.length; i++) {
      if (updatedSorts[i].field === "RelFirstName") {
        updatedSorts[i].addedCoalesceFields = ["RelFirstName", "RelLastName"];
      }
      if (updatedSorts[i].field === "RelEmail") { // should I coalesce both EA username and EA email to Rel email? That could be desirable
        updatedSorts[i].addedCoalesceFields = ["RelEmail"];
      }
    }

    return updatedSorts;
  }


  const loadApps = async (dataState: IGridParamsAdvancedRefreshable) => {
    setIsLoading(true);
    try {
      const request = { ...dataState };
      request.filters = filterQueryFormat(request.filter);
      request.sort = sortQueryFormat(request.sort);

      var result = await ResourceService.getResourcesDetailsBatch(request, "relativityclient");

      if (result.ok) {
        updateMergedState(result.data.results, initialLoad ? {} : syncRelUsers.selections);
        setHasError(false);
      }
      else {
        setSyncRelUsers(defaultAppState);
        setHasCheckedAll(false);
        NotificationService.showErrorToast("Something went wrong while getting relativity users.");
        setHasError(true);
      }

    } catch (e) {
      setSyncRelUsers(defaultAppState);
      setHasCheckedAll(false);
      NotificationService.showErrorToast("Something went wrong while getting relativity users.");
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const selectRow = (dataItem: IResourceFieldsSelectable) => {
    const changes = { ...syncRelUsers };
    for (let i = 0; i < changes.items.length; i++) {
      if (dataItem.fields["RelEmail"] === changes.items[i].fields["RelEmail"]) {
        changes.items[i].selected = !changes.items[i].selected;
        if (!changes.items[i].selected) {
          delete changes.selections[changes.items[i].fields["RelEmail"]];
        } else {
          changes.selections[changes.items[i].fields["RelEmail"]] = changes.items[i];
        }
      }
    }

    setSyncRelUsers(changes);

    const hasAnyNonSelected = changes.items.find(x => x.selected !== true);
    setHasCheckedAll(hasAnyNonSelected ? false : true);

    props.selectionChange && props.selectionChange(Object.keys(changes.selections).map(e => changes.selections[e]));
  };

  const firstNameCell = (props: GridCellProps) => {
    return <td>{props.dataItem.fields.RelFirstName} {props.dataItem.fields.RelLastName}</td>
  }

  const emailCell = (props: GridCellProps) => {
    return <td>{props.dataItem.fields.RelEmail}</td>
  }

  const columns = new Array<GridColumnProps>(
    { field: "RelFirstName", title: "User", sortable: true, filterable: true, filter: "text", width: "200px", cell: firstNameCell },
    { field: "RelEmail", title: "Email", sortable: true, filterable: true, filter: "text", width: "200px", cell: emailCell }
  );

  const handleHeaderCheckBoxSelectAllChange = (e: any) => {

    const updated = { ...syncRelUsers, selections: {} as Record<number | string, IResourceFieldsSelectable> };

    if (e.value) {
      for (let i = 0; i < updated.items.length; i++) {
        updated.items[i].selected = true;
        updated.selections[updated.items[i].fields["RelEmail"]] = updated.items[i];
      }
    }
    else {
      for (let i = 0; i < updated.items.length; i++) {
        updated.items[i].selected = false;
      }
    }

    setSyncRelUsers(updated);
    setHasCheckedAll(e.value);
    props.selectionChange && props.selectionChange(Object.keys(updated.selections).map(e => updated.selections[e]));
  }

  const headerCheckBoxSelectAll = (props: GridHeaderCellProps) => {
    return (
      <div className="select-all-sync-apps-header">
        <Checkbox
          name="checkBoxSelectAll"
          value={"selectall"}
          checked={hasCheckedAll}
          label={""}
          onChange={(e) => handleHeaderCheckBoxSelectAllChange(e)}
          title="Select All Users"
          disabled={!(syncRelUsers && syncRelUsers.items && syncRelUsers.items.length > 0)}
        />
      </div>);
  }

  if (props.enableSelectRelUser) {
    columns.unshift({
      title: "",
      cell: CommandCell({
        onSelection: selectRow,
        selectedField: "selected"
      }),
      headerCell: headerCheckBoxSelectAll,
      sortable: false,
      headerClassName: "no-sort"
    });
  }

  return (
    <div className="sync-rel-users-grid-wrapper">
      <AdvancedGrid
        showErrorState={hasError}
        showLoadingIndicator={isLoading}
        data={syncRelUsers.merged}
        dataFetch={loadApps}
        dataState={dataState}
        columns={columns}
        paging={false}
        noRecordsRender={<p>No relativity users found.</p>}
        noLoadOnMount={false}
        filteredDataOnly={false}
        selectedField="selected"
        filterOperators={{
          text: [{ text: "grid.filterContainsOperator", operator: "contains" }]
        }}
      />
    </div>
  );
}

export default SyncRelativityUsers;