import React, { Component } from "react";
import ResourceService from "../../../../services/ResourceService";
import CommonHelper from "../../../common/utilities/CommonHelper";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import ErrorSharpIcon from "@material-ui/icons/ErrorSharp";
import InfoIcon from "@material-ui/icons/Info";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import ErrorIcon from "@material-ui/icons/Error";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { ServiceBase } from "../../../../services/ServiceBase";
import SanitizeTextHelper from "../../../common/utilities/SanitizeTextHelper";

const FetchSettings = {
  MaxFetchRetryAttempts: 3
};

interface ValidationSummary {
  totalCreateCount: number;
  totalUpdateCount: number;
  totalIssueCount: number;
  validationInProgress: boolean;
}

type Props = {
  validationSummary: ValidationSummary;
  jobId: string;
  selectedFileName: string;
  errorMessage: string;
  importProcessFailed: boolean;
  showImportProgress: boolean;
};

interface State {
  errorMessage: string;
}

class ReviewImport extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { errorMessage:"" };
  }

  downloadValidationResult = async () => {
    const response = await ResourceService.getDownloadUri(this.props.jobId, FetchSettings.MaxFetchRetryAttempts);
    const failedToDownloadErrorMessage = `Unable to download file ${this.props.selectedFileName}. Please try again. If this issue continues, contact support.`;

    if (response.ok) {
      ServiceBase.fetchRetry(
        response.data.downloadUrl,
        {
          method: "GET"
        },
        FetchSettings.MaxFetchRetryAttempts
      )
        .then(resp => resp.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const anchorElement = document.createElement("a");
          const formattedCurrentDate = CommonHelper.dateFormat(new Date(), "MMDDYYYY", "");

          anchorElement.style.display = "none";
          anchorElement.href = SanitizeTextHelper.sanitizeUrl(url);
          anchorElement.download = `Import Resources Validation Results ${formattedCurrentDate}.xls`;
          document.body.appendChild(anchorElement);
          anchorElement.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error: any) => {
          console.log(
            `Error occurred while downloading the validation result file from blob storage. Exception Details : ${error}`
          );
          this.setState({ errorMessage: failedToDownloadErrorMessage });
        });
    } else {
      this.setState({ errorMessage: failedToDownloadErrorMessage });
    }
  };

  downloadFileErrorMessage = () => {
    const errorMessage = this.props.importProcessFailed ? this.props.errorMessage : this.state.errorMessage;
    return errorMessage && errorMessage.length > 0 ? (<div>
      <div className="error-info">
        <span>
          <ErrorIcon />
        </span>{" "}
        <span className="error-msg">{errorMessage}</span>
      </div>
    </div>) : ("");
  }


  render() {
    const { validationSummary, selectedFileName, jobId, errorMessage, importProcessFailed} = this.props;
    const totalResourcesCount =
      validationSummary.totalCreateCount + validationSummary.totalUpdateCount + validationSummary.totalIssueCount;
    

    return (
      <div className="review-import">
        <h2>Review Results and Import</h2>
        <p>
          After validation, review the status of the resources. Then, import available resources and update an existing resource group.
          If issues appear, download the validation summary file to locate the issues, correct your resources list file, and
          go back to upload the updated file.
        </p>
        <div className="file-upload-dropzone">
          <span className="attached-file">Attached file:</span>
          <DescriptionOutlinedIcon className="description-icon" /> <span className="fine-name">{selectedFileName}</span>
        </div>
        <br />
        <div className="validation-summary">
          <div className="validation-message-container">
            <h3>
              {validationSummary.validationInProgress ? (
                <span className="pending">Validation summary: Pending...</span>
              ) : importProcessFailed || errorMessage.length === 0 ? (
                  <span className={validationSummary.totalIssueCount > 0 ? "error-display" : ""  }>
                    Validation summary: {validationSummary.totalCreateCount} of {totalResourcesCount} resources
              available for import
                </span>
              ) : (<>
                <div className="error-info align-middle">
                  <span className="error-msg">Validation summary: {errorMessage} </span>
                </div>
                {this.downloadFileErrorMessage()}
              </>
                  )}
            </h3>
          </div>
          {validationSummary.validationInProgress && errorMessage.length === 0 ? (
            <div className="validation-progress">
              <CircularProgress size={16} />
            </div>
          ) : (
              " "
            )}
          <div className="validation-status-container">
            {validationSummary.totalIssueCount > 0 ? (

              <Button variant="contained" className="download-and-fix" onClick={this.downloadValidationResult}>
                Download & Fix
              </Button>
            ) : errorMessage.length === 0 && validationSummary.validationInProgress === false ? (
              <div className="validation-done">
                <Tooltip title="All resources available for import" placement="left-end" arrow>
                  <CheckIcon />
                </Tooltip>
              </div>
            ) : (
                  ""
                )}
          </div>
          {validationSummary.validationInProgress ? (
            <div className="processing">Processing...</div>
          ) : importProcessFailed || errorMessage.length === 0 ? (
            <>
              {this.downloadFileErrorMessage()}

              <div className="validation-result-container">
                <ul>
                  <li className="available-to-import">
                    <CheckCircleRoundedIcon />
                    <span className="available-count">{validationSummary.totalCreateCount}</span> available for import
                  </li>
                  <li className="exist-users">
                    <InfoIcon />
                    <span className="exist-users">{validationSummary.totalUpdateCount}</span> exist as resources, not available for import
                  </li>
                  <li className="issues">
                    <ErrorSharpIcon />
                    <span className="error-count">{validationSummary.totalIssueCount}</span> show issues or require
                    information
                  </li>
                </ul>

                {validationSummary.validationInProgress ? (
                  <div className="loading-in-progress">
                    <span>Loading...</span>
                  </div>
                ) : (
                    ""
                  )}
              </div>


            </>
          ) : (
                ""
              )}
        </div>

      </div>
    );
  }
}

export default ReviewImport;
