import React from "react";
import { BrowserRouter as Router, RouteComponentProps } from "react-router-dom";
import { Route, Switch, Redirect } from "react-router-dom";
import { ISessionManager } from "./SessionManager";
import { ISessionData } from "./store/Session";
import { ImplicitCallback } from "@okta/okta-react";
import Layout from "./components/Layout";
import LoginLayout from "./components/LoginLayout";
import BasicAuthenticatedLayout from "./components/BasicAuthenticatedLayout";
import PlainLayout from "./components/PlainLayout";
import { StaticContext } from "react-router";
import { AppInsightsHelper } from "./AppInsights";
import authenticationStorageApi from "./apis/storage/AuthenticationStorageApi";
import { IRoute, RouteType, authenticatedRoutes, basicAuthenticatedRoutes, routes, Routes } from "./models/Routing";

interface IProps {
  sessionManager: ISessionManager;
  sessionData: ISessionData;
  type: RouteType;
}

type Props = IProps;

export class Routing extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  getRoute(
    route: IRoute,
    LayoutComponent: any,
    layoutProps: any = {},
    renderOverride: (routeProps: RouteComponentProps<any, StaticContext, any>) => JSX.Element = null
  ): React.ReactNode {
    const render = (routeProps: RouteComponentProps<any, StaticContext, any>) => (
      <LayoutComponent {...layoutProps} {...routeProps}>
        <route.component {...layoutProps} {...routeProps} />
      </LayoutComponent>
    );

    return <Route exact={route.exact} path={route.path} render={renderOverride || render} />;
  }

  render() {
    const defaultLayout = this.props.type === RouteType.BasicAuthenticated ? BasicAuthenticatedLayout : this.props.type === RouteType.Authenticated ? Layout : LoginLayout;
    const routesToRender = this.props.type === RouteType.BasicAuthenticated ? basicAuthenticatedRoutes : this.props.type === RouteType.Authenticated ? authenticatedRoutes : routes;
    const featurePermissionsKey =
      (this.props.sessionData && Object.keys(this.props.sessionData.featurePermissions)) || new Array<string>();

    return (
      <Router>
        <Switch>
          <Route
            path={window.ApplicationVariables.redirectPath}
            render={routeProps => (
              <Layout sessionManager={this.props.sessionManager} {...routeProps}>
                <ImplicitCallback
                  {...routeProps}
                  {...{
                    baseUrl: window.ApplicationVariables.issuer,
                    apiUri: window.ApplicationVariables.apiUri
                  }}
                />
              </Layout>
            )}
          />

          {Object.keys(routesToRender).map(key => {
            let layoutToRender = defaultLayout;

            let layoutProps = { sessionManager: this.props.sessionManager };
            let render = null;
            const isFeatureRoute = featurePermissionsKey.find(
              k => routesToRender[key].id === this.props.sessionData.featurePermissions[k].routeId
            );

            if (isFeatureRoute && !this.props.sessionData.featurePermissions[isFeatureRoute].authorized) {
              return;
            }

            if (this.props.type === RouteType.Authenticated || this.props.type === RouteType.BasicAuthenticated) {
              if (routesToRender[key].id === Routes.Default) {
                render = (routeProps: RouteComponentProps<any, StaticContext, any>) => {
                  AppInsightsHelper.addTelemetryInitializer();

                  let path = this.props.sessionData.destination.path;

                  if(this.props.type === RouteType.Authenticated) {
                    let overridepath = authenticationStorageApi.getAndClearPreLoginPath();
                    if (overridepath) {
                      path = overridepath;
                    }
                  }

                  return <Redirect push={true} to={path} {...routeProps} />;
                };
              }
            }

            if (routesToRender[key].id === Routes.Login || routesToRender[key].id === Routes.PrivacyStatement) {
              layoutToRender = LoginLayout;
            }

            if (routesToRender[key].id === Routes.UploadSuccess) {
              layoutToRender = PlainLayout;
            }

            return this.getRoute(routesToRender[key], layoutToRender, layoutProps, render);
          })}
        </Switch>
      </Router>
    );
  }
}
