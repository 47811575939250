import AuthenticationStorageApi from "../apis/storage/AuthenticationStorageApi";
import SessionStorageApi from "../apis/storage/SessionStorageApi";
import { store } from "../index";
import * as SessionStore from "../store/Session";

export interface ResponseMessages {
  FailedMessage: string;
  SuccessMessage: string;
}

export interface ServiceResponse {
  ok: boolean;
  aborted?: boolean;
  message?: string;
}

export interface ServiceResponseJson extends ServiceResponse {
  data: any;
}

export interface ServiceResponseData<T> extends ServiceResponse {
  data?: T; // TODO, consider should we not make this optional?
  inValid?: boolean;
  message?: string;
}

export interface ServiceResponseStr extends ServiceResponse {
  value: string | undefined;
}

class ServiceBase {
  static baseHeader = (): any => {
    return {
      Authorization: `Bearer ${AuthenticationStorageApi.getToken()}`
    };
  };

  static fetchOptions = (abortSignal?: AbortSignal): any => {
    return {
      signal: abortSignal,
      headers: ServiceBase.baseHeader()
    };
  };

  static fetchPostOptions = (abortSignal?: AbortSignal): any => {
    return {
      signal: abortSignal,
      method: "POST",
      headers: {
        ...ServiceBase.baseHeader(),
        "Content-Type": "application/json;charset=utf-8"
      }
    };
  };

  static fetchPutOptions = (abortSignal?: AbortSignal): any => {
    return {
      signal: abortSignal,
      method: "PUT",
      headers: {
        ...ServiceBase.baseHeader(),
        "Content-Type": "application/json;charset=utf-8"
      }
    };
  };

  static fetchDeleteOptions = (abortSignal?: AbortSignal): any => {
    return {
      signal: abortSignal,
      method: "DELETE",
      headers: ServiceBase.baseHeader()
    };
  };

  static fetchBlobOptions = (abortSignal?: AbortSignal): any => {
    return {
      headers: {
        ...ServiceBase.baseHeader(),
        "Content-Type": "application/octet-stream"
      }
    };
  };

  static async handleResponseStr(response: Response, responseMessage: ResponseMessages): Promise<ServiceResponseStr> {
    const result = await this.handleResponse(response, responseMessage);
    let resultStr;

    if (result.ok) {
      resultStr = await response.text();
    }

    return { ok: result.ok, aborted: result.aborted, value: resultStr };
  }

  static async handleResponseJson(response: Response, responseMessage: ResponseMessages): Promise<ServiceResponseJson> {
    const result = await this.handleResponse(response, responseMessage);
    let resultJson = null;

    if (result.ok) {
      resultJson = await response.json();
    }

    return { ok: result.ok, aborted: result.aborted, data: resultJson };
  }

  static async handleResponseData<T>(
    response: Response,
    responseMessage: ResponseMessages
  ): Promise<ServiceResponseData<T>> {
    const result = await this.handleResponse(response, responseMessage);
    let resultJson: ServiceResponseData<T> = { ok: false };

    let obj = null;
    if (result.ok) {
      obj = await response.json();
      resultJson.inValid = obj.inValid;
      resultJson.message = obj.message;
      resultJson.data = obj.data || obj;
    }
    resultJson.ok = result.ok;
    resultJson.aborted = result.aborted;

    return resultJson;
  }

  static async handleResponse(response: Response, responseMessage: ResponseMessages): Promise<ServiceResponse> {
    if (response.ok) {
      console.log(responseMessage.SuccessMessage);
      return response;
    }

    // @ts-ignore --- ignoring because typescript says that fetch() returns a Promise<Response> but Response is incomplete and doesn't have the properties that are returned on aborts (e.g. resonse.name)
    if (response.name == "AbortError") {
      return { ok: false, aborted: true };
    }

    const bodyText = response.text ? await response.text() : "";

    if (response.status === 401) {
      if (bodyText.toLowerCase() === "session could not be retrieved or does not exist.") {
        if (!store.getState().sessionState.isLost) {
          //Show the session lost warning modal if we have lost our session
          SessionStorageApi.setSessionLostWarning(true);
          store.dispatch(SessionStore.actionCreators.setSessionIsLost(true));
          //Force the session timeout warning modal closed if we have lost our session
          SessionStorageApi.setSessionTimeoutWarning(false);
          store.dispatch(SessionStore.actionCreators.setSessionIsIdle(false));
        }
      }
    }

    console.log(`${responseMessage.FailedMessage} Response Message:`, response);

    return { ok: false, message: bodyText };
  }

  static toQueryString(obj: any, keysToIgnore: Array<string> = null) {
    return Object.keys(obj)
      .reduce((_result, key) => {
        if (obj[key] != null && (!keysToIgnore || !keysToIgnore.find(keyToIgnore => keyToIgnore === key))) {
          if (Array.isArray(obj[key])) {
            for (let i = 0; i < obj[key].length; i++) {
              _result.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key][i]));
            }
          } else {
            _result.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]));
          }
        }
        return _result;
      }, [])
      .join("&");
  }

  static toPostBody(content: any) {
    return this.stripLinks(JSON.stringify(content));
  }

  static stripLinks(content: string) {
    return content.replace(/(https?|ftp|file)\:\/\//gi, "");
  }

  static encodeToBase64(rawContent: string) {
    return encodeURIComponent(btoa(encodeURIComponent(rawContent)));
  }

  static getFilterSortQueryString(filterJSON: string, sortJSON: string) {
    return `filter=${this.encodeToBase64(filterJSON)}&sort=${this.encodeToBase64(sortJSON)}`;
  }

  static setTimeoutPromise = (timeout: number) =>
    new Promise(resolve => {
      setTimeout(resolve, timeout);
    });

  static fetchRetry = async (url: string, options: RequestInit, maxFetchRetryAttempts: number): Promise<Response> => {
    let error: any;

    for (let i = 0; i < maxFetchRetryAttempts; i++) {
      try {
        return await fetch(url, options);
      } catch (err) {
        console.log(err);
        error = err;
        await ServiceBase.setTimeoutPromise(2000);
      }
    }

    throw error;
  };
}

export { ServiceBase };
