import React, { useState } from "react";
import { FieldRenderProps } from "@progress/kendo-react-form";
import { IFileInfo, standardExtensionRestrictions, StandardFileUpload } from "../fileupload/StandardFileUpload";
import { IWizardValues } from "../../../models/Wizard";
import { updateValForField } from "./WizardElements";
import "./FileUploadField.scss";
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import JobsService from "../../../services/JobService";
import bgMessages from '../../../Localization/en.json';
import NotificationService from "../../../services/NotificationService";
import { ServiceBase } from "../../../services/ServiceBase";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
loadMessages(bgMessages, 'en-US');

interface LocaleInterface {
  language: string;
  locale: string;
}

enum JobStates {
  ImportReadyForValidation = 1,
  ImportValidating = 2,
  ImportValidatedError = 3,
  ImportValidatedSuccess = 4,
}

const FileUploadField = function (fieldRenderProps: FieldRenderProps) {
  const [status, setStatus] = useState<number>(0);
  const [error, setError] = useState<string>("");
  const valInfo = fieldRenderProps.valInfo as IWizardValues;
  const [files, setFiles] = useState([] as Array<IFileInfo>);
  const uploadedFileInfo = valInfo && valInfo.value as Array<IFileInfo>;
  const [isLoading, setLoading] = useState(false);

  const handleOnDataStateChanged = function (files: Array<IFileInfo>) {
    if (fieldRenderProps.fieldData.bulkImportMode) {
      setFiles(files);
    }
    else {
      updateValForField(files, fieldRenderProps, getfileNames(files));
    }
  };

  const getfileNames = (files: Array<IFileInfo>) => {
    let text = "";
    if (files && files.length) {
      text = files.map(f => f.file.name).join(", ");
    }
    return text;
  }

  const handleOnUploadComplete = async function () {
    if (fieldRenderProps.fieldData.bulkImportMode) {
      setStatus(JobStates.ImportReadyForValidation);
      updateValForField(null, fieldRenderProps, getfileNames(files)); //reset the uploaded files
      setLoading(true);
      const result = await JobsService.submitJob({
        jobType: fieldRenderProps.jobType,
        jobId: fieldRenderProps.jobId,
        readyForValidation: true
      });

      if (result.ok) {
        await fetchJobStatus(fieldRenderProps.jobId);
      } else {
        NotificationService.showErrorToast("Failed to submit relativity user import for validation.");
      }
    }
  };

  const fetchJobStatus = async (jobId: string) => {
    if (jobId) {
      const { ok, data } = await JobsService.getJobStatus(jobId);

      if (ok) {
        const jobData = data.data ? JSON.parse(data.data) : null;
        const importDataError = jobData && jobData.ErrorMessage || (jobData.ImportJobData && jobData.ImportJobData.Data.Error.Mess) || "";
        setStatus(data.statusId);

        switch (data.statusId) {
          case JobStates.ImportReadyForValidation:
          case JobStates.ImportValidating:
            await ServiceBase.setTimeoutPromise(1000);
            await fetchJobStatus(jobId);
            break;
          case JobStates.ImportValidatedError:
            setError(importDataError);
            updateValForField(null, fieldRenderProps, getfileNames(files)); //next button will be disabled if validation is failed
            setLoading(false);
            break;
          case JobStates.ImportValidatedSuccess:
            const userData = jobData.ImportRelativityUserData || (jobData.SubmitData && jobData.SubmitData.ImportUsers);

            updateValForField(files, fieldRenderProps, getfileNames(files), userData); //next button will be enabled after validation success.
            setLoading(false);
            break;
        }
      } else {
        NotificationService.showErrorToast("Failed to get job status. Please re-initiate the export process.");
      }
    }
  }

  const handleOnBeforeUpload = function () { };

  const locales: LocaleInterface[] = [
    {
      language: "en-US",
      locale: "en",
    },
  ];

  const [currentLocale, setCurrentLocale] = React.useState<LocaleInterface>(
    locales[0]
  );

  const uploadedFiles = (fieldRenderProps.fieldData.bulkImportMode
  ) ? (
    <>
     
      {uploadedFileInfo && uploadedFileInfo.length > 0 && !isLoading && uploadedFileInfo.map((file: IFileInfo, i: number) => (
        <>
          <div className="uploaded-file">
            <span className="check-mark success">
              <CheckCircleIcon className="check-icon" />
            </span>
            <span className="k-file-name" title={file.file.name}>{file.file.name}</span>
          </div>
        </>
      ))}
    </>) : (
    "");

  const preExistingFiles = () => {
    return fieldRenderProps.fieldData.bulkImportMode ? [] : valInfo && valInfo.value as Array<IFileInfo>
  }

  return (
    <>
      {fieldRenderProps.fieldData.description && <div className="download-label-description">{fieldRenderProps.fieldData.description}</div>}
      <LocalizationProvider language={currentLocale.language}>
        <IntlProvider locale={currentLocale.locale} >
          {!isLoading && (
            <StandardFileUpload
              requestData={{
                jobId: fieldRenderProps.jobId,
                jobType: fieldRenderProps.jobType
              }}
              preExistingFiles={preExistingFiles()}
              key={fieldRenderProps.seedKey}
              restrictions={{
                allowedExtensions: fieldRenderProps.fieldData.allowedFileExtensions || standardExtensionRestrictions,
                maxFileSize: fieldRenderProps.fieldData.maxFileSize || 52428800 // default 50 MB max
              }}
              onUploadComplete={handleOnUploadComplete}
              onBeforeUpload={handleOnBeforeUpload}
              onDataStateChanged={handleOnDataStateChanged}
              hideSupportedFileTypesString={!fieldRenderProps.fieldData.showSupportedFileTypesString}
              autoUpload={!fieldRenderProps.fieldData.restrictAutoUpload}
              multiple={fieldRenderProps.fieldData.multiple}
              maxCount={fieldRenderProps.fieldData.maxCount}
            />
          )}
        </IntlProvider>
      </LocalizationProvider>
      {uploadedFiles}
      {(fieldRenderProps.fieldData.bulkImportMode && status > 0 && status !== JobStates.ImportValidatedSuccess) && (
        <div className="validation-summary">
          <div className="validation-message-container">
            <h3>
              {(status === JobStates.ImportReadyForValidation || status === JobStates.ImportValidating) ? (
                <span className="pending">Validation is in progress, please wait for a few seconds ...</span>
              ) : status === JobStates.ImportValidatedError ? (<>
                <div className="error-info">
                  <span className="error-msg">Validation summary: {error} </span>
                </div>
              </>
              ) : <></>}
            </h3>
          </div>
        </div>
      )
      }
    </>
  );
};

export default FileUploadField;
