import { IAppFeature } from "./Features";

class Application {
  public id: number = 0;
  public ssoEnabled: boolean = false;
  public name: string = "";
  public instanceName: string = "";
  public url: string = "";
  public ssoApplicationId: string = "";
  public location: string = "";
  public folder: string = "";
  public startupUrl: string = "";
  public selected: boolean = false;
  public imageUrl: string = "";
  public typeName: string = "";
  public typeNameId: number = 0;
  public idpGroupId: string = "";
  public epiqOnly: boolean = false;
  public statusId: number = 0;
  public credentialsKey?: string = null;
  public authenticationProfile?: string = null;
  public description: string = "";
  public connectorType?: string = null;
  public isFavorite: boolean = false;
  public appResourceId: number = 0;

  static dedupeApps = (newApps: Application[], existingApps: Application[]): Application[] => {
    const combined = (existingApps || []).concat(newApps || []);
    return combined.filter((app, idx, self) => self.findIndex(app2 => app2.id === app.id) === idx);
  };
}

class ApplicationEdit extends Application {
  public credChanges?: boolean = false;
  public deleteCreds?: boolean = false;
  public newCredsRecentlySaved?: boolean = false;
  public credentials?: Credentials;
}


class UpsertUserAppModel extends ApplicationEdit {
  public addUsers: number[] = [];
  public removeUsers: number[] = [];
  public featureChanges: Array<IAppFeature>;
}

class ApplicationsType {
  public id: number = 0;
  public typeName: string = "";
  public imageUrl: string = "";
  public createdDate: Date;
  public createdBy: number = 0;
  public connectorId?: number = null;
}

class Connector {
  public id: number = 0;
  public name: string = "";
}

class Credentials {
  public type: CredentialType;
  public identifier: string;
  public code: string;
  public apiUrl: string;
  public epiqUsersOnly: boolean = true;
}

enum ApplicationStatus {
  active = 1,
  deactivated = 2,
  deleted = 3
}

export enum CredentialType {
  usernamePassword = 1,
  clientIdClientSecret = 2,
  httpHeader = 3
}
export enum TabView {
  AppView = "AppView",
  WorkspaceView = "WorkspaceView",
  FavAppsOnly = "FavAppsOnly",
  AllApps = "AllApps"
}

export class ApplicationFeature extends Application {
  public autoReenable: number = 0;
  public loginSupport: number = 0;
  public hideGeneralTechSupport: number = 0;
  public hasWorkspaceView?: boolean;
  public connectorTypeId?: number = 0;
}

export interface IApplicationDatabase {
  platform: string;
  databaseName: string;
  // There's plenty more in the API DTO but we don't need those now, and perhaps not ever
}

export enum Connectors {
  RelativityConnector = 1,
  GoAnywhereConnector = 2,
}

export { Application, ApplicationsType, UpsertUserAppModel, ApplicationStatus, ApplicationEdit, Connector };
