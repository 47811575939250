import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";

export interface IWarningMessageState {
  initialState?: string;
  isUnsavedChangesExist: boolean;  
}

interface ISaveUnsavedChangesAction {
  type: "SAVE_UNSAVED_CHANGES";
  initialState: string;
  isUnsavedChangesExist: boolean;
}

interface IUpdateUnsavedChangesAction {
  type: "UPDATE_UNSAVED_CHANGES"; 
  isUnsavedChangesExist: boolean;
}

type KnownAction = ISaveUnsavedChangesAction | IUpdateUnsavedChangesAction;

export const actionCreators = {
  saveUnsavedChanges: (initialState: any, isUnsavedChangesExist: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({
      type: "SAVE_UNSAVED_CHANGES",
      initialState,
      isUnsavedChangesExist
    });
  },
    updateUnsavedChanges: (isUnsavedChangesExist: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({
      type: "UPDATE_UNSAVED_CHANGES",      
      isUnsavedChangesExist
    });
  }
};

const unloadedState: IWarningMessageState = {
  initialState: null,
  isUnsavedChangesExist: false
};

export const reducer: Reducer<IWarningMessageState> = (state: IWarningMessageState | undefined, incomingAction: Action): IWarningMessageState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    
    case "SAVE_UNSAVED_CHANGES":
      return {
        initialState: action.initialState,
        isUnsavedChangesExist: action.isUnsavedChangesExist       
      }
      break;
    case "UPDATE_UNSAVED_CHANGES":
      return {
        initialState: state.initialState,
        isUnsavedChangesExist: action.isUnsavedChangesExist
      };
      break;
  }
  return state;
};
