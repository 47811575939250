import * as React from "react";
import ReactMarkdown from 'react-markdown';
import { Paper } from "@material-ui/core";
interface IProps {
  message: string;
}

type Props = IProps;
const isEmail = (text: string) => {
  if (!text) {
    return false;
  }
  const emailRegex = new RegExp(/\S+@\S+\.\S+/);
  return emailRegex.test(text);

}

const LinkRenderer = (props: any) => {
  if (isEmail(props.children)) {
    const mailtoHref = `mailto:${props.children}`;
    return (
      <a href={mailtoHref} >
        {props.children}
      </a>
    );
  }
  else {
    return (
      <a href={props.href} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    );
  }
}

const DisplayMessage = (props: Props) => {
  return (
    <>
      <div>
        <h4 >Technical Support</h4>
      </div>
      <Paper className="papergrid-space">
        <div>
          {<ReactMarkdown components={{ a: LinkRenderer }} children={props.message} />}
        </div>
      </Paper>
    </>
  );
}

export default DisplayMessage;