import React, { useEffect, useState } from "react";
import { User } from "../../../models/UserModels";
import { connect } from "react-redux";
import * as HeaderStore from "../../../store/Header";
import * as WarningMessageStore from "../../../store/WarningMessage";
import Paper from "@material-ui/core/Paper";
import { Grid } from "@material-ui/core";
import NotificationService from "../../../services/NotificationService";
import AdminContextService from "../../../services/AdminContextService";
import GridSelector from "../../common/grid/GridSelector";
import CommonHelper from "../../common/utilities/CommonHelper";
import "./CreateAdminContext.scss";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import RelativityInstanceList from "./RelativityInstanceList";
import RelativityClientList from "./RelativityClientList";
import { IRelClientSelectable, IRelInstanceSelectable } from "../../../models/RelativityModel";
import { IApplicationState } from "../../../store";
import AddUsersToClientAdmin from "./AddUsersToClientAdmin";
import { RadioButtonProps, RadioGroup, RadioGroupChangeEvent } from "@progress/kendo-react-inputs";
import JobsService, { IGetJobStatusResponseData } from "../../../services/JobService";
import { ServiceBase } from "../../../services/ServiceBase";
enum ErrorMessages {
  ImportProcessFailed = "Error occurred during import processing.",
  FetchJobStatusFailed = "Error occured during fetching the job status details.",
}

enum JobStates {
  ImportReadyForProcessing = 5,
  ImportProcessing = 6,
  ImportProcessedError = 7,
  ImportProcessedSuccess = 8,
  ImportProcessedPartialSuccess = 9,
  ImportCancelRequested = 10,
  ImportCancelling = 11,
  ImportCancelled = 12
}

interface IUploadValidationStatus extends IGetJobStatusResponseData {
  data: string;
}

interface IProps {
  setHeaderButtons: any;
  match?: any;
  history: any;
}

interface IClientAdminScopeOptions {
  id: number;
  name: string;
}

type Props = IProps &
  HeaderStore.IHeaderState &
  typeof HeaderStore.actionCreators &
  WarningMessageStore.IWarningMessageState &
  typeof WarningMessageStore.actionCreators;

const CreateAdminContext = (props: Props) => {
  const [contextName, setContextName] = useState<string>("");
  const [adminUsers, setAdminUsers] = useState<User[]>([]);
  const [memberUsers, setMemberUsers] = useState<User[]>([]);
  const [potentiallySelectedClients, setPotentiallySelectedClients] = useState<Array<IRelClientSelectable>>([]);
  const [selectedClients, setSelectedClients] = useState<Array<IRelClientSelectable>>([]);
  const [selectedRelInstances, setSelectedRelInstances] = useState<IRelInstanceSelectable>(null);
  const [enableAddRelClientBtn, setEnableAddRelClientBtn] = useState(false);
  const [enableAddRelInstanceBtn, setEnableAddRelInstanceBtn] = useState(false);
  const [isRelClientSelectorOpen, setIsRelClientSelectorOpen] = useState(false);
  const [selectedScope, setSelectedScope] = useState<IClientAdminScopeOptions>(null);
  const [isRelInstanceOpen, setIsRelInstanceOpen] = useState<boolean>(false);
  const [potentiallySelectedRelInstances, setPotentiallySelectedRelInstances] = useState<IRelInstanceSelectable>(null);
  const [selAddToExistingRelUsersType, setSelAddToExistingRelUsersType] = useState<number>(0);

  const [showAddUsers, setShowAddUsers] = useState<boolean>(false);
  const [showClientAdminScope, setShowClientAdminScope] = useState<boolean>(false);
  const [showRelInstance, setShowRelInstance] = useState<boolean>(false);
  const [showAddExistingRelUsersType, setShowAddExistingRelUsersType] = useState<boolean>(false);

  const scopeOptions = [{ id: 1, name: "Admin for one dedicated Relativity instance" },
  { id: 2, name: "Admin for one client in a multi-client Relativity instance" }
  ] as IClientAdminScopeOptions[];

  const addToExistingRelUsersOptions = [{ value: 0, label: "Yes, set up existing Relativity users with the proper access" },
  { value: 1, label: "No, the Client Administrator will manually do this" }] as RadioButtonProps[];

  const handleCancelClick = () => {
    clearFields();
  };

  const clearFields = () => {
    setContextName("");
    setAdminUsers([]);
    setPotentiallySelectedClients([]);
    setSelectedClients([]);
    setSelectedRelInstances(null);
    setSelectedScope(null);
    setPotentiallySelectedRelInstances(null);
    setEnableAddRelClientBtn(false);
    setEnableAddRelInstanceBtn(false);
    setIsRelClientSelectorOpen(false);
    setIsRelInstanceOpen(false);
    setShowAddUsers(false);
    setShowClientAdminScope(false);
    setShowRelInstance(false);
    setSelAddToExistingRelUsersType(0);
    setShowAddExistingRelUsersType(false);
  }

  const getBtnsList = (isSaveDisabled: boolean, isSaving: boolean) => {
    const buttons: any = [
      {
        buttonLabel: "Cancel",
        type: "button",
        handleClick: handleCancelClick
      },

      {
        buttonLabel: "Save",
        type: "button",
        disabled: isSaveDisabled,
        handleClick: onAddBtnClick,
        color: "primary",
        isSaving: isSaving
      }
    ];
    return buttons;
  };

  useEffect(() => {
    props.setHeaderButtons(getBtnsList(true, false), "", "/administration/admin-context/0", "Back to create admin client");
    return () => {
      props.setHeaderButtons(null);
      props.saveUnsavedChanges(null, false);
    }
  }, []);

  useEffect(() => {
    if (contextName && adminUsers.length > 0 && selectedScope) {
      if ((selectedScope.id == 1 && selectedRelInstances) || (selectedScope.id == 2 && selectedClients.length > 0)) {
        props.setHeaderButtons(getBtnsList(false, false), "", "/administration/admin-context/0", "Back to create admin client");
      } else {
        props.setHeaderButtons(getBtnsList(true, false), "", "/administration/admin-context/0", "Back to create admin client");
      }
    } else {
      props.setHeaderButtons(getBtnsList(true, false), "", "/administration/admin-context/0", "Back to create admin client");
    }
  }, [contextName, adminUsers, selectedScope, selectedRelInstances, selectedClients, selAddToExistingRelUsersType]);


  const onAddBtnClick = async () => {
    props.setHeaderButtons(getBtnsList(true, true), "", "/administration/admin-context/0", "Back to create admin client");

    const resources = selectedScope.id == 1 ? [selectedRelInstances.resourceId] : selectedClients.map(r => r.resourceId);
    var result = await AdminContextService.createAdminContext({
      name: contextName, resourceIds: resources, adminUserIds: adminUsers.map(a => a.id),
      addAllExistingRelUsersToEAContext: selAddToExistingRelUsersType === 0 ? true : false,
      memberUserIds: memberUsers.map(m => m.id)
    });

    if (result.ok) {

      if (result.data && !result.data.inValid) {
        if (result.data.data && result.data.data.userImportJob) {
          NotificationService.showInfoToast("Client Admin successfully created, please wait while we import the users.");
          props.setHeaderButtons(getBtnsList(true, false), "", "/administration/admin-context/0", "Back to create admin client");
          clearFields();
          await fetchJobStatus(result.data.data.userImportJob);
        } else {
          NotificationService.showSuccessToast("Successfully created context admin.");
          props.setHeaderButtons(getBtnsList(true, false), "", "/administration/admin-context/0", "Back to create admin client");
          clearFields();
        }

      } else {
        const errorMessage = result.data && result.data.message ? result.data.message.toLocaleLowerCase() : "";
        if (errorMessage === "resource group already exists." || errorMessage === "usergroup already exists."
          || errorMessage === "role already exists." || errorMessage === "assignment already exists."
          || errorMessage === "context name already exists.") {
          NotificationService.showErrorToast("Name already exist.");
        } else {
          NotificationService.showErrorToast("Something went wrong. Unable to create context admin.");
        }
        props.setHeaderButtons(getBtnsList(true, false), "", "/administration/admin-context/0", "Back to create admin client");
      }

    }
    else {
      NotificationService.showErrorToast("Something went wrong. Unable to create context admin.");
      props.setHeaderButtons(getBtnsList(true, false), "", "/administration/admin-context/0", "Back to create admin client");
    }
  }

  const handleSelectedInstance = (selected: IRelInstanceSelectable) => {
    setPotentiallySelectedRelInstances(selected);
    setEnableAddRelInstanceBtn(selected ? true : false);
  }

  const handlePotentiallySelected = (selected: Array<IRelClientSelectable>) => {
    setPotentiallySelectedClients(selected);
    setEnableAddRelClientBtn(selected && selected.length > 0);
  };

  const handleRelClientPopupClose = (accepted: boolean) => {
    if (accepted) {
      const dedupeObjects = CommonHelper.dedupeObjects<IRelClientSelectable>(
        potentiallySelectedClients,
        selectedClients || new Array<IRelClientSelectable>(),
        "uniqueClientId"
      );
      const dedupeRelClients = dedupeObjects.map(
        item => (({ ...item, selected: false } as any) as IRelClientSelectable)
      ) as Array<IRelClientSelectable>;
      setSelectedClients(dedupeRelClients);
      setShowAddExistingRelUsersType(true);
    }

    setIsRelClientSelectorOpen(false);
    setEnableAddRelClientBtn(false);
  };

  const handleRelInstancePopupClose = (accepted: boolean) => {
    if (accepted) {
      const dedupeRelInstances = { ...potentiallySelectedRelInstances, selected: false };
      setSelectedRelInstances(dedupeRelInstances);
      if (selectedScope.id === 1) {
        setShowAddExistingRelUsersType(true);
      }
    }

    setIsRelInstanceOpen(false);
    setEnableAddRelInstanceBtn(false);
  }

  const removeSelectedClients = (item: IRelClientSelectable) => {
    const relClients = [...selectedClients];
    for (let s = 0; s < relClients.length; s++) {
      if (relClients[s].uniqueClientId == item.uniqueClientId) {
        relClients.splice(s, 1);
        setSelectedClients(relClients);
        return;
      }
    }

  }

  const relInstanceSaveSelection = () => {
    setSelectedClients([]);
  }

  const handleAddToExistingRelUsers = (e: RadioGroupChangeEvent) => {
    setSelAddToExistingRelUsersType(e.value);
  };

  const fetchJobStatus = async (userImportJobId: string) => {
    if (userImportJobId) {
      const { ok, data } = await JobsService.getJobStatus<IUploadValidationStatus>(userImportJobId);

      if (ok) {
        const jobData = data.data ? JSON.parse(data.data) : null;
        const importData = jobData && jobData.Data && jobData.Data.ImportJobData && jobData.Data.ImportJobData.Data;

        switch (data.statusId) {
          case JobStates.ImportReadyForProcessing:
          case JobStates.ImportProcessing:
            await ServiceBase.setTimeoutPromise(1000);
            await fetchJobStatus(userImportJobId);
            break;
          case JobStates.ImportProcessedError:
            if (importData && importData.Error.Message && importData.Error.Message.length > 0) {
              NotificationService.showErrorToast(`${ErrorMessages.ImportProcessFailed} ${importData.Error.Message}`);
            }
            break;
          case JobStates.ImportProcessedSuccess:
            NotificationService.showSuccessToast(
              `${importData.Success.NumUpdate} users added to the Client Admin.`
            );
            break;
        }
      } else {
        NotificationService.showErrorToast("Failed to get job status. Please re-initiate the process.");
      }
    }
  }

  return (
    <Paper className="create-client-wrapper no-separator">
      <div className="client-admin-name">Set Up Client Admin</div>
      <p className="client-admin-desc">Identify specific Epiq Access users as Client Admins in Relativity. This role can administer a dedicated instance, a client in a multi-client instance,
        or one or more workspaces.</p>
      <div className="create-client-context-options">
        <Grid container>
          <Grid item sm={12} md={12} lg={10}>
            <div className="text-input">
              <label>Enter a name for the Client Relativity Admin Group.</label>
              <input
                type="text"
                name="context_name"
                className="k-textbox"
                value={contextName}
                maxLength={250}
                autoComplete="off"
                onChange={(e) => {
                  setContextName(e.target.value);
                  setShowAddUsers(true);
                }}
              />

            </div>

            {showAddUsers &&
              <div className="client-admin-add-users">
                <label>Search for and select the users to define as Client Admins.</label>
                <AddUsersToClientAdmin
                  onAddUpdateUsers={users => {
                    setAdminUsers(users);
                    setShowClientAdminScope(true);
                  }}
                  users={adminUsers} />
              </div>
            }

            {showClientAdminScope && <div className="client-admin-scope">
              <label>Choose the scope of this Client Admin.</label>
              <DropDownList
                data={scopeOptions}
                defaultValue={selectedScope && scopeOptions.find(e => e.id === selectedScope.id)}
                onChange={e => {
                  setSelectedScope(e.target.value);
                  setShowRelInstance(true);
                  selectedRelInstances && setShowAddExistingRelUsersType(true);
                }}
                className="clientAdminScopeDdl"
                key="id"
                textField="name"
                dataItemKey="id"
              />
            </div>}

          </Grid>
        </Grid>

        {showRelInstance &&
          <Grid container className="rel-instance-container" >
            <Grid item sm={5} md={4} lg={3} className="rel-instance-container-item-label">
              <label>Select the Relativity Instance.</label>
              <Button
                icon={"plus"}
                onClick={() => setIsRelInstanceOpen(true)}
                className="btn btn-primary rel-instance-btn"
              >
                Relativity Instance
              </Button>
            </Grid>

            {selectedRelInstances && <Grid item sm={7} md={8} lg={7} className="rel-instance-sel-item-grid">
              {<span>{selectedRelInstances.name}</span>}
            </Grid>
            }

          </Grid>

        }

        {selectedScope && selectedScope.id == 2 && selectedRelInstances &&
          <Grid container className="rel-client-container">
            <Grid item sm={5} md={4} lg={3} className="rel-client-container-item-label">
              <label>Select the Relativity Client.</label>
              <Button
                icon={"plus"}
                onClick={() => setIsRelClientSelectorOpen(true)}
                className="btn btn-primary rel-client-btn"
              >
                Relativity Client
              </Button>
            </Grid>

            {selectedClients.length > 0 && <Grid item sm={7} md={8} lg={7} className="resource-sel-item-grid">
              {selectedClients.map((item) => (
                <label className="resource-sel-item-btn">
                  <span className="k-icon k-i-minus-outline" onClick={(e) => { removeSelectedClients(item); }}></span> {item.name}
                </label>
              ))}
            </Grid>
            }

          </Grid>
        }

        {showAddExistingRelUsersType &&
          <div className="add-existing-rel-users-container">
            <label>Do you want Epiq to retrieve and set up the existing Relativity user list?</label>
            <p>This sets these users up with Epiq Access accounts pointed to the proper Application tile.</p>
            <RadioGroup
              data={addToExistingRelUsersOptions}
              value={selAddToExistingRelUsersType}
              onChange={handleAddToExistingRelUsers}
              className="add-existing-rel-users-radio"
            />
          </div>
        }

        <div className="rel-instance-list-wrapper">
          <GridSelector
            addClass="rel-instance-list-modal"
            isOpen={isRelInstanceOpen}
            acceptBtnText="Save Selection"
            cancelBtnText="Cancel"
            prefixTitleText="Select Relativity Instance"
            titleText="Select one"
            onClose={handleRelInstancePopupClose}
            addAvailable={enableAddRelInstanceBtn}
            onApply={relInstanceSaveSelection}
            fullWidth={false}
            maxWidth="md"
          >
            {isRelInstanceOpen && <RelativityInstanceList
              enableSelectInstance={true}
              selectionChange={handleSelectedInstance}
            />}
          </GridSelector>
        </div>

        <div className="new-context-admin-resource-list-wrapper">
          <GridSelector
            addClass="new-context-admin-resource-list-modal"
            isOpen={isRelClientSelectorOpen}
            acceptBtnText="Save Selection"
            cancelBtnText="Cancel"
            prefixTitleText="Select Relativity Client"
            titleText="Identify specific Epiq Access users as Client Admins in Relativity. This role can administer a dedicated instance, a client in a multi-client instance, or one or more workspaces."
            addAvailable={enableAddRelClientBtn}
            onClose={handleRelClientPopupClose}
            fullWidth={false}
            maxWidth="md"
          >
            <RelativityClientList
              enableSelectClient={true}
              selectionChange={handlePotentiallySelected}
              instance={selectedRelInstances}
            />
          </GridSelector>
        </div>

      </div>
    </Paper>
  );
};

export default connect((state: IApplicationState) => ({ ...state.headerState, ...state.warningMessageState }), {
  ...HeaderStore.actionCreators,
  ...WarningMessageStore.actionCreators
})(CreateAdminContext as any);
