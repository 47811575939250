import * as React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import * as WarningMessageStore from "../../store/WarningMessage";
import { Prompt } from "react-router-dom";
import { withRouter } from "react-router-dom";
import DialogBox from "../common/DialogBox";
import { DialogCloseEvent } from "@progress/kendo-react-dialogs";
import { PropTypes } from "@material-ui/core";

interface IProps {
  history?: any;
}

interface DialogBoxProps {
  title: string;
  content: string;
  okButtonLabelText: string;
  cancelButtonLabelText: string;
  openDiaglogButtonText: string;
  dailogInfo: string;
  color: PropTypes.Color;
}

type Props = IProps & WarningMessageStore.IWarningMessageState & typeof WarningMessageStore.actionCreators;

type State = {
  modalVisible: boolean;
  lastLocation: any;
  dialogBoxProps: DialogBoxProps;
};

class WarningMessagePopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalVisible: false,
      lastLocation: null,
      dialogBoxProps: {
        title: "Are you sure you want to leave this page?",
        content: "Changes you made may not be saved",
        okButtonLabelText: "LEAVE",
        cancelButtonLabelText: "STAY",
        openDiaglogButtonText: "",
        dailogInfo: " ",
        color: "default"
      }
    };
  }


  componentWillReceiveProps(nextProps: any) {
    if (nextProps.isUnsavedChangesExist !== this.props.isUnsavedChangesExist) {
      if (nextProps.isUnsavedChangesExist) {
        window.addEventListener("beforeunload", this.warnAboutUnsavedChanges);
      } else {
        window.removeEventListener('beforeunload', this.warnAboutUnsavedChanges);
      }
    }
  }

  componentWillUnmount() {
    this.setState({ modalVisible: false });
    window.removeEventListener('onbeforeunload', this.warnAboutUnsavedChanges);
  }

  warnAboutUnsavedChanges = (event: BeforeUnloadEvent) => {
    event.preventDefault();

    if (this.props.isUnsavedChangesExist) {
      return event.returnValue = "";
    }
  }

  showModal = (location: any) => {
    this.setState({ modalVisible: true, lastLocation: location });
  }

  closeModal = (callback: any) => {
    this.setState({ modalVisible: false }, callback);
  }

  navigate = (path: string, state?: any) => {
    if (state) {
      this.props.history.push({ pathname: path, state: state });
    }
    else {
      this.props.history.push(path);
    }
  }

  toggleDialogEvent = (event: React.MouseEvent<HTMLElement>) => {
    if (event.currentTarget.textContent === "LEAVE") {
      this.closeModal(() => {
        const { lastLocation } = this.state;
        if (lastLocation) {
          this.navigate(lastLocation.pathname, lastLocation.state);
        }
      });
      this.props.updateUnsavedChanges(false);
    } else {
      this.setState({ modalVisible: false });
    }
  };

  dialogCloseEvent = (event: DialogCloseEvent) => {
    this.setState({ modalVisible: false });
  };

  handleBlockedNavigation = (nextLocation: any) => {
    if (this.props.isUnsavedChangesExist) {
      this.showModal(nextLocation);
      return false;
    }

    return true;
  };

  render() {
    const { modalVisible, dialogBoxProps } = this.state;
    return (
      <>
        <Prompt when={this.props.isUnsavedChangesExist} message={this.handleBlockedNavigation} />

        <DialogBox
          title={dialogBoxProps.title}
          content={dialogBoxProps.content}
          visible={modalVisible}
          toggleDialogEvent={this.toggleDialogEvent}
          diagCloseEvent={this.dialogCloseEvent}
          okButtonLabelText={dialogBoxProps.okButtonLabelText}
          dailogInfo={dialogBoxProps.dailogInfo}
          cancelButtonLabelText={dialogBoxProps.cancelButtonLabelText}
          openDiaglogButtonText={dialogBoxProps.openDiaglogButtonText}
          color={dialogBoxProps.color}
        />
      </>
    );
  }
}

export default connect((state: IApplicationState) => ({ ...state.warningMessageState }), { ...WarningMessageStore.actionCreators })(withRouter(WarningMessagePopup as any));
