import { ServiceBase, ResponseMessages, ServiceResponseJson, ServiceResponse, ServiceResponseData } from "./ServiceBase";
import { IPermissionGroup, IResourceGroupResourceRequest, IResourceParams, IResourceGroupResourceParams, ResourceGroupDtls, UpsertResourceDetails, UpsertResourceGroup } from "../models/ResourceModels"; // TODO do we need PermissionGroupListRequest as the request or will GridParams suffice?
import GridParams from "../components/common/GridHelper";
import { IGridParams, IGridParamsAdvancedRefreshable } from "../components/common/grid/AdvancedGrid";

export interface IResourceGroupPermission {
  permissionCode: string;
  resourceGroupId: number;
  resourceGroupName: string;
  roleId: number;
  roleName: string;
  userGroupId: number;
  userGroupName: string;
}

const GetPermissionGroupMessages: ResponseMessages = {
  FailedMessage: "Failed to get permission groups.",
  SuccessMessage: "Successfully retrieved permission groups."
};

const DeletePermissionGroupMessages: ResponseMessages = {
  FailedMessage: "Failed delete permission groups.",
  SuccessMessage: "Successfully deleted permission groups."
};

const UpdatePermissionGroupMessages: ResponseMessages = {
  FailedMessage: "Failed to update permission groups.",
  SuccessMessage: "Successfully updated permission groups."
};

const AddPermissionGroupMessages: ResponseMessages = {
  FailedMessage: "Failed to add permission groups.",
  SuccessMessage: "Successfully added permission groups."
};

const GetResourceMessages: ResponseMessages = {
  FailedMessage: "Failed to get resources.",
  SuccessMessage: "Successfully retrieved resources."
};

const GetResourceGroupMessages: ResponseMessages = {
  FailedMessage: "Failed to get resource groups.",
  SuccessMessage: "Successfully retrieved resource groups."
};

const GetResourceGroupDetailsMessages: ResponseMessages = {
  FailedMessage: "Failed to get resource group details.",
  SuccessMessage: "Successfully retrieved resource group details."
};

const CreateResourceGroupMessages: ResponseMessages = {
  FailedMessage: "Failed to create resource group.",
  SuccessMessage: "Successfully created resource group."
};

const UpsertResourcesForGroup: ResponseMessages = {
  FailedMessage: "Failed to update resources for group.",
  SuccessMessage: "Successfully updated resources for group."
};

const GetResourceGroupByIdMessages: ResponseMessages = {
  FailedMessage: "Failed to get resource group details for group.",
  SuccessMessage: "Successfully retrieved resource group details."
};

const UpsertResourceGroupsDetailsMessages: ResponseMessages = {
  FailedMessage: "Failed to update resource group details.",
  SuccessMessage: "Successfully update resource group details."
};

const GetResourceUserGroupRolesMessages: ResponseMessages = {
  FailedMessage: "Get Resource's User Group Roles failed.",
  SuccessMessage: "Get Resource's User Group Roles succeeded."
};

const GetDownloadUriMessages: ResponseMessages = {
  FailedMessage: "Fetching download uri failed.",
  SuccessMessage: "Fetching download uri succeeded."
};

const DeleteRoleMessages: ResponseMessages = {
  FailedMessage: "Delete resource group failed.",
  SuccessMessage: "Delete resource group succeeded."
};

const ReactivateRoleMessages: ResponseMessages = {
  FailedMessage: "Reactivate resource group failed.",
  SuccessMessage: "Reactivate resource group succeeded."
};

const CheckDeleteResourceGroupMessages: ResponseMessages = {
  FailedMessage: "Checking the possibilities of delete resource group failed.",
  SuccessMessage: "Checking the possibilities of delete resource group succeeded."
};

const GetResourceAssignmentMessages: ResponseMessages = {
  FailedMessage: "Get resource assignments failed.",
  SuccessMessage: "Get resource assignments succeeded."
};

const GetUserResourcePermissionMessages: ResponseMessages = {
  FailedMessage: "Failed to get user resource permissions.",
  SuccessMessage: "Successfully retrieved user resource permissions."
};

export default class ResourceService extends ServiceBase {
  static async getPermissionGroups(permissionGroupRequest: IGridParams, abortSignal?: AbortSignal) {
    const filterJSON = JSON.stringify(permissionGroupRequest.filters) || "";
    const sortJSON = JSON.stringify(permissionGroupRequest.sort) || "";
    const url = `${window.ApplicationVariables.apiUri}resource/permission-group?${this.getFilterSortQueryString(filterJSON, sortJSON)}&${this.toQueryString(permissionGroupRequest, ["filters", "sort", "filter"])}`;
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async (response) => await this.handleResponseJson(response, GetPermissionGroupMessages))
      .catch(async (response) => await this.handleResponseJson(response, GetPermissionGroupMessages));

    return result;
  }

  static async getResourceTypes(abortSignal?: AbortSignal) {
    const url = `${window.ApplicationVariables.apiUri}resource/resource-types`;
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async (response) => await this.handleResponseJson(response, GetPermissionGroupMessages))
      .catch(async (response) => await this.handleResponseJson(response, GetPermissionGroupMessages));

    return result;
  }

  static async getResourceTypeDetails(abortSignal?: AbortSignal) {
    const url = `${window.ApplicationVariables.apiUri}resource/resource-types-details`;
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async (response) => await this.handleResponseJson(response, GetPermissionGroupMessages))
      .catch(async (response) => await this.handleResponseJson(response, GetPermissionGroupMessages));

    return result;
  }

  static async updatePermissionGroups<T>(permissionGroup: IPermissionGroup, abortSignal?: AbortSignal) {
    const url = window.ApplicationVariables.apiUri + "resource/permission-group?";
    const result: ServiceResponseData<T> = await fetch(url, {
      body: JSON.stringify(permissionGroup),
      ...this.fetchPutOptions()
    })
      .then((response) => this.handleResponseData<T>(response, UpdatePermissionGroupMessages))
      .catch((response) => this.handleResponseData<T>(response, UpdatePermissionGroupMessages));

    return result;
  }

  static async addPermissionGroups<T>(permissionGroup: IPermissionGroup, abortSignal?: AbortSignal) {
    const url = window.ApplicationVariables.apiUri + "resource/permission-group?";
    const result: ServiceResponseData<T> = await fetch(url, {
      body: this.toPostBody(permissionGroup),
      ...this.fetchPostOptions()
    })
      .then((response) => this.handleResponseData<T>(response, AddPermissionGroupMessages))
      .catch((response) => this.handleResponseData<T>(response, AddPermissionGroupMessages));

    return result;
  }

  static async deletePermissionGroups(permissionGroupId: number, abortSignal?: AbortSignal) {
    const url =
      window.ApplicationVariables.apiUri + "resource/permission-group?resourcePermissionsJoinId=" + permissionGroupId;
    const result: ServiceResponse = await fetch(url, this.fetchDeleteOptions())
      .then(async (response) => await this.handleResponse(response, DeletePermissionGroupMessages))
      .catch(async (response) => await this.handleResponse(response, DeletePermissionGroupMessages));

    return result;
  }

  static async getResources(params: GridParams, abortSignal?: AbortSignal) {
    const url = window.ApplicationVariables.apiUri + "resource?" + this.toQueryString(params);
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async (response) => await this.handleResponseJson(response, GetResourceMessages))
      .catch(async (response) => await this.handleResponseJson(response, GetResourceMessages));

    return result;
  }

  static async getResourceGroupDetailsByName(groupName: string) {
    let url = window.ApplicationVariables.apiUri + "resource/groups/by-name";
    if (typeof groupName !== "undefined") {
      url = `${url}?name=${groupName}`;
    }
    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(response => this.handleResponseJson(response, GetResourceGroupDetailsMessages))
      .catch(response => this.handleResponseJson(response, GetResourceGroupDetailsMessages));

    return result;
  }

  static async getResourcesDetailsBatch(params: IGridParamsAdvancedRefreshable, resourceType: string, abortSignal?: AbortSignal) {
    const filterJSON = JSON.stringify(params) || "";
    const url = `${window.ApplicationVariables.apiUri}resource/user-batch?queryOptions=${this.encodeToBase64(filterJSON)}&typeCode=${resourceType}`;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async (response) => await this.handleResponseJson(response, GetResourceMessages))
      .catch(async (response) => await this.handleResponseJson(response, GetResourceMessages));

    return result;
  }

  static async getResourcesDetails(params: IResourceParams, abortSignal?: AbortSignal) {

    const filterJSON = JSON.stringify(params.filters) || "";

    const url = `${window.ApplicationVariables.apiUri}resource/details?${ResourceService.getFilterSortQueryString(filterJSON, "")}&${ResourceService.toQueryString(params, ["filters", "sort", "filter"])}`;

    const result: ServiceResponseJson = await fetch(url, ResourceService.fetchOptions(abortSignal))
      .then(async (response) => await ResourceService.handleResponseJson(response, GetResourceMessages))
      .catch(async (response) => await ResourceService.handleResponseJson(response, GetResourceMessages));

    return result;
  }

  static async getResourcesUser(params: IResourceParams, abortSignal?: AbortSignal) {

    const filterJSON = JSON.stringify(params.filters) || "";
    //const url = `${window.ApplicationVariables.apiUri}resource/permission-group?${this.getFilterSortQueryString(filterJSON, sortJSON)}&${this.toQueryString(permissionGroupRequest, ["filters", "sort", "filter"])}`;

    const url = window.ApplicationVariables.apiUri + "resource/user?" + ServiceBase.toQueryString(params, ["filters", "sort", "filter"])
      + "&filters=" + ServiceBase.encodeToBase64(filterJSON);
    const result: ServiceResponseJson = await fetch(url, ServiceBase.fetchOptions(abortSignal))
      .then(async (response) => await ServiceBase.handleResponseJson(response, GetResourceMessages))
      .catch(async (response) => await ServiceBase.handleResponseJson(response, GetResourceMessages));

    return result;
  }

  static async getResourcesUserWithDefaultEmpty(params: IResourceParams, abortSignal?: AbortSignal) {

    const filterJSON = JSON.stringify(params.filters) || "";
    //const url = `${window.ApplicationVariables.apiUri}resource/permission-group?${this.getFilterSortQueryString(filterJSON, sortJSON)}&${this.toQueryString(permissionGroupRequest, ["filters", "sort", "filter"])}`;

    const url = window.ApplicationVariables.apiUri + "resource/userWDefault?" + ServiceBase.toQueryString(params, ["filters", "sort", "filter"])
      + "&filters=" + ServiceBase.encodeToBase64(filterJSON);
    const result: ServiceResponseJson = await fetch(url, ServiceBase.fetchOptions(abortSignal))
      .then(async (response) => await ServiceBase.handleResponseJson(response, GetResourceMessages))
      .catch(async (response) => await ServiceBase.handleResponseJson(response, GetResourceMessages));

    return result;
  }

  static async getResourcesDetailsCount(params: IResourceParams, abortSignal?: AbortSignal) {

    const filterJSON = JSON.stringify(params.filters) || "";

    const url = `${window.ApplicationVariables.apiUri}resource/details-count?${ServiceBase.getFilterSortQueryString(filterJSON, "")}&${ServiceBase.toQueryString(params, ["filters", "sort", "filter"])}`;

    const result: ServiceResponseJson = await fetch(url, ServiceBase.fetchOptions(abortSignal))
      .then(async (response) => await ServiceBase.handleResponseJson(response, GetResourceMessages))
      .catch(async (response) => await ServiceBase.handleResponseJson(response, GetResourceMessages));

    return result;
  }

  static async getResourcesGroupResources(params: IResourceGroupResourceRequest, abortSignal?: AbortSignal) {
    const url = window.ApplicationVariables.apiUri + "resource/groups/resource?" + ServiceBase.toQueryString(params);
    const result: ServiceResponseJson = await fetch(url, ServiceBase.fetchOptions(abortSignal))
      .then(async (response) => await ServiceBase.handleResponseJson(response, GetResourceGroupDetailsMessages))
      .catch(async (response) => await ServiceBase.handleResponseJson(response, GetResourceGroupDetailsMessages));

    return result;
  }

  static async getResourceGroupResources(params: IResourceGroupResourceParams, abortSignal?: AbortSignal) {
    const filterJSON = JSON.stringify(params.filters) || "";
    const sortJSON = JSON.stringify(params.sort) || "";
    let url = `${window.ApplicationVariables.apiUri}resource/group/resources?`

    url = `${url}${ServiceBase.getFilterSortQueryString(filterJSON, sortJSON)}&${ServiceBase.toQueryString(params, ["filters", "sort", "filter", "refreshToggle"])}`;

    const result: ServiceResponseJson = await fetch(url, ServiceBase.fetchOptions(abortSignal))
      .then(async (response) => await ServiceBase.handleResponseJson(response, GetResourceGroupDetailsMessages))
      .catch(async (response) => await ServiceBase.handleResponseJson(response, GetResourceGroupDetailsMessages));

    return result;
  } 

  static async getResourceGroups(params: GridParams, abortSignal?: AbortSignal) {
    let encodedParams: GridParams | string = '';

    if (params.skip !== undefined && params.take !== undefined && params.searchText !== undefined) {
      if(params.searchText.length === 0) {
        encodedParams =  {skip: params.skip, take: params.take};
      } else {
        encodedParams =  {...params, searchText: ServiceBase.encodeToBase64(params.searchText)};
      }
    }

    const url = window.ApplicationVariables.apiUri + "resource/group?" + this.toQueryString(encodedParams);
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async (response) => await this.handleResponseJson(response, GetResourceGroupMessages))
      .catch(async (response) => await this.handleResponseJson(response, GetResourceGroupMessages));

    return result;
  }

  static async getResourceGroupsList(params: IGridParams, abortSignal?: AbortSignal) {
    const filterJSON = JSON.stringify(params.filters) || "";
    const sortJSON = JSON.stringify(params.sort) || "";
    const url = window.ApplicationVariables.apiUri + `resource/groups-list?${this.getFilterSortQueryString(filterJSON, sortJSON)}&${this.toQueryString(params, ["filters", "sort", "filter"])}`;
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async (response) => await this.handleResponseJson(response, GetResourceGroupMessages))
      .catch(async (response) => await this.handleResponseJson(response, GetResourceGroupMessages));

    return result;
  }

  static async createResourceGroup(resourceGroup: ResourceGroupDtls, abortSignal?: AbortSignal) {
    const url = window.ApplicationVariables.apiUri + "resource/group";
    const result: ServiceResponseJson = await fetch(url, {
      body: this.toPostBody(resourceGroup),
      ...this.fetchPostOptions(abortSignal)
    })
      .then(async (response) => await this.handleResponseJson(response, CreateResourceGroupMessages))
      .catch(async (response) => await this.handleResponseJson(response, CreateResourceGroupMessages));

    return result;
  }

  static async upsertResourcesForGroup(
    groupId: number,
    addResourceIds: number[],
    removeResourceIds: number[],
    deleteExisting: boolean,
    abortSignal?: AbortSignal
  ) {
    const url = window.ApplicationVariables.apiUri + "resource/groups/upsert-resources";
    const result: ServiceResponseJson = await fetch(url, {
      body: this.toPostBody({ groupId, addResourceIds, deleteExisting, removeResourceIds }),
      ...this.fetchPostOptions(abortSignal)
    })
      .then(async (response) => await this.handleResponseJson(response, UpsertResourcesForGroup))
      .catch(async (response) => await this.handleResponseJson(response, UpsertResourcesForGroup));

    return result;
  }

  static async getGroupPermissions(permissionFilter: string, abortSignal?: AbortSignal) {
    const url = window.ApplicationVariables.apiUri + `resource/groups/permissions?permissionFilter=${permissionFilter}`;
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async (response) => await this.handleResponseJson(response, GetResourceGroupMessages))
      .catch(async (response) => await this.handleResponseJson(response, GetResourceGroupMessages));

    return result;
  }

  static async getResourceGroupByGroupId(id: number, abortSignal?: AbortSignal) {
    const url = window.ApplicationVariables.apiUri + `resource/groups/by-id?resourceGroupId=${id}`;
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async (response) => await this.handleResponseJson(response, GetResourceGroupByIdMessages))
      .catch(async (response) => await this.handleResponseJson(response, GetResourceGroupByIdMessages));

    return result;
  }

  static async getResourceUserGroupRoles(objectId: number, resourceType: number, gridParams: IGridParams, abortSignal?: AbortSignal) {

    const filterJSON = JSON.stringify(gridParams.filters) || "";
    const sortJSON = JSON.stringify(gridParams.sort) || "";
    const url = window.ApplicationVariables.apiUri + `resource/getResourceUserGroupRoles?${this.getFilterSortQueryString(filterJSON, sortJSON)}&${this.toQueryString({ objectId, resourceType, gridParams }, ["filters", "sort", "filter"])}`;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async response => await this.handleResponseJson(response, GetResourceUserGroupRolesMessages))
      .catch(async response => await this.handleResponseJson(response, GetResourceUserGroupRolesMessages));

    return result;
  }


  static async upsertResourceGroupDetails(
    upsertResourceGroup: UpsertResourceGroup,
    abortSignal?: AbortSignal
  ) {
    const url = window.ApplicationVariables.apiUri + "resource/groups/upsert";
    const result: ServiceResponseJson = await fetch(url, {
      body: this.toPostBody(upsertResourceGroup),
      ...this.fetchPostOptions(abortSignal)
    })
      .then(async (response) => await this.handleResponseJson(response, UpsertResourceGroupsDetailsMessages))
      .catch(async (response) => await this.handleResponseJson(response, UpsertResourceGroupsDetailsMessages));

    return result;
  }

  static async getDownloadUri(jobId: string, maxFetchRetryAttempts: number) {
    const url = window.ApplicationVariables.apiUri + `resource/import/downloaduri?jobId=${jobId}`;
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetDownloadUriMessages))
      .catch(async response => await this.handleResponseJson(response, GetDownloadUriMessages));

    return result;
  }

  static async canDeleteResourceGroup(groupId: number) {
    let url = window.ApplicationVariables.apiUri + "resource/group/canDeleteResourceGroup";

    if (typeof groupId !== "undefined" && !isNaN(groupId)) {
      url = `${url}?groupId=${groupId}`;
    }
    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, CheckDeleteResourceGroupMessages))
      .catch(async response => await this.handleResponseJson(response, CheckDeleteResourceGroupMessages));

    return result;
  }

  static async deleteResourceGroup(resourceGroup: ResourceGroupDtls) {
    let url = window.ApplicationVariables.apiUri + "resource/group/delete";

    const result: ServiceResponse = await fetch(url, {
      body: this.toPostBody(resourceGroup),
      ...this.fetchPostOptions()
    }).then(response => this.handleResponse(response, DeleteRoleMessages))
      .catch(response => this.handleResponse(response, DeleteRoleMessages));

    return result;
  }

  static async reactivateResourceGroup(resourceGroup: ResourceGroupDtls) {
    let url = window.ApplicationVariables.apiUri + "resource/group/reactivate";

    const result: ServiceResponse = await fetch(url, {
      body: this.toPostBody(resourceGroup),
      ...this.fetchPostOptions()
    }).then(response => this.handleResponse(response, ReactivateRoleMessages))
      .catch(response => this.handleResponse(response, ReactivateRoleMessages));

    return result;
  }

  static async getResourceAssignments(params: IGridParams, abortSignal?: AbortSignal) {
    const filterJSON = JSON.stringify(params.filters) || "";
    const sortJSON = JSON.stringify(params.sort) || "";
    const url = window.ApplicationVariables.apiUri + `resource/getResourceAssignments?${this.getFilterSortQueryString(filterJSON, sortJSON)}&${this.toQueryString(params, ["filters", "sort", "filter"])}`;
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async (response) => await this.handleResponseJson(response, GetResourceAssignmentMessages))
      .catch(async (response) => await this.handleResponseJson(response, GetResourceAssignmentMessages));

    return result;
  }

  static async getResource(resourceId: number, abortSignal?: AbortSignal) {
    const url = window.ApplicationVariables.apiUri + `resource/getResource?resourceId=${resourceId}`;
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async (response) => await this.handleResponseJson(response, GetResourceAssignmentMessages))
      .catch(async (response) => await this.handleResponseJson(response, GetResourceAssignmentMessages));

    return result;
  }

  static async getUserResourcePermissions(objectId: number, abortSignal?: AbortSignal) {
    const url = window.ApplicationVariables.apiUri + `resource/getUserResourcePermissions?objectId=${objectId}`;
    const result: ServiceResponseJson = await fetch(url, ServiceBase.fetchOptions(abortSignal))
      .then(async (response) => await ServiceBase.handleResponseJson(response, GetUserResourcePermissionMessages))
      .catch(async (response) => await ServiceBase.handleResponseJson(response, GetUserResourcePermissionMessages));

    return result;
  }
}
