import React, { useEffect, useState } from "react";
import SupportService from "../../../services/SupportService";
import { IStatus, RequestType } from "./RequestGrid";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";

interface IProps {
  requestType: RequestType;
  isWatchlist: boolean;
  byProject: boolean;
  statuses: Array<IStatus>;
  selectedStatus: IStatus;
  changeStatus: (status: IStatus) => void;
  refresh: boolean;
}

type Props = IProps;

const RequestStatuses = (props: Props) => {
  const [statuses, setStatuses] = useState(props.statuses);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getStatusCounts(props.isWatchlist, props.byProject);
  }, [props.refresh]);

  const getStatusCounts = async (watchListDataSource?: boolean, byProject?: boolean) => {
    setLoading(true);
    const result = await SupportService.GetTicketStatusCounts(
      props.requestType === RequestType.Pulse,
      watchListDataSource,
      byProject
    );
    if (result.ok) {
      const res = result.data;
      updateStatusesForRequestTypes(res, watchListDataSource, byProject);
    } else {
      console.log("error!");
    }
    setLoading(false);
  }

  const updateStatusesForRequestTypes = (result: any, watchListDataSource?: boolean, byProject?: boolean) => {
    let ticketStatuses = props.statuses;
    const availableStatuses = result.results;

    if (availableStatuses) {

      ticketStatuses = ticketStatuses.map(status => {
        if (status.status === "Open") {
          const openStatuses = availableStatuses.filter((item: IStatus) => item.status !== "Complete") as Array<IStatus>;
          const openCount = openStatuses.reduce((sum, status) => sum = sum + status.count, 0);
          return { ...status, count: openCount };
        } else {
          const selStatus = availableStatuses.find((item: IStatus) => item.status === status.status);
          return { ...status, count: selStatus ? selStatus.count : status.count };
        }
      });

    }

    setStatuses(ticketStatuses);
  }

  return (
    <div className="request-tabs-status-buttons">
      {loading && <div className="counts-progress"><Loader /> Updating counts...</div>}
      <div className="status">
        {
          statuses.map((status: IStatus, index: number) => {
            const statusClass = `status-circle ${status.title
              .toLowerCase()
              .trim()
              .replace(/\s+|[,\/]/g, "-")}`;

            const btnCls = (props.selectedStatus && props.selectedStatus.status === status.status) ? "status-label active-status" : "status-label";

            return (
              <Button
                className={btnCls}
                key={index}
                value={status.status}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  const selectedStatus = statuses.find(s => s.status === e.currentTarget.value);
                  props.changeStatus(selectedStatus);
                }}
              >
                <span className={statusClass}></span>
                {status.text || status.title} {status.count > 0 ? (status.count > 100 ? "(100+)" : `(${status.count})`) : ""}
              </Button>
            );

          })}
      </div>
    </div>
  )
}

export default RequestStatuses;