import React from "react";
import IdleTimer from "react-idle-timer";
import { IIdleTimer, ISessionManager } from "../../SessionManager";

interface IProps {
  sessionManager: ISessionManager;
}

type Props = IProps// & SessionStore.ISessionState;

const SessionSync = (props: Props) => {

  return <IdleTimer
            ref={(ref: IIdleTimer) => {
              props.sessionManager.sessionSyncTimer = ref;
            }}
            events={[]}
            element={document}
            onIdle={props.sessionManager.handleSessionSync.bind(props.sessionManager)}
            timeout={props.sessionManager.windowAndTabSyncTimeout}
          />
};

export default SessionSync;