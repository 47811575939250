import * as React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import * as ApplicationsStore from "../../store/Applications";
import * as NotificationStore from "../../store/NotificationMessages";
import * as SessionStore from "../../store/Session";
import * as HeaderStore from "../../store/Header";
import { ISessionManager } from "../../SessionManager";
import { EpiqHeaderComponent } from "../edge/header/EpiqHeader";
import epiqLogo from "../../images/Epiq_access_logo_horz.svg";
import "./Header.scss";

interface IProps {
  sessionManager: ISessionManager;
  hideRightDropDownMenu?: boolean;
}

type Props = IProps & SessionStore.ISessionState & HeaderStore.IHeaderState & ApplicationsStore.IApplicationsState & NotificationStore.INotificationMessageState;

type RightMenuOption = {
  title: string;
  onItemClick?: () => void;
  url?: string;
};

class HeaderWrapper extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const rightDropDownMenuOptions: Array<RightMenuOption> = new Array<RightMenuOption>();

    if (this.props.sessionData.impersonateMode) {
      rightDropDownMenuOptions.push({
        title: "Exit View As",
        onItemClick: this.props.sessionManager.endImpersonation
      });
    } else {
      rightDropDownMenuOptions.push({
        title: "Logout",
        onItemClick: this.props.sessionManager.clearSession
      });
    }

    const rightMenuOptions: Array<RightMenuOption> = new Array<RightMenuOption>();
    const menuBars = new Array();

    const subHeaderEnabled =
      (this.props.buttons && this.props.buttons.length > 0) ||
      (this.props.pageTitle && this.props.buttons.length === 0) ||
      (this.props.breadCrumbUrl && this.props.breadCrumbTitle);
    const noAccess = rightMenuOptions.length === 0 && menuBars.length === 0;

    return (
      <>
        <div className={subHeaderEnabled ? "header-height-spacer-full" : "header-height-spacer-partial"} />
        <div className="header-wrapper">
          <EpiqHeaderComponent
            logo={epiqLogo}
            logoUrl={noAccess ? "" : "/applications"}
            menuBars={menuBars}
            rightDropDownMenuOptions={rightDropDownMenuOptions}
            rightMenuOptions={rightMenuOptions}
            userEmail={this.props.sessionData ? this.props.sessionData.username : null}
            userName={this.props.sessionData ? this.props.sessionData.firstName + " " + this.props.sessionData.lastName : ""}
            isEmulating={this.props.sessionData.impersonateMode}
            isFederated={this.props.sessionData.isFederated}
            isSessionBasic={this.props.sessionData.isBasic}
            hideRightDropDownMenu={this.props.hideRightDropDownMenu}
            notifications={this.props.notificationData}
            unReadNotificationCount={this.props.unReadNotificationCount}
            showAlertPopup={this.props.openAlertPopup}
          />
        </div>
      </>
    );
  }
}

export default connect(
  (state: IApplicationState) => ({
    ...state.applicationsState,
    ...state.sessionState,
    ...state.headerState,
    ...state.notificationMessageState
  }),
  null
)(HeaderWrapper);
