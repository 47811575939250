import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import { IWizardSection, IFieldValueLookup, IWizardRequest, IWizardValues, IWizardDropDownListItem } from "./Wizard";
import ResourceService from "../services/ResourceService";

export const ftpUrlList = [
  "https://transfer.epiqsystems.com",
  "https://transfer.epiqglobal.com",
  "https://transfer2.epiqglobal.com",
  "https://transfer42.dtiglobal.com",
  "https://transfer77.epiqglobal.com",
  "https://transfer77-wf.epiqglobal.com",
  "https://transfer.itar.epiqsystems.com",
  "https://asp54.epiqglobal.com",
  "https://transfer.us.epiqglobal.com",
  "https://transfer.epiqsystems.ca",
  "https://transfer.epiqglobal.com.sg",
  "https://transfer.epiqsystems.com.hk",
  "https://transfer.epiqsystems.co.jp",
  "https://transfer.epiqsystems.co.uk",
  "https://transfer.epiqsystems.de"
];


export const TrackDataWorkRequestSteps = [
  {
    header: "Request: Track FTP or Shipped Data",
    subHeader: "FTP DETAILS",
    summaryTitle: "FTP DETAILS",
    description:
      "To set up tracking for this data, provide the URL where the data was uploaded, the path to the documents, and whether the archive is password protected. ",
    //staticText: "Supply the location of the documents that you want to image or OCR, or both.",
    dependency: { fieldId: "8", compare: "eq", value: 2 },
    fields: [
      {
        type: "combobox",
        name: "Uploaded to URL",
        fieldId: "9.2.1",
        label: "Specify where you uploaded the file. Select an Epiq FTP URL or enter an external FTP URL.",
        active: true,
        DropDownOptions: ftpUrlList,
        requiredWhenActive: true,
      },
      {
        type: "input",
        name: "Path to Documents",
        fieldId: "9.2.2",
        label: "Provide the full path to the file you uploaded. (For example: A:\\Client-Test\\FTP\\Client007\\2022.06.09 or should start with \\\\).",
        active: false,
        requiredWhenActive: true,
        validation: { compare: "regexp", regExp: "^(\\\\\\\\|[a-zA-Z]:\\\\)(?!.+(\\\\\\\\|(\\.([a-z][0-9a-z]{1,3}|[0-9][a-z]{1,3}[0-9]?)$)))[^\\<\\>\\:\\\"\\/\\|\\?\\*]+$", value: "", failureMessage: "You have entered an invalid character in Source File Path field" },
        dependency: { fieldId: "9.2.1", compare: "neq", value: null }
      },
      {
        type: "dropdown",
        name: "Password protected?",
        fieldId: "9.3",
        label:
          "Is the data you provided in a password protected ZIP or RAR file?",
        active: false,
        dependency: { fieldId: "9.2.2", compare: "neq", value: null },
        DropDownOptions: [{ id: "Yes", title: "Yes" }, { id: "No", title: "No" }],
        requiredWhenActive: true
      },
      {
        type: "passwordField",
        name: "Password",
        subType: "password",
        fieldId: "9.3.1",
        label: "Enter the password for the protected file.",
        active: false,
        dependency: { fieldId: "9.3", compare: "eq", value: "Yes" },
        requiredWhenActive: true
      },

    ]
  }
] as Array<IWizardSection>;