import { IFieldValueLookup, WizardFieldTypes } from "./Wizard";

export const eventNames = { 'New': 'Click New WR', 'Initiate': 'Initiate WR', 'Submit': 'Submit WR', 'Cancel': 'Cancel WR' } as Record<string, string>;
export const createTrackRequest = function (valueDictionary?: IFieldValueLookup, stepField?: string) {
    let customProperties = {} as Record<string, WizardFieldTypes>;
    const lookup = function (id: string, lookup: string) {
      return valueDictionary[id] ? valueDictionary[id].lookupValues[lookup] : null;
    };
    const text = function (id: string) {
      return valueDictionary[id] ? valueDictionary[id].text : null;
    };
    const val = function (id: string) {
      return valueDictionary[id] ? valueDictionary[id].value : null;
    };

    if (valueDictionary != null) {
      const workRequestType = val("0.1.2");
      const projectNumber = lookup("0.1.1", "projectNumber");
      let activityType = null;

      if (projectNumber != null) {
        customProperties["Project P-Number"] = projectNumber;
        customProperties["Project Name"] = lookup("0.1.1", "projectName");
      }

      if (workRequestType != null) {
        customProperties["Work Request type"] = text("0.1.2");

        if (workRequestType == 1) {
          activityType = text("8")
        }
        else if (workRequestType == 2) {
          activityType = text("2")
        }
        else if (workRequestType == 3) {
          activityType = text("6")
        }
        else if (workRequestType == 4) {
          activityType = text("7")
        }

        if (activityType) {
          customProperties["Activity type"] = activityType;
        }
      }

    }

  if (stepField != null) {
    customProperties["Page and Field Number"] = stepField;
    }

    return customProperties;
  }