import * as React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../../store";
import * as HeaderStore from "../../../../store/Header";
import * as WarningMessageStore from "../../../../store/WarningMessage";
import ResourceService from "../../../../services/ResourceService";
import NotificationService from "../../../../services/NotificationService";
import CommonHelper from "../../../common/utilities/CommonHelper";
import {
  ResourceGroupDtls,
  IResourceGroupResource,
  UpsertResourceGroup,
  UpsertResourceDetails,
  IResourceGroupAutoModel,
  IResourceFields,
  IResourceTypeDetail
} from "../../../../models/ResourceModels";
import Resource from "./Resource";
import GridSelector from "../../../common/grid/GridSelector";
import ResourceList from "./ResourceList";
import { Paper, CircularProgress } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Dialog } from "@progress/kendo-react-dialogs";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import BulkResourceImport from "../bulkResourcesImport/BulkResourcesImport";
import "./ResourceGroupDetails.scss";
import * as SessionStore from "../../../../store/Session";
import { ShowEpiqOnlyControls } from "../../../../models/Enums";
import { FilterDescriptor } from "@progress/kendo-data-query";
import DynamicResourceList from "./DynamicResourceList";
import DialogBox from "../../../common/DialogBox";

enum Mode {
  Edit,
  Create,
  View
}

interface IProps {
  match?: any;
  history: any;
}

type Props = IProps &
  HeaderStore.IHeaderState &
  typeof HeaderStore.actionCreators &
  WarningMessageStore.IWarningMessageState &
  typeof WarningMessageStore.actionCreators &
  SessionStore.ISessionState;

type State = {
  resourceGroupDetails: ResourceGroupDtls;
  detailsHasError: boolean;
  loading: boolean;
  isSaving: boolean;
  mode: Mode;
  updateModel: ResourceGroupDtls;
  automationModel: IResourceGroupAutoModel;
  isFormIsValid: boolean;
  isAutomationReady: boolean;
  isResourceGroupAlreadyExist: boolean;
  isResourceSelectorOpen: boolean;
  enableAddButton: boolean;
  potentiallySelectedResources: Array<IResourceGroupResource>;
  selectedResources: Array<IResourceGroupResource>;
  savedResources: Array<IResourceGroupResource>;
  isSavedEditedDetailsIntoDB: boolean;
  resourceInfoSectionMode: Mode;
  importResourceModal: boolean;
  disableCancel: boolean;
  refreshToggle: boolean;
  openDeleteConfirmPopup: boolean;
  openGroupReactivatePopup: boolean;
  canReactivate: boolean;
  canDelete: boolean;
};

const ResourceGroupNameConfig = {
  MinimumCharacters: 3
};

const validationMsg = "Cannot remove Epiq Admin Only flag when group contains Epiq Admin resources. Remove these resources and try again.";
let hasEpiqError = false;

class ResourceGroupDetails extends React.Component<Props, State> {
  detailsGrid: ResourceList;

  constructor(props: Props) {
    super(props);
    const mode = Number(this.props.match.params.resourceGroupId) > 0 ? Mode.Edit : Mode.Create;
    this.state = {
      resourceGroupDetails: new ResourceGroupDtls(),
      detailsHasError: false,
      loading: false,
      isSaving: false,
      mode,
      updateModel: new ResourceGroupDtls(),
      isFormIsValid: false,
      isAutomationReady: true,
      isResourceGroupAlreadyExist: false,
      isResourceSelectorOpen: false,
      enableAddButton: false,
      potentiallySelectedResources: new Array<IResourceGroupResource>(),
      selectedResources: new Array<IResourceGroupResource>(),
      savedResources: new Array<IResourceGroupResource>(),
      isSavedEditedDetailsIntoDB: false,
      resourceInfoSectionMode: mode === Mode.Create ? Mode.Edit : Mode.View,
      importResourceModal: false,
      disableCancel: false,
      refreshToggle: false,
      automationModel: null,
      openDeleteConfirmPopup: false,
      openGroupReactivatePopup: false,
      canReactivate: false,
      canDelete: false
    };
  }

  async componentDidMount() {
    await this.props.setHeaderButtons(
      this.getBtnsList(true, false),
      "",
      "/administration/resourceGroups",
      "BACK TO RESOURCE GROUP LIST"
    );
    // TODO next story we'll want to include automationModel as part of the dirty flag check... 
    // also need to count empty arrays/objects as null for filter.... maybe convert them
    // Also when loading from file, we need to prepopulate usedFields fields by iterating the filter we we don't save the info to DB already
    if (this.state.mode === Mode.Edit) {
      await this.getResourceGroupDetailsById(this.props.match.params.resourceGroupId);
    }

    if (this.state.mode === Mode.Create) {
      await this.props.saveUnsavedChanges(JSON.stringify(this.state.updateModel), false);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
    if (prevState !== this.state) {
      const isDirtyChangesExist = this.isDirtyChangesExist();
      this.props.updateUnsavedChanges(isDirtyChangesExist);
      const isFormValid = this.isFormValidationsPassed();
      this.props.setHeaderButtons(
        this.getBtnsList(this.state.isSaving ? this.state.isSaving : !isFormValid, this.state.isSaving),
        "",
        "/administration/resourceGroups",
        "BACK TO RESOURCE GROUP LIST"
      );
    }
  }

  isDirtyChangesExist = () => {
    const { selectedResources, savedResources, updateModel } = this.state;

    return (
      this.props.initialState !== JSON.stringify(updateModel) ||
      this.getUniqueList(selectedResources, savedResources).length > 0 ||
      this.getUniqueList(savedResources, selectedResources).length > 0
    );
  };

  isFormValidationsPassed = () => {
    const {
      updateModel,
      isFormIsValid,
      isAutomationReady,
      mode,
      savedResources,
      selectedResources,
      isResourceGroupAlreadyExist
    } = this.state;
    const isGroupNameValidationPassed =
      updateModel.groupName && isResourceGroupAlreadyExist === false
        ? updateModel.groupName.length >= ResourceGroupNameConfig.MinimumCharacters
        : false;

    return (
      (isGroupNameValidationPassed && isFormIsValid && isAutomationReady && !hasEpiqError) ||
      (mode == Mode.Edit
        ? isGroupNameValidationPassed &&
        (this.getUniqueList(selectedResources, savedResources).length > 0 ||
          this.getUniqueList(savedResources, selectedResources).length > 0)
        : false)
    );
  };

  componentWillUnmount() {
    this.props.setHeaderButtons(null);
    this.props.saveUnsavedChanges(null, false);
    hasEpiqError = false;
  }

  handleCancelClick() {
    this.props.history.push("/administration/resourceGroups");
  }

  handleSaveClick = async () => {
    const { updateModel, selectedResources, savedResources } = this.state;
    this.props.setHeaderButtons(
      this.getBtnsList(true, true),
      "",
      "/administration/resourceGroups",
      "BACK TO RESOURCE GROUP LIST"
    );

    const requestModel = updateModel;
    let resourceGroupDetails;

    if (requestModel) {
      resourceGroupDetails = {
        id: requestModel.id ? requestModel.id : 0,
        groupName: requestModel.groupName,
        createdDate: requestModel.createdDate ? requestModel.createdDate : new Date(),
        description: requestModel.description ? requestModel.description : "",
        epiqOnly: requestModel.epiqOnly ? requestModel.epiqOnly : false,
        automationFilter: requestModel.automationFilter,
        automationRequireApprove: requestModel.automationRequireApprove,
        automationResourceType: requestModel.automationResourceType,
      } as ResourceGroupDtls;

      const response = await ResourceService.getResourceGroupDetailsByName(requestModel.groupName);
      if (response.ok) {
        if (response.data && response.data.isDeleted) {

          if (this.state.mode === Mode.Create) {
            this.setState({ canReactivate: true, openGroupReactivatePopup: true, resourceGroupDetails: response.data });
          } else {
            this.setState({ canReactivate: false, openGroupReactivatePopup: true });
          }

          return;
        }
      }
    }

    const upsertResourceDetails: UpsertResourceDetails = {
      groupId: requestModel && requestModel.id ? requestModel.id : 0,
      addResourceIds: this.getUniqueList(selectedResources, savedResources).map(item => {
        return item.id;
      }),
      removeResourceIds: this.getUniqueList(savedResources, selectedResources).map(item => {
        return item.id;
      })
    };

    const upsertResourceGroupDetailsModel: UpsertResourceGroup = {
      resourceGroupDetails: resourceGroupDetails,
      upsertResourceGroupDetails: upsertResourceDetails,

    };
    this.upsertResourceGroupDetailsIntoDB(upsertResourceGroupDetailsModel);
  }

  getUniqueList = (allResources: Array<IResourceGroupResource>, excludeResourceList: Array<IResourceGroupResource>) => {
    return allResources
      ? allResources.filter((resource: IResourceGroupResource) => {
        const excludeResource =
          (excludeResourceList &&
            excludeResourceList.find((preSelectedResource: IResourceGroupResource) => {
              return preSelectedResource.id === resource.id;
            })) ||
          false;

        return !excludeResource;
      })
      : new Array<IResourceGroupResource>();
  };

  automationSectionUpdated = (automationModel: IResourceGroupAutoModel) => {
    const editedGroupDetails: ResourceGroupDtls = {
      ...this.state.updateModel,
      automationFilter: automationModel.filter ? JSON.stringify(automationModel.filter) : null,
      automationRequireApprove: automationModel && automationModel.requireApproval != null ? automationModel.requireApproval : this.state.updateModel.automationRequireApprove,
      automationResourceType: automationModel ? automationModel.resourceTypeId : null,
    };

    this.setState({
      ...this.state,
      updateModel: editedGroupDetails,
      isFormIsValid: this.props.initialState !== JSON.stringify(editedGroupDetails),
      automationModel,
      isAutomationReady: true
    });
  };

  automationSectionChanged = (isReady: boolean) => {
    this.setState({ isAutomationReady: isReady });
  };

  automationRequiredChanged = (requireApproval: boolean) => {
    this.setState({
      automationModel: { ...this.state.automationModel, requireApproval },
      updateModel: { ...this.state.updateModel, automationRequireApprove: requireApproval },
      isFormIsValid: true
    });
  };

  updateResourceDetails = (editedResourceDetails: ResourceGroupDtls, isValid: boolean) => {
    const newGroupName = editedResourceDetails.groupName;
    const oldGroupName = this.state.updateModel.groupName;
    const oldEpiqOnly = this.state.updateModel.epiqOnly;
    const editedGroupDetails: ResourceGroupDtls = {
      ...this.state.updateModel,
      groupName: newGroupName,
      description: editedResourceDetails.description,
      epiqOnly: editedResourceDetails.epiqOnly
    };

    if (oldEpiqOnly != editedResourceDetails.epiqOnly) {
      if (!editedResourceDetails.epiqOnly) {
        var epiqOnlyResources = this.state.selectedResources.filter(f => f.epiqOnly == true);
        if (epiqOnlyResources.length > 0) {
          isValid = false;
          this.setState({ isSaving: false });
          hasEpiqError = true;
        }
        else {
          hasEpiqError = false;
        }
      }
      else {
        hasEpiqError = false;
      }
    }

    if (hasEpiqError) {
      isValid = false;
    }

    this.setState({
      ...this.state,
      isResourceGroupAlreadyExist:
        newGroupName.toLowerCase() === oldGroupName.toLowerCase() && this.state.isResourceGroupAlreadyExist
          ? this.state.isResourceGroupAlreadyExist
          : false,
      updateModel: editedGroupDetails,
      isFormIsValid: this.props.initialState !== JSON.stringify(editedGroupDetails) ? isValid : false,
      resourceInfoSectionMode: Mode.View
    });
  };

  upsertResourceGroupDetailsIntoDB = async (upsertResourceGroups: UpsertResourceGroup) => {
    const groupName = upsertResourceGroups.resourceGroupDetails.groupName;
    const { mode, selectedResources, savedResources } = this.state;
    const FailureMsg =
      mode == Mode.Create
        ? "Something went wrong. Resource group was not created."
        : "Something went wrong. Update not saved.";

    const result = await ResourceService.upsertResourceGroupDetails(upsertResourceGroups);

    if (result.ok) {

      const errorMessage = result.data as string;

      if (errorMessage && errorMessage.toLocaleLowerCase() === "resource group already exists.") {
        this.setState({ isResourceGroupAlreadyExist: true, isSaving: false });
        this.props.setHeaderButtons(
          this.getBtnsList(true, false),
          "",
          "/administration/resourceGroups",
          "BACK TO RESOURCE GROUP LIST"
        );
      }
      else if (errorMessage && errorMessage.toLocaleLowerCase() === validationMsg.toLocaleLowerCase()) {
        this.setState({ isSaving: false });
        this.props.setHeaderButtons(
          this.getBtnsList(true, false),
          "",
          "/administration/resourceGroups",
          "BACK TO RESOURCE GROUP LIST"
        );
      }
      else {
        if (mode == Mode.Create) {
          this.props.saveUnsavedChanges(null, false);
          NotificationService.showSuccessToast(`${groupName} Created.`);
          this.props.history.push("/administration/resourceGroups");
        } else {
          await this.props.setHeaderButtons(
            this.getBtnsList(true, false),
            "",
            "/administration/resourceGroups",
            "BACK TO RESOURCE GROUP LIST"
          );

          await this.props.saveUnsavedChanges(JSON.stringify(this.state.updateModel), false);

          let updatedResourceList = new Array<IResourceGroupResource>();
          const addedNewResourceList = this.getUniqueList(selectedResources, savedResources);
          const removedNewResourceList = this.getUniqueList(savedResources, selectedResources);

          if (addedNewResourceList) {
            updatedResourceList = [...addedNewResourceList, ...savedResources];
          }

          if (removedNewResourceList) {
            updatedResourceList = this.getUniqueList(updatedResourceList.slice(), removedNewResourceList);
          }

          this.setState({
            resourceGroupDetails: upsertResourceGroups.resourceGroupDetails,
            isSaving: false,
            isSavedEditedDetailsIntoDB: true,
            isFormIsValid: false,
            potentiallySelectedResources: [],
            savedResources: updatedResourceList,
            selectedResources: updatedResourceList
          });

          NotificationService.showSuccessToast("Update saved.");
        }
      }
    } else {
      NotificationService.showErrorToast(FailureMsg);
      console.log("Could not save resouce group details.");
      this.setState({ detailsHasError: true, isSaving: false });
    }
  };

  getResourceGroupDetailsById = async (resourceGroupId: number) => {
    this.setState({ loading: true });
    const result = await ResourceService.getResourceGroupByGroupId(resourceGroupId);

    let automationFilter = result.data && result.data.automationFilter ? JSON.parse(result.data.automationFilter) : null;

    if (automationFilter && automationFilter.rule) { // older version didn't nest the filters in the Rule property
      automationFilter = automationFilter.rule;
    }

    const automationModel = result.data && result.data.automationResourceTypeNavigation ? {
      resourceTypeId: result.data.automationResourceType,
      filter: automationFilter,
      resourceTypeCode: result.data.automationResourceTypeNavigation ? result.data.automationResourceTypeNavigation.code : null,
      automationResourceType: result.data.automationRequireApprove,
      usedFields: automationFilter ? automationFilter.filters.map((f: FilterDescriptor) => f.field) : null, // We aren't yet handling nested filters
      isDirty: false,
      requireApproval: result.data.automationRequireApprove,
      displayName: result.data.automationResourceTypeNavigation ? result.data.automationResourceTypeNavigation.displayName : null,
    } : null;

    if (result.ok) {
      this.setState({ resourceGroupDetails: result.data, loading: false, updateModel: result.data, automationModel });
      this.props.saveUnsavedChanges(JSON.stringify(result.data), false);
    } else {
      console.log("Could not load resouce group details.");
      this.setState({ loading: false, detailsHasError: true });
    }
  };

  getBtnsList(isSaveDisabled: boolean, isSaving: boolean = false): any {
    const buttons: any = [
      {
        buttonLabel: "Cancel",
        type: "button",
        handleClick: this.handleCancelClick.bind(this)
      },
      {
        buttonLabel: "Save",
        type: "button",
        disabled: isSaveDisabled,
        color: "primary",
        handleClick: this.handleSaveClick.bind(this),
        isSaving: isSaving
      }
    ];

    return buttons;
  }

  handleResourcePopupClose = (accepted: boolean) => {
    if (accepted) {
      const dedupeObjects = CommonHelper.dedupeObjects<IResourceGroupResource>(
        this.state.potentiallySelectedResources,
        this.state.selectedResources || new Array<IResourceGroupResource>(),
        "id"
      );
      const dedupeResources = dedupeObjects.map(
        item => (({ ...item, selected: false } as any) as IResourceGroupResource)
      ) as Array<IResourceGroupResource>;
      this.setState({ selectedResources: dedupeResources });
    }

    this.setState({ isResourceSelectorOpen: false, enableAddButton: false });
  };

  handleAddResources = () => {
    this.setState({ isResourceSelectorOpen: true });
  };

  handleImport = () => {
    this.setState({ importResourceModal: true });
  }

  handleImportModalClose = () => {
    this.setState({ ...this.state, importResourceModal: false });
  };

  refreshTheGrid = (isRefreshGrid?: boolean) => {
    this.setState({ ...this.state, importResourceModal: false });

    if (isRefreshGrid) {
      const refreshToggle = !this.state.refreshToggle;
      this.detailsGrid.refreshTheGrid(refreshToggle);
      this.setState({ refreshToggle });
    }
  };

  disableCancelButton = (disable: boolean) => {
    this.setState({ disableCancel: disable });
  };

  handlePotentiallySelected = (selected: Array<IResourceFields>, resourceType: IResourceTypeDetail) => {

    if (!selected || selected.length === 0) {
      this.setState({ potentiallySelectedResources: [], enableAddButton: false });
      return;
    }

    const potentiallySelectedResources = [] as Array<IResourceGroupResource>;

    for (let i = 0; i < selected.length; i++) {
      const iResource = {
        id: selected[i].resourceId,
        resourceType: resourceType.code,
        name: selected[i].displayName,
        objectId: selected[i].objectId,
        epiqOnly: selected[i].epiqOnly,
        PNumberOrResourceId: resourceType.code === "ediscoveryproject" ? selected[i].fields["Project Number"] : selected[i].resourceId.toString(),
        resourceTypeNavigation: {
          id: resourceType.id,
          code: resourceType.code,
          displayName: resourceType.displayName
        }
      }
      potentiallySelectedResources.push(iResource);
    }

    this.setState({ potentiallySelectedResources, enableAddButton: true });
  };

  onRemoveUpdateResources = (resources: Array<IResourceGroupResource>) => {
    this.setState({ selectedResources: resources });

    if (!this.state.updateModel.epiqOnly) {
      var epiqOnlyResources = resources.filter(r => r.epiqOnly == true);
      if (epiqOnlyResources.length == 0) {
        hasEpiqError = false;
        this.setState({ isFormIsValid: true });
      }
    }
  };

  loadPreselectedResources = (selected: Array<IResourceGroupResource>) => {
    this.setState({ selectedResources: selected, savedResources: selected });
  };

  resetViewMode = (isResourceInfoInEditMode: boolean) => {
    this.setState({ isSavedEditedDetailsIntoDB: isResourceInfoInEditMode, resourceInfoSectionMode: Mode.Edit });
  };

  toggleReactivateDialogEvent = async (event: React.MouseEvent<HTMLElement>) => {
    if (event.currentTarget.textContent.toLocaleLowerCase() === "reactivate") {
      this.setState({ openGroupReactivatePopup: false });
      const result = await ResourceService.reactivateResourceGroup(this.state.resourceGroupDetails);
      if (result.ok) {
        NotificationService.showSuccessToast(`Reactivated: ${this.state.updateModel.groupName}. `);
        this.props.saveUnsavedChanges(null, false);
        this.props.history.push("/administration/resourceGroups");
      }
      else {
        NotificationService.showErrorToast(`${this.state.updateModel.groupName} resource group reactivation failed.`);
      }
    }
    else if (event.currentTarget.textContent.toLocaleLowerCase() === "cancel" || event.currentTarget.textContent.toLocaleLowerCase() === "ok") {
      this.setState({ openGroupReactivatePopup: false });
    }
  };
 
  render() {
    const {
      detailsHasError,
      loading,
      resourceGroupDetails,
      isSaving,
      mode,
      isResourceGroupAlreadyExist,
      isResourceSelectorOpen,
      enableAddButton,
      selectedResources,
      isSavedEditedDetailsIntoDB,
      resourceInfoSectionMode,
      savedResources,
      automationModel
    } = this.state;
    let resourceDetails: any;
    const addedResources = this.getUniqueList(selectedResources, savedResources);
    const removedResources = this.getUniqueList(savedResources, selectedResources);

    let disableEpiqOnlyAdmin = false;
    disableEpiqOnlyAdmin = (mode == Mode.Edit || (!this.props.sessionData.superAdmin && this.props.sessionData.permissions.has("EpiqAdminCreateResourceGroup") && mode == Mode.Create));

    if (mode !== Mode.Create && loading) {
      return (
        <Paper className="resource-details-wrapper">
          <div className="no-resource">
            <CircularProgress />
          </div>
        </Paper>
      );
    } else if (detailsHasError) {
      resourceDetails = (
        <Paper className="resource-details-wrapper">
          <div className="no-resource">
            <p> Oops! Something went wrong. </p>
            <p>Please check your network connection and refresh the page.</p>
          </div>
        </Paper>
      );
    } else {
      let showEpiqOnlyAdmin;
      if (this.props.sessionData.superAdmin) {
        showEpiqOnlyAdmin = ShowEpiqOnlyControls.ENABLE;
      } else if (this.props.sessionData.permissions.has("ShowEpiqOnlyResourceGroups")) {
        showEpiqOnlyAdmin = ShowEpiqOnlyControls.SHOW
      } else {
        showEpiqOnlyAdmin = ShowEpiqOnlyControls.HIDE
      }

      resourceDetails = (
        <Resource
          {...this.props}
          resourceGroupDetails={mode === Mode.Edit ? resourceGroupDetails : new ResourceGroupDtls()}
          createResourceGroup={mode === Mode.Create}
          isSaving={isSaving}
          updateResourceDetails={this.updateResourceDetails}
          isResourceGroupAlreadyExist={isResourceGroupAlreadyExist}
          isSavedEditedDetailsIntoDB={isSavedEditedDetailsIntoDB}
          resetViewMode={this.resetViewMode}
          permissions={this.props.sessionData.permissions}
          showEpiqOnlyAdmin={showEpiqOnlyAdmin}
          hasEpiqOnlyError={hasEpiqError}
          disableEpiqOnlyAdmin={disableEpiqOnlyAdmin}
          automationModel={automationModel}
          automationSectionUpdated={this.automationSectionUpdated}
          automationSectionChanged={this.automationSectionChanged}
          automationRequireChanged={this.automationRequiredChanged}
         
        />
      );
    }

    const reactivateDialog = (
      <div className="warning">
        <DialogBox
          title={this.state.canReactivate ? "Reactivate resource group" : "Name exists"}
          content={this.state.canReactivate ? `Resource group name '${this.state.updateModel.groupName}' was previously deleted. Do you want to reactivate this deleted resource group?` :
            `Because resource group: '${this.state.updateModel.groupName}' was previously deleted, it cannot be reused when editing. Supply a different name`}
          okButtonLabelText={this.state.canReactivate ? "Cancel" : ""}
          cancelButtonLabelText={this.state.canReactivate ? "Reactivate" : "Ok"}
          visible={this.state.openGroupReactivatePopup}
          toggleDialogEvent={this.toggleReactivateDialogEvent.bind(this)}
        /></div>);


    return (
      <Paper className="resource-details-wrapper">
        {resourceDetails}
        <>
          <GridSelector
            isOpen={isResourceSelectorOpen}
            acceptBtnText="Add"
            cancelBtnText="Cancel"
            titleText="Add Resource"
            addAvailable={enableAddButton}
            onClose={this.handleResourcePopupClose}
            fullWidth={true}
          >
            <DynamicResourceList
              selectionChange={this.handlePotentiallySelected.bind(this)}
              excludedResourceGroupsId={resourceGroupDetails.id && resourceGroupDetails.id > 0 ? resourceGroupDetails.id : null}
              filterEpiqOnly={!this.state.updateModel.epiqOnly}
              className="resource-list-modal"
            />
          </GridSelector>

          <div className={resourceInfoSectionMode === Mode.Edit ? "edit-mode" : ""}>
            <ResourceList
              resourceGroupId={this.props.match.params.resourceGroupId}
              addClickHandler={this.handleAddResources}
              importClickHandler={this.handleImport}
              enableRemoveResource={true}
              onRemoveUpdateResources={this.onRemoveUpdateResources}
              selectionChange={this.loadPreselectedResources}
              resources={this.state.selectedResources}
              ref={(gridInstance: ResourceList) => {
                this.detailsGrid = gridInstance;
              }}
              permissions={this.props.sessionData.permissions}
              isResourceGroupEpiqOnly={this.state.updateModel.epiqOnly}
            />
          </div>
          {this.state.importResourceModal && (
            <Dialog
              onClose={this.handleImportModalClose.bind(this)}
              aria-labelledby="customized-dialog-title"
              className="modal bulk-import-modal"
            >
              <DialogTitle className="modal-title">
                Bulk Import Resources
                <IconButton
                  className="modal-close"
                  aria-label="close"
                  onClick={this.handleImportModalClose.bind(this)}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <BulkResourceImport
                  disableCancelButton={this.disableCancelButton.bind(this)}
                  refreshTheGrid={this.refreshTheGrid}
                  resourceGroupId={resourceGroupDetails.id}
                  resourceGroupName={resourceGroupDetails.groupName}
                />
              </DialogContent>
            </Dialog>
          )}
        </>
        {reactivateDialog}
      </Paper>
    );
  }
}

export default connect(
  (state: IApplicationState) => ({
    ...state.headerState,
    ...state.warningMessageState,
    ...state.sessionState
  }),
  {
    ...HeaderStore.actionCreators,
    ...WarningMessageStore.actionCreators
  }
)(ResourceGroupDetails as any);
