import * as React from "react";


const easyUploadAdditionalSummary = () => {
  return (
    <>
      <div>
        This request informs Epiq to download the data and assign the media number. After this request completes, you will need to start a new work request to process or load the data.
      </div>
      <div style={{ paddingTop: "20px" }} >
        Contact your Project Manager with any questions about this media upload request.
      </div>
    </>
  );
};

export default easyUploadAdditionalSummary;