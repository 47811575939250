import * as React from "react";
import * as HeaderStore from "../../../store/Header";
import * as SessionStore from "../../../store/Session";
import { AdvancedGrid, IGridParams } from "../../common/grid/AdvancedGrid";
import { User, SendInvite } from "../../../models/UserModels";
import { connect } from "react-redux";
import { IApplicationState } from "../../../store";
import { GridColumnProps, GridFilterCellProps } from "@progress/kendo-react-grid";
import { DropDownFilter } from "../../common/grid/filters/DropDownFilter";
import { FilterDescriptor, CompositeFilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import AdminService from "../../../services/AdminService";
import CommandCell from "../../common/grid/columns/CommandCell";
import "./UserBatchSendInviteList.scss";
import { Grid, Paper } from "@material-ui/core";
import DialogBox from "../../common/DialogBox";
import { DialogCloseEvent } from "@progress/kendo-react-dialogs";
import * as WarningMessageStore from "../../../store/WarningMessage";
import JobManager from "./JobManager";
import { RadioButtonProps, RadioGroup, RadioGroupChangeEvent } from "@progress/kendo-react-inputs";
import SingleSelectDropDown from "../../common/SingleSelectDropDown";
import SiteBrandingService from "../../../services/SiteBrandingService";
import { SiteBrandingModel } from "../../../models/SiteBrandingModel";

interface IProps {
  history: any;
}

interface UserType {
  name: string;
  id: number;
}

interface IUserFetchResult {
  totalUsers: number;
  users: Array<User>;
}

type Props = IProps &
  HeaderStore.IHeaderState &
  typeof HeaderStore.actionCreators &
  WarningMessageStore.IWarningMessageState &
  typeof WarningMessageStore.actionCreators &
  SessionStore.ISessionState;

interface IState {
  dataState: IGridParams;
  fetchingUsers: boolean;
  fetchResults: IUserFetchResult;
  hasError: boolean;
  selectedUsers: Array<User>;
  showConfirmation: boolean;
  emailInviteType: number;
  selectedSiteItem: SiteBrandingModel;
  overrideSendInvite: number;
}

class UserBatchSendInviteList extends React.PureComponent<Props, IState> {
  columns: Array<GridColumnProps>;
  myGrid: AdvancedGrid<User, IGridParams>;

  constructor(props: Props) {
    super(props);

    this.state = this.getInitialState();

    this.columns = new Array<GridColumnProps>(
      {
        field: "lastName | firstName",
        title: "USERS",
        cell: this.usersBackground,
        filterable: true,
        filter: "text"
      },
      {
        field: "email",
        title: "EMAIL",
        cell: this.userEmail,
        filterable: true,
        filter: "text"
      },
      {
        field: "superAdmin",
        title: "ACCOUNT TYPE",
        cell: this.isSuperAdmin,
        filterable: true,
        filter: "text",
        filterCell: (props: GridFilterCellProps) => (
          <DropDownFilter
            {...props}
            data={
              [
                { name: "User", id: 0 },
                { name: "Global Admin", id: 1 }
              ] as Array<UserType>
            }
            textField="name"
            defaultSelectedOption={null}
          />
        )
      },
      { field: "lastLoginDate", title: "LAST LOGIN", format: "{0:g}", filterable: true, filter: "date" },
      { field: "invitationsentDate", title: "INVITATION SENT", format: "{0:g}", filterable: true, filter: "date" },
      { field: "createdDate", title: "DATE CREATED", format: "{0:g}", filterable: true, filter: "date" }
    );

    this.columns.unshift({
      title: "",
      cell: CommandCell({
        onSelection: this.selectUser,
        selectedField: "selected"
      }),
      sortable: false,
      headerClassName: "no-sort"
    });
  }

  getInitialState = () => {
    const initialState: IState = {
      dataState: {
        skip: 0,
        take: 500,
        sort: [{ field: "lastName | firstName", dir: "asc" }]
      },
      fetchingUsers: false,
      fetchResults: {
        totalUsers: 0,
        users: new Array<User>()
      },
      hasError: false,
      selectedUsers: new Array<User>(),
      showConfirmation: false,
      emailInviteType: 0,
      selectedSiteItem: null,
      overrideSendInvite: 0
    };
    return initialState;
  };

  componentDidMount() {
    this.props.setHeaderButtons(this.getBtnsList(true), "", "/administration/users", "Back to user list");
  }

  componentWillUnmount() {
    this.props.setHeaderButtons(null);
    this.props.saveUnsavedChanges(null, false);
  }

  selectUser = (dataItem: User) => {
    const alteredUsers = this.state.fetchResults.users.map(user => {
      return {
        ...user,
        selected: user.id === dataItem.id ? !user.selected : user.selected
      };
    });

    const justSelectedUsers = alteredUsers.filter(user => user.selected);
    this.props.setHeaderButtons(
      this.getBtnsList(!(justSelectedUsers && justSelectedUsers.length > 0)),
      "",
      "/administration/users",
      "Back to user list"
    );
    this.props.saveUnsavedChanges(null, justSelectedUsers && justSelectedUsers.length > 0);

    this.setState({
      selectedUsers: justSelectedUsers,
      fetchResults: { totalUsers: alteredUsers.length, users: alteredUsers }
    });
  };

  clearCheckboxs = () => {
    const alteredUsers = this.state.fetchResults.users.map(user => {
      return {
        ...user,
        selected: false
      };
    });

    this.setState({ fetchResults: { totalUsers: alteredUsers.length, users: alteredUsers } });
  };

  usersBackground(props: any) {
    const fullName = props.dataItem.lastName + ", " + props.dataItem.firstName;
    return (
      <td>
        <div className="user-details">
          <div className="user-name">
            <em title={fullName}>{fullName}</em>
          </div>
        </div>
      </td>
    );
  }

  userEmail(props: any) {
    const email = props.dataItem.email;
    return (
      <td className="email-cell">
        <em title={email}>{email}</em>
      </td>
    );
  }

  isSuperAdmin(props: any) {
    const superAdmin = props.dataItem.superAdmin ? "Global Admin" : "User";
    return (
      <td>
        <div>{superAdmin}</div>
      </td>
    );
  }

  updateUserTypeSortDirection = (dataState: IGridParams) => {
    const sort: SortDescriptor[] = dataState.sort ? [...dataState.sort] : [];
    return sort.map((item, index) => {
      if (item.field === "superAdmin") {
        item.dir = item.dir === "asc" ? "desc" : "asc";
      }

      return item;
    });
  };

  getModifiedCorrectDateFilter = (
    gridFilters: Array<CompositeFilterDescriptor>,
    dateColumnName: string = "lastLoginDate"
  ) => {
    // This function is used for date column filter in all advance grid pages.
    let isCreatedDateFilterExists = false;
    let createdDateValue: Date;
    const filters = gridFilters
      ? gridFilters.map(compositeFilter => {
        const createdDateFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === dateColumnName
        ) as FilterDescriptor;

        if (createdDateFilter) {
          const dateFilterValue = createdDateFilter.value;

          //Check for valid date and ignoring default date to make filter work on date change from calendar without equals operator in filter menu
          if (!isNaN(new Date(dateFilterValue).getFullYear()) && new Date(dateFilterValue).getFullYear() > 1970) {
            createdDateFilter.operator = 'eq';
          }

          createdDateValue = typeof dateFilterValue === "string" ? new Date("01/01/1901") : ((createdDateFilter.operator === 'isnull' || createdDateFilter.operator === 'isnotnull') ? null : new Date(dateFilterValue));

          if (createdDateFilter.operator === 'eq') {
            isCreatedDateFilterExists = true;

            return {
              ...compositeFilter,
              filters: [
                {
                  ...createdDateFilter,
                  field: dateColumnName,
                  operator: "gte",
                  value: new Date(createdDateValue.toUTCString())
                }
              ]
            };
          }
        }

        return compositeFilter;
      })
      : [];

    if (isCreatedDateFilterExists) {
      filters.push({
        logic: "and",
        filters: [
          {
            field: dateColumnName,
            operator: "lt",
            value: new Date(new Date(createdDateValue.setUTCDate(createdDateValue.getUTCDate() + 1)).toUTCString())
          }
        ]
      });
    }

    return filters;
  };

  async fetchUsers(dataState: IGridParams) {
    let filters = this.getModifiedCorrectDateFilter(dataState.filters);
    filters = this.getModifiedCorrectDateFilter(filters, "invitationsentDate");
    filters = this.getModifiedCorrectDateFilter(filters, "createdDate");
    let userListFilters = new Array<CompositeFilterDescriptor>();
    if (dataState.filters) {
      filters.forEach(compositeFilter => {
        let modifiedCompositeFilter: CompositeFilterDescriptor = compositeFilter;
        const userTypeFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "superAdmin"
        ) as FilterDescriptor;

        if (userTypeFilter) {
          if (userTypeFilter.value === "") {
            return;
          }

          const value = userTypeFilter.value as UserType;
          modifiedCompositeFilter = {
            ...compositeFilter,
            filters: [
              {
                ...userTypeFilter,
                field: "superAdmin",
                operator: "eq",
                value: value.id === 1
              }
            ]
          };
        }

        const lastLoginDateFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "lastLoginDate"
        ) as FilterDescriptor;

        if (lastLoginDateFilter) {
          if (lastLoginDateFilter.value === "") {
            return;
          }
        }

        const invitationsentDateFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "invitationsentDate"
        ) as FilterDescriptor;

        if (invitationsentDateFilter) {
          if (invitationsentDateFilter.value === "") {
            return;
          }
        }

        userListFilters.push(modifiedCompositeFilter);
      });
    }
    let reverseSortDescriptors: SortDescriptor[] = this.updateUserTypeSortDirection(dataState);

    this.setState({ ...this.state, fetchingUsers: true }, async () => {
      const result = await AdminService.getUserList({
        ...dataState,
        filters: userListFilters,
        sort: reverseSortDescriptors
      });

      let updateSortDescriptors: SortDescriptor[] = this.updateUserTypeSortDirection(dataState);
      this.setState({ ...this.state, dataState: { ...dataState, sort: updateSortDescriptors } });
      this.props.setHeaderButtons(this.getBtnsList(true), "", "/administration/users", "Back to user list");

      this.setState({
        ...this.state,
        fetchingUsers: false,
        hasError: !result.ok,
        fetchResults: {
          ...this.state.fetchResults,
          users: result.ok ? result.data.results : new Array<User>(),
          totalUsers: result.ok && result.data.results ? (result.data.results as Array<User>).length : 0
        }
      });
    });
  }

  getBtnsList: any = (isSubmitDisabled: boolean, isSaving: boolean) => {
    const buttons: any = [
      {
        buttonLabel: "Cancel",
        type: "button",
        handleClick: this.handleCancelClick
      },

      {
        buttonLabel: "Send",
        type: "button",
        disabled: isSubmitDisabled,
        handleClick: this.handleSubmitClick,
        color: "primary",
        isSaving: isSaving
      }
    ];
    return buttons;
  };

  handleCancelClick = () => {
    this.props.history.push("/administration/users");
  };

  handleSubmitClick = async () => {
    this.setState({ showConfirmation: true });
    this.props.setHeaderButtons(this.getBtnsList(true), "", "", "");
  };

  async toggleConfirmDialogEvent(event: React.MouseEvent<HTMLElement>) {
    if (event.currentTarget.textContent === "CANCEL") {
      this.setState({ showConfirmation: false });
      this.props.setHeaderButtons(this.getBtnsList(false), "", "", "");
    } else if (event.currentTarget.textContent === "CONFIRM") {
      const inviteUsers = this.state.selectedUsers.map(
        item => ({ oktaUserId: item.idpId, UserId: item.id } as SendInvite)
      ) as Array<SendInvite>;
      const jobManager = new JobManager({
        sendInvites: inviteUsers, bulk: true,
        siteId: this.state.selectedSiteItem ? this.state.selectedSiteItem.siteId : null,
        overrideSendInvite: this.state.overrideSendInvite == 0 ? false : true
      });

      this.setState({ ...this.state, showConfirmation: false });
      await jobManager.submitBulkSendInviteJob();
    }
  }

  dialogCloseEvent(event: DialogCloseEvent) {
    this.setState({ showConfirmation: false });
    this.props.setHeaderButtons(this.getBtnsList(false), "", "", "");
  }

  handleInviteTypeChange = async (e: RadioGroupChangeEvent) => {
    this.setState({ emailInviteType: e.value, overrideSendInvite: 0, selectedSiteItem: null });
    this.clearCheckboxs();
  };

  handleInviteUserTypeChange = async (e: RadioGroupChangeEvent) => {
    this.setState({ overrideSendInvite: e.value });
    this.clearCheckboxs();
    this.props.setHeaderButtons(this.getBtnsList(true), "", "", "");
  };

  getFilter = (searchText: string) => {
    const filters = [
      {
        logic: "or",
        filters: [
          { field: "name", operator: "contains", value: searchText },
        ] as Array<FilterDescriptor>
      }
    ] as CompositeFilterDescriptor[];

    return filters;
  }

  render() {
    const { hasError, fetchResults, fetchingUsers, dataState, showConfirmation, selectedUsers } = this.state;
    const invitationTypeData = [{ value: 0, label: "Standard invitation for Epiq Access" }, { value: 1, label: "Custom invitation for client-branded login page" }] as RadioButtonProps[];
    const inviteUsersTypeData = [{ label: "Only invite users who need to activate accounts.", value: 0 }, { label: "Invite all users, including those with active accounts.", value: 1 }] as RadioButtonProps[];

    const confirmationMessage = showConfirmation && (
      <DialogBox
        title="Send Invites Confirmation"
        content={`Confirm that you want to send ${this.state.emailInviteType === 0 ? "Epiq Access" : ""} invitation emails to the selected users (${selectedUsers.length}).`}
        visible={showConfirmation}
        toggleDialogEvent={this.toggleConfirmDialogEvent.bind(this)}
        diagCloseEvent={this.dialogCloseEvent.bind(this)}
        cancelButtonLabelText={"CONFIRM"}
        okButtonLabelText={"CANCEL"}
      />
    );

    return (
      <>
        <div className="user-invite-grid-wrapper">
          <Paper className="papergrid-space">
            <Grid container alignItems="center">
              <Grid item sm={9} xs={12}>
                <div className="page-title">Send Invites</div>
                <div className="field-hint">Send selected users an invitation email. Invitations are only sent to users who need to activate their accounts.</div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={6} xs={12}>
                <div className="email-invite-type">
                  <label className="email-invite-label">What type of welcome invitation do you want to send?</label>
                  <RadioGroup
                    data={invitationTypeData}
                    value={this.state.emailInviteType}
                    onChange={this.handleInviteTypeChange}
                    className="invitation-type-radio"
                  />
                </div>
                {this.state.emailInviteType === 1 && (
                  <>
                    <div className="client-sites">
                      <label>Select the client name.</label>
                      <SingleSelectDropDown
                        getItems={text => SiteBrandingService.getSites({ skip: 0, take: 9999, filters: this.getFilter(text) })}
                        preselectedValue={this.state.selectedSiteItem
                          ? { id: this.state.selectedSiteItem.siteId as number, text: this.state.selectedSiteItem.name, data: null }
                          : null}
                        onChange={(event: any) => {
                          const selectedSite = event && event.data ? event.data : "";
                          this.setState({ ...this.state, selectedSiteItem: selectedSite, overrideSendInvite: 0 });
                          this.clearCheckboxs();
                          this.props.setHeaderButtons(this.getBtnsList(true), "", "", "");
                        }}
                        typeToListConverter={(sb: SiteBrandingModel[]) => sb.map(item => { return { id: item.siteId, text: item.name, data: item }; })
                        }
                      />
                    </div>
                    {this.state.selectedSiteItem && this.state.selectedSiteItem.siteId > 0 && (
                      <div className="invite-user-type">
                        <label>Send invitations to these users.</label>
                        <RadioGroup
                          data={inviteUsersTypeData}
                          value={this.state.overrideSendInvite}
                          onChange={this.handleInviteUserTypeChange}
                          className="invite-user-type-radio"
                        />
                      </div>)
                    }
                  </>
                )}
              </Grid>
            </Grid>
          </Paper>

          {(this.state.emailInviteType === 0 || (this.state.emailInviteType === 1 && this.state.selectedSiteItem)) &&
            <AdvancedGrid
              ref={(gridInstance: AdvancedGrid<User, IGridParams>) => {
                this.myGrid = gridInstance;
              }}
              showErrorState={hasError}
              showLoadingIndicator={fetchingUsers}
              sortable={{ mode: "multiple" }}
              data={fetchResults.users}
              dataFetch={this.fetchUsers.bind(this)}
              dataState={dataState}
              columns={this.columns}
              paging={false}
              totalRecords={{ value: this.state.fetchResults.totalUsers, label: "Users" }}
              noRecordsRender={<p>No users found.</p>}
              noLoadOnMount={false}
              filteredDataOnly={false}
              multiFieldFilterDelimiter="|"
              filterOperators={{
                text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
                date: [{ text: "grid.filterIsNotNullOperator", operator: "isnotnull" }, { text: "grid.filterIsNullOperator", operator: "isnull" }]
              }}
            />
          }
          {confirmationMessage}
        </div>
      </>
    );
  }
}

export default connect(
  (state: IApplicationState) => ({ ...state.headerState, ...state.sessionState, ...state.warningMessageState }),
  {
    ...HeaderStore.actionCreators,
    ...WarningMessageStore.actionCreators
  }
)(UserBatchSendInviteList as any);
