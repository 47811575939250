import * as React from "react";
import { connect } from "react-redux";
import { differenceWith } from "lodash";
import { IApplicationState } from "../../../store";
import * as UsersStore from "../../../store/Users";
import * as HeaderStore from "../../../store/Header";
import * as WarningMessageStore from "../../../store/WarningMessage";
import * as JobStore from "../../../store/Jobs";
import ApplicationList from "./application/ApplicationList";
import GridSelector from "../../common/grid/GridSelector";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./UserList.scss";
import "./UserDetails.scss";
import User, { IOnUserActionChanges } from "./User";
import { Application } from "../../../models/ApplicationModels";
import { ISsoUserGroup, UpsertUserModel, UserDetailsModel, UserGroup, SubmittedUpsertUserModel } from "../../../models/UserModels";
import CommonHelper from "../../common/utilities/CommonHelper";
import JobManager from "../../../JobManager";
import { IGetJobStatusResponseData } from "../../../services/JobService";
import NotificationService from "../../../services/NotificationService";
import UserGroupAssignmentsList from "./UserGroupAssignmentsList";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@material-ui/core";
import { Button as KendoButton } from "@progress/kendo-react-buttons";
import { ICompositeJobInfo, GenericStatus } from "../../../models/JobModels";
import * as SessionStore from "../../../store/Session";
import UserGroups from "./AssignUserGroupsToUser";
import AdminService from "../../../services/AdminService";
import AdminContextService from "../../../services/AdminContextService";
import { parseJSON } from "jquery";
import SsoGroupList, { kOktaGroupNoOp } from "./SsoGroupList";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import "../../common/AngleTab.scss";
import AuthenticationService from "../../../services/AuthenticationService";
import { IResourceContent } from "../../../models/ResourceModels";
import SpeedTestList from "./SpeedTest/SpeedTestList";

interface IProps {
  match?: any;
  history: any;
}

enum Mode {
  Edit,
  Create,
  View
}

type Props = IProps &
  HeaderStore.IHeaderState &
  typeof HeaderStore.actionCreators &
  UsersStore.IUsersState &
  typeof UsersStore.actionCreators &
  JobStore.IJobState &
  typeof JobStore.actionCreators &
  WarningMessageStore.IWarningMessageState &
  typeof WarningMessageStore.actionCreators &
  SessionStore.ISessionState;

type State = {
  mode: Mode;
  updateModel: SubmittedUpsertUserModel;
  isAppSelectorOpen: boolean;
  isSsoSelectorOpen: boolean;
  selectedApplications: Array<Application>;
  potentiallySelectedApplications: Array<Application>;
  potentiallySelectedSsoGroup: Array<ISsoUserGroup>;
  selectedSsoGroup: Array<ISsoUserGroup>;
  isSaving: boolean;
  jobId: string;
  initializing: boolean;
  displayAssignDialog: boolean;
  userDetailsFormIsValid: boolean;
  enableAddButton: boolean;
  enableAddSsoGroupButton: boolean;
  hideDeleteIcon: boolean;
  userGroups: Array<UserGroup>;
  isUserGroupsAssignOpen: boolean;
  selectedUserGroups: Array<UserGroup>;
  potentiallyAddedUserGroups: Array<UserGroup>;
  removedUserGroups: Array<UserGroup>;
  removedSsoGroups: Array<ISsoUserGroup>;
  removedApps: Array<Application>;
  isLoading: boolean;
  reConfigureJobId: string;
  selectedTab: number;
  contextPermissions: Array<string>;
  userDetails: UserDetailsModel;
  getUserError: boolean;
  useContext: boolean;
  advancedFields: boolean;
  userActionChanges: IOnUserActionChanges;
  excludedSsoGroupIds: Array<string>;
};

class UserDetails extends React.PureComponent<Props, State> {
  unregisterHistoryListener: any;
  jobManager: JobManager;

  constructor(props: Props) {
    super(props);

    const mode = Number(this.props.match.params.userId) > 0 ? Mode.Edit : Mode.Create;

    const updateModel = new SubmittedUpsertUserModel();
    const userDetails = new UserDetailsModel();

    if (mode === Mode.Create && this.props.history.location.state && this.props.history.location.state.newRelUser) {
      const { newRelUser } = this.props.history.location.state;
      userDetails.firstName = updateModel.firstName = newRelUser.fields.RelFirstName;
      userDetails.lastName = updateModel.lastName = newRelUser.fields.RelLastName;
      userDetails.username = updateModel.username = newRelUser.fields.RelEmail;
      userDetails.email = updateModel.email = newRelUser.fields.RelEmail;
    }

    const advancedFields = (mode === Mode.Create && this.props.sessionData.permissions.has("EpiqAdminCreateUser")) || (mode === Mode.Edit && this.props.sessionData.permissions.has("EpiqAdminUpdateUser") && this.props.sessionData.permissions.has("EpiqAdminGetUser"));

    this.jobManager = new JobManager();
    this.state = {
      mode,
      updateModel: updateModel,
      isAppSelectorOpen: false,
      isSsoSelectorOpen: false,
      selectedApplications: new Array<Application>(),
      potentiallySelectedApplications: new Array<Application>(), // apps that may become selectedApplications when the modal closes
      potentiallySelectedSsoGroup: [],
      selectedSsoGroup: [],
      isSaving: false,
      jobId: "",
      initializing: true,
      displayAssignDialog: false,
      userDetailsFormIsValid: mode !== Mode.Create,
      enableAddButton: false,
      enableAddSsoGroupButton: false,
      hideDeleteIcon: false,
      userGroups: new Array<UserGroup>(),
      isUserGroupsAssignOpen: false,
      selectedUserGroups: [],
      potentiallyAddedUserGroups: [],
      removedUserGroups: [],
      removedSsoGroups: [],
      removedApps: [],
      isLoading: false,
      reConfigureJobId: "",
      selectedTab: 0,
      contextPermissions: [],
      userDetails: userDetails,
      getUserError: false,
      useContext: (this.props.history.location.state && this.props.history.location.state.useContext) || !advancedFields,
      advancedFields,
      userActionChanges: {},
      excludedSsoGroupIds: []
    };
  }

  async toggleAssignDialogEvent(event: React.MouseEvent<HTMLElement>) {
    const answer = event.currentTarget.textContent;

    if (answer === "YES") {
      if (event) {
        event.preventDefault();
      }
    }

    this.setState({ ...this.state, displayAssignDialog: false }, () => {
      if (answer === "YES") {
        this.handleSaveClick(null, true);
      }
    });
  }

  async componentDidMount() {
    this.props.setHeaderButtons(this.getBtnsList(true), "", this.getListingPage(), "Back to users list");
    this.ensureDataFetched();
    this.unregisterHistoryListener = this.props.history.listen(this.onRouteChange.bind(this));

    if (this.state.mode === Mode.Create) {
      this.props.saveUnsavedChanges(JSON.stringify(this.state.updateModel), false);
    }
  }

  onRouteChange(route: any) {
    if (this.state.jobId) {
      this.jobManager.stopTrackingJob(this.state.jobId);
    }
    if (this.unregisterHistoryListener) {
      this.unregisterHistoryListener();
    }
  }

  getCompositeInfo(jobStatus: IGetJobStatusResponseData, user: SubmittedUpsertUserModel): ICompositeJobInfo {
    const fullName = `${user.firstName + " " + user.lastName}`;
    // TODO in a later commit, we'll let the API tell us what's a success or not, this is a stopgap for now
    if (jobStatus.statusId == null) {
      return { ...jobStatus, genericStatus: "UNKNOWN", toastMessage: null };
    }

    let toastMessage: string = null;
    let genericStatus: GenericStatus = "PROCESSING";

    switch (jobStatus.statusId) {
      //case 28:  -- this status is probably too premature. We want to find out what happens after saving apps before we can consider this done
      case 35:
        // TODO do we still need to check to see if user groups are null?
        if ((!user.sendInvite && user.addUserGroups && user.addUserGroups.length === 0) || (!user.sendInvite)) {
          toastMessage = `${fullName} has been created.`;
          genericStatus = "SUCCESS";
        }
        break;
      case 31:
        if (user.sendInvite) {
          toastMessage = `${fullName} has been created. An invitation was sent.`;
          genericStatus = "SUCCESS";
        }
        break;
      case 23:
        toastMessage = "Unable to create user in Okta.";
        genericStatus = "FAILED";
        break;
      case 26:
        toastMessage = "Unable to create user in the database.";
        genericStatus = "FAILED";
        break;
      case 29:
        toastMessage = `User ${fullName} was created, but failed to update their applications.`;
        genericStatus = "FAILED";
        break;
      case 32:
        toastMessage = `User ${fullName} was created, but email failed to send.`;
        genericStatus = "FAILED";
        break;
      case 33:
        toastMessage = `User already exists in the database.`;
        genericStatus = "FAILED";
        break;
      case 36:
      case 39:
        toastMessage = `User ${fullName} was updated, but their user groups were not.`;
        genericStatus = "FAILED";
        break;
      case 38:
        toastMessage = `Changes to user ${fullName} have been saved.`;
        genericStatus = "SUCCESS";
        break;
      case 43:
        toastMessage = `Unable to update user ${fullName} in Okta.`;
        genericStatus = "FAILED";
        break;
      case 46:
        toastMessage = `User ${fullName} was updated in Okta, but not in the database.`;
        genericStatus = "FAILED";
        break;
      case 48:
        if (this.state.userDetails.reconfigureUserStatus !== null) {
          toastMessage = `User ${fullName} has been successfully reconfigured.`;
          genericStatus = "SUCCESS";
        }
        break;
      case 49:
        toastMessage = `User ${fullName} was updated, but their applications were not.`;
        genericStatus = "FAILED";
        break;
      case 111:
        toastMessage = `Added user to Relativity: ${fullName}.`;
        genericStatus = "SUCCESS";
        break;
      case 112:
        toastMessage =  `Cannot add user to Relativity: ${fullName}.  Verify Relativity app is assigned.`;
        genericStatus = "FAILED";
        break;
      case 900:
        toastMessage = `Unknown failure`;
        genericStatus = "FAILED";
        break;

    }

    return { ...jobStatus, genericStatus, toastMessage: toastMessage };
  }

  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
    let jobStatus: IGetJobStatusResponseData;

    if (this.state.jobId !== "") {
      console.log("this.state.jobId", this.state.jobId);

      jobStatus = this.jobManager.checkJobStatus(this.state.jobId);
    } else {
      console.log("this.state.jobId EMPTY", this.state.jobId);
    }

    if (jobStatus != null) {
      const compositeInfo: ICompositeJobInfo = this.getCompositeInfo(jobStatus, this.state.updateModel);

      if (compositeInfo.genericStatus === "SUCCESS") {
        this.jobManager.stopTrackingJob(jobStatus.jobId);
        let compositeToastMessage = compositeInfo.toastMessage;

        if (this.state.reConfigureJobId) {
          compositeToastMessage = "This user is successfully reconfigured.";
        }

        if (this.props.createUser) {
          NotificationService.showSuccessToast(compositeToastMessage);

          AuthenticationService.SetDirty().then(r => {
            if (!r.ok) {
              NotificationService.showWarningToast("Error updating your credentials, you may need to relogin in order to view this user");
            }
          });
        }

        this.setState({ ...this.state, jobId: "", isSaving: false });
        this.props.updateUserDetailsComplete(false);
        this.props.history.push(this.getListingPage());

        return;
      } else if (compositeInfo.genericStatus === "FAILED") {
        this.jobManager.stopTrackingJob(jobStatus.jobId);
        this.setState({ ...this.state, jobId: "", isSaving: false });

        //user was created but send invite email didn't go through, consider this a warning
        //and send user back to userlist
        if (jobStatus.statusId === 32) {
          NotificationService.showWarningToast(compositeInfo.toastMessage);
          this.props.updateUserDetailsComplete(false);
          this.props.history.push(this.getListingPage());
        } else {
          NotificationService.showErrorToast(compositeInfo.toastMessage);
          this.props.updateUserDetailsComplete(true);
          this.props.setHeaderButtons(this.getBtnsList(true, false), "", this.getListingPage(), "Back to users list");
        }

        return;
      }
    }

    if (
      (this.state.jobId === "" &&
        this.props.latestJobId !== null &&
        (this.props.jobType == "EDIT_USER" || this.props.jobType == "CREATE_USER")) || (this.state.reConfigureJobId)
    ) {
      this.jobManager.addJobId(!this.state.reConfigureJobId ? this.props.latestJobId : this.state.reConfigureJobId);
      this.setState({ jobId: !this.state.reConfigureJobId ? this.props.latestJobId : this.state.reConfigureJobId });
    } else if (prevState !== this.state) {
      let { updateModel } = this.state;
      let isUnsavedChangesExist =
        this.props.initialState !== JSON.stringify(updateModel) || this.applicationsListIsDirty() || this.usersGroupsListIsDirty() || this.sSoListIsDirty();

      this.props.updateUnsavedChanges(isUnsavedChangesExist);
    }
  }

  onReconfigureUser = async (user: SubmittedUpsertUserModel) => {
    this.setState({ isLoading: true });
    const response = await AdminService.ReconfigureUserInOkta(user);

    if (response.ok) {
      this.setState({ reConfigureJobId: parseJSON(response.value) });
    }
    else {
      NotificationService.showErrorToast("Failed to reconfigure");
    }
  }

  handleCancelClick() {
    this.props.history.push(this.getListingPage());
  }

  async handleSaveClick(event: any, acknowledgedSuperAdminRisk: boolean = false) {
    const requestModel = this.state.updateModel as SubmittedUpsertUserModel;
    const { selectedUserGroups, selectedApplications, selectedSsoGroup, removedUserGroups, removedSsoGroups, removedApps } = this.state;
    const hasValidEmail = this.state.updateModel.hasValidEmail;

    if (!this.isEpiqUser() && selectedUserGroups) {
      const epiqAdminOnlyUserGroups = selectedUserGroups.filter((group) => group.epiqOnly === true);

      if (epiqAdminOnlyUserGroups && epiqAdminOnlyUserGroups.length > 0) {
        NotificationService.showErrorToast(`Epiq Admin Only user group(s) cannot be assigned non epiq domain user: ${requestModel.username}`);
        this.props.setHeaderButtons(this.getBtnsList(true, false), "", this.getListingPage(), "Back to users list")
        return;
      }
    }

    if (!this.isEpiqUser() && selectedApplications) {
      const epiqAdminOnlyApps = selectedApplications.filter((app) => app.epiqOnly === true);

      if (epiqAdminOnlyApps && epiqAdminOnlyApps.length > 0) {
        NotificationService.showErrorToast(`Epiq Admin Only App(s) cannot be assigned non epiq domain user: ${requestModel.username}`);
        this.props.setHeaderButtons(this.getBtnsList(true, false), "", this.getListingPage(), "Back to users list")
        return;
      }
    }

    if (!hasValidEmail) {
      NotificationService.showErrorToast(`No email found. Please enter proper email either in username or in email fields`);
      this.props.setHeaderButtons(this.getBtnsList(true, false), "", "", "Back to users list")
      return;
    }

    requestModel.addApplications = selectedApplications.map(s => s.id);
    requestModel.addOktaUserGroups = selectedSsoGroup.map(s => s.id);
    requestModel.addUserGroups = selectedUserGroups.map(s => s.groupId);
    requestModel.removeApplications = removedApps.map(s => s.id);
    requestModel.removeOktaUserGroups = removedSsoGroups.map(s => s.id);
    requestModel.removeUserGroups = removedUserGroups.map(s => s.groupId);
    requestModel.inviteBrandingId = this.state.userActionChanges.inviteSiteBrandingId;

    this.props.setHeaderButtons(this.getBtnsList(true, true), "", this.getListingPage(), "Back to users list");

    const alreadySuperAdmin = this.state.userDetails.superAdmin;

    if (
      ((requestModel.superAdmin && !alreadySuperAdmin) || (!requestModel.superAdmin && alreadySuperAdmin)) &&
      !acknowledgedSuperAdminRisk
    ) {
      this.setState({ ...this.state, displayAssignDialog: true });
      this.props.setHeaderButtons(this.getBtnsList(false, false), "", this.getListingPage(), "Back to users list");

      return;
    }

    if (this.state.mode === Mode.Create && this.state.useContext && this.props.context.admin && requestModel.addUserGroups.indexOf(this.props.context.admin.memberUserGroupId) === -1) {
      // this should be automatic for users with limited permissions operating within a limited context. We assume user just wants to add newly created user to the context.
      requestModel.addUserGroups.push(this.props.context.admin.memberUserGroupId);
    }

    if (this.props.context.admin && this.props.context.admin.contextResourceGroupId) {
      requestModel.attemptSkipEAChanges = this.props.context.relativityClient ? true : false;
    }

    this.setState({ ...this.state, isSaving: true });

    const configureAccountForApplications = [];

    if (this.props.context.relativityClient && requestModel.addApplications && requestModel.addApplications.length) {
      for (let i = 0; i < requestModel.addApplications.length; i++) {
        // TODO will need to be more discriminating about which apps get the client id.
        configureAccountForApplications.push({ applicationId: requestModel.addApplications[i], data: this.props.context.relativityClient.relativityClientId.toString() });
      }
    }

    if (this.state.mode === Mode.Edit) {
      await this.props.updateUser({ ...requestModel });
    } else {
      await this.props.createUser({ ...requestModel, configureAccountForApplications, resourceContextId: this.props.context.admin ? this.props.context.admin.contextResourceGroupId : null });
    }

    this.props.updateUnsavedChanges(false);
  }

  getBtnsList(isSaveDisabled: boolean, isSaving: boolean = false): any {
    const buttons: any = [
      {
        buttonLabel: "Cancel",
        type: "button",
        handleClick: this.handleCancelClick.bind(this)
      },
      {
        buttonLabel: "Save",
        type: "button",
        disabled: isSaveDisabled,
        color: "primary",
        handleClick: this.handleSaveClick.bind(this),
        isSaving: isSaving
      }
    ];

    return buttons;
  }

  private async ensureDataFetched() {
    if (this.state.mode === Mode.Edit) {
      let userId: number = Number(this.props.match.params.userId);

      this.setState({ ...this.state, isLoading: true });

      const isContext = !this.props.sessionData.permissions.has("EpiqAdminGetUser");
      const response = isContext ? await AdminContextService.getUser(userId) : await AdminService.getUserDetails(userId);

      if (!response.ok) {
        this.setState({
          ...this.state, updateModel: new SubmittedUpsertUserModel() /* our default */, initializing: false,
          selectedSsoGroup: [], selectedUserGroups: [], selectedApplications: [], contextPermissions: [],
          removedSsoGroups: [], removedUserGroups: [], removedApps: [], getUserError: true, isLoading: false
        });

        return;
      }

      const userDetails = isContext ? response.data.data : response.data;
      const contextPermissions = isContext ? response.data.availablePermissions : [];
      const updateModel = CommonHelper.convertPropsToType(userDetails, SubmittedUpsertUserModel);

      console.log("conerted to updateModel***", updateModel);

      updateModel.hasValidEmail = CommonHelper.validateEmailFormat(updateModel.email) || CommonHelper.validateEmailFormat(updateModel.username);
      updateModel.incidentNumber = updateModel.incidentNumber === null ? "" : updateModel.incidentNumber;

      this.setState({
        ...this.state, updateModel: updateModel, initializing: false,
        selectedSsoGroup: [], selectedUserGroups: [], selectedApplications: [], getUserError: false,
        removedSsoGroups: [], removedUserGroups: [], removedApps: [], userDetails, contextPermissions, isLoading: false
      });

      this.props.saveUnsavedChanges(JSON.stringify(updateModel), false);
    } else {

      let selectedApplications = new Array<Application>();

      if (this.state.useContext) {
        const appResponse = await AdminContextService.getAssignableRelInstanceApps({ resourceGroupId: this.props.context.admin.contextResourceGroupId, take: 1000 });

        if (appResponse.ok && appResponse.data.length) {
          selectedApplications = (appResponse.data as Array<IResourceContent<Application>>).map(a => a.data);
        }
      }

      this.setState({ ...this.state, initializing: false, selectedApplications });
    }
  }

  private toggleSave() {
    const dirtyApplicationsList = this.applicationsListIsDirty();
    const dirtyUserGroupsList = this.usersGroupsListIsDirty();
    const dirtysSoGroupList = this.sSoListIsDirty();
    const canSave = (!this.state.isAppSelectorOpen && !this.state.isSsoSelectorOpen) &&
      ((this.userDetailsIsDirty() && this.state.userDetailsFormIsValid) ||
        (!this.userDetailsIsDirty() && this.state.userDetailsFormIsValid && (dirtyApplicationsList || dirtyUserGroupsList || dirtysSoGroupList)));

    this.props.setHeaderButtons(this.getBtnsList(!canSave), "", this.getListingPage(), "Back to users list");
  }

  needsClient() {
    return !this.state.advancedFields /*advanced users don't need context*/ && !this.props.context.relativityClient && !this.props.context.hasNoRelClients;
  }

  handleAppsPopupClose(accepted: boolean) {
    if (accepted) {
      const deduped = Application.dedupeApps(
        this.state.potentiallySelectedApplications,
        this.state.selectedApplications || new Array<Application>()
      );
      const dedupedApps = deduped.map((item) => ({ ...item, selected: false }));

      this.setState({ ...this.state, selectedApplications: dedupedApps, isAppSelectorOpen: false, enableAddButton: false }, this.toggleSave);
    }
    else {
      this.setState({ ...this.state, isAppSelectorOpen: false, enableAddButton: false }, this.toggleSave);
    }
  }

  handleAppsPopupOpen() {
    this.toggleSave();
  }

  handleAppsSsoGroupPopupClose(accepted: boolean) {
    if (accepted) {
      const deduped = CommonHelper.dedupeObjects<ISsoUserGroup>(this.state.potentiallySelectedSsoGroup, this.state.selectedSsoGroup, 'id');
     
      var concatExcludedSsoGroupIds = deduped.map(sso => sso.id).concat(this.state.excludedSsoGroupIds);
      var dedupedExcludedSsoGroupIds = Array.from(new Set(concatExcludedSsoGroupIds))
      
      this.setState({ ...this.state, selectedSsoGroup: deduped, isSsoSelectorOpen: false, enableAddSsoGroupButton: false, excludedSsoGroupIds:dedupedExcludedSsoGroupIds }, this.toggleSave);
    }
    else {
      this.setState({ ...this.state, isSsoSelectorOpen: false, enableAddSsoGroupButton: false }, this.toggleSave);
    }
  }

  handleAppsSsoGroupPopupOpen() {
    this.toggleSave();
  }

  handlePotentiallySelected(selected: Array<Application>) {
    this.setState({ ...this.state, potentiallySelectedApplications: selected, enableAddButton: selected && selected.length > 0 });
  }

  handlePotentiallySelectedSsoGroups(selected: Array<ISsoUserGroup>) {
    this.setState({ ...this.state, potentiallySelectedSsoGroup: selected, enableAddSsoGroupButton: selected && selected.length > 0 });
  }

  userDetailsIsDirty() {
    return this.props.initialState !== JSON.stringify(this.state.updateModel);
  }

  applicationsListIsDirty() {
    return this.state.selectedApplications.length > 0 || this.state.removedApps.length > 0;
  }

  usersGroupsListIsDirty() {
    return this.state.selectedUserGroups.length > 0 || this.state.removedUserGroups.length > 0;
  }

  sSoListIsDirty() {
    return this.state.selectedSsoGroup.length > 0 || this.state.removedSsoGroups.length > 0;
  }

  onRemoveUpdateApps(removedApp: Application) {
    const { selectedApplications, removedApps } = this.state;
    const selected = [...selectedApplications]

    for (let s = 0; s < selectedApplications.length; s++) {
      if (selectedApplications[s].id == removedApp.id) {
        // not removing from DB since we just added it
        selected.splice(s, 1);
        this.setState({ selectedApplications: selected });
        return;
      }
    }

    this.setState({ removedApps: removedApps.concat(removedApp) }, this.toggleSave);
  }

  onRemoveUpdateSsoGroups(removedSsogroup: ISsoUserGroup) {
    const { selectedSsoGroup, removedSsoGroups, excludedSsoGroupIds} = this.state;
    const selected = [...selectedSsoGroup];
    
    for (let i = 0; i < excludedSsoGroupIds.length; i++) {
      if (excludedSsoGroupIds[i] == removedSsogroup.id) {
          excludedSsoGroupIds.splice(i, 1);
        }
    }
    this.setState({excludedSsoGroupIds: excludedSsoGroupIds});

    for (let s = 0; s < selectedSsoGroup.length; s++) {
      if (selectedSsoGroup[s].id == removedSsogroup.id) {
        // not removing from DB since we just added it
        selected.splice(s, 1);
        this.setState({ selectedSsoGroup: selected });
        return;
      }
    }

    this.setState({ removedSsoGroups: removedSsoGroups.concat(removedSsogroup) }, this.toggleSave);
  }

  handleAddApps() {
    this.setState({ isAppSelectorOpen: true });
  }

  handleAddSsoGroups() {
    this.setState({ isSsoSelectorOpen: true });
  }

  handleUserEdited(user: SubmittedUpsertUserModel, isValid: boolean, silentlyUpdateDirtyState: boolean) {
    this.setState({ ...this.state, updateModel: user, userDetailsFormIsValid: isValid }, this.toggleSave);
  }

  handleUserEditCancelled(user: SubmittedUpsertUserModel, isValid: boolean) {
    this.setState({ ...this.state, updateModel: user, userDetailsFormIsValid: isValid }, this.toggleSave);
  }

  removeUserGroups(removedUserGroup: UserGroup) {
    const { selectedUserGroups, removedUserGroups } = this.state;
    const selected = [...selectedUserGroups];

    for (let s = 0; s < selectedUserGroups.length; s++) {
      if (selectedUserGroups[s].groupId == removedUserGroup.groupId) {
        // not removing from DB since we just added it
        selected.splice(s, 1);
        this.setState({ selectedUserGroups: selected });
        return;
      }
    }

    this.setState({ removedUserGroups: removedUserGroups.concat(removedUserGroup) }, this.toggleSave);
  }

  addUserGroupClickHandle = () => {
    this.setState({ ...this.state, isUserGroupsAssignOpen: true });
  }

  addSsoGroupClickHandle = () => {
    this.setState({ ...this.state, isSsoSelectorOpen: true });
  }

  onAddPotentiallySelectedUserGroups = (potentiallyAddedUserGroups: UserGroup[]) => {
    this.setState({ ...this.state, potentiallyAddedUserGroups });
  }

  handleAddUserGroupsPopupClose = (accepted: boolean) => {
    if (accepted) {
      const dedupeUserGroups = CommonHelper.dedupeObjects<UserGroup>(
        this.state.potentiallyAddedUserGroups,
        this.state.selectedUserGroups || new Array<UserGroup>(),
        "groupId"
      );

      this.setState({ ...this.state, selectedUserGroups: dedupeUserGroups, isUserGroupsAssignOpen: false, potentiallyAddedUserGroups: [] }, this.toggleSave);
    } else {
      this.setState({ ...this.state, isUserGroupsAssignOpen: false, potentiallyAddedUserGroups: [] });
    }
  }

  componentWillUnmount() {
    this.props.resetUserDetailsStateToDefault();
    this.props.setHeaderButtons(null);
    this.props.saveUnsavedChanges(null, false);
    this.setState({ reConfigureJobId: "", isLoading: false });
  }

  //static getDerivedStateFromProps(props: Props, state: State) {
  //  if (state.updateModel.id !== props.userDetails.id) {
  //    return {
  //      updateModel: CommonHelper.syncMatchingProps(props.userDetails, state.updateModel)
  //    };
  //  }
  //  return null;
  //}

  isEpiqUser = () => {
    const email = this.state.updateModel.username;
    const userDomain = email && email.substring(email.indexOf('@') + 1, email.length);
    const epiqDomains = this.props.sessionData.internalUsersDomains;
    return epiqDomains && epiqDomains.has(userDomain.toLowerCase());
  }

  getFUllUserName = () => {
    return this.state.updateModel.lastName || this.state.updateModel.firstName ? this.state.updateModel.lastName + ", " + this.state.updateModel.firstName : "";
  }

  updateLoader = (isLoading: boolean) => {
    this.setState({ isLoading: isLoading });
  }

  ssoGroupGridToolBarRender = (): JSX.Element => {
    return (
      <KendoButton className="add-user-btn" icon={"plus"} primary={true}
        onClick={this.addSsoGroupClickHandle}
        disabled={!(this.props.sessionData.permissions.has("EpiqAdminListSSOGroups") || this.props.sessionData.permissions.has("AssignOktaGroup")) /*TODO AssignOktaGroup is not ideal, but should be good enough for now*/ }>
        Okta Groups
      </KendoButton>
    );
  };

  handleSelect = (e: TabStripSelectEventArguments) => {
    const selectedTabIndex = e.selected;
    this.setState({ selectedTab: selectedTabIndex });
  };

  getListingPage = () => {
    if (this.state.useContext) {
      return "/administration/context-users";
    } else {
      return "/administration/users";
    }
  };

  onLoadData (groups: ISsoUserGroup[]){
  this.setState({excludedSsoGroupIds: groups.map(sso => sso.id)})
  };

  render() {
    let userDetails;
    if ((this.state.mode !== Mode.Create && this.state.isLoading) || this.state.initializing) {
      return (
        <Paper className="user-details-wrapper">
          <div className="no-users">
            <CircularProgress />
          </div>
        </Paper>
      );
    } else if (this.state.getUserError) {
      userDetails = (
        <Paper className="user-details-wrapper">
          <div className="no-users">
            <p>Oops! Something went wrong (or) you do not have permissions to view this user.</p>
            <p>Please go to the user list page and search for the user.</p>
          </div>
        </Paper>
      );
    } else {

      userDetails = (
        <div className="user-component-wrapper">
          <User
            onUserEdited={this.handleUserEdited.bind(this)}
            onUserEditCancelled={this.handleUserEditCancelled.bind(this)}
            user={this.state.mode === Mode.Edit || this.state.mode === Mode.Create ? this.state.userDetails : new UserDetailsModel()}
            isSaving={this.state.isSaving}
            {...this.props}
            createUser={this.state.mode === Mode.Create}
            permissions={this.props.sessionData.permissions}
            internalUsersDomains={this.props.sessionData.internalUsersDomains}
            onReconfigureUser={this.onReconfigureUser.bind(this)}
            updateLoader={this.updateLoader.bind(this)}
            isLoggedInAsSuperAdmin={this.props.sessionData.superAdmin}
            contextPermissions={this.state.contextPermissions}
            onUserActionsChange={(change) => this.setState({userActionChanges: change})}
          />
          {this.state.isLoading && <Paper className="user-loader-wrapper">
            <CircularProgress className="loader" />
          </Paper>}
        </div>
      );
    }

    var contextRgId = (this.state.useContext && this.props.context.admin) || (this.props.context.admin && !this.props.sessionData.permissions.has("EpiqAdminListApplication")) ? this.props.context.admin.contextResourceGroupId : null;

    const tabbedView =
      (!this.state.getUserError && this.state.updateModel.id >= 0) ? (
        <>
          <GridSelector
            isOpen={this.state.isSsoSelectorOpen}
            acceptBtnText="Add"
            cancelBtnText="Cancel"
            titleText="Add Okta Groups"
            addAvailable={
              this.state.enableAddSsoGroupButton
            }
            onClose={this.handleAppsSsoGroupPopupClose.bind(this)}
            onOpen={this.handleAppsSsoGroupPopupOpen.bind(this)}
            fullWidth={true}
          >
            {this.state.isSsoSelectorOpen && <SsoGroupList
              className="sso_group"
              selectionChange={this.handlePotentiallySelectedSsoGroups.bind(this)}
              isOpen={this.state.isSsoSelectorOpen}
              excludedGroupIds = {this.state.excludedSsoGroupIds}
            />}
          </GridSelector>
          <GridSelector
            isOpen={this.state.isAppSelectorOpen}
            onOpen={this.handleAppsPopupOpen.bind(this)}
            acceptBtnText="Add"
            cancelBtnText="Cancel"
            titleText="Add Applications"
            addAvailable={
              this.state.enableAddButton
            }
            onClose={this.handleAppsPopupClose.bind(this)}
            fullWidth={true}
          >
            {this.state.isAppSelectorOpen && <ApplicationList
              typeName={"Applications Access"}
              selectionChange={this.handlePotentiallySelected.bind(this)}
              hideAddButton={true}
              enableSelectApplication={true}
              permissions={this.props.sessionData.permissions}
              isEpiqUser={this.isEpiqUser()}
              isOpen={this.state.isAppSelectorOpen}
              excludeUserId={this.props.match.params.userId}
              excludedAppIds={this.state.selectedApplications ? this.state.selectedApplications.map(app => app.id) : null}
              limitResourceGroupId={this.props.sessionData.permissions.has("EpiqAdminGetUser") ? null : contextRgId}
            />}
          </GridSelector>

          <TabStrip selected={this.state.selectedTab} onSelect={this.handleSelect} className="angle-tabs" keepTabsMounted={true}>
            <TabStripTab title={"Applications"}>
              <ApplicationList
                typeName={"Applications Access"}
                additionalApplications={this.state.selectedApplications}
                disableAddBtn={this.state.userDetails.reconfigureUserStatus && this.state.userDetails.reconfigureUserStatus !== null}
                addClickHandler={this.handleAddApps.bind(this)}
                userId={this.props.match.params.userId}
                canOpenPopup={true}
                displayRemoveBtn={true}
                onRemoveUpdateApps={this.onRemoveUpdateApps.bind(this)}
                enableRemoveApplication={true}
                permissions={this.props.sessionData.permissions}
                isEpiqUser={this.isEpiqUser()}
                limitResourceGroupId={this.props.sessionData.permissions.has("EpiqAdminGetUser") ? null : contextRgId}
              />
            </TabStripTab>
            {(this.props.sessionData.permissions.has("EpiqAdminCreateUser") || this.props.sessionData.permissions.has("EpiqAdminUpdateUser")) && <TabStripTab title={"Epiq Access User Groups"}>
              <UserGroupAssignmentsList userId={this.props.match.params.userId} additionalUserGroups={this.state.selectedUserGroups || []} removeUserGroups={this.removeUserGroups.bind(this)} addUserGroupClickHandler={this.addUserGroupClickHandle.bind(this)} /*updateUserGroups={this.updateUserGroups}*/ permissions={this.props.sessionData.permissions} disableAddBtn={this.state.userDetails.reconfigureUserStatus && this.state.userDetails.reconfigureUserStatus !== null} />
            </TabStripTab>}
            <TabStripTab title={"Okta Groups"}>
              <SsoGroupList
                className="sso_group"
                addClickHandler={this.handleAddSsoGroups.bind(this)}
                gridToolbarContent={this.ssoGroupGridToolBarRender()}
                restrictToUserIdpId={this.state.updateModel.idpId || kOktaGroupNoOp}
                additionalGroups={this.state.selectedSsoGroup}
                enableRemoveSsoGroup={true}
                onRemoveUpdateSsoGroups={this.onRemoveUpdateSsoGroups.bind(this)}
                onLoadData = {this.onLoadData.bind(this)}
              />
            </TabStripTab>
            <TabStripTab title={"Speed Tests"}>
             <SpeedTestList userId={this.props.match.params.userId}></SpeedTestList>
            </TabStripTab>
          </TabStrip>
        </>
      ) : (
        ""
      );

    const users =
      this.state.updateModel.id >= 0 ? (
        <>
          <GridSelector
            isOpen={this.state.isUserGroupsAssignOpen}
            acceptBtnText="Add"
            cancelBtnText="Cancel"
            titleText="Add User Group(s)"
            prefixTitleText="USERS"
            addAvailable={this.state.potentiallyAddedUserGroups.length > 0}
            onClose={this.handleAddUserGroupsPopupClose}
            addClass="add-user-group-to-user modal-as-sidebar"
          >
            <UserGroups
              userName={this.getFUllUserName()}
              onAddUpdateUserGroups={this.onAddPotentiallySelectedUserGroups}
              selectedUserGroups={this.state.selectedUserGroups || []}
              isEpiqUser={this.isEpiqUser()}
              domain={this.state.userDetails.employerDomain}
            />
          </GridSelector>
        </>
      ) : (
        ""
      );

    return (
      <Paper className="user-detais-wrapper no-separator">
        {userDetails}
        <div className="user-tabbed-view">
          {tabbedView}
        </div>
        {users}
        {this.state.displayAssignDialog && (
          <Dialog
            title={
              this.state.updateModel.superAdmin ? "Assign User as Global Admin?" : "Unassign User as Global Admin?"
            }
            onClose={() => this.setState({ ...this.state, displayAssignDialog: false })}
          >
            {this.state.updateModel.superAdmin && (
              <p>
                Assigning the Global Admin role provides a user with full administrative access to system functionality.
                Confirm that you want to assign this user to Global Admin.
              </p>
            )}
            {!this.state.updateModel.superAdmin && (
              <p>
                Removing the Global Admin role from a user revokes full administrative access to system functionality.
                Confirm that you want to remove the Global Admin role from this user.
              </p>
            )}
            <DialogActionsBar>
              <Button
                variant="contained"
                className="btn-contined"
                name="YES"
                onClick={this.toggleAssignDialogEvent.bind(this)}
              >
                YES
              </Button>
              <Button
                variant="contained"
                className="btn-contined"
                color="primary"
                name="NO"
                onClick={this.toggleAssignDialogEvent.bind(this)}
              >
                NO
              </Button>
            </DialogActionsBar>
          </Dialog>
        )}
      </Paper>
    );
  }
}

export default connect(
  (state: IApplicationState) => ({
    ...state.headerState,
    ...state.userState,
    ...state.warningMessageState,
    ...state.jobState,
    ...state.sessionState
  }),
  {
    ...HeaderStore.actionCreators,
    ...UsersStore.actionCreators,
    ...WarningMessageStore.actionCreators,
    ...JobStore.actionCreators
  }
)(UserDetails as any);
