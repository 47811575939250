import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import * as SessionStore from "../../../store/Session";
import { IApplicationState } from "../../../store";
import { GridColumnProps, GridFilterCellProps, GridFilterCell, GridCellProps } from "@progress/kendo-react-grid";
import NotificationService from "../../../services/NotificationService";
import { AdvancedGrid, IGridParamsAdvancedRefreshable } from "../../common/grid/AdvancedGrid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import "./JobList.scss";
import FilterHelper from "../../common/grid/FilterHelper";
import JobsService from "../../../services/JobService";
import { IJobQueueResult, IJobStateResult, IJobTypeResult } from "../../../models/JobModels";
import { FilterDescriptor } from "@progress/kendo-data-query";
import { v4 as uuid } from "uuid";
import { DropDownFilter } from "../grid/filters/DropDownFilter";
import { uniqWith } from "lodash";
import { NavLink } from "react-router-dom";
import GridRefreshButton from "../GridRefreshButton";

export interface IJobsRequest extends IGridParamsAdvancedRefreshable {
    includeSystemGeneratedJobs?: boolean;
}

export interface IAdminJobQueueOptions {
    includeSystemGeneratedJobs: boolean;
}

interface IProps {
    match?: any;
    history: any;
}

type IJobQueueProps = {
    adminJobQueueOptions?: IAdminJobQueueOptions;
};

type Props = IProps & SessionStore.ISessionState & IJobQueueProps;

const JobList = (props: Props) => {
    const prevGridState: IJobsRequest = props.history.location.state && props.history.location.state.dataState ? props.history.location.state.dataState : null;
    const [jobTypes, setJobTypes] = useState(new Array<IJobTypeResult>());
    const [jobStates, setJobStates] = useState(new Array<IJobStateResult>());
    const [jobs, setJobs] = useState(new Array<IJobQueueResult>());
    const [totalJobs, setTotalJobs] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const gridRef = useRef<AdvancedGrid<IJobQueueResult, IJobsRequest>>(null);
    const [dataState, setDataState] = useState(prevGridState && prevGridState.filters ? prevGridState : {
        includeSystemGeneratedJobs: props.adminJobQueueOptions ? props.adminJobQueueOptions.includeSystemGeneratedJobs : null,
        refreshToggle: false,
        skip: 0,
        take: 500,
        sort: [{ field: "createdDate", dir: "desc" }],
    } as IJobsRequest);
    const [prevDataState, setPrevDataState] = useState(prevGridState && prevGridState.filters ? prevGridState : null);
    const getJobs = async (dataState: IJobsRequest) => {
        let gridDataState = prevDataState ? prevDataState : dataState;
        const jobFilters = gridDataState.filters
            ? gridDataState.filters.map(compositeFilter => {
                const jobIdFilter: FilterDescriptor = compositeFilter.filters.find(
                    filter => (filter as FilterDescriptor).field === "jobId"
                ) as FilterDescriptor;
                const jobStateFilter: FilterDescriptor = compositeFilter.filters.find(
                    filter => (filter as FilterDescriptor).field === "jobState"
                ) as FilterDescriptor;
                const jobTypeFilter: FilterDescriptor = compositeFilter.filters.find(
                    filter => (filter as FilterDescriptor).field === "jobTypeDisplay"
                ) as FilterDescriptor;

                if (jobIdFilter) {
                    const isGuid = jobIdFilter.value.match("^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$");
                    const isEmpty = jobIdFilter.value.length === 0;

                    if (isEmpty || !isGuid) {
                        return {
                            ...compositeFilter,
                            filters: [
                                {
                                    ...jobIdFilter,
                                    operator: isEmpty ? "neq" : jobIdFilter.operator,
                                    value: uuid()
                                }
                            ]
                        };
                    }
                }

                //if jobStateFilter.value is empty we need to flip the operator
                //JWK: maybe consider updating dropdownfilter to handle this for us and also to allow contains
                //which would solve the issue of value = '' returning nothing.
                if (jobStateFilter) {
                    return {
                        ...compositeFilter,
                        filters: [
                            {
                                ...jobStateFilter,
                                value: jobStateFilter.value !== '' ? jobStateFilter.value.name : jobStateFilter.value,
                                operator: jobStateFilter.value !== '' ? jobStateFilter.operator : "neq"
                            }
                        ]
                    };
                }

                //if jobTypeFilter.value is empty we need to flip the operator
                //JWK: maybe consider updating dropdownfilter to handle this for us and also to allow contains
                //which would solve the issue of value = '' returning nothing.
                if (jobTypeFilter) {
                    return {
                        ...compositeFilter,
                        filters: [
                            {
                                ...jobTypeFilter,
                                value: jobTypeFilter.value !== '' ? jobTypeFilter.value.display : jobTypeFilter.value,
                                operator: jobTypeFilter.value !== '' ? jobTypeFilter.operator : "neq"
                            }
                        ]
                    };
                }

                return compositeFilter;
            })
            : [];

        let filters = FilterHelper.getModifiedCorrectDateFilter(jobFilters, "createdDate");

        filters = FilterHelper.getModifiedCorrectDateFilter(filters, "lastModifiedDate");

        setIsLoading(true);

        try {
            const result = await JobsService.getJobs({ ...dataState, filters }, props.adminJobQueueOptions !== null);

            if (result.ok) {
                setJobs(result.data.results);
                setTotalJobs(result.data.count);
                setHasError(false);
            }
            else {
                setHasError(true);
            }

        } catch (e) {
            setHasError(true);
        } finally {
            //we set this to retain datastate so that on manual refresh it retains filters properly including showing the filter selections
            setDataState(gridDataState);
            setIsLoading(false);
            setPrevDataState(null);

            if (hasError) {
                setJobs(new Array<IJobQueueResult>());
                setTotalJobs(0);
                NotificationService.showErrorToast("Something went wrong while getting Jobs.");
            }
        }
    };
    const jobIdClickableCell = (props: GridCellProps) => {
        return <td>
            <div className="request-number">
                {" "}
                <NavLink to={{ pathname: `/administration/job/jobdetails/${props.dataItem.jobId}`, state: { dataState: dataState } }}>
                    <em className="job-id-cell clickable" title={props.dataItem.jobId}>{props.dataItem.jobId}</em>
                </NavLink>
            </div>
        </td>;
    };
    const jobIdCell = (props: GridCellProps) => {
        return <td>
            <div className="request-number">
                <em className="job-id-cell" title={props.dataItem.jobId}>{props.dataItem.jobId}</em>
            </div>
        </td>;
    };
    const userCell = (props: GridCellProps) => {
        return <td>
            <em className="job-user-cell">{props.dataItem.firstName} {props.dataItem.lastName}</em>
        </td>;
    };
    const statusCell = (props: GridCellProps) => {
        const status = props.dataItem.jobState as string;
        const statusClass = `status-circle ${status
            .toLowerCase()
            .trim()
            .replace(/\s+|[,\/]/g, "-")}`;

        return (
            <td>
                <div className="status">
                    <div className={statusClass} />
                    <div className="status-label">{status}</div>
                </div>
            </td>
        );
    };
    const submittedCell = (props: GridCellProps) => {
        return dateCell(props, props.dataItem.createdDate);
    };
    const lastUpdatedCell = (props: GridCellProps) => {
        return dateCell(props, props.dataItem.lastModifiedDate);
    };
    const dateCell = (props: GridCellProps, dataField: string) => {
        const newDateValue = new Date(dataField);
        const dateValue = newDateValue.toLocaleDateString();
        const timeValue = newDateValue.toLocaleTimeString();

        return (
            <td>
                <div className="date-cell">
                    <div className='date-cell-primary'>{dateValue}</div>
                    <div className="date-cell-secondary">{timeValue}</div>
                </div>
            </td>
        );
    };
    const getColumns = (): Array<GridColumnProps> => {
        const columns = new Array<GridColumnProps>(
            {
                field: "jobId", title: "JOB ID", filterable: true, cell: props.sessionData.permissions.has("EpiqAdminGetJob") ? jobIdClickableCell : jobIdCell, filterCell: (props: GridFilterCellProps) => (
                    <GridFilterCell
                        {...props}
                        operators={[{ text: "Is equal to", operator: "eq" }]}
                        filterType="text"
                    />
                )
            },
            {
                field: "jobTypeDisplay", title: "JOB TYPE", filterable: true, filter: "text", filterCell: (props: GridFilterCellProps) => (
                    <DropDownFilter
                        {...props}
                        data={jobTypes}
                        textField="display"
                        defaultSelectedOption={null}
                    />
                )
            },
            { field: "createdDate", title: "SUBMITTED", filterable: true, filter: "date", cell: submittedCell },
            { field: "lastModifiedDate", title: "LAST UPDATED", filterable: true, filter: "date", cell: lastUpdatedCell },
            {
                field: "jobState", title: "STATUS", filterable: true, filter: "text", cell: statusCell, filterCell: (props: GridFilterCellProps) => (
                    <DropDownFilter
                        {...props}
                        data={jobStates}
                        textField="name"
                        defaultSelectedOption={null}
                    />
                )
            }
        );

        if (props.adminJobQueueOptions) {
            columns.splice(2, 0, { field: "lastName | firstName", title: "SUBMITTED BY", filterable: true, filter: "text", cell: userCell })
        }

        return columns;
    };

    const loadFilterDropdownsData = async () => {
        const jobTypes = (await JobsService.getJobTypes()).data as Array<IJobTypeResult>;
        const jobStates = (await JobsService.getJobStates()).data as Array<IJobStateResult>;

        //we need to dedupe jobtypes since we are filtering on display and that is the value we will load into the dropdownfilter
        setJobTypes(uniqWith(jobTypes, (a, b) => a.display === b.display));
        setJobStates(jobStates);
    }

    useEffect(() => {
        loadFilterDropdownsData();
    }, []);

    const reloadData = async () => {
        const newState: IJobsRequest = { ...dataState, refreshToggle: !dataState.refreshToggle };

        gridRef.current.resetGridState(newState);
    };

    const gridToolBarRender = () => {
        return (
            <>
                <GridRefreshButton onRefreshGridClick={() => reloadData()} />
            </>
        );
    };

    return (
        <div className="job-list-grid-wrapper">
            <Tooltip openDelay={2} position="top">
                <AdvancedGrid
                    ref={(advancedGridInstance: AdvancedGrid<IJobQueueResult, IJobsRequest>) => {
                        gridRef.current = advancedGridInstance;
                    }}
                    showErrorState={hasError}
                    showLoadingIndicator={isLoading}
                    data={jobs}
                    dataFetch={getJobs}
                    dataState={dataState}
                    columns={getColumns()}
                    paging={false}
                    totalRecords={{ value: totalJobs, label: "Jobs" }}
                    multiFieldFilterDelimiter="|"
                    noRecordsRender={<p>No job data found.</p>}
                    noLoadOnMount={false}
                    filteredDataOnly={false}
                    filterOperators={{
                        text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
                        date: [{ text: "grid.filterIsNotNullOperator", operator: "isnotnull" }, { text: "grid.filterIsNullOperator", operator: "isnull" }],
                    }}
                    gridToolbarContent={gridToolBarRender()}
                />
            </Tooltip>
        </div>
    );
};

export default JobList;