import * as React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../store";
import * as HeaderStore from "../../../store/Header";
import * as SessionStore from "../../../store/Session";
import * as WarningMessageStore from "../../../store/WarningMessage";
import AdminService from "../../../services/AdminService";
import NotificationService from "../../../services/NotificationService";
import {
  Application as ApplicationModel,
  ApplicationsType,
  UpsertUserAppModel,
  ApplicationStatus
} from "../../../models/ApplicationModels";
import { Paper, Grid } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CreateIcon from "@material-ui/icons/Create";
import CircularProgress from "@material-ui/core/CircularProgress";
import Edit from "./details/Edit";
import View from "./details/View";
import "./Applications.scss";
import GridSelector from "../../common/grid/GridSelector";
import AddUserToApp from "./details/AddUserToApp";
import AppUserList from "./details/AppUserList";
import { User } from "../../../models/UserModels";
import CommonHelper from "../../common/utilities/CommonHelper";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import DialogBox from "../../common/DialogBox";
import { DialogCloseEvent } from "@progress/kendo-react-dialogs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import CollapsibleSection from "../common/CollapsibleSection";
import { IAppFeature } from "../../../models/Features";

enum Mode {
  Edit,
  Create,
  View
}

const errorMessages = {
  appNameExists: "App name already exists.",
  startUpUrlAndIdpGroupIdExists: "Idp Group Id + Startup url already exists.",
  hasEpiqOnlyError: "This application cannot be marked as Epiq Only because the following are not known Epiq user(s) - ",
  invalidIdpGroupId: "Invalid Idp Group Id"
};

const urlRegex = new RegExp(/^(https?):\/\/(-\.)?([^\s\/?\.#]+\.?)+(\/[^\s]*)?$/i);

interface IProps {
  match?: any;
  history: any;
}

type Props = IProps &
  HeaderStore.IHeaderState &
  typeof HeaderStore.actionCreators &
  WarningMessageStore.IWarningMessageState &
  typeof WarningMessageStore.actionCreators &
  SessionStore.ISessionState;

type State = {
  mode: Mode;
  appInfoEditMode: boolean;
  appCreateMode: boolean;
  appDetails: ApplicationModel;
  isLoading: boolean;
  hasErrors: boolean;
  appTypes: Array<ApplicationsType>;
  isSaving: boolean;
  updateModel: UpsertUserAppModel;
  isAppNameExists: boolean;
  isStartUpUrlAndIdpGroupIdExists: boolean;
  isInvalidIdpGroupId: boolean;
  isFormReadyForSave: boolean;
  isUserSelectorOpen: boolean;
  selectedUsers: User[];
  savedUsers: User[];
  potentiallyAddedUsers: User[];
  showDeactivateConfirmDialog: boolean;
  showReactivateConfirmDialog: boolean;
  hasEpiqOnlyError: boolean;
  nonEpiqUsers: string[];
  canDelete: boolean;
  openDeletePopup: boolean;
  appUsersCount: number;
  oktaSavedApplications: Array<ApplicationModel>;
  oktaFilteredApplications: Array<ApplicationModel>;
  selectedOktaApp: ApplicationModel;
  isAppDetailsFetching: boolean;
  statusId: number;
  showGroupIdWarningDialog: boolean;
};

class Application extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const mode = Number(this.props.match.params.id) > 0 ? Mode.Edit : Mode.Create;

    this.state = {
      mode,
      appInfoEditMode: mode === Mode.Create,
      appCreateMode: props.match.params.id > 0 ? false : true,
      appDetails: null,
      isLoading: false,
      hasErrors: false,
      appTypes: new Array<ApplicationsType>(),
      isSaving: false,
      updateModel: new UpsertUserAppModel(),
      isAppNameExists: false,
      isStartUpUrlAndIdpGroupIdExists: false,
      isInvalidIdpGroupId: false,
      isFormReadyForSave: false,
      isUserSelectorOpen: false,
      selectedUsers: [],
      savedUsers: [],
      potentiallyAddedUsers: [],
      showDeactivateConfirmDialog: false,
      showReactivateConfirmDialog: false,
      hasEpiqOnlyError: false,
      nonEpiqUsers: [],
      canDelete: false,
      openDeletePopup: false,
      appUsersCount: 0,
      oktaSavedApplications: [],
      oktaFilteredApplications: [],
      selectedOktaApp: new ApplicationModel(),
      isAppDetailsFetching: false,
      statusId: 0,
      showGroupIdWarningDialog: false
    };
  }

  getBtnsList(isSaveDisabled: boolean, isSaving: boolean = false): any {
    const buttons: any = [
      {
        buttonLabel: "Cancel",
        type: "button",
        handleClick: this.handleCancelClick.bind(this)
      },
      {
        buttonLabel: "Save",
        type: "button",
        disabled: isSaveDisabled,
        color: "primary",
        handleClick: this.handleSaveClick.bind(this),
        isSaving: isSaving
      }
    ];

    return buttons;
  }

  handleCancelClick = () => {
    this.props.history.push("/administration/applications");
  };

  onAppDetailsEdit = () => {
    this.setState({
      appInfoEditMode: true
    });
  };

  onCancelEditappInfo = () => {
    this.setState({
      appInfoEditMode: false,
      updateModel: {
        ...this.state.updateModel,
        ...this.state.appDetails
      }
    })
  }

  async componentDidMount() {
    await this.props.saveUnsavedChanges(JSON.stringify(this.state.updateModel), false);
    await this.props.setHeaderButtons(this.getBtnsList(true), "", "", " ");

    this.setState({ isLoading: true });
    await this.getAllAppTypes();
    if (this.state.mode === Mode.Create && this.props.sessionData.superAdmin) {
      await this.getAllOktaApps("");
    }
    if (this.state.mode == Mode.Edit) {
      await this.getAppDetails();
    }
  }

  componentWillUnmount() {
    this.props.setHeaderButtons(null);
    this.props.saveUnsavedChanges(null, false);
    this.setState({ nonEpiqUsers: [] });
  }

  async componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
    if (prevState !== this.state) {
      const { isSaving } = this.state;
      const isDirtyChangesExist = this.isDirtyChangesExist();
      await this.props.updateUnsavedChanges(isDirtyChangesExist);
      await this.props.setHeaderButtons(
        this.getBtnsList(isSaving ? isSaving : !(isDirtyChangesExist && this.isFormValidationsPassed()), isSaving),
        "",
        "",
        ""
      );
    }
  }

  isDirtyChangesExist = () => {
    const { updateModel, selectedUsers, savedUsers } = this.state;
    return (
      this.props.initialState !== JSON.stringify(updateModel) ||
      this.getUsersAfterExclude(selectedUsers, savedUsers).length > 0 ||
      this.getUsersAfterExclude(savedUsers, selectedUsers).length > 0
    );
  };

  isFormValidationsPassed = () => {
    const {
      isAppNameExists,
      isStartUpUrlAndIdpGroupIdExists,
      isInvalidIdpGroupId,
      isFormReadyForSave,
      updateModel,
      mode,
      selectedUsers,
      savedUsers,
      hasEpiqOnlyError
    } = this.state;

    const isImageUrlValid = updateModel.imageUrl ? urlRegex.test(updateModel.imageUrl) : true;

    return (
      (isFormReadyForSave && !isStartUpUrlAndIdpGroupIdExists && !isAppNameExists && !isInvalidIdpGroupId && isImageUrlValid && !hasEpiqOnlyError) ||
      (mode == Mode.Edit
        ? !hasEpiqOnlyError &&
        (isFormReadyForSave ||
          (this.getUsersAfterExclude(selectedUsers, savedUsers).length > 0 ||
            this.getUsersAfterExclude(savedUsers, selectedUsers).length > 0))
        : false)
    );
  };

  private validateEpiqAdminUsers(selectedUsers: User[], epiqOnly: boolean) {
    let { nonEpiqUsers } = this.state;
    this.setState({ nonEpiqUsers: [] });
    const unKnownUsers =
      epiqOnly && selectedUsers.length > 0
        ? selectedUsers.filter(item => {
          if (!this.isEpiqUser(item.username)) {
            nonEpiqUsers.push(item.username);
            return true;
          }
        })
        : [];

    return unKnownUsers.length > 0;
  }

  isEpiqUser = (email: string) => {
    const userDomain = email && email.substring(email.indexOf("@") + 1, email.length);
    const epiqDomains = this.props.sessionData.internalUsersDomains;
    return epiqDomains && epiqDomains.has(userDomain.toLowerCase());
  };

  handleSaveClick = async () => {
    this.setState({
      isSaving: true
    });

    const { updateModel, selectedUsers, savedUsers, mode, hasEpiqOnlyError, nonEpiqUsers } = this.state;
    let failureMessage = "Something went wrong. Update not saved.";
    let successMessage = "Update saved.";
    let response;
    let isAppNameExists = false,
      isStartUpUrlAndIdpGroupIdExists = false, isInvalidIdpGroupId = false;
    let updatedUsersList = new Array<User>();
    let appUsersCount = 0;

    if ((updateModel.idpGroupId === "" && updateModel.startupUrl !== "" && updateModel.ssoApplicationId !== "" && this.state.selectedOktaApp != null && this.state.selectedOktaApp.startupUrl !== "") ||
      (updateModel.idpGroupId === "" && updateModel.startupUrl !== "" && updateModel.ssoApplicationId !== "" && updateModel.ssoEnabled == true && (this.state.selectedOktaApp == null || this.state.selectedOktaApp.idpGroupId == ""))) {

      this.setState({ isSaving: false, showGroupIdWarningDialog: true })
      return;
    }

    const hasEpiqError = this.validateEpiqAdminUsers(selectedUsers, this.state.updateModel.epiqOnly);
    if (!hasEpiqError) {
      let requestModel = CommonHelper.cloneObject(updateModel);
      requestModel.addUsers = this.getUsersAfterExclude(selectedUsers, savedUsers).map(item => {
        return item.id;
      });
      requestModel.removeUsers = this.getUsersAfterExclude(savedUsers, selectedUsers).map(item => {
        return item.id;
      });

      if (Mode.Edit === mode) {
        response = await AdminService.updateApplication(requestModel);
        appUsersCount = await this.getAppUsersCount();
      } else {
        response = await AdminService.createApplication(requestModel);
        successMessage = `${updateModel.name} Created.`;
        failureMessage = "Something went wrong. App was not created.";
      }

      if (response.ok) {
        if (!response.data.inValid) {
          if (requestModel.deleteCreds) {
            updateModel.credentialsKey = null;
            updateModel.credentials = null;
            updateModel.deleteCreds = false;
          } else if (requestModel.credChanges) {
            updateModel.newCredsRecentlySaved = true;
            updateModel.credChanges = false;
          }

          NotificationService.showSuccessToast(successMessage);
          if (Mode.Create === mode) {
            this.props.saveUnsavedChanges(null, false);
            this.props.history.push("/administration/applications");
          } else {
            this.props.saveUnsavedChanges(JSON.stringify(updateModel), false);

            const addedNewUserList = this.getUsersAfterExclude(selectedUsers, savedUsers);
            const removedNewUsersList = this.getUsersAfterExclude(savedUsers, selectedUsers);

            if (addedNewUserList) {
              updatedUsersList = [...addedNewUserList, ...savedUsers];
            }

            if (removedNewUsersList) {
              updatedUsersList = this.getUsersAfterExclude(updatedUsersList.slice(), removedNewUsersList);
            }
          }
        } else if (response.data.inValid && response.data.message) {
          const messages = (response.data.message as string).split(":");
          isAppNameExists = messages[0] === errorMessages.appNameExists;
          isInvalidIdpGroupId = messages[0] === errorMessages.invalidIdpGroupId
          isStartUpUrlAndIdpGroupIdExists = messages[messages.length - 1] === errorMessages.startUpUrlAndIdpGroupIdExists;
          NotificationService.showErrorToast(
            isAppNameExists && isStartUpUrlAndIdpGroupIdExists ? "App name and startup url already exists." : messages[0]
          );

          if (!isAppNameExists && !isStartUpUrlAndIdpGroupIdExists && !isInvalidIdpGroupId) {
            this.props.saveUnsavedChanges(JSON.stringify(updateModel), false);
          }
        }
      } else {
        NotificationService.showErrorToast(failureMessage);
      }

      const failed = !response.ok || response.data.inValid;

      this.setState({
        appInfoEditMode: isAppNameExists || isStartUpUrlAndIdpGroupIdExists || isInvalidIdpGroupId,
        isAppNameExists,
        isStartUpUrlAndIdpGroupIdExists,
        isInvalidIdpGroupId,
        isSaving: false,
        appDetails: updateModel,
        potentiallyAddedUsers: !failed ? [] : this.state.potentiallyAddedUsers,
        savedUsers: !failed ? updatedUsersList : this.state.savedUsers,
        selectedUsers: !failed ? updatedUsersList : this.state.selectedUsers,
        appUsersCount: appUsersCount
      });
    } else {
      NotificationService.showErrorToast(`${errorMessages.hasEpiqOnlyError} ${nonEpiqUsers}`);
      this.setState({ hasEpiqOnlyError: true, isSaving: false, nonEpiqUsers: [] });
    }
  };

  getAllOktaApps = async (startsWith: string) => {
    let oktaApps = [] as Array<ApplicationModel>;
    const response = await AdminService.getOktaAppsList(startsWith);
    if (response.ok) {
      oktaApps = response.data;
    }

    this.setState({ oktaSavedApplications: oktaApps, isLoading: false });
  };

  getAllAppTypes = async () => {
    let appTypes = new Array<ApplicationsType>();
    const response = await AdminService.getAllApplicationTypes();
    if (response.ok) {
      appTypes = response.data as Array<ApplicationsType>;
    }

    this.setState({ appTypes, isLoading: false });
  };

  getAppDetails = async () => {
    this.setState({ isLoading: true });
    const result = await AdminService.getApplicationDetails(this.props.match.params.id);

    const appDetails = result ? (result.data as UpsertUserAppModel) : null;

    appDetails.deleteCreds = false;

    await this.props.saveUnsavedChanges(JSON.stringify(appDetails), false);

    const appUsersCount = await this.getAppUsersCount();

    this.setState({
      isLoading: false,
      appUsersCount: appUsersCount,
      updateModel: appDetails,
      statusId: appDetails.statusId,
      appDetails
    });
  };

  getAppUsersCount = async () => {
    const result = await AdminService.getAppUsersCount(this.props.match.params.id);
    return result ? result.data : 0;
  };

  updateEditedAppDetails = (
    editedAppDetails: UpsertUserAppModel,
    isFormReadyForSave: boolean,
    resetAppNameExistMessage: boolean,
    resetUrlExistMessage: boolean,
    resetIdpGroupId: boolean,
    featureChanges?: Array<IAppFeature>
  ) => {

    console.log("))))))))))))updatmodel", {
      updateModel: { ...editedAppDetails, featureChanges },
      isFormReadyForSave,
      isAppNameExists: resetAppNameExistMessage,
      isStartUpUrlAndIdpGroupIdExists: resetUrlExistMessage,
      isInvalidIdpGroupId: resetIdpGroupId,
    });

    this.setState({
      updateModel: { ...editedAppDetails, featureChanges },
      isFormReadyForSave,
      isAppNameExists: resetAppNameExistMessage,
      isStartUpUrlAndIdpGroupIdExists: resetUrlExistMessage,
      isInvalidIdpGroupId: resetIdpGroupId,
    });
  };

  onRemoveUsers = (users: User[]) => {
    const hasEpiqError = this.validateEpiqAdminUsers(users, this.state.updateModel.epiqOnly);
    this.setState({ selectedUsers: users, hasEpiqOnlyError: hasEpiqError });
  };

  handleAddUsers = () => {
    this.setState({ isUserSelectorOpen: true });
  };

  selectionChange = (selected: User[]) => {
    this.setState({ selectedUsers: selected, savedUsers: selected });
  };

  handleBulkRemoveAllComplete = () => {
    this.setState({ appUsersCount: 0 });
  };

  handleAddUsersPopupClose = (accepted: boolean) => {
    if (accepted) {
      const dedupeUsers = CommonHelper.dedupeObjects<User>(
        this.state.potentiallyAddedUsers,
        this.state.selectedUsers || new Array<User>(),
        "id"
      );

      this.setState({ selectedUsers: dedupeUsers, isUserSelectorOpen: false, potentiallyAddedUsers: [] });
    } else {
      this.setState({ isUserSelectorOpen: false, potentiallyAddedUsers: [] });
    }
  };

  onAddPotentiallySelectedUsers = (potentiallyAddedUsers: User[]) => {
    this.setState({ potentiallyAddedUsers });
  };

  onAddUsersPopUpClick = () => {
    this.setState({ hasEpiqOnlyError: false, nonEpiqUsers: [] });
  };

  getUsersAfterExclude = (allUsers: Array<User>, excludeUserList: Array<User>) => {
    return allUsers
      ? allUsers.filter((user: User) => {
        const excludeUser =
          (excludeUserList &&
            excludeUserList.find((preSelectedUser: User) => {
              return preSelectedUser.id === user.id;
            })) ||
          false;

        return !excludeUser;
      })
      : new Array<User>();
  };


  toggleDialogEvent = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ showGroupIdWarningDialog: false });
  }

  async toggleDeleteDialogEvent(event: React.MouseEvent<HTMLElement>) {
    if (event.currentTarget.textContent === "Confirm") {
      const response = await AdminService.DeleteApp(this.state.appDetails);

      if (response.ok) {
        NotificationService.showSuccessToast(`Deleted app: ${this.state.appDetails.name}.`);
        this.props.saveUnsavedChanges(null, false);
        this.props.history.push("/administration/applications");
      } else {
        NotificationService.showErrorToast(`${this.state.appDetails.name} application deletion failed`);
      }
    } else if (event.currentTarget.textContent === "Cancel" || event.currentTarget.textContent === "Ok") {
      this.setState({ openDeletePopup: false, canDelete: false });
    }
  }

  async handleDeleteEvent() {
    const response = await AdminService.canDeleteApp(this.props.match.params.id);

    if (response.ok) {
      this.setState({ openDeletePopup: true, canDelete: response.data });
    } else {
      NotificationService.showErrorToast(
        `Something went wrong. Failed to check the viability to delete ${this.state.appDetails.name} application.`
      );
    }
  }

  comboBoxFilterData = (filter: any) => {
    this.getAllOktaApps(filter && filter.value ? filter.value : "");
  };

  comboBoxFilterChange = (event: any) => {
    this.comboBoxFilterData(event.filter);
  };

  oktaAppSeletionChange = async (event: any) => {
    var selectedOktaApp: ApplicationModel = event.target.value;

    if (selectedOktaApp) {
      const appId = selectedOktaApp.ssoApplicationId;
      this.setState({ isAppDetailsFetching: true });
      selectedOktaApp.idpGroupId = "";
      if (appId) {
        const response = await AdminService.getOktaApplicationGroups(appId);
        if (response.ok) {
          var groups = response.data;
          selectedOktaApp.idpGroupId = groups.length !== 0 && groups[0].id ? groups[0].id : "";
        }
      }
    }

    this.setState({ selectedOktaApp: selectedOktaApp, isAppDetailsFetching: false });
  };

  render() {
    const {
      appDetails,
      mode,
      isLoading,
      hasErrors,
      appInfoEditMode,
      appTypes,
      isSaving,
      showDeactivateConfirmDialog,
      showReactivateConfirmDialog,
      savedUsers,
      updateModel,
      isAppDetailsFetching,
      statusId,
      showGroupIdWarningDialog
    } = this.state;
    const appName = mode === Mode.Edit ? appDetails && appDetails.name : "New Application";
    let appinformation;

    const users = (
      <CollapsibleSection title="Users" count={this.state.appUsersCount}>
        <>
          <GridSelector
            isOpen={this.state.isUserSelectorOpen}
            acceptBtnText="Add"
            cancelBtnText="Cancel"
            titleText="Add Users"
            addAvailable={this.state.potentiallyAddedUsers.length > 0}
            onApply={this.onAddUsersPopUpClick}
            onClose={this.handleAddUsersPopupClose}
            addClass="add-user-group-modal modal-as-sidebar"
          >
            <AddUserToApp
              appName={this.state.updateModel.name}
              onAddUpdateUsers={this.onAddPotentiallySelectedUsers}
              {...this.props}
              selectedAppUsers={this.state.selectedUsers}
            />
          </GridSelector>

          <AppUserList
            users={this.state.selectedUsers}
            addClickHandler={this.handleAddUsers}
            applicationId={this.props.match.params.id}
            selectionChange={this.selectionChange}
            onRemoveUsers={this.onRemoveUsers}
            sessionData={this.props.sessionData}
            isCreate={this.state.mode === Mode.Create}
            applicationName={updateModel && updateModel.name}
            savedUsersCount={this.state.appUsersCount}
            applicationStatus={statusId}
            handleBulkRemoveAllComplete={this.handleBulkRemoveAllComplete}
          />
        </>
      </CollapsibleSection>
    );

    if (isLoading) {
      return (
        <Paper className="app-details-wrapper">
          <div className="no-apps">
            <CircularProgress />
          </div>
        </Paper>
      );
    } else if (hasErrors) {
      appinformation = (
        <Paper className="app-details-wrapper">
          <div className="no-apps">
            <p> Oops! Something went wrong. </p>
            <p>Please check your network connection and refresh the page.</p>
          </div>
        </Paper>
      );
    } else {
      appinformation = (
        <>
          <Grid container>
            <Grid item sm={6} xs={12}>

            </Grid>
            {this.state.mode === Mode.Create &&
              this.props.sessionData.superAdmin && (
                <Grid item sm={12} xs={12} md={6} lg={6} className="select-okta-app-container">
                  <ComboBox
                    placeholder="Select Okta Application"
                    data={this.state.oktaSavedApplications}
                    textField="name"
                    filterable={true}
                    onFilterChange={this.comboBoxFilterChange}
                    onChange={this.oktaAppSeletionChange}
                    className="okta-app-combobox"
                  />
                </Grid>
              )}
            {this.state.mode === Mode.Create &&
              this.props.sessionData.superAdmin && showGroupIdWarningDialog && (
                <div className="warning">
                  <DialogBox
                    title={"IDP GroupId is missing."}
                    content={"Please enter the IDP GroupId."}
                    okButtonLabelText={"Ok"}
                    cancelButtonLabelText={""}
                    visible={showGroupIdWarningDialog == true ? true : false}
                    toggleDialogEvent={this.toggleDialogEvent}
                  /></div>
              )}
            {!this.state.appCreateMode && (
              <>
                <Grid item sm={6} xs={12} className="button-wrapper">
                  {this.props.sessionData.superAdmin && statusId === ApplicationStatus.deactivated && (
                    <span className="dialog-btn">
                      <button className="k-button" onClick={this.handleDeleteEvent.bind(this)}>Delete</button>
                    </span>
                  )}

                  <span className="edit-app-details">
                    {!appInfoEditMode &&
                      this.props.sessionData.permissions.has("EpiqAdminUpdateApplication") && (
                        <CreateIcon className="edit-icon" onClick={this.onAppDetailsEdit} />
                      )}
                    {appInfoEditMode && <CancelIcon className="edit-icon" onClick={this.onCancelEditappInfo} />}
                  </span>
                  <div className="warning">
                    <DialogBox
                      title={this.state.canDelete ? "Confirm deletion" : "Clear application"}
                      content={
                        this.state.canDelete
                          ? `This action deletes the application: '${appName}'.  Confirm that you want to delete this application.`
                          : "Cannot delete the application.Remove all users from the application."
                      }
                      visible={this.state.openDeletePopup}
                      toggleDialogEvent={this.toggleDeleteDialogEvent.bind(this)}
                      okButtonLabelText={this.state.canDelete ? "Cancel" : ""}
                      cancelButtonLabelText={this.state.canDelete ? "Confirm" : "Ok"}
                    />
                  </div>
                </Grid>
              </>
            )}
          </Grid>
          {!appInfoEditMode &&
            appDetails && (
              <CollapsibleSection title="Details">
                <View appDetails={appDetails} />
              </CollapsibleSection>
              //<PanelBar>
              //  <PanelBarItem expanded={false} title="Details">
              //    <View appDetails={appDetails} />
              //  </PanelBarItem>
              //</PanelBar>
            )}
          {isAppDetailsFetching && (
            <Paper className="loader-wrapper">
              <CircularProgress className="loader" />
            </Paper>
          )}
          {appInfoEditMode && (
            <div>
              <Edit
                createApp={mode === Mode.Create}
                appDetails={appDetails}
                appTypes={appTypes}
                isSaving={isSaving}
                updateEditedAppDetails={this.updateEditedAppDetails}
                isAppNameExists={this.state.isAppNameExists}
                isStartUpUrlAndIdpGroupIdExists={this.state.isStartUpUrlAndIdpGroupIdExists}
                isInvalidIdpGroupId={this.state.isInvalidIdpGroupId}
                refreshAppTypeData={this.getAllAppTypes}
                selectedOktaApp={this.state.selectedOktaApp}
              />
            </div>
          )}
        </>
      );
    }

    return (
      <div className="app-info-wrapper">
        <div className="app-info-header-container">
          <div>
            <span className="app-name">{appName}</span>
            {!this.state.appCreateMode && statusId === ApplicationStatus.deactivated && (
              <span className="app-deactivate">
                (Deactivated)
              </span>)
            }
          </div>
          {mode === Mode.Create && <span>*Required Fields</span>}
        </div>
        <Paper className="grid-wrapper papergrid-space">
          {appinformation}
          {users}
        </Paper>
      </div>
    );
  }
}

export default connect(
  (state: IApplicationState) => ({
    ...state.headerState,
    ...state.warningMessageState,
    ...state.sessionState
  }),
  {
    ...HeaderStore.actionCreators,
    ...WarningMessageStore.actionCreators
  }
)(Application as any);
