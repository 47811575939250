import React, { useState, useEffect, useRef, ReactElement } from "react";
import { GridColumnProps, GridCellProps, GridFilterCellProps, GridFilterChangeEvent, GridRowProps } from "@progress/kendo-react-grid";
import AdminService from "../../../services/AdminService";
import NotificationService from "../../../services/NotificationService";
import { IGridParams, IGridStateMerged, AdvancedGrid } from "../../common/grid/AdvancedGrid";
import { Button } from "@progress/kendo-react-buttons";
import CommandCell from "../../common/grid/columns/CommandCell";
import { SecureUploadLocation, Environment } from "../../../models/SecureUploadLocations";
import GridSelector from "../../common/grid/GridSelector";
import "./SecureUploadLocationsList.scss";


export interface IEnvironmentRequest extends IGridParams {
  
}

type Props = {
  additionalEnvironments?: Environment[] | undefined;  
  typeName?: string;
  selectionChange?: (selected: Environment[]) => void;
  addClickHandler?: () => void;
  canOpenPopup?: boolean;
  displayRemoveBtn?: boolean;
  enableRemoveEnvironment?: boolean;
  enableSelectEnvironment?: boolean;
  hideAddButton?: boolean;
  onRemoveUpdateEnvironments?: (selected: Environment) => void;
  permissions: Set<string>;
  disableAddBtn?: boolean;
  isOpen?: boolean;
  locationDetails: SecureUploadLocation;
};

const defaultAppState = { items: [], merged: [], count: 0, selections: {} } as IGridStateMerged<Environment>;

const EnvironmentList = (props: Props) => {
  const [environments, setEnvironments] = useState(defaultAppState);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const filterTimeOut = useRef(null);
  const gridRef = useRef<AdvancedGrid<Environment, IEnvironmentRequest>>(null);

  const [dataState, setDataState] = useState({
   
  } as IEnvironmentRequest);

  const abortController = typeof AbortController === undefined ? new AbortController() : undefined;
  const abortSignal = abortController ? abortController.signal : undefined;
  
 useEffect(() => {
    updateMergedState(environments.items, environments.selections);
  }, [props.additionalEnvironments]);

 useEffect(() => {
    if (props.isOpen === false) {
      const updated = { ... environments }
      updated.selections = {};
      for (let i = 0; i < updated.items.length; i++) {
        updated.items[i].selected = false;
      }
      setEnvironments(updated);
    }
  }, [props.isOpen]);

const updateMergedState = (envs: Environment[], preSelected: Record<number | string, Environment>) => {

    if (!envs) {
      envs = [];
    }

    for (let i = 0; i < envs.length; i++) {
      if (preSelected[envs[i].id]) {
        envs[i].selected = true;
      }
    }

    if (props.additionalEnvironments && props.additionalEnvironments.length) {
      const merged = SecureUploadLocation.dedupeEnvs(envs, props.additionalEnvironments);
      setEnvironments({items: envs, merged, count: merged.length, selections: preSelected});
    } else {
      setEnvironments({items: envs, merged: envs, count: envs.length, selections: preSelected});
    }
  }

  const loadApps = async (dataState: IEnvironmentRequest) => {
    setIsLoading(true);
    try {
      const request = { ...dataState };
      request.filters = [];          
      let result;

      if (props.enableSelectEnvironment) {
        result = await AdminService.getEnvironments(abortSignal);
      }
      else {
         result = await AdminService.getLocationEnvironmentListById(props.locationDetails.id);
      }
      
      if (result.ok) {
        updateMergedState(result.data, request.filter ? environments.selections : {});
        setHasError(false);
      }
      else {
        setEnvironments(defaultAppState);
        NotificationService.showErrorToast("Something went wrong while getting environments.");
        setHasError(true);
      }

    } catch (e) {
      setEnvironments(defaultAppState);
      NotificationService.showErrorToast("Something went wrong while getting environments.");
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const selectRow = (dataItem: Environment) => {

    const changes = { ...environments };

    for (let i = 0; i < changes.items.length; i++) {
      if (dataItem.id === changes.items[i].id) {
        changes.items[i].selected = !changes.items[i].selected;
        if (!changes.items[i].selected) {
          delete changes.selections[changes.items[i].id];
        } else {
          changes.selections[changes.items[i].id] = changes.items[i];
        }
      }
    }

    setEnvironments(changes);

    props.selectionChange(Object.keys(changes.selections).map(e => changes.selections[e]));
  };

  const removeEnvironment = (dataItem: any) => {

    let envs = [...environments.items];

    for (let i = 0; i < envs.length; i++) {
      if (envs[i].id === dataItem.id) {
        envs.splice(i, 1);
        break;
      }
    }

    updateMergedState(envs, environments.selections);

    props.onRemoveUpdateEnvironments(dataItem);
  };

  const gridFilterChange = (event: GridFilterChangeEvent) => {
    const newState = { ...dataState, filter: event.filter };
    setDataState(newState);
    if (filterTimeOut.current) {
      clearTimeout(filterTimeOut.current);
    }
    filterTimeOut.current = setTimeout(() => {
      gridRef.current.resetGridState(newState, false, true);
    }, 500);
  };

  const columns = new Array<GridColumnProps>(
    { field: "environmentLocation", title: "ENVIRONMENT LOCATION", sortable: false, headerClassName: "no-sort", width: "200px" }
  );

  if (props.enableSelectEnvironment) {
    columns.unshift({
      title: "",
      cell: CommandCell({
        onSelection: selectRow,
        selectedField: "selected"
      }),
      sortable: false,
      headerClassName: "no-sort"
    });
  }

  if (props.enableRemoveEnvironment && props.permissions.has("EpiqAdminUpdateSecureUploadLocations") && !props.disableAddBtn) {
    columns.push({
      title: "",
      cell: CommandCell({ onRemove: removeEnvironment }),
      sortable: false,
      headerClassName: "no-sort"
    });
  }

  const gridToolBarRender = (): JSX.Element => {
    return (
      props.permissions.has("EpiqAdminUpdateSecureUploadLocations") && (
        <Button
          className="add-user-btn"
          icon={"plus"}
          primary={true}
          onClick={props.addClickHandler}
          disabled={props.disableAddBtn}
        >
          Environments
        </Button>
      )
    );
  };  

  return (
    <div className="applications-grid-wrapper">
      <AdvancedGrid
        ref={(advancedGridInstance: AdvancedGrid<Environment, IEnvironmentRequest>) => {
          gridRef.current = advancedGridInstance;
        }}
        showErrorState={hasError}
        showLoadingIndicator={isLoading}
        data={environments.merged}
        dataFetch={loadApps}
        dataState={dataState}
        columns={columns}
        paging={false}
        totalRecords={{ value: environments.count, label: "Environments" }}
        noRecordsRender={<p>No environments added.</p>}
        noLoadOnMount={false}
        filteredDataOnly={false}
        gridToolbarContent={!props.hideAddButton && gridToolBarRender()}
        selectedField="selected"
        filterOperators={{
          text: [{ text: "grid.filterContainsOperator", operator: "contains" }]
        }}
        filter={dataState.filter}
        onFilterChange={gridFilterChange}
      />
    </div>
  );
}


export default EnvironmentList;