import * as React from "react";
import ReactDOM from "react-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./DataLoader.scss";

export interface IDataLoaderProps<T> {
  getDataAsync: () => Promise<void>;
  loading: boolean;
  dataState: T;
  dataContainerId?: string;
  dataContainerClassesForScope?: string;
  strongArmRefreshCount?: number;
}

type Props<T> = IDataLoaderProps<T>;

export class DataLoader<T> extends React.Component<Props<T>> {

  private strongArmRefreshCount: number;

  constructor(props: Props<T>) {
    super(props);

    this.strongArmRefreshCount = this.props.strongArmRefreshCount;
    this.state = this.props.dataState;
  }

  componentDidMount() {
    this.requestData(true);
  }

  requestData(initialLoad = false) {
    if (!initialLoad &&
      this.strongArmRefreshCount === this.props.strongArmRefreshCount &&
      (this.props.loading || JSON.stringify(this.state) === JSON.stringify(this.props.dataState))) {

      return;
    }

    this.strongArmRefreshCount = this.props.strongArmRefreshCount;

    this.setState({ ...this.state, ...this.props.dataState });
    this.props.getDataAsync();
  }

  loadingPanelRender() {
    const loadingPanel = (
      <div className="k-loading-mask loading-container">
        <div className="loading-icon-container">
          <CircularProgress />
        </div>
        <div className="masking-container"></div>
      </div>
    );
    const scopeSelector =
      this.props.dataContainerId && this.props.dataContainerClassesForScope
        ? `#${this.props.dataContainerId} ${this.props.dataContainerClassesForScope}`
        : this.props.dataContainerId
        ? `#${this.props.dataContainerId}`
        : this.props.dataContainerClassesForScope
        ? `${this.props.dataContainerClassesForScope}`
        : "body";
    const containerContent = document && document.querySelector(scopeSelector);

    return containerContent ? ReactDOM.createPortal(loadingPanel, containerContent) : loadingPanel;
  }

  render() {
    this.requestData();

    return this.props.loading && this.loadingPanelRender();
  }
}
