import * as React from "react";
import { Grid, Button } from "@material-ui/core";
import { Checkbox } from "@progress/kendo-react-inputs";
import CreateIcon from "@material-ui/icons/Create";
import { UserGroup } from "../../../../models/UserModels";
import { ShowEpiqOnlyControls } from "../../../../models/Enums";
import { TextInput } from "../../../common/TextInput";
import CommonHelper from "../../../common/utilities/CommonHelper";
import CancelIcon from "@material-ui/icons/Cancel";
import { formatDate } from "@telerik/kendo-intl";
import "../../../../settings/kendo-drop-downs.scss";
import "./UserGroups.scss";
import ErrorIcon from "@material-ui/icons/Error";
import AdminService from "../../../../services/AdminService";
import DialogBox from "../../../common/DialogBox";
import NotificationService from "../../../../services/NotificationService";
import DropDownListItem from "../../../../models/DropDownListItem";
import { MultiSelect, MultiSelectChangeEvent, MultiSelectFilterChangeEvent, ListItemProps } from "@progress/kendo-react-dropdowns";
import { FilterDescriptor, SortDescriptor, CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { EmployerDomain } from "../../../../models/EmployerModel";
import SearchIcon from "@material-ui/icons/Search";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";

enum PlaceHolders {
  groupName = "(e.g. Review Manager)"
}

enum ErrorMessages {
  GroupExists = "User group already exists.",
  Required = "This field is required.",
  EpiqAdminOnlyError = "Cannot set Epiq Admin Only flag when group contains external users.",
  NotAllowdDomainUserError = "Group contains users with email domains that are not allowed.\n Adjust the allowed domains or remove the affected users before saving."
}

interface IElementToValidate {
  name: string;
  isValid: boolean;
}

interface IProps {
  savedUserGroupDetails: UserGroup;
  permissions: Set<string>;
  showEpiqOnlyAdmin: ShowEpiqOnlyControls;
  updateUserGroupDetails: (userGroup: UserGroup, isValid?: boolean) => void;
  resetViewMode: (isUserGroupInfoInEditMode: boolean) => void;
  createUserGroup: boolean;
  isGroupExist: boolean;
  hasEpiqOnlyError: boolean;
  isUpdateSaved: boolean;
  history: any;
  saveUnsavedChanges: any;
  addSelectedListItems: (selectedItems: DropDownListItem[]) => void;
  preselectedDomains?: DropDownListItem[];
  hasDomainUserError?: boolean;
}

type Props = IProps;

type State = {
  userGroupInEdit?: UserGroup;
  elementsToValidate: Array<IElementToValidate>;
  isEpiqOnly: boolean;
  isEditMode: boolean;
  canDelete: boolean;
  openDeleteConfirmPopup: boolean;
  domains: DropDownListItem[];
};

class SaveUserGroup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isEditMode: props.createUserGroup,
      userGroupInEdit: props.createUserGroup ? new UserGroup() : CommonHelper.cloneObject(props.savedUserGroupDetails),
      isEpiqOnly: false,
      elementsToValidate: new Array<IElementToValidate>({ name: "groupName", isValid: !props.createUserGroup }),
      canDelete: false,
      openDeleteConfirmPopup: false,
      domains: [],
    };
  }

  onGroupInfoChange = (value: string, name: string, isValid: boolean) => {
    let editedUserGroup: any = this.state.userGroupInEdit;
    editedUserGroup[name] = value;

    this.setElementValidationState({ name, isValid }, () => {
      this.setState({ userGroupInEdit: editedUserGroup });

      if (this.props.updateUserGroupDetails) {
        this.props.updateUserGroupDetails(editedUserGroup, isValid);
      }
    });
  };

  private setElementValidationState(newValidationState: IElementToValidate, callBack: () => void) {
    const indexToUpdate = this.state.elementsToValidate.findIndex(element => element.name === newValidationState.name);
    const elementsToValidate = [...this.state.elementsToValidate];

    if (indexToUpdate === -1) {
      callBack();
    } else {
      elementsToValidate.splice(indexToUpdate, 1);
      elementsToValidate.splice(indexToUpdate, 0, newValidationState);

      this.setState({ ...this.state, elementsToValidate }, callBack);
    }
  }

  onCheckBoxChange = (isChecked: boolean) => {
    let editedUserGroup: UserGroup = this.state.userGroupInEdit;
    editedUserGroup["epiqOnly"] = isChecked;

    this.setState({ userGroupInEdit: editedUserGroup, isEpiqOnly: isChecked },
      () => {
        if (this.props.updateUserGroupDetails) {
          this.props.updateUserGroupDetails(editedUserGroup, true);
        }
      });

  };

  onCancelEditUserGroupInfo = () => {
    this.setState({
      isEditMode: false,
      userGroupInEdit: CommonHelper.cloneObject(this.props.savedUserGroupDetails)
    },
      () => {
        if (this.props.updateUserGroupDetails) {
          this.props.updateUserGroupDetails(this.props.savedUserGroupDetails, true);
        }
      });
  };

  handleClickOpen = () => {
    this.setState({ isEditMode: true, userGroupInEdit: CommonHelper.cloneObject(this.props.savedUserGroupDetails) },
      () => { this.props.resetViewMode(false); }
    );
  };

  handleClickDelete = async () => {
    const response = await AdminService.canDeleteUserGroup(this.props.savedUserGroupDetails.groupId);
    if (response.ok) {
      this.setState({
        canDelete: response.data,
        openDeleteConfirmPopup: true,
      });
    }
  };

  async toggleDialogEvent(event: React.MouseEvent<HTMLElement>) {
    if (event.currentTarget.textContent.toLocaleLowerCase() === "confirm") {
      this.setState({ openDeleteConfirmPopup: false, canDelete: false });
      this.props.savedUserGroupDetails.isDeleted = true;
      const result = await AdminService.deleteOrRestoreUserGroup(this.props.savedUserGroupDetails);
      if (result.ok) {
        if (result.data.inValid === true) {
          NotificationService.showErrorToast(`${this.props.savedUserGroupDetails.groupName} user group deletion failed`);
        }
        else {
          NotificationService.showSuccessToast(`Deleted user group: ${this.props.savedUserGroupDetails.groupName}.`);
          this.props.saveUnsavedChanges(null, false);
          this.props.history.push("/administration/userGroups");
        }
      }
    }
    else if (event.currentTarget.textContent.toLocaleLowerCase() === "cancel" || event.currentTarget.textContent.toLocaleLowerCase() === "ok") {
      this.setState({ openDeleteConfirmPopup: false, canDelete: false });
    }
  }

  getDomains = async (searchText?: string) => {
    const filters = [
      {
        logic: "or",
        filters: [{ field: "Domain", operator: "contains", value: searchText == null ? "" : searchText }] as Array<FilterDescriptor>
      }
    ] as CompositeFilterDescriptor[];

    const response = await AdminService.getUserGroupDomainsList({ filters: filters, sort: [{ field: "Domain", dir: "asc" }] as SortDescriptor[] });
    if (response.ok) {
      const domains = response.data && response.data.results.map((ed: EmployerDomain) => {
        return {
          id: ed.id,
          name: ed.domain,
          restProps: {}
        };
      })

      this.setState({ ...this.state, domains: domains });
    }
    else {
      NotificationService.showErrorToast("Fetching domains list from db failed");
    }
  };

  handleChange = (event: MultiSelectChangeEvent) => {
    const values: DropDownListItem[] = event.target.value;
    if (this.props.addSelectedListItems) {
      this.props.addSelectedListItems(values)
    }
  };

  itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = (
      <span className="multiselect-items ">
        {li.props.children}
      </span>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  filterChange = (event: MultiSelectFilterChangeEvent) => {
    let searchValue = event.filter.value;
    if (!CommonHelper.isNullOrWhiteSpace(searchValue) && searchValue.length >= 3) {
      this.setState({ domains: [] });
      this.getDomains(searchValue);
    }
  };


  render() {
    const { isGroupExist, isUpdateSaved, showEpiqOnlyAdmin, createUserGroup, hasEpiqOnlyError, permissions, savedUserGroupDetails, hasDomainUserError } = this.props;
    const { isEditMode, userGroupInEdit, domains } = this.state;
    const commentLength = userGroupInEdit.comments ? userGroupInEdit.comments.length : 0;
    const createdDate = userGroupInEdit.createdDate ? formatDate(CommonHelper.convertUTCDateToLocalDate(userGroupInEdit.createdDate), "g") : "";
    const isuserGroupDetailsInEditMode = isUpdateSaved ? !isUpdateSaved : isEditMode;
    const groupName = savedUserGroupDetails.groupId > 0 ? savedUserGroupDetails.groupName : "Create User Group";

    const editActionButton = (<div className="button-wrapper">
      {!createUserGroup && (
        <>
          <div className="edit-usergroup">
            {isuserGroupDetailsInEditMode && (
              <CancelIcon className="edit-icon" onClick={this.onCancelEditUserGroupInfo} />
            )}

            {!isuserGroupDetailsInEditMode && permissions.has("EpiqAdminUpdateUserGroup") &&
              <>
                <div><span className="user-group-edit-icon fa-solid fa-pen-to-square" onClick={this.handleClickOpen}></span></div>
              </>
            }
          </div>
        </>
      )}
    </div>);

    const deleteActionButton = (
      <div className="button-wrapper" >
        {!createUserGroup && (
          <>
            <span className="dialog-btn">
              {(showEpiqOnlyAdmin === ShowEpiqOnlyControls.ENABLE) && (
                <Button variant="contained" onClick={this.handleClickDelete.bind(this)} className="btn-contined">
                  Delete
                </Button>
              )}
            </span>
          </>
        )
        }
      </div>
    );

    const saveUserGroup = (<fieldset className="create-user-group-form">
      <div className="field-hint">* Required Fields</div>
      <Grid container className="user-create">
        <Grid item sm={6} md={6}>
          <div className="chk-epiq-admin">
            {(showEpiqOnlyAdmin == ShowEpiqOnlyControls.ENABLE ||
              showEpiqOnlyAdmin == ShowEpiqOnlyControls.SHOW) && <Checkbox
                defaultChecked={userGroupInEdit.epiqOnly ? true : false}
                onChange={(event) => this.onCheckBoxChange(event.value)}
                name="epiqOnly"
                label="Epiq Admin Only"
                disabled={!createUserGroup || showEpiqOnlyAdmin === ShowEpiqOnlyControls.SHOW}
              />
            }
            {hasEpiqOnlyError && (
              <div className="error-message">
                <div className="error-info">
                  <span>
                    <ErrorIcon />
                  </span>
                  <span className="error-msg">{ErrorMessages.EpiqAdminOnlyError}</span>
                </div>
              </div>
            )}
          </div>
          <div className="text-input-has-error">
            <label>* User Group Name</label>
            <TextInput
              type="text"
              name="groupName"
              legacyMode={true}
              defaultValue={userGroupInEdit.groupName}
              placeholder={PlaceHolders.groupName}
              maxLength={250}
              validations={[
                { name: "required", errorMessage: ErrorMessages.Required },
                {
                  name: "CustomValidation",
                  errorMessage: `${ErrorMessages.GroupExists}`,
                  predicate: `${isGroupExist ? true : false}`
                }
              ]}
              onChange={this.onGroupInfoChange}
              displayCustomValidationMessage={isGroupExist ? true : false}
            />
          </div>
          <div className="form-input user-group-domain">
            <label className="domain-label">Allowed Domains</label><br />
            <label className="domain-label-desc">To ensure proper access, allow only users with these email domains.</label>
            <div className="user-group-multiselect-wrapper">
              <span className="search-wrapper">
                <SearchIcon />
              </span>
              <MultiSelect
                className="multiselect-domain"
                name="domains"
                data={domains}
                onOpen={async (e) => this.getDomains()}
                defaultValue={this.props.preselectedDomains}
                itemRender={this.itemRender}
                filterable={true}
                onFilterChange={e => this.filterChange(e)}
                textField="name"
                dataItemKey="id"
                onChange={this.handleChange}
                placeholder="(Optional)"
              />
            </div>
            {hasDomainUserError && (
              <div className="error-message">
                <div className="error-info">
                  <span>
                    <ErrorIcon />
                  </span>
                  <span className="error-msg">{ErrorMessages.NotAllowdDomainUserError}</span>
                </div>
              </div>
            )}
          </div>
        </Grid>
        <Grid item sm={6} md={6} className="text-area-user-group">
          <label>Description</label>
          <TextInput
            type="textarea"
            name="comments"
            legacyMode={true}
            defaultValue={userGroupInEdit.comments}
            onChange={this.onGroupInfoChange}
            notesLength={commentLength}
            maxLength={255}
            placeholder="Enter a short description."
          />
        </Grid>
        <Grid>
          {deleteActionButton}
        </Grid>
      </Grid>
    </fieldset>)
    const dialog = (
      <div className="warning">
        <DialogBox
          title={this.state.canDelete ? "Confirm deletion" : "Clear user group"}
          content={this.state.canDelete ? `This action deletes user group: '${this.props.savedUserGroupDetails.groupName}'. Confirm that you want to delete this user group.` : "Cannot delete an active user group. Remove all users and assignments prior to deletion."}
          okButtonLabelText={this.state.canDelete ? "Cancel" : ""}
          cancelButtonLabelText={this.state.canDelete ? "Confirm" : "Ok"}
          visible={this.state.openDeleteConfirmPopup}
          toggleDialogEvent={this.toggleDialogEvent.bind(this)}
        /></div>);
    return (
      <>
        <Grid container>
          <Grid item sm={6} md={6} >
            <div className="user-group-name">
              {groupName}
            </div>
          </Grid>
          <Grid item sm={6} md={6}>
            {editActionButton}
          </Grid>
        </Grid>
        <div className="user-info-wrapper">
          <div className="pnl-user-group-details">
            <PanelBar >
              <PanelBarItem expanded={true} title="Details" className="pnl-item-user-group-details">
                <div className="grid-wrapper user-grid-wrapper papergrid-space">
                  {isuserGroupDetailsInEditMode && saveUserGroup}
                  {!isuserGroupDetailsInEditMode && <div className="user-info">
                    <Grid container>
                      <Grid item sm={4} xs={12}>
                        <div className="user-info-row">
                          <strong>Created: </strong> {createdDate}
                        </div>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <div className="user-info-row">
                          <strong>Epiq Admin Only: </strong> {userGroupInEdit.epiqOnly ? "True" : "False"}
                        </div>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <div className="user-info-row">
                          <strong> Description: </strong> {userGroupInEdit.comments}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item sm={4} xs={12}>
                        <div className="user-info-row">
                          <strong>Allowed Domains: </strong>
                          {this.props.preselectedDomains.length > 0 && (
                            <>
                              {this.props.preselectedDomains.map(v => v.name).join(", ")}
                            </>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  }
                </div>
              </PanelBarItem>
            </PanelBar>
          </div>
        </div>
        {dialog}
      </>
    );
  }
}
export default SaveUserGroup;
