import AuthenticationService from "./services/AuthenticationService";
import AuthenticationStorageApi from "./apis/storage/AuthenticationStorageApi";
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { User } from "./models/UserModels";
import AdminService from "./services/AdminService";

declare global {
  interface Window {
    UserProfileData: User;
  }
}

const AppInsights = new ApplicationInsights({
    config: {
    instrumentationKey: window.ApplicationVariables.appInsightsInstrumentationKey,
    enableAutoRouteTracking: true
    }
});

class AppInsightsHelper<T> {
  static async trackCustomEvent<T>(customEventName: string, customProperties?: T, isUserLoggedIn: boolean = true) {
    try {
      const metaData = await this.getMetadata(isUserLoggedIn);
      const telemetryData = { telemetryType: "CustomEvent", telemetryName: customEventName };
      AdminService.TrackAppInsights(JSON.stringify({ ...metaData, ...customProperties, ...telemetryData}));
    }
    catch (ex) {
      console.log("Exception occurred while sending custom event and its metadata to app insights.", ex);
    }    
  }

  static async getMetadata(isUserLoggedIn: boolean = true) {
    if (!window.UserProfileData && isUserLoggedIn) {
      const response = await AuthenticationService.getSessionData();
      if (response.ok) {
        window.UserProfileData = response.data;
        window.UserProfileData.idpId = await AuthenticationStorageApi.getUserId();
      }
    }

    const data = window.UserProfileData;
    return data ? { username: data.username, userType: data.superAdmin ? "Global Admin" : "User", userId: data.idpId, firstName: data.firstName, lastName: data.lastName } : {};
  }

  static async addTelemetryInitializer(isUserLoggedIn: boolean = true) {
   
    const metaData = await this.getMetadata(isUserLoggedIn);

    let pageName = window.location.pathname;

    if (pageName.indexOf("servicerequestlist") > -1) {
      pageName = "Technical Support page";
    } else if (pageName.indexOf("dashboards") > -1 && pageName.indexOf("dashboards/") === -1) {
      pageName = "Reports Dashboard page";
    } else if (pageName.indexOf("filelist") > -1) {
      pageName = "Shared Files page";
    } else if (pageName.indexOf("applications") > -1 && pageName.indexOf("administration/applications") === -1) {
      pageName = "Applications page";
    } else {
      pageName = window.location.pathname;
    }

    const telemetryData = { telemetryType: "PageView", telemetryName: pageName };

    AdminService.TrackAppInsights(JSON.stringify({ ...metaData, ...telemetryData}));
  }

  static clearAuthenticatedUserContext() {
    window.UserProfileData = null;
    if (typeof (Storage) !== "undefined") {
      sessionStorage.removeItem("isUserLoggedInEventCaptured");
    }
  } 
}

export { AppInsights, AppInsightsHelper };