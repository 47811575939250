import React, { useState, useEffect } from "react";
import ResourceService from "../../../services/ResourceService";
import AdminService from "../../../services/AdminService";
import ResourceUserGroupRole from "../../../models/ResourceModels";
import { connect } from "react-redux";
import { IApplicationState } from "../../../store";
import * as HeaderStore from "../../../store/Header";
import CommonHelper from "../../common/utilities/CommonHelper";
import { formatDate } from "@telerik/kendo-intl";
import Project from "../../../models/Project";
import { AdvancedGrid, IGridParams } from "../../common/grid/AdvancedGrid";
import { GridColumnProps, GridFilterCellProps, GridCellProps } from "@progress/kendo-react-grid";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./ProjectDetails.scss";
import GridRefreshButton from "../../common/GridRefreshButton";


interface IRefreshGridParams extends IGridParams {
  refreshToggle: boolean;
}

interface IProps {
  setHeaderButtons: any;
  match?: any;
  history: any;
}

type Props = IProps & HeaderStore.IHeaderState &
  typeof HeaderStore.actionCreators;

const GridConfigurations = {
  PageSize: 100,
  OrderBy: "userGroupName",
  Ascending: "asc",
  Descending: "desc"
};

const gridParams: IRefreshGridParams = {
  skip: 0,
  take: GridConfigurations.PageSize,
  sort: [{ field: GridConfigurations.OrderBy, dir: "asc" }],
  refreshToggle: false
};

const ProjectDetails = (props: Props) => {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [projectDetails, setprojectDetails] = useState(new Project());
  const [isGridLoading, setIsGridLoading] = useState(false);
  const [dataState, setDataState] = useState(gridParams);
  const [count, setCount] = useState(0);
  const [fetchResults, setFetchResults] = useState<Array<ResourceUserGroupRole>>(new Array<ResourceUserGroupRole>());

  const formattedprojectstartdate = (projectstartdate?: Date) => {
    let date = "-";
    let startdate = projectstartdate;

    if (startdate) {
      let localdatetime = CommonHelper.convertUTCDateToLocalDate(startdate);
      date = formatDate(localdatetime, { date: "short" });
    }

    return date;
  };

  const getProjectDetailsById = async () => {
    setIsLoading(true);
    let projectId: number = Number(props.match.params.projectId);
    const result = await AdminService.getProjectDetails(projectId);

    if (result.ok) {
      setIsLoading(false);
      setprojectDetails(result.data);
    }
  };

  const fetchDataAsync = async (dataState: IRefreshGridParams) => {
    let projectId: number = Number(props.match.params.projectId);
    let resourceType = 3;
    setIsGridLoading(true);
    const fetchResult = await ResourceService.getResourceUserGroupRoles(projectId, resourceType, dataState);

    if (fetchResult.ok) {
      setIsGridLoading(false);
      setFetchResults(fetchResult.data.results);
      setCount(fetchResult.data.count);
    } else {
      console.log("Could not load grid.");
      setIsGridLoading(false);
      setHasError(true);
    }

    setDataState(dataState);
  };

  useEffect(() => {
    getProjectDetailsById();
    props.setHeaderButtons([], "", "/administration/projects", "Back to project list");
    return () => {
      props.setHeaderButtons();
    }
  }, []);  
    
  const descriptionCustomCell = (props: any) => {
    return <td className="description"> {props.dataItem.userGroupComments ? props.dataItem.userGroupComments : "-"} </td>;
  };

  const columns = new Array<GridColumnProps>(
    { field: "userGroupName", title: "USER GROUP", filterable: true },
    { field: "userGroupComments", title: "DESCRIPTION", cell:descriptionCustomCell, filterable: true },
    { field: "roleName", title: "ROLE", filterable: true },
    { field: "resourceGroupName", title: "RESOURCE GROUP", filterable: true }
  );

  const reloadData = () => {
    const newState: IRefreshGridParams = { ...dataState, refreshToggle: !dataState.refreshToggle };
    myGrid.resetGridState(newState);
    setDataState(newState);
  };

  let myGrid: AdvancedGrid<ResourceUserGroupRole, IRefreshGridParams>;

  const gridToolBarRender = () => {
    return (
      <GridRefreshButton onRefreshGridClick={reloadData}/>   
    );
  };

  let projectDetailsInfo;
  if (isLoading) {
    projectDetailsInfo = (
      <Paper className="user-grid-wrapper">
        <div className="no-users">
          <CircularProgress />
        </div>
      </Paper>
    );
  } else {
    projectDetailsInfo = (
      <div>
        <div className="grid-wrapper user-grid-wrapper papergrid-space">
          <h1>{projectDetails.projectName}</h1>
          <div className="project-detail-view">
            <div className="project-details">
              <div className="project-details-row">
                <strong> Status</strong> {projectDetails.projectStatus}
              </div>
              <div className="project-details-row">
                <strong> Start Date</strong> {formattedprojectstartdate(projectDetails.projectStartDate)}
              </div>
            </div>
            <div className="project-details">
              <div className="project-details-row">
                <strong> Project #</strong>
              </div>
              <div>{projectDetails.projectNumber}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Paper className="project-details-wrapper no-separator">
      {projectDetailsInfo}
      <div>
        <AdvancedGrid
          ref={(standardGridInstance: AdvancedGrid<ResourceUserGroupRole, IRefreshGridParams>) => {
            myGrid = standardGridInstance;
          }}
          showErrorState={hasError}
          showLoadingIndicator={isGridLoading}
          data={fetchResults}
          dataFetch={fetchDataAsync}
          dataState={dataState}
          columns={columns}
          paging={false}
          noRecordsRender={<p>No user groups and roles assigned to this.</p>}
          noLoadOnMount={false}
          filteredDataOnly={false}
          totalRecords={{ value: count, label: "User Groups and Roles" }}
          multiFieldFilterDelimiter="|"
          gridToolbarContent={gridToolBarRender()}
          />
      </div>
    </Paper>
  );
};

export default connect((state: IApplicationState) => ({ ...state.headerState }), {
  ...HeaderStore.actionCreators
})(ProjectDetails as any);
