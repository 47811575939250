import React, { useEffect, useState } from "react";
import { Field, FieldRenderProps } from "@progress/kendo-react-form";
import {
  ListBox,
  ListBoxToolbar,
  processListBoxData,
  processListBoxDragAndDrop,
  ListBoxToolbarClickEvent,
  ListBoxDragEvent,
  ListBoxItemClickEvent,
} from "@progress/kendo-react-listbox";
import "./ListBoxField.scss";
import { updateValForField } from "./WizardElements";
import CircularProgress from "@material-ui/core/CircularProgress";

const SELECTED_FIELD = "selected";

interface ISelectField {
  [SELECTED_FIELD]: boolean;
  field: string;
  id: string;
}

const ListBoxField = function (fieldRenderProps: FieldRenderProps) {
  const [isLoading, setLoading] = useState(false);
  const [fieldSelections, setFieldSelections] = useState({
    availableFields: [],
    columns: [],
    draggedItem: {}
  } as any);


  useEffect(() => {
    getItems();
  }, []);

  const getItems = async () => {
    setLoading(true);
    const result = await fieldRenderProps.fieldData.GetData(fieldRenderProps.fieldData.GetDataParams());
    const valInfo = fieldRenderProps.valInfo;
    const showPreselectedItems = fieldRenderProps.fieldData.showPreselectedItems(fieldRenderProps.fieldValues);
    console.log(showPreselectedItems);
    if (result.ok) {
      setLoading(false);
      let availableItems;
      let preselectedItems;

      if (fieldRenderProps.fieldData.WizardType === "ClientSetup") {
        if (valInfo && valInfo.value != null) {
          preselectedItems = result.data.results.filter((el: any) => {
            return valInfo.value.find((id: number) => {
              return id === el.id
            });
          });

          availableItems = result.data.results.filter((el: any) => {
            return !valInfo.value.find((id: number) => {
              return id === el.id
            });
          });

          availableItems = fieldRenderProps.fieldData.DataConverter(availableItems);
          preselectedItems = fieldRenderProps.fieldData.DataConverter(preselectedItems);
        }
        else {
          availableItems = fieldRenderProps.fieldData.DataConverter(result.data.results.filter((res: any) => res["isDefault"] === false));
          preselectedItems = showPreselectedItems ? fieldRenderProps.fieldData.DataConverter(result.data.results.filter((res: any) => res["isDefault"] === true)) : [];
        }

        setFieldSelections({
          availableFields: availableItems,
          columns: preselectedItems,
          draggedItem: {},
        });

        updateValForField(
          preselectedItems ? preselectedItems.map((v: any) => v.id) : null,
          fieldRenderProps,
          preselectedItems ? preselectedItems.map((v: any) => v.field).join(";") : null,
        );

      }
      else {
        setFieldSelections({
          availableFields: fieldRenderProps.fieldData.DataConverter((result.data.results || result.data)),
          columns: [],
          draggedItem: {}
        });
      }
    }
    else {
      setLoading(false);
    }
  }

  const handleToolBarClick = (e: ListBoxToolbarClickEvent) => {
    let toolName: string = e.toolName || "";
    let result: any = processListBoxData(
      fieldSelections.availableFields,
      fieldSelections.columns,
      toolName,
      SELECTED_FIELD
    );

    const changedFieldSelections = {
      ...fieldSelections,
      availableFields: result.listBoxOneData,
      columns: result.listBoxTwoData,
    };

    setFieldSelections(changedFieldSelections);

    updateValForField(
      result.listBoxTwoData ? result.listBoxTwoData.map((v: any) => v.id) : null,
      fieldRenderProps,
      result.listBoxTwoData ? result.listBoxTwoData.map((v: any) => v.field).join(";") : null
    );
  };

  const handleDragStart = (e: ListBoxDragEvent) => {
    setFieldSelections({
      ...fieldSelections,
      draggedItem: e.dataItem
    });
  };

  const handleDrop = (e: ListBoxDragEvent) => {
    let result: any = processListBoxDragAndDrop(
      fieldSelections.availableFields,
      fieldSelections.columns,
      fieldSelections.draggedItem,
      e.dataItem,
      "id"
    );

    setFieldSelections({
      ...fieldSelections,
      availableFields: result.listBoxOneData,
      columns: result.listBoxTwoData,
    });

    updateValForField(
      result.listBoxTwoData ? result.listBoxTwoData.map((v: any) => v.id) : null,
      fieldRenderProps,
      result.listBoxTwoData ? result.listBoxTwoData.map((v: any) => v.field).join(";") : null
    );

  };

  const handleItemClick = (event: ListBoxItemClickEvent, data: string, connectedData: string) => {
    setFieldSelections({
      ...fieldSelections,
      [data]: fieldSelections[data].map((item: ISelectField) => {
        if (item.id === event.dataItem.id) {
          item[SELECTED_FIELD] = !item[SELECTED_FIELD];
        } else if (!event.nativeEvent.ctrlKey) {
          item[SELECTED_FIELD] = false;
        }
        return item;
      }),
      [connectedData]: fieldSelections[connectedData].map((item: ISelectField) => {
        item[SELECTED_FIELD] = false;
        return item;
      })
    });
  };

  return (
    <div>
      {!isLoading && (
      <div className="row justify-content-center">
        <div className="col k-pr-2">
          <h4 className="listboxHeader">Available Reports</h4>
          <ListBox
            style={{ height: 200, width: '25em' }}
            data={fieldSelections.availableFields}
            textField="field"
            valueField="id"
            selectedField={SELECTED_FIELD}
            onItemClick={(e: ListBoxItemClickEvent) => handleItemClick(e, "availableFields", "columns")}
            onDragStart={handleDragStart}
            onDrop={handleDrop}
            toolbar={() => {
              return (
                <ListBoxToolbar
                  tools={['transferTo', 'transferFrom', 'transferAllTo', 'transferAllFrom']}
                  data={fieldSelections.availableFields}
                  dataConnected={fieldSelections.columns}
                  onToolClick={handleToolBarClick}
                />
              );
            }}
          />
        </div>
        <div className="col k-pl-0">
          <h4 className="listboxHeader">Selected Reports</h4>
          <ListBox
            style={{ height: 200, width: '20em' }}
            data={fieldSelections.columns}
            textField="field"
            valueField="id"
            selectedField={SELECTED_FIELD}
            onItemClick={(e: ListBoxItemClickEvent) => handleItemClick(e, "columns", "availableFields")}
            onDragStart={handleDragStart}
            onDrop={handleDrop}
          />
        </div>
        </div>
      )}
      {isLoading && (
        <div className="align-center">
          <CircularProgress />
        </div>)
      }
    </div>)
}
export default ListBoxField;