import React from "react";
import { IInitializeJobRequestData, IInitializeJobResponseData } from "../../../services/JobService";
import { StandardFileUpload } from "../../common/fileupload/StandardFileUpload";
import FileValidation from "./FileValidation";
import { UploadFileRestrictions, UploadFileInfo } from "@progress/kendo-react-upload";
import { JobTypeEnum } from "../../../models/Enums";

export enum ValidationStatus {
  Validating = 0,
  Valid = 1,
  InValidFileType = 2,
  InfectedFile = 3,
  Error = 4
}

interface IFileUploadInitializeJobRequestData extends IInitializeJobRequestData {
  resourceGroupId?: number;
}

interface IUploadInitializeJobResponseData extends IInitializeJobResponseData {
  uploadUrl: string;
}

export interface IFileInfo extends IUploadInitializeJobResponseData {
  file: UploadFileInfo;
  url?: string;
  validationStatus?: ValidationStatus;
}

type Props = {
  onValidationComplete: (invalidFiles: Array<IFileInfo>) => void;
  onFileUploaded?: (file: IFileInfo) => void;
  onFilesReadyForValidation: () => void;
  resourceGroupId?: number;
  allowedExtensions?: Array<string>;
  allowMultiple?: boolean;
  jobType?: number;
  maxFileSize?: number;
};

type State = {
  files: Array<IFileInfo>;
  filesReadyToValidate: boolean;
};

class FileUploadStep extends React.Component<Props, State> {
  uploadFileRestrictions: UploadFileRestrictions;

  constructor(props: Props) {
    super(props);

    this.state = {
      files: new Array<IFileInfo>(),
      filesReadyToValidate: false
    };

    this.uploadFileRestrictions = {
      allowedExtensions: props.allowedExtensions || new Array<string>(".doc", ".docs", ".docx", ".xls", ".xlsx", ".pdf", ".pdfx"),
      maxFileSize: this.props.maxFileSize || 10485760 //10MB as bytes
    };
  }

  handleOnDataStateChanged(files: Array<IFileInfo>) {
    this.setState({ files });
  }

  handleOnUploadComplete() {
    this.setState({ filesReadyToValidate: true });
    this.props.onFilesReadyForValidation();
  }

  render() {
    const requestData: IFileUploadInitializeJobRequestData = {
      jobType: this.props.jobType || JobTypeEnum.Upload,
      resourceGroupId: this.props.resourceGroupId
    };

    return (
      <>
        {this.state.filesReadyToValidate && (
          <FileValidation files={this.state.files} onValidationComplete={this.props.onValidationComplete} onFileUploaded={this.props.onFileUploaded} jobType={this.props.jobType} />
        )}
        {!this.state.filesReadyToValidate && (
          <>
            <h2>Upload Files</h2>
            <p>
              Select files for uploading. After selecting or dragging files for upload click the 'Upload' button. Once
              they are Uploaded they will be scanned for viruses and for matching file type. Files with a virus or a
              mismatched file type will not be saved.
            </p>
            <StandardFileUpload
              multiple={this.props.allowMultiple != null ? this.props.allowMultiple : true}
              restrictions={this.uploadFileRestrictions}
              requestData={requestData}
              onDataStateChanged={this.handleOnDataStateChanged.bind(this)}
              onUploadComplete={this.handleOnUploadComplete.bind(this)}
            />
          </>
        )}
      </>
    );
  }
}

export default FileUploadStep;
