import * as React from "react";
import { CircularProgress } from "@material-ui/core";
import { Button, PropTypes } from "@material-ui/core";
import "./ActionBar.scss";
import Tooltip from "@material-ui/core/Tooltip";

interface IActionBarProps {
  pageTitle?: string;
  buttons?: IActionBarButtons[];
  breadCrumbTitle?: string;
  breadCrumbUrl?: string;
  infoMessage?: string | React.ReactNode;
}

export interface IActionBarButtons {
  handleClick?: (event: React.MouseEvent<HTMLElement>) => void;
  buttonLabel?: string;
  color?: PropTypes.Color;
  className?: string;
  disabled?: boolean;
  type?: string;
  formId?: string;
  isSaving?: boolean;
  tooltip?: string;
}

const ActionBarButtons = (props: IActionBarButtons) => {
  return props.type && props.type.toLowerCase() === "submit" ? (
    <Button variant="contained" type="submit" color={props.color} disabled={props.disabled} form={props.formId} className={props.className}>
      {props.isSaving && <CircularProgress size={14} />} {props.buttonLabel}{" "}
    </Button>
  ) : (
    <Tooltip
    title={props.tooltip ? props.tooltip:""}
    placement="top-start"
    arrow>
      <div>
        <Button variant="contained" disabled={props.disabled} onClick={props.handleClick} color={props.color} className={props.className}>
          {props.isSaving && <CircularProgress size={14} />} {props.buttonLabel}
        </Button>
      </div>
    </Tooltip>
  );
};

const ActionBar: React.FunctionComponent<IActionBarProps> = props => {
  return (
    <div className="action-bar-wrapper">
      {props.infoMessage && <span className="infoblock">{props.infoMessage}</span>}
      {props.buttons && props.buttons.map(item => (
        <ActionBarButtons key={item.buttonLabel} {...item} />
      ))}
    </div>
  );
};

export { ActionBar };
