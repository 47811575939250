import React from "react";
import { ISessionManager } from "../SessionManager";
import HeaderWrapper from "../components/common/HeaderWrapper";
import Session from "../components/session/Session";
import WarningMessagePopup from "../components/common/WarningMessagePopup";
import MainDrawer from "../components/edge/nav/MainDrawer";
import { EpiqFooterComponent } from "../components/edge/footer/EpiqFooterComponent";
import epiqwhiteLogo from "../images/epiq-logo-white.svg";
import JobTracker from "../components/job/JobTracker";
import NotificationMessage from "../components/notificationmessage/NotificationMessage";
import "@progress/kendo-theme-material/dist/all.css";
import "./Layout.scss";

interface IProps {
  sessionManager: ISessionManager;
  history: any;
}

type Props = IProps & { children?: React.ReactNode };

export const Layout = (props: Props) => {
  return (
    <React.Fragment>
      <HeaderWrapper sessionManager={props.sessionManager} />
      <Session sessionManager={props.sessionManager} />
      <JobTracker />
      <MainDrawer sessionManager={props.sessionManager} history={props.history} children={props.children} />
      <WarningMessagePopup />
      <NotificationMessage sessionManager={props.sessionManager} />
      <EpiqFooterComponent footerLogo={epiqwhiteLogo} />
    </React.Fragment>
  );
};

export default Layout;
