import React, { Component, MouseEvent } from "react";
import { connect } from "react-redux";
import * as AuthenticationStore from "../../store/Authentication";
import * as WarningMessageStore from "../../store/WarningMessage";
import UserService from "../../services/UserService";
import AuthenticationService from "../../services/AuthenticationService";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import "./ChangePassword.scss";
import { IApplicationState } from "../../store";
import { TextInput } from "../common/TextInput";
import { AppInsights, AppInsightsHelper } from "../../AppInsights";

enum OktaErrorMessages {
  OldPasswordIsNotCorrect = "Old Password is not correct",
  PasswordCannotBeYourCurrentPassword = "Password cannot be your current password"
}

enum ErrorMessages {
  EmptyCurrentPassword = "Please enter your current password",
  EmptyNewPassword = "Please enter a new password",
  EmptyConfirmNewPassword = "Please enter a confirm new password",
  MismatchPassword = "New passwords must match",
  IncorrectCurrentPassword = "Current password is incorrect",
  NewPasswordShouldNotBeSameAsOld = "New password cannot be same as previous password. Please enter a new password.",
  PasswordNotMetTheRequirement = "New password does not meet the minimum security requirements",
  EmptyString = ""
}

interface Rule {
  key: string;
  isPassed: boolean;
  regexExpression: string;
}

interface PasswordPolicies {
  minLength: number;
  minLowerCase: number;
  minUpperCase: number;
  minNumber: number;
  minSymbol: number;
  excludeUsername: boolean;
  excludeAttributes: [];
}

interface ErrorMessage {
  emptyCurrentPassword: string;
  emptyNewPassword: string;
  emptyConfirmNewPassword: string;
  mismatchPassword: string;
  incorrectCurrentPassword: string;
  passwordNotMetTheRequirement: string;
  newPasswordShouldNotBeSameAsOld: string;
  oktaSaveErrorMessage: string,
}

interface IProps {
  history: any;
}

type Props = IProps &
  AuthenticationStore.IAuthenticationState &
  typeof AuthenticationStore.actionCreators &
  WarningMessageStore.IWarningMessageState &
  typeof WarningMessageStore.actionCreators;

type State = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  isLoading: boolean;
  errorMessage: ErrorMessage;
  passwordRules: Rule[];
};

class ChangePassword extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      isLoading: true,
      errorMessage: {
        emptyCurrentPassword: "",
        emptyNewPassword: "",
        emptyConfirmNewPassword: "",
        mismatchPassword: "",
        incorrectCurrentPassword: "",
        passwordNotMetTheRequirement: "",
        newPasswordShouldNotBeSameAsOld: "",
        oktaSaveErrorMessage: ""

      },
      passwordRules: []
    };
  }

  async componentDidMount() {
    const response = await UserService.GetPasswordPolicies();
    AppInsightsHelper.addTelemetryInitializer(true);
    if (response.ok) {
      this.addPasswordRules(response.data);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
    if (prevState != this.state) {
      let isUnsavedChangesExist = this.state.currentPassword.length > 0 || this.state.confirmNewPassword.length > 0 || this.state.newPassword.length > 0;
      this.props.updateUnsavedChanges(isUnsavedChangesExist);
    }
  }

  componentWillUnmount() {
    this.props.saveUnsavedChanges(null, false);
  }

  addPasswordRules = (policies: PasswordPolicies) => {
    let rules: Rule[] = [];

    if (policies.minLength) {
      let rule: Rule = {
        key: `At least ${policies.minLength} characters.`,
        isPassed: false,
        regexExpression: `(?=.{${policies.minLength},}$)`
      };
      rules.push(rule);
    }

    if (policies.minUpperCase) {
      let rule: Rule = {
        key: `At least ${policies.minUpperCase} uppercase letter.`,
        isPassed: false,
        regexExpression: "(?=.*[A-Z])"
      };
      rules.push(rule);
    }

    if (policies.minLowerCase) {
      let rule: Rule = {
        key: `At least ${policies.minLowerCase} lowercase letter.`,
        isPassed: false,
        regexExpression: "(?=.*[a-z])"
      };
      rules.push(rule);
    } 

    if (policies.minNumber) {
      let rule: Rule = {
        key: "At least 1 number.",
        isPassed: false,
        regexExpression: "[0-9]"
      };
      rules.push(rule);
    }

    if (policies.minSymbol) {
      let rule: Rule = {
        key: `At least ${policies.minSymbol} special character (! @ # $ % _ + = - &).`,
        isPassed: false,
        regexExpression: "[!@#$%^&*)(+=._-]"
      };
      rules.push(rule);
    }
    this.setState({ passwordRules: rules, isLoading: false });
  };

  updateValidationRules = (value: string) => {
    let rules = this.state.passwordRules.slice();
    let updatedRules: Rule[] = [];
    if (value) {
      let count = 0;
      updatedRules = rules.map(rule => {
        let isPassed = RegExp(rule.regexExpression).test(value);
        if (isPassed) {
          count = count + 1;
        }
        return { ...rule, isPassed: isPassed };
      });

      this.setState({
        passwordRules: updatedRules
      });
    } else {
      updatedRules = rules.map(rule => {
        return { ...rule, isPassed: false };
      });
    }
    this.setState({
      passwordRules: updatedRules,
      newPassword: value
    });
  };

  onKeyUpCurrentPassword = (value: string) => {
    let errorMsg = this.state.errorMessage;
    if (value) {
      this.setState({
        currentPassword: value,
        errorMessage: {
          ...errorMsg,
          emptyCurrentPassword: ErrorMessages.EmptyString,
          incorrectCurrentPassword: ErrorMessages.EmptyString
        }
      });
    } else {
      this.setState({
        currentPassword: "",
        errorMessage: {
          ...errorMsg,
          emptyCurrentPassword: ErrorMessages.EmptyCurrentPassword,
          newPasswordShouldNotBeSameAsOld: ErrorMessages.EmptyString
        }
      });
    }
  };

  onKeyUpNewPassword = (value: string) => {
    let errorMsg = this.state.errorMessage;
    if (value) {
      this.updateValidationRules(value);
      if (value === this.state.confirmNewPassword) {
        this.setState({
          newPassword: value,
          errorMessage: {
            ...errorMsg,
            emptyNewPassword: ErrorMessages.EmptyString,
            newPasswordShouldNotBeSameAsOld: ErrorMessages.EmptyString,
            mismatchPassword: ErrorMessages.EmptyString,
            emptyConfirmNewPassword: ErrorMessages.EmptyString
          }
        });
      } else if (this.state.confirmNewPassword !== ErrorMessages.EmptyString) {
        this.setState({
          newPassword: value,
          errorMessage: {
            ...errorMsg,
            emptyNewPassword: ErrorMessages.EmptyString,
            newPasswordShouldNotBeSameAsOld: ErrorMessages.EmptyString,
            mismatchPassword: ErrorMessages.MismatchPassword,
            emptyConfirmNewPassword: ErrorMessages.EmptyString
          }
        });
      } else {
        this.setState({
          newPassword: value,
          errorMessage: {
            ...errorMsg,
            emptyNewPassword: ErrorMessages.EmptyString,
            newPasswordShouldNotBeSameAsOld: ErrorMessages.EmptyString
          }
        });
      }
    } else {
      this.updateValidationRules(value);
      this.setState({
        newPassword: "",
        errorMessage: {
          ...errorMsg,
          emptyNewPassword: ErrorMessages.EmptyNewPassword,
          incorrectCurrentPassword: ErrorMessages.EmptyString,
          newPasswordShouldNotBeSameAsOld: ErrorMessages.EmptyString
        }
      });
    }
  };

  onKeyUpConfirmNewPassword = (value: string) => {
    let errorMsg = this.state.errorMessage;
    if (value) {
      if (value !== this.state.newPassword || this.state.newPassword === ErrorMessages.EmptyString) {
        this.setState({
          confirmNewPassword: value,
          errorMessage: {
            ...errorMsg,
            mismatchPassword: ErrorMessages.MismatchPassword,
            emptyConfirmNewPassword: ErrorMessages.EmptyString
          }
        });
      } else {
        this.setState({
          confirmNewPassword: value,
          errorMessage: {
            ...errorMsg,
            mismatchPassword: ErrorMessages.EmptyString,
            emptyConfirmNewPassword: ErrorMessages.EmptyString
          }
        });
      }
    } else {
      this.setState({
        confirmNewPassword: "",
        errorMessage: {
          ...errorMsg,
          mismatchPassword: ErrorMessages.EmptyString,
          emptyConfirmNewPassword: ErrorMessages.EmptyConfirmNewPassword,
          incorrectCurrentPassword: ErrorMessages.EmptyString,
          newPasswordShouldNotBeSameAsOld: ErrorMessages.EmptyString
        }
      });
    }
  };

  onClickPasswordUpdate = async (event: MouseEvent<HTMLInputElement>) => {
    let oktaChangePasswordRequest = {
      oldPassword: {
        value: this.state.currentPassword
      },
      newPassword: {
        value: this.state.newPassword
      }
    };
    let response = await UserService.UpdatePassword(oktaChangePasswordRequest);

    console.log("-----------update password response", response);

    if (response.ok) {
      let errorMessage = response.data.errorMessage;
      if (errorMessage === "") {
        this.props.updateUnsavedChanges(false);

        await AuthenticationService.deleteUserSession();

        this.props.setAuthentication(false);
        this.props.history.push("/login");
      } else {
        let errorMsg = this.state.errorMessage;
        if (errorMessage === OktaErrorMessages.OldPasswordIsNotCorrect)
          this.setState({
            errorMessage: {
              ...errorMsg,
              incorrectCurrentPassword: ErrorMessages.IncorrectCurrentPassword
            }
          });
        else if (errorMessage === OktaErrorMessages.PasswordCannotBeYourCurrentPassword) {
          this.setState({
            errorMessage: {
              ...errorMsg,
              newPasswordShouldNotBeSameAsOld: ErrorMessages.NewPasswordShouldNotBeSameAsOld
            }
          });
        }
        else {
          this.setState({
            errorMessage: {
              ...errorMsg,
              incorrectCurrentPassword: ErrorMessages.EmptyString,
              newPasswordShouldNotBeSameAsOld: ErrorMessages.EmptyString,
              oktaSaveErrorMessage: errorMessage
            }
          });
        }
      }
    } else {
      console.log("Error occurred during password update");
    }
  };

  render() {
    let isDisable = true;
    if (this.state.currentPassword !== "" && this.state.newPassword !== "" && this.state.confirmNewPassword !== "" && this.state.newPassword === this.state.confirmNewPassword) {
      let count = 0;
      let rules = this.state.passwordRules;
      rules.filter(rule => {
        if (rule.isPassed) {
          count++;
        }
      });

      isDisable =
        !(this.state.newPassword === this.state.confirmNewPassword && count === rules.length) &&
        this.state.errorMessage.emptyCurrentPassword === "" &&
        this.state.errorMessage.emptyNewPassword === "" &&
        this.state.errorMessage.mismatchPassword === "";
    }

    let currentPasswordErrorMessage =
      this.state.errorMessage.emptyCurrentPassword === ErrorMessages.EmptyCurrentPassword
        ? ErrorMessages.EmptyCurrentPassword
        : this.state.errorMessage.incorrectCurrentPassword === ErrorMessages.IncorrectCurrentPassword
          ? ErrorMessages.IncorrectCurrentPassword
          : "";
    let newPasswordErrorMessage =
            this.state.errorMessage.emptyNewPassword === ErrorMessages.EmptyNewPassword ? ErrorMessages.EmptyNewPassword
          : this.state.errorMessage.newPasswordShouldNotBeSameAsOld === ErrorMessages.NewPasswordShouldNotBeSameAsOld ? ErrorMessages.NewPasswordShouldNotBeSameAsOld
          : this.state.errorMessage.oktaSaveErrorMessage ? this.state.errorMessage.oktaSaveErrorMessage
          : "";

    let confirmNewPasswordErrorMessage =
      this.state.errorMessage.mismatchPassword === ErrorMessages.MismatchPassword
        ? ErrorMessages.MismatchPassword
        : this.state.errorMessage.emptyConfirmNewPassword === ErrorMessages.EmptyConfirmNewPassword
          ? ErrorMessages.EmptyConfirmNewPassword
          : "";

    return (
      <div className="section">
        <div className="change-password-wrapper">
          <Grid container>
            <div className="change-password-header">
              <i className="k-icon fas fa-lock"/>
              Change Password
            </div>
            <div className="change-password-container">
              {this.state.isLoading ? (
                <div className="change-password-loader">
                  <CircularProgress />
                </div>
              ) : (
                  <div>
                    <div className="password-rules-container">
                      {this.state.passwordRules.map((rule, index) => (
                        <div className="password-rules" key={`rule${index}`}>
                          <span className={rule.isPassed ? "check-mark success" : "check-mark"}>
                            <CheckCircleIcon className="check-icon" />
                          </span>
                          <span>{rule.key}</span>
                        </div>
                      ))}
                    </div>
                    <div className="change-password-form-content">
                      <div className="form-wrapper">
                        <TextInput
                          type="password"
                          name="currentPassword"
                          label="Current Password"
                          legacyMode={true}
                          validations={[
                            { name: "required", errorMessage: ErrorMessages.EmptyCurrentPassword },
                            { name: "CustomValidation", errorMessage: `${currentPasswordErrorMessage}`, predicate: `${currentPasswordErrorMessage ? true : false}` }
                          ]}
                          onChange={this.onKeyUpCurrentPassword}
                          displayCustomValidationMessage={currentPasswordErrorMessage ? true : false}
                        />
                      </div>
                      <div className="form-wrapper">
                        <TextInput
                          type="password"
                          name="newPassword"
                          label="New Password"
                          legacyMode={true}
                          validations={[
                            { name: "required", errorMessage: ErrorMessages.EmptyNewPassword },
                            { name: "CustomValidation", errorMessage: `${newPasswordErrorMessage}`, predicate: `${newPasswordErrorMessage ? true : false}` }
                          ]}
                          onChange={this.onKeyUpNewPassword}
                          displayCustomValidationMessage={newPasswordErrorMessage ? true : false}
                        />
                      </div>
                      <div className="form-wrapper">
                        <TextInput
                          type="password"
                          name="confirmNewPassword"
                          label="Confirm Password"
                          legacyMode={true}
                          validations={[
                            { name: "required", errorMessage: ErrorMessages.EmptyConfirmNewPassword },
                            { name: "CustomValidation", errorMessage: `${confirmNewPasswordErrorMessage}`, predicate: `${confirmNewPasswordErrorMessage ? true : false}` }
                          ]}
                          onChange={this.onKeyUpConfirmNewPassword}
                          displayCustomValidationMessage={confirmNewPasswordErrorMessage ? true : false}
                        />
                      </div>
                    </div>
                    <div className="form-wrapper btn-cpwd-container">
                      <input disabled={isDisable} type="button" className="change-password-button" value="Change Password" onClick={this.onClickPasswordUpdate} />
                    </div>
                    <div className="help-links">
                      <a onClick={() => this.props.history.goBack()}>Cancel</a>
                    </div>
                  </div>
                )}
            </div>
          </Grid>
        </div>
      </div>
    );
  }
}

export default connect((state: IApplicationState) => ({ ...state.warningMessageState }), { ...AuthenticationStore.actionCreators, ...WarningMessageStore.actionCreators })(
  ChangePassword as any
);
