import { GridFilterCellProps } from "@progress/kendo-react-grid";
import React, { useState, useEffect, useRef, ReactElement } from "react";
import MultiSelectDropDown, { IMultiSelectDropSelectedEvent } from "../../MultiSelectDropdown";
import "./DropDownFilter.scss";

interface IProps<T> extends GridFilterCellProps {
  data: Array<T>;
  textField?: string;
  dataItemKey?: string;
  className?: string;
  itemClassName?: string;
}

type Props<T> = IProps<T>;

function getSelectionObjects(ids: Array<number>, dataPool: Array<any>, idProp: string) {

  if ((ids as any) === '') return [];

  const selectedIts = dataPool.filter(d => {
    return ids.findIndex(id => id === d[idProp]) > -1;
  });

  return selectedIts;
}

const MultiSelectDropDownFilter = <T extends object = any>(props: Props<T>) => {

  const [selectedItems, setSelectedItems] = useState<Array<T>>(props.value || []);

  useEffect(() => {
      const selections = getSelectionObjects(props.value, props.data, props.dataItemKey);

      setSelectedItems(selections);
  }, [props.value]);

  useEffect(() => { }, [selectedItems]);

  function statusChange(event: IMultiSelectDropSelectedEvent<T>): void {
    props.onChange({
      value: event.data.map(s => (s as any)[props.dataItemKey]),
      operator: "",
      syntheticEvent: event.backingEvent.syntheticEvent
    });
  }

  function onClearButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    props.onChange({
      value: [],
      operator: "",
      syntheticEvent: event
    });
  }

  return <div className={"k-filtercell drop-down-filter-wrapper " + (props.className ? props.className : "")}>
    <MultiSelectDropDown
        onChange={statusChange}
        itemClassName={props.itemClassName}
        textField={props.textField}
        dataItemKey={props.dataItemKey}
        data={props.data}
        value={selectedItems}
    />
    <button
      className="k-button k-button-icon k-clear-button-visible"
      title="Clear"
      disabled={selectedItems.length === 0}
      onClick={onClearButtonClick}
    >
      <span className="k-icon k-i-filter-clear" />
    </button>
  </div>
}

MultiSelectDropDownFilter.defaultProps = {
  textField: "text",
  dataItemKey: "id"
};

export default MultiSelectDropDownFilter;