import { PageChangeEvent, Pager } from "@progress/kendo-react-data-tools";
import { ListView, ListViewHeader } from "@progress/kendo-react-listview";
import { headerStatusUploaded } from "@progress/kendo-react-upload/dist/es/messages";
import React, { useEffect, useState } from "react";
import './SummaryListView.scss';
interface IProps {
  header?: string;
  items: Array<string>;
  skip: number;
  take: number;
}

type Props = IProps;

const SummaryListView = (props: Props) => {
  const [page, setPage] = React.useState({
    skip: props.skip,
    take: props.take,
  });

  const Header = (props: any) => {
    return (
      <ListViewHeader
        style={{ color: "rgb(160, 160, 160)", fontSize: 14 }}
        className="pl-4 pb-2 pt-2"
      >
        {props.header}
      </ListViewHeader>
    );
  };

  const ItemRender = (props: any) => {
    let item = props.dataItem;
    return (
      <div className="k-vbox k-column" style={{
        borderBottom: "1px solid rgba(0,0,0,0.12)",
      }}>
        <div className="summaryContent">
          {item}
        </div>

      </div>
    );
  };

  const handlePageChange = (e: PageChangeEvent) => {
    setPage({
      skip: e.skip,
      take: e.take,
    });
  };

  const { skip, take } = page;

  return (
    <div id="summaryListView">
      {(props.items && props.items.length > 0) ? (<div><ListView
        data={props.items.slice(skip, skip + take)}
        item={ItemRender}
        style={{ width: "100%", maxHeight: "100%" }}
        header={Header}
      />
        {props.items.length > take &&
          <Pager
            skip={skip}
            take={take}
            onPageChange={handlePageChange}
            total={props.items.length}
            info={true}
            className={"k-pager-lg summary-list-pagination"}
          />
        }</div>) : <p>No records available.</p>
      }

    </div>
  );
};

export default SummaryListView;