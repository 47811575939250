import React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../store";
import Project from "../../../models/Project";
import AdminService from "../../../services/AdminService";
import { formatDate } from "@telerik/kendo-intl";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { GridColumnProps, GridFilterCellProps } from "@progress/kendo-react-grid";
import { NavLink } from "react-router-dom";
import CommonHelper from "../../common/utilities/CommonHelper";
import { IGridParams, AdvancedGrid } from "../../common/grid/AdvancedGrid";
import "./project.scss";
import { FilterDescriptor } from "@progress/kendo-data-query";
import * as SessionStore from "../../../store/Session";
import { DateFilter } from "@progress/kendo-react-data-tools";

const GridConfigurations = {
  PageSize: 100,
  OrderBy: "projectNumber",
  Ascending: "asc",
  Descending: "desc"
};

interface IProps {
  history: any;
}

interface IProjectFetchResult {
  totalProjects: number;
  projects: Array<Project>;
}

type Props = IProps &
  SessionStore.ISessionState;

type State = {
  dataState: IGridParams;
  fetchingProjects: boolean;
  fetchResults: IProjectFetchResult;
  hasError: boolean;
};

class ProjectList extends React.PureComponent<Props, State> {
  columns: Array<GridColumnProps>;

  constructor(props: Props) {
    super(props);

    this.state = {
      dataState: {
        skip: 0,
        take: 100,
        sort: [{ field: "projectNumber", dir: "asc" }]
      },
      fetchingProjects: false,
      fetchResults: {
        totalProjects: 0,
        projects: new Array<Project>()
      },
      hasError: false
    };

    this.columns = new Array<GridColumnProps>(
      { field: "projectNumber", title: "PROJECT #", cell: this.props.sessionData.permissions.has("EpiqAdminGetProject") ? this.codeCustomCell :this.codeCustomCellNoLink, filterable: true },
      { field: "projectName", title: "PROJECT NAME", cell: this.nameCustomCell, filterable: true },
      {
        field: "projectStatus",
        title: "STATUS",
        cell: this.statusCustomCell,
        filterable: true
      },
      {
        field: "projectStartDate",
        title: "START DATE",
        cell: this.startDateCustomCell,
        format: "{0:g}",
        filterable: true,
        filter: "date"
      }
    );
  }

  async getProjects(dataState: IGridParams) {
    let projectStartDateValue: Date;
    const filters = dataState.filters
      ? dataState.filters.map(compositeFilter => {
          const projectStartDateFilter: FilterDescriptor = compositeFilter.filters.find(
            filter => (filter as FilterDescriptor).field === "projectStartDate"
          ) as FilterDescriptor;

          if (projectStartDateFilter) {
            const dateFilterValue = projectStartDateFilter.value;

            if (!isNaN(new Date(dateFilterValue).getFullYear()) && new Date(dateFilterValue).getFullYear() > 1970) {
              projectStartDateFilter.operator = 'eq';
            }

            projectStartDateValue =
              typeof dateFilterValue === "string" ? new Date("01/01/1700") : ((projectStartDateFilter.operator === 'isnull' || projectStartDateFilter.operator === 'isnotnull') ? null : new Date(dateFilterValue));

            return {
              ...compositeFilter,
              filters: [
                {
                  ...projectStartDateFilter,
                  field: "projectStartDate",
                  operator: projectStartDateFilter.operator !== 'eq' ? projectStartDateFilter.operator : "eq",
                  value: projectStartDateFilter.operator !== 'eq' ? projectStartDateValue : (new Date(
                    new Date(projectStartDateValue.toUTCString()).setDate(projectStartDateValue.getDate() + 1))
                  )
                }
              ]
            };
          }

          return compositeFilter;
        })
      : [];

    this.setState({ ...this.state, fetchingProjects: true }, async () => {
      const result = await AdminService.getProjects({ ...dataState, filters: filters });

      this.setState({
        ...this.state,
        fetchingProjects: false,
        hasError: !result.ok,
        fetchResults: {
          ...this.state.fetchResults,
          projects: result.ok ? result.data.results : new Array<Project>(),
          totalProjects: result.ok ? result.data.count : 0
        }
      });
    });
  }

  filterElements(element: any) {
    if (element.tagName === "EM") {
      return true;
    }
    return false;
  }

  codeCustomCell(props: any) {
    const projectNumber = props.dataItem.projectNumber ? props.dataItem.projectNumber : "-";

    return (
      <td>
        <div className="project-details">
          <div className="project-code">
            <NavLink to={`/administration/projectDetails/${props.dataItem.projectId}`}>
              <em title={projectNumber}> {projectNumber} </em>
            </NavLink>
          </div>
        </div>
      </td>
    );
  }

  codeCustomCellNoLink(props: any) {
    const projectNumber = props.dataItem.projectNumber ? props.dataItem.projectNumber : "-";

    return (
      <td>
        <div className="project-details">
          <div className="project-code">
            <em title={projectNumber}> {projectNumber} </em>
          </div>
        </div>
      </td>
    );
  }

  nameCustomCell(props: any) {
    return <td> {props.dataItem.projectName ? props.dataItem.projectName : "-"} </td>;
  }

  statusCustomCell(props: any) {
    return <td> {props.dataItem.projectStatus ? props.dataItem.projectStatus : "-"} </td>;
  }

  startDateCustomCell(props: any) {
    let date = "-";
    let startDate = props.dataItem.projectStartDate;

    if (startDate) {
      let localDateTime = CommonHelper.convertUTCDateToLocalDate(startDate);
      date = formatDate(localDateTime, "g");
    }

    return <td>{date}</td>;
  }

  render() {
    const { fetchingProjects, fetchResults, dataState, hasError } = this.state;

    return (
      <div className="projects-list">
        <Tooltip openDelay={2} position="right" filter={this.filterElements}>
          <AdvancedGrid
            showErrorState={hasError}
            showLoadingIndicator={fetchingProjects}
            sortable={{ mode: "multiple" }}
            data={fetchResults.projects}
            dataFetch={this.getProjects.bind(this)}
            dataState={dataState}
            columns={this.columns}
            paging={false}
            totalRecords={{ value: fetchResults.totalProjects, label: "Projects" }}
            noRecordsRender={<p>No projects found.</p>}
            noLoadOnMount={true}
            filteredDataOnly={<p>Please filter to search for projects.</p>}
            filterOperators={{
              text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
              date: [{ text: "grid.filterIsNotNullOperator", operator: "isnotnull" }, { text: "grid.filterIsNullOperator", operator: "isnull" }]
            }}
          />
        </Tooltip>
      </div>
    );
  }
}

//export default ProjectList;
export default connect(
  (state: IApplicationState) => ({  ...state.sessionState }),{})(ProjectList as any);
