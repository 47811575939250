import React, { useEffect } from "react";
import { Container } from "@material-ui/core";
import "./UploadSuccess.scss";

interface IProps {
  history: any
}

type Props = IProps;

const UploadSuccess = () => { 

  useEffect(() => {
    var targetWindow = window.parent
    console.log("got parent window?");
    targetWindow.postMessage("UploadSuccess","*");
    console.log("UploadSuccess....");
    
  }, []);

  return (
    <React.Fragment>
      <div className="header-bar">
      </div>
      <Container maxWidth="lg" className="content-wrapper">
        <div className="makeStyles-root-13">
          <div className="MuiGrid-root">
            <div className="no-apps">
              <div className="msg">
                <h3><i className={`upload-success-icon fa fa-check-circle`} /> File Upload Successful</h3>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default UploadSuccess;
