import { Action, Reducer } from "redux";
import { AppThunkAction, AppThunkActionPromise } from "./";
import { UserActivity } from "../models/UserActivityModels";
import AdminService from "../services/AdminService";
import GridParams from "../components/common/GridHelper";

export interface IUserActivityState {
  isLoading: boolean;
  userActivity: UserActivity[];
  totalUserActivityCount: number;
  searchText?: string;
  hasError?: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface GetUserActivityRequestAction {
  type: "GET_USER_ACTIVITY_REQUEST";
}

interface GetUserActivityRequestFailureAction {
  type: "GET_USER_ACTIVITY_REQUEST_FAILURE";
  hasError?: boolean;
}

interface GetUserActivityResponseAction {
  type: "GET_USER_ACTIVITY_RESPONSE";
  userActivity: UserActivity[];
  totalUserActivityCount: 0;
}

interface SetUserActivityToDefault {
  type: "SET_USER_ACTIVITY_TO_DEFAULT"
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = GetUserActivityRequestAction | GetUserActivityRequestFailureAction | GetUserActivityResponseAction | SetUserActivityToDefault;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  getUserActivity: (gridParams: GridParams): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    dispatch({ type: "GET_USER_ACTIVITY_REQUEST" });
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      const result = await AdminService.getUserActivity(gridParams);

      if (result.ok) {
        dispatch({
          type: "GET_USER_ACTIVITY_RESPONSE",
          userActivity: result.data.results,
          totalUserActivityCount: result.data.count
        });
      } else {
        dispatch({
          type: "GET_USER_ACTIVITY_REQUEST_FAILURE",
          hasError: true
        });
      }
    }
  },
  setUserActivityToDefault: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({
      type: "SET_USER_ACTIVITY_TO_DEFAULT"
    });
  }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: IUserActivityState = {
  userActivity: [],
  isLoading: false,
  totalUserActivityCount: 0,
  searchText: "",
  hasError: false
};

export const userActivityreducer: Reducer<IUserActivityState> = (
  state: IUserActivityState | undefined,
  incomingAction: Action
): IUserActivityState => {
  if (state === undefined) {
    return unloadedState;
  }
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "GET_USER_ACTIVITY_REQUEST": {
      return { ...state, isLoading: true };
    }
    case "GET_USER_ACTIVITY_REQUEST_FAILURE": {
      return { ...unloadedState, hasError: true };
    }
    case "GET_USER_ACTIVITY_RESPONSE": {
      return {
        userActivity: action.userActivity,
        isLoading: false,
        totalUserActivityCount: action.totalUserActivityCount,
        searchText: state.searchText
      };
    }
    case "SET_USER_ACTIVITY_TO_DEFAULT": {
      return { ...unloadedState};
    }
    default: {
      return state;
    }
  }
};
