import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DynamicResourceList from "./resourcegroups/DynamicResourceList";
import "./ResourceList.scss";
import * as SessionStore from "../../../store/Session";
import { IApplicationState } from "../../../store";

interface IProps {
  history: any;
}

type Props = IProps & SessionStore.ISessionState;

const ResourceList = (props: Props) => {
  const permissions = props.sessionData.permissions;
  const take = 500;
  const showAssignmentsPerm = permissions.has("EpiqAdminListAssignments");

  return (
    <div className = 'resources-list-admin-wrapper'>
      <div className = {showAssignmentsPerm ? 'admin-col-wrapper' : ''}>
        <DynamicResourceList isAdminResourceList={true} showAssignments={showAssignmentsPerm} take={take}/>
      </div>
    </div>);
}

export default connect((state: IApplicationState) => ({ ...state.sessionState }), {})(ResourceList as any);