import * as React from "react";
import { NavLink } from "react-router-dom";
import { IAppUserRequestParams } from "../../../../models/RequestModels";
import AdminService from "../../../../services/AdminService";
import NotificationService from "../../../../services/NotificationService";
import { Button } from "@progress/kendo-react-buttons";
import { User } from "../../../../models/UserModels";
import { GridColumnProps, GridFilterCellProps, GridCellProps } from "@progress/kendo-react-grid";
import { SortDescriptor, FilterDescriptor, CompositeFilterDescriptor } from "@progress/kendo-data-query";
import "./AppUserList.scss";
import { AdvancedGrid, IGridParams } from "../../../common/grid/AdvancedGrid";
import { DropDownFilter } from "../../../common/grid/filters/DropDownFilter";
import CommandCell from "../../../common/grid/columns/CommandCell";
import { ISessionData } from "../../../../store/Session";
import { ApplicationStatus, Application } from "../../../../models/ApplicationModels";
import DialogBox from "../../../common/DialogBox";
import ApplicationJobManager from "../../application/applicationJobManager";
import CommonHelper from "../../../common/utilities/CommonHelper";
import { formatDate } from "@telerik/kendo-intl";

interface UserType {
  name: string;
  id: number;
}

type Props = {
  users?: User[] | undefined;
  applicationId?: number;
  selectionChange?: (selected: User[]) => void;
  addClickHandler?: () => void;
  onRemoveUsers?: (selected: User[]) => void;
  sessionData: ISessionData;
  isCreate?: boolean;
  applicationStatus: ApplicationStatus;
  applicationName: string;
  savedUsersCount: number;
  handleBulkRemoveAllComplete?: () => void;
};

type State = {
  hasError: boolean;
  isLoading: boolean;
  dataState: IAppUserRequestParams;
  isFilterExists: boolean;
  showRemoveAllConfirmPopup: boolean;
  disableRemoveAllbtn: boolean;
};

class AppUserList extends React.PureComponent<Props, State> {
  columns: Array<GridColumnProps>;

  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
      isLoading: false,
      isFilterExists: false,
      showRemoveAllConfirmPopup: false,
      disableRemoveAllbtn: false,
      dataState: {
        skip: 0,
        take: 500,
        sort: [{ field: "lastName|firstName", dir: "asc" }],
        appId: props.applicationId
      }
    }

    this.columns = new Array<GridColumnProps>(
      {
        field: "lastName|firstName",
        title: "USERS",
        cell: props.sessionData.permissions.has("EpiqAdminGetUser") ? this.usersBackground : this.usersBackgroundNoLink,
        filterable: true,
        filter: "text"
      },
      {
        field: "username",
        title: "USERNAME",
        cell: this.userName,
        filterable: true,
        filter: "text"
      },
      {
        field: "email",
        title: "EMAIL",
        cell: this.email,
        filterable: true,
        filter: "text"
      },
      {
        field: "superAdmin",
        title: "ACCOUNT TYPE",
        cell: this.isSuperAdmin,
        filterable: true,
        filter: "text",
        filterCell: (props: GridFilterCellProps) => (
          <DropDownFilter
            {...props}
            data={[{ name: "User", id: 0 }, { name: "Global Admin", id: 1 }] as Array<UserType>}
            textField="name"
            defaultSelectedOption={null}
          />
        )
      },
      { field: "lastLoginDate", title: "LAST LOGIN", format: "{0:g}", filterable: true, filter: "date", className: "user-date" },
      { field: "createdDate", title: "CREATED DATE", format: "{0:g}", filterable: true, filter: "date", cell: this.createdDateCustomCell, className: "user-date" }
    );

    if (props.sessionData.superAdmin) {
      this.columns.push({
        title: "",
        cell: CommandCell({ onRemove: this.removeUser }),
        sortable: false,
        headerClassName: "no-sort"
      });
    }
  }

  createdDateCustomCell = (props: GridCellProps) => {
    let date = "";
    let createdDate = props.dataItem.createdDate;

    if (createdDate) {
      let localDateTime = CommonHelper.convertUTCDateToLocalDate(createdDate);
      date = formatDate(localDateTime, "g");
    }

    return <td>{date}</td>;
  }

  getModifiedDateFilter = (
    gridFilters: Array<CompositeFilterDescriptor>,
    dateColumnName: string = "lastLoginDate"
  ) => {
    // This function is used for date column filter in all advance grid pages.
    let isDateFilterExists = false;
    let dateValue: Date;
    const filters = gridFilters
      ? gridFilters.map(compositeFilter => {
        const dateFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === dateColumnName
        ) as FilterDescriptor;

        if (dateFilter) {
          const dateFilterValue = dateFilter.value;

          if (dateFilterValue === "")
            return compositeFilter;

          if (!isNaN(new Date(dateFilterValue).getFullYear()) && new Date(dateFilterValue).getFullYear() > 1970) {
            dateFilter.operator = 'eq';
          }

          dateValue = typeof dateFilterValue === "string" ? new Date("01/01/1901") : ((dateFilter.operator === 'isnull' || dateFilter.operator === 'isnotnull') ? null : new Date(dateFilterValue));

          if (dateFilter.operator === 'isnull' || dateFilter.operator === 'isnotnull') {
            return {
              ...compositeFilter,
              filters: [
                {
                  ...dateFilter,
                  field: dateColumnName,
                  operator: dateFilter.operator,
                  value: dateValue
                }
              ]
            };
          }

          isDateFilterExists = true;

          return {
            ...compositeFilter,
            filters: [
              {
                ...dateFilter,
                field: dateColumnName,
                operator: "gte",
                value: new Date(dateValue.toUTCString())
              }
            ]
          };
        }

        return compositeFilter;
      })
      : [];

    if (isDateFilterExists) {
      filters.push({
        logic: "and",
        filters: [
          {
            field: dateColumnName,
            operator: "lt",
            value: new Date(new Date(dateValue.setUTCDate(dateValue.getUTCDate() + 1)).toUTCString())
          }
        ]
      });
    }

    return filters;
  };

  loadUsers = async (dataState: IAppUserRequestParams) => {
    try {
      this.setState({ isLoading: true, disableRemoveAllbtn: dataState.filter ? true : false});

      let userListFilters = new Array<CompositeFilterDescriptor>();

      if (dataState.filters) {
        let filters = this.getModifiedDateFilter(dataState.filters);
        filters = this.getModifiedDateFilter(filters, "createdDate");
        filters.forEach(compositeFilter => {
          let modifiedCompositeFilter: CompositeFilterDescriptor = compositeFilter;
          const userTypeFilter: FilterDescriptor = compositeFilter.filters.find(
            filter => (filter as FilterDescriptor).field === "superAdmin"
          ) as FilterDescriptor;

          if (userTypeFilter) {

            if (userTypeFilter.value === "") {
              return;
            }

            const value = userTypeFilter.value as UserType
            modifiedCompositeFilter = {
              ...compositeFilter,
              filters: [
                {
                  ...userTypeFilter,
                  field: "superAdmin",
                  operator: "eq",
                  value: value.id === 1
                }
              ]
            }
          }

          const lastLoginDateFilter: FilterDescriptor = compositeFilter.filters.find(
            filter => (filter as FilterDescriptor).field === "lastLoginDate"
          ) as FilterDescriptor;

          if (lastLoginDateFilter) {
            if (lastLoginDateFilter.value === "") {
              return;
            }
          }

          const createdDateFilter: FilterDescriptor = compositeFilter.filters.find(
            filter => (filter as FilterDescriptor).field === "createdDate"
          ) as FilterDescriptor;

          if (createdDateFilter) {
            if (createdDateFilter.value === "") {
              return;
            }
          }

          userListFilters.push(modifiedCompositeFilter);
        });

      }
      let reverseSortDescriptors: SortDescriptor[] = this.updateUserTypeSortDirection(dataState);
      const result = await AdminService.getAppUsers({
        ...dataState, filters: userListFilters, sort: reverseSortDescriptors
      });

      let updateSortDescriptors: SortDescriptor[] = this.updateUserTypeSortDirection(dataState);
      const resultUsers = result ? result.data.results : null;

      if (this.props.selectionChange) {
        this.props.selectionChange(resultUsers);
      }

      this.setState({ dataState: { ...dataState, filters: userListFilters, sort: updateSortDescriptors }, isFilterExists: (dataState.filters && dataState.filters.length > 0) });
    } catch (e) {
      this.setState({ hasError: true });
      NotificationService.showErrorToast("Something went wrong while getting users.");
    } finally {
      this.setState({
        isLoading: false
      })
    }
  }

  updateUserTypeSortDirection = (dataState: IAppUserRequestParams) => {
    const sort: SortDescriptor[] = dataState.sort ? [...dataState.sort] : [];

    return sort.map((item, index) => {
      if (item.field === "superAdmin") {
        item.dir = item.dir === "asc" ? "desc" : "asc"
      }

      return item;
    });
  }

  removeUser = (cellProps: any) => {
    if (this.props.onRemoveUsers) {
      const alteredUsers = this.props.users.filter(resource => resource.id !== cellProps.id);
      this.props.onRemoveUsers(alteredUsers as User[]);
    }
  };

  isSuperAdmin = (props: any) => {
    const superAdmin = props.dataItem.superAdmin ? "Global Admin" : "User";

    return (
      <td>
        <div>{superAdmin}</div>
      </td>
    );
  };

  userName = (props: any) => {
    return (
      <td>
        <div>
          <em className="text-ellipsis" title={props.dataItem.username}>{props.dataItem.username} </em>
        </div>
      </td>
    );
  };

  email = (props: any) => {
    return (
      <td>
        <div>
          <em className="text-ellipsis" title={props.dataItem.email}>{props.dataItem.email} </em>
        </div>
      </td>
    );
  };

  usersBackground = (props: any) => {
    const { lastName, firstName } = props.dataItem;
    const fullName = lastName + ", " + firstName;

    return (
      <td>
        <div className="user-details">
          <div className="user-name">
            <NavLink to={`/administration/userDetails/${props.dataItem.id}`}>
              <em title={fullName}>{fullName} </em>
            </NavLink>
          </div>
        </div>
      </td>
    );
  };

  usersBackgroundNoLink = (props: any) => {
    const { lastName, firstName } = props.dataItem;
    const fullName = lastName + ", " + firstName;

    return (
      <td>
        <div className="user-details">
          <div className="user-name">
            <em title={fullName}>{fullName} </em>
          </div>
        </div>
      </td>
    );
  };

  handleRemoveAllUsers = () => {
    this.setState({ showRemoveAllConfirmPopup: true });
  }

  async toggleDialogEvent(event: React.MouseEvent<HTMLElement>) {
    if (event.currentTarget.textContent.toLocaleLowerCase() === "confirm") {
      const jobManager = new ApplicationJobManager({ applicationId: this.props.applicationId, applicationName: this.props.applicationName });
      this.setState({ showRemoveAllConfirmPopup: false, disableRemoveAllbtn: true })
      try {
        await jobManager.submitBulkUserDeletionJob();
        await this.loadUsers(this.state.dataState);
        this.setState({ disableRemoveAllbtn: false });

        if (this.props.handleBulkRemoveAllComplete) {
          this.props.handleBulkRemoveAllComplete();
        }
      }
      catch (error) {
        this.setState({ disableRemoveAllbtn: false });
        NotificationService.showErrorToast("Something went wrong while removing users" + error);
      }
      finally {
      }
    }
    else if (event.currentTarget.textContent.toLocaleLowerCase() === "cancel") {
      this.setState({ showRemoveAllConfirmPopup: false });
    }
  }

  gridToolBarRender = (): JSX.Element => {
    const btnRemovecls = this.state.disableRemoveAllbtn ? "btn-secondary disableButton" : "btn-secondary";
    return (
      <div>
        {((!this.props.isCreate && this.props.sessionData.superAdmin && this.props.savedUsersCount > 0) && (
          <>
            <Button className={btnRemovecls} disabled={this.state.disableRemoveAllbtn} primary={false} onClick={this.handleRemoveAllUsers.bind(this)}>
              Remove All
            </Button>
          </>
        ))
        }
        {// the epiq admin permissions that are checked below look very suspicious and wrong, but I don't have time to change things for that right now
          ((this.props.sessionData.permissions.has("AssignApplication") || (this.props.isCreate && this.props.sessionData.permissions.has("EpiqAdminCreateApplication")) || (!this.props.isCreate && this.props.applicationStatus === ApplicationStatus.active && this.props.sessionData.permissions.has("EpiqAdminUpdateApplication")))) && (
          <>
            <Button className="add-user-btn" icon={"plus"} primary={true} onClick={this.props.addClickHandler}>
              Users
            </Button>
          </>
        )}
      </div>);
  }

  render() {
    const { hasError, isLoading, dataState, isFilterExists } = this.state;
    const data = this.props.users;

    const dialog = (
      <div className="warning">
        <DialogBox
          title={"Remove all users from application?"}
          content={`This action removes all user access to this application. To proceed, confirm that you want to remove application access from all ${this.props.savedUsersCount} users.`}
          okButtonLabelText={"CANCEL"}
          cancelButtonLabelText={"CONFIRM"}
          visible={this.state.showRemoveAllConfirmPopup}
          toggleDialogEvent={this.toggleDialogEvent.bind(this)}
        />
      </div>);

    return (
      <div className="base-grid-wrapper application-user-list">
        <AdvancedGrid
          showErrorState={hasError}
          showLoadingIndicator={isLoading}
          sortable={{ mode: "multiple" }}
          data={data}
          dataFetch={this.loadUsers}
          dataState={dataState}
          columns={this.columns}
          paging={false}
          noRecordsRender={<p>{isFilterExists ? "No users found." : "No users added."}</p>}
          noLoadOnMount={false}
          filteredDataOnly={false}
          multiFieldFilterDelimiter="|"
          filterOperators={{
            text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
            date: [{ text: "grid.filterIsNotNullOperator", operator: "isnotnull" }, { text: "grid.filterIsNullOperator", operator: "isnull" }]
          }}
          gridToolbarContent={this.gridToolBarRender()}
        />
        {dialog}
      </div>
    );
  }
}

export default AppUserList;
