import { IGridParams } from "../components/common/grid/BaseGrid";

class BaseUpdateableUser {
  [x: string]: string | number | boolean | Date | Array<number> | Array<string> | Array<IAccountConfiguration>;
  public id?: number = 0;
  public firstName: string = "";
  public lastName: string = "";
  public idpId?: string | null | undefined;
  public username: string = "";
  public email: string = "";
  public comments: string = "";
  public status?: string = "";
  public incidentNumber?: string = "";
  public sendInvite: boolean = false;
  public superAdmin: boolean = false;
  public employerId?: number;
  public lastLoginDate?: Date | null | undefined;
  public invitationsentDate?: Date | null | undefined;
  public reconfigureUserStatus?: number | null;
  public oktaLastLoginDate?: Date | null | undefined;
  public employerName?: string;
  public employerClient?: boolean;
  public employerDept?: string;
  public entityType?: string;
  public employerDomain?: string;
  public resourceContextId?: number;
  public hasValidEmail?: boolean = false;
}

class User extends BaseUpdateableUser {
  public createdDate: Date | null | undefined;
  public createdBy: number = 0;
  public selected: boolean = false;
  public sendInvitation: string | null | undefined;
  public lastmodifiedDate: Date | null | undefined;
  public lastmodifiedBy: number | null | undefined;

  static dedupeUsers = (newUsers: User[], existingUsers: User[]): User[] => {
    const combined = (existingUsers || []).concat(newUsers || []);
    return combined.filter((app, idx, self) => self.findIndex((app2) => app2.id === app.id) === idx);
  };
}

class UserGroupUpdateReq extends User {
  public fullName: string;
  public groupName: string;
  public groupArtifactId: string;
}

class UpsertUserModel extends BaseUpdateableUser {
  public addApplications: number[] = [];
  public removeApplications: number[] = [];
  public addUserGroups: number[] = [];
  public removeUserGroups: number[] = [];
  public addOktaUserGroups: string[] = [];
  public removeOktaUserGroups: string[] = [];
  public configureAccountForApplications?: Array<IAccountConfiguration>;
  public newIdpId?: string | null;
  public attemptSkipEAChanges?: boolean = false;
}

// This is data that gets submitted
class SubmittedUpsertUserModel extends UpsertUserModel {
  public inviteBrandingId?: number;
  public addUserToRelativity: boolean = false;
}

class UserDetailsModel extends SubmittedUpsertUserModel {
  public isFederated: boolean = false;
  public provider?: string = "";
}

class SendInvite {
  public oktaUserId: string = "";
  public UserId: number = 0;
  public status: string = "";
}

class UserGroup {
  public groupId: number = 0;
  public groupName: string = "";
  public comments: string = "";
  public createdDate?: Date | null | undefined;
  public createdBy?: number | null | undefined;
  public lastmodifiedBy?: number | null | undefined;
  public lastmodifiedDate?: string | null | undefined;
  public epiqOnly?: boolean = false;
  public isDeleted?: boolean = false;
  // public users?: number[] = [];
}

class UpsertUserGroupModel extends UserGroup {
  public addUsers: number[] = [];
  public removeUsers: number[] = [];
  public domainIds: number[] = [];
}

export interface IUpsertUserGroupUserModel {
  groupId: number;
  addUsersUsernames?: string[];
  removeUsers?: number[];
}

export interface IAccountConfiguration {
  applicationId: number;
  data: string;
}

enum AccountStates {
  Active = "ACTIVE",
  Provisioned = "PROVISIONED",
  Staged = "STAGED",
  Suspended = "SUSPENDED",
  Locked = "LOCKED",
  LockedOut = "LOCKED_OUT",
  PasswordExpired = "PASSWORD_EXPIRED",
  Recovery = "RECOVERY",
  Deprovisioned = "DEPROVISIONED"
}

export { User, UpsertUserModel, UserDetailsModel, SendInvite, UserGroup, UpsertUserGroupModel, SubmittedUpsertUserModel, AccountStates, UserGroupUpdateReq };

export interface ISsoUserRequest extends IGridParams {

}

export interface ISsoProfile {
  name: string;
  description: string;
}
export interface ISsoUserGroup {
  id: string;
  created: number;
  lastUpdated: number
  lastMembershipUpdated: number;
  type: string;
  profile: ISsoProfile;
}

export interface ISsoUser {
  id: string;
  status: string;
  created: string;
  activated: string;
  statusChanged: string;
  lastLogin: string;
  eAUserId?: number;
  firstName: string;
  lastName: string;
  email: string;
  login: string;
}

export interface IUserFactor {    
  id: string;
  factorType: string;  
  status: string;
  created: number;
  lastUpdated: number
}

export enum ReconfigureUserStatus {
  UserDoesNotExistInOkta,
  UserAccountDoesNotHaveCorrectOktaId 
}

export interface IFetchssoOktaGroup {
  take: number;
  groupName: string;
}