import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";

import "./InlineFeedback.scss";

export type InlineFeedBackResultLevel = "Success" | "Problem" | null;

export interface IInlineFeedBackResultItem {
  level?: InlineFeedBackResultLevel;
  message?: string;
}

interface IProps {
  result?: IInlineFeedBackResultItem
}

export default (props: IProps) => {

  if (!props.result) {
    return <></>;
  }

  const resultClass = props.result.level === "Success" ? "fa-check-circle" : "fa-exclamation-circle";
  const icon = props.result.level != null ? <i className={`fa inline-feedback-icon ${resultClass}`} /> : <CircularProgress className="inline-feedback-icon" size={14} />;
  const containerClass = props.result.level === "Success" ? "inline-feedback-success"
    : props.result.level === "Problem" ? "inline-feedback-problem"
    : "";

  return (<span className={"inline-feedback " + containerClass}>
    {icon}
    {props.result.message}
  </span>);
}