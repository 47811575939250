import * as React  from "react"; 
import { Application,ApplicationStatus } from "../../../../models/ApplicationModels";
import { Grid } from "@material-ui/core"; 
import "../Applications.scss";

interface IProps {
  appDetails: Application;
}

type Props = IProps;

const View = (props: Props) => {  
    const { appDetails} = props;
    return (
      <div className="app-details">
        <div className="app-info">
          <Grid container>
            <Grid item md={8} xs={12}>
              <div className="app-info-row">
                <strong>App Type Name</strong> {appDetails.typeName}
              </div>
              <div className="app-info-row">
                <strong>Instance Name</strong> {appDetails.instanceName}
              </div>
              <div className="app-info-row">
                <strong>URL</strong> {appDetails.url ? appDetails.url : " - "}
              </div>
            </Grid>
            <Grid item md={4} xs={12}>
              <div className="app-info-row">
                <strong>SSO Enabled</strong> {appDetails.ssoEnabled ? "Yes" : "No"}
              </div>
              <div className="app-info-row">
                <strong>Epiq Admin Only</strong> {appDetails.epiqOnly ? "Yes" : "No"}
              </div>
              <div className="app-info-row">
                <strong>Deactivated</strong> {appDetails.statusId === ApplicationStatus.deactivated ? "Yes" : "No"}
              </div>
            </Grid>           
            <Grid item md={12} xs={12}>
              <div className="app-info-row">
                <strong>Image URL</strong> {appDetails.imageUrl ? appDetails.imageUrl : " - "}
              </div>
              <div className="app-info-row">
                <strong>Startup URL</strong> {appDetails.startupUrl ? appDetails.startupUrl : " - "}
              </div>
              <div className="app-info-row">
                <strong>SSO Application ID</strong> {appDetails.ssoApplicationId ? appDetails.ssoApplicationId : " - "}
              </div>
              <div className="app-info-row">
                <strong>IDP Group ID</strong> {appDetails.idpGroupId ? appDetails.idpGroupId : " - "}
              </div>
             <div className="app-info-row">
                <strong>Description</strong> {appDetails.description ? appDetails.description : " - "}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );  
}

export default View;
