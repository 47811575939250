import { IFieldData, IFieldValueLookup, IWizardDependencyCondition, IWizardValues, WizardFieldTypes } from "../../../models/Wizard";

export default class WizardHelper {

  static defaultLookupRegex = /\[`fieldLookup:(.*?)``lookupName:([^`]*?)`(?:`defaultStr:([^`]*)`)?]/g;

  public static getLabel = function (label: string | ((existingFieldVals: IFieldValueLookup) => string), existingFieldVals: IFieldValueLookup, defaultLabel: string = "", lookupRegex: RegExp = WizardHelper.defaultLookupRegex) {

    if (typeof label === 'function') {
      return label(existingFieldVals);
    }

    if (!label || label.length === 0) return defaultLabel;
    return label.replace(lookupRegex, function(match, fieldIds, lookupName, defaultStr) {
      let valInfo = null as IWizardValues;
      const allIds = fieldIds.split(",");

      for (let i = allIds.length - 1; i > -1; i--) {
        valInfo = existingFieldVals[allIds[i]];
        if (valInfo && valInfo.active) {
          break;
        }
      }
      if (!valInfo || !valInfo.lookupValues) {
        return defaultStr || defaultLabel;
      }
      if (!valInfo.lookupValues && defaultStr) return defaultStr;

      const val = valInfo.lookupValues[lookupName];

      return val == null ? defaultStr || defaultLabel : val.toString();
    });
  };

  public static isFieldActive = function(preconfiguredFieldData: IFieldData, valDictionary: IFieldValueLookup) {
    const valueInfo = valDictionary[preconfiguredFieldData.fieldId];

    if (valueInfo) return valueInfo.active; // this is a dynamicly changed value as opposed to statically predfined values

    return preconfiguredFieldData.active;
  };

  public static isConditionValidWLookup(valDictionary: IFieldValueLookup, condition: IWizardDependencyCondition) {
    const value = valDictionary[condition.fieldId] ? valDictionary[condition.fieldId].value : null;

    return WizardHelper.isConditionValid(value, condition);
  }

  public static isConditionValid(
    value: WizardFieldTypes,
    condition: IWizardDependencyCondition,
    insertedConditionValue?: WizardFieldTypes
  ) {
    const conditionalValue = insertedConditionValue && insertedConditionValue !== undefined ? insertedConditionValue : condition.value;

    switch (condition.compare) {
      case "eq":
        return conditionalValue === value;
      case "neq":
        return conditionalValue !== value;
      case "gt":
        return conditionalValue > value;
      case "gte":
        return conditionalValue >= value;
      case "lt":
        return conditionalValue < value;
      case "lte":
        return conditionalValue <= value;
      case "falsy":
        return conditionalValue === !value;
      case "hascontent":
        return conditionalValue === (Array.isArray(value) ? (value as Array<any>).length > 0 : !!value);
      case "contains":
        return value == null
          ? false
          : Array.isArray(value)
            ? (value as Array<any>).indexOf(conditionalValue) > -1
            : conditionalValue.toString().includes(value.toString());
      case "regexp":
        const regex = new RegExp(condition.regExp);
        return regex.test(conditionalValue.toString());
      default:
        console.error("Unhandled condition: " + condition.compare);
        return false;
    }
  };
}