import { FilterDescriptor } from "@progress/kendo-data-query";
import { GridCellProps, GridColumnProps } from "@progress/kendo-react-grid";
import React, { useEffect, useState } from "react";
import { EmployerSelectable } from "../../../models/EmployerModel";
import AdminService from "../../../services/AdminService";
import { AdvancedGrid, IGridParams, IGridStateMerged } from "../../common/grid/AdvancedGrid";
import { connect } from "react-redux";
import * as SessionStore from "../../../store/Session";
import { IApplicationState } from "../../../store";
import "./Employers.scss";
import CommandCell from "../../common/grid/columns/CommandCell";
import { Tooltip } from "@progress/kendo-react-tooltip";
import "./EmployerListWithDomains.scss";

type IProps = {
  selectedEmployers?: EmployerSelectable[];
  enableSelection?: boolean;
  selectionChange?: (selected: EmployerSelectable[]) => void;
}

type Props = IProps & SessionStore.ISessionState;

const defaultAppState = { items: [], merged: [], count: 0, selections: {} } as IGridStateMerged<EmployerSelectable>;

const defaultDataState: IGridParams = {
  skip: 0,
  take: 100,
  sort: [{ field: "name", dir: "asc" }],
  filter: { logic: "and", filters: [{ field: "name", operator: "contains", value: "" }] },
};

let myGrid: AdvancedGrid<EmployerSelectable, IGridParams>;

const EmployersListWithDomains = (props: Props) => {
  const [employers, setEmployers] = useState(defaultAppState);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [gridDataState, setGridDataState] = useState(defaultDataState);

  useEffect(() => {
    const updated = { ...employers }

    updated.selections = getSelectionRecords();

    for (let i = 0; i < updated.items.length; i++) {
      updated.items[i].selected = false;
    }
    setEmployers(updated);
    setInitialLoad(false);
  }, []);

  const getSelectionRecords = () => {
    if (props.selectedEmployers && props.selectedEmployers.length > 0) {
      let selectedRecords: Record<number | string, EmployerSelectable> = {}
      for (let item of props.selectedEmployers) {
        selectedRecords[item.id] = item;
      }
      return selectedRecords;
    }
    return {};
  }

  const updateMergedState = (emplrs: EmployerSelectable[], preSelected: Record<number | string, EmployerSelectable>) => {

    if (!emplrs) {
      emplrs = [];
    }

    for (let i = 0; i < emplrs.length; i++) {
      if (preSelected[emplrs[i].id]) {
        emplrs[i].selected = true;
      }
    }

    setEmployers({ items: emplrs, merged: emplrs, count: emplrs.length, selections: preSelected });
  }


  const fetchEmployers = async (dataState: IGridParams) => {
    setIsLoading(true);

    var filters = dataState.filters ? dataState.filters.map(compositeFilter => {

      const nameFilter: FilterDescriptor = compositeFilter.filters.find(
        filter => (filter as FilterDescriptor).field === "name"
      ) as FilterDescriptor;
      console.log(nameFilter);
      if (nameFilter) {
        const nameFilterValue = nameFilter.value || "";

        return {
          ...compositeFilter,
          filters: [
            {
              ...nameFilter,
              field: "name",
              operator: "contains",
              value: nameFilterValue
            }
          ]
        };
      }

      const numericFilter: FilterDescriptor = compositeFilter.filters.find(
        filter => (filter as FilterDescriptor).field === "id" || (filter as FilterDescriptor).field === "totalDomainCount"
      ) as FilterDescriptor;

      if (numericFilter) {
        const isFilterValueString = numericFilter.value && typeof (numericFilter.value) === "string"

        if (isFilterValueString) {
          return {
            ...compositeFilter,
            filters: [
              {
                ...numericFilter,
                value: -1
              }
            ]
          };
        }
        else if (isFilterValueString === null) {
          return {
            ...compositeFilter,
            filters: [
              {
                ...numericFilter,
                operator: "gte",
                value: 0
              }
            ]
          };
        }

      }

      return compositeFilter;
    }) : [];

    const result = await AdminService.getEmployerList({ ...dataState, filters: filters }, true);

    if (result.ok) {
      const preSelected = initialLoad ? getSelectionRecords() : employers.selections;
      updateMergedState(result.data.results, preSelected);
      setHasError(false);
      setGridDataState(dataState);
    } else {
      setHasError(true);
      console.log("Something went wrong while fetching employers.");
    }

    setIsLoading(false);

  }

  const employerNameCell = (props: GridCellProps) => {
    const name = props.dataItem.name;
    return (
      <td>
        <em className="employer-name" title={name}>{name}</em>
      </td>
    );
  }

  const employerDomainCell = (props: GridCellProps) => {
    const domain = props.dataItem.employerDomain ? props.dataItem.employerDomain : "";
    return (
      <td>
        <em className="domain-names" title={domain}>{domain}</em>
      </td>
    );
  }

  const employerDomainCountNoLink = (props: GridCellProps) => {
    const domainCount = props.dataItem.totalDomainCount ? props.dataItem.totalDomainCount : 0;
    return (
      <td>
        {domainCount}
      </td>
    );
  }


  const columns = new Array<GridColumnProps>(
    { field: "name", title: "EMPLOYER NAME", cell: employerNameCell, filterable: true, sortable: true, filter: "text" },
    { field: "employerDomain", title: "EMPLOYER DOMAIN", cell: employerDomainCell, filterable: true, filter: "text" },
    { field: "totalDomainCount", title: "TOTAL DOMAINS", cell: employerDomainCountNoLink, filterable: true, sortable: true, filter: "numeric" },
    { field: "id", title: "EMPLOYER ID", filterable: true, sortable: true, filter: "numeric" }
  );

  const selectRow = (dataItem: EmployerSelectable) => {
    const changes = { ...employers };
    for (let i = 0; i < changes.items.length; i++) {
      if (dataItem.id === changes.items[i].id) {
        changes.items[i].selected = !changes.items[i].selected;
        if (!changes.items[i].selected) {
          delete changes.selections[changes.items[i].id];
        } else {
          changes.selections[changes.items[i].id] = changes.items[i];
        }
      }
    }

    setEmployers(changes);
    props.selectionChange && props.selectionChange(Object.keys(changes.selections).map(e => changes.selections[e]));
  };


  if (props.enableSelection) {
    columns.unshift({
      title: "",
      cell: CommandCell({
        onSelection: selectRow,
        selectedField: "selected"
      }),
      sortable: false,
      headerClassName: "no-sort"
    });
  }

  const filterElements = (element: any) => {
    if (element.tagName === "EM") {
      return true;
    }
    return false;
  };

  return (
    <div className="employerlist-alldomains-admin-wrapper">
      <Tooltip openDelay={2} position="right" filter={filterElements}>
        <AdvancedGrid
          ref={(advancedGridInstance: AdvancedGrid<EmployerSelectable, IGridParams>) => {
            myGrid = advancedGridInstance;
          }}
          showErrorState={hasError}
          showLoadingIndicator={isLoading}
          sortable={{ mode: "multiple" }}
          data={employers.merged}
          dataFetch={fetchEmployers}
          dataState={gridDataState}
          columns={columns}
          paging={false}
          noRecordsRender={<p>No employers found.</p>}
          noLoadOnMount={props.enableSelection ? false : true}
          filteredDataOnly={<p>Please filter to search for employers.</p>}
          multiFieldFilterDelimiter="|"
          filterOperators={{
            text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
            numeric: [{ text: "grid.filterEqOperator", operator: "eq" }]
          }}
          moreRecordsAvailableMessage="Too many employers to display. Adjust filters to refine results"
        />
      </Tooltip>
    </div>
  );
};

const mapStateToProps = function (state: IApplicationState, ownProps: IProps) {
  return { ...ownProps, ...state.sessionState };
}

export default connect(mapStateToProps, {})(EmployersListWithDomains as any);