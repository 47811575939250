import { Action, Reducer } from "redux";
import { AppThunkAction, AppThunkActionPromise } from "./";
import { UpsertUserGroupModel, UserGroup } from "../models/UserModels";
import AdminService from "../services/AdminService";
import NotificationService from "../services/NotificationService";
import GridParams from "../components/common/GridHelper";
import CommonHelper from "../components/common/utilities/CommonHelper";
import UserGroupDetails from "../components/administration/user/usergroups/UserGroupDetails";

// STATE - This defines the type of data maintained in the Redux store.

export interface IUserGroupState {
  isLoading: boolean;
  userGroups: UserGroup[];
  totalUserGroupsCount: number;
  searchText?: string;
  hasError: boolean;
  isGroupAlreadyExists: boolean;
  userGroupDetails: UserGroup;
  isInvalid:boolean;
}

interface CreateUserGroupRequestAction {
  type: "CREATE_USER_GROUP_REQUEST";
}

interface CreateUserGroupResponseAction {
  type: "CREATE_USER_GROUP_RESPONSE";
  isGroupAlreadyExists: boolean;
  isInvalid: boolean;
}

interface CreateUserGroupFailureAction {
  type: "CREATE_USER_GROUP_REQUEST_FAILURE";
  hasError?: boolean;
}

interface GetUserGroupRequestAction {
  type: "GET_USER_GROUP_REQUEST";
}

interface GetUserGroupResponseAction {
  type: "GET_USER_GROUP_RESPONSE";
  userGroups: UserGroup[];
  totalUserGroupsCount: 0;
}

interface GetUserGroupRequestFailureAction {
  type: "GET_USER_GROUP_REQUEST_FAILURE";
  hasError?: boolean;
}

interface GetUpdateUserGroupRequestAction {
  type: "GET_UPDATE_USER_GROUP_DETAILS_REQUEST";
}

interface UpdateUserGroupResponsetAction {
  type: "UPDATE_USER_GROUP_DETAILS_RESPONSE";
  isGroupAlreadyExists: boolean;
  isInvalid: boolean;
}

interface UpdateUserGroupFailureAction {
  type: "UPDATE_USER_GROUP_DETAILS_FAILURE";
  hasError?: boolean;
}

interface GetUserGroupDetailsRequestAction {
  type: "GET_USER_GROUP_DETAILS_REQUEST";
}

interface GetUserGroupDetailsResponseAction {
  type: "GET_USER_GROUP_DETAILS_RESPONSE";
  userGroupDetails: UserGroup;
}

interface GetUserGroupDetailsFailureAction {
  type: "GET_USERGROUP_DETAILS_REQUEST_FAILURE";
  hasError?: boolean;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
  | CreateUserGroupRequestAction
  | CreateUserGroupResponseAction
  | GetUserGroupRequestAction
  | GetUserGroupRequestFailureAction
  | GetUserGroupResponseAction
  | CreateUserGroupFailureAction
  | GetUpdateUserGroupRequestAction
  | UpdateUserGroupResponsetAction
  | UpdateUserGroupFailureAction
  | GetUserGroupDetailsRequestAction
  | GetUserGroupDetailsResponseAction
  | GetUserGroupDetailsFailureAction;

// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  createUserGroup: (userGroup: UpsertUserGroupModel): AppThunkActionPromise<KnownAction> => async (dispatch, getState) => {
    dispatch({ type: "CREATE_USER_GROUP_REQUEST" });

    console.log("about to create internal usergroup...");

    const createUserGroupResult = await AdminService.createUserGroup(userGroup);

    if (!createUserGroupResult.ok) {
      console.log("Error creating usergroup in Admin Service.");
      NotificationService.showErrorToast("Something went wrong.  User Group was not created.");
      dispatch({
        type: "CREATE_USER_GROUP_REQUEST_FAILURE",
        hasError: true
      });
    } else if (createUserGroupResult.data.inValid) {
      dispatch({
        type: "CREATE_USER_GROUP_RESPONSE",
        isGroupAlreadyExists:createUserGroupResult.data.message.toLowerCase() === "usergroup already exists.",
        isInvalid:createUserGroupResult.data.inValid
      });
      NotificationService.showErrorToast(createUserGroupResult.data.message);
    } else {
      NotificationService.showSuccessToast(`${userGroup.groupName} successfully created.`);
      dispatch({
        type: "CREATE_USER_GROUP_RESPONSE",
        isGroupAlreadyExists:false,
        isInvalid:createUserGroupResult.data.inValid
      });
    }
  },

  getUserGroups: (gridParams: GridParams): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    dispatch({ type: "GET_USER_GROUP_REQUEST" });
    const appState = getState();
    if (appState) {
      const result = await AdminService.getUserGroups(gridParams);
      if (result.ok) {
        dispatch({
          type: "GET_USER_GROUP_RESPONSE",
          userGroups: result.data.results,
          totalUserGroupsCount: result.data.count
        });
      } else {
        dispatch({
          type: "GET_USER_GROUP_REQUEST_FAILURE",
          hasError: true
        });
      }
    }
  },

  updateUserGroup: (userGroup: UpsertUserGroupModel): AppThunkActionPromise<KnownAction> => async (dispatch, getState) => {
    dispatch({ type: "GET_UPDATE_USER_GROUP_DETAILS_REQUEST" });
    console.log("about to update internal usergroup...");
    const appState = getState();

    if (appState) {
      const result = await AdminService.updateUserGroup(userGroup);
 
      if (!result.ok) {
        dispatch({
          type: "UPDATE_USER_GROUP_DETAILS_FAILURE",
          hasError:true
        });
        NotificationService.showErrorToast("Something went wrong. Update not saved.");
      } else if (result.data.inValid) {
        dispatch({
          type: "UPDATE_USER_GROUP_DETAILS_RESPONSE",
          isGroupAlreadyExists:result.data.message.toLowerCase() === "usergroup already exists.",
          isInvalid:result.data.inValid
        });
        NotificationService.showErrorToast(result.data.message);
      } else {
        dispatch({
          type: "UPDATE_USER_GROUP_DETAILS_RESPONSE",
          isGroupAlreadyExists:false,
          isInvalid:result.data.inValid
        });
        NotificationService.showSuccessToast(`Changes to user group ${userGroup.groupName} have been saved.`);
      }
    }
  },

  getUserGroupDetails: (groupId: number): AppThunkActionPromise<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    dispatch({ type: "GET_USER_GROUP_DETAILS_REQUEST" });
    if (appState) {
      const result = await AdminService.getUserGroupDetails(groupId);
      if (result.ok) {
        dispatch({
          type: "GET_USER_GROUP_DETAILS_RESPONSE",
          userGroupDetails: result.data
        });
      } else {
        dispatch({
          type: "GET_USERGROUP_DETAILS_REQUEST_FAILURE",
          hasError: true
        });
      }
    }
  },

};

// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: IUserGroupState = {
  isLoading: false,
  userGroups: [],
  totalUserGroupsCount: 0,
  searchText: undefined,
  hasError: false,
  isGroupAlreadyExists: false,
  userGroupDetails: new UserGroup(),
  isInvalid:false
};

export const reducer: Reducer<IUserGroupState> = (
  state: IUserGroupState | undefined,
  incomingAction: Action
): IUserGroupState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "CREATE_USER_GROUP_REQUEST":
      return {
        ...state,
        isLoading: true,
        isGroupAlreadyExists: false
      };
    case "CREATE_USER_GROUP_RESPONSE":
      return {
        ...state,
        isLoading: false,
        isGroupAlreadyExists: action.isGroupAlreadyExists,
        isInvalid: action.isInvalid
      };

    case "CREATE_USER_GROUP_REQUEST_FAILURE":
      return {
        ...state,
        isLoading: false,
        isGroupAlreadyExists: false,
        hasError: true
      };
    case "GET_USER_GROUP_REQUEST":
      return {
        ...state,
        isLoading: true,
        hasError: false
      };
    case "GET_USER_GROUP_RESPONSE":
      return {
        ...state,
        userGroups: action.userGroups,
        isLoading: false,
        hasError: false,
        totalUserGroupsCount: action.totalUserGroupsCount
      };
    case "GET_USER_GROUP_REQUEST_FAILURE":
      return {
        ...state,
        userGroups: state.userGroups,
        isLoading: false,
        hasError: true,
        totalUserGroupsCount: 0
      };
    case "GET_UPDATE_USER_GROUP_DETAILS_REQUEST":
      return {
        ...state,
        userGroups: state.userGroups,
        isLoading: false,
        hasError: false,
        totalUserGroupsCount: 0,
        userGroupDetails: state.userGroupDetails ? state.userGroupDetails : new UserGroup()
      };
    case "UPDATE_USER_GROUP_DETAILS_RESPONSE":
      return {
        ...state,
        isLoading: false,
        hasError: false,
        isGroupAlreadyExists: action.isGroupAlreadyExists,
        isInvalid: action.isInvalid
      };
    case "UPDATE_USER_GROUP_DETAILS_FAILURE":
      return {
        ...state,
        isLoading: false,
        hasError: true,
        isGroupAlreadyExists: false
      };
    case "GET_USER_GROUP_DETAILS_REQUEST":
      return {
        ...state,
        userGroups: state.userGroups,
        isLoading: true,
        hasError: false,
        totalUserGroupsCount: 0,
        userGroupDetails: state.userGroupDetails ? state.userGroupDetails : new UserGroup()
      };

    case "GET_USER_GROUP_DETAILS_RESPONSE":
      return {
        ...state,
        userGroups: state.userGroups,
        isLoading: false,
        hasError: false,
        totalUserGroupsCount: 0,
        userGroupDetails: action.userGroupDetails
      };

    default: {
      return state;
    }
  }

  return state;

};
