import React, { useState, useEffect } from "react";
import {
  MultiSelect,
  MultiSelectChangeEvent,
  MultiSelectFilterChangeEvent,
  ListItemProps
} from "@progress/kendo-react-dropdowns";
import AdminService from "../../../services/AdminService";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import { TagData } from "@progress/kendo-react-dropdowns/dist/npm/MultiSelect/TagList";
import NotificationService from "../../../services/NotificationService";
import Tooltip from "@material-ui/core/Tooltip";
import CommonHelper from "../../common/utilities/CommonHelper";
import { UserGroup } from "../../../models/UserModels";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import { IGridParams } from "../../common/grid/AdvancedGrid";
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import InfoIcon from "@material-ui/icons/Info";
import { Popover } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";
import CloseIcon from "@material-ui/icons/Close";
import bgMessages from '../../../Localization/en.json';
loadMessages(bgMessages, 'en-US');

type Props = {
  userId?: number;
  typeName?: string;
  onAddUpdateUserGroups?: (selected: UserGroup[]) => void;
  selectedUserGroups: UserGroup[];
  userName?: string;
  isEpiqUser: boolean;
  domain?: string;
};

const excludeEpiqOnlyUserGroupsFilter: Array<CompositeFilterDescriptor> = [
  {
    logic: "or",
    filters: [{ field: "epiqonly", operator: "eq", value: false }
    ] as Array<FilterDescriptor>
  }
]

const AssignUserGroupsToUser = (props: Props) => {
  const [userGroups, setUserGroups] = useState<UserGroup[]>([]);
  const [selectedUserGroups, setSelectedUserGroups] = useState<UserGroup[]>([]);
  const [isLodaing, setIsLoading] = useState(false);
  const [openDropDown, SetOpenDropDown] = useState(false);
  const anchor = React.useRef<MultiSelect>(null);
  const [show, setShow] = React.useState(false);
  const [resultInfo, setResultInfo] = useState<string>(null);

  useEffect(() => { }, [userGroups]);

  const handleChange = async (event: MultiSelectChangeEvent) => {
    const values = event.target.value;

    if (values.length > 0) {
      const lastItem = values[values.length - 1];
      const filteredUserGroups = userGroups.filter(item => item !== lastItem);
      setUserGroups(filteredUserGroups);
      setSelectedUserGroups(values);
    }
    else {
      setUserGroups([]);
      setSelectedUserGroups([]);
    }

    props.onAddUpdateUserGroups(values.length > 0 ? values : []);
    SetOpenDropDown(values.length > 0);
  };

  const itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = usersBackground(itemProps.dataItem);
    return React.cloneElement(li, li.props, itemChildren);
  };

  const tagRender = (tagData: TagData, li: React.ReactElement<HTMLLIElement>) =>
    React.cloneElement(li, li.props, [
      <Tooltip
        key={"addusergrouptooltip" + tagData.data[0].id}
        title={tagData.data[0].groupName}
        placement="top-start"
        arrow
        className="email-tool-tip"
      >
        <span key={"addgroupname" + tagData.data[0].id} id={tagData.data[0].id}>
          {" "}
          {tagData.data[0].groupName}{" "}
        </span>
      </Tooltip>,

      li.props.children[1]
    ]);

  let UserName = props.userName ? props.userName : "<UserName>";

  const filterChange = (event: MultiSelectFilterChangeEvent) => {
    let searchValue = event.filter.value;

    if (!CommonHelper.isNullOrWhiteSpace(searchValue) && searchValue.length >= 3) {
      getUserGroups(setUserGroups, searchValue.trim());
    }

    if (searchValue.length === 0) {
      SetOpenDropDown(false);
      setUserGroups([]);
    }
  };

  const removeSelectedUserGroups = (searchResults: any, selectedUserGroups: UserGroup[]) => {
    for (let i = searchResults.length - 1; i >= 0; i--) {
      for (let j = 0; j < selectedUserGroups.length; j++) {
        if (searchResults[i] && searchResults[i].groupId === selectedUserGroups[j].groupId) {
          searchResults.splice(i, 1);
        }
      }
    }
    return searchResults;
  };

  const getUserGroups = async (setUserGroups: React.Dispatch<React.SetStateAction<UserGroup[]>>, values: any) => {
    setIsLoading(true);

    try {
      const result = await AdminService.getUserGroupList(getFilters(values as string), props.domain);

      if (result.ok) {
        let searchResults = result.data.results;

        let selectedUserGroupsList = selectedUserGroups && selectedUserGroups.length > 0 ? selectedUserGroups : new Array<UserGroup>(); // Selected usergroups in the popup
        selectedUserGroupsList = props.selectedUserGroups && props.selectedUserGroups.length > 0 ? selectedUserGroupsList.concat(props.selectedUserGroups) : selectedUserGroupsList; // Concatenate already selected usergroups from the grid
        searchResults = removeSelectedUserGroups(searchResults, selectedUserGroupsList); // remove already selected usergroups from search results.
        setUserGroups(searchResults);
        setResultInfo(result.data.resultInfo)
      }

      setIsLoading(false);
      SetOpenDropDown(true);
    } catch (e) {
      setUserGroups([]);
      NotificationService.showErrorToast("Something went wrong while getting users.");
    } finally {
      setIsLoading(false);
    }
  };

  const getFilters = (searchTex: string) => {
    const filters: Array<CompositeFilterDescriptor> = [
      {
        logic: "and",
        filters: [{ field: "groupName", operator: "contains", value: searchTex }
        ] as Array<FilterDescriptor>
      }
    ];

    return {
      skip: 0,
      take: 100,
      sort: [{ field: "groupName", dir: "asc" }],
      filters: !props.isEpiqUser ? [...filters, ...excludeEpiqOnlyUserGroupsFilter] : filters
    } as IGridParams
  }

  const focusedItemIndex = (data: any, inputText: string, textField?: string) => {
    let text = inputText.toLowerCase();
    const index = data.findIndex((item: any) =>
      String(textField ? item[textField] : item)
        .toLowerCase()
        .includes(text.trim())
    );

    return index > -1
      ? index
      : data.findIndex((item: any) =>
        String(textField ? item["groupName"] : item)
          .toLowerCase()
          .includes(text.trim())
      );
  };

  const usersBackground = (dataItem: any) => {
    const groupName = dataItem.groupName;
    const isEpiqOnly = dataItem.epiqOnly ? "Epiq Admin Only" : "Non-Epiq Admin";
    return (
      <div key={dataItem.id} className="dd-item-details">
        <Grid container>
          <Grid item md={9} sm={9} className="user-name-wrapper">
            <div className="dd-item-name">
              <em title={groupName}>{groupName}</em>
            </div>
            <p className="last-login">
              {" "}
              <em title={isEpiqOnly}>{isEpiqOnly}</em>{" "}
            </p>
          </Grid>
        </Grid>
      </div>
    );
  };

  const listNoDataRender = (element: React.ReactElement<HTMLDivElement>) => {
    const noData = (
      <div className="model-popup-norecords-message">
        {resultInfo}
      </div>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  }

  return (
    <div>
      <Grid container className="user-create">
        <Grid item xs={12}>
          <Grid item xs={12}>
            <div className="user-add-user-group">
              <div className="user-group-name"> Search and add user groups to <strong>{UserName}.</strong>{" "}</div>
              <span>Displays user groups that allow this user's email domain.</span>
              <span>
                <Button className="info-btn" onClick={() => setShow(!show)}>
                  <span><InfoIcon htmlColor="#27AAE1" fontSize="small" /></span>
                </Button>
              </span>
            </div>
            <div className="popover-user-group">
              <Popover
                show={show}
                anchor={anchor.current && anchor.current.element}
                position={"left"}
              >
                <div className="popover">
                  <div className="xmark"><CloseIcon htmlColor="#406FD9" onClick={() => setShow(!show)} /></div>
                  <div className="next-step"><b>Allowed Domains</b></div>
                  <div className="pop-click">If your search doesn’t return expected user groups, verify that those groups allow this user’s email domain.</div>
                  <div className="pop-button" onClick={() => setShow(!show)}>Got It</div>
                </div>
              </Popover>
            </div>
          </Grid>
          <div className="multi-select-wrapper">
            <span className="search-wrapper">
              <SearchIcon />
            </span>
            <LocalizationProvider language="en-US">
              <IntlProvider locale="en" >
                <MultiSelect
                  ref={anchor}
                  data={userGroups}
                  onChange={handleChange}
                  filterable={true}
                  itemRender={(li, itemprops) => itemRender(li, itemprops)}
                  onFilterChange={e => filterChange(e)}
                  listNoDataRender={listNoDataRender}
                  loading={isLodaing}
                  textField="groupName"
                  dataItemKey="groupId"
                  placeholder="Search"
                  tagRender={(tagData, li) => tagRender(tagData, li)}
                  opened={(userGroups.length > 0 && openDropDown) || (resultInfo != null && openDropDown)}
                  onBlur={() => {
                    SetOpenDropDown(false);
                    setResultInfo(null);
                  }}
                  onFocus={() => {
                    SetOpenDropDown(true);
                  }}
                  focusedItemIndex={focusedItemIndex}
                  popupSettings={{
                    height: '45vh'
                  }}
                />
              </IntlProvider>
            </LocalizationProvider>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AssignUserGroupsToUser;
