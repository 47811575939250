import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import NotificationService from "../../../services/NotificationService";
import AnalyticsService from "../../../services/AnalyticsService";
import * as SessionStore from "../../../store/Session";
import { UpsertReportModel } from "../../../models/ReportModels";
import { GroupInfo, Report as ReportModel, IPbiDisplay, StatusInfo } from "../../../models/ReportModels";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditGrid, { ChangeErrorEvent, IGridEditItem } from "../../common/grid/EditGrid";
import { IGridParams, AdvancedGrid } from "../../common/grid/AdvancedGrid";
import { GridCellProps, GridFilterCellProps } from "@progress/kendo-react-grid";
import { DropDownFilter } from "../../common/grid/filters/DropDownFilter";
import { FilterDescriptor } from "@progress/kendo-data-query";
import { ServiceResponseJson } from "../../../services/ServiceBase";
import { Button } from "@material-ui/core";
import { Checkbox } from "@progress/kendo-react-inputs";
import PowerBiReportDropdown from "./PowerBiReportDropdown";
import { ComboBoxChangeEvent, DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { IApplicationState } from "../../../store";
import "./Report.scss";
import { ShowEpiqOnlyControls } from "../../../models/Enums";

type Props = SessionStore.ISessionState;

var dropDownList = new Array<IPbiDisplay>();

const Report = (props: Props) => {
  const [groupInfo, setGroupInfo] = useState(null as GroupInfo);
  const [jobStatusInfo, setJobStatusInfo] = useState(null as StatusInfo);
  const [loadingGroupInfo, setLoadingGroupInfo] = useState(false);
  const [refreshSent, setRefreshSent] = useState(false);
  const [infoFetched, setInfoFetched] = useState(false);
  const [disableDefaultField, setDisableDefaultField] = useState(false);
  const divJobStatusRef = useRef(null);
  const [noRecordsMsg, setnoRecordsMsg] = useState("No reports found.");
  const [disablePlusIcon, setDisablePlusIcon] = useState(false);
  const [gridParams, setGridParams] = useState({
    skip: 0,
    take: 100
  } as IGridParams);
  let timeout: any = null;

  const fetchReportsAsync = async (dataState: IGridParams) => {

    let isSelectedEpiqAdminOnly: boolean = false;
    const filters = dataState.filters
      ? dataState.filters.map(compositeFilter => {

        const epiqAdminOnlyFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "epiqOnly"
        ) as FilterDescriptor;

        if (epiqAdminOnlyFilter) {
          const epiqAdminOnlyFilterValue = epiqAdminOnlyFilter.value
            ? epiqAdminOnlyFilter.value.id == 0 || epiqAdminOnlyFilter.value.id == 1
              ? epiqAdminOnlyFilter.value.id == 0
                ? false
                : true
              : null
            : false;
          isSelectedEpiqAdminOnly = epiqAdminOnlyFilterValue;
          return {
            ...compositeFilter,
            filters: [
              {
                ...epiqAdminOnlyFilter,
                field: "epiqOnly",
                operator: "eq",
                value: epiqAdminOnlyFilterValue
              }
            ]
          };
        }

        return compositeFilter;
      })
      : [];

    if (isSelectedEpiqAdminOnly && !props.sessionData.permissions.has("ShowEpiqOnlyResource")) {
      setnoRecordsMsg('Action requires additional permissions.');

      return assignEmptyResponse();
    }
    else {
      setnoRecordsMsg('No reports found.');
    }

    return AnalyticsService.GetReports({ ...dataState, filters: filters });
  };

  const assignEmptyResponse = async () => {
    const promiseResponse = new Promise<ServiceResponseJson>((resolve, reject) => {
      resolve({ ok: true, data: [] });
    });

    return promiseResponse;
  };

  const fetchGroupInfoAsync = async () => {
    setLoadingGroupInfo(true);
    const result = await AnalyticsService.GetGroupInfo();

    if (result.ok) {
      setGroupInfo(result.data);
    } else {
      console.error("failed to get power bi group.");
    }

    setLoadingGroupInfo(false);
  };

  const getJobStatusInfo = async () => {
    const result = await AnalyticsService.getJobStatusInfo();
    if (result.ok) {
      setJobStatusInfo(result.data);
      setInfoFetched(true);

      if (divJobStatusRef && divJobStatusRef.current) {
        divJobStatusRef.current.scrollIntoView();
      }

    } else {
      console.error("Failed to Job Status Info.");
    }
  };

  const workspaceId = (props: GridCellProps) => {
    if (props.dataItem._inEdit) {

      return (
        <td>
          <DropDownList
            name="workspaceId"
            data={groupInfo.altWorkspaces}
            value={props.dataItem.workspaceId}
            onChange={(event: DropDownListChangeEvent) => {
              props.onChange({
                dataItem: { ...props.dataItem, workspaceId },
                dataIndex: props.dataIndex,
                field: "workspaceId",
                syntheticEvent: event.syntheticEvent,
                value: event.value
              });
            }}
          />
        </td>
      );
    }
    return <td>{props.dataItem.workspaceId}</td>;
  };

  const foreignId = (props: GridCellProps) => {
    if (props.dataItem._inEdit) {
      if ((dropDownList.length === 0 || dropDownList === null) && groupInfo) {
        groupInfo.dashboards.forEach((item: any) => {
          dropDownList.push(({ id: item.id, name: item.displayName, isDashboard: true, workspaceId: item.workspaceId } as any) as IPbiDisplay);
        });
        groupInfo.reports.forEach((item: any) => {
          dropDownList.push(({ id: item.id, name: item.name, isDashboard: false, workspaceId: item.workspaceId } as any) as IPbiDisplay);
        });
      }
      return (
        <td>
          <PowerBiReportDropdown
            reports={dropDownList}
            onChange={(report: IPbiDisplay, event: ComboBoxChangeEvent) => {
              const id = report ? report.id : null;
              disablePlusButton({ ...props.dataItem, foreignId: id });
              if (report) {
                props.onChange({
                  dataItem: { ...props.dataItem, foreignId: id, isDashboard: report.isDashboard, workspaceId: report.workspaceId },
                  dataIndex: props.dataIndex,
                  field: "foreignId",
                  syntheticEvent: event.syntheticEvent,
                  value: id
                });
                // Small hack to make it async as having two props.onChange was not assigning the guid values foreignId to the dataItem
                timeout = setTimeout(() => {
                  {
                    props.onChange({
                      dataItem: { ...props.dataItem, foreignId: id, isDashboard: report.isDashboard, workspaceId: report.workspaceId },
                      dataIndex: props.dataIndex,
                      field: "isDashBoard",
                      syntheticEvent: event.syntheticEvent,
                      value: report.isDashboard
                    });
                    setTimeout(() => {
                      {
                        props.onChange({
                          dataItem: { ...props.dataItem, foreignId: id, isDashboard: report.isDashboard, workspaceId: report.workspaceId },
                          dataIndex: props.dataIndex,
                          field: "workspaceId",
                          syntheticEvent: event.syntheticEvent,
                          value: report.workspaceId
                        });
                      }
                    }, 1);
                  }
                }, 1);
              }
              else {
                props.onChange({
                  dataItem: { ...props.dataItem, foreignId: id },
                  dataIndex: props.dataIndex,
                  field: "foreignId",
                  syntheticEvent: event.syntheticEvent,
                  value: id
                });
              }
            }}
            preselectedValue={props.dataItem.foreignId}
            onFilterChange={props.dataItem}
          />
        </td>
      );
    }
    return <td>{props.dataItem.foreignId}</td>;
  };

  const rowLevelSecurity = (props: GridCellProps) => {
    if (props.dataItem.rowLevelSecurity === undefined) {
      props.onChange({
        dataItem: { ...props.dataItem, rowLevelSecurity: true },
        dataIndex: props.dataIndex,
        field: "rowLevelSecurity",
        syntheticEvent: null,
        value: true
      });
    }
    if (props.dataItem._inEdit) {
      return (
        <td>
          <Checkbox
            name="rowLevelSecurity"
            defaultChecked={props.dataItem.rowLevelSecurity === undefined ? true : props.dataItem.rowLevelSecurity}
            onChange={e => {
              let updateItem = props.dataItem;
              let isDisable = (!e.value || props.dataItem.epiqOnly);
              updateItem.isDefault = props.dataItem.isDefault ? !isDisable : false;
              disableIsDefaultCheckBox(isDisable);
              props.onChange({
                dataItem: updateItem,
                dataIndex: props.dataIndex,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.value
              });
              disablePlusButton(props.dataItem);
            }}
          />
        </td>
      );
    }
    return (
      <td>
        <input
          type="checkbox"
          name="rowLevelSecurity"
          checked={props.dataItem.rowLevelSecurity === undefined ? true : props.dataItem.rowLevelSecurity}
          disabled={true}
        />
      </td>
    );
  };

  const isDefault = (cellProps: GridCellProps) => {

    if (cellProps.dataItem._inEdit) {
      setDisableDefaultField(!cellProps.dataItem.rowLevelSecurity || cellProps.dataItem.epiqOnly);
      return (
        <td>
          <Checkbox
            name="isDefault"
            defaultChecked={(cellProps.dataItem.isDefault === undefined ? false : cellProps.dataItem.isDefault)}
            onChange={e => {
              cellProps.onChange({
                dataItem: cellProps.dataItem,
                dataIndex: cellProps.dataIndex,
                field: cellProps.field,
                syntheticEvent: e.syntheticEvent,
                value: e.value
              });
              disablePlusButton(cellProps.dataItem);
            }}
            disabled={disableDefaultField}
          />
        </td>
      );
    }
    return (
      <td>
        <input
          type="checkbox"
          name="isDefault"
          checked={cellProps.dataItem.isDefault === undefined ? false : cellProps.dataItem.isDefault}
          disabled={true}
        />
      </td>
    );
  };

  const refreshAsNeeded = (cellProps: GridCellProps) => {
    if (cellProps.dataItem.refreshAsNeeded === undefined) {
      cellProps.onChange({
        dataItem: { ...cellProps.dataItem, refreshAsNeeded: true },
        dataIndex: cellProps.dataIndex,
        field: "refreshAsNeeded",
        syntheticEvent: null,
        value: true
      });
    }
    if (cellProps.dataItem._inEdit) {
      return (
        <td>
          <Checkbox
            name="refreshAsNeeded"
            defaultChecked={(cellProps.dataItem.refreshAsNeeded === undefined ? true : cellProps.dataItem.refreshAsNeeded)}
            onChange={e => {
              cellProps.onChange({
                dataItem: cellProps.dataItem,
                dataIndex: cellProps.dataIndex,
                field: cellProps.field,
                syntheticEvent: e.syntheticEvent,
                value: e.value
              });
              disablePlusButton(cellProps.dataItem);
            }}
          />
        </td>
      );
    }
    return (
      <td>
        <input
          type="checkbox"
          name="refreshAsNeeded"
          checked={cellProps.dataItem.refreshAsNeeded === undefined ? false : cellProps.dataItem.refreshAsNeeded}
          disabled={true}
        />
      </td>
    );
  };

  const epiqAdminOnly = (cellProps: GridCellProps) => {

    if (cellProps.dataItem._inEdit) {
      return (
        <td>
          <Checkbox
            name="epiqOnly"
            defaultChecked={cellProps.dataItem.epiqOnly === undefined ? false : cellProps.dataItem.epiqOnly}
            onChange={e => {
              let updateItem = cellProps.dataItem;
              let isDisable = (e.value || !cellProps.dataItem.rowLevelSecurity);
              updateItem.isDefault = cellProps.dataItem.isDefault ? !isDisable : false;
              disableIsDefaultCheckBox(isDisable);
              cellProps.onChange({
                dataItem: updateItem,
                dataIndex: cellProps.dataIndex,
                field: cellProps.field,
                syntheticEvent: e.syntheticEvent,
                value: e.value
              });
              disablePlusButton(cellProps.dataItem);
            }}
            disabled={(cellProps.dataItem.id != undefined || !props.sessionData.superAdmin) ? true : false}
          />
        </td>
      );
    }

    return (
      <td>
        <input
          type="checkbox"
          name="epiqOnly"
          checked={cellProps.dataItem.epiqOnly === undefined ? false : cellProps.dataItem.epiqOnly}
          disabled={true}
        />
      </td>
    );
  };

  const isDashboard = (props: GridCellProps) => {
    if (props.dataItem._inEdit) {
      return (
        <td>
          <Checkbox
            name="isDashboard"
            defaultChecked={props.dataItem.isDashboard}
            onChange={e => {
              props.onChange({
                dataItem: props.dataItem,
                dataIndex: props.dataIndex,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.value
              });
              disablePlusButton(props.dataItem);
            }}
          />
        </td>
      );
    }
    return (
      <td>
        <input type="checkbox" name="isDashboard" checked={props.dataItem.isDashboard} disabled={true} />
      </td>
    );
  };

  const reportCode = React.useMemo(() => (props: GridCellProps) => {
    if (!props.dataItem._inEdit) {
      return (
        <td>
          {props.dataItem.reportCode}
        </td>
      )
    }
    return (
      <td className="k-grid-edit-cell">
        <input type="text" name="reportCode" className="k-textbox" defaultValue={props.dataItem.reportCode}
          onChange={(e) => {
            props.onChange({
              dataItem: props.dataItem,
              dataIndex: props.dataIndex,
              field: props.field,
              syntheticEvent: null,
              value: e.target.value,
            });
            disablePlusButton({ ...props.dataItem, reportCode: e.target.value });
          }}

        />
      </td>
    );
  }, []);

  const reportName = React.useMemo(() => (props: GridCellProps) => {
    if (!props.dataItem._inEdit) {
      return (
        <td>
          {props.dataItem.reportName}
        </td>
      )
    }
    return (
      <td className="k-grid-edit-cell">
        <input type="text" name="reportName" className="k-textbox" defaultValue={props.dataItem.reportName}
          onChange={(e) => {
            props.onChange({
              dataItem: props.dataItem,
              dataIndex: props.dataIndex,
              field: props.field,
              syntheticEvent: null,
              value: e.target.value,
            });
            disablePlusButton({ ...props.dataItem, reportName: e.target.value });
          }}

        />
      </td>
    );
  }, []);

  const disableIsDefaultCheckBox = (disable: boolean) => {
    setDisableDefaultField(disable);
  }

  const disableSaveButton = (disable: boolean) => {
    setDisablePlusIcon(disable);
  }

  const disablePlusButton = (item: any) => {
    if (!item.reportName || !item.reportCode || !item.foreignId) {
      setDisablePlusIcon(true);
    } else {
      setDisablePlusIcon(false);
    }
  }

  const refreshDatasets = () => {
    AnalyticsService.RefreshAllDatasets();
    setRefreshSent(true);
    return setTimeout(function () {
      setRefreshSent(false);
    }, 10000);
  };

  useEffect(
    () => {
      if (!groupInfo) {
        fetchGroupInfoAsync();
      }
    },
    [refreshSent]
  );

  if (loadingGroupInfo) {
    return (
      <div className="spin-container">
        <CircularProgress />
      </div>
    );
  }

  const columnProps = [
    { field: "reportName", title: "Report Name", filterable: true, cell: reportName },
    { field: "reportCode", title: "Report Code", filterable: true, cell: reportCode }, //
    {
      field: "foreignId",
      title: "Power Bi ID",
      cell: foreignId,
      filterable: true
    },
    { field: "isDashboard", title: "Is Dashboard", cell: isDashboard, filterable: false },
    { field: "rowLevelSecurity", title: "Row Level Security", cell: rowLevelSecurity, filterable: false },
    { field: "isDefault", title: "Default", cell: isDefault, filterable: false },
    { field: "refreshAsNeeded", title: "Refresh As Needed", cell: refreshAsNeeded, filterable: false },
    {
      field: "epiqOnly", title: "Epiq Admin Only", cell: epiqAdminOnly, filterable: true,
      filterCell: (props: GridFilterCellProps) => (
        <DropDownFilter
          {...props}
          data={[{ name: "Epiq Admin", id: 1 }, { name: "Non-Epiq Admin", id: 0 }]}
          textField="name"
          defaultSelectedOption={null}
        />
      )
    },
    //{ field: "workspaceId", title: "Workspace", cell: workspaceId, filterable: false },  
  ];

  return (
    <div className="report-admin">
      <EditGrid
        dataState={gridParams}
        getItems={fetchReportsAsync}
        noRecordsRender={<p>{noRecordsMsg}</p>}
        filteredDataOnly={<p>Please filter to search for reports.</p>}
        updateDataItem={(item: IGridEditItem & UpsertReportModel) => AnalyticsService.UpdateReport<IGridEditItem & UpsertReportModel>(item)}
        createDataItem={(item: IGridEditItem & UpsertReportModel) => AnalyticsService.CreateReport<IGridEditItem & UpsertReportModel>(item)}
        deleteItem={(id: number) => AnalyticsService.DeleteReport(id)}
        hideDeleteIcon={!props.sessionData.permissions.has("EpiqAdminDeleteReport")}
        hideUpdateIcon={!props.sessionData.permissions.has("EpiqAdminUpdateReport")}
        columns={columnProps}
        gridToolbar={
          props.sessionData.permissions.has("EpiqAdminCreateReport")
            ? {
              addButtonText: "REPORT",
              cancelButtonText: "Cancel",
              totalRecordsText: "Reports",
              btnClass: "btn-secondary"
            }
            : false
        }
        multiFieldFilterDelimiter="|"
        disablePlusIcon={disablePlusIcon}
        updateDisablePlusIcon={(disable: boolean) => disableSaveButton(disable)}
        errorCreatingItem={(item: ChangeErrorEvent) => {
          NotificationService.showErrorToast(item.message);
        }}
        errorUpdatingItem={(item: ChangeErrorEvent) => {
          NotificationService.showErrorToast(item.message);
        }}
      />
      {(props.sessionData.permissions.has("EpiqAdminCreateReport") ||
        props.sessionData.permissions.has("EpiqAdminUpdateReport")) && (
          <Button variant="contained" onClick={refreshDatasets}>
            Refresh Datasets
          </Button>
        )}
      {refreshSent && (
        <>
          <span>
            Request sent for data refresh. This may take a few minutes, check the power bi dataset to verify that it's
            done.
          </span>
          <br />
        </>
      )}
      {props.sessionData.permissions.has("EpiqAdminUpdateReport") && (
        <Button variant="contained" onClick={getJobStatusInfo}>
          Get Job Status Info
        </Button>
      )}
      {infoFetched &&
        jobStatusInfo && jobStatusInfo.jobSummary && (
          <div ref={divJobStatusRef} className="job-status-info">
            <span>This information is for developer use. Please copy and paste the information below when creating a ticket for data refresh issue.</span>
            <span>
              <ul>
                <li>DirtyEditDate: {jobStatusInfo.dirtyEditDate}</li>
                <li>LastRun: {jobStatusInfo.jobSummary.lastRun}</li>
                <li>EditTimeStampForLastRun: {jobStatusInfo.jobSummary.editTimeStampForLastRun}</li>
                <li>LastRunIsSuccess: {jobStatusInfo.jobSummary.lastRunIsSuccess === true ? "Yes" : "No"}</li>
                <li>NumberAttempted: {jobStatusInfo.jobSummary.lastRunJobSummary.numberAttempted}</li>
                <li>
                  FailedDatasets:{" "}
                  {
                    <ul>
                      {jobStatusInfo.jobSummary.lastRunJobSummary.failedDatasets &&
                        jobStatusInfo.jobSummary.lastRunJobSummary.failedDatasets.length &&
                        jobStatusInfo.jobSummary.lastRunJobSummary.failedDatasets.map((dataset: any, i) => {
                          return <li>{dataset.datasetId + ":  " + dataset.lastError}</li>;
                        })}
                    </ul>
                  }
                </li>
                <li>
                  TimedOutDatasetIds:{" "}
                  {
                    <ul>
                      {jobStatusInfo.jobSummary.lastRunJobSummary.timedOutDatasetIds &&
                        jobStatusInfo.jobSummary.lastRunJobSummary.timedOutDatasetIds.length &&
                        jobStatusInfo.jobSummary.lastRunJobSummary.timedOutDatasetIds.map((dataset: any, i) => {
                          return <li>{dataset.datasetId + ":  " + dataset.lastError}</li>;
                        })}
                    </ul>
                  }
                </li>
                <li>
                  InstantIgnoreDatasets:{" "}
                  {
                    <ul>
                      {jobStatusInfo.jobSummary.lastRunJobSummary.instantIgnoreDatasets &&
                        jobStatusInfo.jobSummary.lastRunJobSummary.instantIgnoreDatasets.length &&
                        jobStatusInfo.jobSummary.lastRunJobSummary.instantIgnoreDatasets.map((dataset: any, i) => {
                          return <li>{dataset.datasetId + ":  " + dataset.lastError}</li>;
                        })}
                    </ul>
                  }
                </li>
                <li>
                  DatasetFailureHistory:{" "}
                  {
                    <ul>
                      {jobStatusInfo.jobSummary.datasetFailureHistory &&
                        Object.keys(jobStatusInfo.jobSummary.datasetFailureHistory).map((datasetId: any, i) => {
                          return (
                            <li key={i}>
                              <ul>
                                {datasetId + ": " + jobStatusInfo.jobSummary.datasetFailureHistory[datasetId].lastError}
                                <li>{jobStatusInfo.jobSummary.datasetFailureHistory[datasetId].history}</li>
                              </ul>
                            </li>
                          );
                        })}
                    </ul>
                  }
                </li>
                <li>
                  IgnoreDatasetsInfos:{" "}
                  {
                    <ul>
                      {jobStatusInfo.jobSummary.ignoreDatasetsInfos &&
                        Object.keys(jobStatusInfo.jobSummary.ignoreDatasetsInfos).map((datasetId: any, i) => {
                          return (
                            <li key={i}>
                              <ul>
                                {datasetId + ": " + jobStatusInfo.jobSummary.ignoreDatasetsInfos[datasetId].lastError}
                                <li>{jobStatusInfo.jobSummary.ignoreDatasetsInfos[datasetId].ignoreExpiration}</li>
                              </ul>
                            </li>
                          );
                        })}
                    </ul>
                  }
                </li>
              </ul>
            </span>
          </div>
        )}
    </div>
  );
};

export default connect((state: IApplicationState) => ({
  ...state.sessionState
}))(Report as any);
