import * as React from "react";
import { connect } from "react-redux";
import { TextInput } from "../../../common/TextInput";
import DropDownInput from "../../../common/DropDownInput";
import ImageUploadPreview, { IImageChangeEvent } from "../../../common/ImageUploadPreview";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import * as SessionStore from "../../../../store/Session";
import "../Applications.scss";
import {
  ApplicationEdit,
  ApplicationsType,
  CredentialType,
  UpsertUserAppModel,
  Application,
  ApplicationStatus
} from "../../../../models/ApplicationModels";
import CommonHelper from "../../../common/utilities/CommonHelper";
import CreateAppType from "./CreateAppType";
import { Button } from "@progress/kendo-react-buttons";
import "./Edit.scss";
import ConsoleService from "../../../../services/ConsoleService";
import InlineFeedback, { IInlineFeedBackResultItem, InlineFeedBackResultLevel } from "../../common/InlineFeedback";
import CollapsibleSection from "../../common/CollapsibleSection";
import "../../common/AdminStyles.scss";
import { IApplicationState } from "../../../../store";
import DialogBox from "../../../common/DialogBox";
import NotificationService from "../../../../services/NotificationService";
import AdminService from "../../../../services/AdminService";
import { DialogCloseEvent } from "@progress/kendo-react-dialogs";
import AppFeatures from "./AppFeatures";
import { IAppFeature, IToggleAbleSystemFeature } from "../../../../models/Features";




const validationMessages = {
  requiredField: "This field is required.",
  isAppNameExists: "App name already exists.",
  isStartUpUrlAndIdpGroupIdExists: "Idp Group Id + Startup url already exists."
};

const selectAuthProfileMessage = "(Add Account Credentials Below)";

const credTypes = [
  { id: null, name: "(Select one)", identifer: null, code: null, apiUrl: null, epiqUsersOnly: true },
  { id: CredentialType.usernamePassword, name: "Username/Password", identifer: "Username", code: "Password", apiUrl: "API URL", epiqUsersOnly: true },
  { id: CredentialType.clientIdClientSecret, name: "Client ID/Secret", identifer: "Client ID", code: "Secret", apiUrl: "API URL", epiqUsersOnly: true },
  { id: CredentialType.httpHeader, name: "Header Name/Value", identifer: "Header Name", code: "Header Value", apiUrl: "API URL", epiqUsersOnly: true }] as Array<ICredType>;

interface IElementToValidate {
  name: string;
  isValid: boolean;
}

interface ICredType {
  name: string;
  id: number;
  identifer: string;
  code: string;
  apiUrl: string;
  epiqUsersOnly: boolean;
}

interface IProps {
  appDetails?: ApplicationEdit;
  createApp: boolean;
  appTypes: Array<ApplicationsType>;
  isSaving: boolean;
  isAppNameExists: boolean;
  isStartUpUrlAndIdpGroupIdExists: boolean;
  isInvalidIdpGroupId: boolean;
  updateEditedAppDetails?: (
    editedAppDetails: UpsertUserAppModel,
    isFormReadyForSave: boolean,
    resetAppNameExistMessage: boolean,
    resetUrlExistMessage: boolean,
    resetIdpGroupId: boolean,
    featureChanges?: Array<IAppFeature>
  ) => void;
  refreshAppTypeData?: () => void;
  selectedOktaApp?: Application;
}

type testCredsStatusTypes = null | "Success. Credentials connect." | "Failure. Credentials do not connect." | "Connection issue. Unable to test credentials." | "Checking..." | "Unknown Error";

type Props = IProps & SessionStore.ISessionState;

type State = {
  isOpen: boolean;
  isUploadOpen: boolean;
  hasValue: boolean;
  editedAppDetails: ApplicationEdit;
  isLoading: boolean;
  selectedAppTypeItem: ApplicationsType;
  elementsToValidate: Array<IElementToValidate>;
  selectedCredType?: ICredType;
  isCreateAppType: boolean;
  testCredsStatus: testCredsStatusTypes;
  showDeactivateConfirmDialog: boolean;
  showReactivateConfirmDialog: boolean;
  featureChanges: Array<IAppFeature>;
  authProfiles?: Array<string>;
  credProfileError?: boolean;
  appTypeFeature?: Array<IToggleAbleSystemFeature>
};

class Edit extends React.Component<Props, State>  {
  imageUrlRefObj: React.RefObject<TextInput>;

  constructor(props: Props) {
    super(props);

    this.imageUrlRefObj = React.createRef();

    this.state = {
      isOpen: false,
      isUploadOpen: false,
      hasValue: true,
      selectedAppTypeItem: this.getDefaultAppTypeItem(),
      editedAppDetails: props.createApp
        ? new ApplicationEdit()
        : CommonHelper.cloneObject(props.appDetails),
      isLoading: false,
      selectedCredType: this.getCredType(props.appDetails) || credTypes[0],
      elementsToValidate: new Array<IElementToValidate>(
        { name: "name", isValid: !props.createApp },
        { name: "instanceName", isValid: !props.createApp },
        { name: "typeNameId", isValid: !props.createApp },
        { name: "url", isValid: !props.createApp },
        { name: "startupUrl", isValid: !props.createApp }
      ),
      isCreateAppType: true,
      testCredsStatus: null,
      showDeactivateConfirmDialog: false,
      showReactivateConfirmDialog: false,
      featureChanges: [],
      authProfiles: null,
      appTypeFeature: []
    };

    if (!this.credsNotReady(props.appDetails)) {
      this.getAuthProfiles(props.appDetails);
    }
  }

  getAuthProfiles = async (appDetails: ApplicationEdit) => {
    return; // Disable getting profiles for now

    // if (!appDetails) {
    //   return;
    // }

    // if (appDetails.id == 0) {
    //   this.setState({ authProfiles: [], credProfileError: false });
    //   return;
    // }

    // const response = await AdminService.getAuthenticationProfiles(appDetails.id);
    // if (response.ok && response.data && response.data.length) {
    //   this.setState({ authProfiles: [selectAuthProfileMessage].concat(response.data), credProfileError: false });
    // }
    // else if (!response.ok) {
    //   this.setState({ credProfileError: true });
    // }
    // else {
    //   this.setState({ authProfiles: [], credProfileError: false });
    // }
  }

  getCredType = (app: ApplicationEdit) => {
    if (!app || !app.credentials) return null;

    return credTypes.find(c => c.id === app.credentials.type);
  };

  getDefaultAppTypeItem = () => {
    let defaultItem: ApplicationsType;
    const { appDetails, appTypes, createApp } = this.props;

    if (appTypes && appDetails && !createApp) {
      defaultItem = appTypes.find((item: ApplicationsType, index: number) => item.id == appDetails.typeNameId);
    }

    return defaultItem;
  };

  onTestAppConnection = async () => {

    const { editedAppDetails } = this.state;

    this.setState({ testCredsStatus: "Checking..." });

    let appId = "";
    let identifier = "";
    let code = "";
    let apiUrl = "";

    let results = null;

    if (editedAppDetails && editedAppDetails.credChanges && (!editedAppDetails.credentials.code || !editedAppDetails.credentials.identifier || !editedAppDetails.credentials.apiUrl)) {
      // other code should prevent this 'if' from happening
      this.setState({ testCredsStatus: "Unknown Error" });
      return;
    }
    else if (editedAppDetails && editedAppDetails.credChanges) {
      code = editedAppDetails.credentials.code;
      identifier = editedAppDetails.credentials.identifier;
      apiUrl = editedAppDetails.credentials.apiUrl;

      results = await ConsoleService.testConnection(editedAppDetails.typeNameId, identifier, code, apiUrl);
    }
    else if (this.props.appDetails == null || this.props.appDetails.id == 0) {
      // other code should prevent this 'if' from happening
      this.setState({ testCredsStatus: "Unknown Error" });
      return;
    }
    else {
      appId = this.props.appDetails.id.toString();
      results = await ConsoleService.testConnectionForApplication(appId);
    }

    if (!results.ok) {
      this.setState({ testCredsStatus: "Connection issue. Unable to test credentials." });
    }
    else {
      this.setState({ testCredsStatus: results.data.success ? "Success. Credentials connect." : "Failure. Credentials do not connect." });
    }
  }

  credentialFeedback = (message: testCredsStatusTypes): IInlineFeedBackResultItem | null => {

    if (!message) return null;

    let level = null as InlineFeedBackResultLevel;

    switch (message) {
      case "Success. Credentials connect.":
        level = "Success";
        break;
      case "Checking...":
        level = null;
        break;
      default:
        level = "Problem";
        break;
    }

    return { level, message };
  }


  async toggleDeactivateDialogEvent(event: React.MouseEvent<HTMLElement>) {
    if (event.currentTarget.textContent === "NO") {
      this.setState({ showDeactivateConfirmDialog: false });
    } else if (event.currentTarget.textContent === "YES") {
      const resonse = await AdminService.deactivateApp(this.props.appDetails);
      if (resonse.ok) {
        const editedAppDetails: any = { ...this.state.editedAppDetails, statusId: ApplicationStatus.deactivated };

        if (this.props.updateEditedAppDetails) {
          this.props.updateEditedAppDetails(
            { ...editedAppDetails, statusId: ApplicationStatus.deactivated },
            !this.state.elementsToValidate.find(element => !element.isValid),
            this.props.isAppNameExists,
            this.props.isStartUpUrlAndIdpGroupIdExists,
            this.props.isInvalidIdpGroupId
          );
        }

        this.setState({
          showDeactivateConfirmDialog: false,
          editedAppDetails: editedAppDetails
        });

        NotificationService.showSuccessToast(`${this.props.appDetails.name} has been deactivated.`);
      } else {
        NotificationService.showErrorToast(`Something went wrong. Failed to Deactivate ${this.props.appDetails.name}.`);
        this.setState({ showDeactivateConfirmDialog: !this.state.showDeactivateConfirmDialog });
      }
    } else {
      this.setState({ showDeactivateConfirmDialog: !this.state.showDeactivateConfirmDialog });
    }
  }

  async toggleReactivateDialogEvent(event: React.MouseEvent<HTMLElement>) {
    if (event.currentTarget.textContent === "NO") {
      this.setState({ showReactivateConfirmDialog: false });
    } else if (event.currentTarget.textContent === "YES") {
      const result = await AdminService.reactivateApp(this.props.appDetails);
      if (result.ok) {
        const editedAppDetails: any = { ...this.state.editedAppDetails, statusId: ApplicationStatus.active };

        if (this.props.updateEditedAppDetails) {
          this.props.updateEditedAppDetails(
            editedAppDetails,
            !this.state.elementsToValidate.find(element => !element.isValid),
            this.props.isAppNameExists,
            this.props.isStartUpUrlAndIdpGroupIdExists,
            this.props.isInvalidIdpGroupId
          );
        }

        this.setState({
          editedAppDetails: editedAppDetails,
          showReactivateConfirmDialog: false
        });
        NotificationService.showSuccessToast(`${this.props.appDetails.name} has been reactivated.`);
      } else {
        NotificationService.showErrorToast(`Something went wrong. Failed to Reactivate ${this.state.editedAppDetails.name}.`);
        this.setState({ showReactivateConfirmDialog: !this.state.showReactivateConfirmDialog });
      }
    } else {
      this.setState({ showReactivateConfirmDialog: !this.state.showReactivateConfirmDialog });
    }
  }

  dialogCloseEvent(event: DialogCloseEvent) {
    this.setState({ showDeactivateConfirmDialog: false, showReactivateConfirmDialog: false });
  }

  onTextInputChangeEvent = (value: string, name: string, isValid: boolean) => {
    const editedAppDetails: any = this.state.editedAppDetails;
    const nameParts = name.split(".");

    if (nameParts.length == 2) {
      editedAppDetails[nameParts[0]] && (editedAppDetails[nameParts[0]][nameParts[1]] = value);
    } else {
      editedAppDetails[name] = value;
    }

    this.setState({ editedAppDetails: editedAppDetails, testCredsStatus: null });

    if (!this.state.authProfiles && this.credsNotReady(editedAppDetails)) {
      this.getAuthProfiles(editedAppDetails);
    }

    const resetAppNameExistMessage: boolean =
      this.props.isAppNameExists && name === "name" ? false : this.props.isAppNameExists;
    const resetUrlExistMessage: boolean =
      this.props.isStartUpUrlAndIdpGroupIdExists && (name === "startupUrl" || name === "idpGroupId") ? false : this.props.isStartUpUrlAndIdpGroupIdExists;
    const resetIdpGroupIdExistMessage: boolean =
      this.props.isInvalidIdpGroupId && name === "idpGroupId" ? false : this.props.isInvalidIdpGroupId;

    this.setElementValidationState({ name, isValid }, () => {
      this.setState({ editedAppDetails: editedAppDetails });

      if (this.props.updateEditedAppDetails) {
        this.props.updateEditedAppDetails(
          editedAppDetails,
          !this.state.elementsToValidate.find(element => !element.isValid),
          resetAppNameExistMessage,
          resetUrlExistMessage,
          resetIdpGroupIdExistMessage
        );
      }
    });
  };

  onAppTypeDropDownListChange = (event: DropDownListChangeEvent) => {
    let selectedItem = event ? (event.value as ApplicationsType) : null;

    if (selectedItem) {
      let editedAppDetails: any = this.state.editedAppDetails;
      editedAppDetails["typeNameId"] = selectedItem.id.toString();
      editedAppDetails["typeName"] = selectedItem.typeName ? selectedItem.typeName : "";
      editedAppDetails["imageUrl"] = selectedItem.imageUrl ? selectedItem.imageUrl : "";
      const ref = this.imageUrlRefObj;
      if (ref.current) {
        ref.current.onInputChange({ value: editedAppDetails["imageUrl"] } as any);
      }

      this.setElementValidationState({ name: "typeNameId", isValid: true }, () => {
        this.setState({ editedAppDetails: editedAppDetails, selectedAppTypeItem: selectedItem });
        if (this.props.updateEditedAppDetails) {
          this.props.updateEditedAppDetails(
            editedAppDetails,
            !this.state.elementsToValidate.find(element => !element.isValid),
            this.props.isAppNameExists,
            this.props.isStartUpUrlAndIdpGroupIdExists,
            this.props.isInvalidIdpGroupId
          );
        }
      });
    }
  }

  onCredTypeDropDownListChange = (event: DropDownListChangeEvent) => {
    const selectedItem = event ? (event.value as ICredType) : null;

    const editedAppDetails = { ...this.state.editedAppDetails } as UpsertUserAppModel;

    if (!selectedItem.id) {
      editedAppDetails.credentials = null;
    }
    else {
      editedAppDetails.credentials = { type: selectedItem.id, identifier: null, code: null, apiUrl: null, epiqUsersOnly: true };
    }

    this.setElementValidationState({ name: "credType", isValid: true }, () => {
      this.setState({ selectedCredType: selectedItem, editedAppDetails });
      if (this.props.updateEditedAppDetails) {
        this.props.updateEditedAppDetails(
          editedAppDetails,
          !this.state.elementsToValidate.find(element => !element.isValid),
          this.props.isAppNameExists,
          this.props.isStartUpUrlAndIdpGroupIdExists,
          this.props.isInvalidIdpGroupId
        );
      }
    });
  }

  private setElementValidationState(newValidationState: IElementToValidate, callBack: () => void) {
    const indexToUpdate = this.state.elementsToValidate.findIndex(element => element.name === newValidationState.name);
    const elementsToValidate = [...this.state.elementsToValidate];

    if (indexToUpdate === -1) {
      callBack();
    } else {
      elementsToValidate.splice(indexToUpdate, 1);
      elementsToValidate.splice(indexToUpdate, 0, newValidationState);

      this.setState({ ...this.state, elementsToValidate }, callBack);
    }
  }

  handleModal = async () => {
    await this.setState({ isOpen: true, isCreateAppType: true });
  };

  handleEditClick = async () => {
    await this.setState({ isOpen: true, isCreateAppType: false });
  };

  handleClose = async () => {
    await this.setState({
      isOpen: false
    });
  };

  onImageUploadFinished = (uploadFinishedEvent: IImageChangeEvent) => {
    if (uploadFinishedEvent.imgUrl) {
      this.onTextInputChangeEvent(uploadFinishedEvent.imgUrl, "imageUrl", true)
    }
  }

  refreshSelectedAppTypeData = () => {
    var selAppType = this.state.selectedAppTypeItem;
    var selectedAppType = selAppType ? this.props.appTypes.find(a => a.id == selAppType.id) : null;
    var editedAppDetails = this.state.editedAppDetails;
    editedAppDetails.imageUrl = selectedAppType && selectedAppType.imageUrl ? selectedAppType.imageUrl : "";

    if (selectedAppType) {
      this.setState({ ...this.state, selectedAppTypeItem: selectedAppType, editedAppDetails: editedAppDetails });
    }
  };

  preFillAppData = () => {
    const appDetails = this.props.selectedOktaApp;

    if (appDetails) {
      let editedAppDetails = this.state.editedAppDetails;
      editedAppDetails["ssoApplicationId"] = appDetails["ssoApplicationId"];
      editedAppDetails["url"] = appDetails["url"];
      editedAppDetails["startupUrl"] = appDetails["startupUrl"];
      editedAppDetails["idpGroupId"] = appDetails["idpGroupId"];

      this.setState({ editedAppDetails: editedAppDetails });

      if (this.state.editedAppDetails) {
        this.setElementValidationState({ name: "url", isValid: this.state.editedAppDetails["url"] && this.state.editedAppDetails["url"].length > 0 }, () => {
          if (this.props.updateEditedAppDetails) {
            this.props.updateEditedAppDetails(
              editedAppDetails as UpsertUserAppModel,
              !this.state.elementsToValidate.find(element => !element.isValid),
              this.props.isAppNameExists,
              this.props.isStartUpUrlAndIdpGroupIdExists,
              this.props.isInvalidIdpGroupId
            );
          }
        });
      }
    }
  };

  onFeatureChange = (featureChanged: IAppFeature) => {

    const { featureChanges } = this.state;

    const changeInx = featureChanges.findIndex(f => f.featureId === featureChanged.featureId);
    if (changeInx < 0) {
      featureChanges.push(featureChanged);
    } else {
      featureChanges[changeInx] = featureChanged;
    }

    this.setState({ ... this.state, featureChanges });
    const editedAppDetails: any = this.state.editedAppDetails;

    if (this.props.updateEditedAppDetails) {
      this.props.updateEditedAppDetails(
        editedAppDetails,
        !this.state.elementsToValidate.find(element => !element.isValid),
        this.props.isAppNameExists,
        this.props.isStartUpUrlAndIdpGroupIdExists,
        this.props.isInvalidIdpGroupId,
        featureChanges
      );
    }
  }

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.appTypes !== this.props.appTypes) {
      this.refreshSelectedAppTypeData();
    }

    if (prevProps.selectedOktaApp !== this.props.selectedOktaApp) {
      this.preFillAppData();
    }

    if (this.props.appDetails != null && this.props.appDetails.deleteCreds && this.props.appDetails.credentialsKey == null && prevProps.appDetails.credentialsKey != null) {
      this.setState({ ...this.state, editedAppDetails: { ...this.state.editedAppDetails, credentialsKey: null, credentials: null, deleteCreds: false } });
    }
  };

  credsNotReady = (appEdit: ApplicationEdit) => {
    if (!appEdit) return false;

    return (!appEdit.credChanges && (!appEdit.credentialsKey && !appEdit.newCredsRecentlySaved)) ||
      (appEdit.credChanges && (!appEdit.credentials || !appEdit.credentials.code || !appEdit.credentials.identifier || !appEdit.credentials.apiUrl));
  }

  getNotesFieldLength = (appEdit: ApplicationEdit, appDetails: ApplicationEdit) => {
    return appEdit
      ? appEdit.description
        ? appEdit.description.length
        : 0
      : appDetails.description
        ? appDetails.description.length
        : 0;
  };

  updateAppTypeFeature = (features: Array<IToggleAbleSystemFeature>) => {
    this.setState({ appTypeFeature: features });
  }

  render() {
    const { editedAppDetails, isOpen, selectedAppTypeItem, isUploadOpen, selectedCredType, showDeactivateConfirmDialog, showReactivateConfirmDialog, authProfiles, credProfileError } = this.state;
    const { isSaving, isAppNameExists, isStartUpUrlAndIdpGroupIdExists, appTypes } = this.props;
    const credsAreReady = !this.credsNotReady(editedAppDetails);
    const notesLength = this.getNotesFieldLength(this.state.editedAppDetails, this.props.appDetails);

    const disableCredActions = isSaving || !credsAreReady;
    const disableDeleteCreds = !!editedAppDetails.credChanges || (!editedAppDetails.credentialsKey && !editedAppDetails.newCredsRecentlySaved);

    return (
      <div className="app-info-wrapper">

        <CollapsibleSection title="Application Details">
          <div className="grid-container">
            <div>
              <ImageUploadPreview allowMultiple={false} value={editedAppDetails.imageUrl} name="imageUrl" label="Uploaded Image Preview" onChange={this.onImageUploadFinished.bind(this)} />
              <Checkbox
                defaultChecked={editedAppDetails.ssoEnabled}
                name="ssoEnabled"
                label="SSO Enabled"
                disabled={isSaving}
                className="form-input checkbox-field-space"
                onChange={(event: CheckboxChangeEvent) => { this.onTextInputChangeEvent(event.value as any as string, "ssoEnabled", true) }}
              />
              <br />
              <Checkbox
                defaultChecked={editedAppDetails.epiqOnly}
                name="epiqOnly"
                label="Epiq Admin Only"
                disabled={!this.props.createApp}
                className="form-input checkbox-field-space"
                onChange={(event: CheckboxChangeEvent) => { this.onTextInputChangeEvent(event.value as any as string, "epiqOnly", true) }}
              />
              <div>
                {this.props.sessionData.permissions.has("EpiqAdminDeactivateApplication") &&
                  this.props.sessionData.permissions.has("EpiqAdminGetApplication") && editedAppDetails.statusId === ApplicationStatus.active && <>
                    <DialogBox
                      title={"Deactivate application?"}
                      content={"Do you want to deactivate this application?"}
                      visible={showDeactivateConfirmDialog}
                      toggleDialogEvent={this.toggleDeactivateDialogEvent.bind(this)}
                      diagCloseEvent={this.dialogCloseEvent.bind(this)}
                      okButtonLabelText={"YES"}
                      cancelButtonLabelText={"NO"}
                      openDiaglogButtonText={"DEACTIVATE"}
                      btnClassName="btn-secondary"
                    />
                  </>}
                {this.props.sessionData.permissions.has("EpiqAdminDeactivateApplication") &&
                  this.props.sessionData.permissions.has("EpiqAdminGetApplication") && editedAppDetails.statusId === ApplicationStatus.deactivated && (
                    <>
                      <DialogBox
                        title={"Activate application?"}
                        content={"Do you want to reactivate this application?"}
                        visible={showReactivateConfirmDialog}
                        toggleDialogEvent={this.toggleReactivateDialogEvent.bind(this)}
                        diagCloseEvent={this.dialogCloseEvent.bind(this)}
                        okButtonLabelText={"YES"}
                        cancelButtonLabelText={"NO"}
                        openDiaglogButtonText={"REACTIVATE"}
                        btnClassName="btn-secondary"
                      />
                    </>
                  )}
              </div>

            </div>
            <div>
              <TextInput
                type="text"
                placeholder="App Name"
                name="name"
                label="* App Name"
                defaultValue={editedAppDetails.name}
                validations={[
                  { name: "required", errorMessage: "This field is required." },
                  {
                    name: "CustomValidation",
                    errorMessage: validationMessages.isAppNameExists,
                    predicate: isAppNameExists.toString()
                  }
                ]}
                displayCustomValidationMessage={isAppNameExists}
                onChange={this.onTextInputChangeEvent}
                maxLength={255}
                disabled={isSaving}
              />

              <TextInput
                type="text"
                placeholder="Instance Name"
                defaultValue={editedAppDetails.instanceName}
                name="instanceName"
                label="* Instance Name"
                maxLength={255}
                disabled={isSaving}
                validations={[{ name: "required", errorMessage: "This field is required." }]}
                onChange={this.onTextInputChangeEvent}
              />

              <TextInput
                type="text"
                placeholder="SSO Application ID"
                value={editedAppDetails.ssoApplicationId}
                name="ssoApplicationId"
                label="SSO Application ID"
                disabled={isSaving}
                maxLength={64}
                onChange={this.onTextInputChangeEvent}
              />

              <TextInput
                type="url"
                placeholder="URL"
                value={editedAppDetails.url}
                name="url"
                label="* URL"
                disabled={isSaving}
                maxLength={1500}
                onChange={this.onTextInputChangeEvent}
                validations={[
                  { name: "required", errorMessage: "This field is required." },
                  { name: "Url", errorMessage: "Please enter a valid URL." }
                ]}
              />
            </div>
            <div>

              <DropDownInput
                name="typeNameId"
                label="* App Type Name"
                data={appTypes}
                key="id"
                textField="typeName"
                dataItemKey="id"
                value={selectedAppTypeItem}
                disabled={isSaving}
                required={true}
                onChange={this.onAppTypeDropDownListChange}
                editDetails={{ title: "Edit app type names", onEdit: this.handleEditClick }}
                createDetails={{ title: "Add app type names", onCreate: this.handleModal }}
              />

              <TextInput
                type="text"
                placeholder="IDP Group Id"
                value={editedAppDetails.idpGroupId}
                name="idpGroupId"
                label="IDP Group Id"
                disabled={isSaving}
                maxLength={64}
                validations={[
                  {
                    name: "CustomValidation",
                    errorMessage: validationMessages.isStartUpUrlAndIdpGroupIdExists,
                    predicate: isStartUpUrlAndIdpGroupIdExists.toString()
                  }
                ]}
                displayCustomValidationMessage={isStartUpUrlAndIdpGroupIdExists}
                onChange={this.onTextInputChangeEvent}
              />

              <TextInput
                type="url"
                placeholder="Startup URL"
                value={editedAppDetails.startupUrl}
                name="startupUrl"
                label="* Startup URL"
                validations={[
                  { name: "required", errorMessage: "This field is required." },
                  { name: "Url", errorMessage: "Please enter a valid URL." },
                  {
                    name: "CustomValidation",
                    errorMessage: validationMessages.isStartUpUrlAndIdpGroupIdExists,
                    predicate: isStartUpUrlAndIdpGroupIdExists.toString()
                  }
                ]}
                displayCustomValidationMessage={isStartUpUrlAndIdpGroupIdExists}
                maxLength={4000}
                disabled={isSaving}
                onChange={this.onTextInputChangeEvent}
              />

              {false /* hide for now until we move to features */ && !!(authProfiles && authProfiles.length > 0 || editedAppDetails.authenticationProfile) && <DropDownInput
                name="authenticationProfile"
                label="Default Login Method"
                data={authProfiles || [editedAppDetails.authenticationProfile]}
                value={editedAppDetails.authenticationProfile || selectAuthProfileMessage}
                disabled={disableCredActions || !authProfiles || authProfiles.length === 0}
                required={false}
                onChange={e => this.onTextInputChangeEvent(e.value === selectAuthProfileMessage ? null : e.value, "authenticationProfile", true)}
              />}
              {credProfileError ? (
                <div className="error-info">
                  <span className="erro-msg">Error retrieving credential profiles</span>
                </div>
              ) : (
                <></>
              )}

              <TextInput
                type="textarea"
                placeholder="Enter a short description"
                defaultValue={editedAppDetails.description}
                name="description"
                label="Description"
                maxLength={255}
                disabled={isSaving}
                onChange={this.onTextInputChangeEvent}
                notesLength={notesLength}
                legacyMode={false}
              />
            </div>
          </div>
        </CollapsibleSection>

        <CollapsibleSection title="Service Account Details" initHidden={true}>
          <>
            <div className="grid-container">
              <div>
                <Checkbox
                  defaultChecked={false}
                  name="credChanges"
                  disabled={!!editedAppDetails.deleteCreds}
                  label="Make changes to credentials?"
                  className="form-input checkbox-field-space"
                  onChange={(event: CheckboxChangeEvent) => { this.onTextInputChangeEvent(event.value as any as string, "credChanges", true) }}
                />

                <DropDownInput
                  name="credType"
                  label="Account Credentials"
                  data={credTypes}
                  key="id"
                  textField="name"
                  dataItemKey="id"
                  value={selectedCredType}
                  disabled={isSaving || !editedAppDetails.credChanges}
                  required={true}
                  onChange={this.onCredTypeDropDownListChange}
                />

                {selectedCredType.id && <TextInput
                  type="text"
                  placeholder={selectedCredType.identifer}
                  value={editedAppDetails.credentials ? editedAppDetails.credentials.identifier : null}
                  name="credentials.identifier"
                  autoComplete="off"
                  label={selectedCredType.identifer}
                  disabled={isSaving || !editedAppDetails.credChanges}
                  onChange={this.onTextInputChangeEvent}
                />}
              </div>
              <div className="flow-from-end">
                {selectedCredType.id && <TextInput
                  type="password"
                  placeholder={selectedCredType.code}
                  value={editedAppDetails.credentials ? editedAppDetails.credentials.code : null}
                  name="credentials.code"
                  autoComplete="off"
                  label={selectedCredType.code}
                  disabled={isSaving || !editedAppDetails.credChanges}
                  onChange={this.onTextInputChangeEvent}
                />}
              </div>
              <div className="flow-from-end">
                {selectedCredType.id && <TextInput
                  type="url"
                  placeholder={selectedCredType.apiUrl}
                  value={editedAppDetails.credentials ? editedAppDetails.credentials.apiUrl : null}
                  name="credentials.apiUrl"
                  autoComplete="off"
                  label={selectedCredType.apiUrl}
                  disabled={isSaving || !editedAppDetails.credChanges}
                  maxLength={1500}
                  onChange={this.onTextInputChangeEvent}
                />}
              </div>
            </div>

            <div className="app-credential-testing">
              <Button primary={false} className={"btn-secondary" + (disableCredActions ? " disableButton" : "")} onClick={() => this.onTestAppConnection()} disabled={disableCredActions}>
                Test Credentials
              </Button>
              <Button
                className={`btn-secondary ${disableDeleteCreds ? "disableButton" : ""}`}
                iconClass="fa fa-trash-o"
                name="deleteCreds"
                disabled={disableDeleteCreds}
                onClick={() => { this.onTextInputChangeEvent("true", "deleteCreds", true) }}
              >
                Delete credentials
              </Button>
              <InlineFeedback result={this.credentialFeedback(this.state.testCredsStatus)} />
            </div>

            <div className="app-feature-container">
              <label className="app-feature-setting-label">Relativity Feature Settings</label>
              <AppFeatures appTypeId={editedAppDetails.typeNameId} appId={editedAppDetails.id} onChange={this.onFeatureChange} />
            </div>

          </>
        </CollapsibleSection>

        <CollapsibleSection title="Additional Feature Settings" initHidden={true}>
          <AppFeatures appTypeId={editedAppDetails.typeNameId} appId={editedAppDetails.id} onChange={this.onFeatureChange} systemFeatures={true} appTypeFeature={this.state.appTypeFeature}
            noRecordsMessage={"Application type has no available options."}
          />
        </CollapsibleSection>

        {isOpen && (
          <CreateAppType
            isCreateAppType={this.state.isCreateAppType}
            selectedAppType={this.state.selectedAppTypeItem}
            handleClose={this.handleClose}
            refreshAppTypeData={this.props.refreshAppTypeData}
            updateAppTypeFeature={this.updateAppTypeFeature}
          />
        )}
      </div>
    );
  }
}

export default connect(
  (state: IApplicationState) => ({
    ...state.sessionState
  }),
  null
)(Edit);

