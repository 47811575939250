import React, { useEffect, useState } from "react";
import AdminService from "../../../services/AdminService";
import { GridCellProps, GridFilterCellProps } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { IGridParams, IGridStateMerged, AdvancedGrid } from "../../common/grid/AdvancedGrid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { UserGroup } from "../../../models/UserModels";
import { NavLink } from "react-router-dom";
import "./UserGroupAssignmentsList.scss";
import CommandCell from "../../common/grid/columns/CommandCell";
import { FilterDescriptor } from "@progress/kendo-react-dropdowns/dist/npm/common/filterDescriptor";
import { DropDownFilter } from "../../common/grid/filters/DropDownFilter";
import CommonHelper from "../../common/utilities/CommonHelper";

interface Props {
  userId: number;
  additionalUserGroups: UserGroup[];
  removeUserGroups: (selected: UserGroup) => void;
  addUserGroupClickHandler: () => void;
  permissions: Set<string>;
  disableAddBtn?: boolean;
}

const defaultUserGroupState = {items:[], merged:[], count:0, selections:{}} as IGridStateMerged<UserGroup>;

const UserGroupAssignmentsList = (props: Props) => {
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userGroups, setUserGroups] = useState(defaultUserGroupState);
  const userId = props.userId;
  const dataState: IGridParams = {
    skip: 0,
    take: 100,
    sort: [{ field: "groupName", dir: "asc" }]
  };

  const updateMergedState = (uGroups: UserGroup[], preSelected: Record<number | string, UserGroup>) => {

    if (!uGroups) {
      uGroups = [];
    }

    if (props.additionalUserGroups && props.additionalUserGroups.length) {
      const merged = CommonHelper.dedupeObjects<UserGroup>(props.additionalUserGroups, uGroups, 'groupId');
      setUserGroups({items: uGroups, merged, count: merged.length, selections: preSelected});
    } else {
      setUserGroups({items: uGroups, merged: uGroups, count: uGroups.length, selections: preSelected});
    }
  }

  useEffect(() => {
    updateMergedState(userGroups.items, userGroups.selections);
  }, [props.additionalUserGroups]);

  const fetchUserGroups = async (dataState: IGridParams) => {
    let isEpiqAdminFilterValueEmpty = false;

    if (props.permissions.has("EpiqAdminListUserGroups")) {
      setLoading(true);
      let filters = dataState.filters
        ? dataState.filters.map(compositeFilter => {

          const epiqAdminOnlyFilter: FilterDescriptor = compositeFilter.filters.find(
            filter => (filter as FilterDescriptor).field === "epiqOnly"
          ) as FilterDescriptor;

          if (epiqAdminOnlyFilter && epiqAdminOnlyFilter.value !== "") {
            const epiqAdminOnlyFilterValue = epiqAdminOnlyFilter.value
              ? epiqAdminOnlyFilter.value.id == 0 || epiqAdminOnlyFilter.value.id == 1
                ? epiqAdminOnlyFilter.value.id == 0
                  ? false
                  : true
                : null
              : false;

            return {
              ...compositeFilter,
              filters: [
                {
                  ...epiqAdminOnlyFilter,
                  field: "epiqOnly",
                  operator: "eq",
                  value: epiqAdminOnlyFilterValue
                }
              ]
            };
          }

          if (epiqAdminOnlyFilter && epiqAdminOnlyFilter.value == "") {
            isEpiqAdminFilterValueEmpty = true;
          }

          return compositeFilter;
        })
        : [];

      if (isEpiqAdminFilterValueEmpty) {
        filters = filters.filter(compositeFilter => compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field !== "epiqOnly"));
      }

      const result = await AdminService.getUserGroupsByUserId(userId, { ...dataState, filters: filters });
      
      if (result.ok) {
        updateMergedState(result.data.results, userGroups.selections);
        setHasError(false);
      } else {
        setHasError(true);
      }

      setLoading(false);
    }
  };

  const groupNameCell = (props: GridCellProps) => {
    const groupName = props.dataItem.groupName;
    return (
      <td className="groupName">
        <NavLink to={`/administration/userGroupDetails/${props.dataItem.groupId}`}>
          <em title={groupName}>{groupName}</em>
        </NavLink>
      </td>
    );
  };

  const NoLinkGroupNameCell = (props: GridCellProps) => {
    const groupName = props.dataItem.groupName;
    return (
      <td className="groupName">
          <em title={groupName}>{groupName}</em>
      </td>
    );
  };

  const epiqAdminOnly = (props: any) => {
    return <td>{props.dataItem.epiqOnly ? "Epiq Admin" : "Non-Epiq Admin"}</td>;
  };

  const descriptionCell = (props: GridCellProps) => {
    const description = props.dataItem.comments;
    return <td className="description">{description}</td>;
  };

  const filterElements = (element: any) => {
    if (element.tagName === "EM") {
      return true;
    }
    return false;
  };

  const removeUserGroup = (dataItem: any) => {

    let ugs = [ ... userGroups.items ];

    for (let i = 0; i < ugs.length; i++) {
      if (ugs[i].groupId === dataItem.groupId) {
        ugs.splice(i, 1);
        break;
      }
    }

    updateMergedState(ugs, userGroups.selections);

    props.removeUserGroups(dataItem);
  }

  const gridToolBarRender = (): JSX.Element => {
    return props.permissions.has("EpiqAdminCreateUserGroup") && (
      <>
        <Button className="add-user-btn" icon={"plus"} primary={true} onClick={props.addUserGroupClickHandler} disabled={ props.disableAddBtn}>
          User Groups
        </Button>
      </>
    );
  }

  return (
    <Tooltip openDelay={2} position="right" filter={filterElements}>
      <div className="user-groups-grid-wrapper"> 
        <AdvancedGrid
          showErrorState={hasError}
          showLoadingIndicator={loading}
          sortable={{ mode: "multiple" }}
          data={userGroups.merged}
          dataFetch={fetchUserGroups}
          dataState={dataState}
          columns={[
            { field: "groupName", title: "Group Name", cell: props.permissions.has("EpiqAdminGetUserGroup") && !props.disableAddBtn ? groupNameCell : NoLinkGroupNameCell, filterable: true },
            {
              field: "epiqOnly",
              title: "EPIQ Admin Only",
              cell: epiqAdminOnly,
              filterCell: (props: GridFilterCellProps) => (
                <DropDownFilter
                  {...props}
                  data={[{ name: "Epiq Admin", id: 1 }, { name: "Non-Epiq Admin", id: 0 }]}
                  textField="name"
                  defaultSelectedOption={null}
                />
              ),
              filterable: true,
              filter: "text"
            },
            { field: "comments", title: "Description ", cell: descriptionCell, filterable: true },
            { title: "", cell: CommandCell(props.permissions.has("EpiqAdminUpdateUserGroup") && !props.disableAddBtn ? { onRemove: removeUserGroup } : {}), headerClassName:'no-sort' }
          ]}
          paging={false}
          totalRecords={{ value: userGroups.merged.length, label: "Epiq Access User Groups" }}
          noRecordsRender={props.permissions.has("EpiqAdminListUserGroups") ? 
            <p>No user groups assigned.</p> : 
            <p> Requires additional permissions to view. </p>
          }
          noLoadOnMount={false}
          filteredDataOnly={false}
          gridToolbarContent={gridToolBarRender()}
          filterOperators={{
            text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
            date: [{ text: "grid.filterEqOperator", operator: "eq" }, { text: "grid.filterIsNullOperator", operator: "isnull" }]
          }}
        />
      </div>
    </Tooltip>
  );
};

export default UserGroupAssignmentsList;
