import React, { useState, useEffect, useRef } from "react";
import { GridColumnProps } from "@progress/kendo-react-grid";
import NotificationService from "../../../../services/NotificationService";
import { IGridParams, AdvancedGrid } from "../../../common/grid/AdvancedGrid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { ISpeedTest } from "../../../../models/SpeedTestResultModel";
import SpeedTestService from "../../../../services/SpeedTestService";
import "./SpeedTestList.scss";
import FilterHelper from "../../../common/grid/FilterHelper";

export interface IUserSpeedTestRequest extends IGridParams {
    userId?: number;
}

type Props = {
    userId?: number;
};

const SpeedTestList = (props: Props) => {
    const [speedTests, setSpeedTests] = useState(new Array<ISpeedTest>());
    const [totalSpeedTests, setTotalSpeedTests] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const gridRef = useRef<AdvancedGrid<ISpeedTest, IUserSpeedTestRequest>>(null);

    const [dataState, setDataState] = useState({
        userId: props.userId,
        skip: 0,
        take: 100,
        sort: [{ field: "createdDate", dir: "desc" }],
    } as IUserSpeedTestRequest);

    useEffect(() => {

    }, []);

    const getSpeedTestResults = async (dataState: IUserSpeedTestRequest) => {
        const filters = FilterHelper.getModifiedCorrectDateFilter(dataState.filters, "createdDate");

        setIsLoading(true);
        try {
            const result = await SpeedTestService.getSpeedTests({ userId: props.userId, ...dataState, filters });

            if (result.ok) {
                setSpeedTests(result.data.results);
                setTotalSpeedTests(result.data.count);
                setHasError(false);
            }
            else {
                setHasError(true);
            }

        } catch (e) {   
            setHasError(true);
        } finally {
            setIsLoading(false);

            if(hasError) {
                setSpeedTests(new Array<ISpeedTest>());
                setTotalSpeedTests(0);
                NotificationService.showErrorToast("Something went wrong while getting speed tests.");
            }
        }
    };

    const columns = new Array<GridColumnProps>(
        { field: "createdDate", title: "DATE", filterable: true, filter: "date" },
        { field: "jobId", title: "JOB ID", filterable: true, filter: "text" },
        { field: "up", title: "UPLOAD", filterable: false },
        { field: "down", title: "DOWNLOAD", filterable: false },
        { field: "jitter", title: "JITTER RATE", filterable: false },
        { field: "ping", title: "PING", filterable: false },
        { field: "ip", title: "IP", filterable: false }
    );


    return (
        <div className="speedtest-list-grid-wrapper">
            <Tooltip openDelay={2} position="top">
                <AdvancedGrid
                    ref={(advancedGridInstance: AdvancedGrid<ISpeedTest, IUserSpeedTestRequest>) => {
                        gridRef.current = advancedGridInstance;
                    }}
                    showErrorState={hasError}
                    showLoadingIndicator={isLoading}
                    data={speedTests}
                    dataFetch={getSpeedTestResults}
                    dataState={dataState}
                    columns={columns}
                    paging={false}
                    totalRecords={{ value: totalSpeedTests, label: "Speed Tests" }}
                    noRecordsRender={<p>No speed test has been run to date.</p>}
                    noLoadOnMount={false}
                    filteredDataOnly={false}
                    filterOperators={{
                        text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
                        date: [{ text: "grid.filterEqOperator", operator: "eq" }]
                    }}
                />
            </Tooltip>
        </div>
    );
};

export default SpeedTestList;
