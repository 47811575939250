import React, { useEffect, useState } from "react";
import { Grid, CircularProgress, Button } from "@material-ui/core";
import { Employer, EmployerDomain } from "../../../models/EmployerModel";
import AdminService from "../../../services/AdminService";
import { IGridParams } from "../../common/grid/AdvancedGrid";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { FilterDescriptor, CompositeFilterDescriptor } from "@progress/kendo-data-query";
import NotificationService from "../../../services/NotificationService";


interface IProps {
  domain: EmployerDomain;
  currentEmployer: Employer;
  updateTargetEmployer: (employer: Employer) => void;
}

type Props = IProps;

const MoveDomain = (props: Props) => {
  const [employers, setEmployers] = useState<Employer[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [selectedEmployer, setSelectedEmployer] = useState({} as Employer);
  const dataState: IGridParams = {
    skip: 0,
    take: 100,
    sort: [{ field: "name", dir: "asc" }]
  };

  const getAllEmployers = async (filters: Array<CompositeFilterDescriptor>) => {
    setIsLoading(true);
    const result = await AdminService.getEmployerList({ ...dataState, filters: filters });

    if (result.ok) {
      setEmployers(result.data.results.filter((e: any) => e.id != props.currentEmployer.id));
    } else {
      setHasError(true);
      console.log("Something went wrong while fetching employers.");
    }

    setIsLoading(false);
  };

  const comboBoxFilterData = (filter: any) => {
    if (filter && filter.value) {
      const filters = [{
              logic: "and",
              filters: [{ field: "name", operator: "contains", value: filter.value }] as Array<FilterDescriptor>
            }] as Array<CompositeFilterDescriptor>;

      getAllEmployers(filters);
    }
  };

  const comboBoxFilterChange = (event: any) => {
    if(event)
    {
      comboBoxFilterData(event.filter);
    }
  };

  const employerSeletionChange = async (event: any) => {
    if(event && props.updateTargetEmployer)
    {
      props.updateTargetEmployer(event.value);
    }
  };

  useEffect(() => {
    getAllEmployers([] as Array<CompositeFilterDescriptor>);
  }, []);

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <div>Move domain <b>{props.domain.domain}</b> from <b>{props.currentEmployer.name}</b> to</div>
          <ComboBox
            data={employers}
            textField="name"
            filterable={true}
            onFilterChange={comboBoxFilterChange}
            onChange={employerSeletionChange}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MoveDomain;
