import * as React from "react";
import { connect } from "react-redux";
import * as SessionStore from "../../../store/Session";
import { IApplicationState } from "../../../store";
import JobList, { IAdminJobQueueOptions } from "../../common/Job/JobList";

interface IProps {
  match?: any;
  history: any;
}

type Props = IProps & SessionStore.ISessionState;

const JobQueue = (props: Props) => {
  const adminJobQueueOptions: IAdminJobQueueOptions = { includeSystemGeneratedJobs: false };

  return (
    <>
      <JobList {...props} adminJobQueueOptions={adminJobQueueOptions}></JobList>
    </>
  );
};

export default connect((state: IApplicationState) => ({ ...state.sessionState }), {})(JobQueue as any);
