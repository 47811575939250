import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import * as AppStatusStore from "../../store/AppStatus";
import * as SessionStore from "../../store/Session";
import { IApplicationStateManager } from "../../AppStatusManager";
import "./AppStateMessages.scss";

interface IProps {
  history: any;
  appStateManager: IApplicationStateManager;
}

type State = {
  unregisterHistoryListener: () => void;
}

type Props = IProps & AppStatusStore.IAppStatusState & typeof AppStatusStore.actionCreators
          & SessionStore.ISessionState;

class AppStateMessages extends React.PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);

    this.props.appStateManager.addWatcherAsync("AppStateMessages");

    this.state = {
      unregisterHistoryListener: props.history.listen(() => {
        this.unregisterWatcher();
      })
    }
  }

  unregisterWatcher() {
    this.props.appStateManager.removeWatcher("AppStateMessages");
    this.state.unregisterHistoryListener && this.state.unregisterHistoryListener();
  }

  componentDidUpdate(nextProps: Props) {
    if (!nextProps.sessionData) {
      console.log("session is gone, unregistring watch for app state");
      this.unregisterWatcher();
    }
  }

  componentWillUnmount() {
    console.log("component unmounting, unregistring watch for app state");
    this.unregisterWatcher();
  }

  render() {

    if (!this.props.status) {
      return <></>;
    }
  
    return (
      <>
        <div className="bi-dirty-edit">{this.props.status && this.props.status.isPowerBiDirty}</div>
      </>
    );
  }
};
// @ts-ignore
export default connect((state: IApplicationState) => ({ ...state.appStatus, ...state.sessionState }), { ...AppStatusStore.actionCreators })(AppStateMessages);
