export const shippingListValues = [
  { id: "Australia", title: "Australia" },
  { id: "Canada - Toronto", title: "Canada - Toronto" },
  { id: "China - Shenzhen", title: "China - Shenzhen" },
  { id: "China -Shanghai", title: "China -Shanghai" },
  { id: "Germany - Frankfurt", title: "Germany - Frankfurt" },
  { id: "Hong Kong", title: "Hong Kong" },
  { id: "Japan - Tokyo", title: "Japan - Tokyo" },
  { id: "Korea - Seoul", title: "Korea - Seoul" },
  { id: "Singapore", title: "Singapore" },
  { id: "UK - London", title: "UK - London" },
  { id: "US - New York", title: "US - New York" },
  { id: "US - Phoenix", title: "US - Phoenix" },
  { id: "US - Beaverton", title: "US - Beaverton" },
  { id: "Switzerland - Zurich", title: "Switzerland - Zurich" }
];
