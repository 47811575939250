import React, { useState, useEffect, useRef } from "react";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { GridCellProps, GridColumnProps, GridFilterCellProps } from "@progress/kendo-react-grid";
import { AdvancedGrid, IGridParams } from "../../common/grid/AdvancedGrid";
import AdminService from "../../../services/AdminService";
import { Button } from "@progress/kendo-react-buttons";
import CommandCell from "../../common/grid/columns/CommandCell";
import { Employer, EmployerDomain, MoveDomainModel } from "../../../models/EmployerModel";
import { DropDownFilter } from "../../common/grid/filters/DropDownFilter";
import { Checkbox } from "@progress/kendo-react-inputs";
import CommonHelper from "../../common/utilities/CommonHelper";
import { formatDate } from "@telerik/kendo-intl";
import AddDomainsToEmployer from "./AddDomainsToEmployer";
import GridSelector from "../../common/grid/GridSelector";
import "./Employers.scss";
import { CompositeFilterDescriptor, FilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import { differenceWith } from "lodash";
import MoveDomain from "./MoveDomain";
import NotificationService from "../../../services/NotificationService";


interface Props {
  employerDetails: Employer;
  updateSelectedDomains?: (addedDomains: EmployerDomain[], isFilterEnabled?: boolean) => void;
  updateSavedDomains?: (savedDbDomains: EmployerDomain[], isFilterExits: boolean, dataState: IGridParams) => void;
  permissions: Set<string>;
  isCreate?: boolean;
  savedDomains?: EmployerDomain[];
  employerDomains?: EmployerDomain[];
  navigateToEmpList?: () => void;
  isSuperAdmin: boolean;
}

const gridProps: IGridParams = {
  skip: 0,
  take: 1000,
  sort: [{ field: "domain", dir: "asc" }]
};

type State = {
  employerDomains: EmployerDomain[];
  filteredDomains: EmployerDomain[];
  dataState: IGridParams;
  savedDomains: EmployerDomain[];
  selectedDomains: EmployerDomain[];
  isFilterExists: boolean;
  isLoading: boolean;
  hasError: boolean;
  openAddDomainsPopup: boolean;
  showAddDomainsButton: boolean;
  editedDomain: EmployerDomain;
  newlyAddedDomain: EmployerDomain;
  isDomainAlreadyExists: boolean;
  isValidDomain: boolean;
  isDomainCreate: boolean;
  openMoveDomainPopup: boolean;
  showMoveDomainButton: boolean;
  targetEmployer: Employer;
};

class EmployerDomains extends React.Component<Props, State> {
  state: State;
  columns: Array<GridColumnProps>;
  constructor(props: Props) {
    super(props);

    this.state = {
      employerDomains: [] ,
      filteredDomains: [],
      dataState: gridProps,
      savedDomains: [],
      selectedDomains: [],
      isFilterExists: false,
      isLoading: false,
      hasError: false,
      openAddDomainsPopup: false,
      showAddDomainsButton: false,
      editedDomain: new EmployerDomain(),
      newlyAddedDomain: new EmployerDomain(),
      isDomainAlreadyExists: false,
      isValidDomain: true,
      isDomainCreate: true,
      openMoveDomainPopup: false,
      showMoveDomainButton: false,
      targetEmployer:new Employer()
    };

    this.columns = new Array<GridColumnProps>(
      { field: "domain", title: "DOMAIN NAME", cell: this.domainNameCell, filterable: true, filter: "text", width:120 },
      {
        field: "federated", title: "FEDERATED", cell: this.isFederated, filterable: true,
        filterCell: (props: GridFilterCellProps) => (
          <DropDownFilter
            {...props}
            data={[{ name: "Federated", id: 1 }, { name: "Non-Federated", id: 0 }]}
            textField="name"
            defaultSelectedOption={null}
          />
        )
      },
      {
        field: "treatAsFederated", title: "TREAT AS FEDERATED", cell: this.treatAsFederated, filterable:true,
        filterCell: (props: GridFilterCellProps) => (
          <DropDownFilter
            {...props}
            data={[{ name: "Treat As Federated", id: 1 }, { name: "Treat As Non-Federated", id: 0 }]}
            textField="name"
            defaultSelectedOption={null}
          />
        )
      },
      { field: "createdDate", title: "DATE CREATED", format: "{0:g}", filterable: true, filter: "date", cell: this.createdDateCustomCell });

    if (this.props.permissions.has("EpiqAdminUpdateEmployer")) {
      this.columns.push(
        {
          title: "",
          cell: CommandCell({ onEdit: this.editDomain }),
          sortable: false,
          headerClassName: "no-sort"
        },
        {
          title: "",
          cell: this.customDeleteCommandCell,
          sortable: false,
          headerClassName: "no-sort"
        }
      );
    }

    if (this.props.isSuperAdmin) {
      this.columns.push(
        {
          title: "",
          cell: this.customUnlinkCommandCell,
          sortable: false,
          headerClassName: "no-sort"
        });
    }
  }

  componentWillMount = () => {
    this.getEmployerDomains(this.state.dataState);
  }

  updateFederatedSortDirection = (dataState: IGridParams) => {
    const sort: SortDescriptor[] = dataState.sort ? [...dataState.sort] : [];
    return sort.map((item, index) => {

      if (item.field === "federated") {
        item.dir = item.dir === "asc" ? "desc" : "asc"
      }

      return item;
    });
  }

  getModifiedCorrectDateFilter = (
    gridFilters: Array<CompositeFilterDescriptor>,
    dateColumnName: string = "createdDate"
  ) => {
    // This function is used for date column filter in all advance grid pages.
    let isCreatedDateFilterExists = false;
    let createdDateValue: Date;
    const filters = gridFilters
      ? gridFilters.map(compositeFilter => {
        const createdDateFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === dateColumnName
        ) as FilterDescriptor;

        if (createdDateFilter) {
          const dateFilterValue = createdDateFilter.value;

          if (dateFilterValue === "") {
            let index = compositeFilter.filters.findIndex((p) => p === createdDateFilter);
            if (index >= 0) {
              compositeFilter.filters.splice(index, 1);
              return { ...compositeFilter };
            }
          }


          if (!isNaN(new Date(dateFilterValue).getFullYear()) && new Date(dateFilterValue).getFullYear() > 1970) {
            createdDateFilter.operator = 'eq';
          }

          createdDateValue = typeof dateFilterValue === "string" ? new Date("01/01/1901") : ((createdDateFilter.operator === 'isnull' || createdDateFilter.operator === 'isnotnull') ? null : new Date(dateFilterValue));

          if (createdDateFilter.operator === 'isnull' || createdDateFilter.operator === 'isnotnull') {
            return {
              ...compositeFilter,
              filters: [
                {
                  ...createdDateFilter,
                  field: dateColumnName,
                  operator: typeof dateFilterValue === "string" && createdDateFilter.operator === 'isnotnull' ? "eq" : createdDateFilter.operator,
                  value: createdDateValue
                }
              ]
            };
          }

          isCreatedDateFilterExists = true;

          return {
            ...compositeFilter,
            filters: [
              {
                ...createdDateFilter,
                field: dateColumnName,
                operator: "gte",
                value: new Date(createdDateValue.toUTCString())
              }
            ]
          };
        }

        return compositeFilter;
      })
      : [];

    if (isCreatedDateFilterExists) {
      filters.push({
        logic: "and",
        filters: [
          {
            field: dateColumnName,
            operator: "lt",
            value: new Date(new Date(createdDateValue.setUTCDate(createdDateValue.getUTCDate() + 1)).toUTCString())
          }
        ]
      });
    }

    return filters;
  };

  getFederatedDomainFilter = (
    gridFilters: Array<CompositeFilterDescriptor>,
    field: string = "federated"
  ) => {
    const filters = gridFilters
      ? gridFilters.map(compositeFilter => {
        const federatedFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === field
        ) as FilterDescriptor;

        if (federatedFilter) {
          const federatedFilterValue = federatedFilter.value
            ? (federatedFilter.value.id == 0 || federatedFilter.value.id == 1
              ? (federatedFilter.value.id == 0
                ? false
                : true)
              : null)
            : null;

          if (federatedFilterValue === null) {
            let index = compositeFilter.filters.findIndex((p) => p === federatedFilter);
            if (index >= 0) {
              compositeFilter.filters.splice(index, 1);
              return { ...compositeFilter };
            }
          }

          return {
            ...compositeFilter,
            filters: [
              {
                ...federatedFilter,
                field: field,
                operator: "eq",
                value: federatedFilterValue
              }
            ]
          };
        }

        return compositeFilter;
      }) : [];

    return filters;
  };

  getEmployerDomains = async (dataState: IGridParams) => {

    if (this.props.employerDetails.id > 0) {

      let filters = this.getModifiedCorrectDateFilter(dataState.filters);
      filters = this.getFederatedDomainFilter(filters,"federated");
      filters = this.getFederatedDomainFilter(filters,"treatAsFederated");

      if (filters && filters.length > 0) {
        let compositeFilters = new Array<CompositeFilterDescriptor>();

        filters.forEach(compositeFilter => {
          if (compositeFilter.filters.length > 0) {
            let federatedFilter: FilterDescriptor = compositeFilter.filters.find(
              filter => (filter as FilterDescriptor).value !== ""
            ) as FilterDescriptor;

            if (federatedFilter) {
              compositeFilters.push(compositeFilter);
            }
          }
        });       

        filters = compositeFilters;

      }

      this.setState({ isFilterExists: filters && filters.length > 0, isLoading: true });

      let reverseSortDescriptors: SortDescriptor[] = this.updateFederatedSortDirection(dataState);

      const result = await AdminService.getEmployerDomainListById(this.props.employerDetails.id, { ...dataState, filters: filters, sort: reverseSortDescriptors });    

      let updateSortDescriptors: SortDescriptor[] = this.updateFederatedSortDirection(dataState);

      if (result) {
        const domains = result.data && result.data.results ? result.data.results as EmployerDomain[] : [];

        if (filters && filters.length > 0) {
          this.setState({ ...this.state, filteredDomains: domains });
        }
        else {
          this.setState({ ...this.state, employerDomains: domains });
          if (this.props.updateSavedDomains) {
            this.props.updateSavedDomains(domains, this.state.isFilterExists, { ...dataState, filters: filters, sort: reverseSortDescriptors });
          }
        }

        this.setState({ ...this.state, hasError: false });
      }
      else {
        this.setState({ ...this.state, hasError: true });
      }

      this.setState({ ...this.state, isLoading: false });
    }
  }

  domainNameCell = (cellProps: GridCellProps) => {
    const domain = cellProps.dataItem.domain;
    return (
      <td>
        <em title={domain}>
          {domain}
        </em>
      </td>
    );
  }

  isFederated = (cellProps: GridCellProps) => {
      return (
      <td>
        <input
            type="checkbox"
            name="federated"
            checked={cellProps.dataItem.federated === undefined ? false : cellProps.dataItem.federated}
            disabled={true}
        />
      </td>
    );
  };

  treatAsFederated = (cellProps: GridCellProps) => {
    return (
      <td>
        <input
          type="checkbox"
          name="treatasfederated"
          checked={cellProps.dataItem.treatAsFederated === undefined ? false : cellProps.dataItem.treatAsFederated}
          disabled={true}
        />
      </td>
    );
  };

  createdDateCustomCell = (props: GridCellProps) => {
    let date = "";
    let createdDate = props.dataItem.createdDate;

    if (createdDate) {
      let localDateTime = CommonHelper.convertUTCDateToLocalDate(createdDate);
      date = formatDate(localDateTime, "g");
    }

    return <td>{date}</td>;
  }

  customDeleteCommandCell = (props: GridCellProps) => {
    const isEpiqEmployer = this.props.employerDetails && this.props.employerDetails.id && this.props.employerDetails.id == 1 ? true : false;
    const domain = props.dataItem ? props.dataItem as EmployerDomain : new EmployerDomain();
    const id = props.dataItem.id ? props.dataItem.id : 0;
    return (
    !isEpiqEmployer && <td className="k-command-cell">
      <button className="k-button k-button-icon icon-button" onClick={(e) => this.removeDomain(domain)}>
        <span className="k-icon k-i-delete"></span>
      </button>
    </td>
  )
  }

  onSelectionChange = (addedDomain: EmployerDomain) => {
    this.setState({ ...this.state, isDomainAlreadyExists: false, isValidDomain: true });
  };

  isDomainAlreadyExists = async (domainName: string, excludeDomainId: number) => {
    const result = await AdminService.isDomainAlreadyExist(domainName, excludeDomainId);
    return result.ok && result.value == "true";
  }

  isDomainValid = (addedDomain: EmployerDomain) => {
    const domainRegex = new RegExp(/^([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}$/i);
    return domainRegex.test(addedDomain.domain.trim());
  }

  onSaveDomainButtonClick = async (addedDomain: EmployerDomain) => {
    const newDomain = { ...addedDomain };
    const isCreate = this.state.isDomainCreate;
    let employerDomains = [...this.props.employerDomains];
    let filteredDomains = [...this.state.filteredDomains];

    const isDomainNameValid = this.isDomainValid(newDomain);
    const domainIndex = newDomain.id > 0 ?
      employerDomains.findIndex((d) => d.domain.toLowerCase() == newDomain.domain.toLowerCase() && d.id != newDomain.id) :
      employerDomains.findIndex((d) => d.domain.toLowerCase() == newDomain.domain.toLowerCase() && d.domain.toLowerCase() != ((this.state.editedDomain && this.state.editedDomain.domain) ? this.state.editedDomain.domain.toLowerCase():""));

    const isDomainExists = domainIndex == -1 ? await this.isDomainAlreadyExists(newDomain.domain.toLowerCase(), newDomain.id) : true;

    if (isDomainNameValid) {
      if (isDomainExists) {
        this.setState({ ...this.state, isDomainAlreadyExists: true }, () => {
          if (this.props.updateSelectedDomains) {
            this.props.updateSelectedDomains(employerDomains, this.state.isFilterExists);
          }
        });
      }
      else {
        if (isCreate) {
          newDomain.isSelected = true;
          employerDomains.push(newDomain);
          if (this.state.isFilterExists) {
            filteredDomains.push(newDomain);
          }
        }
        else {
          const editDomainIndex = employerDomains.findIndex((d) => d.domain.toLowerCase() == this.state.editedDomain.domain.toLowerCase());
          const editDomain = { ...this.state.editedDomain };
          editDomain.domain = newDomain.domain;
          editDomain.treatAsFederated = newDomain.treatAsFederated;
          employerDomains[editDomainIndex] = editDomain;

          if (this.state.isFilterExists && filteredDomains.length>0) {
            const filteredEditDomainIndex = filteredDomains.findIndex((d) => d.domain.toLowerCase() == this.state.editedDomain.domain.toLowerCase());
            filteredDomains[filteredEditDomainIndex] = editDomain;
          }
        }

        this.setState({ ...this.state, isValidDomain: true, openAddDomainsPopup: false, editedDomain: new EmployerDomain(), filteredDomains: filteredDomains }, () => {
          if (this.props.updateSelectedDomains) {
            this.props.updateSelectedDomains(employerDomains, this.state.isFilterExists);
          }
        });
      }
    }
    else {
      this.setState({ ...this.state, isValidDomain: false }, () => {
        if (this.props.updateSelectedDomains) {
          this.props.updateSelectedDomains(employerDomains, this.state.isFilterExists);
        }
      });
    }
  }

  closeAddDomainsPopup = (accepted: boolean) => {
    this.setState({ ...this.state, openAddDomainsPopup: false, isDomainAlreadyExists: false, isValidDomain: true, editedDomain: new EmployerDomain()});
  }

  addDomainsClick = () => {
    this.setState({ ...this.state, openAddDomainsPopup: true, isDomainCreate: true})
  }

  removeDomain = (domain: EmployerDomain) => {
    const empDomains = [...this.props.employerDomains];
    const filteredDomains = [...this.state.filteredDomains];
    const alteredDomains = empDomains.filter(resource => resource.domain.toLowerCase() != domain.domain.toLowerCase());
    const alteredFilteredDomains = filteredDomains.filter(resource => resource.domain.toLowerCase() != domain.domain.toLowerCase());

    this.setState({ ...this.state, employerDomains: alteredDomains, filteredDomains: alteredFilteredDomains }, () => {

      if (this.props.updateSelectedDomains) {
        this.props.updateSelectedDomains(alteredDomains, false);
      }
    });
  }

  editDomain = (cellProps: any) => {
    const editDomain = this.props.employerDomains.filter(resource => resource.domain.toLowerCase() == cellProps.domain.toLowerCase());
    this.setState({ ...this.state, editedDomain: editDomain[0], openAddDomainsPopup: true, isDomainCreate: false});
  }

  filterElements: any = (element: any) => {
    if (element.tagName === "EM") {
      return true;
    }
    return false;
  };

  gridToolBarRender = (): JSX.Element => {
    return ((this.props.isCreate && this.props.permissions.has("EpiqAdminCreateEmployer")) || (!this.props.isCreate && this.props.permissions.has("EpiqAdminUpdateEmployer"))) && (
      <>
        <Button className="add-user-btn" icon={"plus"} primary={true} onClick={this.addDomainsClick}>
          Domains
        </Button>
      </>
    );
  }

  unLinkDomainClick = (domain: EmployerDomain) => {
    const selectedDomain = this.props.employerDomains.filter(resource => resource.domain.toLowerCase() == domain.domain.toLowerCase());
    this.setState({ ...this.state, editedDomain: selectedDomain ? selectedDomain[0] : null, openMoveDomainPopup: true});
  }

  updateTargetEmployer = (employer: Employer) => {
    if(employer)
    {
      this.setState({...this.state, targetEmployer: employer, showMoveDomainButton : true});
    }
  }

  moveDomainClick = async() => {
    const {editedDomain, targetEmployer} = this.state;
    const currentEmployer = this.props.employerDetails;
    this.setState({...this.state, isLoading: true});
    const moveDomainModel: MoveDomainModel = { domainId : editedDomain.id, domainName : editedDomain.domain, currentEmployerId : currentEmployer.id, currentEmployer: currentEmployer.name, targetEmployerId: targetEmployer.id, targetEmployer: targetEmployer.name};
    const result = await AdminService.mapDomainToNewEmployer(moveDomainModel);
      if (result.ok) {
          if(this.props.navigateToEmpList)
          {
            this.props.navigateToEmpList();
          }
          NotificationService.showSuccessToast("Domain mapped successfully to new employer");
      }
      else {
        NotificationService.showErrorToast("Something went wrong.Unable to map domain to new employer");
      }
      
      this.setState({...this.state, isLoading: false, editedDomain: new EmployerDomain(), targetEmployer: new Employer() });
  }

  closeMoveDomainPopup = () => {
    this.setState({ ...this.state, openMoveDomainPopup: false, editedDomain: new EmployerDomain(), targetEmployer: new Employer()}); 
  }

  customUnlinkCommandCell = (props: GridCellProps) => {
    const domain = props.dataItem ? props.dataItem as EmployerDomain : new EmployerDomain();
    
    return (
      this.props.savedDomains.length > 1 && <td className="k-command-cell">
        <button className="k-button k-button-icon icon-button" title="Move Domain" onClick={(e) => this.unLinkDomainClick(domain)}>
          <span className="k-icon k-i-unlink-horizontal"></span>
        </button>
    </td>);
  }

  render() {
    const { hasError, isLoading, employerDomains, dataState, isFilterExists } = this.state;

    return (
      //this class is being set to base-grid-wrapper for now until we convert all of this to standard grid
      <>
        <div>
          <GridSelector
            isOpen={this.state.openAddDomainsPopup}
            acceptBtnText="Add"
            cancelBtnText="Cancel"
            titleText={this.state.isDomainCreate ? "Add Domain" : "Update Domain"}
            addAvailable={this.state.showAddDomainsButton}
            onClose={this.closeAddDomainsPopup}
            addClass="add-employer-group-modal modal-as-sidebar"
          >
            <AddDomainsToEmployer
              employerDetails={this.props.employerDetails}
              savedDomains={this.state.savedDomains}
              editedDomain={this.state.editedDomain}
              closeDomainsPopup={this.closeAddDomainsPopup}
              isDomainsPopUpOpened={this.state.openAddDomainsPopup}
              isDomainAlreadyExists={this.state.isDomainAlreadyExists}
              onSaveClick={this.onSaveDomainButtonClick}
              isValidDomain={this.state.isValidDomain}
              onSelectionChange={this.onSelectionChange}
              isCreate={this.state.isDomainCreate}
            />
          </GridSelector>

          <GridSelector
            isOpen={this.state.openMoveDomainPopup}
            acceptBtnText="Move"
            onApply= {this.moveDomainClick}
            onReject={this.closeMoveDomainPopup}
            cancelBtnText="Cancel"
            titleText={"Move Domain"}
            addAvailable={this.state.showMoveDomainButton}
            addClass="move-employer-group-modal"
            onClose={this.closeMoveDomainPopup}
          >
            <MoveDomain  domain = {this.state.editedDomain} currentEmployer = {this.props.employerDetails} updateTargetEmployer={this.updateTargetEmployer} />
          </GridSelector>
          
          <div className="domain-list">
            <Tooltip openDelay={2} position="right" filter={this.filterElements}>
              <AdvancedGrid
                showErrorState={hasError}
                showLoadingIndicator={isLoading}
                sortable={{ mode: "multiple" }}
                data={!isFilterExists ? this.props.employerDomains : this.state.filteredDomains}
                dataFetch={this.getEmployerDomains}
                dataState={dataState}
                columns={this.columns}
                paging={false}
                totalRecords={{ value: !isFilterExists ? this.props.employerDomains.length : this.state.filteredDomains.length, label: "Domains" }}
                noRecordsRender={<p>{isFilterExists ? "No domains found." : "No domains added."}</p>}
                noLoadOnMount={true}
                filteredDataOnly={false}
                multiFieldFilterDelimiter="|"
                filterOperators={{
                  text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
                  date: [{ text: "grid.filterIsNotNullOperator", operator: "isnotnull" }, { text: "grid.filterIsNullOperator", operator: "isnull" }]
                }}
                gridToolbarContent={this.gridToolBarRender()}
              />
            </Tooltip>
          </div>
        </div>
      </>
    );
  }

}

export default EmployerDomains;
