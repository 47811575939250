import React, { useEffect, useState } from "react";
import { ISessionManager } from "../../SessionManager";
import { connect } from "react-redux";
import * as SessionStore from "../../store/Session";
import { IApplicationState } from "../../store";
import * as AuthenticationStore from "../../store/Authentication";
import AgreementService, { AgreementDecision, IAgreementDecisionResponseModel } from "../../services/AgreementService";
import "@progress/kendo-theme-material/dist/all.css";
import "../Layout.scss";
import AgreementDialog from "./AgreementDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import { IAgreementAcceptanceModel } from "../../models/MessageModel";
import { Container, Paper } from "@material-ui/core";
import { Button } from "@progress/kendo-react-buttons";

interface IProps {
  sessionManager: ISessionManager;
  history: any;
}

type Props = IProps &
  SessionStore.ISessionState &
  AuthenticationStore.IAuthenticationState &
  typeof AuthenticationStore.actionCreators &
{ children?: React.ReactNode };

export const Agreements = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [initializing, setInitializing] = useState(true);
  const [activeAgreement, setActiveAgreement] = useState(null);
  const [showError, setShowError] = useState(false);

  const returnToLogin = () => {
    window.location.href = "/";
  }

  const loadNextAgreement = async () => {
    setIsLoading(true);

    const response = await AgreementService.getNextAgreement();

    if(!response.ok) {
      setShowError(true);
      return;
    }

    setActiveAgreement(response.data);
    setInitializing(false);
    setIsLoading(false);
  }

  const processDecisionResponse = async (response: IAgreementDecisionResponseModel) => {
    if(!response.isValid) {
      setShowError(true);
      return;
    }

    if(response.isSessionComplete) {
      returnToLogin();
      return;
    }

    if(response.nextAgreement !== null) {
      await loadNextAgreement();
      return;
    }
  }

  const toggleDialogEvent = async (decision: AgreementDecision) => {
    setIsLoading(true);

    const response = await AgreementService.submitDecision(activeAgreement.agreementId, decision);

    if(!response.ok) {
      setShowError(true);
      return;
    }

    if(decision == AgreementDecision.Decline && activeAgreement.forceLogoutOnDisagree) {
      await logOut();
      return;
    }

    await processDecisionResponse(response.data);
  }

  useEffect(() => {
    if(initializing) {
      loadNextAgreement()
        .catch(console.error);

      setInitializing(false);
    }
  });

  const logOut = async () => {   
    await props.sessionManager.clearSession();    
    returnToLogin();
  }

  const content = (
    <>
      <Container maxWidth="lg" className="content-wrapper">

      {isLoading && 
        <div className="agreements-container">
          <CircularProgress />
        </div>
      }

      {
        (!isLoading && activeAgreement && !showError && 
          <div>
            <AgreementDialog
              toggleDialogEvent={toggleDialogEvent}
              agreement={{ title: activeAgreement.title, message: activeAgreement.message } as IAgreementAcceptanceModel}
              acceptButtonLabelText={"Accept"}
              declineButtonLabelText={"Decline"}
              isLoading={isLoading}
              modalWidth={"1200px"}
            />
          </div>
        )
      }

      {
        (showError && 
          <div className="makeStyles-root-13">
            <div className="MuiGrid-root">
              <div className="no-apps">
                <div className="msg">
                  <h3>There was a problem fetching this agreement.</h3>
                  <p> Please contact your network administrator for support.</p>
                  <Button className="btn" onClick={logOut}>RETRY</Button>
                </div>
              </div>
            </div>
          </div>
        )
      }      
      </Container>
    </>
  )

  return (
    <>
      {content}
    </>
  )
};

export default connect((state: IApplicationState) => ({ ...state.authenticationState, ...state.sessionState }), {
  ...AuthenticationStore.actionCreators,
  ...SessionStore.actionCreators,
})(Agreements);