import { Action, Reducer } from "redux";
import { AppThunkAction, AppThunkActionPromise } from ".";
import NotificationService from "../services/NotificationService";
import { IAppStatus } from "../models/AppStatus";

export interface IAppStatusState {
  status: IAppStatus;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface IAppStatusActionRequest {
  type: "GET_APP_STATUS_REQUEST";
}

interface IAppStatusActionResponse {
  type: "GET_APP_STATUS_RESPONSE";
  status: IAppStatus;
}

interface IAppStatusActionFailure {
  type: "GET_APP_STATUS_FAILURE";
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = IAppStatusActionRequest | IAppStatusActionResponse | IAppStatusActionFailure;

export const actionCreators = {
  getAppStatus: (): AppThunkActionPromise<KnownAction> => async (dispatch, getState) => {
    dispatch({ type: "GET_APP_STATUS_REQUEST" });
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      const result = await NotificationService.getApplicationState();

      if (result.ok) {
        dispatch({
          type: "GET_APP_STATUS_RESPONSE",
          status: result.data
        });
      } else {
        dispatch({
          type: "GET_APP_STATUS_FAILURE"
        });
      }
    }
  }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: IAppStatusState = {
  status: null
};

export const reducer: Reducer<IAppStatusState> = (
  state: IAppStatusState | undefined,
  incomingAction: Action
): IAppStatusState => {
  if (state === undefined) {
    return initialState;
  }
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "GET_APP_STATUS_REQUEST":
      return {
        status: state.status
      };
    case "GET_APP_STATUS_RESPONSE":
      return {
        status: action.status
      };
    case "GET_APP_STATUS_FAILURE":
      return {
        status: state.status
      };
    default:
      return state;
  }
};
